import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import InputCheckbox from "../inputComponents/inputCheckbox";

function ReadingMultichoice({ data, grabAnswer }) {
  const { instructions, question_content, question_options } = data;
  const[count,seTcount] = useState(0)
useEffect(()=>{
  seTcount(count+1)
},[data])
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <Row className="g-gs">
          <Col md="6">
            <p>{question_content}</p>
          </Col>
          <Col md="1"></Col>
          <Col md="5">
          <em className="pte-test-instructions-bold">{instructions}</em>
          <p style={{lineHeight:"1.5",marginBottom:"0.4rem"}}>{data?.question}</p>
     <InputCheckbox  data={question_options}/>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ReadingMultichoice;
