import React from 'react'
import AWS from "aws-sdk";
import wavEncoder from 'wav-encoder';
const S3_BUCKET_NAME = 'hilingo';
const REGION = 'ap-south-1';


AWS.config.update({
  // accessKeyId: "AKIAWSTGTHYDQG72BXEC",
  // secretAccessKey: "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",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();


export async function S3upload(recordingBlob,key) {

  // const downloadWavFile = (blob) => {
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.style.display = 'none';
  //   a.href = url;
  //   a.download = 'converted_audio.wav';
  //   document.body.appendChild(a);
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // };


  // const convertToWav = async (blob) => {
  //   const arrayBuffer = await blob.arrayBuffer();
  //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
  //   // Decode audio data
  //   const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
  //   // Resample the audio to 16000 Hz
  //   const offlineContext = new OfflineAudioContext(
  //     1,
  //     audioBuffer.duration * 16000,
  //     16000
  //   );
  
  //   const source = offlineContext.createBufferSource();
  //   source.buffer = audioBuffer;
  
  //   source.connect(offlineContext.destination);
  //   source.start(0);
  
  //   const resampledBuffer = await offlineContext.startRendering();
  
  //   // Encode to WAV
  //   const wavData = await wavEncoder.encode({
  //     sampleRate: 16000,
  //     channelData: [resampledBuffer.getChannelData(0)]
  //   });
  
  //   const wavBlob = new Blob([wavData], { type: 'audio/wav' });
  //   // downloadWavFile(wavBlob);
    
  //   return wavBlob
  // };
  

    // const convertToWavFile = await convertToWav(recordingBlob)

    // console.log("RECORDING BLOB",recordingBlob);
    // console.log("RECORDING BLOB convertToWav",convertToWavFile);


    if (!recordingBlob) {
        return;
      }
  
      const params = {
        Bucket: S3_BUCKET_NAME,
        Key: key,
        Body: recordingBlob
      };
  
      return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            console.log("ERR",err);
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      });
}

