// import React from 'react';

// const TicketSvg = ({ fill = '#000000' }) => {
//   return (
//     <svg
//     xmlns='http://www.w3.org/2000/svg'
//     width='128'
//     height='128'

//     viewBox='0 0 128 128'
//   >
//     <path
//       d='M121.5 64c1 0 1.7-.8 1.8-1.7V40.5c0-1-.8-1.7-1.8-1.8h-4.8l-2.1-14.3c-.1-1-1-1.6-2-1.5l-27.1 4h-.1L6.2 38.8s-1.5.3-1.5 1.7v21.7c0 1 .8 1.7 1.7 1.8 4.4 0 7.9 3.5 7.9 7.9s-3.5 7.9-7.9 7.9c-1 0-1.7.8-1.8 1.7v21.7c0 1 .8 1.7 1.8 1.8h115c1 0 1.7-.8 1.8-1.8V81.6c0-1-.8-1.7-1.7-1.8-4.4 0-7.9-3.5-7.9-7.9s3.5-7.9 7.9-7.9zM84.2 30.7l.7 4.7c.1.9.9 1.5 1.7 1.5h.3c1-.1 1.6-1 1.5-2l-.7-4.7 23.7-3.5 1.8 12.1H30.1zm35.6 52.5v18.4H93.9v-4.8c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v4.8h-82V83.2c6.2-1 10.5-6.8 9.5-13-.8-4.9-4.6-8.8-9.5-9.5V42.3h82.1V47c0 1 .8 1.8 1.8 1.8S94 48 94 47v-4.8h25.9v18.4c-6.2 1-10.5 6.8-9.5 13 .6 5 4.4 8.8 9.4 9.6z'
//       data-original='#000000'
//     ></path>
//     <path
//       d='M92.1 74.6c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V76.4c0-1-.8-1.8-1.8-1.8zm0-20.4c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V55.9c0-.9-.8-1.7-1.8-1.7zM27.3 57H49c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7H27.3c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7zm43.4 7.6H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z'
//       data-original='#000000'
//     ></path>
//   </svg>
//   );
// }

// export default TicketSvg;



import React from 'react';

const TicketSvg = ({ fill }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 510 510'>
      <g>
        <path d='M488.468 345.72C501.347 340.733 510 328.041 510 314.136v-64.334c0-19.068-15.513-34.582-34.582-34.582h-3.531c10.468-13.552 9.502-33.148-2.921-45.572l-45.491-45.491c-9.831-9.831-24.924-12.688-37.558-7.108-6.786 2.998-14.871 1.485-20.118-3.762-5.247-5.248-6.76-13.333-3.764-20.118 5.581-12.633 2.725-27.727-7.108-37.559l-45.49-45.491c-13.482-13.481-35.422-13.483-48.906 0L55.431 215.22H34.582C15.513 215.22 0 230.734 0 249.802v64.334c0 13.905 8.653 26.598 21.532 31.583 6.917 2.679 11.565 9.465 11.565 16.886s-4.648 14.208-11.565 16.886C8.653 384.48 0 397.173 0 411.076v64.333c0 19.068 15.513 34.582 34.582 34.582h440.836c19.069 0 34.582-15.514 34.582-34.582v-64.333c0-13.903-8.652-26.596-21.531-31.585-6.918-2.679-11.566-9.465-11.566-16.886s4.648-14.207 11.565-16.885zM281.744 31.334a4.587 4.587 0 0 1 6.48-.001l45.49 45.491c1.139 1.139 1.492 2.836.878 4.225-7.963 18.031-3.947 39.512 9.993 53.451 13.94 13.94 35.421 17.955 53.453 9.992 1.387-.612 3.085-.26 4.224.879l45.49 45.49a4.587 4.587 0 0 1 .001 6.48l-17.88 17.879h-78.162L224.784 88.293zm-78.173 78.172L309.286 215.22H97.856zM30 475.409v-64.333c0-1.611.951-3.061 2.366-3.609 18.381-7.119 30.73-25.147 30.73-44.862s-12.35-37.743-30.732-44.862c-1.414-.547-2.364-1.997-2.364-3.607v-64.334a4.588 4.588 0 0 1 4.582-4.582H375v234.771H34.582A4.587 4.587 0 0 1 30 475.409zm450-161.273c0 1.61-.95 3.06-2.365 3.608-18.381 7.119-30.732 25.147-30.732 44.862s12.35 37.743 30.731 44.861c1.415.548 2.366 1.999 2.366 3.609v64.333a4.588 4.588 0 0 1-4.582 4.582H405V245.22h70.418a4.588 4.588 0 0 1 4.582 4.582z'
          fill={fill}
          opacity='1'
          data-original='#000000' class=''></path>
        <path d='M105 284.991h225v30H105zM105 344.991h225v30H105zM105 404.991h225v30H105z'
          fill={fill} opacity='1' data-original='#000000' class=''></path></g></svg>
  );
}

export default TicketSvg;

