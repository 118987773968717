import { useEffect, useState, useRef } from "react";
import {
  OverlayTrigger,
  Popover,
  Button,
  Tab,
  Nav,
  Modal,
  Form,
  Row,
  Col,
  ProgressBar,
  Badge,
  Table,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Pureknob, Icon } from "../../../components";
import { Colors } from "../../../utilities/index";
import AISpeechRecognition from "../../studentresult/answerscomponents/AISpeechRecognition";
import { wrapWordForIndividualPlayback, playWithMozillaApi } from "./callback.js";
import AdminApi from "../../../api/admin/index.js";
import SingleAnswer from "./Answers/singleAnswer.js";
import Swal from "sweetalert2";
import PracticeResult from "./PracticeComponents/PracticeResult.js";
import parse from 'html-react-parser';


function PracticePteCommentsNew({moduleName, qst_id, user_id, redoCount, answerSubmitted,setScoreModal,scoreModal,audioUrl,api_responseAll }) {
  let api = new AdminApi();
  console.log('api_responseAll :>> ', api_responseAll);
  const [loaderModal, setLoaderModal] = useState(false);
  const [trying, setTrying] = useState([]);
  const loaderModalClose = () => setLoaderModal(false);
  const loaderModalShow = () => setLoaderModal(true);

  const myRef = useRef(null)

  const [CommentModal, setCommentModal] = useState(false);
  const CommentModalClose = () => setCommentModal(false);
  const CommentModalShow = () => setCommentModal(true);
  let path = window.location.pathname;
  // const [scoreModal, setScoreModal] = useState(false);
  const scoreModalClose = () => setScoreModal(false);
  const [activeProject, setactiveProject] = useState({
    size: 110,
    value: 0,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.blue,
    trackWidth: "0.15",
  });
  const [state, setState] = useState({
    activeKey: "all",
    shareModal: false,
    answer_data: [],
    current_qst_data: {},
    loader_modal: false,
    currentText: "",
    clicked_value_obj: {
      original_word: "",
      real_transcript: "",
      user_transcript: "",
      audioUrl: "",
    },
    real_transcripts_ipa: "",
    matched_transcripts_ipa: "",
    pair_accuracy_category: "",
    api_res: {},
    swtsmodal: false,
    fibwrModal: false,
    wfdsModal: false,
    AI_modal: false,
    answerGivenByUser: {},
    answer_id: "",
    api_response: {
      content_score: 2,
      form_score: 0,
      grammar_score: 0,
      vocabulary_score: 0,
      total_score: 2,
      max_score: 7,
      actual_score: 27,
      grammar: [
        {
          index: 10,
          message: "Add a space between sentences.",
          replacements: [" In"],
        },
        {
          index: 15,
          message: "Possible spelling mistake found.",
          replacements: ["Canfield"],
        },
      ],
    },
  });
  let {
    shareModal,
    activeKey,
    answer_data,
    loader_modal,
    currentText,
    current_qst_data,
    real_transcripts_ipa,
    pair_accuracy_category,
    lettersOfWordAreCorrect,
    matched_transcripts_ipa,
    clicked_value_obj,
    api_res,
    answerGivenByUser,
    answer_id,
    swtsmodal,
    fibwrModal,
    wfdsModal,
    AI_modal,
    api_response,
  } = state;

  console.log('api_res?.content :>> ', api_res?.content);
  const scoreModalShow = async (
    quesData,
    S3Url,
    answer_id,
    api_response,
    audioUrl,
    a,
    answer,
    score_type
  ) => {
    let { module_name, module } = current_qst_data;
    // if (module_name === "ssts") {
    //   await setState({
    //     ...state,
    //     swtsmodal: true,
    //     AI_modal: true,
    //     answerGivenByUser: answer,
    //     answer_id: answer_id,
    //     api_response: api_response,
    //   });
    // }
    if (module === "speaking" || module === "writing" || module === "listening") {
      setState({
        ...state,
        AI_modal: true,
        loader_modal: true,
        currentText: current_qst_data?.question_content,
      });
      // if (answer_id) {
      //   var lastIndex = S3Url?.lastIndexOf("practice");
      //   var desiredPart = S3Url?.substring(lastIndex);

      //   let data_ = JSON.stringify({
      //     title: module_name == 'repeat_sentences'?current_qst_data?.question_transcript: current_qst_data.question_content ? current_qst_data.question_content : "",
      //     file_key: desiredPart,
      //     language: "en",
      //     sample_answer:
      //       current_qst_data?.question_options?.length &&
      //       current_qst_data?.question_options[0].answer
      //         ? current_qst_data.question_options[0]?.answer.length
      //           ? current_qst_data.question_options[0]?.answer[0]
      //           : ""
      //         : "",
      //   });
      //   if(module_name == "answer_questions"){
      //     data_ = JSON.stringify({
      //       title: module_name == 'repeat_sentences'?current_qst_data?.question_transcript: current_qst_data.question_content ? current_qst_data.question_content : "",
      //       file_key: desiredPart,
      //       language: "en",
      //       sample_answer:
      //         current_qst_data?.sample_answer?.length &&
      //         current_qst_data?.sample_answer,
      //     });
      //   }
      //   if(module_name == "essays"){
      //     let removeSpecialChar = answer?.answer
      //     if(removeSpecialChar){
      //       removeSpecialChar = removeSpecialChar.replaceAll("\n"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll("\t"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll("\r"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll(/\s+/g, ' ')

      //     }
      //     data_ = JSON.stringify({
      //       title: current_qst_data?.question_content ? current_qst_data?.question_content : "",
      //       file_key: desiredPart,
      //       language: "en",
      //       answer: removeSpecialChar,
      //       score_type:score_type,
      //       sample_answer:
      //         current_qst_data?.sample_answer?.length &&
      //         current_qst_data?.sample_answer,
      //     });
      //   }
      //   if(module_name === "swts"){
      //     let removeSpecialChar = answer?.answer
      //     if(removeSpecialChar){
      //       removeSpecialChar = removeSpecialChar.replaceAll("\n"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll("\t"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll("\r"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll(/\s+/g, ' ')

      //     }
      //     data_ = JSON.stringify({
      //       title: current_qst_data?.question_content ? current_qst_data?.question_content : "",
      //       file_key: desiredPart,
      //       language: "en",
      //       answer: removeSpecialChar,
      //       score_type:score_type,
      //       sample_answer:
      //         current_qst_data?.sample_answer?.length &&
      //         current_qst_data?.sample_answer,
            
      //     });
      //   }
      //   if(module_name === "ssts"){
      //     let removeSpecialChar = answer?.answer
      //     if(removeSpecialChar){
      //       removeSpecialChar = removeSpecialChar.replaceAll("\n"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll("\t"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll("\r"," ")
      //       removeSpecialChar = removeSpecialChar.replaceAll(/\s+/g, ' ')

      //     }
      //     data_ = JSON.stringify({
      //       title: current_qst_data?.question_transcript ? current_qst_data?.question_transcript : "",
      //       file_key: desiredPart,
      //       language: "en",
      //       answer: removeSpecialChar,
      //       score_type:score_type,
      //       sample_answer:
      //         current_qst_data?.sample_answer?.length &&
      //         current_qst_data?.sample_answer,
            
      //     });
      //   }
      //   console.log("MODULE_NAMEPTE",module_name);
      //   let speechengineUrl = ""
      //   if(module_name == "read_alouds"){
      //     speechengineUrl = "https://speechengine.hilingo.com/GetTextFromRecordedAudio"
      //   }
      //   else if(module_name == "repeat_sentences"){
      //     speechengineUrl ="https://speechengine.hilingo.com/GetRepeatSentence"
      //   }
      //   else if(module_name == "answer_questions"){
      //     speechengineUrl ="https://speechengine.hilingo.com/GetAnswerShortQuestion"
      //   }
      //   else if(module_name == "essays"){
      //     speechengineUrl ="https://speechengine.hilingo.com/GetEssayTextScore"
      //   }
      //   else if(module_name == "swts"){
      //     speechengineUrl ="https://speechengine.hilingo.com/GetSummarizeTextScore"
      //   }
      //   else if(module_name == "ssts"){
      //     speechengineUrl ="https://speechengine.hilingo.com/GetSummarizeListenScore"
      //   }

     
           
          
      // } 
      


      // else {
      //   await setScoreModal(true);
      //   coloredText(api_response, audioUrl);
      // }
    } else {
      if (module_name === "wfds") {
        setState({
          ...state,
          wfdsModal: true,
          AI_modal: true,
          answerGivenByUser: answer,
          answer_id: answer_id,
          api_response: api_response,
        });
      } else {
        console.log("ELSE",answer);
        setState({
          ...state,
          fibwrModal: true,
          answerGivenByUser: answer,
          answer_id: answer_id,
          api_response: api_response,
        });
      }
    }
  };

 
  const coloredText = async (data, audioUrl) => {
    // alert("*")
    console.log('data?.real_transcripts :>> ', data);
    setactiveProject({
      ...activeProject,
      value: Number(data.total_score),
    });
    var lettersOfWordAreCorrect = data?.is_letter_correct_all_words?.split(" ");
    var startTime = data?.start_time;
    var endTime = data?.end_time;
    var real_transcripts_ipa = data?.real_transcripts_ipa?.split(" ");
    var matched_transcripts_ipa = data?.matched_transcripts_ipa?.split(" ");
    var wordCategories = data?.pair_accuracy_category?.split(" ");
    setState({
      ...state,
      real_transcripts_ipa,
      matched_transcripts_ipa,
      pair_accuracy_category: wordCategories,
      lettersOfWordAreCorrect:lettersOfWordAreCorrect,
      api_res: { ...data },
      clicked_value_obj: {
        ...clicked_value_obj,
        audioUrl: audioUrl,
      },
    });
    let currentTextWords = data?.real_transcripts?.split(" ");
    let pause = data?.pause;
    
    var coloredWords = "";
    var original_script = "";
    console.log("currentTextWords",currentTextWords);
    for (let word_idx = 0; word_idx < currentTextWords?.length; word_idx++) {
        var wordTemp = "";
        var color_letter = "black";

        var color_letter = "";

      if(matched_transcripts_ipa[word_idx] == "-"){
        console.log("INSIDE");
        color_letter = "red";

      }

      else{
        if(lettersOfWordAreCorrect[word_idx] == "0"){
          color_letter = "green"
        }else if(lettersOfWordAreCorrect[word_idx] == "1"){
          color_letter = "orange"
        }else{
          color_letter = "red";
        }

      }

        // if (letter_is_correct) color_letter = "green";

        // else color_letter = "red";

        wordTemp +=
          "<font color=" +
          color_letter +
          ">" +
          currentTextWords[word_idx] +
          "</font>";
      // for (
      //   let letter_idx = 0;
      //   letter_idx < currentTextWords[word_idx].length;
      //   letter_idx++
      // ) {
        
      // }

      // currentTextWords[word_idx];
      console.log("currentTextWords",currentTextWords);
      console.log("wordTemp",wordTemp);
      wordTemp = "<span id=" + word_idx + ">" + wordTemp + "</span>  ";

      original_script +=  wordTemp;
      if(pause[word_idx]?.includes('/')){

        original_script += `<span id='${word_idx}'><font color=black>` +  pause[word_idx] + "</font></span>  ";

      }
  

/*
      
      coloredWords +=
        " " +
        wrapWordForIndividualPlayback(
          matched_transcripts_ipa,
          real_transcripts_ipa,
          wordTemp,
          word_idx
        );*/

    }

    console.log("original_script",original_script);
    let newTrying = [];
   
    newTrying = original_script.split("  ");
    // newTrying.splice(0,1)
    setTrying(newTrying)
    // var originalScriptElement = document.getElementById("original_script");
    // if (originalScriptElement) {
    //   originalScriptElement.innerHTML = original_script;
    // } else {
    //   console.error("Element with ID 'original_script' not found");
    // }
  };

  
    // await setScoreModal(true);
   function setData(){ setState({
      ...state,
      AI_modal: false,
      loader_modal: false,
      api_res: api_responseAll,
    });
    coloredText(api_responseAll, audioUrl);

  }
  useEffect(()=>{
    setData()
  },[])
  const shareModalClose = () => setState({ ...state, shareModal: false });
  const shareModalShow = () => setState({ ...state, shareModal: true });

  console.log("ANSWERSUBMITTED",answerSubmitted);

  useEffect(() => {
    if (user_id && qst_id && activeKey ) {
      getPracticeSubmittedAnswerList();
    }
  }, [qst_id, user_id, activeKey, redoCount]);

  useEffect(() => {
    // if(answerSubmitted){
    //   setState({...state, activeKey: "me"})
    // }
    console.log("ANSWERSUBMITTED",answerSubmitted);
    if(activeKey == "me"){
      getPracticeSubmittedAnswerList()
    }
    else if(answerSubmitted){
      setState((prev) => ({...prev,activeKey:"me"}))
    }
    console.log("ACTIVEKEY",activeKey);
    
    // if(answerSubmitted){
    //   setState((prev) => ({...prev,activeKey:"me"}))
    // }
    // else if(answerSubmitted == false){
    //   setState((prev) => ({...prev,activeKey:"all"}))
    // }
   
    // getPracticeSubmittedAnswerList()
    // setState({...state, activeKey: "me"})
    
  },[answerSubmitted])

  useEffect(() =>{
    if(answerSubmitted && answer_data?.length>0){

      
      myRef.current.scrollIntoView(true);
      console.log("CHLYA");
    }
  },[answer_data,answerSubmitted])

  const getPracticeSubmittedAnswerList = async () => {
    setState({ ...state, answer_data: [], current_qst_data: {} });
    await api
      .getPracticeSubmittedAnswerList(activeKey, qst_id, user_id)
      .then((res) => {
        if (res.status) {
          res.data?.length &&
            res.data[0]?.user_answer.length &&
            setState({
              ...state,
              answer_data: res.data[0]?.user_answer?.reverse(),
              current_qst_data: res.data[0]?.qst_id,
            });
        } else {
          setState({ ...state, answer_data: [], current_qst_data: {} });
        }
      })
      .catch((err) => console.log(err));
  };
  const[noShowOverlayTrigger,setnoShowOverlayTrigger] = useState(false)

  const handleClick = (event) => {

    var id = event.target.parentElement.id;
    var rnd = event.target.getAttribute("color");;
    console.log("IDDD",id);
    console.log("TRYINGID", trying[id]);
    console.log("rnd",rnd);
    console.log("IPHONE",matched_transcripts_ipa[id]);
    if(id !== "text-area"){
      setnoShowOverlayTrigger(true)
        setState({
          ...state,
          clicked_value_obj: {
            ...clicked_value_obj,
            start_time: api_res?.start_time[id],
            end_time: api_res?.end_time[id],
            msg:
              (rnd?.includes("red") && "Bad Pronunciation") || 
              (rnd?.includes("green") && "Good Pronunciation") ||
              (rnd?.includes("orange") && "Average pronunciation. Try to improve it") ||
              ("Pause Detected."),
              // (lettersOfWordAreCorrect[id] == "0" && "Good pronunciation") ||
              // (lettersOfWordAreCorrect[id] == "1" &&
              //   "Average pronunciation. Try to improve it") ||
              // (lettersOfWordAreCorrect[id] == "2" && "Bad pronunciation.")||
              // (lettersOfWordAreCorrect[id] == " " && "Pause Detected."),
            user_transcript: matched_transcripts_ipa[id],
            real_transcript: real_transcripts_ipa[id],
            original_word: current_qst_data?.question_content?.split(" ")[id],
          },
        });
    }
    else{
       setnoShowOverlayTrigger(false)
    }
  };

  const roundOff = (num) => {
    num = Number(num);
    return Math.floor(num * 100) / 100;
  };
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const start_time = Number(clicked_value_obj.start_time);
  const end_time = Number(clicked_value_obj.end_time);

  const handlePlay = () => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      audioElement.currentTime = start_time;
      audioElement.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      audioElement.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;

      if (currentTime >= end_time) {
        handlePause();
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [clicked_value_obj]);


  function setColorToTranscripts(original_transcript, user_transcript){
    let ele = "";
    for(var i=0;i<Math.min(original_transcript?.length,user_transcript?.length);i++){
      if(original_transcript[i] == user_transcript[i]){
        ele+=`<span><font color="green">${original_transcript[i]}</font></span>`
      }
      else{
        ele+=`<span><font color="red">${user_transcript[i]}</font></span>`
      }
    }
    console.log("ELECOLOR",ele)
    return ele
    console.log("ELECOLOR",ele)
  }

console.log('state323 :>> ', state);
  return (
    <div className="practice-tabs">
      <h5 ref={myRef} className="mb-4">Discussion</h5>
      <Tab.Container
        id="default-tabs-example"
      
        activeKey={activeKey}
        onSelect={(key) => setState({ ...state, activeKey: key })}
        defaultActiveKey="all"
      >
        <Nav variant="tabs" className="mb-3 gap-3">
          <Nav.Item id="0">
            <Nav.Link eventKey="Discussion-Tab">
              <img src="/images/practice-discussion.svg"></img>
              <span className="ms-2">Discussion</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id="1">
            <Nav.Link eventKey="all">
              <img src="/images/practice-board.svg"></img>
              <span className="ms-2">Board</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id="2">
            <Nav.Link eventKey="me">
              <img src="/images/practice-me.svg"></img>
              <span className="ms-2">Me</span>
            </Nav.Link>
          </Nav.Item>
          <div className="gap-3" style={{marginLeft:"auto"}}>
            <Button variant="outline-light" style={{marginRight:"0.7rem"}} size="sm" onClick={loaderModalShow}>
              AI Score Check
            </Button>
            <Button variant="primary" size="sm" onClick={CommentModalShow}>
              <Icon name="chat-circle"></Icon>
              <span>Comment</span>
            </Button>
          </div>
        </Nav>
        <Tab.Content>
          
          <Tab.Pane eventKey="Discussion-Tab">
            <div className="practice-comments py-3">
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <img
                    className="img-comment"
                    src="/images/listening-instructions.png"
                  ></img>
                </div>
                <small>
                  <b className="text-secondary">percy </b>
                  <span className="text-middark ms-1">2023-05-31</span>
                </small>
              </div>
              <div className="comment-container d-flex flex-column gap-3 mt-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-3 align-items-center">
                    <div>
                      <img
                        className="img-comment"
                        src="/images/listening-instructions.png"
                      ></img>
                    </div>
                    <small>
                      <b className="text-secondary">manpreet </b>
                      <span className="text-middark ms-1">2023-05-31</span>
                      <p className="text-middark">hlo percy</p>
                    </small>
                  </div>
                  <div className="d-flex gap-2">
                    <button className="btn btn-color-middark btn-hover-light btn-icon">
                      <img src="/images/threedot.svg"></img>
                    </button>
                    <button className="btn btn-color-middark btn-hover-light btn-icon">
                      <img src="/images/comment.svg"></img>
                    </button>
                    <div className="d-flex align-items-center ">
                      <button className="btn btn-color-middark btn-hover-light btn-icon">
                        <img src="/images/like.svg"></img>
                      </button>{" "}
                      <span className="text-middark">20</span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-3 align-items-center">
                    <div>
                      <img
                        className="img-comment"
                        src="/images/listening-instructions.png"
                      ></img>
                    </div>
                    <small>
                      <b className="text-secondary">manpreet </b>
                      <span className="text-middark ms-1">2023-05-31</span>
                      <p className="text-middark">hlo percy</p>
                    </small>
                  </div>
                  <div className="d-flex gap-2">
                    <button className="btn btn-color-middark btn-hover-light btn-icon">
                      <img src="/images/threedot.svg"></img>
                    </button>
                    <button className="btn btn-color-middark btn-hover-light btn-icon">
                      <img src="/images/comment.svg"></img>
                    </button>
                    <div className="d-flex align-items-center ">
                      <button className="btn btn-color-middark btn-hover-light btn-icon">
                        <img src="/images/like.svg"></img>
                      </button>{" "}
                      <span className="text-middark">20</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="all" className="board-tab">
            <Tab.Container
              id="pills-tabs-example"
              defaultActiveKey="Excellent-Ans"
            >
              <Nav variant="pills" className="gap-3 py-3">
                <Nav.Item>
                  <Nav.Link eventKey="Excellent-Ans">Excellent Ans</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="All-Answer">All Answer</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="Excellent-Ans">
                  <SingleAnswer
                    click_Score={false}
                    current_qst_data={current_qst_data}
                    scoreModalShow={scoreModalShow}
                    data={answer_data}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="All-Answer">
                  <SingleAnswer
                    click_Score={false}
                    current_qst_data={current_qst_data}
                    scoreModalShow={scoreModalShow}
                    data={answer_data}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Tab.Pane>
          <Tab.Pane eventKey="me">
            <SingleAnswer
              click_Score={true}
              answerSubmitted={answerSubmitted}
              current_qst_data={current_qst_data}
              scoreModalShow={scoreModalShow}
              data={answer_data}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <Modal size="lg" show={CommentModal} onHide={CommentModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            placeholder="Textarea Placeholder"
            id="exampleFormControlTextarea8"
            rows="3"
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer className="justify-content-between flex-nowrap gap-2">
          <Form.Control type="file" className="w-50 mx-0" />
          {/* <Button size="sm" variant="secondary" onClick={CommentModalClose}>
                        Close
                    </Button> */}
          <Button size="sm" variant="primary" onClick={CommentModalClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <PracticeResult
        answer_id={answer_id}
        current_qst_data={current_qst_data}
        wfdsModal={wfdsModal}
        fibwrModal={fibwrModal}
        answer={answerGivenByUser}
        swtsmodal={swtsmodal}
        AI_modal={AI_modal}
        swtsClose={() =>
          setState({
            ...state,
            swtsmodal: false,
            wfdsModal: false,
            fibwrModal: false,
          })
        }
        AIClose={() => setState({ ...state, AI_modal: false })}
      />

      {/* score modal for read_alouds */}
      <Modal size="xl" show={scoreModal} onHide={scoreModalClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>#{current_qst_data?.id} Score Info</Modal.Title>
        </Modal.Header>

        {(moduleName == 'read_alouds' || moduleName == 'retell_lectures' ) && <Modal.Body>
          <div className="d-flex gap-5 mt-3 mb-5">
            <div className="shadow d-flex gap-3 justify-content-center align-items-center p-3 w-50">
              {/* pronunciation_accuracy */}
              <Pureknob
                data={activeProject}
                key={activeProject.value}
                className="nk-chart-project-active"
                
              />
              <div className="d-flex gap-3 text-middark">
                <div className="d-flex flex-column">
                  <span>Content :</span>
                  <span>Pronun :</span>
                  <span>Fluency :</span>
                </div>
                <div className="d-flex flex-column">
                  <span>{roundOff(api_res?.content)}/90</span>
                  <span>{api_res?.pronun}/90</span>
                  <span>{roundOff(api_res?.fluency)}/90</span>
                </div>
              </div>
            </div>
            <div className="shadow d-flex gap-3 justify-content-center align-items-center p-3 w-50">
              {" "}
              {clicked_value_obj?.audioUrl && (
                <audio style={{    width: "100%"}} controls>
                  <source src={clicked_value_obj?.audioUrl}></source>
                </audio>
              )}{" "}
            </div>
          </div>
          <div className="d-flex gap-5 mb-5">
            <div className="d-flex flex-column w-50">
              <h5>Skill Analysis 1234</h5>
              <p className="text-middark">
                The following scores show how you have performed in different
                aspects as compared to other users. These scores can be used to
                analyse how you can improve in this question, but they are not
                directly related to the PTE scores.
              </p>
              <div className="shadow d-flex gap-3 justify-content-center align-items-center p-3 mt-5">
                <div className="d-flex flex-column gap-3 text-middark w-100">
                  <div className="d-flex gap-3 align-items-center">
                    <span className="w-25">Pronunciation Accuracy</span>
                    <ProgressBar
                      now={api_res?.pronunciation_accuracy}
                      className="w-50"
                    />
                    <span>{roundOff(api_res?.pronunciation_accuracy)}%</span>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <span className="w-25">Fluent</span>
                    <ProgressBar now={api_res?.fluent} className="w-50" />
                    <span>{roundOff(api_res?.fluent)}%</span>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <span className="w-25">Stress</span>
                    <ProgressBar now={100} className="w-50" />
                    <span>100%</span>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <span className="w-25">Speed</span>
                    {api_res?.speed > 100 ? (
                      <>
                        <ProgressBar
                          now={api_res?.speed}
                          variant="warning"
                          className="w-50"
                        />
                        <Badge bg="warning">{roundOff(api_res?.speed)}%</Badge>
                      </>
                    ) : (
                      <>
                        <ProgressBar now={api_res?.speed} className="w-50" />{" "}
                        <span>{roundOff(api_res?.speed)}%</span>
                      </>
                    )}
                  </div>
                  {api_res?.speed > 100 && (
                    <small className="text-end">
                      * you may have spoken too fast
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="w-50">
              <Tab.Container
                id="custom-tabs-example"
                defaultActiveKey="custom-home"
              >
                <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="custom-home">
                      Pronunciation Accuracy
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="custom-home">
                    {loader_modal && (
                      <div>
                        <div className="ml-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    <div
                      className="container-small flex expand"
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {/* <p
                        id="single_word_ipa_pair"
                        className="expand ipa-text-small"
                        style={{ textAlign: "center" }}
                      ></p> */}
                    </div>

                    <img src="/images/ai_accuracy.svg" alt="Accuracy Stats" className="mb-2" />

                    {/* TRYING TO OPEN FOR EVERY WORD */}
                    {console.log("TRYING",trying)}
                    {console.log("clickedVal",clicked_value_obj)}
                    <div id="text-area" className="main-text-div">
                      {trying.length > 0 && trying.map((ele, key) => {
                      
                        return <> <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose 
                            overlay={
                              <Popover id="popover-positioned-top" >
                             { noShowOverlayTrigger &&  <Popover.Header as="h3">
                                  Hilingo Alert
                                </Popover.Header>}
                                <Popover.Body>
                                    {noShowOverlayTrigger &&(
                                      <>
                                      <p>{clicked_value_obj.msg}</p>
                                  <p>{clicked_value_obj.msg?.includes("Pause Detected") ? "/" : parse(ele)}</p>
                                  
                                  {/* not to display audio icon if pause is Detected in transcript */}
                                  {!clicked_value_obj?.msg?.includes("Pause Detected.") &&
                                    <>
                                      <p>
                                      [Yours]  {parse(setColorToTranscripts(clicked_value_obj?.real_transcript, clicked_value_obj?.user_transcript))}
                                      {/* [Yours] {clicked_value_obj?.user_transcript} */}
                                    </p>
                                    <p>
                                      [Org] / {clicked_value_obj?.real_transcript} /{" "}
                                      <img
                                        src="/images/headphone.svg"
                                        style={{ width: "20px", marginLeft: "5px" }}
                                        onClick={() => playWithMozillaApi(clicked_value_obj?.original_word)}
                                      ></img>
                                       </p>
                                    <audio
                                      ref={audioRef}
                                      src={clicked_value_obj?.audioUrl}
                                      
                                    />
                                    </>
                                  }
                                  
                                      </>
                                    )} 
                                  
                                  {/* <p>[US]/ˈwɔtɚ, ˈwɑtɚ/ <img src="/images/headphone.svg" style={{width:"20px",marginLeft:"5px"}}></img></p> */}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <p
                              style={{ cursor: "pointer", display:"inline", fontFamily:"Roboto" }}
                              id="original_script"
                              onClick={handleClick}
                              className=" bigger-text text-primary main-text "
                              
                            >
                              {parse(ele)}
                            </p>
    
                            
                          </OverlayTrigger>
                          </>
                    })}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Modal.Body>}
        
        { (moduleName == 'repeat_sentences' || moduleName == 'answer_questions'|| moduleName == 'swts' || moduleName == 'essays'|| moduleName == 'ssts') && <Modal.Body >
        <div className="w-100">
            <div className="mb-4 comment-table">
              {/* <table class="table table-bordered"> */}
              <Table responsive className="medium mb-0">

                <thead className="">
                  <tr className="table-light">
                    <th className="text-start text-secondary" scope="col">Component</th>
                    <th className="text-start text-secondary" scope="col">Score</th>
                    <th className="text-start text-secondary" scope="col">Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="text-start text-middark" scope="row">Content</td>
                    <td className="text-start text-middark">{api_res?.content}/90</td>
                    <td className="text-start text-middark">Give one More Try</td>
                  </tr>
                  <tr>
                    <td className="text-start text-middark" scope="row">Pronunciation</td>
                    <td className="text-start text-middark">{api_res?.pronunciation_accuracy}/90</td>
                    <td className="text-start text-middark">Speak Slow</td>
                  </tr>
                  <tr>
                    <td className="text-start text-middark" scope="row">Fluency</td>
                    <td className="text-start text-middark">{api_res?.fluency}/90</td>
                    <td className="text-start text-middark">Speak Continuous</td>
                  </tr>
                  <tr className="table-light">
                    <td colSpan={3} style={{textAlign:"left"}} className="text-secondary">Max Score : 90, &nbsp;Your Score :{"  "} <span className="text-danger fw-bold">{api_res?.total_score}</span></td>
                  </tr>
                </tbody>
              
              </Table> 
            </div>

            <div className="w-30">
              {" "}
              {clicked_value_obj?.audioUrl && (
                <audio style={{    width: "100%"}} controls>
                  <source src={clicked_value_obj?.audioUrl}></source>
                </audio>
              )}{" "}
            </div>
          <Tab.Container
            id="custom-tabs-example"
            defaultActiveKey="custom-home"
          >
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="custom-home">
                    AI Speech Recognition
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <img src="/images/ai_accuracy_without_bottom.svg" alt="Accuracy Stats" className="mb-2" />
            </div>
            
            <Tab.Content>
              <Tab.Pane eventKey="custom-home">
                {loader_modal && (
                  <div>
                    <div className="ml-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                <div
                  className="container-small flex expand"
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <p
                    id="single_word_ipa_pair"
                    className="expand ipa-text-small"
                    style={{ textAlign: "center" }}
                  ></p> */}
                </div>

                

                {/* TRYING TO OPEN FOR EVERY WORD */}
                {console.log("TRYING",trying)}
                {console.log("clickedVal",clicked_value_obj)}
                <div id="text-area" className="main-text-div">
                  {trying.length > 0 && trying.map((ele, key) => {
                  
                    return <> <OverlayTrigger
                        trigger="click"
                        placement="top"
                        rootClose 
                        overlay={
                          <Popover id="popover-positioned-top" >
                         { noShowOverlayTrigger &&  <Popover.Header as="h3">
                              Hilingo Alert
                            </Popover.Header>}
                            <Popover.Body>
                                {noShowOverlayTrigger &&(
                                  <>
                                  <p>{clicked_value_obj.msg}</p>
                              <p>{clicked_value_obj.msg?.includes("Pause Detected") ? "/" : parse(ele)}</p>
                              
                              {/* not to display audio icon if pause is Detected in transcript */}
                              {!clicked_value_obj?.msg?.includes("Pause Detected.") &&
                                <>
                                  <p>
                                  [Yours]  {parse(setColorToTranscripts(clicked_value_obj?.real_transcript, clicked_value_obj?.user_transcript))}
                                  {/* [Yours] {clicked_value_obj?.user_transcript} */}
                                </p>
                                <p>
                                  [Org] / {clicked_value_obj?.real_transcript} /{" "}
                                  <img
                                    src="/images/headphone.svg"
                                    style={{ width: "20px", marginLeft: "5px" }}
                                    onClick={() => playWithMozillaApi(clicked_value_obj?.original_word)}
                                  ></img>
                                   </p>
                                <audio
                                  ref={audioRef}
                                  src={clicked_value_obj?.audioUrl}
                                  
                                />
                                </>
                              }
                              
                                  </>
                                )} 
                              
                              {/* <p>[US]/ˈwɔtɚ, ˈwɑtɚ/ <img src="/images/headphone.svg" style={{width:"20px",marginLeft:"5px"}}></img></p> */}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <p
                          style={{ cursor: "pointer", display:"inline", fontFamily:"Roboto" }}
                          id="original_script"
                          onClick={handleClick}
                          className=" bigger-text text-primary main-text "
                          
                        >
                          {parse(ele)}
                        </p>

                        
                      </OverlayTrigger>
                      </> 
                })}
                </div>
                <div className="text-secondary" style={{display:"flex", justifyContent:"center", marginTop:"1rem"}}>* Click on the colored text to check score details</div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
    </Modal.Body>}

      {/* FOR ANSWER_QUESTIONS */}
      {(path.includes("/practice/pte/answer_questions")) && <Modal.Body >
        <div className="w-100">
            <div className="mb-4 comment-table">
              {/* <table class="table table-bordered"> */}
              <Table responsive className="medium mb-0">

                <thead className="">
                  <tr className="table-light ">
                    <th className="text-start" scope="col">Component</th>
                    <th className="text-start" scope="col">Score</th>
                    <th className="text-start" scope="col">Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="text-start" scope="row">Content</td>
                    <td className="text-start">{api_res?.total_score}/1</td>
                    <td className="text-start">{api_res?.total_score == 1 ? "Correct" : "Inaccurate pronunciation could also result in loss of marks."}</td>
                  </tr>
                  <tr className="table-light">
                    <td colSpan={3} style={{textAlign:"left"}}>Max Score : 1, &nbsp;Your Score :{"  "} <span className="text-danger fw-bold">{api_res?.total_score}</span></td>
                  </tr>
                </tbody>
              
              </Table> 
            </div>

            <div className="w-30">
              {" "}
              {clicked_value_obj?.audioUrl && (
                <audio style={{    width: "100%"}} controls>
                  <source src={clicked_value_obj?.audioUrl}></source>
                </audio>
              )}{" "}
            </div>
          <Tab.Container
            id="custom-tabs-example"
            defaultActiveKey="custom-home"
          >
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="custom-home">
                    AI Speech Recognition
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <img src="/images/ai_accuracy_without_bottom.svg" alt="Accuracy Stats" className="mb-2" /> */}
            </div>
            
            <Tab.Content>
              <Tab.Pane eventKey="custom-home">
                {loader_modal && (
                  <div>
                    <div className="ml-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                <div
                  className="container-small flex expand"
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <p
                    id="single_word_ipa_pair"
                    className="expand ipa-text-small"
                    style={{ textAlign: "center" }}
                  ></p> */}
                </div>

                

                {/* TRYING TO OPEN FOR EVERY WORD */}
                {console.log("TRYING",trying)}
                {console.log("clickedVal",clicked_value_obj)}
                <div id="text-area" className="main-text-div">
                  {api_res?.mapped_list?.map((ele) => {
                    return <span className="text-black">{ele} </span>
                  })}
                </div>
                {/* <div className="text-black" style={{display:"flex", justifyContent:"center", marginTop:"1rem"}}>* Click on the colored text to check score details</div> */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
    </Modal.Body>}

    {/* FOR ESSAYS  */}
    {(path.includes("/practice/pte/essays")) && <Modal.Body >
        <div className="w-100">
            <div className="mb-4 comment-table">
              {/* <table class="table table-bordered"> */}
              <Table responsive className="medium mb-0">

                <thead className="">
                  <tr className="table-light ">
                    <th className="text-start" scope="col">Component</th>
                    <th className="text-start" scope="col">Score</th>
                    <th className="text-start" scope="col">Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="text-start" scope="row">Content</td>
                    <td className="text-start">{api_res?.content_score}/3</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Form</td>
                    <td className="text-start">{api_res?.form_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Grammar</td>
                    <td className="text-start">{api_res?.grammar_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Spelling</td>
                    <td className="text-start">{api_res?.spelling}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Vocabulary range</td>
                    <td className="text-start">{api_res?.vocabulary_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">General linguistic range</td>
                    <td className="text-start">{api_res?.linguistic_range}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Development, structure and coherence</td>
                    <td className="text-start">{api_res?.coherence_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="table-light">
                    <td colSpan={3} style={{textAlign:"left"}}>Max Score : {api_res?.max_score}, &nbsp;Your Score :{"  "} <span className="text-danger fw-bold">{api_res?.total_score}</span></td>
                  </tr>
                </tbody>
              
              </Table> 
            </div>

            <div className="w-30">
              {" "}
              {clicked_value_obj?.audioUrl && (
                <audio style={{    width: "100%"}} controls>
                  <source src={clicked_value_obj?.audioUrl}></source>
                </audio>
              )}{" "}
            </div>
          <Tab.Container
            id="custom-tabs-example"
            defaultActiveKey="custom-home"
          >
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="custom-home">
                    AI Speech Recognition
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <img src="/images/ai_accuracy_without_bottom.svg" alt="Accuracy Stats" className="mb-2" /> */}
            </div>
            
            <Tab.Content>
              <Tab.Pane eventKey="custom-home">
                {loader_modal && (
                  <div>
                    <div className="ml-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                <div
                  className="container-small flex expand"
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <p
                    id="single_word_ipa_pair"
                    className="expand ipa-text-small"
                    style={{ textAlign: "center" }}
                  ></p> */}
                </div>

                

                {/* TRYING TO OPEN FOR EVERY WORD */}
                {console.log("SPLITTED",api_res?.mapped_list?.split(" "))}
                <div id="text-area" className="main-text-div text-black">
                  <span>{api_res?.mapped_list?.split(" ")?.filter((e) => e)?.map((word,index) => {
                    return (
                      api_res?.grammar?.filter((ele) => ele.index == index).length>0 ? 
                      <OverlayTrigger trigger="hover" placement="top" overlay={
                        <Popover id="popover-positioned-top">
                            <Popover.Header as="h3">{api_res?.grammar?.filter((ele) => ele.index == index)[0]?.errorCategory} ERROR</Popover.Header>
                            <Popover.Body>
                                <strong>{api_res?.grammar?.filter((ele) => ele.index == index)[0]?.message}</strong> Check this info.
                            </Popover.Body>
                        </Popover>
                      }>
                        <span style={{color:"red"}}>{word} </span>
                      </OverlayTrigger> 
                      : 
                      word + " "
                    )
                  })}</span>
                  
                </div>
                <div className="text-black" style={{display:"flex", justifyContent:"center", marginTop:"1rem"}}>* Hover on the colored text to check error details</div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
    </Modal.Body>}
    
    {/* for SWTS */}
    {(path.includes("/practice/pte/swts")) && <Modal.Body >
        <div className="w-100">
            <div className="mb-4 comment-table">
              {/* <table class="table table-bordered"> */}
              <Table responsive className="medium mb-0">

                <thead className="">
                  <tr className="table-light ">
                    <th className="text-start" scope="col">Component</th>
                    <th className="text-start" scope="col">Score</th>
                    <th className="text-start" scope="col">Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="text-start" scope="row">Content</td>
                    <td className="text-start">{api_res?.content_score}/3</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Form</td>
                    <td className="text-start">{api_res?.form_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Grammar</td>
                    <td className="text-start">{api_res?.grammar_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Vocabulary</td>
                    <td className="text-start">{api_res?.vocabulary_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="table-light">
                    <td colSpan={3} style={{textAlign:"left"}}>Max Score : {api_res?.max_score}, &nbsp;Your Score :{"  "} <span className="text-danger fw-bold">{api_res?.total_score}</span></td>
                  </tr>
                </tbody>
              
              </Table> 
            </div>

            <div className="w-30">
              {" "}
              {clicked_value_obj?.audioUrl && (
                <audio style={{    width: "100%"}} controls>
                  <source src={clicked_value_obj?.audioUrl}></source>
                </audio>
              )}{" "}
            </div>
          <Tab.Container
            id="custom-tabs-example"
            defaultActiveKey="custom-home"
          >
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="custom-home">
                    AI Speech Recognition
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <img src="/images/ai_accuracy_without_bottom.svg" alt="Accuracy Stats" className="mb-2" /> */}
            </div>
            
            <Tab.Content>
              <Tab.Pane eventKey="custom-home">
                {loader_modal && (
                  <div>
                    <div className="ml-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                <div
                  className="container-small flex expand"
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <p
                    id="single_word_ipa_pair"
                    className="expand ipa-text-small"
                    style={{ textAlign: "center" }}
                  ></p> */}
                </div>

                

                {/* TRYING TO OPEN FOR EVERY WORD */}
                {console.log("SPLITTED",api_res?.mapped_list?.split(" "))}
                <div id="text-area" className="main-text-div text-black">
                  <span>{api_res?.mapped_list?.split(" ")?.filter((e) => e)?.map((word,index) => {
                    return (
                      api_res?.grammar?.filter((ele) => ele.index == index).length>0 ? 
                      <OverlayTrigger trigger="hover" placement="top" overlay={
                        <Popover id="popover-positioned-top">
                            <Popover.Header as="h3">{api_res?.grammar?.filter((ele) => ele.index == index)[0]?.errorCategory} ERROR</Popover.Header>
                            <Popover.Body>
                                <strong>{api_res?.grammar?.filter((ele) => ele.index == index)[0]?.message}</strong> Check this info.
                            </Popover.Body>
                        </Popover>
                      }>
                        <span style={{color:"red"}}>{word} </span>
                      </OverlayTrigger> 
                      : 
                      word + " "
                    )
                  })}</span>
                  
                </div>
                <div className="text-black" style={{display:"flex", justifyContent:"center", marginTop:"1rem"}}>* Hover on the colored text to check error details</div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
    </Modal.Body>}

    {/* for SSTS */}
    {(path.includes("/practice/pte/ssts")) && <Modal.Body >
        <div className="w-100">
            <div className="mb-4 comment-table">
              {/* <table class="table table-bordered"> */}
              <Table responsive className="medium mb-0">

                <thead className="">
                  <tr className="table-light ">
                    <th className="text-start" scope="col">Component</th>
                    <th className="text-start" scope="col">Score</th>
                    <th className="text-start" scope="col">Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="text-start" scope="row">Content</td>
                    <td className="text-start">{api_res?.content_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Form</td>
                    <td className="text-start">{api_res?.form_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Grammar</td>
                    <td className="text-start">{api_res?.grammar_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Spelling</td>
                    <td className="text-start">{api_res?.spelling_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="">
                    <td className="text-start" scope="row">Vocabulary</td>
                    <td className="text-start">{api_res?.vocabulary_score}/2</td>
                    <td className="text-start">Average</td>
                  </tr>
                  <tr className="table-light">
                    <td colSpan={3} style={{textAlign:"left"}}>Max Score : {api_res?.max_score}, &nbsp;Your Score :{"  "} <span className="text-danger fw-bold">{api_res?.total_score}</span></td>
                  </tr>
                </tbody>
              
              </Table> 
            </div>

            <div className="w-30">
              {" "}
              {clicked_value_obj?.audioUrl && (
                <audio style={{    width: "100%"}} controls>
                  <source src={clicked_value_obj?.audioUrl}></source>
                </audio>
              )}{" "}
            </div>
          <Tab.Container
            id="custom-tabs-example"
            defaultActiveKey="custom-home"
          >
            <div style={{display:"flex",justifyContent:"space-between"}}>
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="custom-home">
                    AI Speech Recognition
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <img src="/images/ai_accuracy_without_bottom.svg" alt="Accuracy Stats" className="mb-2" /> */}
            </div>
            
            <Tab.Content>
              <Tab.Pane eventKey="custom-home">
                {loader_modal && (
                  <div>
                    <div className="ml-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
                <div
                  className="container-small flex expand"
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <p
                    id="single_word_ipa_pair"
                    className="expand ipa-text-small"
                    style={{ textAlign: "center" }}
                  ></p> */}
                </div>

                

                {/* TRYING TO OPEN FOR EVERY WORD */}
                {console.log("SPLITTED",api_res?.mapped_list?.split(" "))}
                <div id="text-area" className="main-text-div text-black">
                  <span>{api_res?.mapped_list?.split(" ")?.filter((e) => e)?.map((word,index) => {
                    return (
                      api_res?.grammar?.filter((ele) => ele.index == index).length>0 ? 
                      <OverlayTrigger trigger="hover" placement="top" overlay={
                        <Popover id="popover-positioned-top">
                            <Popover.Header as="h3">{api_res?.grammar?.filter((ele) => ele.index == index)[0]?.errorCategory} ERROR</Popover.Header>
                            <Popover.Body>
                                <strong>{api_res?.grammar?.filter((ele) => ele.index == index)[0]?.message}</strong> Check this info.
                            </Popover.Body>
                        </Popover>
                      }>
                        <span style={{color:"red"}}>{word} </span>
                      </OverlayTrigger> 
                      : 
                      word + " "
                    )
                  })}</span>
                  
                </div>
                <div className="text-black" style={{display:"flex", justifyContent:"center", marginTop:"1rem"}}>* Hover on the colored text to check error details</div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
    </Modal.Body>}

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={scoreModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      {console.log("SCOREMODAL",scoreModal)}
     


      <div className="">
        <Modal
          show={shareModal}
          onHide={shareModalClose}
          className="show-backdrop"
        >
          <Modal.Header closeButton>
            <Modal.Title>Answer Link Created</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-middark">
            <h5>
              Send the following link to your friends to share your answer!
            </h5>
            <p>RA #306 Score Info AI Score 73/90</p>
            <p>Share URL </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-between flex-nowrap gap-2">
            <Button size="sm" variant="secondary" onClick={shareModalClose}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" onClick={shareModalClose}>
              Copy Link
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PracticePteCommentsNew;
