
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../../components/Form/DropDown";
import Context from "../../context";
import InstBranchesApi from "../../api/branches/branches";

const UpdateEnquiryModal = ({
  teachers,
  setShow,
  show,
  isEdit,
  setIsEdit,
  setEnquiry,
  editId,
  enquiry,
  get_enquiriesArr,
  setEditId
}) => {
  let navigate = useNavigate();
  console.log(teachers, "teachers");
  console.log(setShow, "setShow123");
  let api = new AuthApi();

  console.log(enquiry, "enquiry32232");
  const [state, setState] = useState({
    data: {
      id: enquiry?.[0]?._id ? enquiry?.[0]?._id : "",
      name: enquiry?.[0]?.name ? enquiry?.[0]?.name : "",
      email: enquiry?.[0]?.email ? enquiry?.[0]?.email : "",
      phone: enquiry?.[0]?.phone ? enquiry?.[0]?.phone : "",
      enquiry: enquiry?.[0]?.enquiry ? enquiry?.[0]?.enquiry : "",
      gender: enquiry?.[0]?.gender ? enquiry?.[0]?.gender : "",
      program: enquiry?.[0]?.program ? enquiry?.[0]?.program : "",
      nextFollowUpDate: enquiry?.[0]?.nextFollowUpDate
        ? enquiry?.[0]?.nextFollowUpDate
        : "",
      teamMember: enquiry?.[0]?.teamMember ? enquiry?.[0]?.teamMember : "",
      mode: enquiry?.[0]?.mode ? enquiry?.[0]?.mode : "",
      reference: enquiry?.[0]?.reference ? enquiry?.[0]?.reference : "",
      branchName: enquiry?.[0]?.branchName ? enquiry?.[0]?.branchName : "",
      
    },
    errors: {},
  });

  let DropDownWidth = { width: "100%" };
  const handleClose = () => {
    if (Object.keys(enquiry).length > 0) {
      setEnquiry({});
      setEditId("")
    }
    setState({
      data: {},
      errors: {},
    });
  };

  let { userData, token } = useContext(Context);
  userData = JSON.parse(userData);

  let instBranchesApi = new InstBranchesApi();

  const [branches, setBranches] = useState([]);



  const get_branch_by_inst_id = async (instId) => {
    console.log("instid", instId, token);
    await instBranchesApi
      .get_branch_by_inst_id( instId)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setBranches(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    if (userData?._id && token) {

      get_branch_by_inst_id(userData?._id )
    
    }
  }, []);
  
  const Instbranches= branches?.map((item) => ({name:item?.name.toLowerCase(),id:item?._id}));

  const { data, errors } = state;
  
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...prev.errors,
        [e.target.name]: "",
      },
    }));

    if(name == "branchName"){
      const selectedOption = e?.target?.selectedOptions?.[0];
      const selectedId = selectedOption.id;
      console.log(name, value,selectedId, "naem...");

      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          branch_id: selectedId,
        },
       
      }));

    }
  };

  console.log(data, "data11121232");
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.name);
    if (!data?.name?.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(data.name)) {
      newErrors.name =
        "Name should be 3-16 characters and shouldn't include any special character!";
    }
    if (!data?.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
        data.email
      )
    ) {
      newErrors.email = "Email must be valid address eg: me@mydomain.com";
    }
    // if (!data?.phone?.trim()) {
    //   newErrors.phone = "Phone is required";
    // } else
    
    if (data.phone && data.phone.length !== 10) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    // if (!data?.enquiry?.[0]?.trim()) {
    //   newErrors.enquiry = "Enquiry is required";
    // }
    // if (!data?.gender?.trim()) {
    //   newErrors.gender = "Gender is required";
    // }
    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");
      await api
        .add_enquiry(data)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            // setShow(false);
            get_enquiriesArr()
            // navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };

  console.log(enquiry, "enquiry123");
  console.log(Object.keys(enquiry).length, "data###");
  return (
    <>

      <Modal
        size="lg"
        show={Object.keys(enquiry).length > 0}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="6">
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="title">Student Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      required
                      type="text"
                      id="name"
                      placeholder="Student Name"
                      name="name"
                      value={data.name}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Phone Number</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="tel"
                      maxLength={10}
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      // value={data.phone}
                      value={data.phone?.length < 11 ? data.phone : ""}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="mode">Select Mode</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="mode"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="mode"
                      value={data.mode}
                    >
                      <option value="">Select</option>
                      <option value="Telephonic">Telephonic</option>
                      <option value="Walk-in">Walk-in</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="gender">Select Gender </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      required
                      id="gender"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="gender"
                      value={data?.gender}
                      isInvalid={!!errors.gender}
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <DropDown
                  className="w-25 mb-3"
                  data={teachers}
                  defaultSelectedOption={"Select"}
                  text="Team Member Name"
                  name="teamMember"
                  onChange={handleChange}
                  value={data.teamMember}
                  width={DropDownWidth}
                />

                
<Form.Group className="form-group mt-3">
        <Form.Label>Branch</Form.Label>
        <div className="form-control-wrap me-2" style={DropDownWidth}>
          <Form.Select
            removeItemButton
            value={data?.branchName}
            name={"branchName"}
            onChange={handleChange}
            isInvalid={!!errors.branchName}
          >
           
           
            <option value="">{"Select"}</option>
        
            {Instbranches?.length > 0 &&
              Instbranches.map((item) => {
              ;
                return (
                  <>
                    <option value={item?.name}  id={item?.id} key={item?.id}>
                      { item?.name}
                    </option>
                  </>
                );
              })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                      {errors.branchName}
                    </Form.Control.Feedback>
        </div>
      </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="email">Email Id</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="email"
                      id="emailid"
                      name="email"
                      placeholder="Email Id"
                      value={data.email}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="program">Select Program</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="program"
                      aria-label="Default select example"
                      value={data.program}
                      onChange={(e) => handleChange(e)}
                      name="program"
                    >
                      <option value="">Select</option>
                      <option value="Pte">PTE</option>
                      <option value="Pte core">PTE CORE</option>
                      <option value="Ilets">ILETS</option>
                      <option value="Cd Ielts">CD IELTS</option>
                      <option value="Toefl">TOEFL</option>
                      <option value="Cale">CALE</option>
                      <option value="Celpip">CELPIP</option>
                      <option value="Grammer">GRAMMER</option>
                      <option value="Spoken English">SPOKEN ENLGISH</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="reference">
                    Select Reference Medium
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="reference"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="reference"
                      value={data.reference}
                    >
                      <option value="">Select</option>
                      <option value="Friend Reference">Friend Reference</option>
                      <option value="Direct Phone Call">
                        Direct Phone Call
                      </option>
                      <option value="Direct Walk In">Direct Walk In</option>
                      <option value="Existing Student Reference">
                        Existing Student Reference
                      </option>
                      <option value="Team Memeber Reference">
                        Team Memeber Reference
                      </option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Newspaper">Newspaper</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Google">Google</option>
                      <option value="Webstie">Webstie</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Next FollowUp Date</Form.Label>
                  <div className="form-control-wrap">
                    <DatePickerOnChange
                      onChange={(e) => handleChange(e)}
                      name={"nextFollowUpDate"}
                      value={data.nextFollowUpDate}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Comment(Optional)</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      as="textarea"
                      placeholder="Enquiry"
                      name="enquiry"
                      id="enquiry"
                      rows="5"
                      value={data.enquiry}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.enquiry}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.enquiry}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col md="12" className="d-flex justify-content-center"></Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update Equiry
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateEnquiryModal;
