import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import AuthApi from "../../api/auth";
function BranchFilter({
  allData,
  tableData,
  setData,
  showItemPerPage,
  setShowPage,
  currentFilterName,

}) {
  console.log("tableData", tableData,allData);

  const uniqueBatchValues = new Set();
  const Batch = allData

    .map((item) => {
      const value = `${item?.starttime} to ${item?.endtime}`;
    
      const obj = { value};
      const stringifiedObj = JSON.stringify(obj);
      if (!uniqueBatchValues.has(stringifiedObj)) {
        uniqueBatchValues.add(stringifiedObj);
        return obj;
      }
      return null;
    })
    ?.filter(Boolean);

    console.log('uniqueBatchValuesBatch :>> ', Batch,uniqueBatchValues);

  const [filterDataObj, setFilterDatObj] = useState({});


  const handleDateChange = (e) => {
    let { value, name } = e.target;
    console.log('value,name :>> ', value,name);
   
      setFilterDatObj({ ...filterDataObj, [name]: value });
   
  };

  let {
    
    branchName,
   
    location,
    address
    
  } = filterDataObj;

  console.log('filterData :>> ', filterDataObj);
  const filterData = async () => {
    
    const filteredData = tableData?.filter((item) => {
     
      return (
        
        (!branchName ||
          item?.name.toLowerCase().includes(branchName?.toLowerCase()?.trim())) &&
        
          (!location ||
            item?.location?.toLowerCase().includes(location?.toLowerCase()?.trim())) &&

        (!address ||
          item?.address?.toLowerCase().includes(address?.toLowerCase()?.trim()))
      
        
      );

    });
    setData(filteredData);

    console.log("filteredData", filteredData);
  };
  let api = new AuthApi();

  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
  }, []);

  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        if (res.data.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("Batch", Batch);
  console.log('currentFilterName :>> ', currentFilterName);
  console.log("filterDataObj", filterDataObj);
  console.log('hellokHar :>> ', filterDataObj?.teacher);
  return (
    <>
      <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2">
            <select
              className="form-select"
              onChange={(e) => setShowPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>
   
        <Form.Group className="form-group w-20 mb-3">
          <Form.Label>Branch Name</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="filter"
              placeholder="Search By Branch Name "
              value={filterDataObj?.branchName}
              autoComplete="on"
              name="branchName"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        <Form.Group className="form-group w-20 mb-3">
        <Form.Label>Address</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="address"
              placeholder="Search By Address  "
              value={filterDataObj?.address}
              autoComplete="off"
              name="address"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        <Form.Group className="form-group w-20 mb-3">
        <Form.Label>Location</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="filter"
              placeholder="Search By Location  "
              value={filterDataObj?.location}
              autoComplete="off"
              name="location"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

       
        

        
        
       

        <Form.Group className="form-group w-20 ms-2 mb-3">
          <Form.Label
            htmlFor="exampleFormControlInputText5"
            style={{ visibility: "hidden" }}
          >
            Filter Reset
          </Form.Label>
          <div className="form-control-wrap d-flex me-2">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObj({
                  ...filterDataObj,
                  
                  address: "",
                 
                  location:"",
                  branchName:"",
                  
                });

                setData(allData);
               
                
                
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" onClick={filterData}  style={{
                                          backgroundColor: "var(--theme-color)",
                                          cursor: "pointer",
                                            border:"var(--theme-color)"
                                        }}>
              Search
            </Button>
          </div>
        </Form.Group>
      </div>
    </>
  );
}

export default BranchFilter;


