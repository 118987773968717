import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Nav, Table, CloseButton } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Image } from "../../components";
import FileUpload from '../../components/Form/FileUpload';

function AssignTest() {
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Assign Test</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <Card.Body>

            <Tab.Container id="assign-tabs" defaultActiveKey="batch-test">
              <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                <Nav.Item>
                  <Nav.Link eventKey="batch-test">Batch</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="student-test">Student</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="batch-test">
                  <Tab.Container id="batch-tabs" defaultActiveKey="batch-details">
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="batch-details">Batch Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="test-details">Test Details</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="batch-details">
                        <Row className="g-gs my-3">
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="program-text">Program</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control type="text" id="program-text" value="PTE" disabled />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText5">Choose Plan</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Select id="exampleFormControlInputText5" aria-label="Default select example">
                                  <option value="0">Choose Plan</option>
                                  <option value="1">Free</option>
                                  <option value="2">VIP 30 Days</option>
                                  <option value="3">VIP 90 Days</option>
                                  <option value="4">VIP 180 Days</option>
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="program-text">Program</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control type="text" id="program-text" value="PTE" disabled />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText5">Student Type</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Select id="exampleFormControlInputText5" aria-label="Default select example">
                                  <option value="0">Choose Student Type</option>
                                  <option value="1">Single Test</option>
                                  <option value="2">Bulk Test</option>
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <ButtonGroup aria-label="Basic radio toggle button group" className="hilingo-radio-button gap-3">
                              <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                              <Button as="label" variant="outline-primary" htmlFor="btnradio3" className="flex-column">PTE_DEFAULT <p>(11:00 AM 11:01 AM)</p></Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <h5>BATCH DETAILS</h5>
                            {/* You can use data list component */}
                            <Table responsive striped bordered hover className="small">
                              <thead>
                                <tr className="bg-primary">
                                  <th className="text-white">Batch Name</th>
                                  <th className="text-white">Teacher</th>
                                  <th className="text-white">No. of Students</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>PTE_default</td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                      <Form.Check label="" type="checkbox" id="inlineCheckbox1"></Form.Check>
                                      <span> Teacher</span>
                                      <Button variant="transparent"><Icon name="search"></Icon></Button>
                                    </div>
                                  </td>
                                  <td>0/0</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                      <Form.Check label="" type="checkbox" id="inlineCheckbox1"></Form.Check>
                                      <span> Teacher</span>
                                      <Button variant="transparent"><Icon name="search"></Icon></Button>
                                    </div>
                                  </td>
                                  <td>0/0</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>Total Students</td>
                                  <td>1</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="test-details">
                        <Tab.Container id="assign-tabs" defaultActiveKey="sectionwise-test">
                          <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                            <Nav.Item>
                              <Nav.Link eventKey="sectionwise-test">Section Wise Test</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="mock-test">Mock Test</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="sectionwise-test">
                            {/* You can use data list component */}
                              <Table responsive striped bordered hover className="small">
                                <thead>
                                  <tr className="bg-primary">
                                    <th></th>
                                    <th className="text-white">Test Name</th>
                                    <th className="text-white">Module</th>
                                    <th className="text-white">Category Name</th>
                                    <th className="text-white">Level</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-01(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-02(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-03(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-04(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 01</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 02</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 03</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 04</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Hard</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Tab.Pane>
                            <Tab.Pane eventKey="mock-test">
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane>

                <Tab.Pane eventKey="student-test">
                  <Tab.Container id="student-tabs" defaultActiveKey="select-student">
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="select-student">Select Student</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="student-test-details">Test Details</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="select-student">
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <h5>SELECT STUDENT</h5>
                            <Form.Group className="form-group my-3">
                              <div className="form-control-wrap">
                                <div className="form-control-icon end"><Icon name="search"></Icon></div>
                                <Form.Control type="text" placeholder="Student Name" />
                              </div>
                            </Form.Group>
                            {/* You can use data list component */}
                            <Table responsive striped bordered hover className="small">
                              <thead>
                                <tr className="bg-primary">
                                  <th className="text-white">Sr No.</th>
                                  <th className="text-white">Student Name</th>
                                  <th className="text-white">Student ID</th>
                                  <th className="text-white">User Name</th>
                                  <th className="text-white">Student Type</th>
                                  <th className="text-white">Program</th>
                                  <th className="text-white">Batch</th>
                                  <th className="text-white"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><Form.Check type="radio" name="flexRadioDefault" id="flexRadioDefault-1" label="1"/></td>
                                  <td>Amanan</td>
                                  <td>CR1</td>
                                  <td></td>
                                  <td>Bulk Test</td>
                                  <td>PTE</td>
                                  <td>PTE_default</td>
                                  <td><CloseButton /></td>
                                </tr>
                                <tr>
                                  <td><Form.Check type="radio" name="flexRadioDefault" id="flexRadioDefault-2" label="2"/></td>
                                  <td>Amanan</td>
                                  <td>CR1</td>
                                  <td></td>
                                  <td>Bulk Test</td>
                                  <td>PTE</td>
                                  <td>PTE_default</td>
                                  <td><CloseButton /></td>
                                </tr>
                                <tr>
                                  <td><Form.Check type="radio" name="flexRadioDefault" id="flexRadioDefault-3" label="3"/></td>
                                  <td>Amanan</td>
                                  <td>CR1</td>
                                  <td></td>
                                  <td>Bulk Test</td>
                                  <td>PTE</td>
                                  <td>PTE_default</td>
                                  <td><CloseButton /></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="student-test-details">
                        <Tab.Container id="assign-tabs" defaultActiveKey="sectionwise-test">
                          <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                            <Nav.Item>
                              <Nav.Link eventKey="sectionwise-test">Section Wise Test</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="mock-test">Mock Test</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="sectionwise-test">
                            {/* You can use data list component */}
                              <Table responsive striped bordered hover className="small">
                                <thead>
                                  <tr className="bg-primary">
                                    <th></th>
                                    <th className="text-white">Test Name</th>
                                    <th className="text-white">Module</th>
                                    <th className="text-white">Category Name</th>
                                    <th className="text-white">Level</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-01(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-02(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-03(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-04(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 01</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 02</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 03</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 04</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Hard</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Tab.Pane>
                            <Tab.Pane eventKey="mock-test">
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AssignTest;
