import React, { useRef, useEffect, useState, useContext } from "react";
import { Card, Button } from "react-bootstrap";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import Icon from "../../../../components/Icon/Icon";
import ModalContext from "../../../../context/ModalContext";
import PracticeVolumneSvg from "../../../../components/SvgIcons/PracticeVolumneSvg";
import PracticePlaySvg from "../../../../components/SvgIcons/PracticePlaySvg";
import PracticePauseSvg from "../../../../components/SvgIcons/PracticePauseSvg";

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
}

function PracticePlayingAudio({countupdate, selected_ques_Obj,play }) {
  let {
    question_content_audio,
    question_content_video,
    question_content_image,
  } = selected_ques_Obj;
  let c = selected_ques_Obj.question_content_audio;

  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(play);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [count, setCount] = useState(100);
  const [isMuted, setIsMuted] = useState(false);
  let { timeForPractice, setTimeForPractice } = useContext(ModalContext);
  let { dummyPrepare } = timeForPractice;
  let whenAudioIsTrue = question_content_audio && !question_content_video;
  const [whenVideoIsTrue, setWhenVideotrue] = useState(null);

useEffect(()=>{
  setCurrentTime(0)
  setIsPlaying(false)
},[countupdate])


  useEffect(() => {
    if (whenAudioIsTrue) {
      const audioElement = audioRef.current;
      audioElement.addEventListener("timeupdate", () => {
        if (audioElement.currentTime == audioElement.duration) {
          setIsPlaying(false);
          setTimeForPractice({...timeForPractice,startRecord:true})
        }
        setCurrentTime(audioElement.currentTime);
      });
      audioElement.addEventListener("loadedmetadata", () => {
        setDuration(audioElement.duration);
      });
      audioElement.addEventListener("canplaythrough", () => {
        if (isPlaying == true) {

    console.log("audioElement11111",audioElement)
          audioElement?.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        }
      });

      return () => {
        audioElement.removeEventListener("timeupdate", () => {});
        audioElement.removeEventListener("loadedmetadata", () => {});
        audioElement.removeEventListener("canplaythrough", () => {});
      };
    }
  }, [isPlaying, whenAudioIsTrue]);

  const playPauseToggle = (stop) => {
    const audioElement = audioRef.current;
    if(audioElement == undefined){
      return
    }
    console.log("audioElement",audioElement)
    if(stop =="stop"){
      audioElement.pause();
      setIsPlaying(false);
      return
    }
    if (isPlaying) {
      audioElement.pause();
    } else {
      if(whenAudioIsTrue){
      audioElement?.play();
      setTimeForPractice({
        ...timeForPractice,
        isRunning: false,
        original: { ...timeForPractice.original, status: true,time:selected_ques_Obj?.answer_time },
      });
    }
    }
    setIsPlaying(!isPlaying);
  };
useEffect(()=>{
  if(timeForPractice?.stopped == true){
  playPauseToggle("stop");

}
},[timeForPractice.stopped])
  const handleTimeChange = (event) => {
    const audioElement = audioRef.current;
    const newTime = parseFloat(event.target.value); 
    audioElement.currentTime = newTime;
    setCurrentTime(newTime);
    setIsPlaying(true);
  };
  function handleVolumeChange(val) {
    console.log("valee", val);
    setCount(Number(val));
    //audioRef.current.volume = Number(val[0]) / 100;
    audioRef.current.volume = isMuted ? 0 : Number(val) / 100;
  }

  useEffect(() => {
    setWhenVideotrue(!question_content_audio && question_content_video);
  }, [question_content_audio, question_content_video]);
  const toggleMute = (e) => {
    e.preventDefault();
    if (whenAudioIsTrue) {
      const audioElement = audioRef.current;
      audioElement.muted = !isMuted;
      setIsMuted(!isMuted);
    } else {
      const audioElement = videoRef.current;
      audioElement.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };
  const handleVolumeChangeForVideo = (val) => {
    const volumeValue = Number(val);
    setCount(volumeValue);
    videoRef.current.volume = volumeValue / 100;
  };

  useEffect(() => {
    if (whenVideoIsTrue) {
      const handleTimeUpdate = () => {
        setCurrentTime(
          (videoRef?.current?.currentTime / videoRef?.current?.duration) * 100
        );
      };

      videoRef?.current?.addEventListener("timeupdate", handleTimeUpdate);
      // setTimeForPractice({...timeForPractice,startRecord:true})
      return () => {
        videoRef?.current?.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
    
    setIsPlaying(false)
  }, []);
  useEffect(() => {
    if(timeForPractice.original.status && whenAudioIsTrue){
    playPauseToggle()
  }
  }, [timeForPractice.original.status]);
  useEffect(() => {
    if (whenVideoIsTrue && timeForPractice.original.status ) {
      let videoElement = videoRef.current;
      videoElement?.play();
    }
  }, [whenVideoIsTrue, timeForPractice.original.status]);
  useEffect(()=>{
    setIsPlaying(false)
  },[selected_ques_Obj])
  return (
    <>
    <audio className="d-none" ref={audioRef} src={c}/>
      {whenAudioIsTrue && (
        <div className="d-flex flex-column align-items-center justify-content-between bg-hilight p-1 mb-5">
          <div className="d-flex flex-column align-items-center w-50 practice-ui py-3">
            <audio ref={audioRef} src={c} />
            {question_content_image && <img src={question_content_image} />}
            <div class="d-flex justify-content-center align-items-center w-100">
              <Button
                className="btn btn-color-primary btn-icon"
                variant="transparent"
                onClick={()=>playPauseToggle()}
              >
                {isPlaying ? (
                 <PracticePauseSvg fill={"var(--theme-color)"} style={{ height: "30px" }} />
                ) 
                
                
                : (
                

                  <PracticePlaySvg fill={"var(--theme-color)"} style={{ height: "30px" }} />
                )}
              </Button>
              <input
                type="range"
                value={currentTime}
                max={duration}
                onChange={handleTimeChange}
                className="w-50"
              />
              <span className="text-secondary">
                {formatTime(currentTime)} /
              </span>
              <span className="text-secondary">{formatTime(duration)}</span>
            </div>
          </div>
          <div className="w-50 d-flex justify-content-center align-items-center">
            <button
              className="btn btn-color-hidisable btn-icon"
              onClick={toggleMute}
            >
              {isMuted ? (
                <img
                  src="/images/practice-mute.svg"
                  alt="image"
                  style={{ height: "22px" }}
                ></img>
              ) : (
              

                <PracticeVolumneSvg  fill={"var(--theme-color)"} style={{ height: "22px" }} />
              )}
            </button>

            <div className="d-flex align-items-center w-60 practice-ui">
              <input
                type="range"
                value={count}
                onChange={(e) => handleVolumeChange(e.target.value)}
                className="w-100"
              />
            </div>
          </div>
        </div>
      )}
      {whenVideoIsTrue && (
        <div className="d-flex flex-column align-items-center justify-content-between bg-hilight p-1 mb-5">
          <div className="d-flex align-items-center w-50 practice-ui py-3">
            <video
              id="to-stop-video"
              ref={videoRef}
              className="custom-video"
              onEnded={() => {setTimeForPractice({...timeForPractice,startRecord:true})}}
              onPlay={() => {  }}
            >
              <source
                src={question_content_video}
                type="video/mp4"
                className="exam-img"
              />
            </video>
            <br />
          </div>
          <div className="w-50 d-flex justify-content-center align-items-center">
            <button
              className="btn btn-color-hidisable btn-icon"
              onClick={toggleMute}
            >
              {isMuted ? (
                <img
                  src="/images/practice-mute.svg"
                  alt="image"
                  style={{ height: "22px" }}
                ></img>
              ) : (
                <img
                  src="/images/practice-volume.svg"
                  alt="image"
                  style={{ height: "22px" }}
                ></img>
              )}
            </button>

            <div className="d-flex align-items-center w-50 practice-ui">
              <input
                type="range"
                value={count}
                onChange={(e) => handleVolumeChangeForVideo(e.target.value)}
                className="w-100"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PracticePlayingAudio;
