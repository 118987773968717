import React, { useState, useContext } from "react";
import { ProgressBar, Spinner, Button, Form } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import ModalContext from "../../../../context/ModalContext";
import Timer from "../Timer";
import InputRadio from "../inputComponents/inputRadio";
import OnlyRecorderUI from "../Istructions/ExamComponents/OnlyRecorderUI";
import AudioUI from "../Istructions/ExamComponents/AudioUI";
function ListeningHighlightCorrectSummary({ data }) {
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [startAudio, setStartAudio] = useState(false);
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <div className="d-flex flex-column gap-5 justify-content-start w-50 mb-5">
          </div>
          <AudioUI
             startRecording={() => {
              // setStartAudio(true);
              // startRec();
            }}
                timer={data.question_begin_time}
                audio={data.question_content_audio}
              />

        <div className="d-flex flex-column w-100">
          <p>{data?.question}</p>
          <div className="d-flex flex-column hilightcorrect">
            <InputRadio data={data.question_options} />
          </div>
        </div>
      </div>
    </>
  );
}
export default ListeningHighlightCorrectSummary;
