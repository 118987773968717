import {
  Card,
  OverlayTrigger,
  Popover,
  Form,
  Row,
  Col,
  Button,
  Modal,
  ButtonGroup,
  Tab,
  Tabs,
  Nav,
  ProgressBar,
} from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";
import { useState, useEffect } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import ModalContext from "../../../../context/ModalContext";
import { useContext } from "react";
import { Stream } from "@mui/icons-material";
import MicroPhoneSvg from "../../../../components/SvgIcons/MicroPhoneSvg";

function PracticeMicrophone({ data, setRedoCount,isSubmitted }) {
  let {
    popUpType,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
    timeForPractice,
    setTimeForPractice,
  } = useContext(ModalContext);
  const [demoModal, setDemoModal] = useState(false);
  const demoModalClose = () => setDemoModal(false);
  const demoModalShow = () => setDemoModal(true);
  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder();
  const [isMicConnected, setIsMicConnected] = useState(false);

  console.log("CHAA", timeForPractice);

  useEffect(() => {
    async function checkMicStatus() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        console.log("stream", stream);
        if (stream) {
          setIsMicConnected(true);
          stream.getTracks().forEach((track) => track.stop());
        }
        console.log("stream", stream);
      } catch (error) {

        setIsMicConnected(false);
      }
    }
    checkMicStatus();
  }, []);

  useEffect(() => {
    let timer = setInterval(() => {
      async function permissionChange() {
        const permission = await navigator.permissions.query({ name: "microphone" });
        // console.log("PERMISSIONDANE", permission);
        if (permission.state == "granted") {
          setIsMicConnected(true)
          
          // window.location.reload();
          // clearInterval(timer)
        }
        if(permission.state == "denied"){
          setIsMicConnected(false)
          // setRedoCount((prevCount) => prevCount + 1);
          setTimeForPractice({
            ...timeForPractice,
            isRunning: true,
            original: { ...timeForPractice.original, status: true,time:"00:00" },
            prepare: { ...timeForPractice.prepare, status: false },
          });
          // setTimeForPractice({ ...timeForPractice, isRunning: false ,stopped:true});
          setTimeInSeconds(0)
          stopRecording();
          // clearInterval(timer)
        }
        // console.log("permission", permission);
      }
      permissionChange()

    }, 1000)
    return () => clearInterval(timer)
  }, [])



  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [startBeep, setStartBeep] = useState(false);
  const [show, setshow] = useState(false);

  const answerTimeInSeconds = convertTimeToSeconds(
    data?.answer_time ? data?.answer_time : "00:00"
  );
  function convertTimeToSeconds(time) {
    // if(isRecording){
    // alert("*")
    const [minutes, seconds] = time?.split(":");
    return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
  }
  const startRecordingAnswer = (e) => { };

  // useEffect(() => {
  //   console.log("HELLO");
  //   async function permissionChange(){
  //     const permission = await navigator.permissions.query( { name: "microphone" } );
  //     console.log("PERMISSIONOYE",permission);
  //     if(permission.state == "denied"){
  //       stopRecording();
  //       setIsMicConnected(false)
  //     }
  //     console.log("permission",permission);
  //   }
  //   console.log("FUNCTION");
  //   if(timeForPractice.isRunning == true){
  //     permissionChange()
  //   }
  //   else{
  //     stopRecording();
  //     setIsMicConnected(false)
  //   }
    
  // },[timeInSeconds])
  console.log("DATATATA",data);
  useEffect(() => {
    console.log("HELLO");
    async function permissionChange() {
      const permission = await navigator.permissions.query({ name: "microphone" });
      console.log("PERMISSIONOYE", permission);
      if (permission.state == "denied") {
        stopRecording();
        setIsMicConnected(false)
      }
      // console.log("permission", permission);
    }
    console.log("FUNCTION");
    if (timeForPractice.isRunning == true) {
      permissionChange()
    }
    else {
      stopRecording();
      setIsMicConnected(false)
    }

  }, [timeInSeconds])

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimeInSeconds((prevTime) => {
          const updatedTime = prevTime + 0.5 * (100 / answerTimeInSeconds);
          if (updatedTime >= 100) {
            stopRecording();
            clearInterval(interval);
            return 100;
          }
          return updatedTime;
        });
        setshow(!show);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording, answerTimeInSeconds]);

  useEffect(() => {
    if (!answerJson.audioBlob) {
      setTimeInSeconds(0);
    }
  }, [answerJson]);
  useEffect(() => {
    if (recordingBlob != undefined) {
      setAnswerJson({ ...answerJson, audioBlob: recordingBlob });
    }
  }, [recordingBlob]);
  useEffect(() => {
    if (timeForPractice.startRecord == true) {
      startRecordingji()
    }
  }, [timeForPractice.startRecord]);
  const startRecordingji = (e) => {
    if(window.location.pathname.includes("/practice/pte/repeat_sentences") || window.location.pathname.includes("/practice/pte/answer_questions") || window.location.pathname.includes("/practice/pte/retell_lectures") ){
      if(e?.nativeEvent?.pointerType == "mouse"){
        document?.getElementsByClassName("btn btn-color-primary btn-icon btn btn-transparent")[0]?.click()
        if(window.location.pathname.includes("/practice/pte/retell_lectures")){
          document?.getElementById("to-stop-video")?.pause()
        }
      }
    }
    console.log("TRIGGER",e );
    setStartBeep(true)
    startRecording();
    setTimeForPractice({
      ...timeForPractice,
      isRunning: true,
      original: { ...timeForPractice.original, status: true, time: "00:00" },
      prepare: { ...timeForPractice.prepare, status: false },
    });
    recordingBlob && recordingBlob instanceof Blob && setTimeInSeconds(0);
  };

  useEffect(() => {
    if (answerJson?.audioBlob) {
      setTimeForPractice({
        ...timeForPractice,
        isRunning: false,
      });
    }
  }, [answerJson?.audioBlob])

  console.log("ANSWERTIME",data.answer_time);

  let minutes = data?.answer_time?.split(":")[0]*60
  let seconds = data?.answer_time?.split(":")[1]
  let total_time = minutes+seconds

  var div = document?.getElementById('getInPixels');
  var width = div?.offsetWidth;
  console.log("getInPixels", width);
  let max = 0

  return (
    <>
      {startBeep && <audio
        className="d-none"
        autoPlay
        src="/sound/beep.wav"
        controls
      ></audio>}
      {isMicConnected ? (
        <>
          {" "}
          <ProgressBar
            max={50}

            variant="hisuccess "
            className="w-100"
            id="getInPixels"
            now={timeInSeconds}
          />
          {isRecording ? (
            <div
              className="bg-hilight p-4 d-flex flex-column justify-content-center align-items-center text-middark mb-4"
              style={{ height: "140px" }}
            >
              Click to Stop{" "}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setTimeForPractice({ ...timeForPractice, isRunning: false, stopped: true });
                  stopRecording();
                }}
                variant="transparent"
                size="lg"
              >


              
    <MicroPhoneSvg  fill={"var(--theme-color)"} style={{ height: "40px" }} />
    
              </Button>
              <div className="use-headset">
                <OverlayTrigger
                  trigger="click"
                  className="practice-popover"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Body>
                        - The audio recording quality of your microphone and the
                        environment noise will affect the accuracy of AI scores
                        <br />- It's recommended to use a headset with inline
                        microphone to practice in a quiet environment to ensure
                        better recording quality and an accurate score
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="transparent">
                    <Icon name="info"></Icon>{" "}
                    <span className="ms-1">
                      {" "}
                      Use a headset with inline microphone to get accurate AI
                      scores
                    </span>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          ) : (
            <div
              className="bg-hilight p-4 d-flex flex-column justify-content-center align-items-center text-middark mb-4"
              style={{ height: "140px" }}
            >
              {isSubmitted ? "Click On Redo" : answerJson?.audioBlob &&
                recordingBlob &&
                recordingBlob instanceof Blob
                ? "Done"
                : " Click to Start"}
                {isSubmitted ? <img
                  src="/images/microphone-disable.svg"
                  style={{ height: "40px", cursor:"not-allowed" }}
                ></img> :
              <Button
                onClick={(e) => {
                  startRecordingji(e);
                  // setTimeInSeconds(data?.answer_time)
                }}
                variant="transparent"
                size="lg"
                // disabled={isSubmitted}
                
              >
                <img
                  src="/images/microphone-disable.svg"
                  style={{ height: "40px" }}
                ></img>
              </Button>}
              <div className="use-headset">
                <OverlayTrigger
                  trigger="click"
                  className="practice-popover"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Body>
                        - The audio recording quality of your microphone and the
                        environment noise will affect the accuracy of AI scores
                        <br />- It's recommended to use a headset with inline
                        microphone to practice in a quiet environment to ensure
                        better recording quality and an accurate score
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="transparent">
                    <Icon name="info"></Icon>{" "}
                    <span className="ms-1">
                      {" "}
                      Use a headset with inline microphone to get accurate AI
                      scores
                    </span>
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          )}
          {answerJson?.audioBlob &&
            recordingBlob &&
            !isRecording &&
            recordingBlob instanceof Blob && (
              <audio
                className="my-3"
                src={URL.createObjectURL(recordingBlob)}
                controls
              />
            )}
        </>
      ) : (
        <div
          className="bg-primary p-4 d-flex flex-column justify-content-center align-items-center text-white mb-4"
          style={{ height: "180px" }}
        >
          <p>Microphone permission is not granted.</p>
          <Button
            variant="outline-light"
            className="py-1 text-white btn-hover-hiprimary"
            onClick={demoModalShow}
          >
            Help
          </Button>
          <Modal
            show={demoModal}
            onHide={demoModalClose}
            className="modal-popup"
          >
            <Modal.Header closeButton>
              <Modal.Title>Hilingo Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column text-middark">
                <span>Your microphone permission is not enabled</span>
                <span>Please enable it as follows :</span>
                <span>1. Please use the latest version of Google Chrome to practice.
                  <a href="https://www.google.com/intl/en_in/chrome/" target="_blank">Download Google Chrome from here.</a></span>
                <span>2. Please enable your microphone permission on your Google Chrome browser as shown in the illustration.</span>
              </div>

              <a href="https://www.google.com/intl/en_in/chrome/" target="_blank">
                <img src="/images/microphone-blocked.png"></img>
              </a>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={demoModalClose}>
                Close
              </Button>
              <Button size="sm" variant="primary" onClick={demoModalClose}>
                Help (Q&A) <Icon name="chevron-right"></Icon>
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default PracticeMicrophone;
