import { Row, Col } from 'react-bootstrap';
import InstituteDataTable from '../admin/institutepages/InstituteDataTable';
import InstituteData, { InstituteColumns } from '../admin/institutepages/InstituteData';

function ViewTickets() {
    return (
        <Row className="g-gs">
            <Col md="12">
                <InstituteDataTable tableClassName="data-table-head-light table-responsive" data={InstituteData} columns={InstituteColumns}></InstituteDataTable>
            </Col>
        </Row>
    )
}

export default ViewTickets;