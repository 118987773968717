import { Card, Form, Row, Col, Button, ButtonGroup, InputGroup, Badge } from 'react-bootstrap';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Icon } from '../../components';

function SuperAdminSelectMockTest() {
    return (
        <Layout title="Add Student" content="container">
            <h1 className='mb-5'>Select Mock Test</h1>
            <Block>
                <Row className="g-gs">
                    <Col md="8">
                        <Card className='h-100'>
                            <Card.Body>
                                <h5>Select Mock Test</h5>
                                <hr></hr>
                                <ButtonGroup aria-label="Basic checkbox toggle button group" className='hilingo-radio-button gap-3'>
                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio1">0 - 5 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio2">6 - 10 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio3">11 - 15 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio4">16 - 20 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio5">21 - 25 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio6" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio6">26 - 30 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio7" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio7">30 - 35 Mock Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio8" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio8">36 - 40 Mock Test</Button>
                                </ButtonGroup>
                                
                                <div className='d-flex justify-content-end mt-5'>                                    
                                    <a href='/superadmin/super-admin-section-wise-test' className='btn btn-primary fw-bold'>Next <Icon name="arrow-right" className='ms-2'></Icon></a>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md="4">
                        <Card className='h-100'>
                            <Card.Body>
                                <h5 className='mb-3'>Purchase Summary</h5>
                                <ul>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <span className='text-light'> Mock Test </span> <b className='text-primary'>0</b>
                                    </li>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <span className='text-light'> Section Wise Test </span> <b className='text-primary'>0</b>
                                    </li>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <span className='text-light'> Section Wise Test </span> <b className='text-primary'>4500+</b>
                                    </li>
                                    <hr></hr>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <h5 className='text-secondary'> Total Credit Spent </h5> <h5 className='text-primary'>600</h5>
                                    </li>
                                </ul>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Block>
        </Layout>
    )
}

export default SuperAdminSelectMockTest;