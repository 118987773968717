import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import { Icon } from "../../../components";
import Block from "../../../components/Block/Block";
import Layout from "../../../layout/default";
import InstituteDataTable from "../TeamDataTable";
// import InstituteData, { InstituteColumns } from './InstituteData';
import { useParams } from "react-router-dom";
import ScoreFeedbackApi from "../../../api/auth/adminPanel/scoreFeedback";
import { LinkList, LinkListItem } from "../../../components";
import Swal from "sweetalert2";

function ViewScoreFeedbackList() {
  let { id } = useParams();
  let api = new ScoreFeedbackApi();

  const [data, setData] = useState([]);


  useEffect(() => {
    getInstituteList();
  }, []);

  const getInstituteList = async () => {
    await api
      .getScoreFeedback()
      .then((res) => {
        console.log("ress", res);
        if (res.data.length > 0) {
          setData(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(data);
  const deleteScoreFeedback = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.value) {
        api
          .deleteScoreFeedbackById(id)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: `${res?.msg}`,
            });

            getInstituteList()
          })
          .catch((err) => {
            console.log("err", err);
          });
        }
    })
    
  };
  const InstituteColumns = [
    {
      name: "Test Type",
      selector: (row) => row?.module,
      cell: (row) => <span className="text-middark">{row?.module}</span>,
      sortable: true,
    },
    {
      name: "Question Type ",
      selector: (row) => row.module_name,
      cell: (row) => <span className="text-middark">{row?.module_name}</span>,
      sortable: true,
    },

    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`/edit-score-feedback/${row._id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem>
            <div className="del-btn" onClick={() => deleteScoreFeedback(row._id)}>
              <Icon name="trash"></Icon>
            </div>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Score Feedback List</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <InstituteDataTable
                  tableClassName="data-table-head-light table-responsive staff-list"
                  data={data}
                  columns={InstituteColumns}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}

export default ViewScoreFeedbackList;
