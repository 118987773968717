import { Card, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InstituteDataTable from './admin/institutepages/InstituteDataTable';
import Layout from '../layout/default';
import { useContext, useEffect, useState } from 'react';
import AuthApi from '../api/auth';
import ModalContext from '../context/ModalContext';
import Context from '../context';
import {
  LinkList,
  LinkListItem, Icon
} from "../components";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ViewNotification() {
  let { userData } = useContext(Context)
  userData = JSON.parse(userData)
  console.log(userData?._id)

  const [tableData, setTabledata] = useState([])

  const [deleteId, setDeleteId] = useState("");

  const deleteHandler = (id) => {
    setDeleteId(id);
  };





  function formatDate(dateString) {
    const date = new Date(dateString);
    console.log('date.getDate() :>> ', date.getDate(), `${("0" + date.getDate()).slice(-2)}`);

    // Format date
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;

    // Format time
    let hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} | ${formattedTime}`;
  }


  const InstituteColumns = [
    {
      name: "SR No.",
      cell: (row, index) => <span className="text-middark">{index + 1}</span>,
      sortable: false,
    },
    {
      name: "Title",
      selector: (row) => row?.email,
      cell: (row) => (
        <span className="text-middark">{row?.title}</span>
      ),
      sortable: true,
    },
    {
      name: "Sub-title",
      selector: (row) => row?.date,
      cell: (row) => (
        <span className="text-middark">{row?.sub_title}</span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row?.createdAt,
      cell: (row) => (
        <span className="text-middark">{formatDate(row?.createdAt)}</span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.sending_type,
      cell: (row) => (
        <span className="text-middark">{row?.status ? row?.status : '-'}</span>
      ),
      sortable: true,
    },

    {
      name: "Button link",
      selector: (row) => row?.button_name,
      cell: (row) => (




        row?.button_name ?
          <Button className="primary" size='sm' onClick={() => window.open(row?.button_link, '_blank')}>
            {row?.button_name}
          </Button> : "-"
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100 d-flex justify-content-center">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">


            <LinkListItem to={`/notification/${row._id}`}>
              <Icon name="eye"></Icon>
            </LinkListItem>

            {/*             
            <div className="del-btn" onClick={() => deleteHandler(row?._id)}>
             
              <Icon name="trash-fill"></Icon>
            </div> */}

          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  useEffect(() => {
    get_notification_by_instId();
  }, [])
  let api = new AuthApi()


  const get_notification_by_instId = async () => {
    await api.get_notification_by_instId(userData?._id).then((res) => {
      if (res.status) {
        setTabledata(res?.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const navigate = useNavigate()


  const delete_Single_Notification = async (id) => {
    await api
      .delete_notification(id)
      .then((res) => {
        if (res?.msg) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: `${res?.msg}`,
          });
          get_notification_by_instId();

          navigate("/view-notification");

          // }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (deleteId) {
      delete_Single_Notification(deleteId);
    }
  }, [deleteId]);

  return (
    <Layout title="Add Student" content="container">
      <h1 className='mb-5'>Notification Details</h1>
      <Row className="g-gs">
        <Col md="12">
          <Card>
            <Card.Body>

              <ul className="d-flex justify-content-end">
                <li>
                  <Button
                    className="d-none d-md-inline-flex btn btn-primary add-btn"
                    variant="primary"
                    // onClick={()=>navigate('/otherpages/enquiry')}
                    onClick={() => navigate("/add-notification")}

                  >
                    <Icon name="plus" /> <span> Add Notification</span>
                  </Button>
                </li>
              </ul>

              <InstituteDataTable tableClassName="data-table-head-light table-responsive" data={tableData} columns={InstituteColumns}></InstituteDataTable>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default ViewNotification;
