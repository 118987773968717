import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { ProgressBar, Spinner, Button, Form } from "react-bootstrap";
import ModalContext from "../../../../context/ModalContext";
function InputRadio({ showAnwer,data ,question}) {
    let{answerJson,setAnswerJson} = useContext(ModalContext)
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const handleOptionChange = (optionIndex) => {
    setSelectedAnswer(optionIndex);
    const selectedAnswerArray =
      data?.length > 0 && data.map((_, index) => optionIndex === index);
    const newAnswerString = selectedAnswerArray.join(",");
    setAnswerJson({...answerJson,answer:newAnswerString})
  };
console.log("showAnwer",selectedAnswer)
  return (
    <>
      <div className="d-flex flex-column">
      <p>{(data?.question!="null" && data?.question ) ?data.question :"" }</p>
        {data?.length > 0 && showAnwer ?

          data?.map((item, index) => {
            return (
              <div className={`d-flex gap-1 ${data[selectedAnswer]?.answer == true?'hilightSuccessted':data[selectedAnswer]?.answer == false&& 'hilightIncorrect'}`} key={index}>
                <Form.Check
                  type="radio"
                  id={`ansRadio-${index}`}
                  name="ansRadio"
                  label={item.options}
                  className={(item.answer == true && "hightLiteGray")}
                  checked={selectedAnswer === index}
                  onChange={() => handleOptionChange(index)}
                />
              </div>
            );
          })
          :
          data?.map((item, index) => {
            return (
              <div className="d-flex gap-1 hilightcorrect" key={index}>
                <Form.Check
                  type="radio"
                  id={`ansRadio-${index}`}
                  name="ansRadio"
                  label={item.options}
                  checked={selectedAnswer === index}
                  onChange={() => handleOptionChange(index)}
                />
              </div>
            );
          })
        }
      </div>
    </>
  );
}

export default InputRadio;
