import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, CloseButton, Col, Form, Image, Nav, Row, Tab, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import { Icon, } from "../../components";
import Block from "../../components/Block/Block";
import FileUpload from "../../components/Form/FileUpload";
import { NewQuill } from "../../components/Form/editors/NewQuill";
import Context from "../../context";
import Layout from "../../layout/default";
import SubmitButton from "../questions/PTE/SubmitButton";
import FileUploadAnnoucement from "../../components/Form/FileUploadAnnoucement";
import FileUploadImageExtension from "../../components/Form/FileUploadImageExtension";


import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material';
import dayjs from "dayjs";
function EditNotification() {
  let { userData, domainData } = useContext(Context);
  userData = JSON.parse(userData);
  domainData = JSON.parse(domainData);
  let navigate = useNavigate()
  let api = new AuthApi();
  const [count, setCount] = useState(0)
  const [studentList, setStudentList] = useState([])
  const [allData, setAllData] = useState({})
  const [quillData, setQuillData] = useState({})
  
  const [data, setdata] = useState({
    user_id: [],
    sending_type: "now",
    status: "complete",
    inst_id: domainData?._id,
    inst_name: domainData?.inst_profile?.inst_business_name,
    hours: "00:00",
    minutes: "00:00",
  });
  const [loader, setloader] = useState(false);

  const [editId, setEditId] = useState("");
  let formdata = new FormData();
  const sendTicket = async () => {
    setloader(true)
    console.log('formdata :>> ', data);
    console.log('formdata1 :>> ', allData);
    for (const [key, value] of Object.entries(allData)) {
      formdata.append([key], value)
    }
    await api.create_notification(formdata).then((res) => {
      setloader(false)
      if (res?.status) {
        Swal.fire({
          icon: "success",
          text: res.message,
          confirmButtonText: "Done",
        });
        navigate("/view-notification")
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: res.msg,
        });
      }
      console.log("res", res)
    }).catch((err) => {
      console.log(err)
    })

  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    setdata({ ...data, [name]: value });
  };
  const checkValidation = () => {

    // console.log('allmydata :>> ', data);
    return (
      allData?.title &&
      allData?.sub_title &&
      allData?.description
    );
  };


  let { id } = useParams();

  console.log('id :>> ', id);
  useEffect(() => {
    get_notification_single(id);
  }, []);

  const get_notification_single = async (id) => {
    await api
      .get_notification_by_id(id)
      .then((res) => {
        if (res?.data?.length > 0) {
          setdata(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendData = (quilData) => {
    console.log('childData :>> ', quilData)
    setQuillData({ description: quilData })
  }

  useEffect(() => {
    setAllData({ ...data, ...quillData });
  }, [quillData, data])

  console.log('allData :>> ', data);


  const [selectedTime, setSelectedTime] = useState(null)
  console.log('selectedTime :>> ', new Date(selectedTime).toLocaleTimeString());

  console.log('selectedTime### ', selectedTime);
// console.log('data?.[0]?.date :>> ', data?.[0]?.date,dayjs(new Date()),dayjs(data?.[0]?.date));
console.log('data?.[0]?.date :>> ',data?.[0]?.hours,dayjs(data?.[0]?.hours));


const theme = createTheme({
  elevation:2
});
  return (
    <Layout title="Add Student" content="container">
      <h1 className='d-flex align-items-center mb-5'><Icon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} name="arrow-left-circle-fill" className="text-primary h1 me-2"></Icon> Back</h1>
      {/* <h1 className="mb-5">Add Notification</h1> */}

      <Block>
        <Card className="h-100">
          {count == 0 && <Card.Body>
            <Row className="g-gs">
              <Col md="9">
                <Row className="g-gs">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Title</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="title"
                          onChange={(e) => handleChange(e)}
                          value={data?.[0]?.title}
                          type="text"
                          id="subject"
                          placeholder="Enter a title here..."
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label>Sub title</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="sub_title"
                          onChange={(e) => handleChange(e)}
                          value={data?.[0]?.sub_title}
                          type="text"
                          id="subject"
                          placeholder="Enter a sub title here..."
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    {data?.[0]?.description &&
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="message">Description</Form.Label>
                        <div className="form-control-wrap">


                          <NewQuill placeholderValue={"Type your message here..."}
                            sendData={sendData}
                            value={data?.[0]?.description}
                            disabled={true}

                          />

                          {/* <Form.Control
                          onChange={(e) => handleChange(e)}
                          name="description"
                          value={data?.description}
                          as="textarea"
                          placeholder="Type your message here..."
                          id="message"
                          rows="3"
                        ></Form.Control> */}
                        </div>
                      </Form.Group>}
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group d-flex">
                      <div className="w-50">
                        <Form.Label>Upload an attachment</Form.Label>
                        <div className="form-control-wrap">



                          {/* 
                        <FileUploadImageExtension
                          filetype="image"
                          iconName="files"
                          name="attachment"
                          maxFiles={1}
                          ImageData={data}
                          setdata={(data)=>setdata(data)}
                          //  maxSize={4194304} //--> if use this line then comment the fileType props
                          //  errorText="File size is too large, please upload file size within (12MB)"

                          onChange={(uploadDocData) =>
                            setdata({ ...data, attachment: uploadDocData[0] })
                          }
                        /> */}

                          <div
                            className={`${data?.[0]?.img != "" &&
                              "p-2 border d-flex align-items-center justify-content-center rounded",

                              data?.[0]?.img != null &&
                              "p-2 border d-flex align-items-center justify-content-center rounded"
                              }`}
                            // style={{ width: "160px", height: "160px" }}
                            style={{
                              width: `${data?.[0]?.img == "" || data?.[0]?.img == null ? "0px" : "160px"
                                }`,
                              height: `${data?.[0]?.img == "" || data?.[0]?.img == null ? "0px" : "160px"
                                }`,
                            }}
                          >
                            <Image src={data?.[0]?.img} className="rounded"></Image>
                          </div>

                          {/* <div className="mt-2">
                            <small>
                              Image must be JPG/JPEG or PNG (Aspect ratio 2:1)
                            </small>
                          </div> */}
                        </div>
                      </div>

                      {/* <TimePicker
        label='Time Picker'
        // value={selectedTime}
        onChange={newValue => {
          setSelectedTime(newValue)
        }}
        renderInput={props => <TextField {...props} />}
      /> */}



                      <div className="w-50">
                        <Form.Label>Button Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="button_name"
                            onChange={(e) => handleChange(e)}
                            value={data?.[0]?.button_name}
                            type="text"
                            id="button_name"
                            placeholder="Button name "
                            disabled
                          />
                        </div>

                        <Form.Label>Button Link</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="button_link"
                            onChange={(e) => handleChange(e)}
                            value={data?.[0]?.button_link}
                            type="text"
                            id="button_link"
                            placeholder="Button link"
                            disabled
                          />
                        </div>
                      </div>

                    </Form.Group>

                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group d-flex">
                      <Col lg="6">
                        <Form.Check inline label="Send Now" checked={data?.[0]?.sending_type == "now"} onClick={() => setdata({ ...data, sending_type: "now", status: "complete" })} name="inlineRadioOptions" type="radio" id="inlineRadio1"></Form.Check>
                        <Form.Check inline label="Send Later" checked={data?.[0]?.sending_type == "later"} onClick={() => setdata({ ...data, sending_type: "later", status: "pending" })} name="inlineRadioOptions" type="radio" id="inlineRadio2"></Form.Check>
                      </Col>

                    </Form.Group>
                  </Col>
                  {data?.[0]?.sending_type == "later" &&
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label>Date</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control type="date" disabled
                            value={data?.[0]?.date}
                            onChange={(e) => setdata({ ...data, date: e.target.value })} />

                          {/* <DatePicker /> */}
                        </div>
                      </Form.Group>
                    </Col>
                  }
                  {data?.[0]?.sending_type == "later" && <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Hours</Form.Label>
                      {/*  <div className="form-control-wrap addNotification">
                       <TimePickerWithProps name="hours"  format={12} placeholder="hrs" interval={60} value={data?.hours} onChange={(dataFromComp) => setdata({...data, ...dataFromComp })}/>
                      </div>


                      <Form.Label htmlFor="fullname">Minutes</Form.Label>
                      <div className="form-control-wrap addNotification">
                       <TimePickerWithProps name="minutes"  placeholder="hrs" start={"01:00"} end={"60:00"} interval={1} value={data?.minutes} onChange={(dataFromComp) => setdata({...data, ...dataFromComp })}/>
                      </div>    */}

<ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'TimePicker',
                            'DateTimePicker',
                            'DateRangePicker',
                          ]}
                        >
                          <TimePicker
                            // value={selectedTime}
                            name="hours" 

            
                            value={dayjs(data?.[0]?.hours)}
                            
                        
                            onChange={(newValue) => console.log("newwwwwwww",newValue)}
                            renderInput={params => <TextField color="warning" {...params} />}
                          />

                {/* <DatePicker defaultValue={dayjs(data?.[0]?.date)} /> */}
                        </DemoContainer>
                      </LocalizationProvider>
                      </ThemeProvider>

                    </Form.Group>




                  </Col>}
                  {/* {data?.sending_type == "later" && 
                  <Col lg="3">
                    <Form.Group className="form-group">
                    <Form.Label htmlFor="fullname">Am/Pm</Form.Label>
                    <Form.Select
                      id="priority"
                      aria-label="Default select example"
                      onChange={(dataFromComp) => setdata({...data, ...dataFromComp})}
                      name="minutes"
                  
                    >
                       

                      <option value="">Select</option>
                      <option value="am">Am</option>
                      <option value="pm">Pm</option>
                      
                    </Form.Select>

                    
                   
                    </Form.Group>
                  </Col>
} */}
                </Row>
              </Col>
              <Col md="3"></Col>
              <Col md="12" className="my-5">
                <Button disabled={!checkValidation()} onClick={() => setCount((count) => count + 1)}>Next</Button>
                {/* <SubmitButton
                  text="Submit"
                  onClick={() => sendTicket()}
                  loader={loader}
                  disabled={!checkValidation()}
                /> */}
              </Col>
            </Row>
          </Card.Body>}
          {count == 1 && <Card.Body>
            <Tab.Container id="assign-tabs" defaultActiveKey="student-test">
              {/* <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                <Nav.Item>
                  <Nav.Link eventKey="batch-test">Batch</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="student-test">Student</Nav.Link>
                </Nav.Item>
              </Nav> */}
              <Tab.Content>
                <Tab.Pane eventKey="batch-test">
                  <Tab.Container id="batch-tabs" defaultActiveKey="batch-details">
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="batch-details">Batch Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="test-details">Test Details</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="batch-details">
                        <Row className="g-gs my-3">
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="program-text">Program</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control type="text" id="program-text" value="PTE" disabled />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText5">Choose Plan</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Select id="exampleFormControlInputText5" aria-label="Default select example">
                                  <option value="0">Choose Plan</option>
                                  <option value="1">Free</option>
                                  <option value="2">VIP 30 Days</option>
                                  <option value="3">VIP 90 Days</option>
                                  <option value="4">VIP 180 Days</option>
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="program-text">Program</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control type="text" id="program-text" value="PTE" disabled />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText5">Student Type</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Select id="exampleFormControlInputText5" aria-label="Default select example">
                                  <option value="0">Choose Student Type</option>
                                  <option value="1">Single Test</option>
                                  <option value="2">Bulk Test</option>
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <ButtonGroup aria-label="Basic radio toggle button group" className="hilingo-radio-button gap-3">
                              <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                              <Button as="label" variant="outline-primary" htmlFor="btnradio3" className="flex-column">PTE_DEFAULT <p>(11:00 AM 11:01 AM)</p></Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <h5>BATCH DETAILS</h5>
                            {/* You can use data list component */}
                            <Table responsive striped bordered hover className="small">
                              <thead>
                                <tr className="bg-primary">
                                  <th className="text-white">Batch Name</th>
                                  <th className="text-white">Teacher</th>
                                  <th className="text-white">No. of Students</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>PTE_default</td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                      <Form.Check label="" type="checkbox" id="inlineCheckbox1"></Form.Check>
                                      <span> Teacher</span>
                                      <Button variant="transparent"><Icon name="search"></Icon></Button>
                                    </div>
                                  </td>
                                  <td>0/0</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                      <Form.Check label="" type="checkbox" id="inlineCheckbox1"></Form.Check>
                                      <span> Teacher</span>
                                      <Button variant="transparent"><Icon name="search"></Icon></Button>
                                    </div>
                                  </td>
                                  <td>0/0</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>Total Students</td>
                                  <td>1</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="test-details">
                        <Tab.Container id="assign-tabs" defaultActiveKey="sectionwise-test">
                          <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                            <Nav.Item>
                              <Nav.Link eventKey="sectionwise-test">Section Wise Test</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="mock-test">Mock Test</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="sectionwise-test">
                              {/* You can use data list component */}
                              <Table responsive striped bordered hover className="small">
                                <thead>
                                  <tr className="bg-primary">
                                    <th></th>
                                    <th className="text-white">Test Name</th>
                                    <th className="text-white">Module</th>
                                    <th className="text-white">Category Name</th>
                                    <th className="text-white">Level</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-01(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-02(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-03(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-04(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 01</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 02</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 03</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 04</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Hard</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Tab.Pane>
                            <Tab.Pane eventKey="mock-test">
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane>

                <Tab.Pane eventKey="student-test">
                  <Tab.Container id="student-tabs" defaultActiveKey="select-student">
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="select-student">Select Student</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item className="w-50">
                        <Nav.Link eventKey="student-test-details">Test Details</Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="select-student">
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <h5>SELECT STUDENT</h5>
                            <Form.Group className="form-group my-3">
                              <div className="form-control-wrap">
                                <div className="form-control-icon end"><Icon name="search"></Icon></div>
                                <Form.Control type="text" placeholder="Student Name" />
                              </div>
                            </Form.Group>
                            {/* You can use data list component */}
                            <Table responsive striped bordered hover className="small">
                              <thead>
                                <tr className="bg-primary">
                                  <th className="text-white">Sr No.</th>
                                  <th className="text-white">Email</th>
                                  <th className="text-white">Student Name</th>
                                  <th className="text-white">Product</th>
                                  <th className="text-white">Mobile</th>
                                  <th className="text-white">Techers</th>
                                  {/* <th className="text-white">Batch</th> */}
                                  {/* <th className="text-white"></th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {studentList.length > 0 ? (
                                  studentList.map((item, index) => {
                                    return (
                                      <tr key={item._id}>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            onClick={() => {
                                              if (data.user_id.includes(item._id)) {
                                                setdata(prevData => ({
                                                  ...prevData,
                                                  user_id: prevData.user_id.filter(id => id !== item._id)
                                                }));
                                              } else {
                                                let arr = [...data.user_id]
                                                arr.push(item._id)
                                                setdata(prevData => ({
                                                  ...prevData,
                                                  user_id: arr
                                                }));
                                              }
                                            }}
                                            id={`flexRadioDefault-${index}`}
                                            label={index}
                                          />
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>{item?.name}</td>
                                        <td>{item?.product}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item?.stu_profile?.assignTeacher}</td>
                                        {/* <td>PTE_default</td> */}
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="6">No record found</td>
                                  </tr>
                                )}

                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="student-test-details">
                        <Tab.Container id="assign-tabs" defaultActiveKey="sectionwise-test">
                          <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                            <Nav.Item>
                              <Nav.Link eventKey="sectionwise-test">Section Wise Test</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="mock-test">Mock Test</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="sectionwise-test">
                              {/* You can use data list component */}
                              <Table responsive striped bordered hover className="small">
                                <thead>
                                  <tr className="bg-primary">
                                    <th></th>
                                    <th className="text-white">Test Name</th>
                                    <th className="text-white">Module</th>
                                    <th className="text-white">Category Name</th>
                                    <th className="text-white">Level</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-01(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-02(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-03(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-04(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 01</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 02</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 03</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 04</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Hard</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Tab.Pane>
                            <Tab.Pane eventKey="mock-test">
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <Button className="fw-bold" size="sm" style={{ marginRight: "0.5rem" }} onClick={() => setCount((count) => count - 1)}>Previous</Button>
            <SubmitButton
              text="Submit"
              onClick={() => sendTicket()}
              loader={false}
              disabled={!checkValidation()}
            />
          </Card.Body>}
        </Card>
      </Block>
    </Layout>
  );
}

export default EditNotification;
