import { Button, Card, Table, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import AuthApi from "../../api/auth";
import { useContext, useState, useEffect } from "react";
import Context from "../../context";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useNavigate } from "react-router-dom";
function AddCredits() {
  let navigate = useNavigate();
  const [count, setCount] = useState(1);
  let { domainData, userData, setUserData, setDomainData } =
    useContext(Context);
  domainData = JSON.parse(domainData);
  userData = JSON.parse(userData);
  let api = new AuthApi();
  let apiAdmin = new AdminApi();
  const [data, setData] = useState({
    amount: 5000,
    user_id: userData?._id,
    GST: 18,
  });
  const [promocodeData, setPromocodeData] = useState({});
  const [plan, setPlan] = useState([]);
  const [promocode, setPromocode] = useState(false);
  const [promocodeText, setPromocodeText] = useState(null);
  let includingGSTPrice = data.amount + (data.amount * data.GST) / 100;
  let includingCouponPrice =
    promocodeData.discountType == "amount"
      ? includingGSTPrice - Number(promocodeData.value)
      : includingGSTPrice -
        (includingGSTPrice * Number(promocodeData.value)) / 100;

  useEffect(() => {
    getPlanPricing();
  }, []);

  const getPlanPricing = async () => {
    await apiAdmin
      .get_planall()
      .then((res) => {
        console.log("ress", res);
        if (res?.data?.length > 0) {
          setPlan(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const applyPromocde = async () => {
    if (promocodeText) {
      await api
        .verify_promo_code({
          promo_code: promocodeText,
          login_type: userData.login_type,
          plan_id: data?.plan_id,
        })
        .then((res) => {
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Coupon Applied!",
              text: res.msg,
            });
            setPromocodeData(res.data[0]);
            // navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
          // if (res?.data?.length > 0) {
          //   setPlan(res.data.reverse());
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const createOrder = async () => {
    try {
      await api
        .createOrder({
          amount:
            (!promocode.value && parseInt(includingCouponPrice * 100)) ||
            parseInt(includingGSTPrice * 100),
            credit_amount:data?.amount,
          currency: "INR",
          user_id: data.user_id,
          plan_id:userData?.planId
        })
        .then(async (response) => {
          if (response.status) {
            const res = await loadScript(
              "https://checkout.razorpay.com/v1/checkout.js"
            );

            if (!res) {
              alert("Razorpay SDK failed to load. Are you online?");
              return;
            }
            console.log("res", response);
            const options = {
              key: "rzp_test_jHyOcCc7kzwkl4",
              // amount: includeGstprice,
              currency: "INR", // your 3 letter currency code
              name: domainData?.domain_name, // project or transaction name
              description: "Top up recharge",
              order_id: response.order.id,
              image: domainData?.inst_profile?.business_logo, // your project logo
              handler: function (response) {
                console.log("rer",response);
                orderPlace(response); // after payment completes on stripe this function will be called and you can do your stuff
              },
              // prefill: {
              //   name: "Hilingo",
              //   email: "infohilingo@gmail.com",
              //   contact: "9878243223",
              // },
              notes: {
                address: "India",
              },
              theme: {
                color: domainData?.inst_profile?.color_code,
              },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  const orderPlace = async (params) => {
    await apiAdmin
      .verifyOrder({ ...params,institue_id:userData?._id })
      .then((res) => {
        console.log("res", res);
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: "Payment Verified",
          text: res.msg,
        });
        setUserData(JSON.stringify(res.user));
        localStorage.setItem("userHilingo", JSON.stringify(res.user));
        localStorage.setItem("instDomainHilingo", JSON.stringify(res.user));
        setDomainData(JSON.stringify(res.user));

        // setPromocodeData(res.data[0]);
        navigate("/my-account/add-credits");

        // if (res?.data?.length > 0) {
        //   setPlan(res.data.reverse());
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("order placed");
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // const pay = async () => {
  //   let amount = 100;
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );

  //   if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     return;
  //   }

  //   const options = {
  //     key: "rzp_test_jHyOcCc7kzwkl4", // This is Api key. you will get it from razorpay dashboard > account and settings > API keys
  //     amount: parseInt(amount * 100),
  //     currency: "INR", // your 3 letter currency code
  //     name: "Hilingo", // project or transaction name
  //     description: "Test Transaction",
  //      order_id: order.id,
  //     image: "images/hilingo-logo.png", // your project logo
  //     handler: function (response) {
  //       console.log("***",response)
  //       // console.log("response", response);
  //       orderPlace(); // after payment completes on stripe this function will be called and you can do your stuff
  //     },
  //     prefill: {
  //       name: "Hilingo",
  //       email: "infohilingo@gmail.com",
  //       contact: "9878243223",
  //     },
  //     notes: {
  //       address: "India",
  //     },
  //     theme: {
  //       color: "#158993",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };
  const handleChange = (value) => {
    setData({ ...data, amount: value });
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add Credit</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      {console.log("count", count)}
      {count == 1 ? (
        <Block>
          <Card className="card-gutter-md w-75 mx-auto h-100">
            <Card.Body>
              <div className="d-flex flex-column gap-3">
                <h5>ADD CREDIT </h5>
                <div className="bg-primary rounded px-3 d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="text-white">BALANCE</h5>
                  <h3 className="text-white">{userData.credit}</h3>
                  </div>
                  <img src="/images/wallet.gif"></img>
                </div>
                <h5>Quick Options For Payment</h5>
                <div className="addcredit-btn">
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="addcredit-1"
                    autoComplete="off"
                  />
                  <Button
                    as="label"
                    variant="outline-primary"
                    htmlFor="addcredit-1"
                    onClick={(e) => handleChange(5000)}
                  >
                    +5000
                  </Button>

                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="addcredit-2"
                    autoComplete="off"
                  />
                  <Button
                    as="label"
                    variant="outline-primary"
                    htmlFor="addcredit-2"
                    onClick={(e) => handleChange(10000)}
                  >
                    +10000
                  </Button>

                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="addcredit-3"
                    autoComplete="off"
                  />
                  <Button
                    as="label"
                    variant="outline-primary"
                    htmlFor="addcredit-3"
                    onClick={(e) => handleChange(15000)}
                  >
                    +15000
                  </Button>

                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="addcredit-4"
                    autoComplete="off"
                  />
                  <Button
                    as="label"
                    variant="outline-primary"
                    htmlFor="addcredit-4"
                    onClick={(e) => handleChange(20000)}
                  >
                    +20000
                  </Button>
                </div>
                <h5>OR</h5>
                <Form.Group className="form-group">
                  <div className="form-control-wrap">
                    <Form.Control
                      type="text"
                      id="exampleFormControlInputText1"
                      value={data.amount}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </div>
                </Form.Group>
                <Button variant="primary" className="theme-colored" onClick={() => setCount(count + 1)}>
                  Pay Now
                </Button>
                <span className="text-secondary">
                  <b>Note : </b> Upgrade to any plan to add credit in your
                  wallet. <a href="#">Upgrade Now</a>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Block>
      ) : (
        <Block>
          <Card className="card-gutter-md w-75 mx-auto h-100">
            <Card.Body>
              <div className="d-flex flex-column gap-3">
                <h5>Purchase Summary</h5>
                <div className="d-flex justify-content-between">
                  <b>Credit</b>
                  <b>{data.amount}</b>
                </div>
                <div className="d-flex justify-content-between">
                  <small>Price</small>
                  <small>INR {data.amount}</small>
                </div>
                <div className="d-flex justify-content-between">
                  <small>GST (18%)</small>
                  <small>INR {(data.amount * data.GST) / 100}</small>
                </div>
                <div className="d-flex justify-content-between">
                  <h3>Total : </h3>
                  <h3 style={{ "text-align": "right" }}>
                    INR{" "}
                    {!promocodeData.value ? (
                      includingGSTPrice
                    ) : (
                      <>
                        {" "}
                        <strike>{includingGSTPrice}</strike>
                        <br />
                        Sub Total:- {includingCouponPrice}
                        <br />
                      </>
                    )}{" "}
                  </h3>
                </div>
                <Form.Check
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioChecked"
                  defaultChecked
                  onClick={() => {
                    setPromocode(false);
                    setPromocodeData({});
                  }}
                  label="I don't have Promocode"
                />
                <Form.Check
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioChecked"
                  onClick={() => setPromocode(true)}
                  label="I have a Promocode"
                />

                {promocode && (
                  <div className="d-flex gap-5">
                    <Form.Group className="form-group w-50">
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="exampleFormControlInputText1"
                          onChange={(e) => setPromocodeText(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                    {!promocodeData.value ? (
                      <Button onClick={applyPromocde} variant="primary">
                        Apply
                      </Button>
                    ) : (
                      <Button onClick={applyPromocde} variant="success">
                        Applied
                      </Button>
                    )}
                  </div>
                )}
                <div
                  className="border p-2 text-center text-secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setCount(count - 1)}
                >
                  Previous
                </div>
                <Button variant="primary" onClick={createOrder}>
                  Checkout Now
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Block>
      )}
    </Layout>
  );
}

export default AddCredits;
