import { useContext, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import '../../../assets/scss/libs/editors/tinymce.scss';
import ModalContext from '../../../context/ModalContext';

function Tinymce({ height, toolbar, menubar, inline, initialValue, ...props }) {
    let { popUpType, setPopUpType,setAnswerJson,answerJson } = useContext(ModalContext);
    const editorRef = useRef(null);

    const handleEditorChange = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent({ format: 'text' });
            console.log(content)
            setAnswerJson({...answerJson,answer:content})
            // onEditorContentChange(content);
        }
    };

    return (
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={initialValue}
            init={{
                plugins: 'wordcount',
                height: height || 300,
                skin: false,
                branding: false,
                toolbar: 'cut copy paste | undo redo ',
                wordcount: true, // Enable word count
                menubar: menubar,
                inline: inline,
                formats: {
                    removeformat: [
                        { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li', remove: 'all', split: true, expand: false, block_expand: true, deep: true }
                    ]
                }
            }}
            onEditorChange={handleEditorChange}
        />
    )
}

export default Tinymce;
