import exportFromJSON from "export-from-json";
import { Button } from "react-bootstrap";
import React, { useContext } from "react";
import Context from "../../context";
const Export = ({ data1 }) => {
  const fileName = "user-data";
  const myExcelData = new Array();
  data1?.map((item) => {
    const enquiryDate = new Date(item?.createdAt).toISOString().split("T")[0];
    const studentName = item?.name;
    const phone = item?.phone;
    const email = item?.email;
    const teamMember = item?.teamMember;
    const nextFollowup = item?.nextFollowUpDate;
    const enquiryType = item?.program;

    const obj = {
      enquiryDate,
      studentName,
      phone,
      email,
      teamMember,
      nextFollowup,
      enquiryType,
    };
    myExcelData.push(obj);
  });
  // console.log(myExcelData, "myExcelData", data1);
  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
      data: myExcelData,
      fileName,
      fields: {
        enquiryDate: "ENQUIRY DATE",
        studentName: "STUDENT NAME",
        phone: "PHONE NUMBER",
        email: "EMAIL ID",
        teamMember: "TEAM MEMBER",
        nextFollowup: "NEXT FOLLOWUP DATE",
        enquiryType: "ENQUIRY TYPE",
      },
      exportType,
    });
  };
  let { domainData } = useContext(Context);
  domainData = JSON.parse(domainData);
  return (
    <div className="export-options d-flex ">
      <Button variant="primary" onClick={() => exportExcel()}  style={{backgroundColor:domainData?.inst_profile?.color_code}}>
        Export
      </Button>
    </div>
  );
};
export default Export;
