import { Form } from "react-bootstrap";

function DatePickerOnChange({
  startDate,
  endDate,
  className,
  onChange,
  name,
  value,
  ...props
}) {
  const {
    autoHide,
    clearBtn,
    format,
    maxView,
    pickLevel,
    startView,
    title,
    todayBtn,
    weekStart,
  } = props;
  const getFormattedToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");

    // return `${year}-${month}-${day}`;
    return `${year}-${month}-${day} ${hours} ${minutes}`;

  };
  return (
    <Form.Control
      placeholder="yyyy-mm-dd"
      type="date"
      value={value}
      autoComplete="off"
      onChange={onChange}
      name={name}
      max={getFormattedToday()}
    />
  );
}

export default DatePickerOnChange;
