import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
function ScoreComp() {
  return (
    <>
      <div className='d-flex gap-2 mt-2'>
        <Button variant='outline-primary' size='sm'>RL V1.0</Button>
        <Button variant='outline-assigned' size='sm'>Score Info 10/90</Button>
      </div>
    </>
  );
}
export default ScoreComp;