import { useContext } from "react";
import AdminApi from "../../../../api/admin";
import ModalContext from "../../../../context/ModalContext";

export async function add_answer_sheet(
  data,
  setAnswerJson,
  module_name,
  module,
  current_qst_data
) {
  try {
    console.log("module_name", data);
    let api = new AdminApi();
    if (
      module_name != "ro" &&
      (!data.hasOwnProperty("answer") || !data.answer)
    ) {
      data.answer = "NA";
    }
    if (
      (data.audio_url && module == "speaking") ||
      module == "writing" ||
      module_name === "ssts"
    ) {
     
      await api
        .add_answer_sheet({ ...data })
        .then((res) => {
          if (res.status) {
            setAnswerJson((prevAnswerJson) => ({
              ...prevAnswerJson,
              id: res.id,
              answer: "",
              audio_url: "",
            }));
          } else {
            setAnswerJson((prevAnswerJson) => ({
              ...prevAnswerJson,
              id: res.id,
              answer: "",
              audio_url: "",
            }));
          }
        })
        .catch((err) => {
          setAnswerJson((prevAnswerJson) => ({
            ...prevAnswerJson,
            answer: "",
            audio_url: "",
          }));
        });
    } else {
      
      var answer = "";
      if (module_name == "ro") {
        // original
        // var answerNode = document.getElementById("rightId").children;

        // for (let i = 0; i < answerNode.length; i++) {
        //   var ans = answerNode[i].children[0].getAttribute(
        //     "data-rbd-drag-handle-draggable-id"
        //   );
        //   ans = ans.replace("content-", "");
        //   ans = Number(ans) + 1;
        //   ans = String(ans);
        //   answer += ans + ",";
        // }

        var answerNode = document.getElementsByClassName(
          "smooth-dnd-container vertical"
        )[2].children;
        console.log("answerNode", answerNode);
        for (let i = 0; i < answerNode.length; i++) {
          var ans = answerNode[i].children[0].children[1].innerHTML[0];
          if (i == answerNode.length - 1) {
            answer += ans;
          } else {
            answer += ans + ",";
          }
        }
      } else if (module_name == "fib_rd") {
        var answerNode = document.getElementsByClassName("css-1cp7cqg");
        for (var i = 0; i < answerNode.length - 2; i++) {
          const ans = answerNode[i].innerHTML;
          answer += ans + ",";
        }
        console.log("ASNNODE",answerNode);
      }
      await api
      
        .add_answer_sheet({ ...data, answer: answer ? answer : data?.answer })
        .then((res) => {
          if (res.status) {
            setAnswerJson((prevAnswerJson) => ({
              ...prevAnswerJson,
              id: res.id,
              answer: "",
              audio_url: "",
            }));
          }else{
            setAnswerJson((prevAnswerJson) => ({
              ...prevAnswerJson,
              id: res.id,
              answer: "",
              audio_url: "",
            }));
          }
        })
        .catch((err) => {
          setAnswerJson((prevAnswerJson) => ({
            ...prevAnswerJson,
            answer: "",
            audio_url: "",
          }));
        });
    }
  } catch {
    setAnswerJson((prevAnswerJson) => ({
      ...prevAnswerJson,
      id: data?.id,
      answer: "",
      audio_url: "",
    }));
  }
}

// PYTHON API INTEGRATED CODE FORM MY SIDE

// import { useContext } from "react";
// import AdminApi from "../../../../api/admin";
// import ModalContext from "../../../../context/ModalContext";

// export async function add_answer_sheet(
//   data,
//   setAnswerJson,
//   module_name,
//   module,
//   current_qst_data
// ) {
//   try{
//   console.log("module_name", data);
//   let api = new AdminApi();
//   if (module_name != 'ro' &&(!data.hasOwnProperty("answer") || !data.answer)) {
//     data.answer = "NA";
//   }
//   if (data.audio_url && module == "speaking") {
//     var S3Url = data.audio_url;
//     let path = data.module_name;
//     var lastIndex = S3Url.lastIndexOf("answers");
//     var desiredPart = S3Url.substring(lastIndex);

//     let data_ = JSON.stringify({
//       title:module_name == 'repeat_sentences'?data?.question_transcript: data.question_content ? data.question_content : "",
//       file_key: desiredPart,
//       language: "en",
//       sample_answer:
//         data?.question_options?.length && data?.question_options[0].answer
//           ? data.question_options[0]?.answer.length
//             ? data.question_options[0]?.answer[0]
//             : ""
//           : "",
//     });

//     await fetch(
//       "https://speechengine.hilingo.com/GetTextFromRecordedAudio",
//       {
//         method: "post",
//         body: data_,
//         headers: {
//           "X-Api-Key": "",
//           "Content-Length": data_.length,
//           Host: "speechengine.hilingo.com",
//         },
//       }
//     )
//       .then((res) => res.json())
//       .then(async (api_res) => {
//         if (api_res) {
//           await api
//             .add_answer_sheet({ ...data, api_response: { ...api_res } })
//             .then((res) => {
//               if (res.status) {
//                 setAnswerJson((prevAnswerJson) => ({
//                   ...prevAnswerJson,
//                   id: res.id,
//                   answer: "",
//                   audio_url: "",
//                 }));
//               } else {
//                 setAnswerJson((prevAnswerJson) => ({
//                   ...prevAnswerJson,
//                   id: res.id,
//                   answer: "",
//                   audio_url: "",
//                 }));
//               }
//             })
//             .catch((err) => {
//               setAnswerJson((prevAnswerJson) => ({
//                 ...prevAnswerJson,
//                 answer: "",
//                 audio_url: "",
//               }));
//             });
//         }
//       }).catch((err)=>{
//         setAnswerJson((prevAnswerJson) => ({
//           ...prevAnswerJson,
//           id: data?.id,
//           answer: "",
//           audio_url: "",
//         }));
//       });
//   } else if (module == "writing") {
//     console.log("data.answer",data.answer)
//     if (data.answer!== "NA") {
//       let data_ = JSON.stringify({
//         title: current_qst_data.question_content
//           ? JSON.stringify(current_qst_data.question_content)
//           : "",
//         language: "en",
//         answer: JSON.stringify(data.answer),
//         sample_answer: "",
//       });
//       await fetch(module_name == "essays"?"https://speechengine.hilingo.com/GetEssayTextScore":"https://speechengine.hilingo.com/GetSummarizeTextScore", {
//         method: "post",
//         body: data_,
//         headers: {
//           "X-Api-Key": "",
//           "Content-Length": data_.length,
//           Host: "speechengine.hilingo.com",
//         },
//       })
//         .then((res) => res.json())
//         .then(async (api_res) => {
//           if (api_res) {
//             await api
//               .add_answer_sheet({ ...data, api_response: { ...api_res } })
//               .then((res) => {
//                 if (res.status) {
//                   setAnswerJson((prevAnswerJson) => ({
//                     ...prevAnswerJson,
//                     id: res.id,
//                     answer: "",
//                     audio_url: "",
//                   }));
//                 } else {
//                   setAnswerJson((prevAnswerJson) => ({
//                     ...prevAnswerJson,
//                     id: res.id,
//                     answer: "",
//                     audio_url: "",
//                   }));
//                 }
//               }).catch((err)=>{
//                 setAnswerJson((prevAnswerJson) => ({
//                   ...prevAnswerJson,
//                   id: data?.id,
//                   answer: "",
//                   audio_url: "",
//                 }));
//               });
//           }
//           //   setState({ ...state, loader_modal: false, api_res: data });
//         })
//     } else {
//       let essay = {
//         "content_score": 2,
//         "form_score": 0,
//         "grammar_score": 0,
//         "vocabulary_score": 0,
//         "actual_score": 12.6,
//         "coherence_score": 0,
//         "linguistic_range": 0,
//         "spelling": 2,
//         "total_score": 2,
//         "max_score": 15,
//         "grammar": []
//     }
//     let summarize_written = {
//       content_score: 0,
//       form_score: 0,
//       grammar_score: 0,
//       vocabulary_score: 0,
//       total_score: 0,
//       max_score: 7,
//       actual_score: 10,
//       grammar: [],
//     }
//       await api
//         .add_answer_sheet({
//           ...data,
//           api_response:module_name === "essays"?essay:module_name === "swts"? summarize_written:"" ,
//         })
//         .then((res) => {
//           if (res.status) {
//             setAnswerJson((prevAnswerJson) => ({
//               ...prevAnswerJson,
//               id: res.id,
//               answer: "",
//               audio_url: "",
//             }));
//           } else {
//             setAnswerJson((prevAnswerJson) => ({
//               ...prevAnswerJson,
//               id: res.id,
//               answer: "",
//               audio_url: "",
//             }));
//           }
//         });
//     }
//   } else if (module_name === "ssts") { if (data.answer!== "NA") {
//     let data_ = JSON.stringify({
//       title: current_qst_data.question_transcript
//         ? JSON.stringify(current_qst_data.question_transcript)
//         : "",
//       language: "en",
//       answer: JSON.stringify(data.answer),
//       sample_answer: "",
//     });

//     await fetch("https://speechengine.hilingo.com/GetSummarizeListenScore", {
//       method: "post",
//       body: data_,
//       headers: {
//         "X-Api-Key": "",
//         "Content-Length": data_.length,
//         Host: "speechengine.hilingo.com",
//       },
//     })
//       .then((res) => res.json())
//       .then(async (api_res) => {
//         if (api_res) {
//           await api
//             .add_answer_sheet({ ...data, api_response: { ...api_res } })
//             .then((res) => {
//               if (res.status) {
//                 setAnswerJson((prevAnswerJson) => ({
//                   ...prevAnswerJson,
//                   id: res.id,
//                   answer: "",
//                   audio_url: "",
//                 }));
//               } else {
//                 setAnswerJson((prevAnswerJson) => ({
//                   ...prevAnswerJson,
//                   id: res.id,
//                   answer: "",
//                   audio_url: "",
//                 }));
//               }
//             }).catch((err)=>{
//               setAnswerJson((prevAnswerJson) => ({
//                 ...prevAnswerJson,
//                 id: data?.id,
//                 answer: "",
//                 audio_url: "",
//               }));
//             });
//         }
//         //   setState({ ...state, loader_modal: false, api_res: data });
//       });
//   } else {
//     await api
//       .add_answer_sheet({
//         ...data,
//         api_response: {
//             "content_score": 2,
//             "form_score": 0,
//             "grammar_score": 0,
//             "vocabulary_score": 0,
//             "actual_score": 12.6,
//             "coherence_score": 0,
//             "linguistic_range": 0,
//             "total_score": 2,
//             "max_score": 15,
//             "grammar": []
//         }
//       })
//       .then((res) => {
//         if (res.status) {
//           setAnswerJson((prevAnswerJson) => ({
//             ...prevAnswerJson,
//             id: res.id,
//             answer: "",
//             audio_url: "",
//           }));
//         } else {
//           setAnswerJson((prevAnswerJson) => ({
//             ...prevAnswerJson,
//             id: res.id,
//             answer: "",
//             audio_url: "",
//           }));
//         }
//       });
//   }} else {
//     var answer = "";
//     if(module_name == 'ro'){
//       var answerNode = document.getElementById("rightId").children;

//       for (let i = 0; i < answerNode.length; i++) {
//         var ans = answerNode[i].children[0].getAttribute(
//           "data-rbd-drag-handle-draggable-id"
//         );
//         ans = ans.replace("content-", "");
//         ans = Number(ans) + 1;
//         ans = String(ans);
//         answer += ans + ",";
//       }
//     }
//     await api
//       .add_answer_sheet({ ...data,answer:answer?answer:data?.answer, })
//       .then((res) => {
//         if (res.status) {
//           setAnswerJson((prevAnswerJson) => ({
//             ...prevAnswerJson,
//             id: res.id,
//             answer: "",
//             audio_url: "",
//           }));
//         }
//       })
//       .catch((err) => {
//         setAnswerJson((prevAnswerJson) => ({
//           ...prevAnswerJson,
//           answer: "",
//           audio_url: "",
//         }));
//       });
//   }
// }catch{
//   setAnswerJson((prevAnswerJson) => ({
//     ...prevAnswerJson,
//     id: data?.id,
//     answer: "",
//     audio_url: "",
//   }));
// }
// }
