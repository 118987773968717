import { useEffect, useState } from "react";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import AuthApi from "../../../api/auth";
import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";
import { Icon, Select } from "../../../components";
import InstituteDataTable from "../TeamDataTable";
import Swal from "sweetalert2/src/sweetalert2.js";
// import InstituteData, { InstituteColumns } from './InstituteData';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../../components";
import AdminApi from "../../../api/admin";
import DeleteModal from "../Modal/DeleteModal";

function PattrenList() {
  let api = new AdminApi();
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    status:false,
    id:null,
  });
  const [data, setData] = useState([]);
  const handleShowModal = () => {
    setShowModal({...showModal,status:!showModal.status,id:""});
  };
  console.log("status",showModal.status)
  useEffect(() => {
    getPatternList();
  }, []);

  const getPatternList = async () => {
    await api
      .get_pattern()
      .then((res) => {
        console.log("ress", res);
        if (res?.question?.length > 0) {
          setData(res.question.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(data);
  const deletePAttern = async (id) => {
    await api
      .delete_pattern(id)
      .then((res) => {
        if (res.status) {
          setShowModal({...showModal,status:!showModal.status,id:""});
          setData(res.data.reverse());
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const InstituteColumns = [
    {
      name: "Pattern Name",
      selector: (row) => row?.code,
      cell: (row) => <span className="text-middark">{row?.pattern}</span>,
      sortable: true,
    },
    {
      name: "Speaking ",
      selector: (row) => row.inst_profile?.inst_contact_firstname,
      cell: (row) => (
        <span className="text-middark">{row?.speaking?.total_question}</span>
      ),
      sortable: true,
    },
    {
      name: "Reading",
      selector: (row) => row.attempt,
      cell: (row) => (
        <span className="text-middark">{row?.reading?.total_question}</span>
      ),
      sortable: true,
    },
    {
      name: "Writing",
      selector: (row) => row.score,
      cell: (row) => (
        <span className="text-middark">{row?.writing?.total_question}</span>
      ),
      sortable: true,
    },
    {
      name: "Listening",
      selector: (row) => row.score,
      cell: (row) => (
        <span className="text-middark">{row?.listening?.total_question}</span>
      ),
      sortable: true,
    },
    {
      name: "Pattern Type",
      selector: (row) => row.score,
      cell: (row) => (
        <span className="text-middark">{row?.module}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            {/* <LinkListItem to={`/edit-pattern/${row._id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem> */}
            <div
              className="del-btn"
              onClick={() => {
                !row?.isUsed
                  ? navigate(`/edit-pattern/${row._id}`)
                  : Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "This Pattern is Already used in Test Can't ",
                    });
              }}
            >
   <Icon name="edit"></Icon>
            </div>
            <div
              className="del-btn"
              onClick={() => {
                !row?.isUsed
                  ? setShowModal({...showModal,status:true,id:row?._id})
                  : Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "This Pattern is Already used in Test ",
                    });
              }}
            >
              <Icon name="trash"></Icon>
            </div>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Pattern List</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                {/* <h5>Staff List</h5> */}
                <InstituteDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  columns={InstituteColumns}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
      <DeleteModal show={showModal.status} handleShowModal={handleShowModal} deleted={()=>deletePAttern(showModal?.id)} />
    </Layout>
  );
}

export default PattrenList;
