import { Link } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";
import { toInitials } from "../../../utilities";

// user table column

export const InstituteColumns = [
    {
        name: <Form.Check className="form-check" id="check-all" />,
        cell: (row) => (
            <div className="text-start w-100">
                <Form.Check className="form-check" />
            </div>
        ),
        sortable: false,
    },
    {
        name: "Sno",
        selector: (row) => row.sno,
        cell: (row) => (
            <span className="text-middark">{row.sno}</span>
        ),
        sortable: true,
    },
    {
        name: "ID",
        selector: (row) => row.id,
        cell: (row) => (
            <span className="text-middark">{row.id}</span>
        ),
        sortable: true,
    },
    {
        name: "Tag",
        selector: (row) => row.tag,
        cell: (row) => (
            <span className="text-middark">{row.tag}</span>
        ),
        sortable: true,
    },
    {
        name: "Type",
        grow: "2",
        selector: (row) => row.type,
        cell: (row) => (
            <span className="text-middark">{row.type}</span>
        ),
        sortable: true,
    },
    {
        name: "Questions Name",
        grow: "4",
        selector: (row) => row.quesname,
        cell: (row) => (
            <span className="text-middark">{row.quesname}</span>
        ),
        sortable: true,
    }
];

// users data

const InstituteData = [
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        type: 'Practiced',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ],
        section:'Speaking',
        item: 'RL',
        correct:'11.0%',
        target:'Buy VIP'
    },
    {
        sno: '2',
        id: 'uid02',
        quesname: 'Lois b',
        tag: '1234',
        type: 'Not Practiced',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '3',
        id: 'uid03',
        quesname: 'Lois c',
        tag: '1235',
        type: 'Bookmarked',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    }
];

// View Test Column

export const ViewTestColumn=[
    {
        name: <Form.Check className="form-check" id="check-all" />,
        cell: (row) => (
            <div className="text-start w-100">
                <Form.Check className="form-check" />
            </div>
        ),
        sortable: false,
    },
    {
        name: "Test Name",
        selector: (row) => row.sno,
        cell: (row) => (
            <span className="text-middark">{row.sno}</span>
        ),
        sortable: true,
    },
    {
        name: "Module",
        selector: (row) => row.id,
        cell: (row) => (
            <span className="text-middark">{row.id}</span>
        ),
        sortable: true,
    },
    {
        name: "Category Name",
        selector: (row) => row.tag,
        cell: (row) => (
            <span className="text-middark">{row.tag}</span>
        ),
        sortable: true,
    },
    {
        name: "Level",
        grow: "2",
        selector: (row) => row.type,
        cell: (row) => (
            <span className="text-middark">{row.type}</span>
        ),
        sortable: true,
    }
];

// View Test List

export const ViewTestList = [
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        type: 'Practiced',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '2',
        id: 'uid02',
        quesname: 'Lois b',
        tag: '1234',
        type: 'Not Practiced',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '3',
        id: 'uid03',
        quesname: 'Lois c',
        tag: '1235',
        type: 'Bookmarked',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        sno: '1',
        id: 'uid01',
        quesname: 'Lois A',
        tag: '1233',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    }
]

export default InstituteData;
