import { useState } from 'react';
import Dropzone from 'react-dropzone';
import Swal from "sweetalert2/src/sweetalert2";
import { Button } from 'react-bootstrap';
import Icon from '../../../components/Icon/Icon';

function FileUpload({filetype,iconName, name, maxFiles, maxSize, errorText,onChange,noImage , ...props}) {
  const [files, setFiles] = useState([]);

  // convert file size bytes to MB
  const bytesToMegaBytes = bytes => bytes / (1024 ** 2);

// handles ondrop function of dropzone
const handleDropChange = (acceptedFiles) => {
  const updatedFiles = acceptedFiles.map((file) =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),

    })
  );
  console.log("updatedFiles",updatedFiles)
  if(filetype == "image"){
  if(!updatedFiles[0].type.includes("image")){
    Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select Image file",
      }); 
  }else{
    setFiles(updatedFiles);
    if (onChange ) {
      onChange(updatedFiles); 
    }
  }}
  if(filetype == "video"){
  if(!updatedFiles[0].type.includes("video")){
    Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select Video file",
      }); 
  }else{
    setFiles(updatedFiles);
    if (onChange ) {
      onChange(updatedFiles); 
    }
  }}
  if(filetype=="audio"){
  if(!updatedFiles[0].type.includes("audio")){
    Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select Audio file",
      });
    return    
  }else{
    setFiles(updatedFiles);
    if (onChange ) {
      onChange(updatedFiles); 
    }
  }}
  if(filetype=="document"){
  if(!updatedFiles[0].type.includes("pdf") && !updatedFiles[0].type.includes("text")  &&   !updatedFiles[0].type.includes("doc") && !updatedFiles[0].type.includes("docx")&& !updatedFiles[0].type.includes("rtf")){
    Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select Document File(.pdf,.txt,.doc,.docx,.rtf etc)",
      });
    return    
  }else{
    setFiles(updatedFiles);
    if (onChange ) {
      onChange(updatedFiles); 
    }
  }} 
};
// preview thumbs
const thumbs =!noImage && files.map(file => (
  <div className="dz-preview dz-processing dz-image-preview dz-complete" key={file.name}>
    <div className="dz-image">
      <img src={file.preview} alt="preview" />
    </div>
  </div>
));
  return (
    <>
      <Dropzone 
        onDrop={acceptedFiles => handleDropChange(acceptedFiles, setFiles)} 
        maxFiles={maxFiles}
        maxSize={maxSize}
        onDropRejected={() => alert(errorText)}
      >
          {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="dropzone dz-clickable">
                <input {...getInputProps()} />
                {(files.length === 0 || noImage) && (
                    <div className="dz-message">
                        <div className="dz-message-icon">
                          <Icon size="lg" name={iconName} />
                        </div>
                        <div className="dz-message-btn">
                        <Button size="md" variant='transparent'>Upload</Button>
                            {/* <Button size="md" vaiant="transparent">Upload</Button> */}
                        </div>
                        {/* <span className="dz-message-text">Click to replace or drag and drop <small>{maxSize && 'Max ' + bytesToMegaBytes(maxSize) + ' MB'}</small></span> */}
                        <small className='dz-message-text'>(Please select file for conversation)</small>
                       
                    </div>
                )}
                {files.length > 0 && files[0].type?.startsWith('image/')?thumbs:files[0]?.name}
              </div>
          )}
        </Dropzone>
    </>
  )
}

export default FileUpload