import React from 'react';

const BatchSVg = ({ fill}) => {
  console.log("fill", fill);
  return (
    
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%" // Adjust width as needed
      height="100%" // Adjust height as needed
      viewBox="0 0 682.667 682.667"
      style={{ maxWidth: '512px', maxHeight: '512px' }} // Ensure it fits within bounds
    >
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path
            fill={fill}
            d="M0 512h512V0H0z"
            data-original="#000000"
          ></path>
        </clipPath>
        <clipPath id="b" clipPathUnits="userSpaceOnUse">
          <path
            fill={fill}
            d="M0 512h512V0H0z"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="22.926"
          strokeWidth="30"
          d="M111 133H51v364h290l-.008-56"
          data-original="#000000"
        ></path>
      </g>
      <path
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="40"
        d="M224 582.667h-76V97.335h386.666l-.012 77.333"
        data-original="#000000"
      ></path>
      <g
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="30"
        clipPath="url(#b)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
      >
        <path d="M460.947 15H171v364h290z" data-original="#000000"></path>
        <path
          d="M251.019 153.464c8.51 28.795 34.956 48.555 64.981 48.555a67.76 67.76 0 0060.864-37.98"
          data-original="#000000"
        ></path>
        <path
          d="M261.549 157.8l-10.758-7.053L245 162.235M380.98 115.055C372.47 86.26 346.025 66.5 316 66.5a67.76 67.76 0 00-60.508 37.261"
          data-original="#000000"
        ></path>
        <path
          d="M370.451 110.718l10.758 7.054L387 106.284M231.022 319.001h169.957M256.508 259.001h118.984"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export default BatchSVg;
