import axios from "axios";
import Context from "../context";
import { useContext } from "react";
import Swal from "sweetalert2/src/sweetalert2";
const API_URLoooo = process.env.API_URL;
// const API_URL =  "http://127.0.0.1:8000/api/"
const API_URL = "https://api.hilingo.com/api/";
console.log("API_URLoooo",process.env.API_URL)
class BaseApi {

  //, additionalHeaders = {}
  API = async (options, onUploadPrgress, onDownloadPrgress) => {

    let token = JSON.parse(localStorage.getItem("userHilingotoken")) ;


    let authHeader = "";
    if (token) {
      authHeader = `${token}`;
    }

    const client = axios.create({
      baseURL: API_URL,
      headers: { token: authHeader }, //, ...additionalHeaders
      onUploadProgress: onUploadPrgress,
      onDownloadProgress: onDownloadPrgress,
    });

    return client(options)
      .then((response) => {
        console.log(response)
        if (response.data && response.data.error) {
          if(response?.data?.error?.response === 401){
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: "Sessions Expired...",
            });
            localStorage.clear();
            window.location.reload();
            }
        } else {
          return response.data;
        }
      })
      .catch(async (error) => {
        console.log(error)
        // window.location.href("/")
      if(error?.response?.status === 401){
       await Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Sessions Expired...",
        });
        localStorage.clear();
        window.location.reload();
        }
      } );
  };
}

export default BaseApi;
