
function formatDate(dateString) {
    const date = new Date(dateString);
    console.log('date.getDate() :>> ', date.getDate(), `${("0" + date.getDate()).slice(-2)}`);
  
    // Format date
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
  
    return formattedDate
  }
  
  
  
  function formatDateAndTime(dateString) {
    const date = new Date(dateString);
    console.log('date.getDate() :>> ', date.getDate(), `${("0" + date.getDate()).slice(-2)}`);
  
    // Format date
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
  
    // Format time
    let hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const formattedTime = `${hours}:${minutes} ${ampm}`;
  
    return `${formattedDate} | ${formattedTime}`;
  }


  function formatDateMonthString(dateString) {
  const givenDate = new Date(dateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = givenDate.getUTCFullYear();
  const monthIndex = givenDate.getUTCMonth();
  const day = givenDate.getUTCDate();
    return `${day} ${monthNames[monthIndex]} ${year}`;
}

function formatTimestamp(timestamp) {
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const date = new Date(timestamp);
  return date.toLocaleString("en-US", options);
  
}



function formate23August2024(dateStr){


const date = new Date(dateStr);

// Options to format the date
const options = { day: '2-digit', month: 'long', year: 'numeric' };

// Convert the date to the desired format
return date.toLocaleDateString('en-GB', options);

 // Output: 23 August 2024

}

  export default{formatDate,formatDateAndTime,formatDateMonthString,formate23August2024,formatTimestamp} 
  
  
  // module.exports={formatDate,formatDateAndTime} 