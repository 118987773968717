import React from 'react';

const PracticeVolumneSvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" style={style}>
    <path d="M13 2.25049V17.7461C13 18.8247 11.7255 19.397 10.9194 18.6802L6.42793 14.6865C6.29063 14.5644 6.11329 14.497 5.92956 14.497H2.25C1.00736 14.497 0 13.4896 0 12.247V7.74907C0 6.50643 1.00736 5.49907 2.25 5.49907H5.92961C6.11333 5.49907 6.29065 5.43165 6.42794 5.30958L10.9195 1.31631C11.7255 0.599639 13 1.17187 13 2.25049ZM16.9916 3.89782C17.3244 3.65128 17.7941 3.72126 18.0407 4.05411C19.2717 5.71619 20 7.77439 20 10.0005C20 12.2266 19.2717 14.2848 18.0407 15.9469C17.7941 16.2798 17.3244 16.3497 16.9916 16.1032C16.6587 15.8567 16.5888 15.387 16.8353 15.0541C17.8815 13.6416 18.5 11.8943 18.5 10.0005C18.5 8.1067 17.8815 6.35945 16.8353 4.9469C16.5888 4.61404 16.6587 4.14435 16.9916 3.89782ZM15.143 6.36982C15.5072 6.17262 15.9624 6.30806 16.1596 6.67233C16.6958 7.66294 17 8.7973 17 10.0005C17 11.2037 16.6958 12.338 16.1596 13.3287C15.9624 13.6929 15.5072 13.8284 15.143 13.6312C14.7787 13.434 14.6432 12.9788 14.8404 12.6146C15.2609 11.8378 15.5 10.9482 15.5 10.0005C15.5 9.0528 15.2609 8.1632 14.8404 7.38642C14.6432 7.02216 14.7787 6.56701 15.143 6.36982Z" fill={fill}/>
    </svg>
    
  );
}

export default PracticeVolumneSvg;
