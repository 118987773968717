import { Form, InputGroup } from "react-bootstrap";

function DateRangePickerName({
  startDate,
  endDate,
  className,
  onChange,
  inputGrpname1,
  inputGrpname2,
  ...props
}) {
  const getFormattedToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  return (
    <InputGroup className={className}>
      <Form.Control
        placeholder="yyyy-mm-dd"
        type="date"
        value={startDate}
        autoComplete="off"
        onChange={onChange}
        name={inputGrpname1}
      />
      <InputGroup.Text>to</InputGroup.Text>
      <Form.Control
        placeholder="yyyy-mm-dd"
        type="date"
        value={endDate}
        autoComplete="off"
        onChange={onChange}
        name={inputGrpname2}
        max={getFormattedToday()}
      />
    </InputGroup>
  );
}

export default DateRangePickerName;
