import { ProgressBar, Spinner, Button } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import Timer from "../Timer";
import { useState } from "react";
import ProgressBarCustom from "../CustomProgressbar";
import { useAudioRecorder } from "react-audio-voice-recorder";
import RecorderUI from "../Istructions/ExamComponents/RecorderUI";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import { useEffect } from "react";
import OnlyRecorderUI from "../Istructions/ExamComponents/OnlyRecorderUI";
function ReadAloud({ examsTime,data, popUpType, startRec, stopRec }) {
  const [startAudio, setStartAudio] = useState(false);
  const handleStopRecording = async () => {
    stopRec();
  };
    let {
      studentContextData,
      setStudentContextData} = useContext(ModalContext)
      // useEffect(() => {
      //   if(data?.module_name == "read_alouds"){
      //     setStudentContextData((prevState) => ({
      //       ...prevState,
      //       timeRamaining: {
      //         ...prevState.timeRamaining,
      //         status: true,
      //         total_time: examsTime.speaking_time,
      //       },
      //     }));}
      
      // }, []);
  return (
    <>
      {/* <ExamHeader /> */}
      <div className='container my-5 text-secondary'>
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <div class="d-flex justify-content-center align-items-center gap-4 my-5">
          <div className="d-flex flex-column w-50 gap-5 justify-content-start">
            {/* loader */}
            {/* <Button variant="transparent">
                        <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                        <span className="ms-2">Loading...</span>
                    </Button> */}
            {/* <Placeholder as="p" animation="glow" className="w-50">
                        <Placeholder xs={2} className="rounded py-3" />
                    </Placeholder> */}
            {/* after loader */}
            
            <OnlyRecorderUI
              startAudio={startAudio}
              data={data}
              stopRecordings={handleStopRecording}
              initialTime={data.answer_time}
              timer={data?.answer_prepare_time}
              startRecording={() => {
                setStartAudio(true);
                startRec();
              }}
              NoRecording={false}
            />
            {/* <div className="d-flex gap-3 justify-content-start align-items-center w-100">
              <Timer
                timer={data?.answer_prepare_time}
                startRecording={() => {
                  setStartAudio(true);
                  startRec();
                }}
                NoRecording={false}
              />
            </div> */}
            {/* recording */}
            {/* {startAudio && popUpType == "default" && (
              <ProgressBarCustom
                data={data}
                stopRecordings={handleStopRecording}
                initialTime={data.answer_time}
              />
            )} */}
          </div>
        </div>
        <p>{data.question_content}</p>
      </div>
      {/* <ExamFooter /> */}
    </>
  );
}
export default ReadAloud;
