import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimePicker from "../../../../components/Form/TimePicker";
import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../../components";
import { useContext, useState, useEffect } from "react";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteListeningMultiChoiceRadio() {
  let { id } = useParams();
  let api = new AdminApi();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    test_type: "listening_l_mcs",
    exam_type: "pte",
    instructions:"Listen to the recording and answer the single-choice question by selecting the correct response . Only one response is correct.",
    question_options: [
      {
        options: "",
        answer: false,
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setErrror] = useState({
    image: false,
    audio: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      file,
      question,
      question_transcript,
      answer_time,
      question_begin_time,
    } = data;
    let questionOptions = data.question_options.some(
      (option) => option.options == ""
    );
   
    return (
      !questionOptions &&
      instructions &&
      question_transcript &&
      data.sample_answer &&
      (file ? file : data.question_content_audio || data.question_content_video ) &&
      tags &&
      question &&
      (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      question_begin_time.length ===5
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) => item.options !== ""
    );
    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };

  const handleAdd = (parentIndex, childIndex, type) => {
    let handleAdd = [...data.question_options];
    handleAdd.push({
      options: "",
      answer: false,
    });
    setData({
      ...data,
      question_options: handleAdd,
    });
  };
  const handleRemove = (item, index) => {
    let handleAddRemove = [...data.question_options];
    handleAddRemove.splice(index, 1);
    setData({
      ...data,
      question_options: handleAddRemove,
    });
  };

  const handleChange = (e, i,sample_answer) => {
    console.log("name", e.target.name)
    // e.preventDefault();
    let { name, value } = e.target;
    if (i == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if(sample_answer){
      setData({ ...data, [name]: value });
    }else{
      if (name == "options") {
        const updatedOptions = [...data.question_options];
        console.log("updatedOptions[i].answer", updatedOptions[i].answer);
        updatedOptions[i] = {
          ...updatedOptions[i],
          options: value,
        };
  
        setData({
          ...data,
          question_options: updatedOptions,
        });
      } else if (name == "answer") {
        const updatedOptions = data.question_options.map((option, index) => ({
          ...option,
          answer: i === index ? value === "true" : false,
        }));
        setData({
          ...data,
          question_options: updatedOptions,
        });
      } else if (name == "file") {
        setData({ ...data, ["file"]: e.target.files[0] });
      } else {
        if (name !== "options" && name !== "answer") {
          if (name == "question_type") {
            const options = e.target.options;
            const selectedValues = [];
            if (options.length == 0) {
              setSelectedOptions([]);
            }
            for (let i = 0; i < options.length; i++) {
              if (options[i].selected) {
                selectedValues.push(options[i].value);
              }
              setSelectedOptions(selectedValues);
            }
            setData({
              ...data,
              isMock: selectedValues.includes("isMock") ? true : false,
              isPractice: selectedValues.includes("isPractice") ? true : false,
              isSection: selectedValues.includes("isSection") ? true : false,
              isQuestion: selectedValues.includes("isQuestion") ? true : false,
              isPrediction: selectedValues.includes("isPrediction") ? true : false,
            });
          } else {
            setData({ ...data, [name]: value });
          }
        }
      }
    }
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isAudio = false;
    let isVideo = false;
    if (data?.imagefile?.length) {
      if (
        data?.imagefile?.length > 0 &&
        data.imagefile[0].type.startsWith("image/")
      ) {
        formObject.append("question_content_image", data.imagefile[0]);
        setErrror({ ...error, image: false });
      } else {
        if (!data?.question_content_image) {
          setErrror({ ...error, image: true });
          Swal.fire({
            icon: "error",
            title: "File must be an image",
            text: "Please upload an image file.",
          });
          return;
        }
      }
    }
    // if (data?.file?.length > 0) {
    //   const fileType = data.file[0].type;
    //   if (fileType.startsWith("audio/")) {
    //     isAudio = true;
    //     formObject.append("question_content_audio", data.file[0]);
    //     setErrror({ ...error, audio: false });
    //   } else {
    //     setErrror({ ...error, audio: true });
    //     Swal.fire({
    //       icon: "error",
    //       title: "File must be an audio ",
    //       text: "Please upload an audio file.",
    //     });
    //     return;
    //   }
    // } else {
    //   if (!data?.question_content_audio) {
    //     setErrror({ ...error, audio: true });
    //     Swal.fire({
    //       icon: "error",
    //       title: "File is required",
    //       text: "Please upload an audio file.",
    //     });
    //     return;
    //   }
    // }
    if(!data.question_content_audio && !data.question_content_video  ){    if (data?.file?.length > 0) {
      const fileType = data.file[0].type;
      if (fileType.startsWith("audio/")) {
        isAudio = true;
        formObject.append("question_content_audio", data.file[0]);
      } else if (fileType.startsWith("video/")) {
        isVideo = true;
        formObject.append("question_content_video", data.file[0]);
      } else {
        Swal.fire({
          icon: "error",
          title: "File must be an audio or video",
          text: "Please upload an audio or video file.",
        });
        return;
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "File is required",
        text: "Please upload an audio or video file.",
      });
      return;
    }}
    var data1 = removeBlankItems();
    for (const [key, value] of Object.entries(data1)) {
      if (key !== "file" && key !== "question_options" && key != "data.question_content_audio") {
        if(value!=null){
          formObject.append(key, value);
        }
      } else if (key === "question_options") {
        value.forEach((option, index) => {
          formObject.append(
            `question_options[${index}][options]`,
            option.options
          );
          formObject.append(
            `question_options[${index}][answer]`,
            option.answer
          );
        });
      }
    }
    setloader(true)
    await api
      .add_Question_Listening(formObject)
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
          ;
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
            // text: "Please upload an audio or video file.",
          });
        }
        setloader(false)
      })
      .catch((err) => {
        setloader(false)
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (id) {
      getQuesById();
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Listening Multiple Choice, Choose Single Answer Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => { setSelectedOptions(e) }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e); setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                   
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group
                        className={`form-group ${error.image && `error-Image`}`}
                      >
                        <Form.Label
                          htmlFor="addaudio"
                          className=" ms-3 fw-bold"
                        >
                          Add Image (Optional)
                        </Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_image &&
                          data.question_content_image !== "null" ? (
                            <>
                              <div className="d-flex gap-2">
                                <img
                                  src={data?.question_content_image}
                                  width="160"
                                  height="155"
                                />
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_image: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                              filetype="image"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                console.log("updatedFiles", updatedFiles);
                                setErrror({
                                  ...error,
                                  image: false,
                                  audio: false,
                                });
                                setData({
                                  ...data,
                                  imagefile: updatedFiles,
                                  question_content_image: "",
                                });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">Add Audio</Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_audio ? (
                            <>
                              <div className="d-flex gap-2">
                                <audio
                                  src={data?.question_content_audio}
                                  controls
                                ></audio>
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles, question_content_audio: "" });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col> */}
                     <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio">
                          Add Audio/Video
                        </Form.Label>
                        <div className="form-control-wrap">
                        {data.question_content_audio ||
                          data.question_content_video ? (
                            <>
                              <div className="d-flex gap-2">
                                {( data.question_content_video !== "null"  && (
                                  <video
                                    src={data?.question_content_video}
                                    width="200"
                                    height="155"
                                    controls={false}
                                  />
                                ))}{
                                  (data.question_content_audio && (
                                    <audio
                                      src={data?.question_content_audio}
                                      controls
                                    />
                                  ))}
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_video: "",
                                        question_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="audio_video"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Add Question</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="question"
                            value={data.question}
                            onChange={(e) => handleChange(e)}
                            as="textarea"
                            placeholder="Add Question"
                            id="desc"
                            rows="1"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <Form.Group className="form-group my-2">
                    <Row className="g-gs">
                      <Col md="10">
                        <Form.Label htmlFor="multiradio" className="ms-3 fw-bold">
                          Add Options
                        </Form.Label>
                      </Col>
                      <Col md="2">
                        <Form.Label htmlFor="multiradio">
                          Add Correct Answer
                        </Form.Label>
                      </Col>
                    </Row>
                    {data?.question_options.length > 0 &&
                      data?.question_options.map((item, i) => {
                        return (
                          <>
                            <div className="d-flex gap-1 gap-md-3 align-items-center my-2">
                              <Form.Group className="form-group w-75">
                                <div className="form-control-wrap">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Options"
                                    id="option-1"
                                    name="options"
                                    value={item.options}
                                    onChange={(e) => handleChange(e, i)}
                                  ></Form.Control>
                                </div>
                              </Form.Group>
                              <div>
                                {data?.question_options.length - 1 == i ? (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                    onClick={handleAdd}
                                  >
                                    <Icon
                                      name="plus-circle"
                                      variant="primary"
                                    ></Icon>
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                  ></Button>
                                )}
                                {data?.question_options.length > 1 && (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                    onClick={(e) => handleRemove(e, i)}
                                  >
                                    <Icon
                                      name="minus-circle"
                                      variant="danger"
                                    ></Icon>
                                  </Button>
                                )}
                              </div>{" "}
                              <div className="border rounded px-2 py-1">
                                <Form.Check
                                  type="radio"
                                  value={((item?.answer == 'false' || item?.answer == false) && true )|| ((item?.answer == 'true' || item?.answer == true)&& false  )}
                                  onChange={(e) => handleChange(e, i)}
                                  checked={((item?.answer == 'true' || item?.answer == true ) && true )|| ((item?.answer == 'false' || item?.answer == false)&& false  )}
                                  name="answer"
                                  id={`option-${i}`}
                                />
                              </div>
                              {/* <div className="border rounded px-2 py-1">
                                <Form.Check
                                  value={!item?.answer}
                                  onChange={(e) => handleChange(e, i)}
                                  checked={item?.answer}
                                  name="answer"
                                  type="checkbox"
                                  id="option-1"
                                />
                              </div> */}
                            </div>
                          </>
                        );
                      })}
                  </Form.Group>
                </Col>
                <Col lg="9">
                  <TranscriptExplanation transcript={true} sample_answer={true} setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e,null,true)} />
                </Col>
                {/* <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">
                      Add Transcript (Optional)
                      <Button className="btn-icon" variant="outline-transparent" onClick={() => toggleContent('transcript')}>
                        <Icon name={showContent.transcript ? 'minus-circle' : 'plus-circle'} variant={showContent.transcript ? 'danger' : 'primary'} />
                      </Button>
                    </Form.Label>
                    {showContent.transcript && (
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="addtranscript"
                          placeholder="Transcript"
                          name="question_transcript"
                          value={data?.question_transcript}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">
                      Add Explanation (Optional)
                      <Button className="btn-icon" variant="outline-transparent" onClick={() => toggleContent('explanation')}>
                        <Icon name={showContent.explanation ? 'minus-circle' : 'plus-circle'} variant={showContent.explanation ? 'danger' : 'primary'} />
                      </Button>
                    </Form.Label>
                    {showContent.explanation && (
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="addexplanation"
                          placeholder="Explanation"
                          name="question_explanation"
                          value={data?.question_explanation}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col> */}
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        placeholder="#Tag"
                        name="tags"
                        value={data.tags}
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between my-5">
                  <PrevNextButton id={id} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid}/>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteListeningMultiChoiceRadio;
