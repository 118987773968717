
import AdminApi from '../../../../api/admin';
export const getQuestion_by_module_name = async (module_name,user_id, paginate, filterObj) => {
    let api = new AdminApi()
  try {
    const res = await api.get_qst_filter({ module_name,user_id, paginate, filterObj });
    return res;
  } catch (err) {
    console.error('Error', err);
    throw err; // You can choose to handle the error as needed
  }
};
