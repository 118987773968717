import React from 'react';

const PracticeBoardSvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.70709 14.5833C3.28206 14.5833 3.74817 15.0494 3.74817 15.6244C3.74817 16.1994 3.28206 16.6655 2.70709 16.6655C2.13212 16.6655 1.66602 16.1994 1.66602 15.6244C1.66602 15.0494 2.13212 14.5833 2.70709 14.5833ZM5.62435 15H17.7077C18.0528 15 18.3327 15.2798 18.3327 15.625C18.3327 15.9414 18.0975 16.2029 17.7925 16.2443L17.7077 16.25H5.62435C5.27917 16.25 4.99935 15.9702 4.99935 15.625C4.99935 15.3086 5.23447 15.0471 5.53954 15.0057L5.62435 15ZM2.70709 9.16667C3.28206 9.16667 3.74817 9.63275 3.74817 10.2077C3.74817 10.7827 3.28206 11.2488 2.70709 11.2488C2.13212 11.2488 1.66602 10.7827 1.66602 10.2077C1.66602 9.63275 2.13212 9.16667 2.70709 9.16667ZM5.62435 9.58333H17.7077C18.0528 9.58333 18.3327 9.86317 18.3327 10.2083C18.3327 10.5247 18.0975 10.7863 17.7925 10.8277L17.7077 10.8333H5.62435C5.27917 10.8333 4.99935 10.5535 4.99935 10.2083C4.99935 9.89192 5.23447 9.63042 5.53954 9.589L5.62435 9.58333ZM2.70709 3.75C3.28206 3.75 3.74817 4.21611 3.74817 4.79107C3.74817 5.36604 3.28206 5.83215 2.70709 5.83215C2.13212 5.83215 1.66602 5.36604 1.66602 4.79107C1.66602 4.21611 2.13212 3.75 2.70709 3.75ZM5.62435 4.16667H17.7077C18.0528 4.16667 18.3327 4.44649 18.3327 4.79167C18.3327 5.10808 18.0975 5.36958 17.7925 5.41096L17.7077 5.41667H5.62435C5.27917 5.41667 4.99935 5.13684 4.99935 4.79167C4.99935 4.47525 5.23447 4.21376 5.53954 4.17237L5.62435 4.16667Z" fill={fill}/>
    </svg>
    
    
    
  );
}

export default PracticeBoardSvg;
