import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { DateRangePicker } from "../../../components";
import Container from "react-bootstrap/Container";
import AuthApi from "../../../api/auth";
import { Label } from "@mui/icons-material";
function InstituteRequestFilter({
  allData,
  tableData,
  setData,
  showItemPerPage,
  setShowPage,
}) {
  let { inst_profile } = allData;
  // let {inst_business_state,inst_business_country,inst_business_city} = inst_profile;
  const City = [
    ...new Set(
      allData
        .filter((item) => item?.inst_profile?.inst_business_city != undefined)
        .map((item) => item?.inst_profile?.inst_business_city?.toLowerCase())
    ),
  ];
  const Country = [
    ...new Set(
      allData
        .filter((item) => item?.inst_profile?.inst_contact_source_country_student != undefined)
        ?.map((item) => item?.inst_profile?.inst_contact_source_country_student?.toLowerCase())
    ),
  ];
  const State = [
    ...new Set(
      allData
        .filter((item) => item?.inst_profile?.inst_business_state != undefined)
        .map((item) => item?.inst_profile?.inst_business_state?.toLowerCase())
    ),
  ];
  const studentNo = [
    ...new Set(
      allData
        .filter(
          (item) => item?.inst_profile?.inst_number_of_student != undefined
        )
        .map((item) => item?.inst_profile?.inst_number_of_student)
    ),
  ];
  const Plans = [
    ...new Set(
      allData
        .filter((item) => item?.planId?.plan_name != undefined)
        .map((item) => item?.planId?.plan_name)
    ),
  ];
  const ActionType = ["less then <", "Greater then >", "Equal to ="];
  console.log(
    "Plans",
    allData.filter((item) => item?.planId?.plan_name != undefined)
  );
  const [filterDataObj, setFilterDatObj] = useState({
    startDate: "",
    endDate: "",
  });
  let {
    startDate,
    endDate,
    country,
    state,
    city,
    pincode,
    domain_name,
    email,
    studentno,
    plans,
    creditfilter,
    creditvalue,
    manageBy,
    ownerName

  } = filterDataObj;
  const handleDateChange = (e) => {
    let { value, name } = e.target;
    console.log('value,name :>> ', value, name);
    if (name == "start") {
      setFilterDatObj({ ...filterDataObj, startDate: value });
    } else if (name == "end") {
      setFilterDatObj({ ...filterDataObj, endDate: value });
    } else {
      setFilterDatObj({ ...filterDataObj, [name]: value });
    }
  };
  const filterData = async () => {
    creditvalue = Number(creditvalue);
    const filteredData = allData.filter((item) => {
      const itemDate = new Date(item.createdAt);
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);
      console.log('domain_name :>> ', domain_name);

      return (
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= endOfDay) &&
        (!country ||
          country?.toLowerCase() ===
          item?.inst_profile?.inst_contact_source_country_student?.toLowerCase()) &&

        (!state ||
          state?.toLowerCase() ===
          item?.inst_profile?.inst_business_state?.toLowerCase()) &&
        (!city ||
          city?.toLowerCase() ===
          item?.inst_profile?.inst_business_city?.toLowerCase()) &&
        (!plans ||
          plans?.toLowerCase() === item?.planId?.plan_name?.toLowerCase()) &&
        (!domain_name ||
          item?.inst_profile?.inst_business_name
            ?.toLowerCase()?.trim()
            ?.includes(domain_name?.toLowerCase()?.trim())) &&
        (!email ||
          item?.email
            ?.toLowerCase()?.trim()
            ?.includes(email?.toLowerCase()?.trim())) &&
        (!ownerName || item?.inst_profile?.inst_contact_firstname
          ?.toLowerCase()?.trim()
          ?.includes(ownerName?.toLowerCase()?.trim())) &&

        (!manageBy || item?.inst_profile?.manageBy == manageBy) &&
        (!pincode ||
          pincode?.toString() ===
          item?.inst_profile?.inst_business_postal_code?.toString()) &&
        (!studentno ||
          item?.inst_profile?.inst_number_of_student?.includes(studentno)) &&
        ((!creditfilter && !creditvalue) ||
          (creditfilter.includes("=") && item.credit == creditvalue) ||
          (creditfilter.includes("<") && item.credit < creditvalue) ||
          (creditfilter.includes(">") && item.credit > creditvalue))
      );
    });
    setData(filteredData);
  };
  let api = new AuthApi();

  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
  }, []);

  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        console.log("raaaaaaaaaaaaaaaaaaaaaaaaess", res);
        if (res.data.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("filteredData", filterDataObj);
  return (
    <>
      <Container>
        <Row>
          <div className="d-flex mb-2 ">
            <label class="form-label" >Per Page</label>
            <Form.Group className="form-control-wrap ms-1 me-1">
              <select
                className="form-select"
                onChange={(e) => setShowPage(e.target.value)}
                value={showItemPerPage}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              {/* <span className="text-secondary mx-1">Show</span> */}
            </Form.Group>
          </div>
        </Row>
        <div className="d-flex justify-content-start mb-3 flex-wrap">
          <Form.Group className="form-group w-30 ">
            <Form.Label>Institute Name</Form.Label>
            <div className="form-control-wrap me-2">
              <Form.Control
                id="filter"
                placeholder="Search By Institute Name"
                value={filterDataObj?.domain_name}
                name="domain_name"
                onChange={handleDateChange}
              />
            </div>
          </Form.Group>

          <Form.Group className="form-group w-30 ">
            <Form.Label>Email</Form.Label>
            <div className="form-control-wrap me-2">
              <Form.Control
                id="email"
                placeholder="Search By Email"
                value={filterDataObj?.email}
                name="email"
                onChange={handleDateChange}
              />
            </div>
          </Form.Group>

          <Form.Group className="form-group w-30 ">
            <Form.Label>Owner Name</Form.Label>
            <div className="form-control-wrap me-2">
              <Form.Control
                id="ownerName"
                placeholder="Search By Owner Name"
                value={filterDataObj?.ownerName}
                name="ownerName"
                onChange={handleDateChange}
              />
            </div>
          </Form.Group>

          <div className="w-20">
            <DropDown
              data={Country}
              text="Country"
              name="country"
              value={filterDataObj?.country}
              onChange={handleDateChange}
            />
          </div>
          <div className="w-20">
            <DropDown
              data={ActionType}
              text="By Account Credit"
              name="creditfilter"
              value={filterDataObj?.creditfilter}
              onChange={handleDateChange}
            />
          </div>
          {creditfilter && (
            <Form.Group className="form-group">
              <Form.Label>Value</Form.Label>
              <div
                className="form-control-wrap ms-1 me-1"
                style={{ width: "108px" }}
              >
                <Form.Control
                  type="number"
                  id="filter"
                  placeholder="Credit value"
                  value={filterDataObj?.creditvalue}
                  name="creditvalue"
                  onChange={handleDateChange}
                />
              </div>
            </Form.Group>
          )}
          <div className="w-20">
            <DropDown
              data={studentNo}
              text="Student By No"
              name="studentno"
              value={filterDataObj?.studentno}
              onChange={handleDateChange}
            />
          </div>
          <Form.Group className="form-group w-20 me-5">
            <Form.Label htmlFor="exampleFormControlInputText5">
              Filter Reset
            </Form.Label>
            <div className="form-control-wrap d-flex me-2">
              <Button
                className="me-2"
                onClick={(e) => {
                  setFilterDatObj({
                    ...filterDataObj,
                    startDate: "",
                    endDate: "",
                    creditfilter: "",
                    country: "",
                    state: "",
                    city: "",
                    pincode: "",
                    domain_name: "",
                    creditvalue: "",
                    plans: "",
                    studentno: "",
                    manageBy: "",
                    email: "",
                    ownerName: ""
                  });
                  setData(allData);
                }}
                variant="secondary"
              >
                Reset
              </Button>
              <Button variant="primary" onClick={filterData}>
                Search
              </Button>
            </div>
          </Form.Group>
        </div>


      </Container>
    </>
  );
}

export default InstituteRequestFilter;

function DropDown({ value, text, name, data, onChange }) {
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label>{text}</Form.Label>
        <div className="form-control-wrap ms-1 me-1">
          <Form.Select
            removeItemButton
            value={value}
            name={name}
            onChange={onChange}
          >
            <option value="">All</option>
            {data?.length > 0 &&
              data.map((item) => {
                return (
                  <>
                    <option value={item}>{item}</option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
    </>
  );
}
