function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  
  export function getAudioDuration(audioUrl) {
    return new Promise((resolve) => {
      if (audioUrl === "") {
        resolve("00:00");
        return;
      }
  
      const audio = new Audio(audioUrl);
      audio.addEventListener('loadedmetadata', function() {
        const duration = audio.duration;
        resolve(formatTime(duration));
      });
  
      audio.addEventListener('error', function() {
        resolve("00:00");
      });
    });
  }
  
  export function addTimes(time1, time2, time3) {
    let hours1, hours2, minutes1, minutes2, hours3, minutes3
  
    if (time1) {
      [hours1, minutes1] = time1.split(":").map(Number);
    } else {
      hours1 = 0;
      minutes1 = 0;
    }
  
    if (time2) {
      [hours2, minutes2] = time2.split(":").map(Number);
    } else {
      hours2 = 0;
      minutes2 = 0;
    }
    
    if (time3) {
        [hours3, minutes3] = time3.split(":").map(Number);
      } else {
        hours3 = 0;
        minutes3 = 0;
      }

    let totalMinutes = minutes1 + minutes2 + minutes3;
    let additionalHours = Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;
  
    let totalHours = hours1 + hours2 + hours3 + additionalHours;
    totalHours = totalHours.toString().padStart(2, '0');
    totalMinutes = totalMinutes.toString().padStart(2, '0');
  
    return `${totalHours}:${totalMinutes}`;
  }
  
  export async function calculateTotalTime(questionBeginTime, answerPrepareTime, content_audio_time) {
    
    const resultTime = addTimes(questionBeginTime, answerPrepareTime, content_audio_time);
    return resultTime
  }