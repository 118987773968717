import BaseApi from "../baseApi";


class InstBranchesApi extends BaseApi {
async get_branch_by_inst_id(id) {
    return await this.API({
      url: `branch/get_branch_by_inst_id/${id}`,     
      method: "get",
    });
  }
  
}
export default InstBranchesApi