import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import { Button, Form, Row, Col, ButtonGroup } from "react-bootstrap";
import { Icon, LinkList } from "../../components";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BranchDataTable from "../branch/BranchDataTable";
import AuthApi from "../../api/auth";
import BranchFilter from "../branch/BranchFilter";
import Context from "../../context";
import TicketsIntFilter from "./TicketsIntFilter";
import StudentTicket from "../tickets/StudentTicket";
import InstTicket from "../tickets/InstTicket";



function TicketInstAdminDataTable({inst_id}) {
  const [showItemPerPage, setShowItemPerPage] = useState(5);
  


  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);

  let {
    userData,
  } = useContext(Context);
  userData = JSON.parse(userData);


  let api = new AuthApi();
  const get_branch = async () => {
    await api
      .get_support_enquiry(inst_id,"INSTITUTE")
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log("RESDATA",res.data);
          setData(res?.data.reverse());
          setAllData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    get_branch();
  }, []);


  const [followupIdPayload, setFollowupIdPayload] = useState();
  const editHandler = (id) => {
    console.log(id, "abcEdit");

    setFollowupIdPayload(id);
  };



  const BranchColumns = [

    {
      name: "SR No.",
      cell: (row, index) => <span className="text-middark">{index + 1}</span>,
      sortable: false,
    },
    {
      name: "Subject",
      grow: "3",
      selector: (row) => row.subject,
      cell: (row) => (
        <span className="text-middark">{row?.subject}</span>
    ),
    sortable: true,
    },
    // {
    //   name: "Department",
    //   grow: "3",
    //   selector: (row) => row.department,
    //   cell: (row) => <span className="text-middark">{row.department}</span>,
    //   sortable: true,
    // },

    {
      name: "Message",
      grow: "3",
      selector: (row) => row?.message,
      cell: (row) => <span className="text-middark">{row?.message}</span>,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Student",
      grow: "3",
      selector: (row) => row?.user_id?.name,
      cell: (row) => <span className="text-middark">{row?.user_id?.name}</span>,
      sortable: true,
      hide: "lg",
    },

  
    {
      name: "Status",
      grow: "5",
      selector: (row) => row.status,
      cell: (row) => (
          <span className="text-middark">{row.status}</span>
      ),

      cell: (row) => (
          // <span className={`badge text-bg-${row.status === "Active" ? "success"
          //     : row.status === "Inactive" ? "danger"
          //         : "primary"}`
          // }>
          <span className={`badge text-bg-${row.status == "pending"  || row.status == "reopen" ? "danger"
              : "success"}`
          }>
              {row.status == "pending"  || row.status == "reopen" ? "Pending" : 'Complete'}
          </span>
      ),

      sortable: true,
  },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
         
              <div
                className="del-btn"
                onClick={() => editHandler(row?._id)

                  
                }
              >
                {/* <Icon name="user-add"></Icon> */}
                <Icon name="edit"></Icon>
              </div>
            
            </LinkList>
            </div>
      )
    }
  ];

  let navigate = useNavigate();



  const [singleFollowup, setSingleFollowup] = useState({});
  const get_Single_followup = async () => {
    console.log("inside single floowup effect", followupIdPayload);
    await api
      .get_single_support_enquiry( followupIdPayload)
      .then((res) => {
        if (res?.data?.length > 0) {
          setSingleFollowup(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (followupIdPayload) {
      get_Single_followup();
    }
  }, [followupIdPayload]);


  return (
    <div className="data-table-wrapper">

{Object.keys(singleFollowup).length > 0 && (
                  <InstTicket
                    // isFollowup={isFollowup}
                    // setisFollowup={(data) => setisFollowup(data)}
                    // get_all_followup_list={()=>get_all_followup_list()}
                    singleFollowup={singleFollowup}
                    setSingleFollowup={(data) => setSingleFollowup(data)}
                    setFollowupIdPayload={(data) => setFollowupIdPayload(data)}
                    get_Single_followup={() => get_Single_followup()}
                    get_support_enquiry={() => get_branch()}
                  />
                )}

   

    

   
      {(window.location?.pathname?.includes("/institute-detail") || window.location?.pathname?.includes("/institute/profile")) &&
           (

            <>
            {/* <TicketsIntFilter
                
                allData={alldata}
                setAllData={setAllData}
            tableData={data}
            setData={(data) => setData(data)}
            showItemPerPage={showItemPerPage}
            setShowPage={(value) => setShowItemPerPage(Number(value))}
         
                /> */}


                     <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2">
            <select
              className="form-select"
              onChange={(e) => setShowItemPerPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>
        </div>
                
      <BranchDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
           
                  BatchColumns={BranchColumns}
                  showItemPerPage={showItemPerPage}
                  setShowItemPerPage={setShowItemPerPage}
                />
                
                </>
                )}
    </div>)
}
  


export default TicketInstAdminDataTable;
