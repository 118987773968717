import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
import PracticePteCommentsNew from '../../../practice-test/PTE/PracticePteCommentsNew';
function ResultYourAnswer({ans,audioUrl,api_responseAll ,  moduleName}) {
  const [scoreModal, setScoreModal] = React.useState(false);
  return (
    <>
      <div className='p-3 text-middark d-flex flex-column align-items-start gap-3'>
        <b>Your Answer : </b>

        {/* for FIB-RW, FIB-R, RO, MCS-R, MCM-R, SST, MCM-L, MCS-L */}
        {/* <p> slave, helping, reject, carrying, center</p> */}
        <p> {ans}</p>

        {/* Only show when answer is skipped */}
        {/* <p className='text-danger'>You skipped this question</p> */}

        {/* for Read aloud, Repeat Sentence, Describe Image, Re-tell Lecture   */}
        {

          audioUrl &&
          <div className='p-3'>
          {/* <audio src="/images/audio.wav" controls ></audio> */}
          <audio src={audioUrl} controls ></audio>
        </div>
        }

        <div className='d-flex gap-3'>
        {
            scoreModal &&
            <PracticePteCommentsNew moduleName={moduleName} scoreModal={scoreModal}setScoreModal={setScoreModal}api_responseAll={api_responseAll}audioUrl={audioUrl} />
          }

          {/* for Read aloud */}
          <Button variant='outline-assigned' size='sm'>RA S-ASIA V3i</Button>

          <Button variant='outline-primary' size='sm' onClick={()=>setScoreModal(true)}>Score Info 10/90</Button></div>

      </div>
      <hr></hr>
    </>
  );
}
export default ResultYourAnswer;