import React, { useState } from 'react'
import {Link, useNavigate } from "react-router-dom";
import { Card, Form, Row, Col, Button, Tab, Nav, InputGroup, Badge } from "react-bootstrap";
function PracticePteSearchResults({data}) {
  console.log("PRACTICERESULTS",data);

  let navigate = useNavigate()

  const [answer, setAnswer] = useState(false)
  
  const toHideAnswers = ["read_alouds","repeat_sentences","essays","fib_wr","r_mcm", "r_mcs","l_mcm","l_fib","l_hcs","l_mcs","l_smw","hiws","wfds"]

  return (
    <div className='d-flex flex-column my-4 text-middark border-bottom py-4'>
              {/* For all sections */}
              <h5 style={{marginBottom:"1rem"}}>{data?.tags} <span className="text-secondary">
                {data?.tags_id && (
                <Badge style={{backgroundColor:"rgb(204, 204, 204) !important"}} className="text-white ms-1"
                                                >
                                                  #{data?.tags_id}
                                                </Badge>
                                              )}
            </span> <span className="text-secondary">
                {data?.isExplained && (
                <Badge className="text-white ms-3"
                                                >
                                                  Explain
                                                </Badge>
                                              )}
            </span></h5>
              
              

              {/* For RS, RL, ASQ, SST, FIB-L, HCS, MCS-L, SMW, HIW, WFD */}
              {data.question_content_audio && <audio className='my-3' controls>
                <source src="https://hilingo.s3.ap-south-1.amazonaws.com/practice_answer/642/1711531997053_657ffcfa1c1c9302d8a1459d.ogg"></source>
              </audio>
}
              
              {/* For DI */}
              {data?.module_name == "describe_images" && <div className='my-3'>
                <img src={data?.question_content_image} class="desc-image"></img>
              </div>}
              

              {/* FOR FIB_RD, l_fib  blanks red line*/}
              
              {(data?.module_name=="fib_rd" || data?.module_name=="l_fib") && <p> {data?.question_content?.split(" ")?.map((ele) => {return data?.answer?.split(",")?.includes(ele) ? <span className='border-bottom border-danger'>{ele} </span> : <span>{ele} </span>} )} </p>}

              {/* FOR HIWS */}
              {data?.module_name=="hiws" && <p> {data?.question_content?.split(" ")?.map((ele) => {return data?.answer?.split(",")?.includes(data?.question_options?.filter((fil) => fil.options == ele)[0]?.answer)  ? <span className='border-bottom border-danger'>{data?.question_options?.filter((fil) => fil.options == ele)[0]?.answer} </span> : <span>{ele} </span>} )} </p>}

              {/* For all sections */}
              <p>{(data?.module_name != "fib_rd" && data?.module_name != "l_fib") && data?.question_content}</p>
              {/* <p>Around 250 million years ago, 700 species of reptiles closely related to the modern-day crocodile roamed the earth, now new research reveals how a complex interplay between climate change, species competition and habitat can help explain why just 23 species of crocodile survive today. </p> */}

              {/* For FIB-RW, FIB-R, FIB-L, HIW */}
              <p>{data?.question_transcript}</p>
              {/* <p>Around 250 million years ago, 700 <span className='border-bottom border-danger'>species</span> of reptiles closely related to the modern-day crocodile roamed the earth, now new research reveals how a complex interplay between climate change, species competition and habitat can help explain why just 23 species of crocodile survive today. </p> */}

              

              {/* FOR RO */}
              {data?.module_name == "ro" && data?.question_options?.map((ele,key) => {return <p>{ele?.options}</p>})}

              {/* show answers for "r_mcm" "r_mcs" "l_mcm"   */}
              {(data?.module_name == "r_mcm" || data?.module_name == "r_mcs" || data?.module_name == "l_mcm" || data?.module_name == "l_hcs" || data?.module_name == "l_mcs" || data?.module_name == "l_smw") && <> <p>--------</p> <p>Question:</p> <p>{data?.question}</p> <p>--------</p> <p>Correct Answer:</p>
                {data?.question_options?.filter((ele) => (ele.answer == true || ele.answer == "true"))?.map((ele,key) => {return <p>{ele?.options}</p>})}
              </>}

              {/* Answers */}
              {answer && <div className='bg-light rounded d-flex flex-column p-3 my-3'>
                <h5>Answer</h5> 
                <span>{data?.sample_answer || data?.answer   || data?.question_transcript}</span>
                
              </div>}
              

              <div className="d-flex justify-content-between align-items-center">
                <div style={{visibility:toHideAnswers?.includes(data?.module_name) && "hidden"}} className='d-flex align-items-center gap-2'>
                  <span>Answer</span>
                  <Form.Check
                    type="switch"
                    id="checkboxGoogle"
                    onChange={() => {setAnswer(!answer)}}
                  />
                </div>
                <div className='d-flex justify-content-end gap-3'>
                  <Button  variant="primary" size="sm">
                    <span>Comment</span>
                  </Button>
                  <Link target="_blank" to={`${window.location.origin}/practice/pte/${data?.module_name}/${data.id}`} ><Button  variant="primary" size="sm">
                    <span>Practice</span>
                  </Button></Link>
                  
                </div>
              </div>
            </div>
  )
}

export default PracticePteSearchResults