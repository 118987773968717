import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Form } from "react-bootstrap";
import { Icon } from '../../components';

const columns = [
  { field: 'sno', headerName: 'SNO', width:80},
  { field: 'id', headerName: 'ID', width:80},
  { field: 'quesname', headerName: 'Question Name', width:300},
  
];

const rows = [
  { sno: 1, id: 321, quesname: 'Agricultural Problems'},
  { sno: 2, id: 322, quesname: 'Innovative Product'},
  { sno: 3, id: 323, quesname: 'Bills'},
  { sno: 4, id: 324, quesname: 'Urban Forest'},
  { sno: 5, id: 325, quesname: 'Child Psychology'},
  { sno: 6, id: 326, quesname: 'Statistics'},
  { sno: 7, id: 327, quesname: 'Tutor'},
  { sno: 8, id: 328, quesname: 'Attendance'},
];

export default function QuestionsListMUI() {
  return (
    <>
    <Form.Group className="form-group my-5">
    <div className="form-control-wrap w-50">
      <div className="form-control-icon end"><Icon name="search"></Icon></div>
      <Form.Control type="text" id="searchque" placeholder="Search Questions" />
    </div>
    
  </Form.Group>
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
    </>
  );
}
