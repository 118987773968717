import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
  DateRangePicker,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";
import exportFromJSON from "export-from-json";


function formatTimestamp(timestamp) {
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const date = new Date(timestamp);
  return date.toLocaleString("en-US", options);
}

const Export = ({ data1 }) => {
  const fileName = "user-data";
  const myExcelData = new Array();
  data1?.map((item,i) => {
    // const enquiryDate = new Date(item?.createdAt).toISOString().split("T")[0];
    const srNO=i + 1;
    const action = item?.action;
    const date = formatTimestamp(item?.createdAt)
    const transactionBy = item?.transaction_by?.email;
    const credit = item?.status == "Credit"?`+${item.credit_amount}`:`-${item.credit_amount}`
   
    const obj = {
      srNO,
      action,
      date,
      transactionBy,
      credit,
    };
    myExcelData.push(obj);
  });
  // console.log(myExcelData, "myExcelData", data1);
  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
      data: myExcelData,
      fileName,
      fields: {
        srNO:'SR NO',
        action: "ACTION",
        date: "DATE",
        transactionBy: "TRANSACTION BY",
        credit: "CREDIT",
       
      },
      exportType,
    });
  };
  return (
    <div className="export-options d-flex align-items-center me-2">
      <Button variant="primary" onClick={() => exportExcel()}>
        Export
      </Button>
    </div>
  );
};

function CreditHistoryList() {
  let api = new AuthApi();
  let { userData } = useContext(Context);
  const [data, setData] = useState([]);
  const [DummyData, setDummyData] = useState([]);
  const uniqueActions = [...new Set(DummyData.map((item) => item.action))];
  console.log(uniqueActions,"unique actions");
  const uniqueTransactions = [
    ...new Set(DummyData.map((item) => item?.userID?.email)),
  ];
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    get_credit_histories();
  }, []);

  const get_credit_histories = async () => {
    await api
      .get_credit_histories({ user_id: JSON.parse(userData)?._id })
      .then((res) => {
        if (res?.data?.length > 0) {

          setData(res?.data?.reverse()?.filter((item)=>item?.action != "Add Credit"));
          setDummyData(res?.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 

  // const Export = ({ data }) => {
  //   const fileName = "credit-report";

  //   const exportCSV = () => {
  //     const exportType = exportFromJSON.types.csv;
  //     exportFromJSON({ data, fileName, exportType });
  //   };

  //   const exportExcel = () => {
  //     const exportType = exportFromJSON.types.xls;
  //     exportFromJSON({ data, fileName, exportType });
  //   };

  //   return (
  //     <div className="export-options d-flex align-items-center me-2">
  //       {/* <div className="export-title small me-2">Export</div> */}
  //       <div className="btn-group">
  //         {/* <Button variant="outline-light" onClick={() => exportCSV()}>
  //           CSV
  //         </Button> */}
  //         {/* <Button variant="outline-light" onClick={() => exportExcel()}>
  //           Download Report
  //         </Button> */}
  //       </div>
  //     </div>
  //   );
  // };
  const [filterDataObj, setFilterDatObj] = useState({
    startDate: "",
    endDate: "",
    action: "",
    transaction:""
  });
  let { startDate, endDate, action,transaction } = filterDataObj;

  const handleDateChange = (e) => {
    let { value, name } = e.target;
    if (name == "start") {
      setFilterDatObj({ ...filterDataObj, startDate: value });
    } else {
      setFilterDatObj({ ...filterDataObj, endDate: value });
    }
  };
  useEffect(() => {filterData()}, [filterDataObj]);
  const filterData = () => {
    let filteredData = DummyData.filter((item) => {
      const itemDate = new Date(item.createdAt);
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);
  
      return (
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= endOfDay) &&
        (!action || item.action === action) &&
        (!transaction || item?.userID?.email === transaction)
      );
    });
  
     setData(filteredData?.filter((item)=>item?.action != "Add Credit"))
  };
  
  
  const handleReset = () => {
    setFilterDatObj({
      startDate: "",
      endDate: "",
      action: "",
      transaction: "",
    });
  };
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Credit History List</h1>
      {/* {<Export data={data} />} */}
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-start mb-3">
                  <Form.Group className="form-group w-30 me-5">
                    <Form.Label>Filter by Date</Form.Label>
                    <DateRangePicker startDate={startDate} endDate={endDate}onChange={handleDateChange} />
                  </Form.Group>
                  <Form.Group className="form-group w-20 me-5">
                    <Form.Label htmlFor="exampleFormControlInputText5">
                      Action
                    </Form.Label>
                    <div className="form-control-wrap">
                      <Form.Select
                      value={action}
                        onChange={(e) => {
                          setFilterDatObj({
                            ...filterDataObj,
                            action: e.target.value,
                          });
                        }}
                        id="exampleFormControlInputText5"
                        aria-label="Default select example"
                      >
                        <option value="">All</option>
                        {/* {uniqueActions.length > 0 &&
                          uniqueActions.map((item) => {
                            return (
                              <>
                                <option value={item}>{item}</option>
                              </>
                            );
                          })} */}


{uniqueActions.length > 0 &&
                          uniqueActions.map((item) => {
                           
                              
                              if(item != "Add Credit"){

                                return <option value={item}>{item}</option>
                              }
                              
                           
                          })}
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group w-20 me-5">
                    <Form.Label htmlFor="exampleFormControlInputText5">
                      Transaction By
                    </Form.Label>
                    <div className="form-control-wrap">
                      <Form.Select
                      value={transaction}
                        onChange={(e) => {
                          setFilterDatObj({
                            ...filterDataObj,
                            transaction: e.target.value,
                          });
                        }}
                        id="exampleFormControlInputText5"
                        aria-label="Default select example"
                      >
                        <option value="">All</option>
                        {uniqueTransactions.length > 0 &&
                          uniqueTransactions.map((item) => {
                            return (
                              <>
                                <option value={item}>{item}</option>
                              </>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group w-20 me-5">
                    <Form.Label htmlFor="exampleFormControlInputText5">
                     Filter Reset
                    </Form.Label>
                    <div className="d-flex gap-2">
                    <div className="form-control-wrap">
                    <Button variant="secondary" onClick={handleReset}>
                    Reset
                  </Button>
                    </div>
                  <div className="form-control-wrap">
                  <Export data1={data} />
                    </div>
                    </div>
                  </Form.Group>
              
                </div>
                {data.length > 0 ? (
                  <Table responsive striped bordered hover className="small">
                    <thead>
                      <tr className="bg-primary " > 
                        <th className="text-white">SR No.</th>
                        <th className="text-white">Action</th>
                        <th className="text-white">Date</th>
                        <th className="text-white">Transaction By</th>
                        <th className="text-white">Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 &&
                        data.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{item.action}</td>
                                <td>{formatTimestamp(item.createdAt)}</td>
                                <td>{item?.transaction_by?.email} </td>
                                <td>
                                  {item.status == "Credit" ? (
                                    <span className="bg-success rounded px-3 py-1 text-white">
                                      {" "}
                                      +{item.credit_amount}
                                    </span>
                                  ) : (
                                    <span className="bg-danger rounded px-3 py-1 text-white">
                                      {" "}
                                      -{item.credit_amount}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                ) : (
                  <h5>No data found</h5>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}

export default CreditHistoryList;
