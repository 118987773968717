import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useLocation } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../components";
import { useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import AdminApi from "../../api/admin";
function AddPromocode() {
  let { _id } = useParams();
  let api = new AuthApi();
  let adminApi = new AdminApi();
  let { userData } = useContext(Context);
  let navigate = useNavigate();
  const [oneTime, setOneTime] = useState(true);
  const [data, setData] = useState({
    active: true,
    usage_limit_per_coupon: 1,
    usage_limit_per_user: 1,
  });
  const [planList, setPlan] = useState([]);
  useEffect(() => {
    if (_id) {
      get_promo_code_by_id();
    }
  }, [_id]);
  useEffect(() => {
    getPlanAll();
  }, []);

  const getPlanAll = async () => {
    await adminApi.get_planall().then((res) => {
      try {
        if (res.data.length) {
          setPlan(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const get_promo_code_by_id = async () => {
    await api.get_promo_code_by_id(_id).then((res) => {
      try {
        if (res.status) {
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleChange = (e) => {
    let { name, value, checked, type } = e.target;

    if (name === "active") {
      if (checked) {
        setData({ ...data, [name]: checked });
      } else {
        setData({ ...data, [name]: false });
      }
    } else {
      if (name == "code") {
        setData({ ...data, [name]: value?.toUpperCase() });
      } else {
        if (type == "number" && value.length < 11) {
          setData({ ...data, [name]: value });
        } else {
          if (type != "number") {
            setData({ ...data, [name]: value });
          }
        }
        if(name == "usage_limit_per_coupon" || name == "usage_limit_per_user"){
          setData({...data, [name]:value})
        }
      }
    }
    // console.log(data);
  };
  let refrer = document.referrer;
  console.log(
    "**refrerrefrerrefrerrefrerrefrerrefrerrefrerrefrerrefrer***",
    refrer
  );
  const handleSubmit = async (e) => {
    let api2 = new AdminApi();
    e.preventDefault();
    await api2.add_promo_code(data).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  console.log("document.referer", document.referrer);
  const handleSubmitEditTeam = async (e) => {
    e.preventDefault();
    await api.edit_promo_code_id(data, _id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad month and day with leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  return (
    <Layout title="Add Promocode" content="container">
      {console.log("DATA ---- ",data)}
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              {_id ? "Edit Promocode" : "Add Promocode"}
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              {/* <h5 className="mb-3">Select Product</h5> */}
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname"></Form.Label>
                        <div className="form-control-wrap">
                          <h5>Select Coupon type</h5>
                          <Form.Check
                            type="radio"
                            id="flexRadioDefault"
                            name="check"
                            defaultChecked
                            onClick={() => {
                              setData(
                                {
                                  ...data,
                                  usage_limit_per_coupon: 1,
                                  usage_limit_per_user: 1,
                                },
                                setOneTime(true)
                              );
                            }}
                            label="One Time"
                          />
                          <Form.Check
                            type="radio"
                            id="flexRadioDefault"
                            name="check"
                            onClick={() => {
                              setData({ active: true }, setOneTime(false));
                            }}
                            label="Recurring"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    
                    {!oneTime && (
                      <>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="emailid">
                              Usage Limit Per coupon
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="number"
                                name="usage_limit_per_coupon"
                                value={data.usage_limit_per_coupon}
                                onChange={(e) => handleChange(e)}
                                placeholder="100"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="emailid">
                              Usage Limit Per User
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="number"
                                name="usage_limit_per_user"
                                value={data.usage_limit_per_user}
                                onChange={(e) => handleChange(e)}
                                placeholder="100"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">Promocode</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="code"
                            value={data?.code}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="Promocode"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="description">
                          Description
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            name="discription"
                            value={data.discription}
                            onChange={(e) => handleChange(e)}
                            id="mobile"
                            placeholder="Promocde Description"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="emailid">
                          Select Discount Type
                        </Form.Label>
                        <div className="form-control-wrap d-flex gap-2">
                          <Form.Group className="form-group w-40">
                            <div className="form-control-wrap">
                              <Select
                                removeItemButton
                                name="discountType"
                                value={data.discountType}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">
                                  Select Percentage or Fixed Amount
                                </option>
                                <option value="percent">Percentage %</option>
                                <option value="amount">Fixed Amount</option>
                              </Select>
                            </div>
                          </Form.Group>
                          <Form.Control
                            type="number"
                            name="value"
                            disabled={!data?.discountType ? true : false}
                            value={data.value}
                            onChange={(e) => handleChange(e)}
                            placeholder={
                              data?.discountType == "amount"
                                ? "Enter Amount"
                                : data?.discountType == "percent"
                                ? "Enter how many percent % Off"
                                : "Select Percentage or Fixed Amount"
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label> Select Plan for Specific User</Form.Label>
                        <div className="form-control-wrap">
                          <Select
                            removeItemButton
                            name="login_type"
                            value={data.login_type}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="">Select Code for</option>
                            <option value="1">Institute</option>
                            <option value="2">Student</option>
                          </Select>
                        </div>
                      </Form.Group>
                    </Col>
                    

                    {data.login_type == "1" && (
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label> Select Code Type</Form.Label>
                          <div className="form-control-wrap">
                            <Select
                              removeItemButton
                              name="plan_id"
                              value={data.plan_id}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Code Type</option>
                              {planList.length > 0 &&
                                planList.map((item) => {
                                  return (
                                    <>
                                      {item?.active && (
                                        <option value={item._id}>
                                          {item?.plan_name}
                                        </option>
                                      )}
                                    </>
                                  );
                                })}
                            </Select>
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    {data.login_type == "1" && (
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label> Select Plan for Promocode</Form.Label>
                          <div className="form-control-wrap">
                            <Select
                              removeItemButton
                              name="code_type"
                              value={data?.code_type}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Plan for Promocode</option>
                              <option value="student_id">For Student Id</option>
                              <option value="top_up">For Top Up</option>
                              <option value="plan_upgrade">For Plan Upgrade</option>
                            </Select>
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    {data.login_type == "1" && data.code_type=="student_id" && (
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label> Select Student Id Type</Form.Label>
                          <div className="form-control-wrap">
                            <Select
                              removeItemButton
                              name="student_id_type"
                              value={data?.student_id_type}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Student Id Type</option>
                              <option value="unlimited_id">Unlimited Id</option>
                              <option value="limited_id">Demo Id</option>
                              <option value="retail_id">Retail Id</option>
                            </Select>
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    {data?.login_type == "1" && data?.code_type=="student_id" && (data?.student_id_type=="unlimited_id" || data?.student_id_type=="limited_id") && (
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label> Select Student Plan Validity</Form.Label>
                          <div className="form-control-wrap">
                            <Select
                              removeItemButton
                              name="student_id_type_plan"
                              value={data?.student_id_type_plan}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Student Plan</option>
                              <option value="30_days_plan">30 Days Plan</option>
                              <option value="90_days_plan">90 Days Plan</option>
                              <option value="180_days_plan">180 Days Plan</option>
                            </Select>
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    {data.login_type == "1" && data.code_type=="student_id" && (
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label> Select Student Type</Form.Label>
                          <div className="form-control-wrap">
                            <Select
                              removeItemButton
                              name="student_type"
                              value={data?.student_type}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select Student Type</option>
                              <option value="new_student">New Student</option>
                              <option value="renew_student">Renew Student</option>
                            </Select>
                          </div>
                        </Form.Group>
                      </Col>
                    )}

                    

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="emailid">Expiry date</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="date"
                            name="expiry_date"
                            value={data.expiry_date}
                            onChange={(e) => handleChange(e)}
                            min={getCurrentDate()}
                            // placeholder="100"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="emailid">Minimum Spend</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="number"
                            name="mimimum_spend"
                            value={data.mimimum_spend}
                            onChange={(e) => handleChange(e)}
                            placeholder="100"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="emailid">Maximum Spend</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="number"
                            name="maximum_spend"
                            value={data.maximum_spend}
                            onChange={(e) => handleChange(e)}
                            placeholder="100"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    
                    <Col lg="12">
                      <div>
                        <h5 className="my-3">Select Status</h5>
                        <div className="form-check form-switch">
                          <span className="text-light">Active</span>{" "}
                          <input
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            id="directMessage"
                            checked={data?.active}
                            name="active"
                            onChange={(e) => handleChange(e)}
                            value={data?.active}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {_id ? (
                    <>
                      <Button
                        onClick={handleSubmitEditTeam}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={
                          !data?.code ||
                          !data.description ||
                          !data.discountType ||
                          !data.value
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleSubmit}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={
                          !data?.code ||
                          !data?.discription ||
                          !data?.discountType ||
                          !data?.value ||
                          !data?.login_type ||
                          (data?.login_type == "1" && !data?.plan_id)
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default AddPromocode;
