import React, { useContext, useEffect } from "react";
import {
    Card,
    Row,
    Col,
    ProgressBar,
    Spinner,
    Table,
    Form,
    Button,
    Modal,
  } from "react-bootstrap";
import ModalContext from "../../../../context/ModalContext";
function InstructionReading({ instCount }) {
  let { popUpType, setPopUpType, studentContextData, setStudentContextData } =
  useContext(ModalContext);
useEffect(() => {
  if (instCount == 0) {
    setStudentContextData((prevState) => ({
      ...prevState,
      timeRamaining: {
        ...prevState.timeRamaining,
        status: true,
        total_time: "00:59",
      },
    }));
  }
 
}, [instCount]);
  return (
    <>
      {" "}
      {instCount == 0 && (
        <Row className="my-3">
          <Col md="12" className="text-examheadtext">
            <h4 className="text-examheadtext">Part 2: Reading</h4>
            <p>
              In this part you will be tested mainly on your reading skills.
            </p>
            <p>
              Read the instructions to each question carefully and answer as
              directed
            </p>
            <Table responsive bordered hover className="small my-3 w-50">
              <thead className="bg-primary-soft">
                <tr>
                  <th colSpan="2" className="text-start">
                    Part 2: Reading
                  </th>
                </tr>
                <tr>
                  <th className="text-examheadtext text-start">Item type</th>
                  <th className="text-examheadtext text-start">Time Allowed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-examheadtext text-start">
                    Reading & Writing. Fill in the blanks
                  </td>
                  <td className="text-examheadtext text-start">28 minutes</td>
                </tr>
                <tr>
                  <td className="text-examheadtext text-start">
                    Multiple-choice, choose multiple answers{" "}
                  </td>
                  <td className="text-examheadtext text-start"></td>
                </tr>
                <tr>
                  <td className="text-examheadtext text-start">
                    Re-order paragraphs{" "}
                  </td>
                  <td className="text-examheadtext text-start"></td>
                </tr>
                <tr>
                  <td className="text-examheadtext text-start">
                   Reading Fill in the Blanks{" "}
                  </td>
                  <td className="text-examheadtext text-start"></td>
                </tr>
                <tr>
                  <td className="text-examheadtext text-start">
                    Multiple-choice, choose single answer{" "}
                  </td>
                  <td className="text-examheadtext text-start"></td>
                </tr>
              </tbody>
            </Table>
            <p className="bg-examblue text-white px-3 mt-5">
              Click on the Next (N) button to continue
            </p>
          </Col>
        </Row>
      )}
    </>
  );
}

export default InstructionReading;
