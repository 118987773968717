import React from 'react'

const DashBoardIconsSvg = ({fill}) => {
  console.log("fill", fill)
  return(
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.81483 8.22384C11.76 6.59346 14.199 5.5329 16.875 5.29894V7.875C16.875 8.49632 17.3787 9 18 9C18.6213 9 19.125 8.49632 19.125 7.875V5.29894C25.3935 5.84697 30.3618 10.9311 30.7283 17.25H28.125C27.5037 17.25 27 17.7537 27 18.375C27 18.9963 27.5037 19.5 28.125 19.5H30.6666C30.2756 22.9674 28.5317 25.8725 25.9148 28.0025C25.433 28.3947 25.3602 29.1033 25.7525 29.5852C26.1447 30.067 26.8533 30.1398 27.3352 29.7475C30.675 27.0291 32.8404 23.1573 32.9916 18.5209C32.9977 18.4731 33.0009 18.4245 33.0009 18.375C33.0009 18.3451 32.9997 18.3154 32.9975 18.286C32.9991 18.1911 33 18.0957 33 18C33 9.71572 26.2842 3 18 3C9.71572 3 3 9.71572 3 18C3 22.8783 5.28454 26.9307 8.65479 29.7393C9.1321 30.137 9.84149 30.0725 10.2393 29.5951C10.637 29.1179 10.5725 28.4085 10.0952 28.0107C7.52837 25.8717 5.7401 22.9604 5.33646 19.5H7.87444C8.49577 19.5 8.99944 18.9963 8.99944 18.375C8.99944 17.7537 8.49577 17.25 7.87444 17.25H5.27169C5.43545 14.4271 6.51762 11.8506 8.22384 9.81483L10.0795 11.6705C10.5188 12.1098 11.2312 12.1098 11.6705 11.6705C12.1098 11.2312 12.1098 10.5188 11.6705 10.0795L9.81483 8.22384ZM25.1383 9.95113C24.781 9.67456 24.2736 9.68417 23.9274 9.97406L23.6016 10.2475C23.394 10.4218 23.0968 10.6718 22.7382 10.9741C22.0212 11.5788 21.0579 12.3937 20.0739 13.2343C19.0905 14.0742 18.0833 14.9423 17.2796 15.6524C16.8781 16.007 16.5236 16.3257 16.2465 16.5834C15.989 16.8231 15.7479 17.056 15.6168 17.2181C14.6373 18.4294 14.847 20.1882 16.0851 21.1465C17.3232 22.1047 19.1208 21.8997 20.1003 20.6884C20.2314 20.5263 20.4076 20.2432 20.5868 19.9429C20.7794 19.6199 21.0149 19.2089 21.2752 18.7451C21.7966 17.8166 22.4284 16.6579 23.0378 15.5283C23.6473 14.398 24.2364 13.2934 24.6729 12.4716C24.8912 12.0606 25.0713 11.7202 25.197 11.4824L25.3941 11.1093C25.6027 10.713 25.4956 10.2277 25.1383 9.95113Z" fill={fill}/>
</svg>

  )
}

export default DashBoardIconsSvg
