import { Form } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import ModalContext from "../../../../context/ModalContext";

const Snippet = ({ text, data }) => {
  let {
    popUpType,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  const [arr, setArr] = useState([]);
  const [count, setCount] = useState(0);
  var str = [];
  var answerlength = 0;
  var prevIndex = 0;
  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      var indexat = text?.indexOf(data[i].answer);
      str[i] = text.substring(answerlength + prevIndex, indexat);

      prevIndex = indexat;
      answerlength = data[i].answer.length;
    }

    str[data[0].answer.length] = text.substring(
      answerlength + prevIndex,
      text.length
    );
  }

  const handlechangeChange = (e, i) => {
    const newArr = [...arr];
    if (i < newArr.length) {
      newArr[i] = e.target.value;
    } else {
      newArr.push(e.target.value);
    }
    setAnswerJson({...answerJson,answer:newArr.join(",")})
    setArr(newArr);
    
  console.log("newArr",newArr.join(","))
  };
  return (
    <>
      {str.length > 0 &&
        str.map((item, i) => {
          return (
            <>
              {item}
              {str.length - 2 >= i && (
                <>
                  <select className="select-rw"
                    name="cars"
                    onChange={(e) => handlechangeChange(e, i)}
                    id="cars"
                  >
                    <option value="" style={{display:"none"}}></option>
                    {data.length > 0 &&
                      data[i].options.length > 0 &&
                      data[i].options.map((item) => {
                        return (
                          <>
                            <option value={item}>{item}</option>
                          </>
                        );
                      })}
                  </select>
                </>
              )}
            </>
          );
        })}
    </>
  );
};
function ReadWriteFillinBlanks({ data }) {
  const [text, setText] = useState(data?.question_content);
  const [count, setcount] = useState(0);
  useEffect(() => {
    setText(data.question_content);
  }, [data.question_content]);
  const [replaceText, setReplaceText] = useState(false);


  // Function to replace the answer with a select box
  const replaceWithSelectBox = (question) => {
    let newText = text;
    if(text == undefined){
      return
    }
    data.question_options.forEach((q) => {
      newText = newText.replace(new RegExp(q.answer, "g"), () => {
        return `<select name="cars" id="cars"}>
        <option value=""></option>
          ${q.options
            .map((option) => `<option value="${option}">${option}</option>`)
            .join("")}
        </select>`;
      });
    });
    setText(newText);
    setReplaceText(true);
  };
  useEffect(() => {
    replaceWithSelectBox();
    setcount(count+1)
  }, [data]);
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <br />
        {data.question_content && <Snippet key={count} text={data?.question_content} data={data.question_options} />}
      </div>
    </>
  );
}
export default ReadWriteFillinBlanks;
