import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar, Badge } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import PracticePteComments from "../PracticePteComments";
import { useContext, useState } from "react";
import ModalContext from "../../../../context/ModalContext";
const Snippet = ({ showAnwer,text, answer, setNewArray, optionsArr }) => {
  let {answerJson,setAnswerJson} = useContext(ModalContext)
    const [arr, setArr] = useState([]);
    var str = [];
    var answerlength = 0;
    var prevIndex = 0;
  
    if (answer?.length) {
      for (let i = 0; i < answer?.length; i++) {
        var indexat = text?.indexOf(answer[i]);
        str[i] = text?.substring(answerlength + prevIndex, indexat);
  
        prevIndex = indexat;
        answerlength = answer[i]?.length;
      }
  
      str[answer.length] = text?.substring(answerlength + prevIndex, text.length);
    }
    const handleChange =(e,i) =>{
      var newArray = [...arr]
       newArray[i] = e.target.value ;
       setAnswerJson({...answerJson,answer:newArray.join(",")})
      setArr(newArray)
    }
    console.log("text",text)
    console.log("answer",answer)
    return (
      <>
      <div className="practice-textarea mb-5">
        {!(showAnwer && str.length > 0) &&
          str.map((item, i) => {
            return (
              <>
                {item}
                {str.length - 2 >= i && (
                  <div className="d-inline-block mx-2 my-1">
                    <Form.Control type="text" id="fib-1" placeholder="" onChange={(e)=>handleChange(e,i)} className="px-3 py-1" />
                  </div>
                )}
              </>
            );
          })}
        {(showAnwer && str.length > 0) &&
          str.map((item, i) => {
            console.log("------------------",((arr[i]?arr[i]:false) == answer[i]))
            return (
              <>
                {item}
                {str.length - 2 >= i && (
                  <>
                  <div className="d-inline-block mx-2 my-1" >
                    <Form.Control type="text" id="fib-1"   placeholder="" onChange={(e)=>handleChange(e,i)} className={`px-3 py-1 ${((arr[i]?arr[i]:false) == answer[i].trim())?`border-green`:`border-red`}`} />
                  </div>
                  <span className="text-success">(Answer: {answer[i]})</span>
                  </>
                )}
              </>
            );
          })}
          </div>
      </>
    );
  };
  
function PracticePteListeningFIB({data,showAnwer}) {
    return (<>
        {/* <Snippet text={data.question_content} answer={question_options[0].answer} /> */}
       { data.question_options?.length > 0 && <Snippet showAnwer={showAnwer}text={data.question_content} answer={data.question_options[0].answer} />}
        </>)
}

export default PracticePteListeningFIB;