import React from 'react';

const ResultManagementSvg = ({ fill}) => {
  console.log("fill", fill);
  return (
    
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      fill='none'
      viewBox='0 0 36 36'
    >
      <path
        fill={fill}
        d='M27.375 4.5A4.125 4.125 0 0131.5 8.625v9.408a9.703 9.703 0 00-2.25-1.063V8.625c0-1.036-.84-1.875-1.875-1.875H8.625c-1.036 0-1.875.84-1.875 1.875v18.75c0 1.036.84 1.875 1.875 1.875h8.345c.259.8.618 1.555 1.063 2.25H8.625A4.125 4.125 0 014.5 27.375V8.625A4.125 4.125 0 018.625 4.5h18.75zM18.75 23.443l.671.645a3 3 0 010 4.324l-.67.645c.3.86.735 1.654 1.278 2.354l.903-.266a3 3 0 013.767 2.173l.259 1.074a7.77 7.77 0 002.584 0l.26-1.074a3 3 0 013.766-2.173l.902.266c.544-.7.979-1.494 1.28-2.354l-.671-.645a3 3 0 010-4.324l.67-.645a8.318 8.318 0 00-1.279-2.354l-.902.266a3 3 0 01-3.767-2.173l-.259-1.074a7.757 7.757 0 00-2.584 0l-.26 1.074a3 3 0 01-3.766 2.173l-.902-.266a8.316 8.316 0 00-1.28 2.354zm7.5 5.057c-1.2 0-2.175-1.007-2.175-2.25S25.05 24 26.25 24c1.2 0 2.174 1.007 2.174 2.25s-.973 2.25-2.174 2.25z'
      ></path>
      <path
          fill={fill}
        d='M22.025 9h4.314l.095.014.094.026.057.024a.684.684 0 01.207.143l.038.042.042.054.051.085.037.086.018.061.013.06.009.09v4.306a.711.711 0 01-1.415.097l-.006-.097v-2.56l-6.1 6.119a.71.71 0 01-.926.069l-.08-.07-2.87-2.764-5.39 5.405a.71.71 0 01-1.005 0 .714.714 0 01-.069-.928l.07-.08 5.891-5.909a.71.71 0 01.925-.069l.08.07 2.87 2.764 5.598-5.613h-2.548a.711.711 0 01-.704-.616l-.007-.096c0-.361.268-.66.615-.706L22.025 9zM9.711 23.25c.392 0 .71.319.71.712v3.326a.712.712 0 11-1.421 0v-3.326c0-.393.318-.712.71-.712zm5.447-3.087a.712.712 0 10-1.421 0v7.125a.712.712 0 101.42 0v-7.125z'
      ></path>
    </svg>
  );
}

export default ResultManagementSvg;
