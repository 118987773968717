import React, { useContext, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import ModalContext from "../../../../../context/ModalContext";
import ProgressBarCustom from "../../CustomProgressbar";
import { addTimes, calculateTotalTime } from "../../../../../utilities/addTwoTimes";

function RecorderUI({
  startRecording,
  timer,
  NoRecording,
  startAudio,
  data,
  stopRecordings,
  initialTime,
  beginTime
}) {
  const { popUpType, setPopUpType, manageRecordings } = useContext(ModalContext);
  const [time, setTime] = useState({
    minutes: timer.split(":").map(Number)[0],
    seconds: timer.split(":").map(Number)[1],
  });
  const [message, setMessage] = useState("Beginning");
  const [recordeDone, setrecordeDone] = useState(false);
  const [stopRecord, setStopRecord] = useState(false);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  

  function getAudioDuration(audioUrl) {
    return new Promise((resolve) => {
      if (audioUrl === "") {
        resolve("00:00");
        return;
      }
  
      const audio = new Audio(audioUrl);
      audio.addEventListener('loadedmetadata', function() {
        const duration = audio.duration;
        resolve(formatTime(duration));
      });
  
      audio.addEventListener('error', function() {
        resolve("00:00");
      });
    });
  }

  useEffect(() => {
    setrecordeDone(false);
    setMessage("Beginning");

    const content_audio_time = getAudioDuration(data.question_content_audio ? data.question_content_audio : "00:00").then((res) => calculateTotalTime(data?.answer_prepare_time, data?.question_begin_time, res)).then(totalTime => {
      const [minutes, seconds] = totalTime.split(":").map(Number);
      setTime({ minutes, seconds });
    })
    console.log("content_audio_time", content_audio_time)
    
    
    
    // calculateTotalTime(data?.answer_prepare_time, data?.question_begin_time, "content_audio_time")
    //   .then(totalTime => {
    //     const [minutes, seconds] = totalTime.split(":").map(Number);
    //     setTime({ minutes, seconds });
    //   });

  }, [data]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timerInterval);
        startRecording();
        setrecordeDone(true);
        setMessage("Recording...");
      } else if (time.minutes === 0 && time.seconds === 1) {
        setMessage("Beginning in 1 second.");
        setTime({ minutes: 0, seconds: 0 });
      } else if (time.minutes === 0) {
        const newSeconds = Math.max(0, time.seconds - 1);
        setMessage(`Beginning in ${newSeconds} seconds.`);
        setTime({ minutes: 0, seconds: newSeconds });
      } else if (time.minutes !== 0 && time.seconds === 0) {
        setMessage(`Beginning in ${time.minutes} minutes.`);
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      } else {
        const minuteText = time.minutes === 1 ? "m" : "m";
        setMessage(`Beginning in ${time.minutes} ${minuteText} ${time.seconds} seconds.`);
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [time, timer, popUpType]);

  return (
    <div className="audio-card my-3 bg-examrecord">
      <p className="text-center mb-0">Recorded Answer</p>
      <p className="d-flex flex-column">
        <p className="mb-0">Current Status :</p>
        <p className="mb-0">{message ? message : stopRecord ? "Completed" : "Recording..."}</p>
      </p>
      {startAudio && recordeDone && popUpType === "default" ? (
        <ProgressBarCustom
          data={data}
          stopRecordings={() => { setStopRecord(true); }}
          initialTime={initialTime}
        />
      ) : (
        <ProgressBar className="progress-ui" variant="hisuccess" now={0} style={{ height: "18px" }} />
      )}
    </div>
  );
}

export default RecorderUI;