import { useEffect, useState, useRef } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Nav,
  ProgressBar,
  Table,
  OverlayTrigger,
  Popover,
  Modal,
  Spinner,
} from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon, Pureknob, ChartLegend } from "../../../../components";
import { Colors } from "../../../../utilities/index";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2";
import parse from 'html-react-parser';
function PracticeResult({
  swtsmodal,
  swtsClose,
  current_qst_data,
  answer,
  answer_id,
  fibwrModal,
  wfdsModal,
  AI_modal,
  AIClose,
  api_response
}) {
  let api_res = api_response;
  let api = new AdminApi();
  const [swtsModal, setSwtsModal] = useState(swtsmodal);
  const swtsModalClose = () => {
    swtsClose();
    setSwtsModal(false);
  };
  useEffect(() => {
    console.log("AI_modal", AI_modal)
    setSwtsModal(swtsmodal);

    if (swtsmodal) {
      getSummarizetextScore();
    }
  }, [swtsmodal]);

  const getSummarizetextScore = async () => {
    let { module_name, module } = current_qst_data;
    try {
      if (module === "writing") {
        if (answer_id) {
          let data_ = JSON.stringify({
            title: current_qst_data.question_content
              ? JSON.stringify(current_qst_data.question_content)
              : "",
            language: "en",
            answer: JSON.stringify(answer?.answer),
            sample_answer: "",
          });

          await fetch(module_name === "essays" ? "https://speechengine.hilingo.com/GetEssayTextScore" : "https://speechengine.hilingo.com/GetSummarizeTextScore", {
            method: "post",
            body: data_,
            headers: {
              "X-Api-Key": "",
              "Content-Length": data_.length,
              Host: "speechengine.hilingo.com",
            },
          })
            .then((res) => res.json())
            .then(async (data) => {
              console.log("data", data);
              if (data) {
                await api
                  .update_practice_result({
                    answer_id: answer_id,
                    api_response: { ...data },
                  })
                  .then((res) => {
                    AIClose();
                  });
              }
              //   setState({ ...state, loader_modal: false, api_res: data });
            })
            .catch((err) => {
              //   setState({ ...state, loader_modal: false });
              //   setScoreModal(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong ",
              });
              console.log("errr", err);
            });
        }
      } else if (module_name === "ssts") {
        if (answer_id) {
          let data_ = JSON.stringify({
            title: current_qst_data.question_transcript
              ? JSON.stringify(current_qst_data.question_transcript)
              : "",
            language: "en",
            answer: JSON.stringify(answer?.answer),
            sample_answer: "",
          });

          await fetch("https://speechengine.hilingo.com/GetSummarizeListenScore", {
            method: "post",
            body: data_,
            headers: {
              "X-Api-Key": "",
              "Content-Length": data_.length,
              Host: "speechengine.hilingo.com",
            },
          })
            .then((res) => res.json())
            .then(async (data) => {
              console.log("data", data);
              if (data) {
                await api
                  .update_practice_result({
                    answer_id: answer_id,
                    api_response: { ...data },
                  })
                  .then((res) => {
                    AIClose();
                  });
              }
              //   setState({ ...state, loader_modal: false, api_res: data });
            })
            .catch((err) => {
              //   setState({ ...state, loader_modal: false });
              //   setScoreModal(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong ",
              });
              console.log("errr", err);
            });

        }
      }
    } catch (err) {
      AIClose();
    }
  };
  const [activeProject, setactiveProject] = useState({
    size: 110,
    value: 0,
    angleOffset: 0.4,
    angleStart: 1,
    data_max: 0,
    angleEnd: 1,
    colorFg: Colors.blue,
    trackWidth: "0.15",
  });
  useEffect(() => {
    console.log("USEEFFECT",answer?.total_score);
    setactiveProject({
      ...activeProject,
      data_max: swtsmodal
        ? api_res?.max_score
        : fibwrModal ? answer?.total_score : 10,
      value: swtsmodal
        ? api_res?.total_score
        : wfdsModal ? answer?.actual_score :fibwrModal && answer?.actual_score,
    });
  }, [api_res, fibwrModal, swtsmodal,
    wfdsModal,]);

  var text = answer?.answer;
  console.log("answer", answer)
  // var text = "this is my house not ypur house this is my house not ypur house this is my house not ypur house this is my house not ypur house this is my house not ypur house"
  return (
    <>

      <Modal
        show={AI_modal}
        onHide={swtsClose}
        className="show-backdrop result-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>AI Score</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-middark">
          <h5 className="d-flex align-items-center justify-content-center mb-5">
            {/* <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner> */}

            <div className="position-relative">
              <img src="/images/loader.gif" style={{ width: "220px", marginLeft: "-14%" }} />
              <span className="score-fixed fadeinout">Scoring</span>
            </div>
            <span style={{ marginLeft: "-10%", zIndex:"9" }}>AI Scoring Ongoing ...</span>
          </h5>
          {/* <p className="text-center">Takes around 30 to 60 sec you can check back later</p> */}
          <p className="text-center fadeinout mb-5">William Shakespear contributed over 1.700 words to the English language, including "eyeball", "bedazzied", and "swagger"</p>
          <p className="text-center">AI Scoring takes around 30 - 60 sec you can check back later</p>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={swtsModal && !AI_modal} onHide={swtsModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>AI Score(SWTS)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-5 mt-3 mb-5">
            <div className="shadow d-flex flex-column align-items-center p-3 w-30 rounded">
              {/* pronunciation_accuracy */}
              <div className="d-flex gap-3 justify-content-center align-items-center">
                <Pureknob
                  datamax={activeProject.data_max}
                  data={activeProject}
                  key={activeProject.value}
                  className="nk-chart-project-active"
                />
                <div className="d-flex gap-3 text-secondary">
                  <div className="d-flex flex-column">
                    <span>Content :</span>
                    <span>Form :</span>
                    <span>Grammar :</span>
                    <span>Vocabulary :</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span>{api_res?.content_score}/2</span>
                    <span>{api_res?.form_score}/1</span>
                    <span>{api_res?.grammar_score}/2</span>
                    <span>{api_res?.vocabulary_score}/2</span>
                  </div>
                </div>
              </div>
              <p className="text-secondary">
                Max Score : {api_res?.max_score}, Your Score :{" "}
                <span className="text-danger fw-bold">
                  {api_res?.total_score}
                </span>
              </p>
            </div>
            <div className="shadow d-flex gap-3 justify-content-center align-items-center p-3 w-70 rounded">
              <p className="text-break">
                {text?.split(" ").map((e, i) => {
                  var findd = api_res?.grammar?.find((item) => item.index == i);
                  return (
                    <>
                      {findd ? (
                        <>
                          <OverlayTrigger
                            overlay={
                              <Popover id="popover-positioned-disbled">
                                <Popover.Header as="h3">
                                  {" "}
                                  {findd.message}
                                </Popover.Header>
                                <Popover.Body>
                                  {findd.message}
                                  <p>Suggestion : - {findd?.replacements}</p>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <span className="text-danger text-decoration-underline mx-1">
                              {e}
                            </span>
                          </OverlayTrigger>
                        </>
                      ) : (
                        <span>{" " + e + " "}</span>
                      )}
                    </>
                  );
                })}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={swtsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="modal-xl" show={fibwrModal} onHide={swtsModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            AI Score <br />
            {/* (FIB_WR,r_mcm,ro,fib_rd,r_mcs,l_mcm,l_fib,l_hcs,l_mcs,l_smw,hiws) */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row className="container  ">

            <Col className="mt-3 ">
              <div className="">
                <Pureknob
                  datamax={activeProject.data_max}
                  data={activeProject}
                  key={activeProject.value}
                  className="nk-chart-project-active"
                />
                <div className="d-flex gap-3 text-secondary">
                  <div className="d-flex flex-column">
                    <span>Blanks :</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span>{answer?.score}</span>
                  </div>
                </div>
              </div>
            </Col>


            <Col className="col-10">
              <Row className="d-flex gap-3 py-3 bg-light ">
                <Col>
                  <h5>Component</h5>
                </Col>
                <Col>
                  <h5>Score</h5>
                </Col>
                <Col>
                  <h5>Suggestion</h5>
                </Col>
              </Row>
              <Row className="d-flex gap-3 py-3 text-black">
                <Col>
                  <p>Pair</p>
                </Col>
                <Col>
                  <p>{answer?.actual_score}/{answer?.total_score}</p>
                </Col>
                <Col>
                  <p>Give one More Try</p>
                </Col>
              </Row>
              <Row className="d-flex gap-3 py-3 bg-light ">
                <Col>
                  <p className="text-black">Max Score : {answer?.total_score}, &nbsp;Your Score :{"  "} <span className="text-danger fw-bold">{answer?.actual_score}</span> </p>

                </Col>

              </Row>
            </Col>

          </Row>
        </Modal.Body>
        {/* <Modal.Body>
          <div className="d-flex gap-5 justify-content-center mt-3 mb-5">
            <div className="shadow d-flex flex-column align-items-center p-3 rounded">
              // pronunciation_accuracy
              <div className="d-flex gap-3 justify-content-center align-items-center">
                <Pureknob
                 datamax={activeProject.data_max}
                  data={activeProject}
                  key={activeProject.value}
                  className="nk-chart-project-active"
                />
                <div className="d-flex gap-3 text-secondary">
                  <div className="d-flex flex-column">
                    <span>Blanks :</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span>{answer?.score}</span>
                  </div>
                </div>
              </div>
              <p className="text-secondary">
                Max Score : {answer?.total_score}, Your Score :{" "}
                <span className="text-danger fw-bold">{answer?.actual_score}</span>
              </p>
            </div>
          </div>
        </Modal.Body> */}
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={swtsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={wfdsModal} onHide={swtsModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>AI Score(WFDS)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-5 mt-3 mb-5">
            <div className="shadow d-flex flex-column align-items-center p-3 w-30 rounded">
              {/* pronunciation_accuracy */}
              <div className="d-flex gap-3 justify-content-center align-items-center">
                <Pureknob
                  data={activeProject}
                  key={activeProject.value}
                  className="nk-chart-project-active"
                />
                <div className="d-flex gap-3 text-secondary">
                  <div className="d-flex flex-column">
                    <span>Words :</span>
                  </div>
                  <div className="d-flex flex-column">
                    {console.log("API_RES",answer)}
                    <span>{answer?.actual_score}/{answer?.total_score}</span>
                  </div>
                </div>
              </div>
              <p className="text-secondary">
                Max Score : {answer?.total_score}, Your Score : {answer?.actual_score}
                {/* <span className="text-danger fw-bold">3</span> */}
              </p>
            </div>
            <div className="shadow d-flex flex-column gap-3 align-items-center p-3 w-70 rounded">
              <div className="d-flex justify-content-between w-100">
                <span className="text-secondary">Your Answer : </span>
                <ChartLegend.Group className="gap gx-3 align-items-center">
                  <div className="gap-col">
                    <ChartLegend
                      className="chart-legend-small"
                      symbol="primary"
                    >
                      Correct
                    </ChartLegend>
                  </div>
                  <div className="gap-col">
                    <ChartLegend className="chart-legend-small" symbol="danger">
                      Missing
                    </ChartLegend>
                  </div>
                  <div className="gap-col">
                    <ChartLegend
                      className="chart-legend-small"
                      symbol="secondary"
                    >
                      Not Answer
                    </ChartLegend>
                  </div>
                </ChartLegend.Group>
              </div>
              <p>{parse(answer?.wfds_answer || "")}</p>
              {/* <p className="text-primary">
                Many{" "}
                <span className="text-danger">(different conferences)</span>{" "}
                <strike className="text-secondary">
                  difference conffrence
                </strike>{" "}
                offer{" "}
                <span className="text-danger">
                  (opportunities for volunteer)
                </span>{" "}
                <strike className="text-secondary">diffrent volountry</strike>{" "}
                work <strike className="text-secondary">worker</strike>
              </p> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={swtsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default PracticeResult;
