import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({children}) => {
    let navigate = useNavigate();
    useEffect(()=>{

        // if(!localStorage.getItem("hilingo_ext_student")){
        if(!localStorage.getItem("userHilingo")){
          // return <Navigate to={"/"} replace/> // not user component render inside hook
           navigate("/auths/student-login")
        }
      },[])
      
      return children
}

export default ProtectedRoute
