import React from 'react';

const AccountSvg = ({ fill}) => {
  console.log("fillacc", fill);
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      fill='none'
      viewBox='0 0 36 36'
    >
      <path
        fill={fill}
        d='M16.2 3.6a7.2 7.2 0 100 14.4 7.2 7.2 0 000-14.4zm-5.4 7.2a5.4 5.4 0 1110.8 0 5.4 5.4 0 01-10.8 0zm-3.585 9A3.602 3.602 0 003.6 23.4c0 3.044 1.499 5.34 3.843 6.834C9.75 31.705 12.86 32.4 16.2 32.4c.74 0 1.468-.034 2.178-.103a9.903 9.903 0 01-1.112-1.723c-.35.017-.706.026-1.066.026-3.124 0-5.863-.655-7.79-1.884-1.89-1.205-3.01-2.96-3.01-5.316 0-.996.806-1.8 1.815-1.8H17.28a9.905 9.905 0 011.184-1.8H7.215zm11.928 5.256a3.6 3.6 0 002.575-4.46l-.281-1.003a7.766 7.766 0 011.48-.895l.606.644a3.6 3.6 0 005.238.003l.584-.619c.536.253 1.04.567 1.503.933l-.227.76a3.6 3.6 0 002.62 4.534l.629.149a8.455 8.455 0 01.018 1.83l-.83.211a3.6 3.6 0 00-2.574 4.46l.28 1.003a7.766 7.766 0 01-1.479.895l-.607-.644a3.6 3.6 0 00-5.237-.003l-.585.62a7.774 7.774 0 01-1.502-.933l.227-.76a3.6 3.6 0 00-2.62-4.535l-.63-.148a8.484 8.484 0 01-.018-1.83l.83-.212zM27.9 26.1a1.8 1.8 0 10-3.6 0 1.8 1.8 0 003.6 0z'
      ></path>
    </svg>
  );
}

export default AccountSvg;
