import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimePicker from "../../../../components/Form/TimePicker";
import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../../components";
import { useContext, useState, useEffect } from "react";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteReadingMultiChoiceCheck() {
  let { id } = useParams();
  let navigate = useNavigate();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    test_type: "Multiple Choice,Choose Multiple Answer",
    exam_type: "pte",
    instructions:"Read the text and answer the question by selecting all the correct responses. More than one response is correct.",
    question_options: [
      {
        options: "",
        answer: false,
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isSubmitDisabled = () => {
    return (
      data.tags === "" ||
      data?.answer_time?.length !== 5 ||
      data.module_name == "" ||
      data.module == "" ||
      !(data.isMock || data.isPrediction || data.isQuestion || data.isSection) ||
      data.instructions === "" ||
      data.question_content === "" ||
      data.question === "" ||
      data.question_options.some((option) => option.options === "")
    );
  };
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) => item.options !== ""
    );
    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };

  const handleAdd = (parentIndex, childIndex, type) => {
    let handleAdd = [...data.question_options];
    handleAdd.push({
      options: "",
      answer: false,
    });
    setData({
      ...data,
      question_options: handleAdd,
    });
  };
  const handleRemove = (item, index) => {
    let handleAddRemove = [...data.question_options];
    handleAddRemove.splice(index, 1);
    setData({
      ...data,
      question_options: handleAddRemove,
    });
  };
  const handleChange = (e, i) => {
    // e.preventDefault();
    let { name, value } = e.target;
    if (i == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if (name == "options") {
      const updatedOptions = [...data.question_options];
      updatedOptions[i] = {
        ...updatedOptions[i],
        options: value,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "answer") {
      const updatedOptions = [...data.question_options];
      updatedOptions[i] = {
        ...updatedOptions[i],
        answer: value == "true" ? true : false,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else {
      if (name !== "options" && name !== "answer") {
        if (name == "question_type") {
          const options = e.target.options;
          const selectedValues = [];
          if (options.length == 0) {
            setSelectedOptions([]);
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues);
          }
          setData({
            ...data,
            isMock: selectedValues.includes("isMock") ? true : false,
            isPractice: selectedValues.includes("isPractice") ? true : false,
            isSection: selectedValues.includes("isSection") ? true : false,
            isQuestion: selectedValues.includes("isQuestion") ? true : false,
            isPrediction: selectedValues.includes("isPrediction")
              ? true
              : false,
          });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };

  let api = new AdminApi();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(!loader);
    await api
      .add_Question(removeBlankItems())
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
        }
        setloader(!loader);
      })
      .catch((err) => {
        setloader(!loader);
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Reading Multiple Choice, Choose Multiple Answer Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => {
                      setSelectedOptions(e);
                    }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e);
                      setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    {id && (
                      <EditTimesFiled
                        data={data}
                        onDataChange={(name, val) =>
                          setData({ ...data, [name]: val })
                        }
                      />
                    )}
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="instruction"
                          className="ms-3 fw-bold"
                        >
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Paragraph
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            onChange={(e) => handleChange(e)}
                            name="question_content"
                            value={data.question_content}
                            placeholder="Add Paragraph"
                            id="paragraph"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Question
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="question"
                            value={data.question}
                            onChange={(e) => handleChange(e)}
                            as="textarea"
                            placeholder="Add Question"
                            id="desc"
                            rows="1"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <Form.Group className="form-group my-2">
                    <Row className="g-gs">
                      <Col md="10">
                        <Form.Label
                          htmlFor="multiradio"
                          className="ms-3 fw-bold"
                        >
                          Add Multiple Answer Option
                        </Form.Label>
                      </Col>
                      <Col md="2">
                        <Form.Label htmlFor="multiradio">
                          Add Correct Answer
                        </Form.Label>
                      </Col>
                    </Row>
                    {data?.question_options.length > 0 &&
                      data?.question_options.map((item, i) => {
                        return (
                          <>
                            <div className="d-flex gap-1 gap-md-3 align-items-center my-2">
                              <Form.Group className="form-group w-75">
                                <div className="form-control-wrap">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Options"
                                    id="option-1"
                                    name="options"
                                    value={item.options}
                                    onChange={(e) => handleChange(e, i)}
                                  ></Form.Control>
                                </div>
                              </Form.Group>
                              <div>
                                {data?.question_options.length - 1 == i ? (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                    onClick={handleAdd}
                                  >
                                    <Icon
                                      name="plus-circle"
                                      variant="primary"
                                    ></Icon>
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                  ></Button>
                                )}
                                {data?.question_options.length > 1 && (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                    onClick={(e) => handleRemove(e, i)}
                                  >
                                    <Icon
                                      name="minus-circle"
                                      variant="danger"
                                    ></Icon>
                                  </Button>
                                )}
                              </div>
                              <div className="border rounded px-2 py-1">
                                <Form.Check
                               value={((item?.answer == 'false' || item?.answer == false) && true )|| ((item?.answer == 'true' || item?.answer == true)&& false  )}
                                  onChange={(e) => handleChange(e, i)}
                                  checked={((item?.answer == 'true' || item?.answer == true ) && true )|| ((item?.answer == 'false' || item?.answer == false)&& false  )}
                                 
                                  name="answer"
                                  type="checkbox"
                                  id="option-1"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </Form.Group>
                </Col>
                <Col lg="9">
                  <TranscriptExplanation
                    setState={(name) => setData({ ...data, [name]: "" })}
                    data={data}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">
                      Add Tag (#)
                    </Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        value={data.tags}
                        placeholder="#Tag"
                        name="tags"
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between my-5">
                  <PrevNextButton id={id} />
                  <SubmitButton
                    loader={loader}
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled()}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteReadingMultiChoiceCheck;
