import React from 'react';

const PracticeDiscussionSvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C8.65077 18.3332 7.34753 18.012 6.17673 17.4065L2.98855 18.2959C2.43445 18.4507 1.85983 18.1269 1.70511 17.5728C1.6539 17.3895 1.65392 17.1956 1.70514 17.0124L2.59486 13.8267C1.98799 12.6548 1.66602 11.3501 1.66602 9.99984C1.66602 5.39746 5.39697 1.6665 9.99935 1.6665ZM9.99935 2.9165C6.08733 2.9165 2.91602 6.08782 2.91602 9.99984C2.91602 11.2245 3.22666 12.4027 3.81021 13.4478L3.93575 13.6725L3.00848 16.9927L6.33064 16.0658L6.55526 16.1911C7.5994 16.7733 8.77618 17.0832 9.99935 17.0832C13.9113 17.0832 17.0827 13.9118 17.0827 9.99984C17.0827 6.08782 13.9113 2.9165 9.99935 2.9165Z" fill={fill}/>
    <path d="M8 10C8 9.44772 7.55228 9 7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11C7.55228 11 8 10.5523 8 10Z" fill={fill}/>
    <path d="M11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10Z" fill={fill}/>
    <path d="M14 10C14 9.44772 13.5523 9 13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10Z" fill={fill}/>
    </svg>
    
    
  );
}

export default PracticeDiscussionSvg;
