import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import Question_Context from "../../../../context/questionContext";
import { useContext, useState, useEffect } from "react";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteReadingWritingFillinBlanks() {
  let { id } = useParams();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "reading_fill_blanks",
    instructions:"There are some words missing in the following text. Please select the correct word in the drop-down box.",
    question_options: [
      {
        options: [""],
        answer: "",
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false
  });
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const trash = (type, index, i) => {
    if (type === "answers") {
      const updatedOptions = [...data.question_options];
      updatedOptions.splice(index, 1);

      setData({
        ...data,
        question_options: updatedOptions,
      });
    }
    if (type === "options") {
      const updatedOptions = [...data.question_options];
      updatedOptions[index].options.splice(i, 1);
      setData({
        ...data,
        question_options: updatedOptions,
      });
    }
  };

  const handleAdd = (parentIndex, childIndex, type) => {
    if (type == "options") {
      const updatedOptions = [...data.question_options];
      updatedOptions[parentIndex].options.push("");
      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else {
      const updatedOptions = [...data?.question_options];
      updatedOptions.push({
        options: [""],
        answer: "",
      });
      setData({
        ...data,
        question_options: updatedOptions,
      });
    }
  };

  const handleChange = (e, parentIndex, childIndex) => {
    const { name, value } = e.target;
    if (parentIndex == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if (name == "answer") {
      const updatedOptions = [...data.question_options];
      updatedOptions[parentIndex] = {
        ...updatedOptions[parentIndex],
        answer: value,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "options") {
      const updatedOptions = [...data.question_options];

      updatedOptions[parentIndex].options[childIndex] = value;
      setData({
        ...data,
        question_options: updatedOptions,
      });

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else {
      if (name !== "options" && name !== "answer") {
        if (name == "question_type") {
          const options = e.target.options;
          const selectedValues = [];
          if (options.length == 0) {
            setSelectedOptions([])
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues)
          }
          setData({
            ...data, isMock: selectedValues.includes("isMock") ? true : false
            , isPractice: (selectedValues.includes("isPractice") ? true : false)
            , isSection: (selectedValues.includes("isSection") ? true : false)
            , isQuestion: (selectedValues.includes("isQuestion") ? true : false)
            , isPrediction: selectedValues.includes("isPrediction") ? true : false,
          });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) =>
        item.answer !== "" ||
        (item.options.length > 0 &&
          item.options.some((option) => option !== ""))
    );

    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };
  let api = new AdminApi();
  let navigate = useNavigate();
  let formObject = new FormData();
  console.log("data",data)
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isAudio = false;
    let isVideo = false;

    // if (data?.explanatory_video_file?.length > 0 ) {
    //   const fileType = data.explanatory_video_file[0].type;
    //   if (fileType.startsWith("audio/")) {
    //     isAudio = true;
    //     formObject.append("explanatory_video_file", data.explanatory_video_file[0]);
    //   } else if (fileType.startsWith("video/")) {
    //     isVideo = true;
    //     formObject.append("explanatory_video_file", data.explanatory_video_file[0]);
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       title: "File must be an video",
    //       text: "Please upload an  video file.",
    //     });
    //     return;
    //   }
    // }
for (const [key, value] of Object.entries(data)) {
  if (key !== "explanatory_video_file" && key !== "question_options") {
    if (value != null) {
      formObject.append(key, value);
    }
  } else if (key === "question_options") {
    value.forEach((question, index) => {
      question.options.forEach((option, subIndex) => {
        formObject.append(
          `question_options[${index}][options][${subIndex}]`,
          option
        );
      });
      formObject.append(
        `question_options[${index}][answer]`,
        question.answer
      );
    });
  }
}

    setloader(!loader)
    await api
      .add_Question_speaking(formObject)
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
          ;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
        setloader(!loader)
      })
      .catch((err) => {
        setloader(!loader)
        console.log("err", err);
      });
  };
  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    const {
      instructions,
      question_content,
      question_options,
      tags,
      answer_time,
      question_type,
      module_name,
      module,
    } = data;
    let questionOptions = data.question_options.some(
      (option) => option.answer == "" || option.options.includes("")
    );
    return (
      instructions &&
      question_content &&
      tags &&
      answer_time.length===5  &&
            (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      !questionOptions
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);
  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // const isSubmitDisabled = () => {
  //   return (
  //     data.module == ""||
  //     data.module_name == ""||
  //     data.tags === "" ||
  //     data.question_type === "" ||
  //     data.question_content === "" ||
  //     data.instructions === "" ||
  //     data.question_options.some(
  //       (option) =>
  //         option.answer === "" &&
  //         option.options.includes("")
  //     )
  //   );
  // };
  console.log("data", data);
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Reading and Writing Fill in Blanks Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => { setSelectedOptions(e) }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e); setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Add Paragraph</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="question_content"
                            value={data.question_content}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Paragraph"
                            id="paragraph"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <h5 className="mb-3">Add Blank Input Field</h5>
                  <div
                    className="d-flex gap-3 flex-wrap"
                    id="blankfieldsOption"
                  >
                    {data?.question_options?.length != 0 &&
                      data?.question_options?.map((item, i) => {
                        return (
                          <>
                            <Form.Group className="form-group">
                              <div className="form-control-wrap" key={i}>
                                <Form.Control
                                  style={{ width: "150px" }}
                                  type="text"
                                  placeholder="Enter Text"
                                  value={item.answer}
                                  onChange={(e) => handleChange(e, i, null)}
                                  name="answer"
                                />

                                {data?.question_options?.length != 1 && (
                                  <div
                                    className="form-control-icon end"
                                    onClick={(e) => trash("answers", i)}
                                  >
                                    <Icon variant="danger" name="trash"></Icon>
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          </>
                        );
                      })}

                    <Button
                      variant="outline-primary"
                      name="options"
                      onClick={handleAdd}
                      style={{ width: "50px" }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </div>
                </Col>
                <Col lg="12">
                  <h5 className="mb-3">Add Input Field Options</h5>
                  <Row className="g-gs">
                    {data?.question_options?.length > 0 &&
                      data?.question_options?.map((item, index) => {
                        return (
                          <>
                            <Col md="3">
                              <div className="d-flex flex-column gap-3 flex-wrap bg-light bg-opacity-50 py-3 px-5 rounded">
                                {item?.options?.length > 0 &&
                                  item?.options?.map((e, i) => {
                                    return (
                                      <>
                                        <Form.Group className="form-group">
                                          <div className="form-control-wrap">
                                            <Form.Control
                                              type="text"
                                              id="fillinblank"
                                              placeholder="Enter Text"
                                              name="options"
                                              value={item.options[i]}
                                              onChange={(e) =>
                                                handleChange(e, index, i)
                                              }
                                            />
                                            {item?.options?.length != 1 && (
                                              <div
                                                className="form-control-icon end"
                                                onClick={(e) =>
                                                  trash("options", index, i)
                                                }
                                              >
                                                <Icon
                                                  variant="danger"
                                                  name="trash"
                                                ></Icon>
                                              </div>
                                            )}
                                          </div>
                                        </Form.Group>
                                        {i == item?.options.length - 1 && (
                                          <Button
                                            variant="outline-primary"
                                            style={{ width: "50px" }}
                                            onClick={(e) =>
                                              handleAdd(index, i, "options")
                                            }
                                            className="mx-auto"
                                          >
                                            <Icon name="plus"></Icon>
                                          </Button>
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                </Col>
                <Col lg="9">
                  <TranscriptExplanation setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        value={data.tags}
                        name="tags"
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                        placeholder="#Tag"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold" htmlFor="addaudio">Explanatory Video (Optional)</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                          type="text"
                          id="Explanatory Video"
                          value={data?.explanatory_video}
                          name="explanatory_video"
                          onChange={(e) => {
                            setData({...data, "explanatory_video":e.target.value})
                          }}
                          placeholder="Explanatory Video URL"
                        />
                          {/* {data?.explanatory_video ? (
                            <>
                              <div className="d-flex gap-2">
                                {(data?.explanatory_video  && (
                                  <video
                                    src={data?.explanatory_video }
                                    width="200"
                                    height="155"
                                    controls={false}
                                  />
                                )) 
                                  }
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        explanatory_video : "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="video"
                          iconName="upload"
                          name="explanatory_video_file"
                          onChange={(updatedFiles) => {
                            setData({ ...data, explanatory_video_file: updatedFiles });
                          }}
                        />
                          )} */}
                        </div>
                      </Form.Group>
                    </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid}/>
               
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteReadingWritingFillinBlanks;
