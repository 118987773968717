import { useState } from "react";
import { Card, Form, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from '../../components';
// import QuestionsList from "./QuestionsList";

function CreateOwnMockTest() {

  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Create Your Own Mocktest</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="6">
                  <Card className="service-box hisuccess h-100">
                    <Card.Body>
                      <h3>Speaking</h3>
                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hisuccess1" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hisuccess1" className="d-flex justify-content-between"><span>Read Aloud</span><span className="d-flex gap-1">00/06 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hisuccess2" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hisuccess2" className="d-flex justify-content-between"><span>Repeat Sentence</span><span className="d-flex gap-1">00/12 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hisuccess3" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hisuccess3" className="d-flex justify-content-between"><span>Describe Image</span><span className="d-flex gap-1">00/03 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hisuccess4" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hisuccess4" className="d-flex justify-content-between"><span>Re-Tell Lecture</span><span className="d-flex gap-1">00/01 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hisuccess5" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hisuccess5" className="d-flex justify-content-between"><span>Answer Short Question</span><span className="d-flex gap-1">00/05 <Icon name="check-circle"></Icon></span></Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="service-box hidanger h-100">
                    <Card.Body>
                      <h3>Writing</h3>
                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hidanger1" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hidanger1" className="d-flex justify-content-between"><span>Summarize Written Text</span><span className="d-flex gap-1">00/01 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hidanger2" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hidanger2" className="d-flex justify-content-between"><span>Write Essay</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="service-box hiwarning h-100">
                    <Card.Body>
                      <h3>Reading</h3>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hiwarning1" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hiwarning1" className="d-flex justify-content-between"><span>R&W : Fill in the Blanks</span><span className="d-flex gap-1">00/06 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hiwarning2" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hiwarning2" className="d-flex justify-content-between"><span>MCQ - Multiple</span><span className="d-flex gap-1">00/01 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hiwarning3" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hiwarning3" className="d-flex justify-content-between"><span>Re-order Paragraphs</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hiwarning4" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hiwarning4" className="d-flex justify-content-between"><span>Reading : Fill in the Blanks</span><span className="d-flex gap-1">00/05 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hiwarning5" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hiwarning5" className="d-flex justify-content-between"><span>MCQ - Single</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="service-box hiprimary h-100">
                    <Card.Body>
                      <h3>Listening</h3>
                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary1" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary1" className="d-flex justify-content-between"><span>Summarize Spoken Text</span><span className="d-flex gap-1">00/01 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary2" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary2" className="d-flex justify-content-between"><span>MCQ - Multiple</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary3" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary3" className="d-flex justify-content-between"><span>Fill in the Blanks</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary4" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary4" className="d-flex justify-content-between"><span>Highlight Correct Summary</span><span className="d-flex gap-1">00/01 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary5" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary5" className="d-flex justify-content-between"><span>MCQ - Single</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary6" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary6" className="d-flex justify-content-between"><span>Select Missing Word</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary7" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary7" className="d-flex justify-content-between"><span>Highlight Incorrect Words</span><span className="d-flex gap-1">00/02 <Icon name="check-circle"></Icon></span></Button>

                      <input type="radio" className="btn-check" name="btnradio-mock" id="btnradio-hirpimary8" autoComplete="off" />
                      <Button as="label" htmlFor="btnradio-hirpimary8" className="d-flex justify-content-between"><span>Write From Dictation</span><span className="d-flex gap-1">00/04 <Icon name="check-circle"></Icon></span></Button>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm="12" xxl="12" md="12" className="my-5">
                  <Card className="h-100">
                    <Card.Body className="my-5">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Select Question</h5>
                        </div>
                      </div>
                      <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-auto">
                        <Nav variant="pills" className="mb-3 justify-content-center gap-2" style={{ marginTop: "-30px" }}>
                          <Nav.Item>
                            <Nav.Link eventKey="pills-auto">Auto</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="pills-manual">Manual</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="pills-auto">
                            <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
                              <div className="d-flex gap-5">
                                <span className="text-secondary">Filter Type 1</span>
                                <Form.Check type="radio" name="filter-1" id="filter-1-1" label="All Ques" />
                                <Form.Check type="radio" name="filter-1" id="filter-1-2" label="Prediction Ques" />
                                <Form.Check type="radio" name="filter-1" id="filter-1-3" label="New Ques" />
                              </div>
                              {/* <div className="d-flex gap-5">
                                <span className="text-secondary">Filter Type 2</span>
                                <Form.Check type="checkbox" name="filter-2" id="filter-2-1" label="Practiced" />
                                <Form.Check type="checkbox" name="filter-2" id="filter-2-2" label="No Practiced" />
                                <Form.Check type="checkbox" name="filter-2" id="filter-2-3" label="Bookmarked" />
                              </div> */}
                              <Button variant="primary" className="w-auto m-auto btn btn-primary">Add</Button>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="pills-manual">
                          <div className="d-flex flex-column">
                           {/* <QuestionsList/> */}
                           <Button variant="primary" className="w-auto mx-auto btn btn-primary">Add</Button>
                           </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default CreateOwnMockTest;