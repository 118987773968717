import { ProgressBar } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import Timer from "../Timer";
import WaveForm from "../../waveForm";
import React, { useState, useEffect, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { useAudioRecorder } from "react-audio-voice-recorder";
import AdminApi from "../../../../api/admin";
import ProgressBarCustom from "../CustomProgressbar";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import AWS from "aws-sdk";
import { Await } from "react-router-dom";
import { S3upload } from "../S3upload";
import RecorderUI from "../Istructions/ExamComponents/RecorderUI";
import OnlyRecorderUI from "../Istructions/ExamComponents/OnlyRecorderUI";


const S3_BUCKET_NAME = 'hilingo';
const REGION = 'ap-south-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();
function DescribeImage({ data, setModal}) {
  let { popUpType, setPopUpType,setAnswerJson ,answerJson} = useContext(ModalContext);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();
  const [startRecord, setStartRecord] = useState(false);
  useEffect(() => {
    if (!isRecording) {
      setPopUpType("prepare");
    } else {
      setPopUpType("default");
    }
  }, [isRecording]);
  

  const handleStopRecording = async () => {
    stopRecording();
      S3upload(recordingBlob,`answers/${answerJson?.exam_id}/${answerJson?.qst_id}/${data?.module_label.split(' ').join('_')}`).then((url) => {
        setAnswerJson({...answerJson,audio_url:url});
      })    
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        {/* <h2 className="my-5">
                Chart 3. Percent of labor forces. by age group.
              </h2> */}
        <div className="d-flex justify-content-center align-items-center gap-4 my-5">
          {/* <h4 className="text-examheadtext">Percent of labor forces</h4>{" "} */}
          <div className="desc-image">
          <img src={data?.question_content_image}></img>
          {/* <img src="https://roxcapitals.com/static/product_statement-aa1dc8c549c44715cb76afda8c9e5fb6.png"></img> */}
          </div>
          <div className="d-flex justify-content-center w-50 mb-5">
          <OnlyRecorderUI
              startAudio={()=>{}}
              data={data}
              stopRecordings={handleStopRecording}
              initialTime={data.answer_time}
              timer={data?.answer_prepare_time}
              startRecording={() => {
                // setStartAudio(true);
                // startRec();
              }}
              NoRecording={false}
            />
          </div>
        
        </div>
        {/* <p>In classes your teachers will talk about topics that you are studying. The information that they provide will be important for you to know when you take tests. You must be able to take good written notes from what your teachers say.</p> */}
      </div>
    </>
  );
}
export default DescribeImage;
