import { Form, Col, Row, Button } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { KanbanBasic } from "../../../../components/Kanban/Kanban";
import { useEffect, useState } from "react";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import DragDropBoth from "../../../kanban/DragDropBoth";
// kanban data

function ReadingReOrder({ data, key}) {
  let {
    popUpType,
    answerNull,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  
  return (
    <>
      {window.location.pathname?.includes("pte-scored-test-intro") && <em className="pte-test-instructions-bold">{data?.instructions}</em>}
     <DragDropBoth data={data}   ></DragDropBoth> 
    </>
  );
}

export default ReadingReOrder;
