import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function MarkSelectionComp() {

  return (
    <>
      <div className='bg-hilight text-secondary d-flex gap-2 flex-wrap p-3'>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-secondary rounded-circle"></span> <span>Total Words: 71</span></div>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-warning rounded-circle"></span> <span>Grammar/Style/Structure Error: 0</span></div>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-danger rounded-circle"></span> <span>Spelling Error : 9</span></div>
      </div>
    </>
  );
}
export default MarkSelectionComp;