import React, { useContext, useEffect, useState, useRef } from "react";
import ModalContext from "../../../context/ModalContext";
import Audio from "./customAudio/audio";
import { ProgressBar } from "react-bootstrap";

function Video({ timer, data, startRecording }) {
  let { setPopUpType, popUpType } = useContext(ModalContext);
  const { question_content_video } = data;
  const videoRef = useRef(null);
  const [count, setCount] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);

  const handleVolumeChange = (val) => {
    const volumeValue = Number(val);
    setCount(volumeValue);
    videoRef.current.volume = volumeValue / 100;
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(
        (videoRef?.current?.currentTime / videoRef?.current?.duration) * 100
      );
      if (((videoRef?.current?.currentTime / videoRef?.current?.duration) * 100) == 100) {
        setPopUpType("default")
      }
    };

    videoRef?.current?.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoRef?.current?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);
  const [startAudio, setstartAudio] = useState(false);
  const [time, setTime] = useState({
    minutes: timer?.split(":").map(Number)[0],
    seconds: timer?.split(":").map(Number)[1],
  });
  const [message, setMessage] = useState("Begining");
  useEffect(() => {
    setPopUpType("prepare_question");
    setstartAudio(false);
  }, []);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timerInterval);
        startRecording();
        setstartAudio(true);
        // setPopUpType("default");
        setMessage("Playing...");
      } else if (time.minutes === 0 && time.seconds === 1) {
        setMessage(" Beginning in 1 second.");
        setTime({ minutes: 0, seconds: 0 });
      } else if (time.minutes === 0) {
        const newSeconds = Math.max(0, time.seconds - 1);

        setMessage(`Beginning in ${newSeconds} seconds.`);
        setTime({ minutes: 0, seconds: newSeconds });
      } else if (time.minutes !== 0 && time.seconds === 0) {
        setMessage(` Beginning in ${time.minutes} minutes.`);
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      } else {
        const minuteText = time.minutes === 1 ? "m" : "m";
        setMessage(
          ` Beginning in ${time.minutes} ${minuteText} ${time.seconds} seconds.`
        );
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [time, timer]);
  useEffect(() => {
    if (startAudio && videoRef.current) {
      setTimeout(() => {
        videoRef.current.play().catch((error) => {
          console.error("Autoplay failed:", error);
        });
      }, 100); // Delay for 100 milliseconds
    }
  }, [startAudio]);
  
  return (
    <>
      <div className="d-flex flex-column border p-2 pb-5">
        <p>
          Status :{" "}
          {popUpType == "default"
            ? "Completed"
            : message}
        </p>
        <video ref={videoRef} className="exam-video mb-3" width="500" >
          <source
            src={question_content_video}
            type="video/mp4"
            className="exam-img"
          />
        </video>
          <p className="w-100 text-secondary exam-vol">
            <span className="w-25">Volume: </span>
            <input
              className="w-75"
              type="range"
              value={count}
              onChange={(e) => handleVolumeChange(e.target.value)}
              max="100"
              step="1"
              id="volume-control"
            />
          </p>
        <div className="progress-bar-container mt-2">
          {/* <progress
            className="progress-bar w-100"
            value={currentTime}
            max="100"
          ></progress> */}
          <ProgressBar
            className="progress-ui"
            now={currentTime}
            max="100"
            variant="hisuccess"
          />
        </div>
      </div>
    </>
  );
}

export default Video;
