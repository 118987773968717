import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
function ResultSampleAnswerBooleanString({fib_wrQuest}) {

  return (
    <>
      <div className='p-3 text-middark d-flex flex-column align-items-start gap-3 bg-light-soft rounded'>
        <b>Answer : </b>
        {/* MCS-R, MCM-R */}
      
{/* 
        <p>{
        
        
        fib_wrQuest?.map((item)=>{
          return item?.question_options?.map((ele,index)=>{
            return ele?.answer && index+1 + ", "})
            
            })}</p> */}

            
        <p>{
        
        
         fib_wrQuest?.answer?.split(",")?.map((ans,index)=>{
          return ans === 'true' && index+1 + ", "
            
            })}</p>


         
        {/* Describe Image, Answer Short Question, Reading Re-Order */}
        {/* <p> The following picture gives information about the number of US households keeping pets. From the bar chart, we can see the value of cats is forty-two point seven million. The value of dogs is higher, which is sixty-three million. From the bar chart, we can see the highest value is for total, which is eighty-four point nine million. The lowest value is for other, which is five point four million. In the bar chart there are also other items, including freshwater fish, birds, horses and saltwater fish. In conclusion, this bar chart is very informative.</p> */}

        {/* for  */}
        {/* <div className='p-3'>
          <audio src="/images/audio.wav" controls ></audio>
        </div> */}
      </div>
    </>
  );
}
export default ResultSampleAnswerBooleanString;