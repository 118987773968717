import { useContext, useEffect, useState } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminApi from "../../api/admin";
import {
  Icon,
  LinkList,
  LinkListItem,
  MediaGroup,
  MediaText,
} from "../../components";
import Block from "../../components/Block/Block";
import Context from "../../context";
import ResultManagementDataTable from "./ResultManagementDataTable";
import ResultManagementApi from "../../api/auth/resultManagement/resultManagement";
function ResultsFilter() {
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);

  const [data, setData] = useState([]);
  const [slectedTab, setSlectedTab] = useState()


  function formatOnlDate(dateString) {
    const date = new Date(dateString);
    console.log('date.getDate() :>> ', date.getDate(), `${("0" + date.getDate()).slice(-2)}`);

    // Format date
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;



    return `${formattedDate}`;
  }


  const StudentColumns = [
    {
      name: "Name/userName",
      grow: "4",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row?.users_details[0]?.name ? row?.users_details[0]?.name : "-"}
        </span>
      ),
      sortable: true,
    },

    // {
    //   name: "Test Name",
    //   grow: "3",
    //   selector: (row) => row.mobile,
    //   cell: (row) => (
    //     <span className="text-middark">{row?.papers[0]?.name?row?.papers[0]?.name:"-"}</span>
    //   ),

    // },
    // {
    //   name: "Exam Date",
    //   grow: "3",
    //   selector: (row) => row.product,
    //   cell: (row) => (
    //     <span className="text-middark">{formatOnlDate(row?.createdAt)?formatOnlDate(row?.createdAt):"-"}</span>
    //   ),
    //   sortable: true,
    // },

    // {
    //   name: "Total Score",
    //   grow: "3",
    //   selector: (row) => row.teacher,
    //   cell: (row) => (
    //     <span className="text-middark">{row?.users_details[0]?.stu_profile?.assignTeacher}</span>
    //   ),
    //   sortable: true,
    // },



    // {
    //   name: "Result Status",
    //   selector: (row) => row.status,
    //   cell: (row) => (
    //     <span className={`badge text-bg-${row?.status ? "success" : "danger"}`}>
    //       {row?.status ? "Done" : "Pending"}
    //     </span>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`/student-management/student-detail/${row._id}`}>
              <Icon name="eye"></Icon>
            </LinkListItem>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  let resultApi = new ResultManagementApi();

  console.log(data, "data111");
  useEffect(() => {
    console.log("USEEFFE");
    get_student_by_inst();
  }, [slectedTab]);

  const get_student_by_inst = async () => {
    console.log("ressss", userData?._id);

    await resultApi
      .get_students_results_by_inst({ inst_id: userData?._id, test_type: slectedTab == undefined ? "isMock" : slectedTab })
      .then((res) => {
        console.log(res, "get_students_results_by_instres");
        if (res?.data?.length > 0) {
          setData(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log('slectedTab :>> ', slectedTab);
  return (
    <Card>
      <Card.Body>
        <Tab.Container id="batch-tabs" defaultActiveKey="mock-test">
          <Nav variant="tabs" className="nav-tabs-s1 mb-3 mt-3 row">
            <Nav.Item className="col-md-4">
              <Nav.Link onClick={() => setSlectedTab("isMock")} eventKey="mock-test">Mock Test</Nav.Link>
            </Nav.Item>
            <Nav.Item className="col-md-4">
              <Nav.Link onClick={() => setSlectedTab("isSection")} eventKey="section-test">Section Wise Test</Nav.Link>
            </Nav.Item>
            <Nav.Item className="col-md-4">
              <Nav.Link onClick={() => setSlectedTab("isQuestion")} eventKey="question-test">Single Question Test</Nav.Link>
            </Nav.Item>

          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="mock-test">
              <Block>
                <Row className="g-gs">
                  <Col md="12">
                      {userData?.login_type == "1" && (
                          <ResultManagementDataTable
                            pagination={true}
                            tableClassName="data-table-head-light table-responsive student-list"
                            data={data}
                            columns={StudentColumns}
                          />
                      )}
                  </Col>
                </Row>
              </Block>
            </Tab.Pane>
            <Tab.Pane eventKey="section-test">
              <Block>
                <Row className="g-gs">
                  <Col md="12">
                      {userData?.login_type == "1" && (
                          <ResultManagementDataTable
                            pagination={true}
                            tableClassName="data-table-head-light table-responsive student-list"
                            data={data}
                            columns={StudentColumns}
                          />
                      )}
                  </Col>
                </Row>
              </Block>
            </Tab.Pane>
            <Tab.Pane eventKey="question-test">
              <Block>
                <Row className="g-gs">
                  <Col md="12">
                      {userData?.login_type == "1" && (
                          <ResultManagementDataTable
                            pagination={true}
                            tableClassName="data-table-head-light table-responsive student-list"
                            data={data}
                            columns={StudentColumns}
                          />
                      )}
                  </Col>
                </Row>
              </Block>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
}

export default ResultsFilter;
