import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import Question_Context from "../../../../context/questionContext";
import { useState, useContext, useEffect } from "react";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteListeningSummarizeSpokenTest() {
  let { id } = useParams();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [loader, setloader] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "listening_ssts",
    instructions:"You will hear a short report. Write a summary for a fellow student who was not present. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.",
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "file") {
      setData({ ...data, ["file"]: e.target.files[0] });
    } else {
      if (name == "question_type") {
        const options = e.target.options;
        const selectedValues = [];
        if (options.length == 0) {
          setSelectedOptions([]);
        }
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            selectedValues.push(options[i].value);
          }
          setSelectedOptions(selectedValues);
        }
        setData({
          ...data,
          isMock: selectedValues.includes("isMock") ? true : false,
          isPractice: selectedValues.includes("isPractice") ? true : false,
          isSection: selectedValues.includes("isSection") ? true : false,
          isQuestion: selectedValues.includes("isQuestion") ? true : false,
          isPrediction: selectedValues.includes("isPrediction") ? true : false,
        });
      } else {
        setData({ ...data, [name]: value });
      }
    }
  };
  let api = new AdminApi();
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let isAudio = false;
    if (!data.question_content_audio) {
      if (data.file?.length > 0) {
        const fileType = data.file[0].type;
        if (fileType.startsWith("audio/")) {
          isAudio = true;
          formObject.append("question_content_audio", data.file[0]);
        } else {
          Swal.fire({
            icon: "error",
            title: "File must be an audio ",
            text: "Please upload an audio file.",
          });
          return;
        }
      } else {
        if(!data?.question_content_audio && !data.question_content_video ){
          Swal.fire({
            icon: "error",
            title: "File is required",
            text: "Please upload an audio file.",
          });
          return
      }
    }
    }
    for (const [key, value] of Object.entries(data)) {
      if (key !== "file") {
        if(value!=null){
          formObject.append(key, value);
        }
      }
    }
    setloader(!loader)
    await api
      .add_Question_Listening(formObject)
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
          ;
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
            // text: "Please upload an audio or video file.",
          });
        }
      })
      .catch((err) => {
        setloader(!loader)
        console.log("err", err);
      });
  };

  console.log("-", data);
  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      file,
      question,
      answer_time,
      question_transcript,
      question_begin_time,
    } = data;
    return (
      instructions &&
      (file
        ? file
        : data.question_content_audio || data.question_content_video) &&
      tags &&
      question_transcript &&
      // question_type &&
      (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      answer_time &&
      question_begin_time.length ===5
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);

  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Listening Summarize Spoken Test Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => { setSelectedOptions(e) }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e);
                      setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                   
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="instruction"
                          className="ms-3 fw-bold"
                        >
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">
                          Add Audio
                        </Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_audio ||
                            data.question_content_video ? (
                            <>
                              <div className="d-flex gap-2">
                                {data.question_content_audio && (
                                  <audio
                                    src={data?.question_content_audio}
                                    controls
                                  />
                                )}
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_video: "",
                                        question_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <TranscriptExplanation transcript={true} setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                    </Col>
                    <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        value={data.tags}
                        placeholder="#Tag"
                        name="tags"
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                  </Row>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid}/>
               
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteListeningSummarizeSpokenTest;
