import React, { useState } from 'react'
import ExternalStudentModalContext from './ExternalStudentModalContext'

const ExternalStudentModalContextProvider = ({children}) => {
    const [loginModal,setLoginModal] =useState(true)
 
  return (
    <ExternalStudentModalContext.Provider value={{loginModal,setLoginModal}}>

      {children}
    </ExternalStudentModalContext.Provider>
  )
}

export default ExternalStudentModalContextProvider
