import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "../../pages/error/NotFound";
import StudentHomePage from "../../pages/studentpages/StudentHomePage";
import StudentLoginPage from "../../pages/studentpages/StudentLoginPage";
import StudentPassResetPage from "../../pages/studentpages/StudentPassResetPage";
import StudentRegisterPage from "../../pages/studentpages/StudentRegisterPage";
import { useEffect } from "react";
import ProtectedRoute from "../protectedRoute";
import AuthConfirmPassword from "../../pages/auths/AuthChangePassword";
import StudentPassForgotPage from "../../pages/studentpages/StudentPassForgotPage";
import ExternalStudentModalContextProvider from "../../context/ExternalStudentModalContext/ExternalStudentModalContextProvider";
  
  function ExternalStudentRoutes() {

    console.log("external routes");
    // let navigate = useNavigate();

    
    return (
        <>
        <ExternalStudentModalContextProvider>
          <Routes>
            <Route path="auths">
              <Route path="register-new-student" element={<StudentRegisterPage />} />
              <Route path="student-pass-reset" element={<StudentPassResetPage />} />
              <Route path="forget-password/:_id/:otp" element={<StudentPassForgotPage />} />
    
              <Route path="student-login" element={<StudentLoginPage />} />
              
            </Route>
              <Route path="/" element={<ProtectedRoute><StudentHomePage /></ProtectedRoute>} />
          
            <Route path="*" element={<NotFound />} />
          </Routes>
          </ExternalStudentModalContextProvider>
        </>
      );
  }
  
  export default ExternalStudentRoutes;