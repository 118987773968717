import { useEffect, useState } from "react";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import AuthApi from "../../../api/auth";
import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";
import { Icon, Select } from "../../../components";
import InstituteDataTable from "../TeamDataTable";
import Swal from "sweetalert2/src/sweetalert2.js";
// import InstituteData, { InstituteColumns } from './InstituteData';
import { Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { KanbanBasic } from "../../../components/Kanban/Kanban";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../../components";
import AdminApi from "../../../api/admin";

function PlanPricingList() {
  let navigate = useNavigate();
  let api = new AdminApi();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    get_plan();
  }, []);

  const get_plan = async () => {
    await api
      .get_plan_admin()
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const inactive_plan = async (e, id) => {
    let { name, checked } = e.target;
    var active = null;
    if (checked) {
      active = true;
    } else {
      active = false;
    }
    await api.inactive_plan({ active: active, id: id }).then((res) => {
      try {
        if (res.status) {
          get_plan();
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const deletePlan = async (id) => {
    console.log("err", id)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.value) {
        api.delete_plan(id).then((res) => {
          if (res.status) {
            get_plan()
          }
        }).catch((err) => {
          console.log("err", err)
        })
        }
    })
    
  }
  const kanbanBasicData = [
    {
      id: "item-1",
      label: [
        <div className="d-flex justify-content-evenly">
          <span>PLAN NAME</span>
          <span>PLAN AMOUNT</span>
          <span>VALIDITY</span>
          <span>MOCK TEST</span>
          <span>SECTION TEST</span>
          <span>STUDENT ACCOUNT VALIDITY</span>
          <span>ACTION</span>
        </div>,
      ],
      items: data.map((item) => ({
        id: item._id,
        content: (
          <div className="d-flex justify-content-evenly">
            <span>{item.plan_name}</span>
            <span>{item.plan_amount}</span>
            <span>{item.validity} Year</span>
            <span>{item.mock_test_cost}</span>
            <span>{item.section_test_cost}</span>
            <span>{item.student_account_validity} Days</span>
            <LinkList className="link-list-hover-bg-primary link-list-md d-flex gap-5">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  value={item.active}
                  checked={item.active}
                  onChange={(e) => inactive_plan(e, item._id)}
                  id="directMessage"
                  name="active"
                />
              </div>
              <div className="d-flex gap-1">
                <LinkListItem to={`/edit-plan-pricing/${item._id}`}>
                  <Icon name="edit"></Icon>
                </LinkListItem>
                <div className="del-btn" onClick={() => deletePlan(item._id)}>
                  <Icon name="trash"></Icon>
                </div>
              </div>
            </LinkList>
          </div>
        ),
      })),
    },
  ];

  var orderData1;
  const saveOrder = async () => {
    console.log("orderData1",orderData1)
    if (orderData1?.length > 0) {
      await api
        .saveOrder({ plan_id: orderData1 })
        .then((res) => {
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: res.message,
            });

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // useEffect(()=>{

  // },[grabItem])
  const grabItem = (e) => {
    console.log("::")
    if(e.length > 0 ){
      orderData1 = (e[0].items?.map((item) => item.id));
    }
  };
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Plan & Pricing</h1>
      <Block>

        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <div className="list-kanban">
                  <div className="d-flex justify-content-end my-3">
                    <Button className="d-none d-md-inline-flex" variant="outline-primary"
                      onClick={() => { navigate("/add-plan-pricing"); }} >
                      <Icon name="plus" />
                      <p>Add Plan & Pricing</p>
                    </Button>
                  </div>
                  <KanbanBasic data={kanbanBasicData} sendItemns={grabItem} />
                  <div className="d-flex justify-content-end">
                    <Button onClick={saveOrder} variant="primary">Save Order</Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="12">
            <Card>
              <Card.Body>
                <InstituteDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  columns={InstituteColumns}
                />
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default PlanPricingList;
