import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function AnswerCheckboxComp() {

  return (
    <div className='p-3 answer-checkbox'>
      <Form.Check type="checkbox" id="checkbox-A-1" label="Since many in the country can  speak and understand English, the language in important for us." />

      <Form.Check className='right-ans' checked type="checkbox" id="checkbox-B-1" label="The literary important of English cannot be underestimated." />

      <Form.Check className='right-ans' checked type="checkbox" id="checkbox-C-1" label="Language plays an important role in research and Development " />

      <Form.Check className='wrong-ans' checked type="checkbox" id="checkbox-D-1" label="English has today become the language of the words. " />
    </div>
  );
}
export default AnswerCheckboxComp;