import React, { useState, useContext, useEffect } from "react";
import Context from "../context";
import { Carousel } from 'react-bootstrap';

export default function ImageSlider({studentpage}) {
   const { domainData, globalLoader } = useContext(Context);

   console.log(domainData,"domainData")
   const imageSlider = studentpage? JSON?.parse(domainData)?.inst_profile?.internal_banner_images: JSON?.parse(domainData)?.inst_profile?.banner_images;

   const [sliderImages, setSliderImages] = useState([
      {
         url: "/images/login-tree.svg",
      },
      {
         url: "/images/login-tree.svg",
      },
      {
         url: "/images/login-tree.svg",
      },
   ]);

   useEffect(() => {
      if (imageSlider ) {
         setSliderImages(imageSlider);
      }
   }, [domainData]);

   return (
      <div>{globalLoader ?
         <div className="spinner-border" role="status">
            <span className="sr-only"></span>
         </div> :
         <Carousel
            bgColor="#00000000"
            width={500}
            height={200}
            showBullets={true}
            autoPlay={true}
            images={sliderImages}
            autoPlayDelay={3000} 
            onStartSlide={(index, length) => {
               // You can use index to track the current slide
            }}
           // Auto play delay in milliseconds
         >
            {sliderImages.map((item, index) => (
               <Carousel.Item key={index}>
                  <img className="d-block slider-img" style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}} src={item.url} alt={`Slide ${index}`} />
               </Carousel.Item>
            ))}
         </Carousel>}
      </div>
   );
}
