import { useContext, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2/src/sweetalert2.js";
import AnnoucementApi from "../../api/auth/annoucement";

import FileUpload from "../../components/Form/FileUpload";
import Context from "../../context";

const UpdateAnnoucementModal = ({
  singleAnnoucement,

  get_all_annoucement_list,

  setSingleAnnoucement,
  setAnnouncementIdPayload,
}) => {
  console.log(singleAnnoucement?.image != "" || singleAnnoucement?.image != null, "singleAnnoucement",singleAnnoucement?.image != "" ,"aaaaa",singleAnnoucement?.image != null);

  let api = new AnnoucementApi();

  let formdata = new FormData();

  const [state, setState] = useState({
    data: {
      id: singleAnnoucement?._id ? singleAnnoucement?._id : "",
      title: singleAnnoucement?.title ? singleAnnoucement?.title : "",
      priority: singleAnnoucement?.priority ? singleAnnoucement?.priority : "",
      image:singleAnnoucement?.image,
      redirectUrl:singleAnnoucement?.redirectUrl
    },
    errors: {},
  });

  const handleClose = () => {
    setSingleAnnoucement({});
    setAnnouncementIdPayload("");

    // setOpenAnnountmentEditModal(false)
  };
  const { data, errors } = state;
  console.log("state=", state);
  let {  userData} = useContext(Context);
  userData = JSON.parse(userData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [e.target.name]: e.target.value,
      },
    }));
  };

  console.log('data434 :>> ', data);
  for (const [key, value] of Object.entries(data)) {
    console.log(key, value, "key,value,");
    formdata.append([key], value);
  }
  formdata.append("institue_id",userData?._id)

  console.log(data?.attachment?.preview, "data11121232");
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.title);
    if (!data?.title) {
      newErrors.title = "Title required";
    }

    setState((prev) => ({
      ...prev,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");

      await api
        .create_announcement(formdata)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            // setShow(false);
            get_all_annoucement_list();
            // navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };

  console.log(singleAnnoucement, "errors1213");

  const updateBannerImages = (updatedFiles) => {
    console.log("updatedFiles :>> ", updatedFiles);

    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        image:"",
        attachment: updatedFiles,
      },
    }));
  };
  // });

  return (
    <>
      <Modal
        size="lg"
        show={Object.keys(singleAnnoucement).length > 0}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Annoucement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="12">
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="title">Title</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      required
                      type="text"
                      id="title"
                      placeholder="Title"
                      name="title"
                      value={data?.title}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="priority">Priority</Form.Label>
                  <div className="form-control-wrap">
                  <Form.Select
                      id="priority"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="priority"
                      value={data?.priority}
                      isInvalid={!!errors?.priority}
                    >
                        {/* <Form.Control
                          type="number"
                          name="priority"
                          id="priority"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter a Priority here..."
                          isInvalid={!!error?.newErrors?.priority}
                        /> */}

                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="2">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.priority}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
             
                    <Form.Group className="form-group my-3">
                      <Form.Label htmlFor="redirectUrl">Redirect URL</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="redirectUrl"
                          name="redirectUrl"
                          value={data?.redirectUrl}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter a Redirect Url"
                        />
                      </div>
                    </Form.Group>
             

                <div className="d-flex justify-content-start gap-3">
                  {data?.attachment?.preview ? (
                    <div
                      className="p-2 border d-flex align-items-center justify-content-center rounded announcement-img"
                      style={{ width: "160px", height: "160px" }}
                    >
                      <Image src={data?.attachment?.preview} className="rounded" ></Image>
                    </div>
                  ) : (
                    <div
                      className={`${
                        singleAnnoucement?.image != ""  &&
                        "p-2 border d-flex align-items-center justify-content-center rounded",

                        singleAnnoucement?.image != null  &&
                        "p-2 border d-flex align-items-center justify-content-center rounded"
                      }`}
                      // style={{ width: "160px", height: "160px" }}
                      style={{
                        width: `${
                          singleAnnoucement?.image == "" || singleAnnoucement?.image == null  ? "0px" : "160px"
                        }`,
                        height: `${
                          singleAnnoucement?.image == "" || singleAnnoucement?.image == null  ? "0px" : "160px"
                        }`,
                      }}
                    >
                      <Image src={singleAnnoucement?.image} className="rounded"></Image>
                    </div>
                  )}

                  <div className="d-flex- flex-column align-items-center justify-content-center announcement-upload">
                    <div className="form-control-wrap">
                      <FileUpload
                        noImage={true}
                        filetype="image"
                        onChange={(uploadDocData) =>
                          updateBannerImages(uploadDocData?.[0])
                        }
                        // setState((prev) => ({
                        //   ...prev,
                        //   data: {
                        //     ...prev.data,
                        //     attachment:uploadDocData[0],
                        //   },
                        // }))}

                        iconName="upload"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Edit Announcement
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateAnnoucementModal;
