import React, { useState } from "react";
import { Image } from "../../../components";
import { Form, Col } from "react-bootstrap";

function EditTimesFiled({ data, onDataChange }) {
  const [A, setAnsFirstTime] = useState(false);
  const handleTimeChange = (e, name) => {
    let value = e.target.value;

    if (parseInt(value, 10) < 60 && !value.includes(":")) {
      console.log("parseInt(value, 10)", parseInt(value, 10));
      if (A) {
        value = value;
        setAnsFirstTime(!A);
      } else {
        if (value.length == 2) {
          setAnsFirstTime(!A);
        }

        value = value.length == 2 ? value + ":" : value;
      }

      onDataChange(name, value);
    } else {
      if (!value.split(":")[1]) {
        if (value == ":") {
          onDataChange(name, "00:");
        } else {
          if (value.includes(":") && value.length == 2) {
            onDataChange(name, "0" + value);
          } else {
            if (value.includes(":") && value.length == 3) {
              onDataChange(name, value);
            } else {
              onDataChange(name, "");
            }
          }
        }
      } else {
        if (value.split(":")[1].length < 3 && value.split(":")[0].length < 3) {
          onDataChange(
            name,
            value.split(":")[1] < 60 && value.split(":")[0] < 60 ? value : ""
          );
        }
      }
    }
  };

  return (
    <>
      {(data.module_name == "l_mcm" ||
        data.module_name == "l_mcs" ||
        data.module == "listening" ||
        (data.module === "speaking" &&
          data.module_name === "repeat_sentences") ||
        data.module_name === "answer_questions" ||
        data.module_name === "retell_lectures") && (
        <Col lg="12">
          <Form.Group className="form-group">
            <Form.Label className="ms-3 fw-bold">
              Question Beginning Time
            </Form.Label>
            <div className="form-control-wrap">
              <div className="form-control-icon end">
                <Image src="/images/stopwatch.svg"></Image>
              </div>
              <Form.Control
                name="question_begin_time"
                id="ques_beg"
                value={data?.question_begin_time}
                onChange={(e) => handleTimeChange(e, "question_begin_time")}
                type="text"
                placeholder="00:00"
              />
            </div>
          </Form.Group>
        </Col>
      )}
      {data.module === "speaking" && (
        <Col lg="12">
          <Form.Group className="form-group">
            <Form.Label className="ms-3 fw-bold">
              Answer Preparation Time
            </Form.Label>
            <div className="form-control-wrap">
              <div className="form-control-icon end">
                <Image src="/images/stopwatch.svg"></Image>
              </div>
              <Form.Control
                type="text"
                id="ans_pep"
                name="answer_prepare_time"
                value={data?.answer_prepare_time}
                onChange={(e) => handleTimeChange(e, "answer_prepare_time")}
                placeholder="00:00"
              />
            </div>
          </Form.Group>
        </Col>
      )}
      {(data.module != "listening" ||
        (data.module == "listening" && data.module_name == "ssts")) && (
        <Col lg="12">
          <Form.Group className="form-group">
            <Form.Label className="ms-3 fw-bold">Answer Time</Form.Label>
            <div className="form-control-wrap">
              <div className="form-control-icon end">
                <Image src="/images/stopwatch.svg"></Image>
              </div>
              <Form.Control
                type="text"
                id="ans_time"
                value={data?.answer_time}
                name="answer_time"
                onChange={(e) => handleTimeChange(e, "answer_time")}
                placeholder="00:00"
              />
            </div>
          </Form.Group>
        </Col>
      )}
    </>
  );
}

export default EditTimesFiled;
