export let colors =[
    "#ffffff",
    "#ffffcc",
    "#fff0f5",
    "#f5f5fd",
    "#e0ffff",
    "#ffe5b4",
    "#e0feea",
    "#fafad2",
    "#ffe4e1",
    "#f0fff0",
    "#f0ffff",
    "#fff5ee",
    "#f0f8ff",
    "#f5fffa",
    "#f0e68c",
    "#f5deb3",
    "#ffdab9",
    "#fffacd",
    "#ffe4b5",
    "#faf0e6",
    "#fff8dc",
    "#f0fff4",
    "#f0ffff",
    "#faf0ff",
    "#f0f0ff",
    "#f0e0ff",
    "#f0d0ff",
    "#f0c0ff",
    "#f0b0ff",
    "#f0a0ff",
    "#f090ff",
   
  ]
  