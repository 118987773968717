import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import DateRangePickerName from "../../components/Form/DateRangePickerName";
import { DropDown } from "../../components/Form/DropDown";

function FollowupFilter({
  teamMemberList,
  followupData,
  tableData,
  resetData,
  setTabledata,
  showItemPerPage,
  setShowPage,
}) {
  let DropDownWidth = { width: "140px" };
  console.log("FilterFollowup", followupData);
  console.log(teamMemberList, "teachers11");
  const [examList, setExamList] = useState([]);
  console.log(examList, "examList");
  const examData = [
    ...new Set(
      examList
        .filter((item) => item?.name != undefined)
        .map((item) => item?.name?.toLowerCase())
    ),
  ];
  console.log(examData, "examData");

  const Status = [
    {
      value: "Done",
      id: true,
    },
    {
      value: "Pending",
      id: false,
    },
  ];
  const enquiryTypeArr = [
    "PTE",
    "PTE CORE",
    "IELTS",
    "CD IELTS",
    "TOEFL",
    "CALE",
    "CELPIP",
    "GRAMMER",
    "SPOKEN ENLGISH",
  ];


  const statusType = [
    "WARM",
    "COLD",
    "HOT",
    "NO RESPONSE",
    "INTERESTED",
    "NOT INTERESTED",
    "IN PROCESS",
    "ENROLLED",
  ];
  const [filterDataObj, setFilterDatObj] = useState({});
  console.log("------------", filterDataObj);
  const handleDateChange = (e) => {
    let { value, name } = e.target;
    console.log("name##RR", value, name);
    setFilterDatObj({ ...filterDataObj, [name]: value });
  };

  let {
    startDate,
    endDate,
  
    name,
    status,
    mobile,
    email,
    enquiryType,
    endEnquiryDate,
    startEnquiryDate,
    endLastFollowDate,
    startLastFollowDate

  } = filterDataObj;

  const filterData = async () => {
    const filteredData = followupData?.filter((item) => {



      const itemDate = new Date(item?.nextFollowUpDate);
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);
      const startOfDay = new Date(startDate);
     


      const itemEnquiryDate = new Date(item?.createdAt);
      const endOfEnquiryDay = new Date(endEnquiryDate);
      endOfEnquiryDay.setHours(23, 59, 59, 999);
      const startOfEnquiryDay = new Date(startEnquiryDate);



      
      const itemLastFollowDate = new Date(item?.prev_follow_up_date);
      const endOfLastFollowDay = new Date(endLastFollowDate);
      endOfLastFollowDay.setHours(23, 59, 59, 999);
      const startOfLastFollowDay = new Date(startLastFollowDate);

      // console.log(itemDate,
      //   !startDate || itemDate >= startOfDay,
      //   "itemDateFollow",
      //   !endDate || itemDate <= endOfDay
      // );

      return (

        (!startDate || itemDate >= startOfDay) &&
        (!endDate || itemDate <= endOfDay) &&


        (!startEnquiryDate || itemEnquiryDate >= startOfEnquiryDay) &&
        (!endEnquiryDate || itemEnquiryDate <= endOfEnquiryDay) &&


        
        (!startLastFollowDate || itemLastFollowDate >= startOfLastFollowDay) &&
        (!endLastFollowDate || itemLastFollowDate <= endOfLastFollowDay) &&

        (!status ||
          (item?.status != null &&
            item?.status.toLowerCase() === status?.toLowerCase())) &&

            (!enquiryType ||
              (item?.program != null &&
                item?.program.toLowerCase() === enquiryType?.toLowerCase())) &&
        (!name ||
          (item?.name != null &&
            item?.name.toLowerCase().includes(name?.toLowerCase().trim()))) &&
        (!email ||
          (item?.email != null &&
            item?.email.toLowerCase().includes(email?.toLowerCase().trim()))) &&
        (!mobile || item?.phone?.includes(mobile.trim()))
      );
    });
    setTabledata(filteredData);
    console.log("filteredDatafollow", filteredData);
  };

  // let api = new AuthApi();
  // let adminApi = new AdminApi();
  // const [staffList, setStaffList] = useState([]);
  // useEffect(() => {
  //   staff_List();
  // }, []);
  // const staff_List = async () => {
  //   await api
  //     .staff_List()
  //     .then((res) => {
  //       if (res.data.length > 0) {
  //         setStaffList(res.data.reverse());
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // useEffect(() => {
  //   get_all_exam();
  // }, []);
  // const get_all_exam = async () => {
  //   await adminApi
  //     .get_all_exam()
  //     .then((res) => {
  //       if (res?.data?.length > 0) {
  //         setExamList(res?.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  console.log("allDataFollow", followupData);
  return (
    <>
      <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2 ">
            <select
              className="form-select"
              onChange={(e) => setShowPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>


        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Enquiry Date</Form.Label>
          <DateRangePickerName
            name="date"
            startDate={startEnquiryDate}
            endDate={endEnquiryDate}
            onChange={handleDateChange}
            inputGrpname1={"startEnquiryDate"}
            inputGrpname2={"endEnquiryDate"}
          />
        </Form.Group>





        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Last Followup Date</Form.Label>
          <DateRangePickerName
            name="date"
            startDate={startLastFollowDate}
            endDate={endLastFollowDate}
            onChange={handleDateChange}
            inputGrpname1={"startLastFollowDate"}
            inputGrpname2={"endLastFollowDate"}
          />
        </Form.Group>

        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Student Name</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="filter"
              placeholder="Search By Name"
              value={filterDataObj?.name}
              name="name"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Next Followup Date</Form.Label>
          <DateRangePickerName
            name="date"
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            inputGrpname1={"startDate"}
            inputGrpname2={"endDate"}
          />
        </Form.Group>
   
        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Phone Number</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="mobilefilter"
              placeholder="Search By Phone Number"
              value={filterDataObj?.mobile}
              name="mobile"
              onChange={handleDateChange}
              type="number"
            />
          </div>
        </Form.Group>
        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Email</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="email"
              placeholder="Search By Email"
              value={filterDataObj?.email}
              name="email"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        <DropDown
          className="w-25 mb-3"
          data={statusType}
          defaultSelectedOption={"All"}
          text="Select Status"
          name="status"
          onChange={handleDateChange}
          width={DropDownWidth}
          value={filterDataObj?.status}
        />

        <DropDown
          className="w-25 mb-3"
          data={enquiryTypeArr}
          defaultSelectedOption={"All"}
          text="Enquiry Type"
          name="enquiryType"
          onChange={handleDateChange}
          width={DropDownWidth}
          value={filterDataObj?.enquiryType}
        />

       

      

        {/* <DropDown
          className="w-25 mb-3"
          data={enquiryTypeArr}
          defaultSelectedOption={"All"}
          text="Select Counselor"
          name="enquiryType"
          onChange={handleDateChange}
          width={DropDownWidth}
          value={filterDataObj?.enquiryType}
        /> */}

        {/* <DropDown
          className="w-25 mb-3"
          data={enquiryTypeArr}
          defaultSelectedOption={"All"}
          text="Select Category"
          name="enquiryType"
          onChange={handleDateChange}
          width={DropDownWidth}
          value={filterDataObj?.enquiryType}
        /> */}

        <Form.Group className="form-group d-flex w-20 ms-2  align-items-end">
          <div className="form-control-wrap d-flex me-2 ">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObj({
                  ...filterDataObj,

                  name: "",

                  mobile: "",

                  email: "",
                  startDate:"",
                  endDate:"",
                  startEnquiryDate:"",
                  endEnquiryDate:"",
                  status:"",
                  enquiryType:"",
                  startLastFollowDate:"",
                  endLastFollowDate:""
                });
                setTabledata(followupData);
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" className="theme-colored" onClick={filterData}>
              Search
            </Button>
          </div>
        </Form.Group>
      </div>
    </>
  );
}
export default FollowupFilter;
