import React, { useState } from "react";
import { ProgressBar, Spinner, Button, Modal } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import Timer from "../Timer";
import Audio from "../customAudio/audio";
import ProgressBarCustom from "../CustomProgressbar";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { S3upload } from "../S3upload";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import Video from "../Video";
import AudioUI from "../Istructions/ExamComponents/AudioUI";
import RecorderUI from "../Istructions/ExamComponents/RecorderUI";
function ReTellLectureImage({ data }) {
  var {
    instructions,
    question_content_video,
    question_content_audio,
    question_content_image,
  } = data;
  let {
    popUpType,
    setPopUpType,
    setAnswerJson,
    answerJson,
    setManageRecordings,
    manageRecordings,
  } = useContext(ModalContext);
  const [startAudio, setStartAudio] = useState(false);

  const [demoModal, setDemoModal] = useState(false);
  const demoModalClose = () => setDemoModal(false);
  const demoModalShow = () => setDemoModal(true);

  const timeUpdate = (e) => {
    // console.log("eee", e);
  };
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();
  const handleStopRecording = async () => {
    stopRecording();
    // S3upload(
    //   recordingBlob,
    //   `answers/${answerJson?.exam_id}/${answerJson?.qst_id}/${data?.module_label
    //     .split(" ")
    //     .join("_")}`
    // )
    //   .then((url) => {
    //     setAnswerJson({ ...answerJson, audio_url: url });
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5>
        <em className="pte-test-instructions-bold">{data.instructions}</em>
        </h5>
        <div className="d-flex justify-content-around align-items-center gap-5 my-5">
          {data?.question_content_video && (
            <Video className="exam-video"
            timer={data.question_begin_time}
              data={data}
              startRecording={() => {
                setStartAudio(true);
                // startRec();
              }}
            />
          )}
          {!data?.question_content_video &&
            data?.question_content_image &&
            data?.question_content_image !== "null" && (
              <img
                src={data?.question_content_image}
                className="exam-img"
                alt="no img"
              ></img>
            )}
          <div className="d-flex flex-column align-items-center">
            {!question_content_video && (
              <AudioUI
                timer={data.question_begin_time}
                audio={data.question_content_audio}
                startRecording={() => {
                  setStartAudio(true);
                  // startRec();
                }}
              />
            )}
            <RecorderUI
              startAudio={startAudio}
              data={data}
              stopRecordings={handleStopRecording}
              initialTime={data.answer_time}
              timer={data?.answer_prepare_time}
              startRecording={() => {
                setStartAudio(true);
                // startRec();
              }}
              NoRecording={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReTellLectureImage;
