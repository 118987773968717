import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthApi from "../../api/auth/index";
import Layout from "../../layout/fullpage";
import Swal from "sweetalert2/src/sweetalert2.js";
import Context from "../../context";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
} from "../../components";
import ImageSlider from "../ImageSlider";
import "../../assets/scss/_variables.scss"
import { RECAPCHA_SITEKEY } from '../../config';

const AuthLoginPage = () => {
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);

  let name = window.location.hostname.split(".")[0];

  let localHostAndAdmin = name == "adm1n";

  let { domainData, setUserData, localHost, setToken } = useContext(Context);
  let inst_id = JSON.parse(domainData)?._id;
  let domainEmail = JSON.parse(domainData)?.email;
  let domain = JSON.parse(domainData)?.inst_profile;
  let navigate = useNavigate();
  console.log(domainEmail,"domain3213")

  const [data, setdata] = useState({
    login_type:
      localHostAndAdmin || (name == "localhost" && localHost == "adm1n")
        ? "0"
        : "1",
    inst_id: inst_id,
  });
  let api = new AuthApi();


 

  

  const handleChange = (e) => {
    let { name, value } = e.target;
   

   
    setdata({ ...data, [name]: value });

 
   
  };


  console.log(data,'fdsafdataaaaaaa')
  const containsUnderscore = /_/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localHost) {
      await api
        .login({
          ...data,
          login_type:
            localHostAndAdmin || (name == "localhost" && localHost == "adm1n")
              ? "0"
              : domainEmail == data.email
                ? "1"
                : "2",
          inst_id: inst_id,

          username: containsUnderscore.test(data.email) && domainEmail !== data.email && name !== "adm1n"
              ? data.email
              : undefined,
        })
        .then((res) => {
          console.log("ress", res);
          if (res.status) {
            setUserData(JSON.stringify(res.data[0]));
            localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
            setToken(res.token)
            localStorage.setItem("userHilingotoken", JSON.stringify(res.token));
            // localStorage.setItem("userHilingoisLogin", JSON.stringify(res.isLogin));
            navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "you don't have permission to login",
      });
    }
  };

  useEffect(() => {
    // if(!domain?.color_code){
    //   return
    // }
    const root = document.documentElement;
    console.log("root", root);

    if (domain?.color_code) {
      document.documentElement.style.setProperty('--theme-color', domain?.color_code);
    }
    else{
      document.documentElement.style.setProperty('--theme-color',"#1682FB");
    }
    // Access the value of $accent-color from the compiled CSS
    // Use the accentColor variable as needed
    // root.style.setProperty("--accent-color", domain?.color_code, "important");
  }, [domain?.color_code]);




  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
              <Col lg="6">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <div className="login-logo">
                        <Logo />
                      </div>
                      {localHostAndAdmin ||
                        (name == "localhost" && localHost == "adm1n") ? (
                        <h1 className="nk-block-title mb-1">
                          Login to your Admin Account
                        </h1>
                      ) : (
                        <h1 className="nk-block-title mb-1">
                          Login to your Institute Account
                        </h1>
                      )}
                    </div>
                  </div>
                  <Form action="#" onSubmit={handleSubmit}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              name="email"
                              onChange={(e) => handleChange(e)}
                              id="email"
                              placeholder="Email id"
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              type="password"
                              name="password"
                              onChange={(e) => handleChange(e)}
                              id="password"
                              placeholder="Password"
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          <Form.Check
                            className="form-check-sm base-light"
                            type="checkbox"
                            id="rememberMe"
                            label="Remember Me"
                          />
                          <div style={{ marginTop: "40px" }} className="hide-captcha">     <ReCAPTCHA

                            // get sitekey from env
                            sitekey={RECAPCHA_SITEKEY}
                            // sitekey= "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            onChange={() => {
                              setRecaptchaVerified(true);
                            }} />
                          </div>
                          <Link to="/auths/auth-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <Col className="col-7 mx-auto">
                        <div className="d-grid">
                        <Button
    type="submit"
  
    disabled={!isRecaptchaVerified}
>
    Login to account
</Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>

                  {!domain && (
                    <div className="text-center my-4">
                      <p className="small">
                        Don't have an account?{" "}
                        <Link to="/auths/student-register">Register</Link>
                      </p>
                    </div>
                  )}

                  {/* <div className="my-3 text-center">
                                    <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                </div> */}
                  {!domain && (
                    <Row className="g-2">
                      <Col className="col-7 mx-auto">
                        <Button
                          href="#auth"
                          variant="outline-light"
                          className="w-100 btn-primary justify-content-start p-0"
                        >
                          <Image
                            src="/images/icon/d.png"
                            alt=""
                            className="icon icon-bg"
                          />
                          <span className="fw-medium ms-4 text-white">
                            Continue with Google
                          </span>
                        </Button>
                      </Col>
                      <Col className="col-7 mx-auto">
                        <Button
                          href="#auth"
                          variant="outline-light"
                          className="w-100 btn-primary justify-content-start p-0"
                        >
                          <Image
                            src="/images/icon/b.png"
                            alt=""
                            className="icon icon-bg"
                          />
                          <span className="fw-medium ms-4 text-white">
                            Continue with Facebook
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <div className="d-flex mt-5 justify-content-between">
                    <div className="d-flex align-items-center">
                      <p className="text-dark">Follow Us on : </p>
                      <div className="d-flex gap-2 ms-3">
                        <a href={`${domain?.inst_business_facebook}`} target="_blank"><img src="/images/facebook.svg" style={{ width: "30px" }}></img></a>
                        <a href={`${domain?.inst_business_instagram}`} target="_blank"><img src="/images/instagram.svg" style={{ width: "30px" }}></img></a>
                        {/* I have added linkedin of institute as a youtube link  */}
                        <a href={`${domain?.inst_business_linkedin}`} target="_blank"><img src="/images/youtube.svg" style={{ width: "30px" }}></img></a>
                      </div>
                    </div>
                    <p className="text-dark">Helpline No. : {domain?.inst_business_helpline_number} </p>
                  </div>
                </Card.Body>
              </Col>
              <Col lg="6">
                <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                  <ImageSlider className="login-slider"></ImageSlider>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default AuthLoginPage;
