import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Nav,
  ProgressBar,
} from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";
import Select from "../../../../components/Form/Select";
import PracticePteComments from "../PracticePteComments";
import InputRadio from "../../../exam/PTE/inputComponents/inputRadio";

function PracticePteReadingMultiChoiceRadio({ data,showAnwer }) {
  return (
    <div>
      {/* <p className="practice-pte-question-newline" style={{marginBottom:"1rem"}}>{(data.question_content!="null" && data.question_content ) ?data.question_content :(data.question!="null" && data.question ) && data.question }</p> */}
      <p className="practice-pte-question-newline" style={{marginBottom:"1rem"}}>{data?.question_content ? data?.question_content : data?.question}</p>
      {(window.location.pathname?.includes("r_mcs")) && <p>{data?.question}</p>}
     <InputRadio showAnwer={showAnwer} question={data?.question}data={data.question_options} />
    </div>
  );
}

export default PracticePteReadingMultiChoiceRadio;
