import { Card, Form, Row, Col, Button, ButtonGroup, InputGroup, Badge } from 'react-bootstrap';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Icon } from '../../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import Context from '../../context';
import FormValidationPage from '../forms/FormValidation';
import AuthApi from '../../api/auth';
import Swal from "sweetalert2/src/sweetalert2";
function StudentChangePassword() {
    let {id} = useParams();
    let {userData} = useContext(Context)
    let api = new AuthApi(); 
    userData = JSON.parse(userData)
    let navigate = useNavigate();
const[state,setState] = useState({
    view_password:{
        current:false,
        new:false,
        confirm:false
    },
    data:{
        _id:id,
        oldPassword:"",
        newPassword:"",
        confirm:""
    },
    samePassword:false
})
let {view_password,data,samePassword} = state;
const [passwordMismatch, setpasswordMismatch] = useState(false);
const [validated, setValidated] = useState(false);
const [passLength, setPasslength] = useState(false);
const [invalidPassword, setinvalidPassword] = useState(false);

const handleSubmit = async (event) => {
    event.preventDefault();
  const form = event.currentTarget;

  if(!data?.confirm || !data?.newPassword || !data?.oldPassword ){
    setValidated(true);
    return
}

  if(data?.confirm != data?.newPassword){
    setpasswordMismatch(true)
    return
}
  if(data?.confirm == data?.newPassword ){
    if(data?.oldPassword == data?.newPassword){
    setState({...state,samePassword:true})
    setValidated(false)
    return
    }
    if( data?.newPassword.length < 8 || data?.confirm.length < 8 ){
        setPasslength(true);
        return
    }
    
}
  if (form.checkValidity()) {
    event.stopPropagation();
    await api.change_password({...data}).then((res)=>{
        console.log("res",res)
        if(res.status){
            navigate(-1)
            Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res.msg,
              });
            
        }else{
            setpasswordMismatch(false)
            setValidated(false)
            setPasslength(false);
            Swal.fire({
                icon: "error",
                title: "Oops",
                title: "Oops",
                text: res.msg,
              });
              if(res.msg == "Invalid password"){
              setinvalidPassword(true)}
        }
    }).catch((err)=>console.log(err))
  }

};

async function handleSubmitForAdminChangePassword(event){
    event.preventDefault();
    const form = event.currentTarget;
  
    if(!data?.confirm || !data?.newPassword){
      setValidated(true);
      return
  }
  
    if(data?.confirm != data?.newPassword){
      setpasswordMismatch(true)
      return
  }
    if(data?.confirm == data?.newPassword ){
    //   if(data?.oldPassword == data?.newPassword){
    //   setState({...state,samePassword:true})
    //   setValidated(false)
    //   return
    //   }
      if( data?.newPassword.length < 8 || data?.confirm.length < 8 ){
          setPasslength(true);
          return
      }
      
  }
    if (form.checkValidity()) {
      event.stopPropagation();
      await api.changeStudentPasswordByAdmin(data).then((res)=>{
          console.log("res",res)
          if(res.status){
              navigate(-1)
              Swal.fire({
                  icon: "success",
                  title: "Good job!",
                  text: res.msg,
                });
              
          }else{
              setpasswordMismatch(false)
              setValidated(false)
              setPasslength(false);
              Swal.fire({
                  icon: "error",
                  title: "Oops",
                  title: "Oops",
                  text: res.msg,
                });
                if(res.msg == "Invalid password"){
                setinvalidPassword(true)}
          }
      }).catch((err)=>console.log(err))
    }
}

const handleChange = (e) =>{
    setpasswordMismatch(false)
    setValidated(false)
    setPasslength(false);
    setinvalidPassword(false);
    let {name,value}= e.target;
    setState({...state,data:{...data,[name]:value}})
}

console.log("data",state)
    return (
        <Layout title="Add Student" content="container">
            <h1 className='mb-5'>Change Password</h1>
            <Block>
                <Card className='h-100'>
                    <Card.Body>
                    <Form noValidate validated={validated} onSubmit={userData?.login_type=="0" ? handleSubmitForAdminChangePassword : handleSubmit }>
                        <Row className="g-gs">
                            <Col md="8">
                                {(userData?.login_type=="1" || userData?.login_type=="2") && <Form.Group className="form-group">
                                    <Form.Label htmlFor="curpass">Current Password</Form.Label>
                                    <div className="form-control-wrap">
                                        <div className="form-control-icon end text-middark" onClick={()=>setState({...state,view_password:{...view_password,current:!view_password.current}})} ><Icon name="eye" className="me-4" ></Icon></div>
                                        <Form.Control onChange={(e)=>handleChange(e)} name='oldPassword' required type={!view_password.current?"password":"text"} id="curpass" placeholder="Current Password" />
                                        <Form.Control.Feedback type="invalid">This is required.</Form.Control.Feedback>
                                        {invalidPassword &&   <div className='text-danger'>Invalid password .</div>}
                                        

                                          </div>
                                </Form.Group>}
                                <Form.Group className="form-group mt-3">
                                    <Form.Label htmlFor="curpass">New Password (Min 8 Characters)</Form.Label>
                                    <div className="form-control-wrap">
                                        <div className="form-control-icon end text-middark" onClick={()=>setState({...state,view_password:{...view_password,new:!view_password.new}})} ><Icon name="eye" className="me-4"></Icon></div>
                                        <Form.Control onChange={(e)=>handleChange(e)} name='newPassword' required type={!view_password.new?"password":"text"} id="newpass" placeholder="New Password" />
                                        <Form.Control.Feedback type="invalid">This is required.</Form.Control.Feedback>
                                        {samePassword &&   <div className='text-danger'>Cannot update same passowrd .</div>}
                                    </div>
                                </Form.Group>
                                <Form.Group className="form-group mt-3">
                                    <Form.Label htmlFor="curpass">Confirm Password</Form.Label>
                                    <div className="form-control-wrap">
                                        <div className="form-control-icon end text-middark" onClick={()=>setState({...state,view_password:{...view_password,confirm:!view_password.confirm}})}><Icon name="eye" className="me-4"></Icon></div>
                                        <Form.Control onChange={(e)=>handleChange(e)}  name='confirm' required type={!view_password.confirm?"password":"text"} id="conpass" placeholder="Confirm Password" />
                                        <Form.Control.Feedback type="invalid">This is required.</Form.Control.Feedback>
                                     {passwordMismatch &&   <div className='text-danger'>New Password and confirm password does not match .</div>}
                                     {passLength &&   <div className='text-danger'>Password should be atleast 8 character .</div>}

                                    </div>
                                </Form.Group>
                                {}
                                <div className='d-flex justify-content-between mt-3'>
                                    <Button variant='primary' onClick={()=>navigate(-1)}> Back</Button>
                                    <Button variant='primary' type="submit"> Submit</Button>
                                </div>
                            </Col>
                            
                        </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Block>
        </Layout>
    )
}

export default StudentChangePassword;