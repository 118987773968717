import { Placeholder, ProgressBar, Spinner, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ExamHeader from '../../../../layout/exam/Header';
import ExamFooter from '../../../../layout/exam/Footer';
import { Icon } from '../../../../components';
import { Height } from '@mui/icons-material';
import Timer from '../Timer';
import Audio from "../customAudio/audio";
import ProgressBarCustom from "../CustomProgressbar";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { S3upload } from "../S3upload";
import React, { useContext, useEffect, useState } from "react";
import Video from '../Video';
import AudioUI from '../Istructions/ExamComponents/AudioUI';
import RecorderUI from '../Istructions/ExamComponents/RecorderUI';
import ModalContext from '../../../../context/ModalContext';
function RepeatSenetence({data,popUpType}) {
  let {manageRecordings} = useContext(ModalContext)
  var { instructions, question_content_video, question_content_audio, } = data;

  const [startAudio, setStartAudio] = useState(false);

  const [demoModal, setDemoModal] = useState(false);
  const demoModalClose = () => setDemoModal(false);
  const demoModalShow = () => setDemoModal(true);

  const timeUpdate = (e) => {
    console.log("eee", e);
  };

  useEffect(() => {
    setStartAudio(false)
  }, [data]);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();
  const handleStopRecording = async () => {
    stopRecording();
    // S3upload(
    //   recordingBlob,
    //   `answers/${answerJson?.exam_id}/${answerJson?.qst_id}/${data?.module_label
    //     .split(" ")
    //     .join("_")}`
    // )
    //   .then((url) => {
    //     setAnswerJson({ ...answerJson, audio_url: url });
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });

    console.log("manageRecordings",manageRecordings)
  };
    return (
        <>
               <div className="container my-5 text-examheadtext">
        <h5>
        <em className="pte-test-instructions-bold">{data.instructions}</em>
        </h5>
        <div className="d-flex justify-content-center align-items-center gap-4 my-5">
          {data?.question_content_video && (
            <Video
              data={data}
              startRecording={() => {
                setStartAudio(true);
                // startRec();
              }}
            />
          )}
          {!data?.question_content_video &&
            data?.question_content_image &&
            data?.question_content_image !== "null" && (
              <img
                src={data?.question_content_image}
                className="exam-img"
                alt="no img"
              ></img>
            )}
          <div className="d-flex flex-column align-items-center">
            {!question_content_video && (
              <AudioUI
              data={data}
                timer={data.question_begin_time}
                audio={data.question_content_audio}
                startRecording={() => {
                  setStartAudio(true);
                  // startRec();
                }}
              />
            )}
            <RecorderUI
              startAudio={startAudio}
              data={data}
              stopRecordings={handleStopRecording}
              initialTime={data.answer_time}
              timer={data?.answer_prepare_time}
              startRecording={() => {
                setStartAudio(true);
                // startRec();
              }}
              NoRecording={false}
            />
          </div>
        </div>
      </div>
        </>
    )
}

export default RepeatSenetence;