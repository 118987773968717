import {
  Card,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import InstituteDataTable from "../admin/institutepages/InstituteDataTable";
import Layout from "../../layout/default";
import { useContext, useEffect, useState } from "react";
import AuthApi from "../../api/auth";
import ModalContext from "../../context/ModalContext";
import Context from "../../context";
import { LinkList, LinkListItem, Icon } from "../../components";
import AnnoucementApi from "../../api/auth/annoucement";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import DataTable from "react-data-table-component";
import UpdataAnnoucementModal from "./UpdateAnnoucementModal";
import UpdateAnnoucementModal from "./UpdateAnnoucementModal";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import Spinner from "react-bootstrap/Spinner";

function ViewAnnouncement() {

  const [announcementData, setAnnouncementData] = useState([]);
  let {  userData} = useContext(Context);
  userData = JSON.parse(userData);

  const [refresh, setRefresh] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  // const itemsPerPage = 10; // Assuming 10 items per page

  const [showItemPerPage, setShowItemPerPage] = useState(10);



  const [selectedIndex, setSelectedIndex] = useState()

  const handleChange = (e, type, index, id) => {
    console.log("inside handler", index);



    // if (type != undefined && type == "check") {
    //   console.log("checkedddddddd :>> ");


    //   setAnnouncementData(prev => 
    //     prev.map((o, i) => 
    //     i === index
    //       ? { ...o, [e.target.name]: e.target.checked }
    //       : o
    //       // console.log('o,i,index, :>> ', o,i,index)
    //     )
    //   )
    //   setSelectedIndex(index)
    // --------------------


    if (type != undefined && type == "check") {
      console.log("checkedddddddd :>> ");


      setAnnouncementData(prev =>
        prev.map((o, i) =>
          o?._id === id
            ? { ...o, [e.target.name]: e.target.checked }
            : o
          // console.log('o,i,index, :>> ', o,i,index)
        )
      )
      setSelectedIndex(id)

    }
  };

  let annoucementApi = new AnnoucementApi();
  let formData = new FormData()
  useEffect(() => {
    async function callApi() {
      const result = announcementData?.filter((item, index) => item?._id == selectedIndex)
      // const result=  announcementData?.filter((item,index)=>index==selectedIndex)

      console.log('result :>> ', result?.[0]?.isActive, result?.[0]?._id, selectedIndex);

      formData.append("id", selectedIndex)
      formData.append("isActive", result?.[0]?.isActive)


      // if(result?.[0]?.isActive!== undefined){
      await annoucementApi
        .create_announcement(formData)
        .then((res) => {

          if (res?.status) {
            Swal.fire({
              icon: "success",
              text: res.message,
            });
            // get_all_annoucement_list()
            navigate("/view-announcement");
            setRefresh(true)
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: res.msg,
            });
          }
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
      // }


    }
    console.log('selectedIndex', selectedIndex);
    callApi()

  }, [announcementData])


  console.log('announcementData :>> ', announcementData);
  const AnnouncementColumns = [
    {
      name: "SR No.",
      // cell: (row, index) => <span className="text-middark">{index + 1}</span>,
      cell: (row, index) => (currentPage - 1) * showItemPerPage + index + 1,
      sortable: false,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <span className="text-middark">{row?.title}</span>,
      sortable: true,
    },
    {
      name: "Priority",
      selector: (row) => row?.priority,
      cell: (row) => <span className="text-middark">{row?.priority}</span>,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row, index) => (
        <div className="text-end w-100">
          {/* <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
                        <LinkListItem>
                            <Icon name="edit"></Icon>
                        </LinkListItem>
                        <LinkListItem>
                            <Icon name="trash"></Icon>
                        </LinkListItem>
                    </LinkList> */}

          <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="view_annoucement">View Annoucement</Tooltip>}
            >
              <div className="del-btn" onClick={() => editHandler(row?._id)}>
                <Icon name="eye"></Icon>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="view_annoucement">Delete</Tooltip>}
            >
              <div className="del-btn" onClick={() => deleteHandler(row?._id)}>
                {/* <Icon name="trash"></Icon> */}
                <Icon name="trash"></Icon>
              </div>
            </OverlayTrigger>

            {/* <span className="text-middark ms-2">{row?.isActive?"Active":"InActive"}</span> */}


            {/* <div className="mb-3">
        <Form.Check type="switch" id="flexSwitchChecked" label={row?.isActive ? "Active" : "Deactivated"} defaultChecked
        name='isActive'
         onChange={(e) => handleChange(e, "check",index)}
         checked={row?.isActive ? true : false}
   
        />
        </div> */}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="view_annoucement">Active / Inactive</Tooltip>}
            >
              <div className="ms-2">
                <Form.Check type="switch" id="flexSwitchChecked"
                  name='isActive'
                  //  onChange={(e) => handleChange(e, "check",index,row?._id)}
                  onChange={(e) => handleChange(e, "check", (currentPage - 1) * showItemPerPage + index, row?._id)}
                  checked={row?.isActive ? true : false}

                />
              </div>
            </OverlayTrigger>




          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];

  // const [announcementData, setAnnouncementData] = useState([]);

  let api = new AnnoucementApi();
  let navigate = useNavigate();

  const [announcementIdPayload, setAnnouncementIdPayload] = useState("");
  // const[openAnnountmentEditModal,setOpenAnnountmentEditModal]=useState(false)
  const [deleteId, setDeleteId] = useState("");

  const [singleAnnoucement, setSingleAnnoucement] = useState({});

  const [loader, setLoader] = useState(false);
  const get_all_annoucement_list = async () => {
    setLoader(true);
    await api
      .get_announcement(userData?._id)
      .then((res) => {
        if (res.status) {
          setAnnouncementData(res?.data);
          setTotalRows(res?.data?.length)

          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    get_all_annoucement_list();
  }, [refresh]);

  const editHandler = (id) => {
    console.log(id, "abcEdit");

    setAnnouncementIdPayload(id);
    // setOpenAnnountmentEditModal(true)
  };

  const deleteHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        setDeleteId(id);
      }
    })

  };

  const get_Single_annocement = async () => {
    await api
      .get_Single_annoucement(announcementIdPayload)
      .then((res) => {
        if (res?.data?.length > 0) {
          setSingleAnnoucement(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (announcementIdPayload) {
      get_Single_annocement();
    }
  }, [announcementIdPayload]);

  const delete_Single_annoucement = async (id) => {
    await api
      .delete_annoucement(id)
      .then((res) => {
        if (res?.msg) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: `${res?.msg}`,
          });
          get_all_annoucement_list();

          navigate("/view-announcement");

          // }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (deleteId) {
      delete_Single_annoucement(deleteId);
    }
  }, [deleteId]);

  console.log("loader :>> ", loader);

  return (
    <Layout title="Add Student" content="container">
      <div className="d-flex justify-content-between">
        <h1 className="mb-5">Announcement Details</h1>

      </div>
      {Object.keys(singleAnnoucement)?.length > 0 && (
        <UpdateAnnoucementModal
          // openAnnountmentEditModal={openAnnountmentEditModal}
          // setOpenAnnountmentEditModal={(data)=>setOpenAnnountmentEditModal(data)}
          singleAnnoucement={singleAnnoucement}
          setSingleAnnoucement={(data) => setSingleAnnoucement(data)}
          get_all_annoucement_list={() => get_all_annoucement_list()}
          setAnnouncementIdPayload={(data) => setAnnouncementIdPayload(data)}
          announcementIdPayload={announcementIdPayload}
        />
      )}

      <div className="data-table-wrapper">
        <Col md="12">
          <Card>
            <Card.Body className="">
              {/* <InstituteDataTable tableClassName="data-table-head-light table-responsive" data={announcementData} columns={AnnouncementColumns}></InstituteDataTable> */}

              <ul className="d-flex justify-content-end">
                <li>
                  <Button
                    className="d-none d-md-inline-flex btn btn-primary add-btn"
                    variant="primary"
                    onClick={() => navigate("/add-announcement")}
                  >
                    <Icon name="plus" /> <span> Add Announcement</span>
                  </Button>
                </li>
              </ul>

              <div className="d-flex m-3 ">
                <div className="data-table-select">
                  <select
                    className="form-select"
                    onChange={(e) => setShowItemPerPage(e.target.value)}
                    value={showItemPerPage}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>
                  <span className="text-secondary mx-1">Show</span>
                </div>
              </div>
              <div>
                {/* <Spinner animation="border" variant="secondary" /> */}

                {/* or */}
                {/* <Button className="fw-bold" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </Button> */}

                {loader && announcementData?.length > 0 ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  <DataTable
                    columns={AnnouncementColumns}
                    data={announcementData}
                    noDataComponent={
                      <div className="p-2">There are no records found.</div>
                    }
                    sortIcon={<div className="data-table-sorter"></div>}
                    pagination={true}

                    paginationTotalRows={totalRows}
                    onChangePage={setCurrentPage}

                    paginationComponent={({
                      rowsPerPage,
                      rowCount,
                      onChangePage,
                      onChangeRowsPerPage,
                      currentPage,
                    }) => (
                      <div className="data-table-bottom">
                        <DataTablePagination
                          showItemPerPage={showItemPerPage}
                          itemPerPage={rowsPerPage}
                          totalItems={rowCount}
                          paginate={onChangePage}
                          currentPage={currentPage}
                          onChangeRowsPerPage={onChangeRowsPerPage}
                          setShowItemPerPage={setShowItemPerPage}
                        />
                      </div>
                    )}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Layout>
  );
}

export default ViewAnnouncement;
