import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Icon,
    Select,
    Block,
    QuillMinimal,
    FileUpload,
    Tags,
    ImageUpload,
    TimePicker,
  } from "../../../components";
import { Button } from "react-bootstrap";
function PrevNextButton({ id }) {
  let navigate = useNavigate();
  return (
    <div>
      {id ? (
        <button
        onClick={(e)=>{e.preventDefault();navigate(-1)}}
          className="btn btn-outline-primary fw-bold"
        >
          <Icon name="arrow-left" className="me-2"></Icon> Previous
        </button>
      ) : (
        <Link
          to="../../../add-questions"
          className="btn btn-outline-primary fw-bold"
        >
          <Icon name="arrow-left" className="me-2"></Icon> Previous
        </Link>
      )}
    </div>
  );
}

export default PrevNextButton;
