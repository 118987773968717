import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import ReactPaginate from "react-paginate";

function PracticeDetails() {

  return (
    <Layout title="Analytics" content="container">
      <Block>
        <Row>
          <Col md="12" lg="12">
            <h1 className="mb-5">Practice Details</h1>
            <Card className="mb-3">
              <Card.Body className="d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <div className="d-flex gap-3 align-items-center text-secondary">
                    <img src="/images/practice-icons/read_alouds.svg" style={{ width: "80px" }}></img>
                    <div>
                      <h5>Read Aloud</h5>
                      <p>Date : 2024-03-26</p>
                    </div>
                  </div>
                  <div className="d-flex gap-4">
                    <div className="d-flex flex-column align-items-center text-secondary">
                      <p>Prac. Qs</p>
                      <h5>1</h5>
                    </div>
                    <div className="d-flex flex-column align-items-center text-secondary">
                      <p>Ans. Count</p>
                      <h5>1</h5>
                    </div>
                  </div>
                </div>
                <div className="border rounded text-middark">
                  <div className="d-flex flex-column py-2">
                    <div className="d-flex fw-bold text-secondary justify-content-between align-items-center border-bottom mb-2 pb-2 px-3">
                      <p className="mb-0">Practice Details</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <b> Questions</b>
                      <b>Ans. Count </b>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p> #131 Blue</p>
                      <p>1</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p> #131 Blue</p>
                      <p>1</p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="d-flex justify-content-between position-relative bottom-0 align-items-center react-pagination m-5">
              <ReactPaginate breakLabel="..."
                nextLabel="next >"
                pageRangeDisplayed={5}
                pageCount="20"
                previousLabel="< previous"
                renderOnZeroPageCount={null} />
            </div>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}

export default PracticeDetails;