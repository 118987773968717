import React, { useState ,useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthApi from "../../api/auth/index";
import Layout from "../../layout/fullpage";
import { Colors} from '../../utilities/index';
import Context from "../../context";import Swal from 'sweetalert2/src/sweetalert2.js';
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
} from "../../components";
import ImageSlider from "../ImageSlider";

const InstituteLogin = () => {
  let navigate = useNavigate();
  const { domainData  } = useContext(Context);
  let domain = JSON.parse(domainData)?.inst_profile

  let [validated,setValidated] = useState(false)
  const [data, setdata] = useState({});
  let api = new AuthApi();
  const handleChange = (e) => {
    let { name, value } = e.target;
    setdata({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if(data.email && data.passsword){
    await api
      .login(data)
      .then((res) => {
        console.log("ress", res)
        if (res.status) {
          localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
          localStorage.setItem("userHilingotoken", JSON.stringify(res.token));
          window.location.reload();
          navigate("/home")
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  };
  // console.log(domain)
  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Row className="g-0 flex-lg-row-reverse">
            <Col lg="9" className="mx-auto">
              <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">

                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <Row className="g-0">
                    <Col lg="6">
                      <Image src="/images/login-sidebar.jpg" alt="side image" />
                    </Col>
                    <Col lg="6">
                      <div className="nk-block-head text-center">
                        <div className="nk-block-head-content">
                          <div className="login-logo">
                            <Logo />
                          </div>
                          <h1 className="nk-block-title mb-1">
                            Login to your Account
                          </h1>
                        </div>
                      </div>
                      <Form action="#" validated={validated} onSubmit={handleSubmit}>
                        <Row className="gy-3">
                          <Col className="col-12">
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="text"
                                  name="email"
                                  onChange={(e) => handleChange(e)}
                                  id="email"
                                  placeholder="Email id"
                                  required
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col className="col-12">
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="text"
                                  name="password"
                                  onChange={(e) => handleChange(e)}
                                  id="password"
                                  placeholder="Password"
                                  required
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col className="col-12">
                            <div className="d-flex flex-wrap justify-content-between">
                              <Form.Check
                                className="form-check-sm base-light"
                                type="checkbox"
                                id="rememberMe"
                                label="Remember Me"
                              />
                              
                              <Link to="/auths/auth-reset" className="small">
                                Forgot Password?
                              </Link>
                            </div>
                          </Col>
                          <Col className="col-7 mx-auto">
                            <div className="d-grid">
                              <Button type="submit" style={{ borderColor: "red", backgroundColor: domain?.color_code }} >Login to account</Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>

                      <div className="text-center my-4">
                        <p className="small">
                          Don't have an account?{" "}
                          <Link to="/auths/auth-register">Register</Link>
                        </p>
                      </div>

                      {/* <div className="my-3 text-center">
                                    <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                </div> */}
                      {/* <Row className="g-2">
                        <Col className="col-7 mx-auto">
                          <Button
                            href="#auth"
                            variant="outline-light"
                            className="w-100 btn-primary justify-content-start p-0"
                          >
                            <Image
                              src="/images/icon/d.png"
                              alt=""
                              className="icon icon-bg"
                            />
                            <span className="fw-medium ms-4 text-white">
                              Continue with Google
                            </span>
                          </Button>
                        </Col>
                        <Col className="col-7 mx-auto">
                          <Button
                            href="#auth"
                            variant="outline-light"
                            className="w-100 btn-primary justify-content-start p-0"
                          >
                            <Image
                              src="/images/icon/b.png"
                              alt=""
                              className="icon icon-bg"
                            />
                            <span className="fw-medium ms-4 text-white">
                              Continue with Facebook
                            </span>
                          </Button>
                        </Col>
                      </Row> */}
                    </Col>
                  </Row>
                </Card.Body>

              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default InstituteLogin;
