import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { Dropdown, Button, Offcanvas, Alert, Form } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { useNavigate } from "react-router-dom";
import parser from "html-react-parser"
import {
  Logo,
  Image,
  Icon,
  MediaAction,
  MediaGroup,
  MediaText,
  Media,
  LinkList,
  LinkListItem,
  CustomDropdownToggle,
  CustomDropdownMenu,
  Schedule,
  Select,
} from "../../../components";

import Menu from "./Menu";

import ToggleSidebar from "../Toggle/Sidebar";
import ToggleNavbar from "../Toggle/Navbar";

import { useLayout, useLayoutUpdate } from "./../LayoutProvider";
import Context from "../../../context";
import AuthApi from "../../../api/auth";

import "../../../assets/scss/core/utilites/customClass.css"
import Swal from "sweetalert2";
import OverrideContext from "../../../context/OverrideContext/OverrideContext";
import AdminApi from "../../../api/admin";


function QuickNav({ className, ...props }) {
  const compClass = classNames({
    "nk-quick-nav": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function QuickNavItem({ className, ...props }) {
  const compClass = classNames({
    "d-inline-flex": true,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function Header() {
  let { setUserData, userData, setbranchId, branchId, domainData, setAdminToStudent,token } = useContext(Context);
  console.log(domainData,"domainData?.inst_profile?.color_code")
  let api = new AuthApi();
  const [data, setData] = useState([]);
  userData = JSON.parse(userData);
  domainData = JSON.parse(domainData);
  // token = JSON.parse(token)
  let { inst_profile } = userData;
  let navigate = useNavigate();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const layout = useLayout();
  const layoutUpdate = useLayoutUpdate();

  const compClass = classNames({
    "nk-header customColor position-sticky top-0": true,
    [`is-${layout.headerVariant}`]: layout.headerVariant,
  });

  const navClass = classNames({
    "nk-header-menu nk-navbar": true,
    "navbar-active": layout.headerActive,
    // eslint-disable-next-line
    "navbar-mobile":
      layout.headerTransition ||
      eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
  });

  // offcanvas
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);

   const logout = async () => {
    setUserData(null);
    setAdminToStudent(false)
    localStorage.clear();
    getInstittuteDetailByname()


    // window.location.reload();
    navigate("/");
    api.logout({_id:userData._id, token:token}).then((response) => {
      if(response?.data?.error?.response === 401){
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Sessions Expired...",
        });
      }
    })
  };

  let name = window.location.hostname.split(".")[0];

  const getInstittuteDetailByname = async () => {
    console.log("inside getInstittuteDetailByname")
  
    await api
      .get_inst_public({ name })
      .then((res) => {
        console.log("ress2332@", res);
        if (res?.data?.length > 0) {

          localStorage.setItem(
            "instDomainHilingo",
            JSON.stringify(res.data[0])
          );
        
        } 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [notifications, setNotifications] = useState([])
  const get_notification_to_user_id = async () => {
    
    const res = await api.get_branch_by_inst_id((userData?.login_type == "2" && domainData?._id) || (userData?.teacher_profile?.inst_id || userData?._id));
    await api.get_notification_to_user_id(userData?._id).then((res) => {
      if (res.status) {
        const sortedByDate = res?.data?.sort((a,b)=>new Date(a.createdAt) - new Date(b.createdAt));
        setNotifications(res.data.reverse())
      }
    }).catch((err) => {
      console.log()
    })
  }
  const update_user_notification = async () => {
    let api = new AuthApi();
    const res = await api.get_branch_by_inst_id((userData?.login_type == "2" && domainData?._id) || (userData?.teacher_profile?.inst_id || userData?._id));
    await api.update_user_notification({ user_id: userData?._id }).then((res) => {
      if (res.status) {
        console.log("update_user_notification")
      }
    }).catch((err) => {
      console.log()
    })
  }
  const get_branch_by_inst_id = async () => {
    let api = new AuthApi();
    try {
      const res = await api.get_branch_by_inst_id((userData?.login_type == "2" && domainData?._id) || (userData?.teacher_profile?.inst_id || userData?._id));
      if (res.status) {
        setData(res.data.reverse());
      }
    } catch (err) {
      console.error(err);
    }
  };
  // Use useEffect properly with dependencies array
  useEffect(() => {
    get_notification_to_user_id();
    get_branch_by_inst_id();
  }, []);

  useEffect(() => {
    if (showOffcanvas) {
      update_user_notification();
      get_notification_to_user_id();
    }
  }, [showOffcanvas])

  useEffect(() => {
    if (data.length && !branchId) {
      setbranchId(data[0]?._id);
    }
  }, [data]);
  console.log("DATA --> ", data);
  let full_name = userData?.login_type == "0" ? "Admin" : userData?.login_type == "1" ? inst_profile?.inst_contact_firstname + " " + inst_profile?.inst_contact_lastname : userData?.name;
  if (userData?.login_type == "1") {
    full_name = userData?.login_type == "1" && (userData?.prefix ? inst_profile?.inst_contact_firstname + " " + inst_profile?.inst_contact_lastname : userData?.name)
  }
  console.log("Full_name", full_name)
  let accoun_Type = userData?.login_type == "0" ? "Admin" : userData?.login_type == "1" ? "Institute" : "Student"
  let institute_profile = userData?.login_type == "1";
  let student_profile = userData?.login_type == "2"

  useEffect(() => {

    document.getElementsByClassName("nk-header")[0].style.setProperty("background-color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");

    document.getElementsByClassName("nk-wrap")[0].style.setProperty("background-color", "#F6F6F6", "important");

    const btns = document.getElementsByClassName("btn btn-primary");

    if (userData?.login_type == "2") {
      for (var i = 4; i < btns.length; i++) {
        btns[i].style.setProperty("background-color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");
      }
    }
    else {
      for (var i = 3; i < btns.length; i++) {
        btns[i].style.setProperty("background-color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");
      }
    }

    const btns1 = document.getElementsByClassName("bg-primary");
    for (var i = 0; i < btns1.length; i++) {
      btns1[i].style.setProperty("background-color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");
    }

    //code removed to change colors of all, weeklyprediction and mar prediction
    // const roundedPill = document.getElementsByClassName("rounded-pill");
    // for(var i=0;i<roundedPill.length;i++){
    //   roundedPill[i].style.setProperty("color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");
    // }


    const addBtn = document.getElementsByClassName("add-btn");
    for (var i = 0; i < addBtn.length; i++) {
      addBtn[i].style.setProperty("background-color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");
    }


    if (domainData?.inst_profile?.color_code) {
      document.documentElement.style.setProperty('--theme-color', domainData?.inst_profile?.color_code);
    }
    else{
      document.documentElement.style.setProperty('--theme-color',"#1682FB");
    }



    const title = document.getElementsByClassName("title");
    console.log("TITLE", title);
    for (var i = 0; i < title.length; i++) {
      title[i].style.setProperty("color", userData?.inst_profile?.color_code || domainData?.inst_profile?.color_code, "important");
    }


  }, [data,domainData?.inst_profile?.color_code])

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format date
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;

    // Format time
    let hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} | ${formattedTime}`;
  }

  
  const {overrideData,setOverrideData}=useContext(OverrideContext)

    setOverrideData(JSON.parse(localStorage.getItem("isOverride")))
  console.log(overrideData,"overrideData313")

  const [institutePlan, setInstitutePlan] = useState({})
  useEffect(()=>{
    if(overrideData!=undefined)
    getInsitutePlan();
  },[overrideData])
    
  let adminApi = new AdminApi();
    const getInsitutePlan = async () => {
      await adminApi
        .get_active_plans_by_inst(userData?._id,overrideData)
        .then((res) => {
          if (res.status) {
            if(overrideData == 0){
  
              setInstitutePlan(res.data)
            }
            else{
              setInstitutePlan(res.data?.plan_data)
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };


  return (
    <>
      <div className={compClass} style={{ marginLeft: "1.8rem", marginRight: "0.75rem", marginTop: "1rem", borderRadius: "1rem" }}>
        <div className="container-fluid" > 
          <div className="nk-header-wrap justify-content-start justify-content-md-end">
            <div className="nk-header-logo">
              <ToggleSidebar variant="zoom" icon="menu" />
              <ToggleNavbar className="me-2" />
              <Logo />
            </div>
            {layout.headerActive && (
              <div
                className="navbar-overlay"
                onClick={layoutUpdate.headerMobile}
              ></div>
            )}
            <nav className={navClass}>
              <Menu />
            </nav>
            <div className={navClass}>
              <QuickNav className="gap-1 flex-column flex-sm-row align-items-start m-2">
                {(userData?.login_type == "1" || userData?.login_type == "0")&& institutePlan?.multi_branch_management  && (
                  <Form.Group className="form-group">
                    <div className="form-control-wrap">
                      <div className="form-control-icon start">
                        <Icon name="map-pin" variant="secondary"></Icon>
                      </div>
                      <Form.Select
                        id="exampleFormSelect1"
                        aria-label="Default select example"
                        value={branchId}
                        className="ps-5"
                        onChange={(e) => {
                          localStorage.setItem("@#branch_id", e.target.value);
                          setbranchId(e.target.value);
                        }}
                      >
                        {data.length > 0 &&
                          data.map((item) => {
                            return (
                              <>
                                <option value={item._id}>
                                  {item.name}, {item.location}
                                </option>
                              </>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </Form.Group>
                )}
                {console.log("MACDATA", data)}
                {
                  userData?.login_type == "2" && <Form.Group className="form-group">
                    <div className="form-control-wrap">




                      <Button variant="outline-primary-rev" className="top-btn">
                        <Icon name="map-pin" variant="secondary"></Icon><span className="pe2">{data[0]?.name + ", " + data[0]?.location}</span>
                      </Button>
                      {/* <Form.Select
                      id="exampleFormSelect1"
                      aria-label="Default select example"
                      className="ps-5"
                      disabled
                    >
                         <option >
                              {data[0]?.name + ", " + data[0]?.location}
                                {userData?.stu_profile?.branch_name}
                              </option>
                    </Form.Select> */}
                    </div>
                  </Form.Group>
                }

                {/* <Button className="top-btn">
               
                  <Icon name="gift" className="pe-2"></Icon>Offers Only For You
                </Button> */}
                <Button variant="outline-primary-rev" className="top-btn">
                  <Icon name="gift" className="pe-2"></Icon>Announcement
                </Button>
                <Button  variant="outline-primary-rev" className="top-btn">
                  <Icon name="help" className="pe-2"></Icon>Help
                </Button>

                <Dropdown className="d-none" as={QuickNavItem}>
                  <Dropdown.Toggle
                    variant="zoom"
                    size="sm"
                    bsPrefix
                    className="btn-icon d-sm-none"
                  >
                    <Icon name="search"></Icon>
                  </Dropdown.Toggle>
                  <Dropdown.Toggle
                    variant="zoom"
                    size="md"
                    bsPrefix
                    className="btn-icon d-none d-sm-inline-flex"
                  >
                    <Icon name="search"></Icon>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-lg">
                    <div className="dropdown-content dropdown-content-x-lg py-1">
                      <div className="search-inline">
                        <div className="form-control-wrap flex-grow-1">
                          <input
                            placeholder="Type Query"
                            type="text"
                            className="form-control-plaintext"
                          />
                        </div>
                        <Icon name="search" size="sm"></Icon>
                      </div>
                    </div>
                    <Dropdown.Divider className="m-0"></Dropdown.Divider>
                    <div className="dropdown-content dropdown-content-x-lg py-3">
                      <div className="dropdown-title pb-2">
                        <h5 className="title">Recent searches</h5>
                      </div>
                      <ul className="dropdown-list gap gy-2">
                        <li>
                          <MediaGroup>
                            <Media size="md" shape="circle" variant="light">
                              <Icon name="clock"></Icon>
                            </Media>
                            <MediaText>
                              <div className="lead-text">
                                Styled Doughnut Chart
                              </div>
                              <span className="sub-text">1 days ago</span>
                            </MediaText>
                            <MediaAction end>
                              <Button
                                size="md"
                                variant="zoom"
                                className="btn-icon me-n1"
                              >
                                <Icon name="trash"></Icon>
                              </Button>
                            </MediaAction>
                          </MediaGroup>
                        </li>
                        <li>
                          <MediaGroup>
                            <Media size="md" shape="circle" variant="light">
                              <Icon name="clock"></Icon>
                            </Media>
                            <MediaText>
                              <div className="lead-text">
                                Custom Select Input
                              </div>
                              <span className="sub-text">07 Aug</span>
                            </MediaText>
                            <MediaAction end>
                              <Button
                                size="md"
                                variant="zoom"
                                className="btn-icon me-n1"
                              >
                                <Icon name="trash"></Icon>
                              </Button>
                            </MediaAction>
                          </MediaGroup>
                        </li>
                        <li>
                          <MediaGroup>
                            <Media size="md" shape="circle" variant="light">
                              <Image src="/images/avatar/a.jpg" staticImage />
                            </Media>
                            <MediaText>
                              <div className="lead-text">Sharon Walker</div>
                              <span className="sub-text">Admin</span>
                            </MediaText>
                            <MediaAction end>
                              <Button
                                size="md"
                                variant="zoom"
                                className="btn-icon me-n1"
                              >
                                <Icon name="trash"></Icon>
                              </Button>
                            </MediaAction>
                          </MediaGroup>
                        </li>
                      </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <QuickNavItem>
                  <button
                    className="btn-icon btn btn-zoom btn-sm d-sm-none bg-primary rounded-circle"
                    onClick={handleOffcanvasShow}
                  >
                    <Icon name="bell"></Icon>
                  </button>
                  <button
                    className="btn-icon btn btn-zoom btn-md d-none d-sm-inline-flex rounded-circle" variant="light"
                    onClick={handleOffcanvasShow}
                  >
                    <Icon name="bell"></Icon>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ms-0"> {userData?.login_type == "1" || userData?.login_type == "0" ? 0 : notifications?.length > 0 && notifications.filter((ele) => ele?.viewed == false)?.length}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </button>
                </QuickNavItem>
                <Dropdown as={QuickNavItem}>
                  <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>
                    <div className="d-inline-flex d-sm-none">
                      <Media shape="circle" size="md">
                        <Image
                          src="/images/avatar/c.jpg"
                          staticImage
                          thumbnail
                          topimg
                        />
                      </Media>
                      <MediaText>
                        <div className="lead-text">{full_name}</div>
                        <span className="sub-text">{accoun_Type}</span>
                      </MediaText>
                    </div>
                    <div className="d-none d-sm-flex align-items-center">
                      <Media shape="circle" className="top-img mx-2 ">
                        {userData?.login_type == "0" && <div className="logo-wrap">
                          <p className="no-logo-small" >AD</p>
                        </div>}
                        {userData?.login_type == "1" && <div className="logo-wrap">
                          <p className="no-logo-small">{userData?.prefix && userData?.inst_profile?.inst_contact_firstname?.charAt(0)?.toUpperCase()}</p>
                        </div>}

                        {userData?.login_type == "2" && <div className="logo-wrap">
                          <p className="no-logo-small">{userData?.name?.charAt(0)?.toUpperCase()}</p>
                          {/* <img
                 alt="No logo"
                 className="logo-img"
               /> */}
                        </div>}
                      </Media>
                      <MediaText className="flex-row align-items-center text-white mx-2">
                        <span>{full_name}</span>
                        <Icon name="chevron-down"></Icon>
                      </MediaText>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu-md"
                    as={CustomDropdownMenu}
                  >
                    <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                      <MediaGroup>
                        <Media size="xl" shape="circle">
                          {userData?.login_type == "0" && <div className="logo-wrap">
                            <p className="no-logo-small">AD</p>
                            {/* <img
                 alt="No logo"
                 className="logo-img"
               /> */}
                          </div>}
                          {userData?.login_type == "1" && <div className="logo-wrap">
                            <p className="no-logo-small">{userData?.prefix && userData?.inst_profile?.inst_contact_firstname?.charAt(0)?.toUpperCase()}</p>
                          </div>}
                          {userData?.login_type == "2" && <div className="logo-wrap">
                            <p className="no-logo-small">{userData?.name?.charAt(0)?.toUpperCase()}</p>
                            {/* <img
                 alt="No logo"
                 className="logo-img"
               /> */}
                          </div>}
                        </Media>
                        <MediaText>
                          <div className="lead-text">{full_name}</div>
                          <span className="sub-text">{accoun_Type}</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                    <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                      <LinkList>
                        {institute_profile && <LinkListItem to="/institute/profile">
                          <Icon name="user"></Icon>
                          <span>My Profile</span>
                        </LinkListItem>}
                        {student_profile && <LinkListItem to="/student/profile">
                          <Icon name="user"></Icon>
                          <span>My Profile</span>
                        </LinkListItem>}
                        {/* <LinkListItem to="/institute/profile">
                          <Icon name="contact"></Icon>
                          <span>My Contacts</span>
                        </LinkListItem>
                        <LinkListItem to="/admin/profile-settings">
                          <Icon name="setting-alt"></Icon>
                          <span>Account Settings</span>
                        </LinkListItem> */}
                      </LinkList>
                    </div>
                    <div className="dropdown-content dropdown-content-x-lg py-3">
                      <LinkList>
                        <span
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                        >
                          <Icon
                            name="signout"
                          // style={{ fontSize: "32px" }}
                          ></Icon>
                          <span onClick={logout} className="ms-3">
                            Log Out
                          </span>
                        </span>
                      </LinkList>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </QuickNav>
            </div>
          </div>
        </div>
      </div>
      {console.log(notifications, notifications)}
      <Offcanvas
        className="offcanvas-size-md off-notification"
        placement="end"
        show={showOffcanvas}
        onHide={handleOffcanvasClose}
      >
        <Offcanvas.Header closeButton className="border-bottom border-light">
          <Offcanvas.Title>Recent Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SimpleBar>
            <Schedule>
              {notifications.length > 0 ? notifications.map((item) => {
                // return(
                //   <> 
                //   <Schedule.Item symbol="active">
                //     <span className="smaller text-secondary">{formatDate(item.createdAt)}</span>
                //     <div className="h6">{item.title}</div>
                //     <ul className="d-flex flex-wrap gap g-2 pt-2">
                //       <li>
                //         <Media className="notification-img">
                //           <Image
                //             src={item?.img}
                //             alt="gallery"
                //             thumbnail
                //           />
                //         </Media>
                //       </li>
                //     </ul>
                //   </Schedule.Item>
                //   </>
                // )

                // tochan
                return (
                  <>
                    <Schedule.Item symbol="active">
                      <span className="smaller text-secondary">{formatDate(item.createdAt)}</span>
                      <div className="h1">{item?.notifications_id?.title}</div>
                      <h3 className="text-secondary mb-5">{item?.notifications_id?.sub_title}</h3>
                      <p className="text-secondary">{item?.notifications_id?.description && parser(item?.notifications_id?.description)}</p>
                      <div className="d-flex flex-wrap gap g-2 pt-2">
                        {item?.notifications_id?.img &&
                          <Media className="notification-img">
                            <Image
                              src={item?.notifications_id?.img}
                              alt="gallery"
                            />
                          </Media>
                        }
                      </div>
                      {
                        item?.notifications_id?.button_name &&
                        <div className="d-flex justify-content-center">
                          <Button className="primary mt-4" style={{textTransform:"capitalize"}} onClick={() => window.open(item?.notifications_id?.button_link, '_blank')}>
                            {item?.notifications_id?.button_name}
                          </Button>
                        </div>
                      }
                    </Schedule.Item>
                  </>
                )
              }) :
                <>No notifications
                </>}
              {/* <Schedule.Item symbol="active">
                <span className="smaller text-secondary">05-03-2024 | 4:23 PM</span>
                <div className="h6">
                  Invitation for creative designs pattern
                </div>
              </Schedule.Item> */}

              {/* <Schedule.Item
                symbol="active"
                contentClass="nk-schedule-content-no-border flex-grow-1"
              >
                <span className="smaller text-secondary">02-03-2024 | 5:05 PM</span>
                <div className="h6">Check our Question Bank</div>
                <Alert variant="info" className="mt-2">
                  <div className="d-flex">
                    <Icon
                      size="lg"
                      name="file-code"
                      className="opacity-75"
                    ></Icon>
                    <div className="ms-2 d-flex flex-column gap-3">
                      <div>
                        <h6 className="alert-heading mb-0">
                          Download PDF File
                        </h6>
                      </div>
                      <div className="d-block mt-1">
                        <Button size="md" variant="primary">
                          <Icon name="download"></Icon>
                          <span>Download</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Alert>
              </Schedule.Item> */}
              {/* <Schedule.Item symbol="active">
                <span className="smaller text-secondary">01-03-2024 | 2:45 PM</span>
                <div className="h6">Project status updated successfully</div>
              </Schedule.Item> */}
              {/* <Schedule.Item
                symbol="active"
                contentClass="nk-schedule-content-no-border"
              >
                <span className="smaller text-secondary">10:30 PM</span>
                <div className="h6">Task report - uploaded weekly reports</div>
                <div className="list-group-dotted mt-3">
                  <div className="list-group-wrap">
                    <div className="p-3">
                      <MediaGroup>
                        <Media className="rounded-0">
                          <Image
                            src="/images/icon/file-type-pdf.svg"
                            alt="icon"
                          />
                        </Media>
                        <MediaText className="ms-1">
                          <a href="#download" className="title">
                            Modern Designs Pattern
                          </a>
                          <span className="text smaller">1.6.mb</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                    <div className="p-3">
                      <MediaGroup>
                        <Media className="rounded-0">
                          <Image
                            src="/images/icon/file-type-doc.svg"
                            alt="icon"
                          />
                        </Media>
                        <MediaText className="ms-1">
                          <a href="#download" className="title">
                            Cpanel Upload Guidelines
                          </a>
                          <span className="text smaller">18kb</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                    <div className="p-3">
                      <MediaGroup>
                        <Media className="rounded-0">
                          <Image
                            src="/images/icon/file-type-code.svg"
                            alt="icon"
                          />
                        </Media>
                        <MediaText className="ms-1">
                          <a href="#download" className="title">
                            Weekly Finance Reports
                          </a>
                          <span className="text smaller">10mb</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                  </div>
                </div>
              </Schedule.Item>
              <Schedule.Item symbol="active">
                <span className="smaller text-secondary">3:23 PM</span>
                <div className="h6">
                  Assigned you to new database design project
                </div>
              </Schedule.Item> */}
            </Schedule>
          </SimpleBar>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
