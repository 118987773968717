import React, { useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthApi from "../../api/auth";
import Layout from "../../layout/fullpage";
import Swal from 'sweetalert2/src/sweetalert2.js';
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
} from "../../components";
import ImageSlider from "../ImageSlider";
import { useState } from "react";
// import Swal from 'sweetalert2/src/sweetalert2.js';
// import AuthApi from "../../api/auth";

function AuthRegisterPage() {
  const [data, setData] = useState({
    "login_type":2,
    "roleId":"64b26c44a4437fc38fcb3378",
    "stu_profile": {
      "rating":5,
      "inst_id":"64b2817f8991975cd095cb08"
  },
  });

  let api = new AuthApi();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  console.log("data", data);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await api.student_register(data).then((res) => {
      try {
        if (res.data.status) {
          console.log(res.data)
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: "Register Successfully",
          });
        }
        // else {
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Oops...',
        //     text: res.err,
        //   });
        // }
      } catch (err) {
        console.log(err)
      }
    });
  };


  

  useEffect(() => {
        document.documentElement.style.setProperty('--theme-color',"#1682FB");
   
    // Access the value of $accent-color from the compiled CSS
    // Use the accentColor variable as needed
    // root.style.setProperty("--accent-color", domain?.color_code, "important");
  }, []);  

  return (
    <Layout title="Register" centered>
      <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse">
            <Col lg="6">
              <Card.Body>
                <div className="nk-block-head text-center">
                  <div className="nk-block-head-content">
                    <div className="login-logo">
                      <Logo />
                    </div>
                    <h1 className="nk-block-title mb-1">Create New Account</h1>
                  </div>
                </div>
                <Form action="#" onSubmit={handleSubmit}>
                  <Row className="gy-3">
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="selectProduct">Select Product</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Select id="selectProduct" name="product" required>
                            <option >Choose...</option>
                            <option value={"PTE"}>PTE</option>
                            <option value={"IELTS"} >IELTS</option>
                            <option value={"DUOLINGO"}> DUOLINGO</option>
                            <option value={"CELPIP"}>CELPIP</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">Please select a Product.</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            name="userName"
                            value={data.userName}
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter username"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <Form.Control
                            type="email"
                            name="email"
                            value={data.email}
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter email address"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            name="password"
                            value={data.password}
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter password"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <Form.Control
                            type="number"
                            name="mobile"
                            value={data.mobile}
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Check
                        className="form-check-sm"
                        type="checkbox"
                        id="agreeCheckbox"
                        label="I agree to privacy policy & terms"
                      />
                    </Col>
                    <Col className="col-7 mx-auto">
                      <div className="d-grid">
                        <Button type="submit">Sign up</Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div className="text-center my-4">
                  <p className="small">
                    Already have an account?{" "}
                    <Link to="/auths/auth-login">Login</Link>
                  </p>
                </div>
                {/* <div className="my-3 text-center">
                                    <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                </div> */}
                <Row className="g-2">
                  <Col className="col-7 mx-auto">
                    <Button
                      href="#auth"
                      variant="outline-light"
                      className="w-100 btn-primary justify-content-start p-0"
                    >
                      <Image
                        src="/images/icon/d.png"
                        alt=""
                        className="icon icon-bg"
                      />
                      <span className="fw-medium ms-4 text-white">
                        Continue with Google
                      </span>
                    </Button>
                  </Col>
                  <Col className="col-7 mx-auto">
                    <Button
                      href="#auth"
                      variant="outline-light"
                      className="w-100 btn-primary justify-content-start p-0"
                    >
                      <Image
                        src="/images/icon/b.png"
                        alt=""
                        className="icon icon-bg"
                      />
                      <span className="fw-medium ms-4 text-white">
                        Continue with Facebook
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
            <Col lg="6">
              <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                <ImageSlider className="login-slider"></ImageSlider>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
}

export default AuthRegisterPage;
