import { Button, Card, Table, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";

function AddFund() {

  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add Fund</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card className="card-gutter-md w-75 mx-auto h-100">
          <Card.Body>
            <div className="d-flex flex-column gap-3">
              <h5>Purchase Summary</h5>
              <div className="d-flex justify-content-between">
                <b>Credit</b>
                <b>20000</b>
              </div>
              <div className="d-flex justify-content-between">
                <small>Price</small>
                <small>INR 20000</small>
              </div>
              <div className="d-flex justify-content-between">
                <small>GST (18%)</small>
                <small>INR 3600</small>
              </div>
              <div className="d-flex justify-content-between">
                <h3>Total : </h3>
                <h3>INR 23600</h3>
              </div>
              {/* <div className="border p-2 text-center text-secondary">
                OFFER ON PLAN UPGRADE
              </div> */}

              {/* <div class="d-flex gap-2">
                <label class="card w-50">
                  <input name="plan" class="radio" type="radio" />
                  <span class="plan-details">
                    <span class="plan-type">UPTO 25% OFF</span><hr />
                    <h5>Essentials</h5>
                    <span>Upgrade your current plan with INR 20,000 and get benefit in student creation. Your per student creation price will be 500.</span>
                  </span>
                </label>
                <label class="card w-50">
                  <input name="plan" class="radio" type="radio" />
                  <span class="plan-details">
                    <span class="plan-type">UPTO 50% OFF</span><hr />
                    <h5>Basic</h5>
                    <span>Upgrade your current plan with INR 10,000 and get benefit in student creation. Your per student creation price will be 600.</span>
                  </span>
                </label>
              </div> */}

              <div className="border p-2 text-center text-secondary">
                PAYMENT OPTION
              </div>

              <Button variant="primary">Checkout Now</Button>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddFund;