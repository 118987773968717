

import React, { useEffect, useState } from 'react'
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";
import {
  Icon,
  Image,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../components";
import { DropDown } from '../../../components/Form/DropDown';
import Swal from 'sweetalert2';
import AnnoucementApi from "../../../api/auth/annoucement";
import ScoreFeedbackApi from '../../../api/auth/adminPanel/scoreFeedback';
import { useParams } from 'react-router-dom';
import { components } from 'react-trello';

function EditScoreFeedback() {

    const [data, setData] = useState({})

    const [filterByModuleName, setFilterByModuleName] = useState([])
    const [defaultModuleData, setDefaultModuleData] = useState([])

    const rangeType = [
      "Less Than",
      "Greater Than"
    
    ];

    let DropDownWidth = { width: "140px" };

    let scoreFeedbackApi = new ScoreFeedbackApi();

    let {_id} = useParams();
   

    const getScoreFeedbackById = async () => {

      await scoreFeedbackApi.getScoreFeedbackById(_id).then((res) => {
        try {
          if (res.status) {           
            setData(res?.data)
          }
        } catch (err) {
          console.log(err);
        }
      });
    };
useEffect(() => {

  getScoreFeedbackById();
},[])

    const handleChange =async  (e) => {
            const {name,value}=e.target


            console.log('name,value :>> ', name,value);
        setData({...data, [e.target.name]:e.target.value})
   
     
    
    
      };

    let speaking = [
        { value: "read_alouds", text: "Read Aloud" },
        { value: "repeat_sentences", text: "Repeat Sentence" },
        { value: "describe_images", text: "Describe Image" },
        { value: "retell_lectures", text: "Re-Tell Lecture" },
        { value: "answer_questions", text: "Answer Short Question" },
      ];
    
      let writing = [
        { value: "swts", text: "Summarize Writing Text" },
        { value: "essays", text: "Essay" },
      ];
    
      let reading = [
        { value: "fib_wr", text: "Reading and Writing Fill in the Blanks" },
        { value: "r_mcm", text: "Multiple Choice, Choose Multiple Answer" },
        { value: "ro", text: "Re-order Paragraphs" },
        { value: "r_mcs", text: "Multiple Choice, Choose Single Answer" },
        { value: "fib_rd", text: "Reading: Fill in the Blanks" },
      ];
    
      let listening = [
        { value: "ssts", text: "Summarize Spoken Text" },
        { value: "l_mcm", text: "Multiple Choice Multiple Answer" },
        { value: "l_fib", text: "Fill in the Blanks" },
        { value: "l_hcs", text: "Highlight Correct Summary" },
        { value: "l_mcs", text: "Multiple Choice Single Answer" },
        { value: "l_smw", text: "Select Missing Words" },
        { value: "hiws", text: "Highlight Incorrect Words" },
        { value: "wfds", text: "Write From Dictation" },
      ];

      let feedbackData = [
        {
            module_name:["repeat_sentences","describe_images","retell_lectures"], 
            components:["content","pronunciation","fluency"]
        },
        {
            module_name:["answer_questions"],
            components:["content"]
        },
        {
            module_name:["swts"],
            components:["content","form","grammar","vocabulary"]
        },
        {
            module_name:["essays"],
            components:["content","form","grammar","spelling","vocabulary_range","general_linguistic_range","development_structure_coherence"]
        },
        {
            module_name:["fib_wr", "fib_rd"],
            components:["blanks"]
        },
        {
            module_name:["r_mcm","r_mcs","l_mcm","l_hcs","l_mcs","l_smw"],
            components:["choice"]
        },
        {
            module_name:["ro"],
            components:["pair"]
        },
        {
            module_name:["ssts"],
            components:["content","form","grammar","spelling","vocabulary"]
        },
        {
            module_name:["l_fib"],
            components:["blanks"]
        },
        {
            module_name:["hiws","wfds"],
            components:["words"]
        }
      ]

      

      console.log("SCOREFEEDBACKDATA",data);
   
      console.log("defaultModuleData",defaultModuleData);
      const [commentValue,SetCommentValue]=useState(true)
      useEffect(() => {
        const filtered = feedbackData.filter((ele) => ele.module_name.includes(data?.module_name))[0]?.components
        setFilterByModuleName(filtered);
      },[data])

      useEffect(() => {
        if(filterByModuleName?.length > 0){
            let dataObj = [];
            for(let val of filterByModuleName){
              console.log("data,val",val);
                dataObj.push({value:val,count:data?.[val]?.length, data:data[val]})
            }
            setDefaultModuleData(dataObj)
        }
        
      
      },[filterByModuleName])

      function handleTextChange(e){
        let changedObj = defaultModuleData?.filter((ele) => ele.value == e.target.id)
        let filteringWithoutCurrent = defaultModuleData?.filter((ele) => ele.value != e.target.id)
        console.log("e.target.id",e.target.alt);
        let last = changedObj[changedObj.length-1]
        console.log("withoutAdding",changedObj);
        last.data[e.target.alt] = {...last.data[e.target.alt],[e.target.name] : e.target.value}
        last = {...last}
        console.log("lastdata",last);
        setDefaultModuleData([...filteringWithoutCurrent, last ])
      }

      function handleAdd(component){
        const filtered = defaultModuleData?.filter((ele) => ele.value == component)[0]
        let filteringWithoutCurrent = defaultModuleData?.filter((ele) => ele.value != component)
        filtered.count++;
        setDefaultModuleData([...filteringWithoutCurrent, filtered ])
      }

      function handleRemove(e, component, key){
        const filtered = defaultModuleData?.filter((ele) => ele.value == component)[0];
        let filteringWithoutCurrent = defaultModuleData?.filter((ele) => ele.value != component)
        filtered.count--;
        console.log("KEY",key);
        filtered.data.splice(key,1)
        console.log("FILTERED",filtered);
        setDefaultModuleData([...filteringWithoutCurrent, filtered ])
        SetCommentValue(false)
      }

      const [error, setError] = useState({});
   

      // setAllData({...data,...defaultModuleData})
      const validateForm = () => {
        const newErrors = {};
        console.log("data.name=", data.title);
        if (!data?.title) {
          newErrors.title = "Title required";
        }
        if (!data?.priority) {
          newErrors.priority = "Priority required";
        }
        if (!data?.attachment) {
          newErrors.attachment = "Banner Image required";
        }
        setError((prev) => ({ ...prev, newErrors }));
    
        console.log(newErrors, "newErrors");
        return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
      };

    
   

      const addScoreFeedbackHandler = async (e) => {
        e.preventDefault();
   
       
        const objDat={}
        for(let ele of defaultModuleData ){
  
     
         const dataToSend={
          [ele.value]:ele.data
         }
         Object.assign(objDat,dataToSend)



        }
        console.log('arrData :>> ', objDat);

        await scoreFeedbackApi
            .updateScoreFeedback({...data, ...objDat})
            .then((res) => {
          
              if (res?.status) {
                Swal.fire({
                  icon: "success",
                  text: res.msg,
                });
                // navigate("/view-announcement");
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops",
                  text: res.msg,
                });
              }
              console.log("res", res);
            })
            .catch((err) => {
              console.log(err);
            });
          console.log("Form submitted:");
        }

        console.log('defaultModuleData :>> ', defaultModuleData);
        console.log('data?.module :>> ', data?.module);
     
        console.log("filterByModuleName",filterByModuleName);
        

        return (
          <Layout title="Score Feedback" content="container">
            <h1 className="mb-5">Score Feeback</h1>
            <Block>
                <Form action="#">
                  <Card className="card-gutter-md">
                    <Card.Body>
                      <Row className="g-gs">
                        <Col md="9">
                          <Row className="g-gs" id="">
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label className="ms-3 fw-bold">
                                  Single Test Type
                                </Form.Label>
                                <div className="form-control-wrap">
                                  <Select
                                    name="module"
                                    onChange={(e) => handleChange(e)}
                                    removeItemButton
                                    value={data?.module}
                                    disabled
                                  >
                                    <option value="">Select Test</option>
                                    <option value="speaking">Speaking</option>
                                    <option value="writing">Writing</option>
                                    <option value="reading">Reading</option>
                                    <option value="listening">Listening</option>
                                  </Select>
                                </div>
                              </Form.Group>
                            </Col>
      
      
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label className="ms-3 fw-bold">
                                  Select Question Type
                                </Form.Label>
                                <div className="form-control-wrap">
                                  <Form.Select
                                    removeItemButton
                                    name="module_name"
                                    onChange={(e) => handleChange(e)}
                                    value={data?.module_name}
                                    disabled
                                  >
                                      <option value="" style={{display:"none"}}>
                                          
                                      </option>
                                    {data?.module === "speaking" &&
                                      speaking.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                    {data?.module === "writing" &&
                                      writing.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                    {data?.module === "reading" &&
                                      reading.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                    {data?.module === "listening" &&
                                      listening.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>
      
                            {data?.module_name && <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label className="ms-3 fw-bold">
                                  Add Score Feedback of {data?.module}
                                </Form.Label>
                                <div className="form-control-wrap">
                                  {filterByModuleName?.length>0  && filterByModuleName.map((component) => {
                                      return (
                                        <>
                                          <div className='row'>
                                            <div className='col-md-3'>
                                              <div id={component}>
                                                  <p className="ms-3  text-black">
                                                      {component[0].toUpperCase() + component.slice(1)}
                                                  </p>
                                              </div>
                                              </div>
                                              <div className='col-md-9'>
                                              <div  id={component} className='ms-1'>
                                                  {[...new Array(defaultModuleData?.filter((ele) => ele.value == component)[0]?.count)].map((ele, key) => {
      console.log([...new Array(defaultModuleData?.filter((ele) => ele.value == component)[0]?.count)],key,defaultModuleData,"defaultModuleData?.filter((ele) => ele.value == component)[0]?.comment");
                                                    return ( 
                                                      <>
                                                      {
      
                                                        defaultModuleData?.map((item,index)=>{
                                                          console.log('ele :>> ', defaultModuleData?.filter((ele) => ele.value == component));
                                                          
                                                        })
      
                                                      }
                                                      
                                                      <Form.Group className="form-group d-flex gap-2 mb-3" >
      
                                                          <div className="form-control-wrap">
                                                              <Form.Control
                                                                  type="text"
                                                                  className='fs-4 '
                                                                  disabled
                                                                  value={" > = "}
                                                                  style={{width:"70px"}}
                                                                  
                                                              ></Form.Control>
      
                                                          </div>
      
                                                          <div className="form-control-wrap">
                                                              <Form.Control
                                                                  type="text"
                                                                  alt={key}
                                                                  placeholder="Enter Range"
                                                                  id={component}
                                                                  name="Range"
                                                                  onChange={handleTextChange}
                                                                  value={
                                                                   
                                                                   
                                                                    (defaultModuleData?.filter((ele) => ele.value == component)?.[0])?.data[key]?.Range
                                                                  }
                                                                  // value={component}
                                                                  
                                                              ></Form.Control>
      
                                                          </div>
      
                                                          
                                                          <div className="form-control-wrap">
                                                              <Form.Control
                                                                 type="text"
                                                                 rows="2"
                                                                 alt={key}
                                                                  placeholder="Enter Remarks"
                                                                  id={component}
                                                                  name="Comment"
                                                                  onChange={handleTextChange}
                                                                  style={{width:"400px"}}
                                                                  
                                                                  value={
                                                                    
                                                                    
                                                                    (defaultModuleData?.filter((ele) => ele.value == component)[0])?.data[key]?.Comment
                                                                  
                                                                  }
                                                                  // value={ defaultModuleData?.[key]?.data}
                                                                  
                                                              ></Form.Control>
                                                          </div>
                                                          {defaultModuleData?.filter((ele) => ele.value == component)[0]?.count > 1&& (
                                                          <Button
                                                              className="btn-icon"
                                                              variant="outline-transparent"
                                                              key={key}
                                                              onClick={(e) => handleRemove(e,component ,key)}
                                                          >
                                                              <Icon
                                                              name="minus-circle"
                                                              variant="danger"
                                                              ></Icon>
                                                          </Button>
                                                          )}


                                                          {defaultModuleData?.filter((ele) => ele.value == component)[0]?.count - 1 == key ? (
                                                          <Button
                                                              className="btn-icon"
                                                              variant="outline-transparent"
                                                              onClick={()=> handleAdd(component)}
                                                          >
                                                              <Icon
                                                              name="plus-circle"
                                                              variant="primary"
                                                              ></Icon>
                                                          </Button>
                                                          ) : (
                                                          <Button
                                                              className="btn-icon"
                                                              variant="outline-transparent"
                                                          ></Button>
                                                          )}
                                                         
                                                      </Form.Group>

                                                      
                                                      </>
                                                     )
                                                      
                                                  })}
      
                              
                                                  
                                              </div>
                                              </div>
                                              
                                          </div>
                                       
                                         </>
                                      )
                                  })}
                                </div>
                              </Form.Group>
                            </Col>}
                            
                          </Row>
                        </Col>
                        <Col md="9" className="my-2  d-flex justify-content-end">
                      <Button className='' onClick={addScoreFeedbackHandler}>Update</Button>
                    </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Block>
          </Layout>
        )
}

export default EditScoreFeedback