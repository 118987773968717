import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import React, { Fragment } from "react";
import { useContext, useState, useEffect } from "react";
import Context from "../../../../context";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin/index";
import Swal from "sweetalert2/src/sweetalert2.js";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteReadingFillinBlanks() {
  let { id } = useParams();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "reading_fill_blanks",
    instructions:"In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.",
    question_options: [
      {
        options: ["test"],
        answer: ["test"],
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [loader, setloader] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [A, setAnsFirstTime] = useState(false);
  const [question_begin_time_FirstTimE, setQuestion_begin_time_FirstTimE] =
    useState(false);
  const [answer_prepare_time_FirstTimE, setanswer_prepare_time_FirstTimE] =
    useState(false);
  const checkFormValidity = () => {
    const {
      instructions,
      question_content,
      question_options,
      tags,
      answer_time,
      question_type,
      module_name,
      module,
    } = data;
    let questionOptions = data.question_options.some(
      (option) => option.answer.includes("") || option.options.includes("")
    );
    return (
      instructions &&
      question_content &&
      tags &&
      answer_time?.length === 5 &&
            (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      !questionOptions
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);

  const handleChange = (e, index) => {
    let { name, value } = e.target;
    e.preventDefault();
    if (name === "answer_time") {
      if (parseInt(value, 10) < 60 && !value.includes(":")) {
        console.log("parseInt(value, 10)", parseInt(value, 10));
        if (A) {
          value = value;
          setAnsFirstTime(!A);
        } else {
          if (value?.length == 2) {
            setAnsFirstTime(!A);
          }

          value = value?.length == 2 ? value + ":" : value;
        }

        setData({ ...data, [name]: value });
      } else {
        if (!value.split(":")[1]) {
          if (value == ":") {
            setData({ ...data, [name]: "00:" });
          } else {
            if (value.includes(":") && value?.length == 2) {
              setData({ ...data, [name]: "0" + value });
            } else {
              if (value.includes(":") && value?.length == 3) {
                setData({ ...data, [name]: value });
              } else {
                setData({ ...data, [name]: "" });
              }
            }
          }
        } else {
          if (
            value.split(":")[1]?.length < 3 &&
            value.split(":")[0]?.length < 3
          ) {
            setData({
              ...data,
              [name]:
                value.split(":")[1] < 60 && value.split(":")[0] < 60
                  ? value
                  : "",
            });
          }
        }
      }
    } else if (name == "question_begin_time") {
      if (parseInt(value, 10) < 60 && !value.includes(":")) {
        if (question_begin_time_FirstTimE) {
          value = value;
          setQuestion_begin_time_FirstTimE(!question_begin_time_FirstTimE);
        } else {
          if (value?.length == 2) {
            setQuestion_begin_time_FirstTimE(!question_begin_time_FirstTimE);
          }
          value = value?.length == 2 ? value + ":" : value;
        }

        setData({ ...data, [name]: value });
      } else {
        if (!value.split(":")[1]) {
          if (value == ":") {
            setData({ ...data, [name]: "00:" });
          } else {
            if (value.includes(":") && value?.length == 2) {
              setData({ ...data, [name]: "0" + value });
            } else {
              if (value.includes(":") && value?.length == 3) {
                setData({ ...data, [name]: value });
              } else {
                setData({ ...data, [name]: "" });
              }
            }
          }
        } else {
          if (
            value.split(":")[1]?.length < 3 &&
            value.split(":")[0]?.length < 3
          ) {
            setData({
              ...data,
              [name]:
                value.split(":")[1] < 60 && value.split(":")[0] < 60
                  ? value
                  : "",
            });
          }
        }
      }
    } else if (name == "answer_prepare_time") {
      if (parseInt(value, 10) < 60 && !value.includes(":")) {
        if (answer_prepare_time_FirstTimE) {
          value = value;
          setanswer_prepare_time_FirstTimE(!answer_prepare_time_FirstTimE);
        } else {
          if (value?.length == 2) {
            setanswer_prepare_time_FirstTimE(!answer_prepare_time_FirstTimE);
          }
          value = value?.length == 2 ? value + ":" : value;
        }

        setData({ ...data, [name]: value });
      } else {
        if (!value.split(":")[1]) {
          if (value == ":") {
            setData({ ...data, [name]: "00:" });
          } else {
            if (value.includes(":") && value?.length == 2) {
              setData({ ...data, [name]: "0" + value });
            } else {
              if (value.includes(":") && value?.length == 3) {
                setData({ ...data, [name]: value });
              } else {
                setData({ ...data, [name]: "" });
              }
            }
          }
        } else {
          if (
            value.split(":")[1]?.length < 3 &&
            value.split(":")[0]?.length < 3
          ) {
            setData({
              ...data,
              [name]:
                value.split(":")[1] < 60 && value.split(":")[0] < 60
                  ? value
                  : "",
            });
          }
        }
      }
    } else {
      if (index != undefined) {
        if (name == "answer") {
          const updatedOptions = [...data.question_options[0].answer];
          updatedOptions[index] = value;
          const newData = {
            ...data,
            question_options: [
              {
                ...data.question_options[0],
                answer: updatedOptions,
              },
            ],
          };

          setData(newData);
        } else {
          if (name == "options") {
            const updatedOptions = [...data.question_options[0].options];
            updatedOptions[index] = value;
            const newData = {
              ...data,
              question_options: [
                {
                  ...data.question_options[0],
                  options: updatedOptions,
                },
              ],
            };

            setData(newData);
            // if (name == "options") {
            //   const updatedOptions = [...data.question_options];
            //   updatedOptions[i] = {
            //     ...updatedOptions[i],
            //     options: value,
            //   };

            //   setData({
            //     ...data,
            //     question_options: updatedOptions,
            //   });
            // }
          }
        }
      } else {
        if (name == "module_name") {
          const selectedOption = e.target.options[e.target.selectedIndex];
          const itemText = selectedOption.getAttribute("data-text");
          setData({ ...data, [name]: value, module_label: itemText });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };

  console.log("data", data);

  const removeBlankItems = () => {
    const updatedAnswer = data.question_options[0]?.answer.filter(
      (item) => item !== ""
    );
    const updatedOptions = data.question_options[0]?.options.filter(
      (item) => item !== ""
    );

    const updatedQuestionOptions = [
      {
        answer: updatedAnswer,
        options: updatedOptions,
      },
    ];

    const newData = {
      ...data,
      question_options: updatedQuestionOptions,
    };

    return newData;
  };
  let formObject = new FormData();
  let api = new AdminApi();
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    let isAudio = false;
    let isVideo = false;

    // if (data?.explanatory_video_file?.length > 0 ) {
    //   const fileType = data.explanatory_video_file[0].type;
    //   if (fileType.startsWith("audio/")) {
    //     isAudio = true;
    //     formObject.append("explanatory_video_file", data.explanatory_video_file[0]);
    //   } else if (fileType.startsWith("video/")) {
    //     isVideo = true;
    //     formObject.append("explanatory_video_file", data.explanatory_video_file[0]);
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       title: "File must be an video",
    //       text: "Please upload an  video file.",
    //     });
    //     return;
    //   }
    // }
    for (const [key, value] of Object.entries(data)) {
      if (key !== "explanatory_video_file" && key !== "question_options") {
        if (value != null) {
          formObject.append(key, value);
        }
      } else if (key === "question_options") {
        value.forEach((question, index) => {
          question.options.forEach((option, subIndex) => {
            formObject.append(
              `question_options[${index}][options][${subIndex}]`,
              option
            );
          });
          question.answer.forEach((answer, subIndex) => {
            formObject.append(
              `question_options[${index}][answer][${subIndex}]`,
              answer
            );
          });
        });
      }
    }

    setloader(!loader)
    await api
      .add_Question_speaking(formObject)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/view-questions");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
        }
        setloader(!loader);
      })
      .catch((err) => {
        setloader(!loader);
        console.log("err", err);
      });
  };

  const handleAdd = (name) => {
    if (name == "options") {
      const updatedOptions = [...data.question_options[0].options];
      updatedOptions.push("");
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            options: updatedOptions,
          },
        ],
      };
      setData(newData);
    } else {
      const updatedOptions = [...data.question_options[0].answer];
      updatedOptions.push("");
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            answer: updatedOptions,
          },
        ],
      };
      setData(newData);
    }
  };

  const trash = (type, index) => {
    console.log("Type:", type, "Index:", index);

    if (type === "options") {
      const updatedOptions = [...data.question_options[0].options];
      updatedOptions.splice(index, 1);
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            options: updatedOptions,
          },
        ],
      };
      setData(newData);
    }
    if (type === "answer") {
      const updatedOptions = [...data.question_options[0].answer];
      updatedOptions.splice(index, 1);
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            answer: updatedOptions,
          },
        ],
      };
      setData(newData);
    }
  };
  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log("data", data);
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Reading Fill in Blanks Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#" onSubmit={handleSubmit}>
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => {
                      setSelectedOptions(e);
                    }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e);
                      setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    {id && (
                      <EditTimesFiled
                        data={data}
                        onDataChange={(name, val) =>
                          setData({ ...data, [name]: val })
                        }
                      />
                    )}
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="instruction"
                          className="ms-3 fw-bold"
                        >
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            placeholder="Add Instructions"
                            value={data.instructions}
                            name="instructions"
                            onChange={(e) => handleChange(e)}
                            id="instruction"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Paragraph
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="question_content"
                            value={data.question_content}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Paragraph"
                            id="paragraph"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <h5 className="mb-3">Add Blank Input Field</h5>
                  <div className="d-flex gap-3 flex-wrap" id="blankfields">
                    {data?.question_options[0].answer != 0 &&
                      data?.question_options[0].answer.map((item, i) => {
                        return (
                          <>
                            {" "}
                            <Form.Group className="form-group">
                              <div className="form-control-wrap" key={i}>
                                <Form.Control
                                  style={{ width: "150px" }}
                                  type="text"
                                  onChange={(e) => handleChange(e, i)}
                                  value={item != "test" ? item : null}
                                  name="answer"
                                  placeholder="Enter Text"
                                />
                                {data?.question_options?.[0].answer?.length !=
                                  1 && (
                                  <div
                                    className="form-control-icon end"
                                    onClick={() => trash("answer", i)}
                                  >
                                    <Icon variant="danger" name="trash"></Icon>
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          </>
                        );
                      })}
                    <Button
                      variant="outline-primary"
                      name="answer"
                      onClick={() => handleAdd("answer")}
                      style={{ width: "50px" }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </div>
                </Col>
                <Col lg="12">
                  <h5 className="mb-3">Add Input Field Options</h5>
                  <div
                    className="d-flex gap-3 flex-wrap"
                    id="blankfieldsOption"
                  >
                    {data?.question_options[0].options != 0 &&
                      data?.question_options[0].options.map((item, i) => {
                        return (
                          <>
                            <Form.Group className="form-group">
                              <div className="form-control-wrap" key={i}>
                                <Form.Control
                                  style={{ width: "150px" }}
                                  type="text"
                                  value={item != "test" ? item : null}
                                  onChange={(e) => handleChange(e, i)}
                                  placeholder="Enter Text"
                                  name="options"
                                />
                                {data?.question_options?.[0].options?.length !=
                                  1 && (
                                  <div
                                    className="form-control-icon end"
                                    onClick={() => trash("options", i)}
                                  >
                                    <Icon variant="danger" name="trash"></Icon>
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          </>
                        );
                      })}

                    <Button
                      variant="outline-primary"
                      name="options"
                      onClick={() => handleAdd("options")}
                      style={{ width: "50px" }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </div>
                </Col>
                <Col lg="9">
                  <TranscriptExplanation
                    setState={(name) => setData({ ...data, [name]: "" })}
                    data={data}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">
                      Add Tag (#)
                    </Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        value={data.tags}
                        name="tags"
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                        placeholder="#Tag"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold" htmlFor="addaudio">Explanatory Video (Optional)</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="Explanatory Video"
                            value={data?.explanatory_video}
                            name="explanatory_video"
                            onChange={(e) => {
                              setData({...data, "explanatory_video":e.target.value})
                            }}
                            placeholder="Explanatory Video URL"
                          />
                          {/* {data?.explanatory_video ? (
                            <>
                              <div className="d-flex gap-2">
                                {(data?.explanatory_video  && (
                                  <video
                                    src={data?.explanatory_video }
                                    width="200"
                                    height="155"
                                    controls={false}
                                  />
                                )) 
                                // ||
                                //   (data.question_content_audio && (
                                //     <audio
                                //       src={data?.question_content_audio}
                                //       controls
                                //     />
                                //   ))
                                  }
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        explanatory_video : "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="video"
                          iconName="upload"
                          name="explanatory_video_file"
                          onChange={(updatedFiles) => {
                            setData({ ...data, explanatory_video_file: updatedFiles });
                          }}
                        />
                          )} */}
                        </div>
                      </Form.Group>
                    </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton
                    loader={loader}
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteReadingFillinBlanks;
