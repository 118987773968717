import React, { useState, useEffect, useContext } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import ModalContext from "../../../context/ModalContext";
function ProgressBarCustom({ initialTime, data,stopRecordings }) {
  let { popUpType, setPopUpType, manageRecordings, setManageRecordings } =
    useContext(ModalContext);
  const answerTimeInSeconds = convertTimeToSeconds(initialTime);

  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [startBeep, setStartBeep] = useState(false);
  const [show, setshow] = useState(false);

  function convertTimeToSeconds(time) {
    const [minutes, seconds] = time.split(":");
    return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeInSeconds(
        (prevTime) => prevTime + 0.5 * (100 / answerTimeInSeconds)
      );
      setshow(!show);
    }, 1000);
    if (timeInSeconds >= 100) {
      return () => clearInterval(interval);
    }
    setshow(!show);
    setStartBeep(true);
  }, []);
  if (
    (timeInSeconds > 99.99 && timeInSeconds < 100) ||
    Math.round(timeInSeconds) == 100
  ) {
    stopRecordings();
    setManageRecordings({ ...manageRecordings, start: false, stop: true });
    setPopUpType("recordingStopped");
  }
  return (
    <div>
      {/* {show ? (
        <img
          src="/images/exam-recording.svg"
          className="exam-btn"
          // onClick={startRecording}
        ></img>
      ) : (
        <img
          src="/images/exam-recording.svg"
          className="exam-btn invisible"
          // onClick={startRecording}
        ></img>
      )} */}
      {/* <button onClick={handleStopRecording}>handleStopRecording Recording</button> */}
      <div className="d-flex flex-column">
        {/* {" "}
        <small>Recording</small> */}
      </div>
      {startBeep && (
        <audio
          className="d-none"
          autoPlay
          src="/sound/beep.wav"
          controls
        ></audio>
      )}
      <ProgressBar
        className='progress-ui'
         variant="hisuccess"
        // onTimeUpdate={()=>}
        now={timeInSeconds}
        style={{ height: "18px" }}
      />
      {/* <small>{data.answer_time}</small> */}

      <div>
        {/* {analyzerData && <WaveForm analyzerData={analyzerData} />} */}
      </div>
    </div>
  );
}

export default ProgressBarCustom;
