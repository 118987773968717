import { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import AuthApi from '../../../api/auth';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Icon, Select } from '../../../components';
import InstituteDataTable from '../TeamDataTable';
// import InstituteData, { InstituteColumns } from './InstituteData';
import { Link, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";

function StaffList() {
    let {id}=useParams();
    let api = new AuthApi();
    const [showModal, setShowModal] = useState(false);
    const[data,setData] = useState([])
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

useEffect(()=>{
    getInstituteList();
},[])

const getInstituteList = async () =>{
await api.staff_List().then((res) => {
    console.log("ress", res)
    if (res.data.length > 0 ) {
        setData(res.data.reverse())
    } 
  })
  .catch((error) => {
    console.log(error);
  });
}
console.log(data)
const deleteStaffList = async () =>{
// await api.deleteStaffList(id).then((res)=>{
//     if(res.status){
//       setData(res.data)
//     }
//   }).catch((err)=>{
//     console.log("err",err)
//   })
}
 const InstituteColumns = [
    // {
    //     name: <Form.Check className="form-check" id="check-all" />,
    //     cell: (row) => (
    //         <div className="text-start w-100">
    //             <Form.Check className="form-check" />
    //         </div>
    //     ),
    //     sortable: false,
    //     hide: "md",
    // },
    // {
    //     name: "Institute Name",
    //     grow: "3",
    //     selector: (row) => row?.domain_name,
    //     cell: (row) => (
    //         <MediaGroup>
    //             <Media size="md" shape="circle" variant={row.theme && row.theme}>
    //                 {row?.inst_profile?.business_logo ?
    //                    <Image src={row?.inst_profile?.business_logo} staticImage /> :
    //                 <Image src={'/images/avatar/a.jpg'} staticImage /> 
    //                 }
    //             </Media>
    //             <MediaText>
    //                 <Link to={`/institute-detail/${row.domain_name}`} className="title">{row?.domain_name}</Link>
    //             </MediaText>
    //         </MediaGroup>
    //     ),
    //     sortable: true,
    // },
    // {
    //     name: "Product",
    //     selector: (row) => row.product,
    //     cell: (row) => (
    //         <span className="text-middark">{row.product}</span>
    //     ),
    //     sortable: true,
    //     hide: "md",
    // },
    {
        name: "Username/email",
        selector: (row) => row?.email,
        cell: (row) => (
            <span className="text-middark">{row?.email}</span>
        ),
        sortable: true,
    },
    {
        name: "Name ",
        selector: (row) => row.inst_profile?.inst_contact_firstname,
        cell: (row) => (
            <span className="text-middark">{row?.name}</span>
        ),
        sortable: true,
    },
    // {
    //     name: "Assigned",
    //     selector: (row) => row.assigned,
    //     cell: (row) => (
    //         <span className="text-middark">{row.assigned}</span>
    //     ),
    //     sortable: true,
    //     hide: "lg",
    // },
    {
        name: "Mobile",
        selector: (row) => row.attempt,
        cell: (row) => (
            <span className="text-middark">{row?.mobile}</span>
        ),
        sortable: true,
    },
    // {
    //     name: "students",
    //     selector: (row) => row.score,
    //     cell: (row) => (
    //         <span className="text-middark">{row?.inst_profile?.inst_number_of_student}</span>
    //     ),
    //     sortable: true,
    //     hide: "lg",
    // },
    {
        name: "status",
        selector: (row) => row.status,
        cell: (row) => (
            // <span className={`badge text-bg-${row.status === "Active" ? "success"
            //     : row.status === "Inactive" ? "danger"
            //         : "primary"}`
            // }>
            <span className={`badge text-bg-${row.is_deleted == 0 ? "success"
                : row.is_deleted == 1? "danger"
                    : "primary"}`
            }>
                {row.is_deleted == 0 ? "Active" : 'Inactive'}
            </span>
        ),
        sortable: true,
    },
    {
        name: "action",
        cell: (row) => (
            <div className="text-end w-100">
                <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
                    <LinkListItem to={`/edit-team/${row._id}`}>
                        <Icon name="edit"></Icon>
                    </LinkListItem>
                    <div className="del-btn" onClick={()=>deleteStaffList(row._id)}>
            <Icon name="trash" ></Icon>
            </div>
                </LinkList>
            </div>
        ),
        sortable: false,
    },

];
    return (
        <Layout title="Users List" content="container">
            <h1 className='mb-5'> Staff List</h1>
            <Block>
                <Row className="g-gs">
                    <Col md="12">
                        <Card>
                            <Card.Body>
                                <InstituteDataTable tableClassName="data-table-head-light table-responsive staff-list" data={data} columns={InstituteColumns}   />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="firstname">First Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="firstname" type="text" placeholder="First name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="lastname">Last Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="lastname" type="text" placeholder="Last name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">Email Address</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="email" type="text" placeholder="Email address" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label>Status</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a status</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Active</option>
                                            <option value="3">Inactive</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group className="form-group">
                                    <Form.Label>Role</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a role</option>
                                            <option value="1">Administrator</option>
                                            <option value="2">Developer</option>
                                            <option value="3">Analyst</option>
                                            <option value="4">Support</option>
                                            <option value="5">Trial</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={handleCloseModal}>Add User</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Discard</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export default StaffList;