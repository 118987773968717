import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";

function PracticeTopHeading({ data, selected_ques_Obj }) {
    let path = window.location.pathname;
    var module_name = path.split("/")[3];
    return (
        <div className="d-flex gap-3 mb-5 practice-head">
            <img src={`/images/practice-icons/${module_name}.svg`} className="practice-topimg"></img>
            <div className="d-flex flex-column">
                <h3>{data.text} <Button variant="assigned" size="sm" className="ms-3">
                    {/* <img src={data.img}></img> */}
                    <img src="/images/practice-graduationcap.svg"></img>
                    <span className="ms-1">Study Guide</span></Button></h3>
                <p className="text-secondary">
                    {selected_ques_Obj?.instructions}
                </p>
            </div>
        </div>
    )
}

export default PracticeTopHeading;