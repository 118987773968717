import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import { Button, Form, Row, Col, ButtonGroup } from "react-bootstrap";
import { Icon } from "../../components";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BranchDataTable from "../branch/BranchDataTable";
import AuthApi from "../../api/auth";
import BranchFilter from "../branch/BranchFilter";
import Context from "../../context";



function BranchAdminDataTable({inst_id}) {
  const [showItemPerPage, setShowItemPerPage] = useState(5);
  


  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);

  let {
    userData,
  } = useContext(Context);
  userData = JSON.parse(userData);


  let api = new AuthApi();
  const get_branch = async () => {
    await api
      .get_branch(inst_id)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log("RESDATA",res.data);
          setData(res?.data.reverse());
          setAllData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    get_branch();
  }, []);
  const BranchColumns = [
    {
      name: "SR No.",
      cell: (row, index) => (
        <span className="text-middark">{index + 1}</span>
      ),
      sortable: false,
    },
    {
      name: "Branch Name",
      // grow: "1",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row.name?row.name:"-"}</span>      
      ),
      sortable: true,
    },
    {
      name: "Address",
      // grow: "1",
      selector: (row) => row.address,
      cell: (row) => (
        <span className="text-middark">{row.address?row.address:"-"}</span>      
      ),
      sortable: true,
    },
    {
      name: "City",
      // grow: "2",
      selector: (row) => row.location,
      cell: (row) => <span className="text-middark">{row.location?row.location:"-"}</span>,
      sortable: true,
    },
   
  ];

  let navigate = useNavigate();
  return (
    <div className="data-table-wrapper">
   
   {userData?.login_type == "0" && <div className="d-flex justify-content-end mt-4">
              <Button
                className="d-none d-md-inline-flex "
                variant="outline-primary"
                // to={`/add-instititute-branch/${instId}`}

                onClick={() => {
                  return (
                    <>
                      {
                      inst_id
                        &&  navigate(`/add-instititute-branch/${inst_id}`)
                        }
                        </>)}}

              
              >
                <Icon name="plus" />

               <p>Add Branch</p>
                 
              </Button>
      </div>}
    

   
      {(window.location?.pathname?.includes("/institute-detail") || window.location?.pathname?.includes("/institute/profile")) &&
           (

            <>
         <BranchFilter
                
                allData={alldata}
                setAllData={setAllData}
            tableData={data}
            setData={(data) => setData(data)}
            showItemPerPage={showItemPerPage}
            setShowPage={(value) => setShowItemPerPage(Number(value))}
         
                /> 
      <BranchDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
           
                  BatchColumns={BranchColumns}
                  showItemPerPage={showItemPerPage}
                  setShowItemPerPage={setShowItemPerPage}
                />
                
                </>
                )}
    </div>)
}
  


export default BranchAdminDataTable;
