import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";
import BrachDataTable from "./BranchDataTable";
import BranchFilter from "./BranchFilter";
import BranchDataTable from "./BranchDataTable";
import Swal from "sweetalert2";

function BranchList({instId}) {
  let api = new AuthApi();
  let { userData,branchId } = useContext(Context);
  userData = JSON.parse(userData)
let isAdmin = userData?.login_type == "0"
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [alldata, setAllData] = useState([]);
  const [showItemPerPage, setShowItemPerPage] = useState(10);

  console.log('data :>> ', data);

  useEffect(() => {
    get_branch();
  }, []);
  const edit_branch_by_id = async (e, id) => {
    let { name, checked } = e.target;
    var active = null;
    if (checked) {
      active = true;
    } else {
      active = false;
    }
    await api.edit_branch_by_id({ active: active }, id).then((res) => {
      try {
        if (res.status) {
          get_branch();
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const get_branch = async () => {
    await api
      .get_branch(instId?instId:userData._id)
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res?.data.reverse());
          setAllData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const StudentColumns = [
    {
      name: "SR No.",
      cell: (row, index) => <span className="text-middark">{index + 1}</span>,
      sortable: false,
    },
    {
      name: "Branch Name",
      grow: "3",
      selector: (row) => row.name,
      cell: (row) => (
        <MediaGroup>
          {/* <Media size="md" shape="circle" variant={row.theme && row.theme}>
            {row.avatar ? (
              <Image src={row.avatar} staticImage />
            ) : (
              <span className="smaller fw-medium">{row.name}</span>
            )}
          </Media> */}
          <MediaText>
            <Link
              //   to={`/student-management/student-detail/${row._id}`}
              className="title"
            >
              {row.name}
            </Link>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Address",
      grow: "3",
      selector: (row) => row.product,
      cell: (row) => <span className="text-middark">{row.address}</span>,
      sortable: true,
    },

    {
      name: "Location",
      grow: "3",
      selector: (row) => row.username,
      cell: (row) => <span className="text-middark">{row.location}</span>,
      sortable: true,
      hide: "lg",
    },
    // {
    //   name: "Student Type",
    //   selector: (row) => row.studenttype,
    //   cell: (row) => <span className="text-middark">{row.studenttype}</span>,
    //   sortable: true,
    //   hide: "lg",
    // },

    // {
    //   name: "Batch",
    //   grow: "3",
    //   selector: (row) => row.attempt,
    //   cell: (row) => <span className="text-middark">{row?.stu_profile?.batchTime}</span>,
    //   sortable: true,
    //   hide: "lg",
    // },
    // {
    //   name: "Test",
    //   grow: "3",
    //   selector: (row) => row.score,
    //   cell: (row) => <span className="text-middark">{row?.stu_profile?.mockTest}</span>,
    //   sortable: true,
    //   hide: "lg",
    // },

    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100 d-flex justify-content-between">
          <div className="form-check form-switch">
            <input
              className="form-check-input ms-3"
              type="checkbox"
              role="switch"
              value={row.active}
              checked={row.active}
              onChange={(e) => edit_branch_by_id(e, row._id)}
              id="directMessage"
              name="active"
            />
          </div>
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`/edit-branch/${row._id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];

  const BranchColumns = [
    {
      name: "SR No.",
      cell: (row, index) => (
        <span className="text-middark">{index + 1}</span>
      ),
      sortable: false,
    },
    {
      name: "Branch Name",
      // grow: "1",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row.name?row.name:"-"}</span>      
      ),
      sortable: true,
    },
    {
      name: "Address",
      // grow: "1",
      selector: (row) => row.address,
      cell: (row) => (
        <span className="text-middark">{row.address?row.address:"-"}</span>      
      ),
      sortable: true,
    },
    {
      name: "Location",
      // grow: "2",
      selector: (row) => row.location,
      cell: (row) => <span className="text-middark">{row.location?row.location:"-"}</span>,
      sortable: true,
    },
   
   
    
    // {
    //   name: "action",
    //   cell: (row) => (
    //     <>
      
    //       <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
   
    //         <LinkListItem to={`/edit-branch/${row._id}`}>
    //           <Icon name="edit"></Icon>
    //         </LinkListItem>
             
    //          <div className="del-btn" >
    //          <Icon name="trash" ></Icon>
    //        </div>
           
   
    //       </LinkList>
       
    //     </>
    //   ),
    //   sortable: false,
    // },
  ];
  console.log("instId",)
  return (<>
  
  {window.location.pathname == "/view-branch"? 
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Branch Details</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
              <div class="data-table-wrapper">
              <BranchFilter
                
                allData={alldata}
                setAllData={setAllData}
            tableData={data}
            setData={(data) => setData(data)}
            showItemPerPage={showItemPerPage}
            setShowPage={(value) => setShowItemPerPage(Number(value))}
         
                />
                <BranchDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  isAdmin={isAdmin}
                  BatchColumns={BranchColumns}
                  showItemPerPage={showItemPerPage}
                  setShowItemPerPage={setShowItemPerPage}
                />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>:  
      <StudentDataTable
      instId={instId}
      isAdmin={isAdmin}
        tableClassName="data-table-head-light table-responsive"
        data={data}
        columns={StudentColumns}
      />
  }
    </>
  );
}

export default BranchList;
