import Router from "./router";
import { useContext, useEffect, useState } from "react";
import Context from "./context";
import AuthApi from "./api/auth";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import OverrideContext from "./context/OverrideContext/OverrideContext";
import OverrideContextProvider from "./context/OverrideContext/OverrideContextProvider";
function App() {
  const [domainData, setDomainData] = useState(
    localStorage.getItem("instDomainHilingo")
  );
  const [userData, setUserData] = useState(
    localStorage.getItem("userHilingo")
  );
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("userHilingotoken"))
  );
  const [branchId, setbranchId] = useState(
    localStorage.getItem("@#branch_id")
  );

  const [adminToStudent, setAdminToStudent] = useState(false)
  const [globalLoader, setGlobalLoader] = useState(false);
  const [incorreCtDomain, setIncorrectDomain] = useState(false);

  const navigate = useNavigate()
  console.log("CAHNGEEEG");
  let localHost = "adm1n";
  //  let localHost = "isntitute";

  let api = new AuthApi();
  let name = window.location.hostname.split(".")[0];
  useEffect(() => {
    console.log("inside useEffect")
    if (name !== "adm1n" &&  name !== "localhost" &&  name !== "onboarding") {
      getInstittuteDetailByname();
    }
    if (
      name !== "adm1n" &&
      name !== "app" &&
      name === "localhost" &&
      localHost !== "adm1n" &&
      name !== "onboarding"
    ) {
      getInstittuteDetailBynameForLocalhost();
    }
  }, []);

  const getInstittuteDetailByname = async () => {
    console.log("inside getInstittuteDetailByname")
    setGlobalLoader(true)
    await api
      .get_inst_public({ name })
      .then((res) => {
        console.log("ress23323#", res);
        if (res?.data?.length > 0) {
          localStorage.setItem("isOverride",JSON.stringify(res?.data[0]?.isOverride))
          // setOverrideData(JSON.stringify(res?.data[0]?.isOverride))
          if(name == 'app'){

            localStorage.setItem("instituteData",JSON.stringify(res?.data?.[0]))
          }

          if(res?.data[0]?.is_deleted == 1){
            
            Swal.fire({
              icon: "error",
              title: "Your Account Has Been Deactivated By Admin",
              text: res?.data[0]?.inactive_remarks,
            });
          }

          setDomainData(JSON.stringify(res.data[0]));
          localStorage.setItem(
            "instDomainHilingo",
            JSON.stringify(res.data[0])
          );
          setGlobalLoader(false)
          // changefavicon(res.data[0]?.inst_profile?.inst_favicon_logo)
          console.log("CAHNGEEEG");
        } else {
          // localStorage.remove();
          localStorage.clear("instDomainHilingo");
          localStorage.clear("userHilingotoken");
          localStorage.clear("userHilingo");
          setIncorrectDomain(true);
          // navigate("/")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getInstittuteDetailBynameForLocalhost = async () => {
    console.log("inside getInstuteDetailBynameForLocalhost")
    await api
      .get_inst_public({ name: "techimmigration" })
      .then((res) => {
        console.log("ress2332", res);
        if (res?.data?.length > 0) {

          if(res?.data[0]?.is_deleted == 1){
           
            Swal.fire({
              icon: "error",
              title: "Your Account Has Been Deactivated By Admin",
              text: res?.data[0]?.inactive_remarks,
            });
          }

          setDomainData(JSON.stringify(res.data[0]));
          localStorage.setItem(
            "instDomainHilingo",
            JSON.stringify(res.data[0])
          );
          console.log("CAHNGEEEG");
          // changefavicon(res.data[0]?.inst_profile?.inst_favicon_logo)
        } else {
          localStorage.clear("instDomainHilingo");
          localStorage.clear("userHilingotoken");
          localStorage.clear("userHilingo");
          setIncorrectDomain(true);
          // navigate("/")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function changefavicon(faviconImageLink){
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = faviconImageLink;
    console.log("CAHNGEEEG");
  }
  // console.log("DOMAINDATA",JSON.parse(domainData?.inst_profile?.inst_favicon_logo));
  useEffect(() => {
    console.log("DOMAINDATA",JSON.parse(domainData));
    if(JSON.parse(domainData)){
      changefavicon(JSON.parse(domainData)?.inst_profile?.inst_favicon_logo)
    }
    
  },[domainData])

  // function adminToStudentBack(){
  //   setAdminToStudent(false)
  //   const adminData = localStorage.getItem("adminData")
  //   console.log("ADMINDATA",adminData);
  //   localStorage.setItem("userHilingo",adminData)
  //   navigate(`/student-management/student-detail/${userData._id}`)
  //   window.location.reload()
  // }

  // useEffect(() => {
  //   console.log("adminToStudent",adminToStudent);
  //   if(adminToStudent == true){
  //     console.log("TRUE");
  //     const logoutStudentAfterTenMinutes = setTimeout(() => {
  //       adminToStudentBack()
  //     }, 5000)

  //     return () => {
  //       clearTimeout(logoutStudentAfterTenMinutes);
  //     };
  //   }
    
  // },[adminToStudent])

  console.log("adminToStudent",adminToStudent);

  return (
    <>
      <OverrideContextProvider>
      <Context.Provider
        value={{
          localHost,
          setGlobalLoader,
          globalLoader,
          domainData,
          setIncorrectDomain,
          incorreCtDomain,
          setDomainData,
          userData,
          setUserData,
          token,
          setToken,
          branchId,
          setbranchId,
          adminToStudent,
          setAdminToStudent
        }}
      >
        <Router />
      </Context.Provider>
      </OverrideContextProvider>
    </>
  );
}

export default App;
