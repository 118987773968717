import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import Question_Context from "../../../../context/questionContext";
import { useState, useContext, useEffect } from "react";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useParams } from "react-router-dom";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteReadAloud() {
  let { id } = useParams();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [loader, setloader] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "speaking_read_aloud",
    instructions: "Look at the text below. In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.",
    question_options: [
      {
        answer: [""],
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
    answer_content_audio: "",
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState({
    audio: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "question_type") {
      const options = e.target.options;
      const selectedValues = [];
      if (options.length === 0) {
        setSelectedOptions([]);
      }
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedValues.push(options[i].value);
        }
      }
      setSelectedOptions(selectedValues);
      setData({
        ...data,
        isMock: selectedValues.includes("isMock") ? true : false,
        isPractice: selectedValues.includes("isPractice") ? true : false,
        isSection: selectedValues.includes("isSection") ? true : false,
        isQuestion: selectedValues.includes("isQuestion") ? true : false,
        isPrediction: selectedValues.includes("isPrediction") ? true : false,
      });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  let api = new AdminApi();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(!loader);
    for (const [key, value] of Object.entries(data)) {
      if (key === "question_options") {
        formObject.append(`question_options[0][answer][0]`, value[0].answer[0]);
      } else if (key === "file") {
        if (value.length > 0 && value[0].type.startsWith("audio/")) {
          formObject.append("answer_content_audio", value[0]);
        } else {
          setError({ ...error, audio: true });
          Swal.fire({
            icon: "error",
            title: "File must be an audio",
            text: "Please upload an audio file.",
          });
          return;
        }
      } else {
        if (value != null) {
          formObject.append(key, value);
        }
      }
    }
    await api
      .add_Question_speaking(formObject)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/view-questions");
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
        setloader(!loader);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      question_content,
      question,
      answer_time,
      question_options,
      question_begin_time,
      answer_prepare_time,
      answer_content_audio,
    } = data;

    return (
      instructions &&
      question_content &&
      tags &&
      (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      answer_time?.length === 5 &&
      answer_prepare_time?.length === 5
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);

  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add PTE Read Aloud Questions</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => { setSelectedOptions(e) }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e); setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    {id && <EditTimesFiled data={data} onDataChange={(name, val) => setData({ ...data, [name]: val })} />}

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="desc" className="ms-3 fw-bold">Add Description</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="question_content"
                            value={data.question_content}
                            onChange={(e) => handleChange(e)}
                            as="textarea"
                            placeholder="Add Description"
                            id="desc"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <TranscriptExplanation setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="addtag"
                            value={data.tags}
                            placeholder="#Tag"
                            name="tags"
                            onChange={(e) => {
                              var removeHash = e.target.value.replaceAll("#", "");
                              removeHash = "#" + removeHash;
                              setData({ ...data, tags: removeHash });
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className={`form-group ${error.audio && `error-Image`}`}>
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">Add Answer Audio</Form.Label>
                        <div className="form-control-wrap">
                          {data.answer_content_audio ? (
                            <>
                              <div className="d-flex gap-2">
                                <audio
                                  src={data?.answer_content_audio}
                                  controls
                                ></audio>
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        answer_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                              filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setError({ ...error, audio: false });
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id ? id : false} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteReadAloud;
