import { LogoDev } from '@mui/icons-material';
import React, { Fragment, useEffect, useState } from 'react'

const PermissionSelect = ({data ,filterParam, item, userRoutes, totalSelectedRoutes, setTotalSelectedRoutes, selectedRoutesId, setSelectedRoutesId}) => {
    console.log("userData",data);
    
    const [all, setAll] = useState(false);
    const filtered = userRoutes.filter((ele) => {
        return ele.category?.includes(filterParam);
    })
    const routesFromFiltered = [];
    const idFromFiltered = [];
    for(var i=0;i<filtered.length;i++){
      routesFromFiltered.push(filtered[i].route)
      idFromFiltered.push(filtered[i]._id)
    }
    console.log("routesFromFiltered",routesFromFiltered);
    console.log("idFromFiltered",idFromFiltered);
    console.log("FILTERED" ,filtered);

    function handleAll(e){
      console.log("handleAll",handleAll);
      setAll(!all)
      
      const temp = [];
 
      for(var i=0;i<filtered.length;i++){
        temp.push(filtered[i].route)
      }

      if(all){
        console.log("INSIDE IF");
        const newTotalSelectedRoutes = [...totalSelectedRoutes];
        const newSelectedRoutesId = [...selectedRoutesId];
        const toRemove = [...temp]
        const toRemoveIds = [...idFromFiltered]
        const difference = newTotalSelectedRoutes.filter((item) => !toRemove?.includes(item));
        const differenceId = newSelectedRoutesId.filter((item) => !toRemoveIds?.includes(item))
        setTotalSelectedRoutes([...difference])
        setSelectedRoutesId([...differenceId])
      }
      else
      {
        console.log("INSIDE ELSE");
        const newArray = [];
        const newArrayIds = [];
        for(var i=0;i<temp.length;i++){

          if(!totalSelectedRoutes?.includes(temp[i])){
            newArray.push(temp[i]);
          }
          if(!selectedRoutesId?.includes(idFromFiltered[i])){
            newArrayIds.push(idFromFiltered[i]);
          }
        }
        console.log("newArray", newArray);
        setTotalSelectedRoutes([...totalSelectedRoutes, ...newArray])
        setSelectedRoutesId([...selectedRoutesId, ...newArrayIds])
        console.log("totalSelectedRoutesInsidePermission", totalSelectedRoutes);
      }

    }
    
    function handleChange(e){
        let isDuplicate = false; 
        let index = -1;

        let isDuplicateid = false;
        let indexDuplicateId = -1;

        for(var i=0;i<totalSelectedRoutes?.length;i++){
          if(totalSelectedRoutes[i] == e.target.value){
            isDuplicate = true;
            index = i;
            break;
          }
        }
        for(var i=0;i<selectedRoutesId?.length;i++){
          if(selectedRoutesId[i] == e.target.id){
            isDuplicateid = true;
            indexDuplicateId = i;
            break;
          }
        }
        if(isDuplicate){
          let without_duplicate = [...totalSelectedRoutes];
          const hasCreateOrder = e.target.value == "/api/question/createOrder" && without_duplicate?.includes("/api/question/createOrder")
          without_duplicate.splice(index, 1);
          
          //logic for removing verify order route from totalSelectedRoutes
          if(hasCreateOrder){
            without_duplicate = without_duplicate?.filter(item => item !== "/api/question/verifyOrder")
          }

          setTotalSelectedRoutes([...without_duplicate])
        }
        else if(isDuplicate == false){
          e.target.value == "/api/question/createOrder" ? setTotalSelectedRoutes([...totalSelectedRoutes,e.target.value,"/api/question/verifyOrder"]) : setTotalSelectedRoutes([...totalSelectedRoutes, e.target.value])
        }
        if(isDuplicateid){
          let without_duplicate = [...selectedRoutesId];
          const hasCreateOrder = e.target.id == "65d5e0e0aadf9e07e16de6b4" && without_duplicate?.includes("65d5e0e0aadf9e07e16de6b4")
          without_duplicate.splice(index, 1);
          
          //logic for removing verify order route from selectedRoutesId
          if(hasCreateOrder){
            without_duplicate = without_duplicate?.filter(item => item !== "65d5e0e0aadf9e07e16de6b4")
          }

          setSelectedRoutesId([...without_duplicate])
        }
        else if(isDuplicateid == false){
          e.target.id == "65d5e0e0aadf9e07e16de6b4" ? setSelectedRoutesId([...selectedRoutesId,e.target.id,"65d5e2c4aadf9e07e16de6f2"]) : setSelectedRoutesId([...selectedRoutesId, e.target.id])
        }
    }
    


    useEffect(() => {
      setTotalSelectedRoutes(data?.permission_routes)
      setSelectedRoutesId(data?.permission_routes_id)
    }, [data])

    

  return (
        <>  
          <input
            type="checkbox"
            className=""
            value={item}
            checked={all || (routesFromFiltered.every(v => totalSelectedRoutes?.includes(v)) && setAll(true))}
            onChange={(e) => handleAll(e)}
            name="All"
             id={item}
            autoComplete="off"
          />
          <label
            as="label"
            variant=""
            htmlFor={item}
          >
            All
          </label>
            {filtered.length > 0  && 
                        filtered.map((item, i) => {
                          return (
                            item.route != "/api/question/verifyOrder" &&
                            <Fragment key={item._id}>
                              <input
                                type="checkbox"
                                className=""
                                value={item.route}
                                onClick={(e) => !all && handleChange(e)}
                                checked={(all == true  ? true : null) || totalSelectedRoutes?.includes(item.route) ? true : false}
                                name={item.category}
                                id={item._id}
                                autoComplete="off"
                              />
                              <label
                                as="label"
                                variant=""
                                htmlFor={item._id}
                              >
                                {item.module_name}
                              </label>
                            </Fragment>
                          );
                        })}
        </>
  )
}

export default PermissionSelect