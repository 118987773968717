import React from 'react';

const PracticePlaySvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" style={style}>
    <path d="M22.666 23.0316C22.666 20.7558 25.1008 19.3087 27.0999 20.3964L43.582 29.3647C45.6702 30.5008 45.6702 33.4987 43.582 34.6349L27.0999 43.6032C25.1008 44.6908 22.666 43.2437 22.666 40.968V23.0316Z" fill={fill}/>
    <circle cx="32" cy="32" r="31" transform="matrix(1 0 0 -1 0 64)" stroke={fill} stroke-width="2"/>
    </svg>
    
    
  );
}

export default PracticePlaySvg;
