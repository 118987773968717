

import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import Icon from "../Icon/Icon";
import { Button, CloseButton } from 'react-bootstrap';
import NotificationContext from '../../context/NotificationContext/NotificationContext';
function FileUploadImageExtension({filetype,aspectRatio,aspectRatioCalculate, props, iconName, noImage, onChange, ImageData, setdata }) {

                    const {notificationImage,setNotificationImage}=useContext(NotificationContext)
  console.log('ImageData :>> ', ImageData,notificationImage);
  function getAspectRatio(url, callback) {
    const img = new Image()
    img.src = url
    img.onload = () => {
      const w = img.naturalWidth;
      const h = img.naturalHeight;
      let aspectRatio;
      if (w > h) {
          aspectRatio = w / h;
      } else {
          aspectRatio = h / w;
      }
      aspectRatio = Math.floor(aspectRatio)
        console.log("ASPECT",aspectRatio);
        callback(aspectRatio)
      
      
      
    }
    
    
  };
  const [files, setFiles] = useState([]);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],

    },
  });

console.log(acceptedFiles,"acceptedFiles")
  const [updatedFiles, setUpdatedFiles] = useState([])
  const [swalClose, setSwalClose] = useState(true)

  const handleDropChange = (acceptedFiles) => {
    console.log(acceptedFiles, "accepted files");
    const updatedFileArr = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    console.log('updatedFileArr :>> ', updatedFileArr);
    setUpdatedFiles(updatedFileArr)
    setSwalClose(true);
  }


  useEffect(() => {
    console.log("effect1")
    setFiles(updatedFiles)
    if (onChange) {
      onChange(updatedFiles);
    }

    if(filetype=="image"){

      getAspectRatio(updatedFiles?.[0]?.preview, function(result){
        if(aspectRatioCalculate){
          if(result != aspectRatioCalculate){
            setFiles([])
            if (onChange ) {
              onChange([]); 
            }
            // setAspectRatioError(true)
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: aspectRatio,
            });
            setNotificationImage()
            
            // setAspectRatioError(true)
            // const lastRemoveFromFiles = files.pop()
            
          // return   
      
          }
        }
      }
    );
    }

  }, [updatedFiles])

  useEffect(() => {
    console.log("effect2")
    handleDropChange(acceptedFiles)
  }, [acceptedFiles])




  const thumbs =
    !noImage &&
    files.map((file) => (
      <div
        className="dz-preview dz-processing dz-image-preview dz-complete"
        key={file.name}
      >
        <div className="dz-image">
          <img src={file.preview} alt="preview" />
        </div>
      </div>
    ));

  console.log('fileRejections :>> ', fileRejections);

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => {


  //   console.log('errors?.message :>> ', errors?.[0]?.message);

  //     if(errors?.[0]?.message){

  //   //  return( Swal.fire({
  //   //     icon: "error",
  //   //     title: "Oops",
  //   //     text: "Please select Image",
  //   //   }))

  //     Swal.fire({
  //                 icon: "error",
  //                 title: "Oops",
  //                 text: "Please select Image",
  //               });
  //               // return;
  //             }
  //           })




  useEffect(()=>{
    console.log("inside swal",swalClose,fileRejections)
    if (swalClose) {

      if (fileRejections?.[0]?.errors?.[0]?.message) {
        Swal.fire({
          icon: "error",
          title: "Oops",
          //   text: fileRejections?.[0]?.errors?.[0]?.message,
          text: "Image must be JPG/JPEG or PNG",
        }).then((result) => {
          if (result.isConfirmed) {
            // Perform your onClick handler functionality here
            setSwalClose(false);
  
          }
        });;
        // return;
      }
    }

  },[swalClose,acceptedFiles])

 


  const clickHandler = () => {
    setFiles([])
    setdata((prev) => ({ ...prev, attachment: "" }))
    setNotificationImage()
  }

  // );
  console.log('swalClose :>> ', swalClose);


  return (
    <section className="container">
      {ImageData?.attachment  || notificationImage &&
        <CloseButton
          className="upload-close-btn mb-4 position-absolute"
          // value={item.url}
          name="deletebanner"
          onClick={

            clickHandler


          }
        />
      }

      <div {...getRootProps()} className="dropzone dz-clickable image-upload">

        <input {...getInputProps()} required />
        {(files.length === 0 || noImage) && (
          <div className="dz-message">
            <div className="dz-message-icon">
              <Icon size="lg" name={iconName} />
            </div>
            <div className="dz-message-btn">
              <Button size="md" variant="transparent">
                Upload
              </Button>

            </div>

            <small className="dz-message-text">
            Image must be JPG/JPEG or PNG
            (Recommended Aspect ratio 2:1)
            </small>
          </div>
        )}
        {files.length > 0 && files[0].type?.startsWith("image/")
          ? thumbs
          : files[0]?.name}
      </div>
    </section>
  );
}

export default FileUploadImageExtension

