import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../components";
import { useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import { useEffect } from "react";
function AddBatch() {
  let { _id } = useParams();
  let api = new AuthApi();
  let { userData, branchId } = useContext(Context);
  let navigate = useNavigate();

  const [data, setData] = useState({
    inst_id: JSON.parse(userData)._id,
    branch_id: branchId,
  });
  useEffect(() => {
    setData({ ...data, branch_id: branchId });
  }, [branchId]);

  useEffect(() => {
    if (_id) {
      get_batch_by_id();
    }
  }, [_id]);

  const get_batch_by_id = async () => {
    await api.get_batch_by_id(_id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "active") {
      setData({ ...data, [name]: !data.active });
    } else {
      if (name == "starttime" || name == "endtime") {
        if (value.length == 7 ||  value.length == 8) {
          setData({ ...data, [name]: value });
        }
        console.log("value.length",value.length)
      } else {
        setData({ ...data, [name]: value });
      }
    }
  };

  function isTimeGreaterThan(timeToCheck, referenceTime) {
    const timeFormat = "hh:mm A"; // Format of the time strings
    const timeToCheckParsed = Date.parse(
      `01/01/2023 ${timeToCheck}`,
      timeFormat
    );
    const referenceTimeParsed = Date.parse(
      `01/01/2023 ${referenceTime}`,
      timeFormat
    );

    return timeToCheckParsed > referenceTimeParsed;

  }
  const [timeFormat, setTimeFormat] = useState(true);
  useEffect(() => {
    console.log("data.starttime && data.endtime",data.starttime,data.endtime)
    if (data.starttime && data.endtime) {     
      const isGreaterThan = isTimeGreaterThan(data.starttime, data.endtime);
        setTimeFormat(isGreaterThan);
    }
  }, [data]);
  const handleSubmit = async (e) => {
    if (!data.branch_id) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select Branch",
      });
    } else {
      e.preventDefault();
      await api.add_batches(data).then((res) => {
        try {
          if (res.status) {
            console.log("res", res);
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res?.message,
            });
            navigate(-1);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  };
  const handleSubmitEditBatch = async (e) => {
    e.preventDefault();
    await api.edit_batch_by_id(data, _id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate("/view-batch");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const permission = [
    "MY Plan",
    "Student Overview",
    "Student Permission",
    "Setting",
    "Invoice",
    "Result",
    "Announcement",
    "Help",
  ];
  console.log("timeFormat", timeFormat);
  let course = ["PTE", "TOEFL", "CELPIP", "CD-IELTS"];
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              {_id ? "Edit Batch" : "Add Batch"}
            </Block.Title>
          </Block.HeadContent>
          {/* <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                    <Icon name="eye" />
                                    <span>View</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                    <Icon name="eye" />
                                    <span>View Products</span>
                                </Link>
                            </li>
                        </ul>
                    </Block.HeadContent> */}
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              {/* <h5 className="mb-3">Select Product</h5> */}
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label>Program</Form.Label>
                        <div className="form-control-wrap">
                          {console.log("data?.type", data?.type)}
                          <Select
                            removeItemButton
                            name="type"
                            value={data?.type}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="">Select Program</option>
                            {course.map((item) => {
                              return (
                                <>
                                  <option value={item}>{item}</option>
                                </>
                              );
                            })}{" "}
                          </Select>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">Batch Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="sub_type"
                            value={data?.sub_type}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="PTE_default"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="mobile">
                          Batch Start Time
                        </Form.Label>
                        <div className="form-control-wrap">
                          <TimePicker
                            required
                            format={12}
                            value={data?.starttime ? data?.starttime : ""}
                            onChange={(e) => handleChange(e)}
                            name="starttime"
                            placeholder="hh:mm"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="mobile">Batch End Time</Form.Label>
                        <div className="form-control-wrap">
                          <TimePicker
                            required
                            format={12}
                            value={data?.endtime ? data?.endtime : ""}
                            onChange={(e) => handleChange(e)}
                            name="endtime"
                            placeholder="hh:mm"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="3"></Col>

                <Col md="12" className="my-5">
                  {/* <Button
                      className="d-md-inline-flex fw-bold"
                      variant="outline-primary"
                    >
                      Update Team
                    </Button> */}
                  {_id ? (
                    <>
                      <Button
                        onClick={handleSubmitEditBatch}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleSubmit}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={
                          !data.type ||
                          !data.sub_type ||
                          timeFormat
                        }
                      >
                        Save
                      </Button>
                      {console.log("---------------", data)}
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default AddBatch;
