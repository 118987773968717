import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";

const EnquiryTabs = ({
  enquiriesData,
  enquiryColumns,
  tableData,
  showItemPerPage,
  setShowItemPerPage,
}) => {
 
  return (
    <div>
      <Tab.Container id="enquiry-tabs" defaultActiveKey="total-enq">
       
        <Tab.Content>
          <Tab.Pane eventKey="total-enq">
            <DataTable
              columns={enquiryColumns}
              data={tableData}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination={true}
              //   expandableRows={mobileView}
              paginationComponent={({
                rowsPerPage,
                rowCount,
                onChangePage,
                onChangeRowsPerPage,
                currentPage,
              }) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setShowItemPerPage={setShowItemPerPage}
                  />
                </div>
              )}
            />
          </Tab.Pane>
          
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default EnquiryTabs;
