import { Link } from "react-router-dom";
import { useContext } from "react";
import Context from "../../context";
import { Image } from "../../components";
function Logo() {
  const { domainData,localHost  } = useContext(Context);
  let data = JSON?.parse(domainData)

  let name = window.location.hostname.split(".")[0];
  let instituteLogo = data
    ?.inst_profile?.business_logo;

    var firstChar = name?.charAt(0)?.toUpperCase()
  return (
    <>
      {name == "app" && (
        <Link to="/" className="logo-link">
          <div className="logo-wrap">
            <img
              src="/images/hilingo-logo.png"
              alt="logo"
              className="logo-img"
            />
          </div>
        </Link>
      )}
      {(name == "admin" || ( localHost =="admin" && name == "localhost" )) && (
      // {name == "admin"   && (
        <Link to="/" className="logo-link">
          <div className="logo-wrap">
            <img
              src="/images/hilingo-logo.png"
              alt="logo"
              className="logo-img"
            />
          </div>
        </Link>
      )}
      {name != "admin" && name != "app" &&  (<>
        {instituteLogo &&
             <Link to="/" className="logo-link">
             <div className="logo-wrap">
               <img
                 src={instituteLogo}
                 alt="logo"
                 className="logo-img"
               />
             </div>
           </Link>
        }
           {name != "admin" && name != "app" && (name != "localhost") && !instituteLogo &&
             <Link to="/" className="logo-link">
             <div className="logo-wrap">
             <p className="no-logo">{firstChar}</p>
               {/* <img
                 alt="No logo"
                 className="logo-img"
               /> */}
             </div>
           </Link>
        }
        </>
      )}
    </>
  );
}

export default Logo;
