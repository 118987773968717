import React, { useState, useEffect, useContext } from "react";
import countryList1 from "../country";
import { Col, Card, Button, Alert } from "react-bootstrap";
import { Form, Row } from "react-bootstrap";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import AuthApi from "../api/auth";
import Layout from "../layout/fullpage";
import DatePicker from "../components/Form/DatePicker";
import Swal from "sweetalert2/src/sweetalert2.js";
import Swal2 from 'sweetalert2';
import { City, Country, State } from "country-state-city";

import AlertDismissible from "../components/UiElements/alert/AlertDismissible";
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
  Icon,
} from "../components";
import ImageSlider from "./ImageSlider";
import styled from "styled-components";
import TimePicker from "../components/Form/TimePicker";
import Context from "../context";
import PhoneFlag from "../components/Form/PhoneFlag";
import ColorPicker from "../components/Form/ColorPicker";

function ContactInformation() {
  const [countryNumber, setCountryNumber] = useState('91');
  const [error, setError] = useState('');
  let { id } = useParams();
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  let navigate = useNavigate();


  useEffect(() => {
    
    document.documentElement.style.setProperty('--theme-color',"#1682FB");
 

},[])


  let api = new AuthApi();
  const [contactPage, setContactPage] = useState(true);
  const [countryList, setCountryList] = useState(countryList1);
  const [formData, setFormData] = useState({
    login_type: 1,
    roleId: "64b26bf0a4437fc38fcb3374",
    inst_profile: {
      inst_contact_method: "Call/SMS/Email",
      inst_contact_findout: "Social Media Platform",
      inst_client_service: "IELTS/TOFEL Coaching",
      inst_number_of_student: "1 - 10",
      color_code: "#000000",
     inst_business_country:"India",

    },
    permission_routes: ["all"],
    banner_images: [],
  });

  console.log("banner_images", formData);
  let { business_certificate, inst_profile } = formData;
  useEffect(() => {
    if (!id) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setFormData((prevData) => ({
              ...prevData,
              ["inst_profile"]: {
                ...formData.inst_profile,
                ["latitude"]: position.coords.latitude.toString(),
                ["longitude"]: position.coords.longitude.toString(),
              },
            }));
          },
          (error) => {
            console.error(error);
          }
        );
      }
      else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else {
      getInstituteDetail();
    }
  }, []);

  const [validated, setValidated] = useState(false);
  const [countStep, setCountStep] = useState(1);
  const [fileName, setFileName] = useState({
    fileData: {
      business_certificate: "",

      logo: "",
    },
    banner_images: [],
  });

  const { fileData, banner_images: file_banner_image } = fileName
  const [prevUploadedFileName, setPrevUploadedFileName] = useState(false)
  const [colorPickError, setColorPickError] = useState("")
  const { inst_contact_firstname } = formData?.inst_profile
  const { banner_images } = formData
  console.log('error?.inst_contact_firstname :>> ', inst_contact_firstname);
  
  console.log('colorPickError :>> ', colorPickError);
  const validateForm = () => {
    let newError = {}
    if (!inst_contact_firstname || inst_contact_firstname === '') {
      newError.inst_contact_firstname = "Please enter Owner's First Name"
    }
    if (banner_images?.length == 0) {
      newError.banner_images = "At least One is required."

    }
    if (!business_certificate || business_certificate === '') {
      newError.business_certificate = "This is required."

    }
   
    return newError
  }


  const nextStep = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    const fromError = validateForm();
    console.log('fromError :>> ', fromError);
    if (Object.keys(fromError).length > 0) {
      setError(fromError)
    }

    // Check if all required fields are filled
    const requiredFields = form.querySelectorAll("[required]");
    let allFieldsFilled = true;

    requiredFields.forEach((field) => {
      if (!field.value.trim()) {
        allFieldsFilled = false;
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
      }
    });

    // Check if email fields have valid email format
    const emailFields = form.querySelectorAll('input[type="email"]');
    let allEmailsValid = true;

    emailFields.forEach((field) => {
      if (!field.checkValidity()) {
        allEmailsValid = false;
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
        field.classList.add("is-valid");
      }
    });

    // Check if mobile number fields have valid format (e.g., 10 digits)
    const mobileNumberFields = form.querySelectorAll('input[type="tel"]');
    let allMobileNumbersValid = true;

    mobileNumberFields.forEach((field) => {
      const mobileNumberRegex = /^\d{10}$/;
      if (!mobileNumberRegex.test(field.value.trim())) {
        allMobileNumbersValid = false;
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
        field.classList.add("is-valid");
      }
    });

    if (allFieldsFilled && allEmailsValid && allMobileNumbersValid && colorPickError == "") {
      let { business_certificate, email, password, mobile } = formData;

      console.log('formData :>> ', formData);
      if (
        business_certificate &&
        formData?.inst_profile?.inst_contact_firstname &&
        formData?.inst_profile?.inst_contact_lastname &&
        email &&
        password &&
        mobile &&
        formData?.inst_profile?.inst_contact_method &&
        formData?.inst_profile?.inst_contact_findout &&
        // formData?.inst_profile?.inst_contact_recuriting_students_year &&
        formData?.inst_profile?.inst_contact_source_country_student &&
        formData?.inst_profile?.inst_client_service
      ) {
        setContactPage(!contactPage);
        setCountStep(countStep + 1);
        setValidated(false);
      }
      // Perform form submission logic here (if needed)
      // form.submit();
    } else {
      setValidated(false);
    }
  };
  console.log("formData", formData);
  const prevStep = () => {
    setContactPage(!contactPage);
    setCountStep(countStep - 1);
    setPrevUploadedFileName(true)
  };
  let formDataObject = new FormData();

  
  const handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };
  const handleChange = (e, inst_profile, index) => {
    let { value, name } = e.target;
    console.log('value,name :>> ', value, name);
    if (name == "inst_business_name") {
      setFormData((prevData) => ({
        ...prevData,
        ["name"]: value?.replace(/\s+/g, " ").trim(),
        ["domain_name"]: value?.replace(/\s+/g, " ").trim(),
      }));
    }
    if (!!error[name]) {
      setError({ ...error, [name]: null })
    }

    if (inst_profile) {
      if (name == "business_logo") {
        setFormData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...formData.inst_profile,
            [name]: null,
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...formData.inst_profile,
            [name]: value?.replace(/\s+/g, " ").trim(),
          },
        }));
      }
    } else {
      if (name == "logo" || name == "business_certificate") {

        if (isValidFileUploaded(e.target.files[0])) {
          setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
          setFileName({ ...fileName, [name]: e.target.files[0].name })
          // if(name== "banner_images" ){
          //   setFileName((prev)=>({...prev,prev.banner_images}))
          // }
          setPrevUploadedFileName(false)
        }
        else {

          // Clear the file input
          e.target.value = null;
          setFormData((prevData) => ({ ...prevData, [name]: "" }));


          Swal2.fire({
            icon: "error",
            title: "Oops",

            text: "Image must be JPG/JPEG or PNG",
          })
        }

      } else {
        if (name == "banner_images") {
          if (isValidFileUploaded(e.target.files[0])) {
            let tempArray = [...formData?.banner_images];
            tempArray[index] = e.target.files[0];
            setFormData((prevData) => ({ ...prevData, [name]: tempArray }));
          }
          else {

            // Clear the file input
            e.target.value = null;
            setFormData((prevData) => ({ ...prevData, [name]: "" }));


            Swal2.fire({
              icon: "error",
              title: "Oops",

              text: "Image must be JPG/JPEG or PNG",
            })
          }
        } else {
          if (name == "credit") {
            setFormData((prevData) => ({
              ...prevData,
              [name]: Number(value),
            }))
          } else {
            setFormData((prevData) => ({
              ...prevData,
              [name]: value?.replace(/\s+/g, " ").trim(),
            }));
          }
        }
      }
      if (name == "mobile") {

       
        setFormData((prevData) => ({ ...prevData, [name]: value, country_code: countryNumber }));
       
      }
    }
  };
  function removeElementByIndex(arr, index) {
    if (index >= 0 && index < arr.length) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)];
    } else {
      console.error("Index out of range");
      return arr;
    }
  }
  const submitForm = async (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(formData)) {
      console.log('key,value :>> ', key, value);
      if (key === "banner_images") {
        for (const imageFile of value) {
          console.log('imageFile :>> ', imageFile);
          formDataObject.append("banner_images", imageFile);
        }
      } else if (key === "business_certificate" || key === "logo") {
        formDataObject.append(key, value);
      } else {
        if (typeof value === "object" && value !== null) {
          if (key == "inst_profile") {
            formDataObject.append("inst_profile", JSON.stringify(value));
          }
        } else {
          formDataObject.append(key, value);
        }
      }
    }
    if (id) {
      await api.institute_update(formDataObject).then((res) => {
        try {
          if (res) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: "Institute update Successfully!",
            });
            // navigate("/home");
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      try {
        await api.institute_register(formDataObject).then((res) => {


          if (res?.status == false) {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: `${res?.msg}`,
            }).then((result) => {
              if (result.isConfirmed) {
                // Perform your onClick handler functionality here
                setContactPage(!contactPage);
                setCountStep(countStep - 1);
              }
            });
          }
          else if (res?.status == true) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: "Institute Register Successfully!",
            });
            navigate("/home");
          }
          else {

            formDataObject = new FormData()
          }
        })
      }
      catch (err) {


        console.log("ABC", err);
      }
    }

  };

  const getInstituteDetail = async () => {
    await api.get_user_by_id(id).then((res) => {
      if (res.status) {
        setFormData({ ...res?.data, banner_images: [] });
      }
    });
  };
  console.log("position.coords.longitude", formData);

  // const totalSteps = steps.length;

  // const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
  console.log('contactPage :>> ', contactPage);
  console.log('fileName :>> ', fileName, countStep, prevUploadedFileName);


  const isValidFileUploaded = (file) => {
    const validExtensions = ['png', 'jpeg', 'jpg']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
  }


  const [country, setCountry] = useState(
    {
        "name": "India",
        "isoCode": "IN",
        "flag": "🇮🇳",
        "phonecode": "91",
        "currency": "INR",
        "latitude": "20.00000000",
        "longitude": "77.00000000",
        "timezones": [
            {
                "zoneName": "Asia/Kolkata",
                "gmtOffset": 19800,
                "gmtOffsetName": "UTC+05:30",
                "abbreviation": "IST",
                "tzName": "Indian Standard Time"
            }
        ]
    });

  const [citySingle, setCitySingle] = useState({});
  const [stateData, setStateData] = useState([]);

  const [state, setState] = useState({
    "name": "Punjab",
    "isoCode": "PB",
    "countryCode": "IN",
    "latitude": "31.14713050",
    "longitude": "75.34121790"
});
  const [cityData, setCityData] = useState([]);

  let countryData = Country.getAllCountries();


  useEffect(() => {
    if (country) {
      setStateData(State.getStatesOfCountry(country.isoCode));
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      console.log('uinside state :>> ');
   
      setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode))
   
    }
  }, [state]);




  useEffect(() => {
    if (stateData.length > 0) {
      setState(stateData[0]);
    } else {
      setState(null);
    }
  }, [stateData]);

  const handleCountryChange = (e) => {
    const selectedCountry = countryData.find(
      (c) => c.isoCode === e.target.value
    );
    setCountry(selectedCountry);
    setFormData((prev) => ({
      ...prev,
      inst_profile: {
        ...prev.inst_profile,
        inst_business_country: selectedCountry?.name,
      },
    }));
  }

  const handleCityChange = (e) => {
    const selectedCity = cityData.find((s) => s.name === e.target.value);
    setCitySingle(selectedCity);
    //   setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:state?.name}})) // not instant update
    // const selectedCountry = countryData.find(c => c.isoCode === e.target.value);
    // setCountry(selectedCountry);
    setFormData((prev) => ({
      ...prev,
      inst_profile: {
        ...prev.inst_profile,
        inst_business_city: selectedCity?.name,
      },
    }));
  };


  const handleStateChange = (e) => {
    const selectedState = stateData.find((s) => s.isoCode === e.target.value);
    // setStateSingle(selectedState);
    setState(selectedState);
    //   setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:state?.name}})) // not instant update
    // const selectedCountry = countryData.find(c => c.isoCode === e.target.value);
    // setCountry(selectedCountry);
    setFormData((prev) => ({
      ...prev,
      inst_profile: {
        ...prev.inst_profile,
        inst_business_state: selectedState?.name,
      },
    }));
  };


  console.log(error, "errorw")
  return (
    <Layout title="Register" centered>
      <div className="container p-2 p-sm-4">
        <Row className="g-2 justify-content-center">
          <Col md="6" xs="12">
            <div className="nk-block-head text-center">
              <div className="nk-block-head-content">
                <Image
                  src="/images/hilingo-logo.png"
                  alt="logo"
                  className="my-4 logo-img"
                />
              </div>
            </div>
            <ul className="list-unstyled multi-steps mb-5">
              <li
                id="step-1"
                className={countStep === 1 ? "is-active" : "step"}
              >
                <div className="hiprogress-bar hiprogress-bar--primary">
                  <div className="hiprogress-bar__bar"></div>
                </div>
                Contact Information
              </li>
              <li
                id="step-2"
                className={countStep === 8 ? "is-active" : "step"}
              >
                Business Information
              </li>
            </ul>
            {contactPage ? (
              <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                <Card.Body>
                  <Form noValidate validated={validated} onSubmit={nextStep}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <AlertDismissible variant="primary">
                          <p>
                            <Icon
                              name="alert-circle"
                              className="me-1 mt-1"
                            ></Icon>
                            Please ensure the owner of the organization
                            completes this form and that the information below
                            matches their business registration document.
                          </p>
                        </AlertDismissible>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label
                            htmlFor="businesscertificate"
                            onChange={(e) => handleChange(e)}
                            name="business_certificate"

                          >
                            Business Certificate
                          </Form.Label>
                          <div className="form-control-wrap">
                            {/* <Form.Control
                              required
                              accept=".pdf"
                              type="file"
                              label={business_certificate?.name}
                              onChange={(e) => handleChange(e)}
                              name="business_certificate"
                              
                            
                            /> */}


                            <Form.Group as={Row}>
                              <Form.Control
                                type="file"

                                id="business_certificate"


                                onChange={(e) => handleChange(e)}
                                name="business_certificate"
                                // accept=".PNG"
                                isInvalid={!!error?.business_certificate}


                              />
                            </Form.Group>
                            {
                              fileName?.business_certificate && (
                                <p >{fileName?.business_certificate}</p>
                              )}



                            <Form.Control.Feedback type="invalid">
                              {error?.business_certificate}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      {userData?.login_type === "0" && <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              type="number"
                              id="lastname"
                              value={formData?.credit}
                              onChange={(e) => handleChange(e)}
                              name="credit"
                            />
                            <Form.Label htmlFor="lastname">
                              Institute's credit
                            </Form.Label>
                          </div>
                        </Form.Group>
                      </Col>}
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label
                            htmlFor="businesslogo"
                            name="logo"
                            onChange={(e) => handleChange(e)}
                          // accept=".jpeg .jpg .png"
                          >
                            Business Logo (optional)
                          </Form.Label>
                          <div className="form-control-wrap">
                            {inst_profile?.business_logo ? (
                              <>
                                <img src={inst_profile?.business_logo} width="150px"
                                  height="100px" />
                                <button
                                  name="business_logo"
                                  style={{ width: "40px", height: "27px", marginLeft: "-33px" }}
                                  onClick={(e) =>
                                    handleChange(e, "inst_profile", null)
                                  }
                                >
                                  X
                                </button>
                              </>
                            ) : (
                              <Form.Control
                                type="file"
                                onChange={(e) => handleChange(e)}
                                name="logo"
                              // accept=".jpeg .jpg .png"
                              />
                            )}
                          </div>
                        </Form.Group>

                        {
                          fileName?.logo && (
                            <p >{fileName?.logo}</p>
                          )}
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="banner_images">
                            Select Banner Image's
                          </Form.Label>
                          <div className="form-control-wrap d-flex gap-2">
                            {inst_profile?.banner_images?.length &&
                              inst_profile?.banner_images[0].url ? (
                              <>
                                <img
                                  src={inst_profile?.banner_images[0].url}
                                  width="150px"
                                  height="100px"
                                />
                                <button
                                  name="banner_images"
                                  style={{ width: "40px", height: "27px", marginLeft: "-33px" }}
                                  onClick={() => {
                                    const newArr = removeElementByIndex(inst_profile?.banner_images, 0)
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      ["inst_profile"]: {
                                        ...formData.inst_profile,
                                        ["banner_images"]: newArr,
                                      },
                                    }))
                                  }}
                                >
                                  X
                                </button>
                              </>
                            ) : (
                              <Form.Control
                                // required={(formData?.banner_images?.length || inst_profile?.banner_images?.length) ?false:true}
                                isInvalid={!!error?.banner_images}
                                type="file"
                                onChange={(e) => handleChange(e, null, 0)}
                                name="banner_images"
                              // accept=".jpeg .jpg .png"
                              />
                            )}
                            {inst_profile?.banner_images?.length > 1 &&
                              inst_profile?.banner_images[1]?.url ? (
                              <>
                                <img
                                  src={inst_profile?.banner_images[1]?.url}
                                  width="150px"
                                  height="100px"
                                />
                                <button
                                  name="banner_images"
                                  style={{ width: "40px", height: "27px", marginLeft: "-33px" }}
                                  onClick={() => {
                                    const newArr = removeElementByIndex(inst_profile?.banner_images, 1)
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      ["inst_profile"]: {
                                        ...formData.inst_profile,
                                        ["banner_images"]: newArr,
                                      },
                                    }))
                                  }}
                                >
                                  X
                                </button>
                              </>
                            ) : (
                              <Form.Control
                                // required={(formData?.banner_images?.length || inst_profile?.banner_images?.length) ?false:true}
                                isInvalid={!!error?.banner_images}
                                type="file"
                                onChange={(e) => handleChange(e, null, 1)}
                                name="banner_images"
                              // accept=".jpeg .jpg .png"
                              />
                            )}
                            {inst_profile?.banner_images?.length > 2 &&
                              inst_profile?.banner_images[2]?.url ? (
                              <>
                                <img
                                  src={inst_profile?.banner_images[2]?.url}
                                  width="150px"
                                  height="100px"
                                />
                                <button
                                  name="banner_images"
                                  style={{ width: "40px", height: "27px", marginLeft: "-33px" }}
                                  onClick={() => {
                                    const newArr = removeElementByIndex(inst_profile?.banner_images, 2)
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      ["inst_profile"]: {
                                        ...formData.inst_profile,
                                        ["banner_images"]: newArr,
                                      },
                                    }))
                                  }}
                                >
                                  X
                                </button>
                              </>
                            ) : (
                              <Form.Control
                                type="file"
                                // required={(formData?.banner_images?.length || inst_profile?.banner_images?.length) ?false:true} 
                                onChange={(e) => handleChange(e, null, 2)}
                                name="banner_images"
                                isInvalid={!!error?.banner_images}
                              // accept=".jpeg .jpg .png"
                              />
                            )}
                            {inst_profile?.banner_images?.length > 3 &&
                              inst_profile?.banner_images[3]?.url ? (
                              <>
                                <img
                                  src={inst_profile?.banner_images[2]?.url}
                                  width="150px"
                                  height="100px"
                                />
                                <button
                                  name="banner_images"
                                  style={{ width: "40px", height: "27px", marginLeft: "-33px" }}
                                  onClick={() => {
                                    const newArr = removeElementByIndex(inst_profile?.banner_images, 2)
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      ["inst_profile"]: {
                                        ...formData.inst_profile,
                                        ["banner_images"]: newArr,
                                      },
                                    }))
                                  }}
                                >
                                  X
                                </button>
                              </>
                            ) : (
                              <Form.Control
                                // required={(formData?.banner_images?.length || inst_profile?.banner_images?.length) ?false:true}  
                                // required={(formData?.banner_images?.length ==0 ) ? true:false}  
                                type="file"
                                onChange={(e) => handleChange(e, null, 3)}
                                name="banner_images"
                                // isInvalid={!!error?.banner_images}
                                isInvalid={!!error?.banner_images}
                              // accept=".jpeg .jpg .png"
                              />
                            )}
                            <Form.Control.Feedback type="invalid">
                              {/* At least One is required.
                               */}


                              {error?.banner_images}


                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              required
                              type="text"
                              id="lastname"
                              value={inst_profile?.inst_contact_firstname}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              // onChange={(e) => handleChange(e, "inst_contact_firstname")}

                              name="inst_contact_firstname"
                            // isInvalid={!!error?.inst_contact_firstname}
                            />
                            <Form.Label htmlFor="lastname">
                              Owner's First Name
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              This is required.
                              {/* {error?.inst_contact_firstname} */}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              required
                              type="text"
                              id="lastname"
                              value={inst_profile?.inst_contact_lastname}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_contact_lastname"
                              placeholder="Owner's Last Name"
                            />
                            <Form.Label htmlFor="lastname">
                              Owner's Last Name
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="lastname">
                            Select Color
                          </Form.Label>
                          <div className="form-control-wrap">
                           
                          <ColorPicker setData={setFormData} data={formData} colorPickError={colorPickError}
                          setColorPickError={setColorPickError} />
                            {/* <Form.Control
                              type="color"
                              name="color_code"
                              value={inst_profile?.color_code}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              className="ms-0"
                            /> */}
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              required
                              type="email"
                              id="emailid"
                              value={formData?.email}
                              onChange={(e) => handleChange(e)}
                              name="email"
                              placeholder="Email Id"
                            />
                            <Form.Label htmlFor="emailid">Email Id</Form.Label>
                            <Form.Control.Feedback type="invalid">
                              This is not a valid Email .
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      {!id && (
                        <Col className="col-12">
                          <Form.Group className="form-group">
                            <div className="form-control-wrap form-item">
                              <Form.Control
                                required
                                type="password"
                                id="password"
                                onChange={(e) => handleChange(e)}
                                name="password"
                                placeholder="Password"
                              />
                              <Form.Label htmlFor="password">
                                Password
                              </Form.Label>
                              <Form.Control.Feedback type="invalid">
                                This is required.
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                      )}

                      <div className="d-flex gap-1">
                        <div className="w-40">
                          <PhoneFlag setCountryNumber={setCountryNumber} />
                        </div>
                        <div className="w-60">
                          <Form.Group className="form-group">
                            <div className="form-control-wrap form-item">
                              <Form.Control
                                required
                                type="tel"
                                id="phonenumber"
                                maxLength={10}
                                onInput={handlePhoneInput}
                                value={
                                  formData?.mobile?.length < 11
                                    ? formData?.mobile
                                    : ""
                                }
                                onChange={(e) => handleChange(e)}
                                name="mobile"
                           
                              />
                              <Form.Label htmlFor="phonenumber">
                                Phone Number
                              </Form.Label>
                              <Form.Control.Feedback type="invalid">
                                Phone Number Should be 10 digit.
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="dateyear">
                            Institute Open Time
                          </Form.Label>

                          <div className="form-control-wrap">
                            <TimePicker
                              required
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_open_time"
                              format={12}
                              value={
                                inst_profile?.inst_open_time
                                  ? inst_profile?.inst_open_time
                                  : ""
                              }
                              placeholder="hh:mm"
                            />
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="dateyear">
                            Institute Close Time
                          </Form.Label>

                          <div className="form-control-wrap">
                            <TimePicker
                              required
                              format={12}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              value={
                                inst_profile?.inst_close_time
                                  ? inst_profile?.inst_close_time
                                  : ""
                              }
                              name="inst_close_time"
                              placeholder="hh:mm"
                            />
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="dateyear">
                            Break Start Time
                          </Form.Label>

                          <div className="form-control-wrap">
                            <TimePicker
                              required
                              format={12}
                              value={
                                inst_profile?.inst_break_start_time
                                  ? inst_profile?.inst_break_start_time
                                  : ""
                              }
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_break_start_time"
                              placeholder="hh:mm"
                            />
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="dateyear">
                            Break End Time
                          </Form.Label>

                          <div className="form-control-wrap">
                            <TimePicker
                              required
                              format={12}
                              value={
                                inst_profile?.inst_break_end_time
                                  ? inst_profile?.inst_break_end_time
                                  : ""
                              }
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_break_end_time"
                              placeholder="hh:mm"
                            />
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="dateyear">Country</Form.Label>

                          <div className="form-control-wrap">
                            <Form.Select
                              required
                              id="country"
                              value={
                                inst_profile?.inst_contact_source_country_student
                              }
                              aria-label="Default select example"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_contact_source_country_student"
                            >
                              <option></option>
                              {countryList.length > 0 &&
                                countryList.map((item) => {
                                  return (
                                    <>
                                      <option value={item.text}>
                                        {item.text}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              This is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="studentnum">
                            Number of Students
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              size="lg"
                              value={inst_profile?.inst_number_of_student}
                              name="inst_number_of_student"
                              onChange={(e) => handleChange(e, "inst_profile")}
                            >
                              <option value="1 - 10">1 - 10</option>
                              <option value="10 - 25">10 - 25</option>
                              <option value="25 - 50">25 - 50</option>
                              <option value="50 - 100">50 - 100</option>
                              <option value="100+">100+</option>
                            </Form.Select>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              required
                              type="text"
                              id="website"
                              value={inst_profile?.inst_business_url}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_url"
                            />
                            <Form.Label htmlFor="website">
                              Website Url
                            </Form.Label>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              required
                              type="text"
                              id="facebook"
                              value={inst_profile?.inst_business_facebook}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_facebook"
                            />
                            <Form.Label htmlFor="facebook">
                              Facebook Page
                            </Form.Label>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          {/* <Form.Label htmlFor="message">Message</Form.Label> */}
                          <div className="form-control-wrap">
                            <Form.Control
                              as="textarea"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="msg"
                              value={inst_profile?.msg}
                              placeholder="message"
                              id="message"
                              rows="3"
                            ></Form.Control>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-7 mx-auto">
                        <div className="d-grid">
                          <Button type="">Next</Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            ) : (
              <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                <Card.Body>
                  <Form action="#" onSubmit={(e) => submitForm(e)}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="businessname">
                            Business Name
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="businessname"
                              value={inst_profile?.inst_business_name}
                              placeholder="Business Name"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_name"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                      <Form.Group className="form-group">
                                <Form.Label> Select Country</Form.Label>
                                <div className="form-control-wrap">
                                  <Form.Select
                                    name="inst_business_country"
                                    value={country?.isoCode || ""}
                                    onChange={handleCountryChange}
                                  >
                                    {countryData.map((country) => (
                                      <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="state">
                            Select State/Province
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                                        name="inst_business_state"
                                        value={state?.isoCode || ""}
                                        onChange={handleStateChange}
                                      >
                                        {stateData.map((state) => (
                                          <option
                                            key={state.isoCode}
                                            value={state.isoCode}
                                          >
                                            {state.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="city">Select City</Form.Label>
                          <div className="form-control-wrap">
                          <Form.Select
                                    name="inst_business_city"
                                    value={citySingle?.name || ""}
                                    onChange={handleCityChange}
                                  >
                                    {cityData.map((city) => (
                                      <option key={city.name} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="address">
                            Street Address
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="address"
                              value={inst_profile?.inst_business_address}
                              placeholder="Street Address"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_address"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    
                   
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="pincode">Postal Code</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="number"
                              id="pincode"
                              value={inst_profile?.inst_business_postal_code}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_postal_code"
                              placeholder="Postal Code"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="instagram"
                              placeholder="Instagram"
                              value={inst_profile?.inst_business_instagram}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_instagram"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="linkedin"
                              value={inst_profile?.inst_business_linkedin}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_linkedin"
                              placeholder="Linkedin"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              type="text"
                              id="twitter"
                              value={inst_profile?.inst_business_linkedin}
                              placeholder="Twitter"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12">
                        <Form.Check
                          className="form-check-sm"
                          type="checkbox"
                          id="agreeCheckbox"
                          label="Accept Terms and Conditions"
                        />
                      </Col>
                      <Col className="col-6 mx-auto">
                        <div className="d-flex justify-content-between">
                          <Button variant="outline-primary" onClick={prevStep}>
                            Previous
                          </Button>
                          {id ? <Button type="submit">Update</Button> :
                            <Button type="submit">Submit</Button>}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            )}{" "}
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ContactInformation;
