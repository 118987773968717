import React from 'react'
import DataTable from 'react-data-table-component'
import DataTablePagination from '../../components/Pagination/DataTablePagination'

const BatchDataTable = ({BatchColumns,data,setShowItemPerPage,showItemPerPage}) => {
  


  console.log('BatchColumnsdata :>> ', data,BatchColumns);
  return (
    <div>
      	<DataTable

		columns={BatchColumns}
		data={data}
    

		pagination
        paginationComponent={({
            rowsPerPage,
            rowCount,
            onChangePage,
            onChangeRowsPerPage,
            currentPage,
          }) => (
            <div className="data-table-bottom">
              <DataTablePagination
                showItemPerPage={showItemPerPage}
                itemPerPage={rowsPerPage}
                totalItems={rowCount}
                paginate={onChangePage}
                currentPage={currentPage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                setShowItemPerPage={setShowItemPerPage}

              />
            </div>
          )}
	/>
    </div>
  )
}

export default BatchDataTable
