import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Tooltip, OverlayTrigger, Tab, Nav } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AuthApi from "../../api/auth";
import { Icon, LinkList, LinkListItem } from "../../components";
import Block from "../../components/Block/Block";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import Context from "../../context";
import Layout from "../../layout/default";
import AddEnquiryModal from "../studentpages/AddEnquiryModal";
import EnquiryFilter from "./EnquiryFilter";
import Export from "./Export";

import { Button } from "react-bootstrap";
import UpdateEnquiryModal from "../studentpages/UpdateEnquiryModal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AddFollowups from "../followups/AddFollowups";
import EnquiryTabs from "./EnquiryTabs";
import QrCode from "../../components/QrCode/QrCode";

import QrCodeNew from "../../components/QrCode/QrCodeNew";



function EnquiryDataTable() {
  let name = window.location.hostname.split(".")[0];
  let api = new AuthApi();
  const [teamMember, setTeamMember] = useState([]);
  const [enquiriesData, setEnquiriesData] = useState([]);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);


  const [showQr, setShowQr] = useState(false);
  const handleShowQr = () => setShowQr(true);

  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const [enquiry, setEnquiry] = useState({});
  // const [enquiryFollowUp, setEnquiryFollowUp] = useState({});
  const [editFollowupId, setEditFollowupId] = useState("");

  const [isFollowup, setIsFollowup] = useState(false);
  // const []

  console.log(isEdit, "id211333");
  const editHandler = (id) => {
    console.log(id, "id211iddddd");
    setIsEdit(true);
    setEditId(id);
  };

  const followupHandler = (id) => {
    console.log(id, "id211iddddd");
    setIsFollowup(true);
    setEditFollowupId(id);
  };

  const enrollHandler = (email, phone, name) => {
    // localStorage.setItem("studentFromFollowup", JSON.stringify({email,phone}))
    navigate(`/student-management/add-student?email=${email}&mobile=${phone}&name=${name}`);
  };

  const get_Single_enquiry = async (id) => {
    await api
      .get_Single_enquiry(id)
      .then((res) => {
        if (res?.data) {
          setEnquiry(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const get_Single_enquiryFollowup = async (id) => {
  //   await api
  //     .get_Single_enquiry(id)
  //     .then((res) => {
  //       if (res?.data) {
  //         setEnquiryFollowUp(res?.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  console.log(editId, "editId..");
  useEffect(() => {
    if (editId) {
      get_Single_enquiry(editId);
    }

    // if (editFollowupId) {
    //   get_Single_enquiryFollowup(editFollowupId);
    // }
  }, [editId]);

  const deleteHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        setDeleteId(id);
      }
    })
  };

  const enquiryColumns = [
    //   {name: "SR No.",
    //   cell: (row, index) => <span className="text-middark">{index + 1}</span>,
    //   sortable: false,
    // },
    {
      name: "Enquiry Date",
      selector: (row) => row.enqdate,
      cell: (row) => (
        <span className="text-middark">
          {new Date(row?.createdAt).toISOString().split("T")[0]}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Student Name",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row?.name ? row?.name : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phno,
      cell: (row) => (
        <span className="text-middark">{row?.phone ? row?.phone : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Email Id",
      selector: (row) => row.emailid,
      cell: (row) => (
        <span className="text-middark">{row?.email ? row?.email : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Team Member",
      selector: (row) => row.action,
      cell: (row) => (
        <span className="text-middark">
          {row?.teamMember ? row?.teamMember : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Next Followup Date",
      selector: (row) => row.nextdate,
      cell: (row) => (
        <span className="text-middark">
          {row?.nextFollowUpDate ? row?.nextFollowUpDate : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Enquiry Type",
      selector: (row) => row.action,
      cell: (row) => (
        <span className="text-middark">
          {row?.program ? row?.program?.toUpperCase() : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="view_enquiry">View Enquiry</Tooltip>}
            >
              <div className="del-btn" onClick={() => editHandler(row?._id)}>
                {/* <Icon name="edit"></Icon> */}
                <Icon name="eye"></Icon>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="follow_up">Follow Up</Tooltip>}
            >
              <div
                className="del-btn"
                onClick={() => followupHandler(row?._id)}
              >
                {/* <Icon name="user-add"></Icon> */}
                <Icon name="user-add"></Icon>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="enroll">Enroll</Tooltip>}
            >
              <div className="del-btn" onClick={() => enrollHandler(row?.email, row?.phone, row?.name)}>
                <Icon name="user-alt"></Icon>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="delete">Delete</Tooltip>}
            >
              <div className="del-btn" onClick={() => deleteHandler(row?._id)}>
                {/* <Icon name="trash"></Icon> */}
                <Icon name="trash"></Icon>
              </div>
            </OverlayTrigger>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];

  const get_enquiriesArr = async () => {
    await api
      .get_enquiriesByInst(userData?._id)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "bn enquiry");
          setEnquiriesData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    get_enquiriesArr();
  }, []);

  const delete_Single_enquiry = async (id) => {
    await api
      .delete_Single_enquiry(id)
      .then((res) => {
        if (res?.msg) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: `${res?.msg}`,
          });
          get_enquiriesArr();

          navigate("/lead-management/enquiry-list");

          // }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("useEffect working", editId);
  };

  useEffect(() => {
    if (deleteId) {
      delete_Single_enquiry(deleteId);
    }
  }, [deleteId]);

  const [tableData, setTableData] = useState(enquiriesData);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  const [institutePlan, setInstitutePlan] = useState({})

  console.log("INSTITUTE",institutePlan);

  useEffect(() => {
    setTableData(enquiriesData);
  }, [enquiriesData]);
  useEffect(() => {
    const filteredData = enquiriesData.filter((item) =>
      item?.email?.toLowerCase().includes(searchText?.toLowerCase())
    );
    if (filteredData.length) {
      setTableData(filteredData);
    }
  }, [searchText]);
  console.log("tableData", tableData);
  console.log("tableData22", enquiriesData);
  let { userData, token,branchId } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData, "userData11");
  console.log(token, "userData12");
  const getTeamByInstituteId = async (instId) => {
    console.log("instid", instId, token);
    await api
      .get_teachers_By_Inst({ id: instId, token })
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setTeamMember(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInsitutePlan = async (instId) => {
    await api
      .get_plans_by_inst(instId)
      .then((res) => {
        if (res.status) {
          setInstitutePlan(res.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userData?._id && token){
      getTeamByInstituteId(userData?._id);
    } 
    getInsitutePlan(userData?._id);
  }, []);



  const teachers = teamMember?.map((item) => item?.name.toLowerCase());
  console.log(Object.keys(enquiry).length, "Object.keys(enquiry).length ");

  console.log(isFollowup, "isFollowup");

  console.log("tableData :>> ", enquiriesData?.length);

  console.log('branchId :>> ',localStorage.getItem("@#branch_id"),name);

  

  const[image,setImage]=useState()



  let { domainData } = useContext(Context);
  domainData = JSON.parse(domainData);
  console.log(domainData?.inst_profile?.business_logo, "domainData?.inst_profile?.business_logo");

  async function apiCall() {
    try {
      const response = await fetch(domainData?.inst_profile?.business_logo,{ mode: 'cors', cache: 'no-cache' });
      
      console.log('response :>> ', response);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const img = URL.createObjectURL(blob);
      setImage(img);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
  
    }
  }

useEffect(()=>{
  apiCall()
},[])


console.log(image,"image")

  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Enquiry Details</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-end gap-2">

                  {/* <QrCodeNew/> */}
                {showQr && image !=undefined && (
<QrCode showQr={showQr} setShowQr={setShowQr} image={image} link={`https://${name}.hilingo.com/enquiry-request/${branchId}`}/>
                      )}

                      {/* <img src={image} alt="img"/>212
                      <img src={domainData?.inst_profile?.business_logo} alt="img"/>212 */}
                    {institutePlan?.qr_code_enquiry_form && 
                    <Button
                        className="d-none d-md-inline-flex btn btn-primary "
                        variant="primary"
                        onClick={handleShowQr}
                        style={{backgroundColor:domainData?.inst_profile?.color_code}}
                        // onClick={()=>console.log(`https://${name}.hilingo.com/enquiry-request/${localStorage.getItem("@#branch_id")}`)}
                        
                      >
                        <span>Generate Enquriy Link</span>
                      
                      </Button>}

                      

                  

                      
{/* <QrCode link={`https://${name}.hilingo.com/enquiry-request/${localStorage.getItem("@#branch_id")}`}/> */}
                  <Export data1={enquiriesData} />

                  <ul className="d-flex justify-content-end">
                    <li>
                      <Button
                        className="d-none d-md-inline-flex btn btn-primary"
                        variant="primary"
                        // onClick={()=>navigate('/otherpages/enquiry')}
                        onClick={handleShow}
                      >
                        <Icon name="plus" /> <span> Add Enquiry</span>
                      </Button>
                    </li>
                  </ul>
                  {show && (
                    <AddEnquiryModal
                      get_enquiriesArr={() => get_enquiriesArr()}
                      teachers={teachers}
                      setShow={(data) => setShow(data)}
                      show={show}
                    />
                  )}
                  {Object.keys(enquiry).length > 0 && (
                    <UpdateEnquiryModal
                      teachers={teachers}
                      setIsEdit={(data) => setIsEdit(data)}
                      setEnquiry={(data) => setEnquiry(data)}
                      isEdit={isEdit}
                      enquiry={enquiry}
                      get_enquiriesArr={() => get_enquiriesArr()}
                      setEditId={(data) => setEditId(data)}
                    />
                  )}

                  {isFollowup && (
                    <AddFollowups
                      // enquiryFollowUp={enquiryFollowUp}

                      editFollowupId={editFollowupId}
                      isFollowup={isFollowup}
                      setIsFollowup={(data) => setIsFollowup(data)}
                    />
                  )}
                </div>
                <div className="data-table-wrapper">
                  <Row>
                    <Col lg="12">
                      <div className="d-flex gap-3">
                        <div className="data-table-action-wrap my-3">
                          <EnquiryFilter
                            resetData={(data) => setEnquiriesData(data)}
                            teamMemberList={teachers}
                            allData={enquiriesData}
                            setData={(data) => setTableData(data)}
                            showItemPerPage={showItemPerPage}
                            setShowPage={(value) =>
                              setShowItemPerPage(Number(value))
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <Tab.Container id="enquiry-tabs" defaultActiveKey="total-enq">
                    <div className="d-flex">
                    <Nav variant="pills" className="mb-5 justify-content-start gap-2 enquiry-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="total-enq">Total Enquiries</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="inprocess">In Process</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="enrolled">Enrolled</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="not-interested">Not Interested</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="result">{enquiriesData?.length}</Nav.Link>
                      </Nav.Item>


                    </Nav>
                  
                    </div>
                    <Tab.Content>
                      <Tab.Pane eventKey="total-enq">
                        <DataTable
                          columns={enquiryColumns}
                          data={tableData}
                          noDataComponent={
                            <div className="p-2">There are no records found.</div>
                          }
                          sortIcon={<div className="data-table-sorter"></div>}
                          pagination={true}
                          expandableRows={mobileView}
                          paginationComponent={({
                            rowsPerPage,
                            rowCount,
                            onChangePage,
                            onChangeRowsPerPage,
                            currentPage,
                          }) => (
                            <div className="data-table-bottom">
                              <DataTablePagination
                                showItemPerPage={showItemPerPage}
                                itemPerPage={rowsPerPage}
                                totalItems={rowCount}
                                paginate={onChangePage}
                                currentPage={currentPage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                setShowItemPerPage={setShowItemPerPage}
                              />
                            </div>
                          )}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="inprocess">
                        inprocess
                      </Tab.Pane>
                      <Tab.Pane eventKey="enrolled">
                        enrolled
                      </Tab.Pane>
                      <Tab.Pane eventKey="not-interested">
                        not-interested
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container> */}
                  <EnquiryTabs
                    enquiriesData={enquiriesData}
                    enquiryColumns={enquiryColumns}
                    tableData={tableData}
                    showItemPerPage={showItemPerPage}
                    setShowItemPerPage={(data) => setShowItemPerPage(data)}

                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}
export default EnquiryDataTable;
