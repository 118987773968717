import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";

function AllTicketList() {
  const StudentColumns = [

    // {
    //   name: <Form.Check className="form-check" id="check-all" />,
    //   grow: 1,
    //   cell: (row) => (
    //     <div className="text-start w-100">
    //       <Form.Check className="form-check" />
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      name: "Requester",
      grow: 5,
      selector: (row) => row.name,
      cell: (row) => (
        <MediaGroup>
          <Media size="md" shape="circle" variant={row.theme && row.theme}>
            {row.avatar ?
              <Image src={row.avatar} staticImage /> :
              <span className="smaller fw-medium">{row.name}</span>
            }
          </Media>
          <MediaText>
            <Link to={`/student-management/student-detail/${row._id}`} className="title text-secondary">{row.name}</Link>
            <span className="small text">{row.email}</span>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Subject",
      grow: "6",
      selector: (row) => row.subject,
      cell: (row) => <span className="text-middark">{row.subject}</span>,
      sortable: true,
    },
    {
      name: "Agent",
      grow: "3",
      selector: (row) => row.agent,
      cell: (row) => <span className="text-middark">{row.agent}</span>,
      sortable: true,
    },
    {
      name: "status",
      grow: "2",
      selector: (row) => row.status,
      cell: (row) => (
        // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //     : row.status === "Inactive" ? "danger"
        //         : "primary"}`
        // }>
        <span className={`badge text-bg-${row.status === "Open" ? "primary"
          : row.status === "Solved" ? "success"
            : row.status === "Closed" ? "secondary"
              : "primary"}`
        }>
          {row.status ? row.status : 'General'}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Last Message",
      grow: "2",
      selector: (row) => row.msg,
      cell: (row) => <span className="text-middark">{row.msg}</span>,
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`#`}>
              <Icon name="edit"></Icon>
            </LinkListItem>
            {/* <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
              <Icon name="trash"></Icon>
            </LinkListItem> */}
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];

  // users data
  const StudentData = [
    {
      id: 'uid01',
      name: 'Lois A',
      email: 'florenza@gmail.com',
      subject: 'Learn how to solve ticket',
      agent: 'Anna Smart',
      status: 'Open',
      msg: '40 min ago',
      avatar: '/images/avatar/a.jpg',
    },
    {
      id: 'uid02',
      name: 'Lois B',
      email: 'florenza@gmail.com',
      subject: 'Learn how to solve ticket',
      agent: 'Anna Smart',
      status: 'Solved',
      msg: '40 min ago',
      avatar: '/images/avatar/a.jpg',
    },
    {
      id: 'uid03',
      name: 'Lois C',
      email: 'florenza@gmail.com',
      subject: 'Learn how to solve ticket',
      agent: 'Anna Smart',
      status: 'Closed',
      msg: '40 min ago',
      avatar: '/images/avatar/a.jpg',
    },
  ];

  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Tickets Details</h1>
      <Block>
        <Row className="g-gs">
          <Col md="3">
            <Card className="h-100">
              <Card.Body>
                <h5>Tickets</h5>
                <Form.Group className="form-group">
                  <div className="form-control-wrap">
                    <div className="form-control-icon start"><Icon name="search"></Icon></div>
                    <Form.Control type="text" id="search" placeholder="Search in all tickets..." />
                  </div>
                </Form.Group>
                <div className="d-flex flex-column gap-2 mt-3 ticket-list">
                  <h6>All Tickets</h6>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Tickets to handle <Badge bg="light" className="ms-1 text-secondary">0</Badge></Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">My open tickets <Badge bg="light" className="ms-1 text-secondary">4</Badge></Link>

                  <h6 className="d-flex justify-content-between text-base-light my-3">My Views <Link to="" className="text-primary">Manage</Link></h6>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">My tickets in last 7 days <Badge bg="light" className="ms-1 text-secondary">23</Badge></Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Open tickets <Badge bg="light" className="ms-1 text-secondary">4</Badge></Link>

                  <h6 className="d-flex justify-content-between text-base-light my-3">Status <Button className="btn-color-base-light btn-icon p-0 m-0 justify-content-end" variant="transparent">
                    <Icon name="info"></Icon>
                  </Button></h6>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Open <Badge bg="light" className="ms-1 text-secondary">4</Badge></Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Pending <Badge bg="light" className="ms-1 text-secondary">0</Badge></Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">On hold <Badge bg="light" className="ms-1 text-secondary">0</Badge></Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Solved</Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Closed</Link>
                  <Link to="" className="d-flex justify-content-between text-secondary align-items-start">Spam</Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="9">
            <Card className="h-100">
              <Card.Body>
                <h5>Tickets List</h5>
                <StudentDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={StudentData}
                  columns={StudentColumns}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

    </Layout>
  );
}

export default AllTicketList;
