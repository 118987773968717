import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Swal from "sweetalert2/src/sweetalert2";
import { Button } from 'react-bootstrap';
import Icon from '../Icon/Icon';
// audio_video
function FileUpload({filetype,iconName, name, maxFiles, maxSize, errorText,onChange,noImage ,extensions,aspectRatio, aspectRatioCalculate, ...props}) {

  function getAspectRatio(url, callback) {
    const img = new Image()
    img.src = url
    img.onload = () => {
      const w = img.naturalWidth;
      const h = img.naturalHeight;
      let aspectRatio;
      if (w > h) {
          aspectRatio = w / h;
      } else {
          aspectRatio = h / w;
      }
      aspectRatio = Math.floor(aspectRatio)
        console.log("ASPECT",aspectRatio);
        callback(aspectRatio)
      
      
      
    }
    
    
  };






  const [files, setFiles] = useState([]);

  const [aspectRatioError, setAspectRatioError] = useState(false)
  
  console.log("FILES",files);
  // convert file size bytes to MB
  const bytesToMegaBytes = bytes => bytes / (1024 ** 2);

// handles ondrop function of dropzone
const handleDropChange = (acceptedFiles) => {
  const updatedFiles = acceptedFiles.map((file) =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),

    })
  );
  console.log("aspectRATIOTEXT",aspectRatio);
  console.log("UPDATEDFILES",updatedFiles)
  let a = 0;
  if(filetype=="image"){
    getAspectRatio(updatedFiles[0].preview, function(result){
      if(aspectRatioCalculate){
        if(result != aspectRatioCalculate){
          setFiles([])
          if (onChange ) {
            onChange([]); 
          }
          // setAspectRatioError(true)
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: aspectRatio,
          });
          
          // setAspectRatioError(true)
          // const lastRemoveFromFiles = files.pop()
          
        // return   
    
        }
      }
    }
  );
  }
  if(filetype == "audio_video"){
    if(!updatedFiles[0].type.includes("video") && !updatedFiles[0].type.includes("audio") ){
      Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Please select Audio or Video file",
        }); 
    }else{
      setFiles(updatedFiles);
      if (onChange ) {
        onChange(updatedFiles); 
      }
    }}
  if(filetype == "video"){
    if(!updatedFiles[0].type.includes("video")){
      Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Please select Video file",
        }); 
    }else{
      setFiles(updatedFiles);
      if (onChange ) {
        onChange(updatedFiles); 
      }
    }}
    if(filetype=="audio"){
    if(!updatedFiles[0].type.includes("audio")){
      Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Please select Audio file",
        });
      return    
    }else{
      setFiles(updatedFiles);
      if (onChange ) {
        onChange(updatedFiles); 
      }
    }}
    // console.log("aspectRatio",getAspectRatio(updatedFiles[0].preview));
    if(filetype=="image"){
    if(!updatedFiles[0].type.includes("image")){
      Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Please select Image",
        });
      return    
    }
    else if(updatedFiles[0].type.includes("jpg") || updatedFiles[0].type.includes("jpeg") || updatedFiles[0].type.includes("png")){
      setFiles(updatedFiles);
      if (onChange ) {
        onChange(updatedFiles); 
      }
    }
    
    else{
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please Select Image of Recommended Type",
      });
      return 
      // setFiles(updatedFiles);
      // if (onChange ) {
      //   onChange(updatedFiles); 
      // }
    }}
    if(filetype=="document"){
      if(!updatedFiles[0].type.includes("pdf") && !updatedFiles[0].type.includes("text")  &&   !updatedFiles[0].type.includes("doc") && !updatedFiles[0].type.includes("docx")&& !updatedFiles[0].type.includes("rtf")){
        Swal.fire({
            icon: "error",
            title: "Oops",
            text: "Please select Document File(.pdf,.txt,.doc,.docx,.rtf etc)",
          });
        return    
      }else{
        setFiles(updatedFiles);
        if (onChange ) {
          onChange(updatedFiles); 
        }
      }} 
};
// preview thumbs
const thumbs =!noImage && files.map(file => (
  <div className="dz-preview dz-processing dz-image-preview dz-complete" key={file.name}>
    <div className="dz-image">
      <img src={file.preview} alt="preview" />
    </div>
  </div>
));


  // useEffect(() => {
  //   if(aspectRatioError){
  //     setFiles([])
  //     if(onChange){
  //       onChange([])
  //     }
  //     setAspectRatioError(false)
  //   }
  // },[aspectRatioError])


  return (
    <>
      <Dropzone 
        onDrop={acceptedFiles => handleDropChange(acceptedFiles, setFiles)} 
        maxFiles={maxFiles}
        maxSize={maxSize}
        onDropRejected={() => alert(errorText)}
      >
          {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="dropzone dz-clickable">
                <input {...getInputProps()} />
                {(files.length === 0 || noImage) && (
                    <div className="dz-message">
                        <div className="dz-message-icon">
                          <Icon size="lg" name={iconName} />
                        </div>
                        <div className="dz-message-btn">
                        <Button size="md" variant='transparent'>Upload</Button>
                            {/* <Button size="md" vaiant="transparent">Upload</Button> */}
                        </div>
                        {/* <span className="dz-message-text">Click to replace or drag and drop <small>{maxSize && 'Max ' + bytesToMegaBytes(maxSize) + ' MB'}</small></span> */}
                          <small className="dz-message-text">
            Image must be JPG/JPEG or PNG
            ({aspectRatio})
            </small>
                       
                    </div>
                )}
                {files.length > 0 && files[0].type?.startsWith('image/')?thumbs:files[0]?.name}
              </div>
          )}
        </Dropzone>
    </>
  )
}

export default FileUpload