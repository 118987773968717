import React, { useRef, useEffect, useState, useContext } from 'react';
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import VolumeUI from './VolumeUI';
import StatusUI from './StatusUI';
import ModalContext from '../../../../../context/ModalContext';
import Audio from '../../customAudio/audio';
function AudioUI({data, timer, startRecording, audio }) {
  const audioRef = useRef();
  const [startAudio, setstartAudio] = useState(false)
  const [count, setCount] = useState(100);
  const [isMuted, setIsMuted] = useState(false);
  const handleVolumeChange = (value) => {
    setCount(Number(value));
    if (audioRef.current) {
      audioRef.current.volume = Number(value) / 100;
    }
  };
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [time, setTime] = useState({
    minutes: timer?.split(":").map(Number)[0],
    seconds: timer?.split(":").map(Number)[1],
  });
  const [message, setMessage] = useState("Begining");
  useEffect(() => {
    setTime({ minutes: timer?.split(":").map(Number)[0],seconds: timer?.split(":").map(Number)[1]})
    setPopUpType("prepare_question")
    setstartAudio(false)
  }, [data,timer])
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timerInterval);
        startRecording();
        setstartAudio(true)
        // setPopUpType("default");
        setMessage("Playing...");
      } else if (time.minutes === 0 && time.seconds === 1) {
        setMessage(" Beginning in 1 seconds.");
        setTime({ minutes: 0, seconds: 0 });
      } else if (time.minutes === 0) {
        const newSeconds = Math.max(0, time.seconds - 1);

        setMessage(`Beginning in ${newSeconds} seconds.`);
        setTime({ minutes: 0, seconds: newSeconds });
      } else if (time.minutes !== 0 && time.seconds === 0) {
        setMessage(` Beginning in ${time.minutes} minutes.`);
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      } else {
        const minuteText = time.minutes === 1 ? "m" : "m";
        setMessage(
          ` Beginning in ${time.minutes} ${minuteText} ${time.seconds} seconds.`
        );
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [time, timer]);
  return (
    <>
      {/* for start audio playing */}
      <div className="audio-card my-3">
        {/* status Beginning in 4s, Playing.., Completed  */}
        <p>Status : {(popUpType == "default" || popUpType == "recordingStopped") ? "Completed" : message}</p>
        <div className='audio-ui'>
          {!startAudio && <p className="w-100 text-secondary exam-vol">
            <span className='w-25'>Volume</span>
            <input
              className="w-100"
              type="range"
              value={count}
              disabled
              // onChange={(e) => handleVolumeChange(e.target.value)}
              max="100"
              step="1"
              id="volume-control"
            />
          </p>}
          {startAudio ? <Audio audio={audio} /> : <ProgressBar className='progress-ui' now={0} variant="hisuccess" style={{ height: "18px" }} />}
        </div>
      </div>
    </>
  );
}

export default AudioUI;
