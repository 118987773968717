import React from "react";
import { Routes, Route } from "react-router-dom";
import Blank from "../../pages/Blank";
import Home from "../../pages/Home";
import HomeEcommerce from "../../pages/HomeEcommerce";
import HomeProject from "../../pages/HomeProject";
import HomeMarketing from "../../pages/HomeMarketing";
import HomeNFT from "../../pages/HomeNFT";

// apps
import AppCalendar from "../../pages/AppCalendar";
import KanbanBasic from "../../pages/kanban/KanbanBasic";
import KanbanCustom from "../../pages/kanban/KanbanCustom";
import Chats from "../../pages/apps/chat/Chats";
import Inbox from "../../pages/apps/mailbox/Inbox";

// user manage
import UserList from "../../pages/user-manage/UserList";
import UserCards from "../../pages/user-manage/UserCards";
import UserProfile from "../../pages/user-manage/UserProfile";
import UserEdit from "../../pages/user-manage/UserEdit";

// admin
import Profile from "../../pages/admin/Profile";
import ProfileSettings from "../../pages/admin/ProfileSettings";

// ecommerce
import Products from "../../pages/ecommerce/Products";
import Categories from "../../pages/ecommerce/Categories";
import AddProduct from "../../pages/ecommerce/AddProduct";
import EditProduct from "../../pages/ecommerce/EditProduct";
import AddCategory from "../../pages/ecommerce/AddCategory";
import EditCategory from "../../pages/ecommerce/EditCategory";

// ui elements
import Accordion from "../../pages/components/Accordion";
import Alert from "../../pages/components/Alert";
import Badge from "../../pages/components/Badge";
import Breadcrumb from "../../pages/components/Breadcrumb";
import Buttons from "../../pages/components/Buttons";
import ButtonGroup from "../../pages/components/ButtonGroup";
import Cards from "../../pages/components/Cards";
import Carousel from "../../pages/components/Carousel";
import CloseButton from "../../pages/components/CloseButton";
import Collapse from "../../pages/components/Collapse";
import Dropdowns from "../../pages/components/Dropdowns";
import ListGroup from "../../pages/components/ListGroup";
import Modal from "../../pages/components/Modal";
import Tabs from "../../pages/components/Tabs";
import Offcanvas from "../../pages/components/Offcanvas";
import Pagination from "../../pages/components/Pagination";
import Placeholders from "../../pages/components/Placeholders";
import Popovers from "../../pages/components/Popovers";
import Progress from "../../pages/components/Progress";
import Spinners from "../../pages/components/Spinners";
import Toasts from "../../pages/components/Toasts";
import Tooltips from "../../pages/components/Tooltips";

// utilities
import Misc from "../../pages/utilities/Misc";
import Typography from "../../pages/utilities/Typography";
import Images from "../../pages/utilities/Images";
import Tables from "../../pages/utilities/Tables";
import Background from "../../pages/utilities/Background";
import Borders from "../../pages/utilities/Borders";
import Colors from "../../pages/utilities/Colors";
import Flex from "../../pages/utilities/Flex";
import Sizing from "../../pages/utilities/Sizing";
import Spacing from "../../pages/utilities/Spacing";

// layout
import Breakpoints from "../../pages/layout/Breakpoints";
import Containers from "../../pages/layout/Containers";
import Gutters from "../../pages/layout/Gutters";
// fomrs
import FormControl from "../../pages/forms/FormControl";
import FormSelect from "../../pages/forms/FormSelect";
import DateTime from "../../pages/forms/DateTime";
import FormUpload from "../../pages/forms/FormUpload";
import InputGroup from "../../pages/forms/InputGroup";
import FloatingLabels from "../../pages/forms/FloatingLabels";
import ChecksRadios from "../../pages/forms/ChecksRadios";
import FormRange from "../../pages/forms/FormRange";
import FormValidation from "../../pages/forms/FormValidation";
import FormLayout from "../../pages/forms/FormLayout";
import QuillPreview from "../../pages/forms/editors/QuillPreview";
import TinymcePreview from "../../pages/forms/editors/TinymcePreview";

// other pages
import DataTablePreview from "../../pages/DataTablePreview";
import ChartsPreview from "../../pages/ChartsPreview";
import Sweetalert from "../../pages/Sweetalert";

// auths pages
import AuthRegister from "../../pages/auths/AuthRegister";
import AuthLogin from "../../pages/auths/AuthLogin";
import AuthReset from "../../pages/auths/AuthReset";
import AuthConfirmPassword from "../../pages/auths/AuthChangePassword";

import NotFound from "../../pages/error/NotFound";
import IconsPreview from "../../pages/IconsPreview";
import ContactInformation from "../../pages/ContactInformation";
import InstituteLogin from "../../pages/auths/InstituteLogin";
import StudentDashboard from "../../pages/studentpages/StudentDashboard";
import ContactInformationRequest from "../../pages/ContactInformationRequest";
import EnquiryRequest from "../../pages/EnquiryRequest";
function PublicRoute() {
  console.log("PUBLIC ROUTE========================")
  return (
    <>
      <Routes>
        <Route path="auths">
          <Route path="institute-register" element={<ContactInformation />} />
          <Route path="institute-request" element={<ContactInformationRequest />} />

          <Route path="student-register" element={<AuthRegister />} />
          <Route path="auth-login" element={<AuthLogin />} />
          <Route path="auth-reset" element={<AuthReset />} />
          <Route path="auth-change-password" element={<AuthConfirmPassword />} />
          <Route path="institute-login" element={<InstituteLogin />} />
          <Route path="forget-password/:_id/:otp" element={<AuthConfirmPassword />} />
        </Route>
        <Route path="icons" element={<IconsPreview />} />
        <Route path="enquiry-request/:branchId" element={<EnquiryRequest />} />
        <Route path="enquiry-request" element={<EnquiryRequest />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="/" element={<AuthLogin />} />
        <Route path="*" element={<AuthLogin />} />
      </Routes>
    </>
  );
}

export default PublicRoute;
