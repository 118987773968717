import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AdminApi from "../../api/admin";
import AuthApi from "../../api/auth";
import { DateRangePicker } from "../../components";

function ResultManagementFilter({
  allData,
  tableData,
  setData,
  showItemPerPage,
  setShowPage,
}) {
  console.log("allData", allData);

  const [examList, setExamList] = useState([]);
  console.log(examList, "examList");

  const uniqueBatchValues = new Set();
  const Batch = allData
    .filter(
      (item) => item?.users_details?.[0]?.stu_profile?.batchTime != undefined
    )
    .map((item) => {
      const value = `${item?.users_details?.[0]?.stu_profile?.batchTime[0]?.starttime} to ${item?.users_details?.[0]?.stu_profile?.batchTime[0]?.endtime}`;
      const id = item?.users_details?.[0]?.stu_profile?.batchTime[0]?._id;
      const obj = { value, id };
      const stringifiedObj = JSON.stringify(obj);
      if (!uniqueBatchValues.has(stringifiedObj)) {
        uniqueBatchValues.add(stringifiedObj);
        return obj;
      }
      return null;
    })
    .filter(Boolean);
  console.log(Batch, "Batch11");

  const teachers = [
    ...new Set(
      allData
        .filter(
          (item) =>
            item?.users_details?.[0]?.stu_profile?.assignTeacher != undefined
        )
        .map((item) =>
          item?.users_details?.[0]?.stu_profile?.assignTeacher?.toLowerCase()
        )
    ),
  ];

  const examData = [
    ...new Set(
      examList
        .filter((item) => item?.name != undefined)
        .map((item) => item?.name?.toLowerCase())
    ),
  ];

  const Student_type = [
    ...new Set(
      allData
        .filter(
          (item) => item?.users_details?.[0]?.stu_profile?.testType != undefined
        )
        .map((item) =>
          item?.users_details?.[0]?.stu_profile?.testType?.toLowerCase()
        )
    ),
  ];
  console.log(Student_type, "Student_type");
  const Status = [
    {
      value: "Done",
      id: true,
    },
    {
      value: "Pending",
      id: false,
    },
  ];
  const [filterDataObj, setFilterDatObj] = useState({});
  let {
    startDate,
    endDate,
    country,
    city,
    name,
    product,
    status,
    testname,
    testType,
    teacher,
    batchTime,
    mobile,
  } = filterDataObj;
  console.log("------------", filterDataObj);
  const handleDateChange = (e) => {
    let { value, name } = e.target;
    console.log("name##RR", value, name);
    if (name == "start") {
      setFilterDatObj({ ...filterDataObj, startDate: value });
    } else if (name == "end") {
      setFilterDatObj({ ...filterDataObj, endDate: value });
    } else {
      setFilterDatObj({ ...filterDataObj, [name]: value });
    }
  };
  console.log(filterDataObj, "filterDataObj...");
  console.log(batchTime, "batchTime1");
  console.log(status, "value...............", !status);
  const filterData = async () => {
    const filteredData = allData.filter((item) => {
      const itemDate = new Date(item?.createdAt);
      const endOfDay = new Date(endDate);
      console.log(
        startDate,
        itemDate,
        "itemDate11",
        endOfDay,
        "aaa",
        itemDate >= new Date(startDate),
        itemDate <= endOfDay
      );

      console.log(testname, item?.papers[0]?.name, "testNameee");

      // const itemsDate = itemDate.getDate();
      // const itemYear = itemDate.getFullYear();
      // const itemMonth = itemDate.getMonth();

      // const endDated = endOfDay.getDate();
      // const endYear = endOfDay.getFullYear();
      // const endMonth = endOfDay.getMonth();
      // console.log(
      //   itemDate,
      //   "itemDate",
      //   endOfDay,
      //   itemsDate === endDated && itemMonth === endMonth && itemYear === endYear
      // );
      endOfDay.setHours(23, 59, 59, 999);
      return (
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= endOfDay) &&
        // (!endDate ||
        //   (itemsDate === endDated &&
        //     itemMonth === endMonth &&
        //     itemYear === endYear)) &&

        (!teacher ||
          teacher?.toLowerCase() ===
            item?.users_details?.[0]?.stu_profile?.assignTeacher?.toLowerCase()) &&
        (!batchTime ||
          batchTime ===
            item?.users_details?.[0]?.stu_profile?.batchTime[0]?._id) &&
        (!testType ||
          testType?.toLowerCase() ===
            item?.users_details?.[0]?.stu_profile?.testType?.toLowerCase()) &&
        (!status || status == String(item?.status)) &&
        (!mobile || item?.users_details?.[0]?.mobile.includes(mobile?.trim())) &&
        //

        (!name ||
          item?.users_details?.[0]?.name
            .toLowerCase()
            .includes(name?.toLowerCase()?.trim())) &&
        (!testname ||
          item?.papers[0]?.name
            .toLowerCase()
            .includes(testname?.toLowerCase())) &&
        (!product || product?.toLowerCase() === item?.product?.toLowerCase())
      );
    });
    setData(filteredData);

    console.log("filteredData", filteredData);
  };
  let api = new AuthApi();

  let adminApi = new AdminApi();

  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
  }, []);

  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        if (res.data.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    get_all_exam();
  }, []);

  const get_all_exam = async () => {
    await adminApi
      .get_all_exam()
      .then((res) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2 ">
            <select
              className="form-select"
              onChange={(e) => setShowPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>
        {/* <Form.Group className="form-group w-15 mb-3 me-2">
          <Form.Label>Filter by Date</Form.Label>
          <DatePickerOnChange
            endDate={endDate}
            onChange={handleDateChange}
            name={"end"}
          />
        </Form.Group> */}

        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Date</Form.Label>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
          />
        </Form.Group>
        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Name/Username</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="filter"
              placeholder="Search By Name"
              value={filterDataObj?.name}
              name="name"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Mobile Number</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="mobilefilter"
              placeholder="Search By Mobile Number"
              value={filterDataObj?.mobile}
              name="mobile"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        {/* <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Testname</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="testfilter"
              placeholder="Search By Testname"
              value={filterDataObj?.testname}
              name="testname"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group> */}

        <DropDown
          className="w-15 mb-3"
          data={examData}
          text="Exam Name"
          name="testname"
          value={filterDataObj?.testname}
          onChange={handleDateChange}
        />

        <DropDown
          className="w-15 mb-3"
          data={Student_type}
          text="Student Type"
          name="testType"
          value={filterDataObj?.testType}
          onChange={handleDateChange}
        />
        <DropDown
          className="w-15 mb-3"
          data={teachers}
          text="Teachers"
          name="teacher"
          value={filterDataObj?.teacher}
          onChange={handleDateChange}
        />
        <DropDown
          className="w-15 mb-3"
          object={true}
          data={Batch}
          text="Batch"
          name="batchTime"
          value={filterDataObj?.batchTime}
          onChange={handleDateChange}
        />

        <DropDown
          className="w-15 mb-3"
          object={true}
          data={Status}
          text="Status"
          name="status"
          value={filterDataObj?.status}
          onChange={handleDateChange}
        />
        <Form.Group className="form-group d-flex w-20 ms-2 align-items-end">
          <div className="form-control-wrap d-flex me-2 align-items-end">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObj({
                  ...filterDataObj,
                  startDate: "",
                  endDate: "",
                  product: "",
                  batchTime: "",
                  name: "",
                  status: "",
                  testType: "",
                  teacher: "",
                  testname: "",
                  mobile: "",
                });
                setData(allData);
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" onClick={filterData}>
              Search
            </Button>
          </div>
        </Form.Group>
      </div>
    </>
  );
}

export default ResultManagementFilter;

function DropDown({ object, value, text, name, data, onChange }) {
  console.log('value :>> ', value);
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label>{text}</Form.Label>
        <div className="form-control-wrap me-2" style={{ width: "140px" }}>
          <Form.Select
            removeItemButton
            value={value}
            name={name}
            onChange={onChange}
          >
            <option value="">All</option>
            {data?.length > 0 &&
              data.map((item) => {
                console.log('item,object :>> ', item,object);
                return (
                  <>
                    <option value={object ? item?.id : item}>
                      {object ? item?.value : item}
                    </option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
    </>
  );
}
