import React from 'react';

const OtherManagementSvg = ({ fill}) => {
  console.log("fillacc", fill);
  return (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'

    viewBox='0 0 24 24'
  >
    <path
      fill={fill}
      fillRule='evenodd'
      d='M6.161 2H17.84c.527 0 .982 0 1.356.03.395.033.789.104 1.167.297a3 3 0 011.311 1.311c.193.378.264.772.296 1.167.031.375.031.83.031 1.356v8.678c0 .527 0 .981-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 01-1.311 1.311c-.378.193-.772.264-1.167.296-.374.031-.83.031-1.356.031h-.97l.963 1.445a1 1 0 01-1.664 1.11L14.465 19h-4.93l-1.703 2.555a1 1 0 01-1.664-1.11L7.13 19h-.97c-.527 0-.981 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 01-1.311-1.311c-.193-.378-.264-.772-.296-1.167A17.9 17.9 0 012 14.838V6.162c0-.527 0-.981.03-1.356.033-.395.104-.789.297-1.167a3 3 0 011.311-1.311c.378-.193.772-.264 1.167-.296C5.18 2 5.635 2 6.161 2zm2.855 15H6.2c-.577 0-.949 0-1.232-.024-.272-.022-.373-.06-.422-.085a1 1 0 01-.437-.437c-.025-.05-.063-.15-.085-.422C4 15.75 4 15.377 4 14.8V6.2c0-.577 0-.949.024-1.232.022-.272.06-.373.085-.422a1 1 0 01.437-.437c.05-.025.15-.063.422-.085C5.25 4 5.623 4 6.2 4h11.6c.577 0 .949 0 1.232.024.272.022.372.06.422.085a1 1 0 01.437.437c.025.05.063.15.085.422C20 5.25 20 5.623 20 6.2v8.6c0 .577 0 .949-.024 1.232-.022.272-.06.372-.085.422a1 1 0 01-.437.437c-.05.025-.15.063-.422.085C18.75 17 18.377 17 17.8 17h-2.816zM12 5a1 1 0 011 1v7h2V9a1 1 0 112 0v4a1 1 0 110 2H7a1 1 0 110-2v-2a1 1 0 112 0v2h2V6a1 1 0 011-1z'
      clipRule='evenodd'
      data-original='#000000'
    ></path>
  </svg>
  );
}

export default OtherManagementSvg;
