import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { DateRangePicker } from "../../../components";
import Container from "react-bootstrap/Container";
import AuthApi from "../../../api/auth";
import { Label } from "@mui/icons-material";
function InstituteFilter({
  allData,
  tableData,
  setData,
  showItemPerPage,
  setShowPage,
  allRecorddata
}) {
  let { inst_profile } = allData;
  // let {inst_business_state,inst_business_country,inst_business_city} = inst_profile;
  const City = [
    ...new Set(
      allData
        .filter((item) => item?.inst_profile?.inst_business_city != undefined)
        .map((item) => item?.inst_profile?.inst_business_city?.toLowerCase())
    ),
  ];
  const Country = [
    ...new Set(
      allData
        .filter(
          (item) => item?.inst_profile?.inst_business_country != undefined
        )
        .map((item) => item?.inst_profile?.inst_business_country?.toLowerCase())
    ),
  ];
  const State = [
    ...new Set(
      allData
        .filter((item) => item?.inst_profile?.inst_business_state != undefined)
        .map((item) => item?.inst_profile?.inst_business_state?.toLowerCase())
    ),
  ];
  const studentNo = [
    ...new Set(
      allData
        .filter(
          (item) => item?.inst_profile?.inst_number_of_student != undefined
        )
        .map((item) => item?.inst_profile?.inst_number_of_student)
    ),
  ];
  const Plans = [
    ...new Set(
      allData
        .filter((item) => item?.planId?.plan_name != undefined)
        .map((item) => item?.planId?.plan_name)
    ),
  ];
  const ActionType = ["less then <", "Greater then >", "Equal to ="];
  console.log(
    "Plans",
    allData.filter((item) => item?.planId?.plan_name != undefined)
  );
  const [filterDataObj, setFilterDatObj] = useState({
    startDate: "",
    endDate: "",
  });
  let {
    startDate,
    endDate,
    country,
    state,
    city,
    pincode,
    domain_name,
    studentno,
    plans,
    creditfilter,
    creditvalue,
    manageBy,
  } = filterDataObj;
  const handleDateChange = (e) => {
    let { value, name } = e.target;
    if (name == "start") {
      setFilterDatObj({ ...filterDataObj, startDate: value });
    } else if (name == "end") {
      setFilterDatObj({ ...filterDataObj, endDate: value });
    } else {
      setFilterDatObj({ ...filterDataObj, [name]: value });
    }
  };
  const filterData = async () => {
    creditvalue = Number(creditvalue);
    const filteredData = allData.filter((item) => {
      const itemDate = new Date(item.createdAt);
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      return (
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= endOfDay) &&
        (!country ||
          country?.toLowerCase() ===
          item?.inst_profile?.inst_business_country?.toLowerCase()) &&
        (!state ||
          state?.toLowerCase() ===
          item?.inst_profile?.inst_business_state?.toLowerCase()) &&
        (!city ||
          city?.toLowerCase() ===
          item?.inst_profile?.inst_business_city?.toLowerCase()) &&
        (!plans ||
          plans?.toLowerCase() === item?.planId?.plan_name?.toLowerCase()) &&
        (!domain_name ||
          item?.inst_profile?.inst_business_name
            ?.toLowerCase()?.trim()
            ?.includes(domain_name?.toLowerCase()?.trim())) &&
        (!manageBy || item?.inst_profile?.manageBy == manageBy) &&
        (!pincode ||
          pincode?.toString() ===
          item?.inst_profile?.inst_business_postal_code?.toString()) &&
        (!studentno ||
          item?.inst_profile?.inst_number_of_student?.includes(studentno)) &&
        ((!creditfilter && !creditvalue) ||
          (creditfilter.includes("=") && item.credit == creditvalue) ||
          (creditfilter.includes("<") && item.credit < creditvalue) ||
          (creditfilter.includes(">") && item.credit > creditvalue))
      );
    });
    setData(filteredData);
  };
  let api = new AuthApi();

  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
  }, []);

  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        console.log("raaaaaaaaaaaaaaaaaaaaaaaaess", res);
        if (res.data.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("filteredData", filterDataObj);
  return (
    <>
      <Container>
        <div className="d-flex justify-content-start gap-2 mb-3 flex-wrap">
          <Form.Group className="form-group w-30" style={{width:"300px"}}>
            <Form.Label>Filter by Date</Form.Label>
            <DateRangePicker
              className="institute-date"
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
            />
          </Form.Group>
          <div className="w-20">
            <DropDown
              data={Country}
              text="Country"
              name="country"
              value={filterDataObj?.country}
              onChange={handleDateChange}
            />
          </div>
          <div className="w-20">
            <DropDown
              data={State}
              text="State"
              name="state"
              value={filterDataObj?.state}
              onChange={handleDateChange}
            />
          </div>
          <div className="w-20">
            <DropDown
              data={City}
              text="City"
              name="city"
              value={filterDataObj?.city}
              onChange={handleDateChange}
            />
          </div>
          <Form.Group className="form-group w-20">
            <Form.Label>PINCODE</Form.Label>
            <div className="form-control-wrap me-2">
              <Form.Control
                type="number"
                id="filter"
                placeholder="Pincode"
                value={filterDataObj?.pincode}
                name="pincode"
                onChange={handleDateChange}
              />
            </div>
          </Form.Group>
          <Form.Group className="form-group w-20 ">
            <Form.Label>Institute Name</Form.Label>
            <div className="form-control-wrap me-2">
              <Form.Control
                id="filter"
                placeholder="Search By Institute Name"
                value={filterDataObj?.domain_name}
                name="domain_name"
                onChange={handleDateChange}
              />
            </div>
          </Form.Group>
          <Form.Group className="form-group w-20">
            <Form.Label htmlFor="exampleFormControlInputText5">
              Filter Reset
            </Form.Label>
            <div className="form-control-wrap d-flex me-2">
              <Button
                className="me-2"
                onClick={(e) => {
                  setFilterDatObj({
                    ...filterDataObj,
                    startDate: "",
                    endDate: "",
                    creditfilter: "",
                    country: "",
                    state: "",
                    city: "",
                    pincode: "",
                    domain_name: "",
                    creditvalue: "",
                    plans: "",
                    studentno: "",
                    manageBy: "",
                  });
                  setData(allRecorddata);
                }}
                variant="secondary"
              >
                Reset
              </Button>
              <Button variant="primary" onClick={filterData}>
                Search
              </Button>
            </div>
          </Form.Group>
        </div>
        <Row>
          <div className="d-flex justify-content-between gap-2 mb-3">

            <div className="w-25">
              <label className="form-label">Managed By </label>{" "}
              <div
                className="form-control-wrap ms-1 me-1">
                <Form.Select
                  removeItemButton
                  value={filterDataObj?.manageBy}
                  name="manageBy"
                  onChange={handleDateChange}
                >
                  <option value="">Select</option>
                  {staffList?.length > 0 &&
                    staffList.map((item) => {
                      return (
                        <>
                          <option value={item._id}>{item.name}</option>
                        </>
                      );
                    })}
                </Form.Select>
              </div>
            </div>
                <div className="w-25">
                  <DropDown
                    data={ActionType}
                    text="By Account Credit"
                    name="creditfilter"
                    value={filterDataObj?.creditfilter}
                    onChange={handleDateChange}
                  />
                </div>

                {creditfilter && (
                  <Form.Group className="form-group">
                    <Form.Label>Value</Form.Label>
                    <div
                      className="form-control-wrap ms-1 me-1">
                      <Form.Control
                        type="number"
                        id="filter"
                        placeholder="Credit value"
                        value={filterDataObj?.creditvalue}
                        name="creditvalue"
                        onChange={handleDateChange}
                      />
                    </div>
                  </Form.Group>
                )}
            <div className="w-25">
              <DropDown
                data={Plans}
                text="By Institute Plan"
                name="plans"
                value={filterDataObj?.plans}
                onChange={handleDateChange}
              />
            </div>
            <div className="w-25">
              <DropDown
                data={studentNo}
                text="Student By No"
                name="studentno"
                value={filterDataObj?.studentno}
                onChange={handleDateChange}
              />
            </div>
            {/* <div><label class="form-label" style={{ visibility: "hidden" }}>No.</label>
              <Form.Group className="form-control-wrap ms-1 me-1">
                <select
                  className="form-select"
                  onChange={(e) => setShowPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
                
              </Form.Group>
            </div> */}
            {/* <Col md="3">
              <Form.Group className="form-group w-30 mt-4">
                <div
                  className="form-control-wrap me-2"
                  style={{ width: "200px" }}
                >
                  <Form.Control
                    id="filter"
                    placeholder="Search By Institute Name"
                    value={filterDataObj?.domain_name}
                    name="domain_name"
                    onChange={handleDateChange}
                  />
                </div>
              </Form.Group>
            </Col> */}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default InstituteFilter;

function DropDown({ value, text, name, data, onChange }) {
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label>{text}</Form.Label>
        <div className="form-control-wrap">
          <Form.Select
            removeItemButton
            value={value}
            name={name}
            onChange={onChange}
          >
            <option value="">All</option>
            {data?.length > 0 &&
              data.map((item) => {
                return (
                  <>
                    <option value={item}>{item}</option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
    </>
  );
}
