import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { DateRangePicker } from "../../components";

import AuthApi from "../../api/auth";
function StudentFilter({
  allData,
  tableData,
  setData,
  showItemPerPage,
  setShowPage,
  currentFilterName,
  expiredApiFilterResult,
  upcomingApiFilterResult
}) {
  console.log("tableData", tableData,currentFilterName);

  // let {inst_business_state,inst_business_country,inst_business_city} = inst_profile;
  const teachers = [
    ...new Set(
      tableData
        ?.filter((item) => item?.stu_profile?.assignTeacher != undefined)
        ?.map((item) => item?.stu_profile?.assignTeacher?.toLowerCase())
    ),
  ];
  const Product = [
    ...new Set(
      tableData
        ?.filter((item) => item?.product != undefined)
        ?.map((item) => item?.product)
    ),
  ];

  const createdBySet = [
    ...new Set(
      tableData
        ?.filter((item) => item?.createdBy != undefined)
        ?.map((item) => item?.createdBy)
    ),
  ];

  const Student_type = [
    ...new Set(
      tableData
        ?.filter((item) => item?.stu_profile?.testType != undefined)
        ?.map((item) => item?.stu_profile?.testType?.toLowerCase())
    ),
  ];

  const Test_type = [
    ...new Set(
      tableData
        ?.filter((item) => item?.stu_profile?.mockTest != undefined)
        ?.map((item) => item?.stu_profile?.mockTest?.toLowerCase())
    ),
  ];
  const Status = [
    {
      value: "Active",
      id: true,
    },
    {
      value: "InActive",
      id: false,
    },
  ];
  const uniqueBatchValues = new Set();
  const Batch = tableData
    ?.filter((item) => item?.stu_profile?.batchTime != undefined)
    .map((item) => {
      const value = `${item?.stu_profile?.batchTime[0]?.starttime} to ${item?.stu_profile?.batchTime[0]?.endtime}`;
      const id = item?.stu_profile?.batchTime[0]?._id;
      const obj = { value, id };
      const stringifiedObj = JSON.stringify(obj);
      if (!uniqueBatchValues.has(stringifiedObj)) {
        uniqueBatchValues.add(stringifiedObj);
        return obj;
      }
      return null;
    })
    ?.filter(Boolean);

  const ActionType = ["less then <", "Greater then >", "Equal to ="];
  const [filterDataObj, setFilterDatObj] = useState({});
  let {
    startDate,
    endDate,
    country,
    state,
    city,
    pincode,
    name,
    product,
    testType,
    status,
    teacher,
    batchTime,
    creditfilter,
    creditvalue,
    manageBy,
    createdBy,
    test
  } = filterDataObj;

  const handleDateChange = (e) => {
    let { value, name } = e.target;
    if (name == "start") {
      setFilterDatObj({ ...filterDataObj, startDate: value });
    } else if (name == "end") {
      setFilterDatObj({ ...filterDataObj, endDate: value });
    } else {
      setFilterDatObj({ ...filterDataObj, [name]: value });
    }
  };
  const filterData = async () => {
    creditvalue = Number(creditvalue);
    const filteredData = tableData?.filter((item) => {
      const itemDate = new Date(item.createdAt);
      const endOfDay = new Date(endDate);
      console.log(
        itemDate,
        "itemDate",
        endOfDay,
        "aaa",
        itemDate >= new Date(startDate),
        itemDate <= endOfDay
      );
      endOfDay.setHours(23, 59, 59, 999);
      return (
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= endOfDay) &&
        (!country ||
          country?.toLowerCase() ===
            item?.inst_profile?.inst_business_country?.toLowerCase()) &&
        (!status || status == String(item?.isActive)) &&
        (!state ||
          state?.toLowerCase() ===
            item?.inst_profile?.inst_business_state?.toLowerCase()) &&
        (!city ||
          city?.toLowerCase() ===
            item?.inst_profile?.inst_business_city?.toLowerCase()) &&
        (!teacher ||
          teacher?.toLowerCase() ===
            item?.stu_profile?.assignTeacher?.toLowerCase()) &&
        (!batchTime || batchTime === item?.stu_profile?.batchTime[0]?._id) &&
        (!testType ||
          testType?.toLowerCase() ===
            item?.stu_profile?.testType?.toLowerCase()) &&

        (!test ||
          test?.toLowerCase() ===
                item?.stu_profile?.mockTest?.toLowerCase()) &&

        (!createdBy ||
                  createdBy?.toLowerCase() ===
                        item?.createdBy?.toLowerCase()) &&
        (!name ||
          item?.name.toLowerCase().includes(name?.toLowerCase()?.trim()) ||
          item?.name.includes(name)) &&
      
        (!product || product?.toLowerCase() === item?.product?.toLowerCase())
      );

    });
    setData(filteredData);

    console.log("filteredData", filteredData);
  };
  let api = new AuthApi();

  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
  }, []);

  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        if (res.data.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("Batch", Batch);
  console.log('currentFilterName :>> ', currentFilterName);
  console.log("filterDataObj", filterDataObj);
  console.log('hellokHar :>> ', filterDataObj?.teacher);
  return (
    <>
      <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3 text-theme-text">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2">
            <select
              className="form-select"
              onChange={(e) => setShowPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>
        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Date</Form.Label>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
          />
        </Form.Group>
        <Form.Group className="form-group w-20 mb-3">
          <Form.Label>Name</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="filter"
              placeholder="Search By Name "
              value={filterDataObj?.name}
              name="name"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>

        <DropDown
          className="w-15 mb-3"
          data={Product}
          text="Products"
          name="product"
          value={filterDataObj?.product}
          onChange={handleDateChange}
        />
        <DropDown
          className="w-15 mb-3"
          data={Student_type}
          text="Student Type"
          name="testType"
          value={filterDataObj?.testType}
          onChange={handleDateChange}
        />

{/* 
        <DropDown
          className="w-15 mb-3"
          data={Test_type}
          text="Test"
          name="test"
          value={filterDataObj?.test}
          onChange={handleDateChange}
        /> */}
        <DropDown
          className="w-15 mb-3"
          data={teachers}
          text="Teachers"
          name="teacher"
          value={filterDataObj?.teacher}
          onChange={handleDateChange}
        />
        <DropDown
          className="w-15 mb-3"
          object={true}
          data={Batch}
          text="Batch"
          name="batchTime"
          value={filterDataObj?.batchTime}
          onChange={handleDateChange}
        />
        <DropDown
          className="w-15 mb-3"
          object={true}
          data={Status}
          text="Status"
          name="status"
          value={filterDataObj?.status}
          onChange={handleDateChange}
        />
        
        <DropDown
          className="w-15 mb-3"
          data={createdBySet}
          text="Created By"
          name="createdBy"
          value={filterDataObj?.createdBy}
          onChange={handleDateChange}
        />


        <Form.Group className="form-group w-20 ms-2 mb-3">
          <Form.Label
            htmlFor="exampleFormControlInputText5"
            style={{ visibility: "hidden" }}
          >
            Filter Reset
          </Form.Label>
          <div className="form-control-wrap d-flex me-2">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObj({
                  ...filterDataObj,
                  startDate: "",
                  endDate: "",
                  product: "",
                  batchTime: "",
                  name: "",
                  status: "",
                  testType: "",
                  teacher: "",
                  createdBy:"",
                  test:""
                });
                {
                  currentFilterName == 'all' || currentFilterName == undefined &&
                  setData(allData);
                }
                {
                  currentFilterName == 'upcoming' &&
                  setData(upcomingApiFilterResult);
                }
                {
                  currentFilterName == 'expired' &&
                  setData(expiredApiFilterResult);
                }

                
                
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" onClick={filterData}>
              Search
            </Button>
          </div>
        </Form.Group>
      </div>
    </>
  );
}

export default StudentFilter;

function DropDown({ object, value, text, name, data, onChange }) {
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label>{text}</Form.Label>
        <div className="form-control-wrap me-2" style={{ width: "140px" }}>
          <Form.Select
            removeItemButton
            value={value}
            name={name}
            onChange={onChange}
          >
            <option value="">All</option>
            {data?.length > 0 &&
              data.map((item) => {
                return (
                  <>
                    <option value={object ? item?.id : item}>
                      {object ? item?.value : item}
                    </option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
    </>
  );
}
