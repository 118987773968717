// import React from 'react';

// const TicketSvg = ({ fill = '#000000' }) => {
//   return (
//     <svg
//     xmlns='http://www.w3.org/2000/svg'
//     width='128'
//     height='128'

//     viewBox='0 0 128 128'
//   >
//     <path
//       d='M121.5 64c1 0 1.7-.8 1.8-1.7V40.5c0-1-.8-1.7-1.8-1.8h-4.8l-2.1-14.3c-.1-1-1-1.6-2-1.5l-27.1 4h-.1L6.2 38.8s-1.5.3-1.5 1.7v21.7c0 1 .8 1.7 1.7 1.8 4.4 0 7.9 3.5 7.9 7.9s-3.5 7.9-7.9 7.9c-1 0-1.7.8-1.8 1.7v21.7c0 1 .8 1.7 1.8 1.8h115c1 0 1.7-.8 1.8-1.8V81.6c0-1-.8-1.7-1.7-1.8-4.4 0-7.9-3.5-7.9-7.9s3.5-7.9 7.9-7.9zM84.2 30.7l.7 4.7c.1.9.9 1.5 1.7 1.5h.3c1-.1 1.6-1 1.5-2l-.7-4.7 23.7-3.5 1.8 12.1H30.1zm35.6 52.5v18.4H93.9v-4.8c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v4.8h-82V83.2c6.2-1 10.5-6.8 9.5-13-.8-4.9-4.6-8.8-9.5-9.5V42.3h82.1V47c0 1 .8 1.8 1.8 1.8S94 48 94 47v-4.8h25.9v18.4c-6.2 1-10.5 6.8-9.5 13 .6 5 4.4 8.8 9.4 9.6z'
//       data-original='#000000'
//     ></path>
//     <path
//       d='M92.1 74.6c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V76.4c0-1-.8-1.8-1.8-1.8zm0-20.4c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V55.9c0-.9-.8-1.7-1.8-1.7zM27.3 57H49c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7H27.3c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7zm43.4 7.6H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z'
//       data-original='#000000'
//     ></path>
//   </svg>
//   );
// }

// export default TicketSvg;



import React from 'react';

const ExamSvg = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="512" height="512" x="0" y="0" viewBox="0 0 512 512" ><g><path d="M175.5 145h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM175.5 225h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15zM175.5 305h-80c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15s-6.716-15-15-15z" fill={fill} data-original="#000000" class="" opacity="1"></path><path d="M390.5 274.928V137.98c0-19.77-7.623-38.421-21.465-52.527l-60.862-62.939-.088-.091C294.072 8.173 274.573 0 254.59 0H61.5c-24.813 0-45 20.187-45 45v389c0 24.813 20.187 45 45 45h231.833c21.376 20.433 50.331 33 82.167 33 66.168 0 120-53.383 120-119 0-60.58-45.885-110.729-105-118.072zM46.5 434V45c0-8.271 6.729-15 15-15h193.09c11.974 0 23.658 4.891 32.066 13.418l60.851 62.929.086.089c8.323 8.467 12.907 19.669 12.907 31.545v136.962c-58.562 7.398-104 57.521-104 118.058 0 20.233 5.082 39.3 14.027 56H61.5c-8.271-.001-15-6.73-15-15.001zm329 48c-49.075 0-89-39.925-89-89s39.925-89 89-89c49.626 0 90 39.925 90 89s-40.374 89-90 89z" fill={fill} data-original="#000000" class="" opacity="1"></path><path d="M410.103 357.477c-6.363-5.301-15.822-4.442-21.126 1.921l-25.317 30.381-5.743-11.486c-3.705-7.41-12.715-10.415-20.125-6.708-7.41 3.704-10.413 12.715-6.708 20.124l16 32a15.001 15.001 0 0 0 24.94 2.895l40-48c5.303-6.366 4.443-15.824-1.921-21.127zM255.5 175h16c8.284 0 15-6.716 15-15s-6.716-15-15-15h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15zM286.5 240c0-8.284-6.716-15-15-15h-16c-8.284 0-15 6.716-15 15s6.716 15 15 15h16c8.284 0 15-6.716 15-15z" fill={fill} data-original="#000000" class="" opacity="1"></path></g></svg>
    
  );
}

export default ExamSvg;

