import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ModalContext from "../../../../context/ModalContext";
import { useParams } from "react-router-dom";

function PracticeTextArea({isSubmitted, handleRedo, getQuestionBy_id}) {
    let { id } = useParams();
    const [wordCount, setWordCount] = useState(0);
    const { answerJson, setAnswerJson } = useContext(ModalContext);
    console.log("answerJson",answerJson);
    const textAreaRef = useRef(null);

    console.log("ANSWERJSONTEXT",answerJson.answer);
    console.log("isSubmitted",isSubmitted);

    const visibleTodos = useMemo(() => answerJson.answer, [answerJson])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key !== "z" && e.key !== "x") {
                e.preventDefault(); // Prevent default for all Ctrl commands except Ctrl+Z and Ctrl+X
            } else if (e.ctrlKey && e.key === "z") {
                // Handle Ctrl+Z (Undo) here
                // Implement your logic to undo the action
            } else if (e.ctrlKey && e.key === "x") {
                // Handle Ctrl+X (Cut) here
                const selectedText = textAreaRef.current.value.substring(
                    textAreaRef.current.selectionStart,
                    textAreaRef.current.selectionEnd
                );
                document.execCommand("cut"); // Cut the selected text
                // You can store the cut text in state or perform additional actions
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleTextAreaChange = (e) => {
        const text = e.target.value;
        setAnswerJson({ ...answerJson, answer: e.target.value });
        const words = text.split(/\s+/);
        const filteredWords = words.filter((word) => word !== "");
        setWordCount(filteredWords.length);
    };

    const handleCut = () => {
        document.execCommand("cut");
    };

    const handleCopy = () => {
        document.execCommand("copy");
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            const currentText = textAreaRef.current.value;
            const selectionStart = textAreaRef.current.selectionStart;
            const selectionEnd = textAreaRef.current.selectionEnd;
            const newText =
                currentText.substring(0, selectionStart) +
                text +
                currentText.substring(selectionEnd);
            textAreaRef.current.value = newText;
            setAnswerJson({ ...answerJson, answer: newText });
            setWordCount(newText.split(/\s+/).filter((word) => word !== "").length);
        } catch (err) {
            console.error("Failed to paste:", err);
        }
    };

    return (
        <>
            <div className="form-control-wrap mb-5">
                <Form.Control value={visibleTodos} disabled={isSubmitted} as="textarea" ref={textAreaRef} placeholder="" id="exampleFormControlTextarea8" onChange={handleTextAreaChange} rows="6" style={{overflowY:"scroll", resize:"none",color:isSubmitted && "rgba(0,0,0,.25)" ,backgroundColor:isSubmitted ? "#f5f5f5 !important":"rgb(248, 248, 249)",cursor:isSubmitted && "not-allowed",opacity:isSubmitted && "1"}}></Form.Control>  
                {isSubmitted && <div className="redo-btn"><Button variant="assigned" style={{backgroundColor:"#50dec9",borderColor:"#50dec9", borderRadius:"100px",width:"120px"}} size="md" onClick={(e) => {
                            handleRedo(e);
                            getQuestionBy_id(id);
                          }}
                        >
                          Re-do
                </Button></div>}              
                <div className="d-flex justify-content-between mt-3">
                    <Button className="btn textarea-btn" variant="outline-light" onClick={handleCut}> Cut </Button>
                    <Button className="btn textarea-btn" variant="outline-light" onClick={handleCopy}> Copy </Button>
                    <Button className="btn textarea-btn" variant="outline-light" onClick={handlePaste}> Paste </Button>
                </div>
                <p className="text-secondary word-count mt-2">Total Word Count: {wordCount}</p>
            </div>
        </>
    );
}

export default PracticeTextArea;