import React, { useState, useContext, useEffect } from "react";
import { ProgressBar, Spinner, Button, Modal, Form } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import ModalContext from "../../../../context/ModalContext";
import Timer from "../Timer";
import Audio from "../customAudio/audio";
import InputCheckbox from "../inputComponents/inputCheckbox";
import AudioUI from "../Istructions/ExamComponents/AudioUI";
import RecorderUI from "../Istructions/ExamComponents/RecorderUI";
import Video from "../Video";
function ListeningMultiChoiceMulti({ data }) {
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [selectedWords, setSelectedWords] = useState([]);
  const [startAudio, setStartAudio] = useState(false);
  const paragraphText = data?.question_content;

  const handleWordClick = (word) => {
    if (selectedWords.includes(word)) {
      setSelectedWords(selectedWords.filter((w) => w !== word));
    } else {
      setSelectedWords([...selectedWords, word]);
    }
  };
  useEffect(() => {
    var s = selectedWords.join(",");
  }, [selectedWords]);
  const[count,seTcount] = useState(0)
useEffect(()=>{
  seTcount(count+1)
},[data])
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5>
        <em className="pte-test-instructions-bold">{data.instructions}</em>
        </h5>
        <div className="d-flex justify-content-center gap-4 my-5">
          <div className="d-flex flex-column w-50">
            {/* <h3>
              Read the text and answer the question by selecting all the correct
              responses. You will need to select more then one response.
            </h3> */}
            <p>{data?.question} </p>
            <InputCheckbox key={count} data={data.question_options} />
          </div>
          <div className="w-50">
            {/* {data.question_content_video && (
              <video width="500" controls autoplay>
                <source
                  src={data.question_content_video}
                  type="video/mp4"
                  className="exam-img"
                />
              </video>
            )} */}

            <div className="d-flex justify-content-center align-items-center gap-4 my-5">
            {data?.question_content_video?.trim() && (
            <Video
            timer={
              data.question_begin_time
                ? data.question_begin_time
                : "00:00"
            }
              data={data}
              startRecording={() => {
                setStartAudio(true);
                // startRec();
              }}
            />
          )}
          {!data?.question_content_video &&
            data?.question_content_image &&
            data?.question_content_image !== "null" && (
              <img
                src={data?.question_content_image}
                className="exam-img"
                alt="no img"
              ></img>
            )}
              {/* <img
                    src="/images/delicious-ice-cream-cones.jpg"
                    className="exam-img"
                  ></img> */}
              <div className="d-flex flex-column align-items-center">
                {(data.question_content_audio?.trim() )  && (
                  <AudioUI
                    timer={
                      data.question_begin_time
                        ? data.question_begin_time
                        : "00:00"
                    }
                    audio={data.question_content_audio}
                    startRecording={() => {
                      setStartAudio(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListeningMultiChoiceMulti;
