import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";

function SectionWiseTestResults() {
  let api = new AuthApi();
  let { userData } = useContext(Context);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    get_student_by_inst();
  }, []);

  const get_student_by_inst = async () => {
    await api
      .get_student_by_inst({ inst_id: JSON.parse(userData)?.teacher_profile?.inst_id || JSON.parse(userData)?._id })
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const StudentColumns = [
    {
      name: <Form.Check className="form-check" id="check-all" />,
      cell: (row) => (
        <div className="text-start w-100">
          <Form.Check className="form-check" />
        </div>
      ),
      sortable: false,
    },
    {
      name: "Email",
      grow: "3",
      selector: (row) => row.name,
      cell: (row) => (
        <MediaGroup>
          {/* <Media size="md" shape="circle" variant={row.theme && row.theme}>
            {row.avatar ? (
              <Image src={row.avatar} staticImage />
            ) : (
              <span className="smaller fw-medium">{row.name}</span>
            )}
          </Media> */}
          <MediaText>
            <Link
              to={`/student-management/student-detail/${row._id}`}
              className="title"
            >
              {row.email}
            </Link>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Name",
      grow: "3",
      selector: (row) => row.product,
      cell: (row) => <span className="text-middark">{row.name}</span>,
      sortable: true,
    },
    {
      name: "Product",
      grow: "3",
      selector: (row) => row.product,
      cell: (row) => <span className="text-middark">{row.product}</span>,
      sortable: true,
    },
    {
      name: "Mobile",
      grow: "2",
      selector: (row) => row.username,
      cell: (row) => <span className="text-middark">{row.mobile}</span>,
      sortable: true,
    },
    // {
    //   name: "Student Type",
    //   selector: (row) => row.studenttype,
    //   cell: (row) => <span className="text-middark">{row.studenttype}</span>,
    //   sortable: true,
    //   hide: "lg",
    // },
    {
      name: "Teachers",
      grow: "3",
      selector: (row) => row.assigned,
      cell: (row) => <span className="text-middark">{row?.stu_profile?.assignTeacher}</span>,
      sortable: true,
    },
    {
      name: "Batch",
      grow: "3",
      selector: (row) => row.attempt,
      cell: (row) => <span className="text-middark">{row?.stu_profile?.batchTime}</span>,
      sortable: true,
    },
    {
      name: "Test",
      grow: "3",
      selector: (row) => row.score,
      cell: (row) => <span className="text-middark">{row?.stu_profile?.mockTest}</span>,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status,
      cell: (row) => (
        // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //     : row.status === "Inactive" ? "danger"
        //         : "primary"}`
        // }>
        <span className={`badge text-bg-${row.is_deleted == 0 ? "success"
          : row.is_deleted == 1 ? "danger"
            : "primary"}`
        }>
          {row.is_deleted == 0 ? "Active" : 'Inactive'}
        </span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem>
            <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
              <Icon name="trash"></Icon>
            </LinkListItem>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Section Wise Test Result</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <h5>Section Wise Test Result</h5>
                {/* <StudentDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  columns={StudentColumns}
                /> */}

                {/* You can use data list component */}

                <Table responsive striped bordered hover className="small">
                  <thead>
                    <tr className="bg-primary">
                      <th className="text-white">Name</th>
                      <th className="text-white">Test Name</th>
                      <th className="text-white">End Date</th>
                      <th className="text-white">Total Score</th>
                      <th className="text-white">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>asd</td>
                      <td>PTE</td>
                      <td>17 Aug 2023</td>
                      <td>86/100</td>
                      <td>
                        <div className="text-end w-100">
                          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
                            <LinkListItem to='#'>
                              <Icon name="edit"></Icon>
                            </LinkListItem>
                            <LinkListItem to='#'>
                              <Icon name="trash"></Icon>
                            </LinkListItem>
                          </LinkList>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default SectionWiseTestResults;
