import { Row, Nav, Tab, Tabs, Col, Card, Dropdown, Table, Tooltip, OverlayTrigger, ListGroup } from 'react-bootstrap';

import Layout from '../layout/default';
import { Media, MediaGroup, MediaText, Icon, ChartLabel, ChartLegend, CustomDropdownMenu, CustomDropdownToggle, OverlineTitle, Timeline, WorldMap } from '../components';
import { ChartBar, ChartLine, ChartDoughnut, ChartPie } from "../components/Chart/Charts";
import { Colors } from '../utilities/index';
import hexRGB from '../utilities/hexRGB';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import Context from '../context';
import { useNavigate } from 'react-router-dom';
import AdminApi from '../api/admin';
import AuthApi from '../api/auth';
import AddEnquiryModal from './studentpages/AddEnquiryModal';
import { years } from '../utilities/monthsAndYears';
import OverrideContext from '../context/OverrideContext/OverrideContext';
function Home() {


  const {overrideData}=useContext(OverrideContext)

  console.log(overrideData,"overrideData313")
  // pie chart data
  let pieChartData = {
    labels: ["Send", "Receive", "Withdraw"],
    datasets: [{
      backgroundColor: [Colors.orange, Colors.blue, Colors.green],
      data: [110, 80, 125]
    }],
  };
  let lineChartFilled = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    legend: true,
    datasets: [
      {
        tension: .4,
        label: "Total Received",
        borderWidth: 2,
        borderColor: Colors.primary,
        backgroundColor: hexRGB(Colors.primary, .2),
        pointBorderColor: Colors.primary,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.primary,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95]
      }
    ]
  };

  // referral  data
  // let referralData = [
  //   {
  //     id: 'uid01',
  //     theme: 'dark',
  //     icon: 'github-circle',
  //     website: 'github.com',
  //     visitor: '3.4K',
  //     revenue: '$3.877',
  //     sale: '267',
  //     conversion: '4.7%'
  //   },
  //   {
  //     id: 'uid02',
  //     theme: 'info',
  //     icon: 'twitter',
  //     website: 'twitter.com',
  //     visitor: '2.5K',
  //     revenue: '$3.426',
  //     sale: '265',
  //     conversion: '4.4%'
  //   },
  //   {
  //     id: 'uid03',
  //     theme: 'danger',
  //     icon: 'google',
  //     website: 'google.com',
  //     visitor: '2.0K',
  //     revenue: '$2.444',
  //     sale: '264',
  //     conversion: '4.7%'
  //   },
  //   {
  //     id: 'uid04',
  //     theme: 'info',
  //     icon: 'vimeo',
  //     website: 'vimeo.com',
  //     visitor: '1.9K',
  //     revenue: '$2.877',
  //     sale: '261',
  //     conversion: '3.6%'
  //   },
  //   {
  //     id: 'uid05',
  //     theme: 'warning',
  //     icon: 'digital-ocean',
  //     website: 'digital-ocean.com',
  //     visitor: '1.8K',
  //     revenue: '$2.870',
  //     sale: '260',
  //     conversion: '3.4%'
  //   }
  // ];





  let { userData, setUserData, token, adminToStudent, setAdminToStudent } = useContext(Context);
  const [data, setData] = useState({})
  const [pendingFollowups, setPendingFollowups] = useState({count:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]})
  const [averageResult, setAverageResults] = useState([])
  const [topperStudents, setTopperStudents] = useState([])
  const [pendingFollowUpFilter, setPendingFollowUpFilter] = useState({ customMonth: new Date().getMonth() + 1, customYear: new Date().getFullYear() })
  const [institutePlan, setInstitutePlan] = useState({})
  console.log("EERER",institutePlan);
  userData = JSON.parse(userData)
  console.log("userData3223", userData)
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  console.log("PENDING FOLLOWUPS", pendingFollowups);
  console.log("averageResult", averageResult);
  console.log("topperStudents", topperStudents);
  let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  let api = new AuthApi()

  function getNext14Days() {
    const datesArray = [];
    const today = new Date();
  
    for (let i = 0; i < 14; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
  
      const formattedDate = futureDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
      });
  
      datesArray.push(formattedDate);
    }
  
    return datesArray;
  }
  
  const dates = getNext14Days();
  console.log("dates",dates);


  useEffect(() => {
    get_institute_dashboard();
 
    get_student_results_average_by_institute_dashboard()
    get_topper_students_by_institute_dashboard()
  }, [])

  let adminApi = new AdminApi();
  useEffect(() => {
    get_pending_followups_by_institute_dashboard()
  }, [pendingFollowUpFilter])

  console.log("COUNTTT",pendingFollowups.count);


useEffect(()=>{
  if(overrideData!=undefined)
  getInsitutePlan();
},[overrideData])
  

  const getInsitutePlan = async () => {
    await adminApi
      .get_active_plans_by_inst(userData?._id,overrideData)
      .then((res) => {
        if (res.status) {
          if(overrideData == 0){

            setInstitutePlan(res.data)
          }
          else{
            setInstitutePlan(res.data?.plan_data)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const get_pending_followups_by_institute_dashboard = async () => {
    await api.get_pending_followups_by_institute_dashboard(userData?._id, pendingFollowUpFilter).then((res) => {
      if (res?.status) {
        for(let obj of res.data){
          count[new Date(obj.date).getDate()-1] = obj.count
          console.log("CNT",obj.count);
        }
        console.log("count",count);
        setPendingFollowups({count:count})

      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const get_student_results_average_by_institute_dashboard = async () => {
    await api.get_student_results_average_by_institute_dashboard(userData?._id).then((res) => {
      if (res?.status) {
        setAverageResults([...res.data.map((ele) => ele.average_score)])

      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const get_topper_students_by_institute_dashboard = async () => {
    await api.get_topper_students_by_institute_dashboard(userData?._id).then((res) => {
      if (res?.status) {
        setTopperStudents(res.data)

      }
    }).catch((err) => {
      console.log(err)
    })
  }



  const get_institute_dashboard = async () => {
    await api.get_institute_dashboard(userData?._id).then((res) => {
      if (res?.status) {
        setData({ ...res.data })
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  console.log("macbook", data);
  // useEffect(() => {
  //   document.getElementsByClassName("nk-header")[0].style.setProperty("background-color", userData?.inst_profile?.color_code, "important");
  // },[data])

  const [teamMember, setTeamMember] = useState([]);
  const getTeamByInstituteId = async (instId) => {
    console.log("instid", instId, token);
    await api
      .get_teachers_By_Inst({ id: instId, token })
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setTeamMember(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    if (userData?._id && token) getTeamByInstituteId(userData?._id);
  }, []);
  const teachers = teamMember?.map((item) => item?.name.toLowerCase());

  const lineChartData = {
    labels: ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    legend: true,
    datasets: [
      {
        label: "Total Received",
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.primary,
        pointBorderColor: Colors.primary,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.primary,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        data: averageResult
      }
    ]
  };

  // const barChartData = {
  //   labels: pendingFollowups.length > 0 && pendingFollowups.map((ele) => { return ele.date }),
  //   legend: true,
  //   datasets: [
  //     {
  //       label: "Bar data",
  //       borderColor: userData?.inst_profile?.color_code,
  //       backgroundColor: userData?.inst_profile?.color_code,
  //       borderWidth: 1,
  //       data: pendingFollowups.length > 0 && pendingFollowups.map(ele => ele.count)
  //     }
  //   ]
  // }
  const barChartData = {
    labels: days,
    legend: true,
    datasets: [
      {
        label: "Bar data",
        borderColor: userData?.inst_profile?.color_code,
        backgroundColor: userData?.inst_profile?.color_code,
        borderWidth: 1,
        data: pendingFollowups.count
      }
    ]
  }
  
  // sessions chart
  let sessionChart = {
    labels: [pendingFollowups.length > 0 && pendingFollowups.map((ele) => { return ele.date })],
    xAxis: false,
    borderSolid: [1],
    barThickness: 40,
    datasets: [
      {
        borderRadius: 2,
        borderColor: userData?.inst_profile?.color_code,
        backgroundColor: userData?.inst_profile?.color_code,
        label: "Session",
        data: [pendingFollowups.length > 0 && pendingFollowups.map((ele) => { return ele.count })]
      },
    ]
  };
  // audience overview chart
  let audienceOverviewChart = {
    labels: ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    // ticksValue: 'k',
    borderDash: [8, 4],
    datasets: [
      {
        tension: .4,
        borderWidth: 2,
        borderColor: Colors.warning,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.warning,
        label: "Average Overall Score",
        data: averageResult
      }
    ]
  };
const [theme,setTheme]=useState("pills-home")
  return (
    <Layout title="Analytics">
      <div className='d-flex flex-column flex-md-row gap-2 justify-content-between mb-5'>
        <h1>Welcome {userData?.login_type == "1" && userData?.inst_profile?.inst_contact_firstname + " " + userData?.inst_profile?.inst_contact_lastname || userData?.login_type == 0 && "Admin"}</h1>
        <div className="d-flex flex-column flex-sm-row gap-2 justify-content-end">
          {/* {userData?.login_type === "1" &&        <Button className="d-md-inline-flex ms-3" variant="outline-primary" onClick={()=>navigate('/otherpages/enquiry')}> */}
          {userData?.login_type === "1" && <Button className="d-md-inline-flex ms-3 custom-button" variant="outline-primary" onClick={handleShow}

>
            <Icon name="plus" /> <span> Add Enquiries</span>
         
          </Button>}
          <Button className="d-md-inline-flex ms-3 custom-button" variant="outline-primary" onClick={() => navigate("/student-management/add-student")}>
            <Icon name="plus" />  <span>Add Student</span>
          </Button>
        </div>
      </div>
      {show && (
        <AddEnquiryModal

          teachers={teachers}
          setShow={(data) => setShow(data)}
          show={show}
        />
      )}
      <Row className="g-gs">
        <Col xl="3">
          <Card className="h-100 card-hiprimary">
            <Card.Body className='d-flex justify-content-between align-items-start'>
              <div className='d-flex mt-3'>
                <Media size="sm" shape="sequre" className='border border-hiprimary me-2 bg-hiprimary-soft text-middark border-2 home-icon'>
                  <img src="/images/wallet.svg"></img>
                </Media>
                <div className='d-flex flex-column'>
                  <h5 className='my-0'>{data?.credit}</h5>
                  <p className='text-secondary'>Total Credit</p>
                </div>
              </div>
              {/* <div className="card-title-group flex-wrap justify-content-end">
                <ChartLegend.Group className="gap gx-3 align-items-center">
                  <div className="gap-col">
                    <Dropdown>
                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                        <Icon name="more-v"></Icon>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                        <Dropdown.Header className="py-2">
                          <h6 className="mb-0">Options</h6>
                        </Dropdown.Header>
                        <Dropdown.Divider className="mt-0" />
                        <Dropdown.Item>7 Days</Dropdown.Item>
                        <Dropdown.Item>15 Days</Dropdown.Item>
                        <Dropdown.Item>30 Days</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </ChartLegend.Group>
              </div> */}

            </Card.Body>
          </Card>
        </Col>

        <Col xl="3">
          <Card className="h-100 card-hisuccess">
            <Card.Body className='d-flex justify-content-between align-items-start'>
              <div className='d-flex mt-3'>
                <Media size="sm" shape="sequre" className='border border-hisuccess me-2 bg-hisuccess-soft text-light border-2 home-icon'>
                  <img src="/images/plan.svg"></img>
                </Media>
                <div className='d-flex flex-column'>
                  {/* <h5 className='my-0'>Free Plan</h5> */}
                  <h5 className='text-secondary'>Active Plan</h5>
                  <p className='text-black'>{userData?.login_type == "1" ? institutePlan?.plan_name : "Free Plan"}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xl="3">
          <Card className="h-100 card-hiwarning">
            <Card.Body className='d-flex justify-content-between align-items-start'>
              <div className='d-flex mt-3'>
                <Media size="sm" shape="sequre" className='border border-hiwarning me-2 bg-hiwarning-soft text-light border-2 home-icon'>
                  <img src="/images/students.svg"></img>
                </Media>
                <div className='d-flex flex-column'>
                  <h5 className='my-0'>{data?.total_students}</h5>
                  <p className='text-secondary'>Total Students</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xl="3">
          <Card className="h-100 card-hidanger">
            <Card.Body className='d-flex justify-content-between align-items-start'>
              <div className='d-flex mt-3'>
                <Media size="sm" shape="sequre" className='border border-hidanger me-2 bg-hidanger-soft text-light border-2 home-icon'>
                  <img src="/images/team.svg"></img>
                </Media>
                <div className='d-flex flex-column'>
                  <h5 className='my-0'>{data?.total_teams}</h5>
                  <p className='text-secondary'>Total Team</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <h1 className='mt-5 mb-3'>Last 7 Days Student Creation</h1>

      <Row className="g-gs my-3">
        <Col sm="12" xxl="4" md="4" className='d-flex flex-column gap-5'>
          <Card className="h-50">
            <Card.Body className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <Media size="sm" shape="circle" className='me-2 bg-hiprimary-soft text-secondary home-icon'>
                  <span>1</span>
                </Media>
                <h5 className='my-0'>Bulk Test Students</h5>
              </div>
            </Card.Body>
          </Card>
          <Card className="h-50">
            <Card.Body className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <Media size="sm" shape="circle" className='me-2 bg-hiprimary-soft text-secondary home-icon'>
                  <span>0</span>
                </Media>
                <h5 className='my-0'>Single Test Students</h5>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" xxl="8" md="8">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Tests Progress</h5>
                </div>
              </div>
              <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-home">
                <Nav variant="pills" className="mb-3 justify-content-end gap-2" style={{ marginTop: "-30px" }}>
                  <Nav.Item>
                    <Nav.Link eventKey="pills-home">Open in Pie Chart</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pills-profile">Open in Graph</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="pills-home">
                    <Row className='align-items-center'>
                      <Col xxl="8" sm="8">
                        <div className="nk-chart-analytics-session-device">
                          <ChartDoughnut data={sessionsDevice} />
                        </div>
                      </Col>
                      <Col xxl="4" sm="4">
                        <ChartLegend.Group className="flex-column justify-content-around flex-wrap gap g-2">
                          <ChartLegend symbol="completed">
                            Completed
                          </ChartLegend>
                          <ChartLegend symbol="pending">
                            Pending
                          </ChartLegend>
                          <ChartLegend symbol="assigned">
                            Assigned
                          </ChartLegend>
                          <ChartLegend symbol="inprogress">
                            In Progress
                          </ChartLegend>
                        </ChartLegend.Group>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="pills-profile">
                    <div className="card-title-group flex-wrap">
                      <ChartLegend.Group className="gap gx-3 align-items-center">
                        <div className="gap-col">
                          <ChartLegend className="chart-legend-small" symbol="warning">
                            New visits
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <ChartLegend className="chart-legend-small" symbol="info">
                            Unique visits
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <Dropdown>
                            <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                              <Dropdown.Header className="py-2">
                                <h6 className="mb-0">Options</h6>
                              </Dropdown.Header>
                              <Dropdown.Divider className="mt-0" />
                              <Dropdown.Item>7 Days</Dropdown.Item>
                              <Dropdown.Item>15 Days</Dropdown.Item>
                              <Dropdown.Item>30 Days</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </ChartLegend.Group>
                    </div>
                    <div className="nk-chart-analytics-audience-overview mt-3">
                      <ChartLine data={audienceOverviewChart} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="pills-contact">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur cupiditate dolorem ipsa sequi rerum suscipit, tenetur exercitationem debitis! Incidunt obcaecati dolorem, mollitia est quaerat dignissimos amet placeat soluta eaque quas.
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <Row className="g-gs my-3">
        <Col sm="12" md="6" xxl="6">
          <Card className="h-100">
            <Card.Body>
              <div className='d-flex justify-content-between'>
                <p className='text-secondary'></p>
                <p className='text-secondary d-flex flex-wrap justify-content-end'><img src="/images/refresh.svg" style={{ width: "15px" }}></img></p>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='text-secondary d-flex flex-column'>
                  <h2>{data?.todayAddFollowupCount}</h2> <span>Today's Follow Ups</span>
                </div>
                <p className='text-secondary d-flex flex-wrap justify-content-end'>
                  <Media size="sm" shape="circle" className='me-2 bg-hiprimary-soft text-secondary home-icon'>
                    <img src="/images/ticket.svg"></img>
                  </Media></p>
              </div>
              {/* <ProgressBar now={60} className='mt-2' style={{ height: "6px" }} /> */}
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" md="6" xxl="6">
          <Card className="h-100">
            <Card.Body>
              <div className='d-flex justify-content-between'>
                <p className='text-secondary'></p>
                <p className='text-secondary d-flex flex-wrap justify-content-end'><img src="/images/refresh.svg" style={{ width: "15px" }}></img></p>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='text-secondary d-flex flex-column'>
                  <h2>{data?.todayAddStudentCount}</h2> <span>Today add Students</span>
                </div>
                <p className='text-secondary d-flex flex-wrap justify-content-end'>
                  <Media size="sm" shape="circle" className='me-2 bg-hiprimary-soft text-secondary home-icon'>
                    <img src="/images/tasklist.svg"></img>
                  </Media></p>
              </div>
              {/* <ProgressBar now={60} className='mt-2' style={{ height: "6px" }} /> */}
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" md="6" xxl="6">
          <Card className="h-100">
            <Card.Body>
              <div className='d-flex justify-content-between mb-3'>
                <p className='text-secondary'>Program-Wise Active Students</p>
                <p className='text-secondary d-flex flex-wrap justify-content-end'><img src="/images/refresh.svg" style={{ width: "15px" }}></img></p>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='text-secondary d-flex'>
                  <Media size="sm" shape="sequre" className='d-flex flex-column border border-hiprimary p-5 me-2 bg-hiprimary-soft text-middark border-2 home-icon'>
                    <h3 className='m-0'>{data?.pteCount?data?.pteCount:0}</h3>
                    <p>PTE_CORE</p>
                  </Media>
                  <Media size="sm" shape="sequre" className='d-flex flex-column border border-hiwarning p-5 me-2 bg-warning-soft text-middark border-2 home-icon'>
                    <h3 className='m-0 p-'>{data?.pteACADEMICCount?data?.pteACADEMICCount:0}</h3>
                    <p>PTE_ACADEMIC</p>
                  </Media>
                </div>
                <p className='text-secondary d-flex flex-wrap justify-content-end'>
                  <Media size="sm" shape="circle" className='me-2 bg-hiprimary-soft text-secondary home-icon'>
                    <img src="/images/user.svg"></img>
                  </Media></p>
              </div>
              {/* <ProgressBar now={0} className='mt-2' style={{ height: "6px" }} /> */}
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12" md="6" xxl="6">
          <Card className="h-100">
            <Card.Body>
              <div className='d-flex justify-content-between mb-3'>
                <p className='text-secondary'></p>
                <p className='text-secondary d-flex flex-wrap justify-content-end'><img src="/images/refresh.svg" style={{ width: "15px" }}></img></p>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='text-secondary d-flex flex-column'>
                  <h2>{data?.todayEnquiryCount}</h2> <span>Today's Enquiries</span>
                </div>
                <p className='text-secondary d-flex flex-wrap justify-content-end'>
                  <Media size="sm" shape="circle" className='me-2 bg-hiprimary-soft text-secondary home-icon'>
                    <img src="/images/students.svg"></img>
                  </Media></p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-gs my-3">
        <Col sm="12" xxl="12">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Mock Tests Results Generated in Last 14 Days</h5>
                </div>
              </div>
              <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-home">
                <Nav variant="pills" className="mb-3 justify-content-end gap-2 " style={{ marginTop: "-30px" }}>
                  <Nav.Item>
                    <Nav.Link eventKey="pills-home" className='ds-menu-link' >Open in Graph</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pills-profile" className='ds-menu-link'  >Open in Pie Chart</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="pills-home">
                    {/* <div className="card-title-group flex-wrap">
                      <ChartLegend.Group className="gap gx-3 align-items-center">
                        <div className="gap-col">
                          <ChartLegend className="chart-legend-small" symbol="warning">
                            New visits
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <ChartLegend className="chart-legend-small" symbol="info">
                            Unique visits
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <Dropdown>
                            <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                              <Dropdown.Header className="py-2">
                                <h6 className="mb-0">Options</h6>
                              </Dropdown.Header>
                              <Dropdown.Divider className="mt-0" />
                              <Dropdown.Item>7 Days</Dropdown.Item>
                              <Dropdown.Item>15 Days</Dropdown.Item>
                              <Dropdown.Item>30 Days</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </ChartLegend.Group>
                    </div> */}
                    <div className="nk-chart-analytics-audience-overview mt-3">
                      <ChartLine data={audienceOverviewChart} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="pills-profile">
                    {/* <div className="card-title-group flex-wrap">
                      <ChartLegend.Group className="gap gx-3 align-items-center">
                        <div className="gap-col">
                          <ChartLegend className="chart-legend-small" symbol="warning">
                            New visits
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <ChartLegend className="chart-legend-small" symbol="info">
                            Unique visits
                          </ChartLegend>
                        </div>
                        <div className="gap-col">
                          <Dropdown>
                            <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                              <Icon name="more-v"></Icon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                              <Dropdown.Header className="py-2">
                                <h6 className="mb-0">Options</h6>
                              </Dropdown.Header>
                              <Dropdown.Divider className="mt-0" />
                              <Dropdown.Item>7 Days</Dropdown.Item>
                              <Dropdown.Item>15 Days</Dropdown.Item>
                              <Dropdown.Item>30 Days</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </ChartLegend.Group>
                    </div> */}
                    <div className="nk-chart-analytics-audience-overview mt-3">
                      <ChartPie data={pieChartData} />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-gs my-3">
        <Col sm="12" xxl="12">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h5 className="title">Pending Follow-Ups in Last 14 Days</h5>
                </div>
                <div className='d-flex justify-content-end align-items-center gap-2'>
                  <h4 className='mb-0'>Year</h4>
                  <select className='select-drop' onChange={(e) => { setPendingFollowUpFilter({ ...pendingFollowUpFilter, customYear: e.target.value }) }} value={pendingFollowUpFilter?.customYear} id="year">
                    {years().map((ele, key) => {
                      return <option value={ele}>{ele}</option>
                    })}
                  </select>

                  <h4 className='mb-0'>Month</h4>
                  <select className='select-drop' onChange={(e) => { setPendingFollowUpFilter({ ...pendingFollowUpFilter, customMonth: e.target.value }) }} value={pendingFollowUpFilter?.customMonth} id="month">
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </div>

              <div className="nk-chart-analytics-session mt-3">
                <ChartBar data={barChartData} />
              </div>
        
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-gs my-3">
        <Col xxl="12">
          <Card className="h-100">
            <Card.Body className="flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Student IDs Expiring Soon</h5>
                </div>
              </div>
            </Card.Body>
            <Table responsive className="table-middle mb-0">
              <thead className="table-light table-head-sm">
                <tr>
                  <th className="tb-col">
                    <OverlineTitle tag="span">#</OverlineTitle>
                  </th>
                  <th className="tb-col tb-col-end">
                    <OverlineTitle tag="span">ID</OverlineTitle>
                  </th>
                  <th className="tb-col tb-col-end">
                    <OverlineTitle tag="span">Name</OverlineTitle>
                  </th>
                  <th className="tb-col tb-col-end">
                    <OverlineTitle tag="span">Program</OverlineTitle>
                  </th>
                  <th className="tb-col tb-col-end">
                    <OverlineTitle tag="span">Username</OverlineTitle>
                  </th>
                  <th className="tb-col tb-col-end">
                    <OverlineTitle tag="span">Course Expiry Date</OverlineTitle>
                  </th>
                </tr>
              </thead>
              <tbody className='d-none'>
                {topperStudents.length > 0 && topperStudents.map(item =>
                  <tr key={item.id}>
                    <td className="tb-col">
                      <MediaGroup>
                        {/* <Media className="flex-shrink-0" size="md" shape="circle" variant={item.theme}>
                          <Icon name={item.icon}></Icon>
                        </Media> */}
                        <MediaText>
                          <span className="title">{item?.name}</span>
                        </MediaText>
                      </MediaGroup>
                    </td>
                    <td className="tb-col tb-col-end">
                      <span className="small">{item.visitor}</span>
                    </td>
                    <td className="tb-col tb-col-end">
                      <span className="change up small">{item.revenue}</span>
                    </td>
                    <td className="tb-col tb-col-end">
                      <span className="small">{item.sale}</span>
                    </td>
                    <td className="tb-col tb-col-end">
                      <span className="small">{item.conversion}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <Row className="g-gs my-3">
        <Col xxl="8">
          <Card className="h-100">
            <Card.Body className="flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">TOOPPERS Students</h5>
                </div>
                <div className="card-tools">
                  <Dropdown>
                    <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                      <Icon name="more-v"></Icon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                      <Dropdown.Header className="py-2">
                        <h6 className="mb-0">Options</h6>
                      </Dropdown.Header>
                      <Dropdown.Divider className="mt-0" />
                      <Dropdown.Item>7 Days</Dropdown.Item>
                      <Dropdown.Item>15 Days</Dropdown.Item>
                      <Dropdown.Item>30 Days</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Card.Body>
            <Table responsive className="table-middle mb-0">
              <thead className="table-light table-head-sm">
                <tr>
                  <th className="tb-col">
                    <OverlineTitle tag="span">ID</OverlineTitle>
                  </th>
                  <th className="tb-col">
                    <OverlineTitle tag="span">Name</OverlineTitle>
                  </th>
                  <th className="tb-col">
                    <OverlineTitle tag="span">Marks</OverlineTitle>
                  </th>
                  <th className="tb-col">
                    <OverlineTitle tag="span">Percentage</OverlineTitle>
                  </th>
                  <th className="tb-col">
                    <OverlineTitle tag="span">Year</OverlineTitle>
                  </th>
                </tr>
              </thead>
              <tbody>
                {topperStudents.length > 0 && topperStudents.map((item, key) =>
                  <tr key={item.id}>
                    <td className="tb-col">
                      <span className="small">{key + 1}</span>
                    </td>
                    <td className="tb-col">
                          <span className="title">{item?.name}</span>
                    </td>
                    <td className="tb-col">
                      <span className="change up small">{item?.overall_score[0]}</span>
                    </td>
                    <td className="tb-col">
                      <span className="small">{parseInt((item?.overall_score[0] * 94.5) / 100)}%</span>
                    </td>
                    <td className="tb-col">
                      <span className="small">{item?.year}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col xxl="4">
          <Card className="h-100">
            <Card.Body className="flex-grow-0 py-2">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Average Active Student Per Day</h5>
                  <h4>129 Student <small>(Average)</small></h4>
                </div>
              </div>
              <div className="nk-chart-overview">
                <ChartLine data={lineChartFilled} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h1 className='mt-5 mb-3'>Test Attempted in Last 7 Days</h1>

      <Row className="g-gs my-3">
        <Col md="12" xxl="12">
          <Tab.Container id="vertical-tabs" defaultActiveKey="PTE">
            <Row className="g-gs">
              <Col lg="3" xl="3">
                <Nav variant="pills" className="flex-column gap-3 hilingo-vertical-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="PTE">PTE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="IELTS">IELTS</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="TOFEL">TOFEL</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="CELPIP">CELPIP</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg="9" xl="9">
                <Tab.Content>
                  <Tab.Pane eventKey="PTE">
                    <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group">
                          <div className="card-title">
                            <h5 className="title">Test Results</h5>
                          </div>
                        </div>
                        <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-home">
                          <Nav variant="pills" className="mb-3 justify-content-end gap-2" style={{ marginTop: "-30px" }}>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-home">Mock</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-profile">Section Wise</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="pills-home">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pills-profile">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="IELTS">
                  <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group">
                          <div className="card-title">
                            <h5 className="title">Test Results</h5>
                          </div>
                        </div>
                        <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-home">
                          <Nav variant="pills" className="mb-3 justify-content-end gap-2" style={{ marginTop: "-30px" }}>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-home">Mock</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-profile">Section Wise</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="pills-home">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pills-profile">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="TOFEL">
                  <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group">
                          <div className="card-title">
                            <h5 className="title">Test Results</h5>
                          </div>
                        </div>
                        <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-home">
                          <Nav variant="pills" className="mb-3 justify-content-end gap-2" style={{ marginTop: "-30px" }}>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-home">Mock</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-profile">Section Wise</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="pills-home">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pills-profile">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="CELPIP">
                  <Card className="h-100">
                      <Card.Body>
                        <div className="card-title-group">
                          <div className="card-title">
                            <h5 className="title">Test Results</h5>
                          </div>
                        </div>
                        <Tab.Container id="pills-tabs-example" defaultActiveKey="pills-home">
                          <Nav variant="pills" className="mb-3 justify-content-end gap-2" style={{ marginTop: "-30px" }}>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-home">Mock</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="pills-profile">Section Wise</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="pills-home">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pills-profile">
                              <div className="card-title-group flex-wrap">
                                <ChartLegend.Group className="gap gx-3 align-items-center">
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="warning">
                                      New visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <ChartLegend className="chart-legend-small" symbol="info">
                                      Unique visits
                                    </ChartLegend>
                                  </div>
                                  <div className="gap-col">
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                        <Icon name="more-v"></Icon>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                        <Dropdown.Header className="py-2">
                                          <h6 className="mb-0">Options</h6>
                                        </Dropdown.Header>
                                        <Dropdown.Divider className="mt-0" />
                                        <Dropdown.Item>7 Days</Dropdown.Item>
                                        <Dropdown.Item>15 Days</Dropdown.Item>
                                        <Dropdown.Item>30 Days</Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </ChartLegend.Group>
                              </div>
                              <div className="nk-chart-analytics-audience-overview mt-3">
                                <ChartLine data={audienceOverviewChart} />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>


      {/* <Row className="g-gs my-3">
        <Col sm="12" xxl="6">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Sessions</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">2,765</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change up">
                    <Icon name="upword-alt-fill"></Icon>
                    <span className="ms-1">10.5%</span>
                  </div>
                  <span className="text-light">From last 2 Weeks</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartBar data={sessionChart} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                <ChartLabel className="chart-label-small">
                  1 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  8 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  15 May
                </ChartLabel>
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" xxl="3">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Sessions</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">2,765</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change up">
                    <Icon name="upword-alt-fill"></Icon>
                    <span className="ms-1">10.5%</span>
                  </div>
                  <span className="text-light">From last 2 Weeks</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartBar data={sessionChart} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                <ChartLabel className="chart-label-small">
                  1 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  8 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  15 May
                </ChartLabel>
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" xxl="3">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Avg.Sessions</h4>
                </div>
                <Media size="sm" shape="circle" variant="success-soft">
                  <Icon name="bar-chart-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">42:50</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change up">
                    <Icon name="upword-alt-fill"></Icon>
                    <span className="ms-1">12%</span>
                  </div>
                  <span className="text-light">From last month</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartLine data={sessionChartAvg} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                <ChartLabel className="chart-label-small">
                  1 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  15 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  30 May
                </ChartLabel>
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" xxl="3">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Bounce Rate</h4>
                </div>
                <Media size="sm" shape="circle" variant="warning-soft">
                  <Icon name="activity-round-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">1,853</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change up">
                    <Icon name="upword-alt-fill"></Icon>
                    <span className="ms-1">10%</span>
                  </div>
                  <span className="text-light">From last week</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartLine data={bounceRateChart} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                <ChartLabel className="chart-label-small">
                  1 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  7 May
                </ChartLabel>
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" xxl="3">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Goal Completions</h4>
                </div>
                <Media size="sm" shape="circle" variant="info-soft">
                  <Icon name="tag-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">2,153</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change down">
                    <Icon name="downward-alt-fill"></Icon>
                    <span className="ms-1">12%</span>
                  </div>
                  <span className="text-light">From last month</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartBar data={goalCompletions} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                <ChartLabel className="chart-label-small">
                  1 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  15 May
                </ChartLabel>
                <ChartLabel className="chart-label-small">
                  30 May
                </ChartLabel>
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col xxl="6">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group flex-wrap">
                <div className="card-title">
                  <h5 className="title">Audience Overview</h5>
                </div>
                <ChartLegend.Group className="gap gx-3 align-items-center">
                  <div className="gap-col">
                    <ChartLegend className="chart-legend-small" symbol="warning">
                      New visits
                    </ChartLegend>
                  </div>
                  <div className="gap-col">
                    <ChartLegend className="chart-legend-small" symbol="info">
                      Unique visits
                    </ChartLegend>
                  </div>
                  <div className="gap-col">
                    <Dropdown>
                      <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                        <Icon name="more-v"></Icon>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                        <Dropdown.Header className="py-2">
                          <h6 className="mb-0">Options</h6>
                        </Dropdown.Header>
                        <Dropdown.Divider className="mt-0" />
                        <Dropdown.Item>7 Days</Dropdown.Item>
                        <Dropdown.Item>15 Days</Dropdown.Item>
                        <Dropdown.Item>30 Days</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </ChartLegend.Group>
              </div>
              <div className="nk-chart-analytics-audience-overview mt-3">
                <ChartLine data={audienceOverviewChart} />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md="6" xxl="4">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Sessions Device</h5>
                </div>
                <div className="card-tools">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-another">Sessions from device</Tooltip>
                    }>
                    <em className="icon-hint icon ni ni-help-fill"></em>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="nk-chart-analytics-session-device mt-4">
                <ChartDoughnut data={sessionsDevice} />
              </div>
              <ChartLegend.Group className="justify-content-around pt-4 flex-wrap gap g-2">
                <ChartLegend symbol="info">
                  Mobile
                </ChartLegend>
                <ChartLegend symbol="warning">
                  Tablet
                </ChartLegend>
                <ChartLegend symbol="success">
                  Desktop
                </ChartLegend>
                <ChartLegend symbol="purple">
                  Others
                </ChartLegend>
              </ChartLegend.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xxl="4">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Recent Activity</h5>
                </div>
              </div>
              <Timeline className="nk-timeline-center mt-4">
                <Timeline.Group title="today">
                  <Timeline.List>
                    <Timeline.Item symbol="user" variant="info">
                      <p className="small"><strong>Nick Mark</strong> mentioned <strong>Sara Smith</strong> in a new post</p>
                    </Timeline.Item>
                    <Timeline.Item symbol="signout" variant="warning">
                      <p className="small">The post <strong>Post Name</strong> was removed by <strong>Nick Mark</strong></p>
                    </Timeline.Item>
                    <Timeline.Item symbol="file-text" variant="success">
                      <p className="small"><strong>Patrick Sullivan</strong> published a new <strong>post</strong></p>
                    </Timeline.Item>
                  </Timeline.List>
                </Timeline.Group>

                <Timeline.Group title="yesterday">
                  <Timeline.List>
                    <Timeline.Item symbol="shuffle" variant="info">
                      <p className="small"><strong>240+</strong> users have subscribed to Newsletter #1</p>
                    </Timeline.Item>
                    <Timeline.Item symbol="signout" variant="success">
                      <p className="small">The post <strong>Post Name</strong> was suspended by <strong>Nick Mark</strong></p>
                    </Timeline.Item>
                  </Timeline.List>
                </Timeline.Group>
              </Timeline>
            </Card.Body>
          </Card>
        </Col>
        <Col xxl="4">
          <Card className="h-100">
            <div className="card-body">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Web Sessions by Region</h5>
                </div>
                <div className="card-tools">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-another">Web Sessions from region</Tooltip>
                    }>
                    <em className="icon-hint icon ni ni-help-fill"></em>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="nk-chart-analytics-session-region-map mt-3 mx-auto">
                <WorldMap id="web-session-map" data={svgWorldMap} />
              </div>
              <div className="list-group-heading d-flex align-items-center justify-content-between">
                <h6 className="title">Top Region</h6>
                <h6 className="title">Sessions</h6>
              </div>
              <ListGroup className="list-group-borderless list-group-sm">
                <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                  <span className="title">United States</span>
                  <span className="text">8,465</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                  <span className="title">United Kingdom</span>
                  <span className="text">6,423</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                  <span className="title">Canada</span>
                  <span className="text">5,764</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                  <span className="title">Germany</span>
                  <span className="text">1,374</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center smaller">
                  <span className="title">Bangladesh</span>
                  <span className="text">890</span>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Card>
        </Col>
      </Row> */}
    </Layout>
  )
}

export default Home;
