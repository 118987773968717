import { Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../../../context/ModalContext";
import { Icon } from "../../../../components";
const Snippet = ({ text, data, switchChecked }) => {
  let {
    popUpType,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  const [arr, setArr] = useState([]);
  const [count, setCount] = useState(0);
  var str = [];
  var answerlength = 0;
  var prevIndex = 0;
  console.log("dataFIBRW", data);
  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      var indexat = text?.indexOf(data[i].answer);

      str[i] = text?.substring(answerlength + prevIndex, indexat);

      prevIndex = indexat;
      answerlength = data[i].answer.length;
    }

    str[data[0].answer.length] = text?.substring(
      answerlength + prevIndex,
      text.length
    );
    console.log("str",str);
  }

  const handlechangeChange = (e, i) => {
    const newArr = [...arr];
    console.log("ii", i)
    // if (i < newArr.length) {
    //   newArr[i] = e.target.value;
    // } else {
    //   newArr.push(e.target.value);
    // }
    newArr[i] = e.target.value;

    setAnswerJson({ ...answerJson, answer: newArr.join(",") });
    setArr(newArr);

    console.log("newArr", newArr.join(","));
  };


  useEffect(() => {
    if(switchChecked){
      const eles = document?.getElementsByClassName("AnswerForFibRW");
      for(var i=0;i<eles?.length;i++){
        eles[i].style.display=""
      }
      const correctAnswer = document?.getElementsByClassName("AnswerForFibRW");
      console.log("correctAnswer",correctAnswer);
      for(var i=0;i<correctAnswer.length;i++){
        if(correctAnswer[i]?.id === correctAnswer[i]?.previousElementSibling?.childNodes[0]?.value){
          correctAnswer[i]?.previousElementSibling?.childNodes[0]?.classList?.remove("border-red")
          correctAnswer[i]?.previousElementSibling?.childNodes[0]?.classList?.add("border-green")
        }
        else{
          correctAnswer[i]?.previousElementSibling?.childNodes[0]?.classList?.remove("border-green")
          correctAnswer[i]?.previousElementSibling?.childNodes[0]?.classList?.add("border-red")
        }
      }
    } 
    else{
      const eles = document?.getElementsByClassName("AnswerForFibRW");
      for(var i=0;i<eles?.length;i++){
        eles[i].style.display="none"
      }
      const correctAnswer = document?.getElementsByClassName("AnswerForFibRW");
      for(var i=0;i<correctAnswer.length;i++){
        if(correctAnswer[i]?.id === correctAnswer[i]?.previousElementSibling?.childNodes[0]?.value){
          correctAnswer[i]?.previousElementSibling?.childNodes[0]?.classList?.remove("border-green")
        }
        else{
          correctAnswer[i]?.previousElementSibling?.childNodes[0]?.classList?.remove("border-red")
        }
      }
    }
    
  },[switchChecked, arr])

  return (
    <>
      {str?.length > 0 &&
        str.map((item, i) => {
          return (
            <>
              {item}
              {str?.length - 2 >= i && (
                <>
                  <div className="form-control-wrap d-inline-block mx-2">
                    <Form.Select id="exampleFormSelect1" aria-label="Default select example" onChange={(e) => handlechangeChange(e, i)}>
                      <option value="" style={{display:"none"}}></option>
                      {data?.length > 0 && typeof(data[i]?.options) != "string" &&
                        data[i]?.options?.length > 0 &&
                        data[i]?.options?.map((item) => {
                          return (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          );
                        })}
                    </Form.Select>
                  </div>
                  <span  className='text-primary AnswerForFibRW' id={data[i].answer} style={{display:"none",color:"green" }}>(Answer:&nbsp;{data[i].answer}) 
                  {/* <Icon name="help-fill" varient="success" size="md"></Icon> */}
                   </span>
                </>
              )}
            </>
          );
        })}
    </>
  );
};
function PracticeFIBRW({ data ,redoCount, switchChecked}) {


  return (
    <>
      <div className="practice-textarea mb-5">
        {data && <Snippet key={redoCount} text={data?.question_content} data={data.question_options} redoKey={redoCount} switchChecked={switchChecked} />}
      </div>
    </>
  );
}

export default PracticeFIBRW;
