import React, { useState, useEffect, useContext } from "react";
import { Card, Form, Row, Col, Nav, Tab, Tabs, Button, Dropdown, ButtonGroup, InputGroup, Badge, Table } from 'react-bootstrap';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Colors } from '../../../utilities/index';
import { Icon, Image, Media, Pureknob, ChartLegend, CustomDropdownMenu, CustomDropdownToggle } from '../../../components';
import { ChartBar, ChartLine, ChartDoughnut } from "../../../components/Chart/Charts";
import { Link, useParams } from 'react-router-dom';
import UsersData from '../../../store/users/UsersData';
import { toInitials } from "../../../utilities";
import AuthApi from "../../../api/auth";
import Context from "../../../context";
function InstituteDetail() {
    let api = new AuthApi();
    const { domain_name } = useParams();
    const [data, setData] = useState({});
    console.log("---", domain_name)
    let { domainData } = useContext(Context);
  domainData = JSON.parse(domainData);
    // grabs the id form the url and loads the corresponding data
    useEffect(() => {
        getInstittuteDetailByname();
    }, [domain_name]);
    const getInstittuteDetailByname = async () => {
        console.log("getInstittuteDetailByname(); called")
        await api.get_instittute_By_Name({ name: domain_name })
            .then((res) => {
                console.log("ress", res)
                if (res.data.length > 0) {
                    setData(res.data[0])
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Sessions Device
    let sessionsDevice = {
        labels: ["Pending", "In Progress", "Assigned", "Completed"],
        datasets: [
            {
                backgroundColor: [Colors.pending, Colors.inprogress, Colors.assigned, Colors.completed],
                data: [140, 70, 110, 120],
                hoverOffset: 4,
                borderWidth: 0
            }
        ]
    };

    // audience overview chart
    let audienceOverviewChart = {
        labels: ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        ticksValue: 'k',
        borderDash: [8, 4],
        datasets: [
            {
                tension: .4,
                borderWidth: 2,
                borderColor: Colors.warning,
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: Colors.warning,
                label: "New visits",
                data: [28, 60, 30, 65, 60, 95, 90, 100, 96, 120, 105, 134]
            },
            {
                tension: .4,
                borderWidth: 2,
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                borderColor: Colors.info,
                pointHoverBackgroundColor: Colors.info,
                label: "Unique visits",
                data: [70, 44, 49, 78, 39, 49, 39, 38, 59, 80, 56, 44]
            }
        ]
    };
    // active project knob chart
    let activeListening = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.inprogress,
        trackWidth: "0.25"
    };
    let activeReading = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.completed,
        trackWidth: "0.25"
    };
    let activeSpeaking = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.hisuccess,
        trackWidth: "0.25"
    };
    let activeWriting = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.pending,
        trackWidth: "0.25"
    };
    let activePte = {
        size: 110,
        value: 70,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.completed,
        trackWidth: "0.4"
    };
    const givenDate = new Date(data?.createdAt);

    // Define an array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Extract year, month, and day from the Date object
    const year = givenDate.getUTCFullYear();
    const monthIndex = givenDate.getUTCMonth();
    const day = givenDate.getUTCDate();

    // Create the desired date format
    const desiredDateFormat = `${day} ${monthNames[monthIndex]} ${year}`;

    return (
        <Layout title="Add Student" content="container">
            <h1 className='d-flex align-items-center mb-5'><Icon name="arrow-left-circle-fill" className="text-primary h1 me-2"></Icon> Back</h1>
            <Block>
                <Card className='h-100 my-3'>
                    <div className="d-flex justify-content-end align-items-center bg-primary">
                        <Image src='/images/myprofile.gif' className="profile-img"></Image>
                    </div>
                    <Card.Body>
                        <div className="d-flex flex-column align-items-start user-profile">
                            <Media size="huge" shape="circle">
                                {data?.inst_profile?.business_logo ?
                                    <Image src={data?.inst_profile?.business_logo} staticImage thumbnail alt="user" />
                                    : <Image src='/images/avatar/a.jpg' staticImage thumbnail alt="user" />
                                }
                            </Media>
                            <div className="mt-3 mt-md-0 ms-md-3">
                                <h3 className="title mb-1">{data?.inst_profile?.inst_contact_firstname + " " + data?.inst_profile?.inst_contact_lastname}</h3>
                                <span className="small fw-bold">Expire Date: 17 Jun 2022</span>
                            </div>
                        </div>
                        <Row className="g-gs mb-5">
                            <Col md="3">
                                <p className='text-light mb-0'>Username : </p> <p className='text-middark'>{data?.email}</p>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Targeted Score : </p> <Badge bg="hilight" className="text-secondary">79</Badge>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Student Type : </p> <Badge bg="hilight" className="text-secondary">Bulk Test</Badge>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Product : </p> <Badge bg="hilight" className="text-secondary">PTE</Badge>
                            </Col>
                        </Row>
                        <Row className="g-gs">
                            <Col md="2">
                                <p className='text-light mb-0'>Created on : </p> <p className='text-secondary'>{desiredDateFormat}</p>
                            </Col>
                            <Col md="4">
                                <p className='text-light mb-0'>Institute : </p> <p className='text-secondary'>{data?.inst_profile?.inst_business_name}</p>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Phone : </p> <p className='text-secondary'>+91 {data?.mobile}</p>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Created by : </p> <p className='text-secondary'>{data?.inst_profile?.inst_contact_firstname + " " + data?.inst_profile?.inst_contact_lastname}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className='h-100 my-3'>
                    <Card.Body>
                        <h5 className='mb-4'> Quick Action</h5>
                        <div className="d-flex my-3 flex-column flex-md-row gap-3">
                            <Button variant="outline-light" className="fw-bold me-3"><Icon name="undo"></Icon><span>Extend Validity</span></Button>
                            <Button variant="outline-light" className="fw-bold me-3"><Icon name="signin"></Icon><span>Login</span></Button>
                            <Button variant="outline-primary" className="fw-bold me-3"><Icon name="plus"></Icon><span>Add Test</span></Button>
                            <Button variant="outline-light" className="fw-bold me-3"><Icon name="edit-alt"></Icon><span>Edit</span></Button>
                            <Button variant="outline-light" className="fw-bold me-3"><Icon name="lock-alt"></Icon><span>Change Password</span></Button>
                            <Button variant="outline-danger" className="fw-bold me-3"><Icon name="trash-alt"></Icon><span>Delete</span></Button>
                        </div>
                    </Card.Body>
                </Card>

                <Card className='h-100 my-3'>
                    <Card.Body>
                        <h5 className='mb-4'> Status of Students</h5>
                        <Row className="g-gs">
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeListening} className="nk-chart-project-active" />
                                        <div className="h5 mb-0 text-menu-text">Listening</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeReading} className="nk-chart-project-active" />
                                        <div className="h5 mb-0 text-menu-text">Reading</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeSpeaking} className="nk-chart-project-active" />
                                        <div className="fw-bold mb-0 text-menu-text">Speaking</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeWriting} className="nk-chart-project-active" />
                                        <div className="h5 mb-0 text-menu-text">Writing</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Row className='my-3'>
                    <Col md="9">
                        <Card className="h-100">
                            <Card.Body>
                                <div className='d-flex justify-content-between'>
                                    <h5 className="title">Test Results</h5>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                            All Results
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="card-title-group flex-wrap my-3">
                                    <ChartLegend.Group className="gap gx-3 align-items-center">
                                        <div className="gap-col">
                                            <ChartLegend className="chart-legend-small" symbol="warning">
                                                New visits
                                            </ChartLegend>
                                        </div>
                                        <div className="gap-col">
                                            <ChartLegend className="chart-legend-small" symbol="info">
                                                Unique visits
                                            </ChartLegend>
                                        </div>
                                        <div className="gap-col">
                                            <Dropdown>
                                                <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                                    <Icon name="more-v"></Icon>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                                    <Dropdown.Header className="py-2">
                                                        <h6 className="mb-0">Options</h6>
                                                    </Dropdown.Header>
                                                    <Dropdown.Divider className="mt-0" />
                                                    <Dropdown.Item>7 Days</Dropdown.Item>
                                                    <Dropdown.Item>15 Days</Dropdown.Item>
                                                    <Dropdown.Item>30 Days</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </ChartLegend.Group>
                                </div>
                                <div className="nk-chart-analytics-audience-overview mt-3">
                                    <ChartLine data={audienceOverviewChart} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md="3">
                        <Card className="h-100">
                            <Card.Body>
                                <h5 className="title">Test Attempted</h5>
                                <div className="border rounded py-4 mt-5">
                                    <Tab.Container id="custom-tabs-example" defaultActiveKey="custom-home">
                                        <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                            <Nav.Item className='px-1'>
                                                <Nav.Link eventKey="custom-home">PTE Moke Test</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='px-1'>
                                                <Nav.Link eventKey="custom-profile">Section Wise Test</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="custom-home">
                                                <div className="card-title mb-0 mt-4 mt-sm-0 d-flex flex-column align-items-center justify-content-between">
                                                    <Pureknob data={activePte} className="nk-chart-project-active" />
                                                    <div className="h5 mb-0 text-menu-text">Total Test-7/10</div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="custom-profile">
                                                <div className="card-title mb-0 mt-4 mt-sm-0 d-flex flex-column align-items-center justify-content-between">
                                                    <Pureknob data={activePte} className="nk-chart-project-active" />
                                                    <div className="h5 mb-0 text-menu-text">Total Test-7/10</div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='my-3'>
                    <Col md="9">
                        <Row className="g-gs">
                            <Col md="6">
                                <Card className="h-100">
                                    <Card.Body style={{ paddingBottom: "60px" }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h5 className="title">Test Results</h5>
                                            <div className="form-check form-switch align-items-center">
                                                <img src="/images/undefine.svg" style={{ width: "22px", height: "22px" }} className="me-1"></img> <input className="form-check-input ms-3" type="checkbox" role="switch" id="directMessage" defaultChecked />
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session-device gap-3">
                                            <ChartDoughnut data={sessionsDevice} />
                                            <ChartLegend.Group className="justify-content-around flex-wrap gap g-2 pt-3">
                                                <ChartLegend symbol="completed">
                                                    Completed
                                                </ChartLegend>
                                                <ChartLegend symbol="pending">
                                                    Pending
                                                </ChartLegend>
                                                <ChartLegend symbol="assigned">
                                                    Assigned
                                                </ChartLegend>
                                                <ChartLegend symbol="inprogress">
                                                    In Progress
                                                </ChartLegend>
                                            </ChartLegend.Group>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="h-100">
                                    <Card.Body style={{ paddingBottom: "60px" }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h5 className="title">Section wise Test</h5>
                                            <div className="form-check form-switch align-items-center">
                                                <img src="/images/undefine.svg" style={{ width: "22px", height: "22px" }} className="me-1"></img> <input className="form-check-input ms-3" type="checkbox" role="switch" id="directMessage" defaultChecked />
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session-device gap-3">
                                            <ChartDoughnut data={sessionsDevice} />
                                            <ChartLegend.Group className="justify-content-around flex-wrap gap g-2 pt-3">
                                                <ChartLegend symbol="completed">
                                                    Completed
                                                </ChartLegend>
                                                <ChartLegend symbol="pending">
                                                    Pending
                                                </ChartLegend>
                                                <ChartLegend symbol="assigned">
                                                    Assigned
                                                </ChartLegend>
                                                <ChartLegend symbol="inprogress">
                                                    In Progress
                                                </ChartLegend>
                                            </ChartLegend.Group>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3">
                        <Card className="h-100">
                            <Card.Body style={{ paddingBottom: "60px" }}>
                                <div className='d-flex flex-column justify-content-between align-items-center'>
                                    <h5 className="title">Test History</h5>
                                    <div className='mt-2 border rounded w-100'>
                                        <h5 className='rounded-top bg-primary-soft text-secondary p-1'>
                                            Date and Time
                                        </h5>
                                        <div className='rounded-top bg-white text-center py-1 text-menu-text'>
                                            <div className='d-flex p-1 gap-1'>
                                                <div className='px-0'><Icon name="header"></Icon><span>31 May 2023</span></div>
                                                <div className='px-0'><Icon name="clock"></Icon><span>17:07 pm</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-3 border rounded w-100'>
                                        <h5 className='rounded-top bg-primary-soft text-secondary p-1'>
                                            Test Allocation
                                        </h5>
                                        <div className='rounded-top bg-white text-center py-1 text-menu-text'>
                                            <div className='d-flex flex-column p-1 gap-1 align-items-start'>
                                                <div className='px-0'>Mock : <span className='text-secondary'>10</span></div>
                                                <div className='px-0'>Section Wise : <span className='text-secondary'>20</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Block>
        </Layout>
    )
}

export default InstituteDetail;