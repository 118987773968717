import { Card, Nav, Tab, Row, Col, Button } from 'react-bootstrap';
import Layout from '../../layout/default';
import ProgressBar from 'react-bootstrap/ProgressBar';

function StudentMockTest() {
    return (
        <Layout title="Add Student" content="container">
            <h1 className='mb-5'>Add More Test</h1>
            <Tab.Container id="pills-tabs-example" defaultActiveKey="mock-test">
                <Nav variant="pills" className="mb-5 justify-content-end gap-2" style={{ marginTop: "-50px" }}>
                    <Nav.Item>
                        <Nav.Link eventKey="mock-test">Mock Test</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="my-result">My Result</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="mock-test">
                        <Row className="g-gs">
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <h5> New Test - 1</h5>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>55</span>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <p className='text-secondary my-0'>Progress</p>
                                                    <p className='text-primary my-0'>0%</p>
                                                </div>
                                                <ProgressBar variant="hisuccess" now={0} className='w-100 my-1' style={{ height: "4px" }} />
                                            </div>
                                            <Button variant="primary" className='w-auto mx-auto mt-5'>Start Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 2</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>51</span>
                                                    </div>
                                                </div>
                                                <p className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>This mock test is only available for HiLingo VIP members</p>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Upgrade Now</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 3</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>57</span>
                                                    </div>
                                                </div>
                                                <p className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>This mock test is only available for HiLingo VIP members</p>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Upgrade Now</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 12</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>54</span>
                                                    </div>
                                                </div>
                                                <h1 className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>RS. 6999/-</h1>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Request Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 12</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>54</span>
                                                    </div>
                                                </div>
                                                <h1 className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>RS. 6999/-</h1>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Request Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 12</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>54</span>
                                                    </div>
                                                </div>
                                                <h1 className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>RS. 6999/-</h1>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Request Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 12</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>54</span>
                                                    </div>
                                                </div>
                                                <h1 className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>RS. 6999/-</h1>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Request Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 12</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>54</span>
                                                    </div>
                                                </div>
                                                <h1 className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>RS. 6999/-</h1>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Request Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 12</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>54</span>
                                                    </div>
                                                </div>
                                                <h1 className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>RS. 6999/-</h1>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Request Test</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="my-result">
                        <Row className="g-gs">
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 1</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Completed</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>55</span>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <p className='text-secondary my-0'>Overall Score</p>
                                                    <p className='text-secondary my-0'>41/90</p>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='text-light my-0'>Submitted at:</p>
                                                    <p className='text-secondary my-0'>2023-07-21, 18:06</p>
                                                </div>
                                                <div className='border-top d-flex justify-content-center mt-3'>
                                                    <div className='d-flex flex-column gap-3 p-4 border-end justify-content-center'>
                                                        <img src="/images/result.svg" style={{ width: "40px" }}></img>
                                                        <h5>Result</h5>
                                                    </div>
                                                    <div className='d-flex flex-column gap-3 p-4 border-end justify-content-center'>
                                                        <img src="/images/answer.svg" style={{ width: "40px" }}></img>
                                                        <h5>Answer</h5>
                                                    </div>
                                                    <div className='d-flex flex-column gap-3 p-4 justify-content-center'>
                                                        <img src="/images/analytics.svg" style={{ width: "40px" }}></img>
                                                        <h5>Analytics</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 2</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>51</span>
                                                    </div>
                                                </div>
                                                <p className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>This mock test is only available for HiLingo VIP members</p>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Upgrade Now</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4" lg="4" sm="6" xs="12">
                                <Card className="h-100 card-hiprimary">
                                    <Card.Body>
                                        <div className='d-flex flex-column justify-content-between'>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h5> New Test - 3</h5>
                                                    <span className='text-light small bg-primary-soft px-2 rounded'>Locked</span>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>Duration</span>
                                                        <span className='text-secondary fw-bold'>02:30:00</span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <span className='text-light small'>No. of Questions</span>
                                                        <span className='text-secondary fw-bold'>57</span>
                                                    </div>
                                                </div>
                                                <p className='w-75 mx-auto text-center mt-3 fw-bold text-hidanger'>This mock test is only available for HiLingo VIP members</p>
                                            </div>
                                            <Button variant="outline-primary" className='w-auto mx-auto mt-5'>Upgrade Now</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Layout>
    )
}

export default StudentMockTest;