import React, { useState, useEffect, useContext } from 'react';
import ModalContext from '../../../../context/ModalContext';

const TimeRemaining = ({ destructure, setDestructure, currentModuleName, timer, question }) => {
  console.log("TIMER in TIMER", timer);
  let { popUpType, setPopUpType, studentContextData, currentTime, setStudentContextData } = useContext(ModalContext);
  const totalTimeString = studentContextData.currentTime ? studentContextData.currentTime : timer ? timer : "00:00";
  console.log("totalTimeString", totalTimeString);

  const [totalHours, totalMinutes, totalSeconds] = totalTimeString.split(":").length === 3 
    ? totalTimeString.split(":").map(Number)
    : [0, ...totalTimeString.split(":").map(Number)];

  const [time, setTime] = useState({
    hours: totalHours,
    minutes: totalMinutes,
    seconds: totalSeconds,
  });

  useEffect(() => {
    const [newTotalHours, newTotalMinutes, newTotalSeconds] = totalTimeString.split(":").length === 3 
      ? totalTimeString.split(":").map(Number)
      : [0, ...totalTimeString.split(":").map(Number)];

    setTime({
      hours: newTotalHours,
      minutes: newTotalMinutes,
      seconds: newTotalSeconds
    });
  }, [timer]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        setPopUpType("timeUp");
        clearInterval(timerInterval);
      } else {
        setTime((prevTime) => {
          let newSeconds = prevTime.seconds - 1;
          let newMinutes = prevTime.minutes;
          let newHours = prevTime.hours;

          if (newSeconds < 0) {
            newSeconds = 59;
            newMinutes -= 1;
          }
          if (newMinutes < 0) {
            newMinutes = 59;
            newHours -= 1;
          }

          return { hours: newHours, minutes: newMinutes, seconds: newSeconds };
        });
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [time]);

  useEffect(() => {
    setStudentContextData((prevState) => ({
      ...prevState,
      current_time: (
        (time.hours < 10 ? `0${time.hours}` : time.hours) + ":" +
        (time.minutes < 10 ? `0${time.minutes}` : time.minutes) + ":" +
        (time.seconds < 10 ? `0${time.seconds}` : time.seconds)
      ),
    }));
  }, [time]);

  return (
    <span>
      {`${time.hours > 0 ? `${time.hours < 10 ? `0${time.hours}` : time.hours}:` : ''}${time.minutes < 10 ? `0${time.minutes}` : time.minutes}:${time.seconds < 10 ? `0${time.seconds}` : time.seconds} / ${timer}`}
    </span>
  );
};

export default TimeRemaining;
