import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noUiSlider from 'nouislider';
// import 'nouislider/distribute/nouislider.css';

function NoUiSlider({ start, connect, min, max, minDistance, maxDistance, step, orientation, tooltip, onSlide }) {
  const noUiSliderElem = useRef(null);

  let startValue = start;
  startValue = /\s/g.test(startValue) ? startValue.split(' ') : startValue;
  startValue = startValue ? startValue : 0;

  let connectValue = connect;
  connectValue = /\s/g.test(connectValue) ? connectValue.split(' ') : connectValue;
  connectValue = typeof connectValue === 'undefined' ? 'lower' : connectValue;
  connectValue = connectValue === 'true' || connectValue === 'false' ? JSON.parse(connectValue) : connectValue;

  useEffect(() => {
    const slider = noUiSliderElem.current;

    if (slider) {
      noUiSlider.create(slider, {
        start: startValue,
        connect: connectValue,
        range: {
          'min': min || 0,
          'max': max || 100,
        },
        margin: minDistance || null,
        limit: maxDistance || null,
        step: step || 1,
        orientation: orientation || 'horizontal',
        tooltips: tooltip || false,
      });

      slider.noUiSlider.on('slide', (values, handle) => {
        if (onSlide) {
          onSlide(values, handle);
        }
      });

      return () => {
        slider.noUiSlider.off('slide');
        slider.noUiSlider.destroy();
      };
    }
  }, [startValue, connectValue, min, max, minDistance, maxDistance, step, orientation, tooltip, onSlide]);

  return <div ref={noUiSliderElem} />;
}

NoUiSlider.propTypes = {
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  connect: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  min: PropTypes.number,
  max: PropTypes.number,
  minDistance: PropTypes.number,
  maxDistance: PropTypes.number,
  step: PropTypes.number,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  tooltip: PropTypes.bool,
  onSlide: PropTypes.func,
};

NoUiSlider.defaultProps = {
  start: 0,
  connect: 'lower',
  min: 0,
  max: 100,
  minDistance: null,
  maxDistance: null,
  step: 1,
  orientation: 'horizontal',
  tooltip: false,
  onSlide: () => {},
};

export default NoUiSlider;
