import { useContext, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, CloseButton, Col, Form, Nav, Row, Tab, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import { Icon,  } from "../../components";
import Block from "../../components/Block/Block";
import FileUpload from "../../components/Form/FileUpload";
import { NewQuill } from "../../components/Form/editors/NewQuill";
import Context from "../../context";
import Layout from "../../layout/default";
import SubmitButton from "../questions/PTE/SubmitButton";
import FileUploadAnnoucement from "../../components/Form/FileUploadAnnoucement";
import FileUploadImageExtension from "../../components/Form/FileUploadImageExtension";
import { ThemeProvider, createTheme } from '@mui/material';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { DropDown } from "../../components/Form/DropDown";
import Mypagination from "../../components/Mypagination";
import NotificationContext from "../../context/NotificationContext/NotificationContext";

function AddNotification() {
  let { userData, domainData ,branchId} = useContext(Context);
  
  userData = JSON.parse(userData);
  domainData = JSON.parse(domainData);
  let navigate = useNavigate()
  let api = new AuthApi();
  const [count, setCount] = useState(0)
  const [studentList, setStudentList] = useState([])
  const [allData, setAllData] = useState({})
  const [quillData, setQuillData] = useState({})
  const [data, setdata] = useState({
    user_id: [],
    sending_type: "now",
    status:"complete",
    inst_id: domainData?._id,
    inst_name: domainData?.inst_profile?.inst_business_name,
    hours:"",
    minutes:"",
  });
  const [loader, setloader] = useState(false);
  let formdata = new FormData();
  const sendTicket = async () => {
    setloader(true)
    console.log('formdata :>> ', data);
    console.log('formdata1 :>> ', allData);
    for (const [key, value] of Object.entries(allData)) {
      formdata.append([key], value)
    }
    await api.create_notification(formdata).then((res) => {
      setloader(false)
      if (res?.status) {
        Swal.fire({
          icon: "success",
          text: res.message,
          confirmButtonText: "Done",
        });
        navigate("/view-notification")
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: res.msg,
        });
      }
      console.log("res", res)
    }).catch((err) => {
      console.log(err)
    })

  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    setdata({ ...data, [name]: value });
  };
  const checkValidation = () => {

    // console.log('allmydata :>> ', data);
    return (
      allData?.title &&
      allData?.sub_title &&
      allData?.description
    );
  };
  useEffect(() => {
    get_student_by_inst();
  }, []);

  const get_student_by_inst = async () => {
    await api
      .get_student_by_inst({ inst_id: (userData)?.teacher_profile?.inst_id || (userData)?._id })
      .then((res) => {
        if (res?.data?.length > 0) {
          setStudentList(res?.data);
          setFilterSearchData(res?.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendData = (quilData) => {  
    console.log('childData :>> ', quilData)
    setQuillData({ description:quilData}) 
  }

 useEffect(() => {
  setAllData({...data, ...quillData});  
 },[quillData,data])


 const {setNotificationData,setNotificationImage}=useContext(NotificationContext)
 useEffect(()=>{
  localStorage.setItem('notificationData',JSON.stringify(allData));
  setNotificationData(JSON.stringify(allData))
},[allData])

 console.log('allData :>> ', data);
 const [filterDataObj, setFilterDatObj] = useState({});
 const [filterDataObjBatch, setFilterDatObjBatch] = useState({});
 const [filterSearchData,setFilterSearchData]=useState([])

 const [batchFilterSearchData,setBatchFilterSearchData]=useState([])
 const [batchFilterSearchDataAll,setBatchFilterSearchDataAll]=useState([])

 const handleDateChange = (e) => {
  let { value, name } = e.target;
  console.log(value,name,"vlau");

    setFilterDatObj({ ...filterDataObj, [name]: value });

};

const handleDateChangeBatch = async (e) => {
  let { value, name } = e.target;
  console.log(value,name,"handleDateChangeBatchvlau");

  setFilterDatObjBatch({ ...filterDataObjBatch, [name]: value });
 


};

 const teachers = [
  ...new Set(
    studentList
      .filter((item) => item?.stu_profile?.assignTeacher != undefined)
      .map((item) => item?.stu_profile?.assignTeacher?.toLowerCase())
  ),
];


const Product = [
  ...new Set(
    studentList
      .filter((item) => item?.product != undefined)
      .map((item) => item?.product)
  ),
];
const Student_type = [
  ...new Set(
    studentList
      .filter((item) => item?.stu_profile?.testType != undefined)
      .map((item) => item?.stu_profile?.testType?.toLowerCase())
  ),
];

const Student_type_batch = [
  ...new Set(
    batchFilterSearchDataAll
      .filter((item) => item?.testType != undefined)
      .map((item) => item?.testType)
  ),
];


const Product_batch = [
  ...new Set(
    batchFilterSearchDataAll
      .filter((item) => item?.productType != undefined)
      .map((item) => item?.productType)
  ),
];



 const [selectedTime, setSelectedTime] = useState(null)
console.log('selectedTime :>> ', new Date(selectedTime).toLocaleTimeString());
console.log('filterDataObj?.testType :>> ', filterDataObj?.testType,filterDataObj);
  

let {

  teacher,
  product,
  testType ,
  name
} = filterDataObj;

const filterData = async () => {
  console.log('studentList :>> ', studentList);
  const filteredData = studentList.filter((item) => {

    return (
    
      (!teacher ||
        (item?.stu_profile?.assignTeacher != null &&
        item?.stu_profile?.assignTeacher.toLowerCase().includes(teacher?.toLowerCase().trim()))) &&
        (!product ||
          (item?.product != null &&
            item?.product.toLowerCase().includes(product?.toLowerCase().trim()))) &&
     (!name ||
              item?.name?.toLowerCase()
                .includes(name?.toLowerCase()?.trim())) &&
      (!testType ||
        (item?.stu_profile?.testType != null &&
          item?.stu_profile?.testType.toLowerCase() === testType?.toLowerCase()))
    );
  });
  setFilterSearchData(filteredData);
  console.log("filteredData", filteredData);
};
console.log('Product :>> ', Product,Student_type);



const filterDataBatch = async () => {


    let paylaod={
      batch_id:batchId,
      testType:filterDataObjBatch?.testType,
      productType:filterDataObjBatch?.product
    }
    await api.get_Sinlge_batch(JSON.parse(localStorage.getItem("userHilingo")).branch_id || branchId,paylaod)
    .then((res)=>{
      if(res?.data?.length>0){
  
  
        setBatchFilterSearchData(res?.data)
     
  
      }
    
    })
  }



// without reset button
// useEffect(()=>{
//   filterData()
 

// },[filterDataObj])


console.log('helloHar :>> ', studentList);
console.log('filterSearchData :>> ', filterSearchData);

const [batches,setBatches] = useState([])

const [showItemPerPage, setShowItemPerPage] = useState(10);

const [batchId,setBatchId]=useState([])

console.log(batches,"console.log(batches);");
const get_batch_by_inst_id = async () => {
  console.log('branchId :>> ', branchId)
  await api
  .get_batch_all(JSON.parse(localStorage.getItem("userHilingo")).branch_id || branchId)
    .then((res) => {
      if (res?.data?.length > 0) {
        setBatches(res?.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};


useEffect(() => {
  console.log('inside upos :>> ');

  get_batch_by_inst_id();
  // handleApiCallAllRec()
  
}, []);

// console.log('inside handleApiCallAllRec2 :>> ',batches);

useEffect(() => {
  console.log('inside upos :>> ');

  handleApiCallAllRec()
  
}, [batches]);

useEffect(() => {
  async function get_Sinlge_batch(batchId){
    console.log('batchId :>> ', batchId);

  let data =  await api.get_Sinlge_batch(branchId,{batch_id:batchId})
  .then((res)=>{
    if(res?.data?.length>0){


      setBatchFilterSearchData(res?.data)
      setFilterDatObjBatch({
        ...filterDataObjBatch,
       
       
        product: "",
        testType: "",


      });
   

    }
  
  })
    
  }
  get_Sinlge_batch(batchId)
},[batchId]);


const handleApiCallAllRec= async ()=>{
  console.log('inside handleApiCallAllRec :>> ',batches,batchId);
  let arr=new Array();

batches?.map((item)=>{

  arr.push(item?._id)
})
// if(arr.length>0){
//   setBatchId(arr)
// }
console.log('sfdarr4324 :>> ', arr,JSON.parse(localStorage.getItem("userHilingo")).branch_id ||branchId);

 
  await api.get_Sinlge_batch(JSON.parse(localStorage.getItem("userHilingo")).branch_id || branchId,{batch_id:arr})
  .then((res)=>{
    if(res?.data?.length>0){


      setBatchFilterSearchData(res?.data)
      setBatchFilterSearchDataAll(res?.data)
   

    }
  
  })
}

const handleApiCall= async (id)=>{
  console.log('id :>> ', id);

  if(!batchId.includes(id)){

    // setBatchId([...batchId,id])
    setBatchId((prev)=>([...prev,id]))
  }
  else{
    // console.log(' object batchId :>> ', batchId);
    setBatchId(batchId?.filter((item)=> item!=id))
  // console.log('object :>> ',   batchId?.filter((item)=> item!=id))
  }
  // await api.get_Sinlge_batch(branchId,{batch_id:batchId})
  // .then((res)=>{
  //   if(res?.data?.length>0){


  //     setBatchFilterSearchData(res?.data)
  //     setFilterDatObjBatch({
  //       ...filterDataObjBatch,
       
       
  //       product: "",
  //       testType: "",


  //     });
   

  //   }
  
  // })
}
console.log('batchId :>> ', batchId);

let PageSize = 5;

const [currentPage, setCurrentPage] = useState(1);

const currentTableData = useMemo(() => {
  const firstPageIndex = (currentPage - 1) * showItemPerPage;
  const lastPageIndex = firstPageIndex + showItemPerPage;
  return batchFilterSearchData.slice(firstPageIndex, lastPageIndex);
}, [currentPage,batchFilterSearchData,showItemPerPage]);

console.log('currentTableData', currentTableData)
const theme = createTheme({
  elevation:2
});


return (
    <Layout title="Add Student" content="container">
      <h1 className="mb-5">Add Notification</h1>

      <Block>
        <Card className="h-100">
          {count == 0 && <Card.Body>
            <Row className="g-gs">
              <Col md="9">
                <Row className="g-gs">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Title</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="title"
                          onChange={(e) => handleChange(e)}
                          value={data?.title}
                          type="text"
                          id="subject"
                          placeholder="Enter a title here..."
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label>Sub title</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="sub_title"
                          onChange={(e) => handleChange(e)}
                          value={data?.sub_title}
                          type="text"
                          id="subject"
                          placeholder="Enter a sub title here..."
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="message">Description</Form.Label>
                      <div className="form-control-wrap">
                       
                       
                      <NewQuill  placeholderValue={"Type your message here..."} 
                      sendData={sendData}
                      />
                    
                        {/* <Form.Control
                          onChange={(e) => handleChange(e)}
                          name="description"
                          value={data?.description}
                          as="textarea"
                          placeholder="Type your message here..."
                          id="message"
                          rows="3"
                        ></Form.Control> */}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group d-flex">
                      <div className="w-50">
                      <Form.Label>Upload an attachment</Form.Label>
                      <div className="form-control-wrap">

                     
                        {/* <FileUploadAnnoucement
                          filetype="image"
                          iconName="file"
                          name="attachment"
                          maxFiles={1}
                          ImageData={data}
                           setdata={(data)=>setdata(data)}
                          onChange={(updatedFiles) => {
                            setdata({ ...data, attachment: updatedFiles[0] });
                          }}
                          errorText="Multiple files not supported, please upload single file."
                        /> */}


                

<FileUploadImageExtension
                          iconName="files"
                          name="attachment"
                          maxFiles={1}
                          ImageData={data}
                          setdata={(data)=>setdata(data)}                        
                          filetype="image"
                          // aspectRatioCalculate={2}
                          // aspectRatio="Recommended Aspect ratio 2:1"
                          
                          //  maxSize={4194304} //--> if use this line then comment the fileType props
                          //  errorText="File size is too large, please upload file size within (12MB)"

                          onChange={(uploadDocData) =>
                            {
                               const reader=new FileReader();
                              reader.addEventListener("load",()=>{
                                // localStorage.setItem("recent-image",reader.result)
                                setNotificationImage(reader.result)
                              })    
                             if(uploadDocData.length)
                              reader.readAsDataURL(uploadDocData?.[0])
                            }
                          }
                        />

{/* <div className="mt-2">
                    <small>
                    Image must be JPG/JPEG or PNG (Aspect ratio 2:1)
                    </small>
                    </div> */}
                    <Button  onClick={() => window.open('https://www.canva.com/templates/?query=banner-landscape-72x36', '_blank')
                    }variant="primary" size="sm" className="mt-2  add-btn" style={{marginLeft:"92px"}}>  Design Free banners </Button>

                      </div>
                      </div>

                      {/* <TimePicker
        label='Time Picker'
        // value={selectedTime}
        onChange={newValue => {
          setSelectedTime(newValue)
        }}
        renderInput={props => <TextField {...props} />}
      /> */}


   
                      <div className="w-50">
                      <Form.Label>Button Name</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="button_name"
                          onChange={(e) => handleChange(e)}
                          value={data?.button_name}
                          type="text"
                          id="button_name"
                          placeholder="Button name "
                        />
                      </div>

                      <Form.Label>Button Link</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="button_link"
                          onChange={(e) => handleChange(e)}
                          value={data?.button_link}
                          type="text"
                          id="button_link"
                          placeholder="Button link"
                        />
                      </div>
                      </div>

                    </Form.Group>

                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group d-flex">
                      <Col lg="6">
                        <Form.Check inline label="Send Now" onClick={() => setdata({ ...data, sending_type: "now",status:"complete" })} defaultChecked name="inlineRadioOptions" type="radio" id="inlineRadio1"></Form.Check>
                        <Form.Check inline label="Send Later" onClick={() => setdata({ ...data, sending_type: "later" ,status:"pending"})} name="inlineRadioOptions" type="radio" id="inlineRadio2"></Form.Check>
                      </Col>

                    </Form.Group>
                  </Col>
                  {data?.sending_type == "later" && 
                  <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label>Date</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control type="date" onChange={(e) => setdata({...data, date:e.target.value})} />
                        {/* <DatePicker /> */}
                      </div>
                    </Form.Group>
                  </Col>
                  }
                  {data?.sending_type == "later" && <Col lg="6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Hours</Form.Label>
                    {/*  <div className="form-control-wrap addNotification">
                       <TimePickerWithProps name="hours"  format={12} placeholder="hrs" interval={60} value={data?.hours} onChange={(dataFromComp) => setdata({...data, ...dataFromComp })}/>
                      </div>


                      <Form.Label htmlFor="fullname">Minutes</Form.Label>
                      <div className="form-control-wrap addNotification">
                       <TimePickerWithProps name="minutes"  placeholder="hrs" start={"01:00"} end={"60:00"} interval={1} value={data?.minutes} onChange={(dataFromComp) => setdata({...data, ...dataFromComp })}/>
                      </div>    */}

<ThemeProvider theme={theme}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'TimePicker',
          'DateTimePicker',
          'DateRangePicker',
        ]}
      >
  
      <TimePicker 
        value={selectedTime}
        name="hours"
        views={['hours', 'minutes']}
        // onChange={newValue => {
        //   setSelectedTime(newValue)
        // }}
        // onChange={(newValue) => setdata({...data, hours:new Date(newValue).toLocaleTimeString() })}
        onChange={(newValue) => setdata({...data, hours:newValue?.$d?.toLocaleTimeString()?.split(":")[0], minutes:newValue?.$d?.toLocaleTimeString()?.split(":")[1] })}
        renderInput={params => <TextField   color="warning" {...params} />}
      />
          {/* <DatePicker defaultValue={dayjs(new Date())} /> */}

          {/* hours: Wed, 10 Apr 2024 19:10:00 GMT */}
      </DemoContainer>
      
    </LocalizationProvider> 
    </ThemeProvider>

                    </Form.Group>



                    
                  </Col>}
                  {/* {data?.sending_type == "later" && 
                  <Col lg="3">
                    <Form.Group className="form-group">
                    <Form.Label htmlFor="fullname">Am/Pm</Form.Label>
                    <Form.Select
                      id="priority"
                      aria-label="Default select example"
                      onChange={(dataFromComp) => setdata({...data, ...dataFromComp})}
                      name="minutes"
                  
                    >
                       

                      <option value="">Select</option>
                      <option value="am">Am</option>
                      <option value="pm">Pm</option>
                      
                    </Form.Select>

                    
                   
                    </Form.Group>
                  </Col>
} */}
                </Row>
              </Col>
              <Col md="3"></Col>
              <Col md="12" className="my-5">
                {/* <Button disabled={!checkValidation()} onClick={() => setCount((count) => count + 1)}>Next</Button> */}
                <Button className="theme-colored" disabled={!checkValidation()} onClick={()=>navigate("/student-management/student-list-notification")}>Next</Button>
                {/* <SubmitButton
                  text="Submit"
                  onClick={() => sendTicket()}
                  loader={loader}
                  disabled={!checkValidation()}
                /> */}
              </Col>
            </Row>
          </Card.Body>}
          {count == 1 && <Card.Body>
            <Tab.Container id="assign-tabs" defaultActiveKey="student-test">
              {/* <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                <Nav.Item>
                  <Nav.Link eventKey="batch-test">Batch</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="student-test">Student</Nav.Link>
                </Nav.Item>
              </Nav> */}
              <Tab.Content>
                <Tab.Pane eventKey="batch-test">
                  <Tab.Container id="batch-tabs" defaultActiveKey="batch-details">
                    <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="batch-details">Batch Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="test-details">Test Details</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="batch-details">
                        <Row className="g-gs my-3">
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="program-text">Program</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control type="text" id="program-text" value="PTE" disabled />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText5">Choose Plan</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Select id="exampleFormControlInputText5" aria-label="Default select example">
                                  <option value="0">Choose Plan</option>
                                  <option value="1">Free</option>
                                  <option value="2">VIP 30 Days</option>
                                  <option value="3">VIP 90 Days</option>
                                  <option value="4">VIP 180 Days</option>
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="program-text">Program</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control type="text" id="program-text" value="PTE" disabled />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText5">Student Type</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Select id="exampleFormControlInputText5" aria-label="Default select example">
                                  <option value="0">Choose Student Type</option>
                                  <option value="1">Single Test</option>
                                  <option value="2">Bulk Test</option>
                                </Form.Select>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <ButtonGroup aria-label="Basic radio toggle button group" className="hilingo-radio-button gap-3">
                              <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                              <Button as="label" variant="outline-primary" htmlFor="btnradio3" className="flex-column">PTE_DEFAULT <p>(11:00 AM 11:01 AM)</p></Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <Row className="g-gs my-3">
                          <Col md="12">
                            <h5>BATCH DETAILS</h5>
                            {/* You can use data list component */}
                            <Table responsive striped bordered hover className="small">
                              <thead>
                                <tr className="bg-primary">
                                  <th className="text-white">Batch Name</th>
                                  <th className="text-white">Teacher</th>
                                  <th className="text-white">No. of Students</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>PTE_default</td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                      <Form.Check label="" type="checkbox" id="inlineCheckbox1"></Form.Check>
                                      <span> Teacher</span>
                                      <Button variant="transparent"><Icon name="search"></Icon></Button>
                                    </div>
                                  </td>
                                  <td>0/0</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                      <Form.Check label="" type="checkbox" id="inlineCheckbox1"></Form.Check>
                                      <span> Teacher</span>
                                      <Button variant="transparent"><Icon name="search"></Icon></Button>
                                    </div>
                                  </td>
                                  <td>0/0</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>Total Students</td>
                                  <td>1</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="test-details">
                        <Tab.Container id="assign-tabs" defaultActiveKey="sectionwise-test">
                          <Nav variant="pills" className="mb-5 justify-content-start gap-2">
                            <Nav.Item>
                              <Nav.Link eventKey="sectionwise-test">Section Wise Test</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="mock-test">Mock Test</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="sectionwise-test">
                              {/* You can use data list component */}
                              <Table responsive striped bordered hover className="small">
                                <thead>
                                  <tr className="bg-primary">
                                    <th></th>
                                    <th className="text-white">Test Name</th>
                                    <th className="text-white">Module</th>
                                    <th className="text-white">Category Name</th>
                                    <th className="text-white">Level</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-01(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-02(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-03(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE MOCK-04(v1)</td>
                                    <td>-</td>
                                    <td>Mock</td>
                                    <td>Hard</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 01</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 02</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 03</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Medium</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check label="" type="checkbox" id="sectionwise-1"></Form.Check></td>
                                    <td>PTE Speaking - 04</td>
                                    <td>Speaking</td>
                                    <td>Practice</td>
                                    <td>Hard</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Tab.Pane>
                            <Tab.Pane eventKey="mock-test">
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane>

                <Tab.Pane eventKey="student-test">
                  <Tab.Container id="student-tabs" defaultActiveKey="select_batch">
                    <Nav variant="pills" className="mb-5 justify-content-start gap-2 enquiry-tabs">
                      {/* <Nav.Item className="w-50">
                        <Nav.Link eventKey="select-student">Select Student</Nav.Link>
                      </Nav.Item> */}
                           <Nav.Item>
                      <Nav.Link eventKey="select_batch" >
                        
                Batch
              </Nav.Link>
              </Nav.Item>
                      <Nav.Item >
                        <Nav.Link eventKey="select-student">Student </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                  
                      <Tab.Pane eventKey="select_batch">
                        <div className="d-flex">

                        <div className="text-secondary mx-1">Show</div>
                        <div className="data-table-select ">
                <select
                  className="form-select mt-4 me-2"
                  onChange={(e) => setShowItemPerPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
               
              </div>


                      <DropDown
                        //  object={true}-------> to ignore target.value of product dropdown
          className="w-50 mb-3"
          data={Product_batch}
          text="Products"
          name="product"
          value={filterDataObjBatch?.product}
          onChange={handleDateChangeBatch}
          defaultSelectedOption={"All"}
          width={{ width: "140px" }}
      
        />
        <DropDown
          className="w-50 mb-3"
        
          data={Student_type_batch}
          text="Student Type"
          name="testType"
          value={filterDataObjBatch?.testType}
          onChange={handleDateChangeBatch}
          defaultSelectedOption={"All"}
          width={{ width: "140px" }}
      
        />

<Form.Group className="form-group d-flex w-20 ms-2 align-items-end">
          <div className="form-control-wrap d-flex me-2 align-items-end">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObjBatch({
                  ...filterDataObjBatch,
                 
                 
                  product: "",
                  testType: "",

 
                });
                setBatchFilterSearchData(batchFilterSearchDataAll);
                
            
            
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" onClick={filterDataBatch}>
              Search
            </Button>
          </div>
        </Form.Group>

</div>
<div className="mt-2">
                    <h5>Select Batch</h5>
                    <hr></hr>
                    <ButtonGroup
                      aria-label="Basic checkbox toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      {batches.length > 0 &&
                        batches.map((item, i) => {
                          return (
                            <>
                              <input
                                type="checkbox"
                                className="btn-check"
                                name="batchTime"
                                value={item._id}
                                onClick={(e) => handleChange(e)}
                                id={`btnradio${(i*2).toString()}`}
                                autoComplete="off"
                                
                                // checked={selectBatch==i}
                                // checked={selectBatch }
                               
                                
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor={`btnradio${(i*2).toString()}`}
                                name="batchTime"
                                onClick={() => handleApiCall(item?._id)}
                                value={item._id}
                                
                              >
                                 {`PTE ${item.starttime+" to "+item.endtime}`}
                              </Button>
                            </>
                          );
                        })}
                    </ButtonGroup>





                    <Row className="g-gs my-3">
                          <Col md="12">
                          
                      
                            <Table responsive striped bordered hover className="small">
                              <thead>
                                <tr className="bg-primary">
                                  <th className="text-white">Sr No.</th>
                                  <th className="text-white">Email</th>
                                  <th className="text-white">Student Name</th>
                                  <th className="text-white">Product</th>
                                  <th className="text-white">Mobile</th>
                                  <th className="text-white">Teacher</th>
                                  <th className="text-white">Student type</th>
                                </tr>
                              </thead>
                            
                              <tbody>
                               
                                 
                                  
                               {
                                currentTableData.length > 0  && 
                                currentTableData?.[0]?.studentName ?
                                currentTableData.map((item, index) => {
                                    return (
                                      <tr key={item._id}>
                                        <td>
                                          <Form.Check
                                            type="checkbox"
                                            onClick={() => {
                                              if (data.user_id.includes(item._id)) {
                                                setdata(prevData => ({
                                                  ...prevData,
                                                  user_id: prevData.user_id.filter(id => id !== item._id)
                                                }));
                                              } else {
                                                let arr = [...data.user_id]
                                                arr.push(item._id)
                                                setdata(prevData => ({
                                                  ...prevData,
                                                  user_id: arr
                                                }));
                                              }
                                            }}
                                            id={`flexRadioDefault-${index}`}
                                            label={index}
                                          />
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>{item?.studentName}</td>
                                        <td>{item?.productType}</td>
                                        <td>{item?.mobile}</td>
                                        <td>{item?.teacher}</td>
                                        <td>{item?.testType}</td>
                                       
                                   
                                      </tr>
                                    );
                                  })
                                  :
                                  <tr>
                                  <td colSpan="6">No record found</td>
                                </tr>
                                 
                                
                                }
                                
                              
                              </tbody>
                              
                              
                            



                            </Table>

                            <Mypagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={batchFilterSearchData.length}
        pageSize={showItemPerPage}
        onPageChange={page => setCurrentPage(page)}
      />
                          </Col>
                        </Row>
                  </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="select-student">

<div className="d-flex">
<DropDown
  //  object={true}-------> to ignore target.value of product dropdown
className="w-50 mb-3"
data={Product}
text="Products"
name="product"
value={filterDataObj?.product}
onChange={handleDateChange}
defaultSelectedOption={"All"}
width={{ width: "140px" }}

/>
<DropDown
className="w-50 mb-3"

data={Student_type}
text="Student Type"
name="testType"
value={filterDataObj?.testType}
onChange={handleDateChange}
defaultSelectedOption={"All"}
width={{ width: "140px" }}

/>
<DropDown

className="w-15 mb-3"
data={teachers}
text="Teachers"
name="teacher"
value={filterDataObj?.teacher}
onChange={handleDateChange}
defaultSelectedOption={"All"}
width={{ width: "140px" }}

/>

<Form.Group className="form-group w-25 ">
<Form.Label>Name</Form.Label>
<div className="form-control-wrap me-2">
<Form.Control
id="filter"
placeholder="Search By Name"
value={filterDataObj?.name}
name="name"
onChange={handleDateChange}
/>
</div>
</Form.Group>



<Form.Group className="form-group d-flex w-20 ms-2 align-items-end">
<div className="form-control-wrap d-flex me-2 align-items-end">
<Button
className="me-2"
onClick={(e) => {
setFilterDatObj({
...filterDataObj,

name: "",

teacher: "",
product: "",
testType: "",


});
setFilterSearchData(studentList);


}}
variant="secondary"
>
Reset
</Button>
<Button variant="primary" onClick={filterData}>
Search
</Button>
</div>
</Form.Group>
</div>

  <Row className="g-gs my-3">
    <Col md="12">
      {/* <h5>SELECT STUDENT</h5>
      <Form.Group className="form-group my-3">
        <div className="form-control-wrap">
          <div className="form-control-icon end"><Icon name="search"></Icon></div>
          <Form.Control type="text" placeholder="Student Name" />
        </div>
      </Form.Group> */}
      {/* You can use data list component */}
      <Table responsive striped bordered hover className="small">
        <thead>
          <tr className="bg-primary">
            <th className="text-white">Sr No.</th>
            <th className="text-white">Email</th>
            <th className="text-white">Student Name</th>
            <th className="text-white">Product</th>
            <th className="text-white">Mobile</th>
            <th className="text-white">Teachers</th>
            <th className="text-white">STudent Type</th>
            {/* <th className="text-white">Batch</th> */}
            {/* <th className="text-white"></th> */}
          </tr>
        </thead>
        { filterSearchData.length > 0 ?
        <tbody>
         
           
            
         {
            filterSearchData.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      onClick={() => {
                        if (data.user_id.includes(item._id)) {
                          setdata(prevData => ({
                            ...prevData,
                            user_id: prevData.user_id.filter(id => id !== item._id)
                          }));
                        } else {
                          let arr = [...data.user_id]
                          arr.push(item._id)
                          setdata(prevData => ({
                            ...prevData,
                            user_id: arr
                          }));
                        }
                      }}
                      id={`flexRadioDefault-${index}`}
                      label={index}
                    />
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.name}</td>
                  <td>{item?.product}</td>
                  <td>{item.mobile}</td>
                  <td>{item?.stu_profile?.assignTeacher}</td>
                  <td>{item?.stu_profile?.testType}</td>
                  {/* <td>PTE_default</td> */}
                </tr>
              );
            })
            
              
           
          
          }
          
        
        </tbody>
        
        :
        <tbody>
        <tr>
          <td colSpan="6">No record found</td>
        </tr>
        </tbody>
        }

{/* { studentList.length > 0 ?
        <tbody>
         
           
            
         {
            studentList.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      onClick={() => {
                        if (data.user_id.includes(item._id)) {
                          setdata(prevData => ({
                            ...prevData,
                            user_id: prevData.user_id.filter(id => id !== item._id)
                          }));
                        } else {
                          let arr = [...data.user_id]
                          arr.push(item._id)
                          setdata(prevData => ({
                            ...prevData,
                            user_id: arr
                          }));
                        }
                      }}
                      id={`flexRadioDefault-${index}`}
                      label={index}
                    />
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.stu_profile?.assignTeacher}</td>
                  <td>{item?.product}</td>
                  <td>{item.mobile}</td>
                  <td>{item?.stu_profile?.assignTeacher}</td>
          
                </tr>
              );
            })
            
              
           
          
          }
          
        
        </tbody>
        
        :
        <tbody>
        <tr>
          <td colSpan="6">No record found</td>
        </tr>
        </tbody>
        } */}


      </Table>
    </Col>
  </Row>
</Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

     


                <Button className="fw-bold" size="sm" style={{marginRight:"0.5rem"}} onClick={()=>setCount((count)=>count-1)}>Previous</Button>
                <SubmitButton
                  text="Submit"
                  onClick={() => sendTicket()}
                  loader={false}
                  disabled={!checkValidation()}
                />
          </Card.Body>}
        </Card>
      </Block>
    </Layout>
  );
}

export default AddNotification;

