import React from 'react';

const PracticePauseSvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style={style}><g><path d="M437.019 74.98C388.667 26.629 324.38 0 256 0 187.619 0 123.332 26.629 74.98 74.98 26.629 123.332 0 187.619 0 256s26.629 132.668 74.98 181.02C123.332 485.371 187.619 512 256 512c68.38 0 132.667-26.629 181.019-74.98C485.371 388.668 512 324.38 512 256s-26.629-132.668-74.981-181.02zM256 482C131.383 482 30 380.617 30 256S131.383 30 256 30s226 101.383 226 226-101.383 226-226 226z" fill={fill} opacity="1" data-original="#000000" class=""></path><path d="M304.199 137.723c-8.284 0-15 6.716-15 15V359.28c0 8.284 6.716 15 15 15s15-6.716 15-15V152.723c0-8.284-6.716-15-15-15zM207.799 137.723c-8.284 0-15 6.716-15 15V359.28c0 8.284 6.716 15 15 15s15-6.716 15-15V152.723c0-8.284-6.716-15-15-15z" fill={fill} opacity="1" data-original="#000000" class=""></path></g></svg>
    
    
  );
}

export default PracticePauseSvg;
