import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import AuthApi from "../../api/auth";
import { DropDown } from "../../components/Form/DropDown";
function TicketsIntFilter({
  allData,
  tableData,
  setData,
  showItemPerPage,
  setShowPage,
  currentFilterName,

}) {
  console.log("tableData", tableData,allData);

  const uniqueBatchValues = new Set();
  const Batch = allData

    .map((item) => {
      const value = `${item?.starttime} to ${item?.endtime}`;
    
      const obj = { value};
      const stringifiedObj = JSON.stringify(obj);
      if (!uniqueBatchValues.has(stringifiedObj)) {
        uniqueBatchValues.add(stringifiedObj);
        return obj;
      }
      return null;
    })
    ?.filter(Boolean);

    console.log('uniqueBatchValuesBatch :>> ', Batch,uniqueBatchValues);

  const [filterDataObj, setFilterDatObj] = useState({});


  const handleDateChange = (e) => {
    let { value, name } = e.target;
    console.log('value,name :>> ', value,name);
   
      setFilterDatObj({ ...filterDataObj, [name]: value });
   
  };

  let {
    
    student,
   
    department,
    
    
  } = filterDataObj;

  console.log('filterData34sf :>> ', filterDataObj,!student,!department);
  const filterData =  () => {
    
    console.log("tableData323",allData)
    const filteredData = allData?.filter((item) => {
      console.log("f###",item?.department?.toLowerCase().includes(department?.toLowerCase()?.trim()))
     
      return (
        // first  student
        //second department  
        // both (&&) are must be true then only return data
        
        (!student ||
          item?.user_id?.name.toLowerCase().includes(student?.toLowerCase()?.trim())) &&
          // in below one (||) must be true then only return data
           
        
          (!department ||
            item?.department?.toLowerCase().includes(department?.toLowerCase()?.trim())) 

      
      
        
      );

    });



    
    console.log("filteredDatafsadf", filteredData);
    setData(filteredData);

  };

  // useEffect(() => {
  //   if (tableData?.length) {
  //     const filtered = tableData.filter((item) => {
  //       return (
  //         item?.user_id?.name.toLowerCase().includes(student?.toLowerCase()?.trim()) &&
  //         item?.department?.toLowerCase().includes(department?.toLowerCase()?.trim())
  //       );
  //     });
    
  //   }
  // }, [tableData, student, department]);



  // useEffect(()=>{
  //   setData(allData);
  // },[])
  let api = new AuthApi();

  const [staffList, setStaffList] = useState([]);
 

  let DropDownWidth = { width: "140px" };

  // const enquiryTypeArr = [
  //   "Billing",
  //   "Press",
  //   "Sales",
  //   "Technical",
 
  // ];
  const stuTypeArr = [... new Set(allData?.map((item)=>item?.user_id?.name)
  )]

  const departTypeArr = [... new Set(allData?.map((item)=>item?.department)
  )]
  console.log("Batch", Batch);
  console.log('currentFilterName :>> ', currentFilterName);
  console.log("filterDataObj", filterDataObj);
  console.log('hellokHar :>> ', filterDataObj?.teacher);
  return (
    <>
      <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2">
            <select
              className="form-select"
              onChange={(e) => setShowPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>
   
       

        <DropDown
          className="w-25 mb-3"
          data={departTypeArr}
          defaultSelectedOption={"All"}
          text="Department Name"
          name="department"
          onChange={handleDateChange}
          width={DropDownWidth}
          value={filterDataObj?.department}
        />

<DropDown
          className="w-25 mb-3"
          data={stuTypeArr}
          defaultSelectedOption={"All"}
          text="Student"
          name="student"
          onChange={handleDateChange}
          width={DropDownWidth}
          v  value={filterDataObj?.student}
        />

      

       

       
        

        
        
       

        <Form.Group className="form-group w-20 ms-2 mb-3">
          <Form.Label
            htmlFor="exampleFormControlInputText5"
            style={{ visibility: "hidden" }}
          >
            Filter Reset
          </Form.Label>
          <div className="form-control-wrap d-flex me-2">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObj({
                  ...filterDataObj,
                  
                  student: "",
                 
                  department:"",
                 
                  
                });

                setData(allData);
               
                
                
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" onClick={()=>filterData()} className="theme-colored">
              Search
            </Button>
          </div>
        </Form.Group>
      </div>
    </>
  );
}

export default TicketsIntFilter;


