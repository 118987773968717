import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "../../../components";
function SubmitButton({text,loader,disabled, onClick ,hideIcon, cursorNotAllowed, borderColor, backgroundColor,color}) {
  return (
    <>{!loader?
      <Button className="fw-bold" size="sm" disabled={disabled} style={{color:color,cursor:cursorNotAllowed,borderColor:borderColor,backgroundColor:backgroundColor, pointerEvents:cursorNotAllowed == "not-allowed" && "all"}}  onClick={onClick}>
      {  text?text:"Submit" }
      {/* <Icon name="arrow-right" className="ms-2"></Icon> */}
      </Button>
    :
      <Button className="fw-bold" size="sm" type="button" disabled>
        <span
          style={{maginRight:"2px !important"}}
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Submitting...
      </Button>
      }
    </>
  );
}

export default SubmitButton;
