import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import Question_Context from "../../../../context/questionContext";
import { useContext, useState, useEffect } from "react";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteWritingEssay() {
  let { id } = useParams();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "writing_essayss",
    instructions:
      "You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.",
    question_options: [
      {
        answer: "",
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    const {
      instructions,
      question_content,
      tags,
      question_type,
      module_name,
      module,
    } = data;
    return (
      instructions &&
      question_content &&
      tags &&
      data?.answer_time?.length === 5 &&
      // data.question_options[0].answer &&
            (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // if (name === "answer") {
    //   const updatedOptions = [...data.question_options];
    //   updatedOptions[0] = {
    //     ...updatedOptions[0],
    //     answer: value,
    //   };
    //   setData({
    //     ...data,
    //     question_options: updatedOptions,
    //   });
    // } else {
    if (name == "question_type") {
      const options = e.target.options;
      const selectedValues = [];
      if (options.length == 0) {
        setSelectedOptions([]);
      }
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedValues.push(options[i].value);
        }
        setSelectedOptions(selectedValues);
      }
      setData({
        ...data,
        isMock: selectedValues.includes("isMock") ? true : false,
        isPractice: selectedValues.includes("isPractice") ? true : false,
        isSection: selectedValues.includes("isSection") ? true : false,
        isQuestion: selectedValues.includes("isQuestion") ? true : false,
        isPrediction: selectedValues.includes("isPrediction") ? true : false,
      });
    } else {
      setData({ ...data, [name]: value });
    }
    // }
  };
  let api = new AdminApi();
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(!loader);
    await api
      .add_Question(data)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/view-questions");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
        }
        setloader(!loader);
      })
      .catch((err) => {
        setloader(!loader);
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add PTE Writing Essay Questions</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => {
                      setSelectedOptions(e);
                    }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e);
                      setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    {id && (
                      <EditTimesFiled
                        data={data}
                        onDataChange={(name, val) =>
                          setData({ ...data, [name]: val })
                        }
                      />
                    )}

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="instruction"
                          className="ms-3 fw-bold"
                        >
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Essay Topic
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="question_content"
                            value={data.question_content}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Paragraph"
                            id="paragraph"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <Form.Group className="form-group">
                            <Form.Label>Type</Form.Label>
                            <div
                              className="form-control-wrap ms-1 me-1"
                              style={{ width: "140px" }}
                            >
                              <Form.Select
                                removeItemButton
                                value={data?.image_type}
                                name="image_type"
                                onChange={(e) => {
                                  setData({ ...data, image_type: e.target.value });
                                }}
                              >
                                <option value="">Select</option>
                                <option value={"dual_q"}>Dual Q </option>
                                <option value={"y/n"}>Y/N </option>
                                <option value={"open_q"}>Open Q </option>
                              </Form.Select>
                            </div>
                          </Form.Group>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <TranscriptExplanation
                        setState={(name) => setData({ ...data, [name]: "" })}
                        data={data}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Tag (#)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="addtag"
                            value={data.tags}
                            placeholder="#Tag"
                            name="tags"
                            onChange={(e) => {
                              var removeHash = e.target.value.replaceAll(
                                "#",
                                ""
                              );
                              removeHash = "#" + removeHash;
                              setData({ ...data, tags: removeHash });
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Sample Answer</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            value={data.question_options[0].answer}
                            placeholder="Add Answer"
                            id="answer"
                            onChange={(e) => handleChange(e)}
                            name="answer"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col> */}
                  </Row>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton
                    loader={loader}
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteWritingEssay;
