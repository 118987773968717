

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import Icon from "../Icon/Icon";
import { Button, CloseButton } from 'react-bootstrap';
function FileUploadImageExtensionOld({ props, iconName, noImage, onChange, ImageData, setdata }) {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],

    }
  });

  const [files, setFiles] = useState([]);
  const [updatedFiles, setUpdatedFiles] = useState([])
  const [swalClose, setSwalClose] = useState(true)

  const handleDropChange = (acceptedFiles) => {
    console.log(acceptedFiles, "accepted files");
    const updatedFileArr = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    console.log('updatedFileArr :>> ', updatedFileArr);
    setUpdatedFiles(updatedFileArr)
    setSwalClose(true)
  }


  useEffect(() => {
    setFiles(updatedFiles)
    if (onChange) {
      onChange(updatedFiles);
    }

  }, [updatedFiles])

  useEffect(() => {
    handleDropChange(acceptedFiles)
  }, [acceptedFiles.length > 0])




  const thumbs =
    !noImage &&
    files.map((file) => (
      <div
        className="dz-preview dz-processing dz-image-preview dz-complete"
        key={file.name}
      >
        <div className="dz-image">
          <img src={file.preview} alt="preview" />
        </div>
      </div>
    ));

  console.log('fileRejections :>> ', fileRejections);
  console.log('acceptedFiles :>> ', acceptedFiles);
  // const fileRejectionItems = fileRejections.map(({ file, errors }) => {


  //   console.log('errors?.message :>> ', errors?.[0]?.message);

  //     if(errors?.[0]?.message){

  //   //  return( Swal.fire({
  //   //     icon: "error",
  //   //     title: "Oops",
  //   //     text: "Please select Image",
  //   //   }))

  //     Swal.fire({
  //                 icon: "error",
  //                 title: "Oops",
  //                 text: "Please select Image",
  //               });
  //               // return;
  //             }
  //           })





  if (swalClose) {

    if (fileRejections?.[0]?.errors?.[0]?.message) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        //   text: fileRejections?.[0]?.errors?.[0]?.message,
        text: "Image must be JPG/JPEG or PNG",
      }).then((result) => {
        if (result.isConfirmed) {
          // Perform your onClick handler functionality here
          setSwalClose(false);

        }
      });;
      // return;
    }
  }


  const clickHandler = () => {
    setFiles([])
    setdata((prev) => ({ ...prev, attachment: "" }))

  }

  // );
  console.log(files, "files");


  return (
    <section className="container">
      {ImageData?.attachment &&
        <CloseButton
          className="upload-close-btn mb-4 position-absolute"
          // value={item.url}
          name="deletebanner"
          onClick={

            clickHandler


          }
        />
      }

      <div {...getRootProps()} className="dropzone dz-clickable image-upload">

        <input {...getInputProps()} required />
        {(files.length === 0 || noImage) && (
          <div className="dz-message">
            <div className="dz-message-icon">
              <Icon size="lg" name={iconName} />
            </div>
            <div className="dz-message-btn">
              <Button size="md" variant="transparent">
                Upload
              </Button>

            </div>

            <small className="dz-message-text">
            Image must be JPG/JPEG or PNG
            (Recommended Aspect ratio 2:1)
            </small>
          </div>
        )}
        {files.length > 0 && files[0].type?.startsWith("image/")
          ? thumbs
          : files[0]?.name}
      </div>
    </section>
  );
}

export default FileUploadImageExtensionOld