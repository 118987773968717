import React, { useEffect, useState } from 'react'
import Dnd, { Blank } from "./Dnd";
import { Flex, Heading } from "@chakra-ui/react";
import parse from 'html-react-parser';
function Fib_rb({data, switchChecked}) {
    console.log("switchChecked",switchChecked);
    const [dndData, setDndData] = useState({
      question_content : data?.question_content,
      answers : data?.question_options?.length > 0 && data?.question_options[0].answer,
      options: data?.question_options?.length > 0 && data?.question_options[0].options,
    });
    const [blanksArray, setBlanksArray] = useState([]);

    function previousDrag(){
      const previousDragged = document?.getElementsByClassName("css-sx1jzh");  
      console.log("previousDragged",previousDragged);
    }
    
    // const question_content = data?.question_content;
    // const answers = data?.question_options?.length > 0 && data?.question_options[0].answer;
    // const blanksArray = [];
    const changeData = () => {
      if (!dndData.question_content) return;

      const splitted = dndData.question_content.split(' ');
      const temp = [];
      let answerIndex = 0; // To track the current position in the answers array

      splitted.forEach((word, index) => {
          const cleanWord = word.replace(/[\.,]/g, ''); // Clean up punctuation if needed
          
          // Check if the current word matches the current answer in sequence
          if (dndData.answers[answerIndex] === cleanWord) {
              // Replace the word with a <Blank> at the correct answer position
              temp.push(
                  <Blank className="css-q4u996" key={index} taskId={index} solution={cleanWord} />
              );
              temp.push(
                  <span
                      className="text-success AnswerForFib"
                      key={`${index}-answer`}
                      style={{ display: 'none', color: 'green' }}
                      id={cleanWord} add_practice_result
                  >
                      (Answer:&nbsp;{cleanWord})
                  </span>
              );
              answerIndex++; // Move to the next answer in the sequence
          } else {
              temp.push(`${word} `);
          }
      });

      setBlanksArray(temp);
  };
    console.log("split", blanksArray);
    const temp = ["I", "am", <Blank solution={"saddas"} />, "hello",]


    useEffect(() => {
      setDndData({
        question_content : data?.question_content,
        answers : data?.question_options?.length > 0 && data?.question_options[0].answer,
        options: data?.question_options?.length > 0 && data?.question_options[0].options,
      })
      setBlanksArray([])
    },[data])

    useEffect(() => {
      changeData();
      
      console.log("INSIDE CHANGEDATA");
    },[dndData])

    useEffect(() => {
      if(switchChecked){
        const eles = document?.getElementsByClassName("AnswerForFib");
        for(var i=0;i<eles?.length;i++){
          eles[i].style.display=""
        }
        console.log("eles",eles);
      } 
      else{
        const eles = document?.getElementsByClassName("AnswerForFib");
        for(var i=0;i<eles?.length;i++){
          eles[i].style.display="none"
        }
        console.log("eles",eles);
      }
      
    },[switchChecked])

    console.log("dndData",dndData);
    console.log("blanksArray",blanksArray);
  return (
    <Flex direction="column" alignItems="center" px="6">
      {data?.question_content?.length > 0 && blanksArray.length>0 &&
      <Dnd
        taskId={blanksArray.length}
        title="Fill in the blank"
        wrongAnswers={data?.question_options?.[0]?.options.filter(option => !data?.question_options?.[0]?.answer.includes(option))}
        switchChecked={switchChecked}
      >
        {/* {data?.question_content?.length>0 && data?.question_content.split(' ').map((ele,key)=>{
          return key%10 ? <Blank taskId={key} solution={ele} /> : ele
        })} */}
        {data?.question_content?.length>0 && blanksArray.length > 0 && blanksArray.map((ele) => {
            return ele
        })}
        {/* {blanksArray} */}
        {/* {temp} */}


        
          {/* am a `drag 'n' drop` <Blank solution="sidhu" />. This blank has
        <Blank solution={["moose", "wala", "tochan"]} /> correct answers.{" "}
        <Blank solution={["badfella", "so high"]} /> and{" "}
        <Blank solution={["never fold", "0 to 100"]} /> are both correct and the
      order doesn't matter. */}
      </Dnd> }
        
      </Flex>
  )
}

export default Fib_rb