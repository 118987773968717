import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function SampleReOrderComp() {

  return (
    <div className='bg-white border rounded h-100'>
      <div className='d-flex flex-column me-5 ms-3 reorder-sentence'>
        <p className='border border-secondary p-3 text-secondary'>A result of not being connected to the electricity grid in rural areas of some countries means people light their homes using kerosene lamps.</p>
        <div className='correct-order'><Icon name="check" variant="success" size="lg"></Icon><div className='connect-order'></div></div>
        <p className='border border-secondary p-3 text-secondary'>In addition to being fairly costly, these create smoke pollution and carbon emissions.</p>
        <div className='correct-order'><Icon name="check" variant="success" size="lg"></Icon><div className='connect-order'></div></div>
        <p className='border border-secondary p-3 text-secondary'>Therefore, alternatives are being investigated.</p>
        <div className='correct-order'><Icon name="check" variant="success" size="lg"></Icon><div className='connect-order'></div></div>
        <p className='border border-secondary p-3 text-secondary'>One such example is a solar panel that could charge an LED lamp to create hours of light each day.</p>
      </div>
    </div>
  );
}
export default SampleReOrderComp;