import React, { useContext, useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import Context from '../../context';

const ColorPicker = ({setData,data,setColorPickError,colorPickError}) => {

  let { domainData } = useContext(Context);
  domainData = JSON.parse(domainData);

  const [color, setColor] = useState(domainData?.inst_profile?.color_code);
 

  const handleColorChange = (e) => {
    const selectedColor = e.target.value;


    if (isLightColor(selectedColor)) {
      setColorPickError('Light colors are not allowed. Please select a darker color.');
      setColor(domainData?.inst_profile?.color_code); // Reset to a default color if the selected color is too light
    } else {
      setColorPickError('');
      setColor(selectedColor);

      setData((prevData) => ({
        ...prevData,
        ["inst_profile"]: {
          ...data.inst_profile,
          [e.target.name]: e.target.value,
        },
      }));
    }


   
  };


  // Function to calculate luminance and check if the color is light
  const isLightColor = (color) => {
    const rgb = hexToRgb(color);
    const luminance = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;
    return luminance > 0.8; // Adjust the threshold as needed
  };

  // Function to convert hex color to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  return (
    <Form>
      <Form.Group>
        <Form.Control className='ms-0'
          type="color"
          name="color_code"
          value={color}
          onChange={handleColorChange}
          isInvalid={!!colorPickError}
        />
        {colorPickError && <Alert className='mt-4' variant="danger">{colorPickError}</Alert>}
      </Form.Group>
 
    </Form>
  );
};

export default ColorPicker;
