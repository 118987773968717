import React from "react";
import { Row, Col, Card, Nav, Tab, Tabs, Form, Button } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { DatePicker, FileUpload, Icon } from "../../components";

function PerformanceDetail() {

  return (
    <Layout title="Analytics" content="container">
      <h1 className="mb-5">Performance Detail</h1>
      <Block>
        <Row>
          <Col md="12">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column">
                <div className="d-flex gap-3 justify-content-between align-items-start text-secondary">
                  <div className="w-10">
                    <img alt="" src="/images/practice-icons/read_alouds.svg" style={{ width: "60px" }}></img>
                  </div>
                  <div className="w-50">
                    <p>AI Study Tips</p>
                    <h5>Your Correctness level is the same as your last test. Keep going!</h5>
                  </div>
                  <div className="d-flex justify-content-between mb-5 w-40">
                    <div className="d-flex flex-column align-items-center">
                      <p className="text-middark">My correctness</p>
                      <h2>11%</h2>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p className="text-middark">Target</p>
                      <h2>62%</h2>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p className="text-middark">7d Practiced</p>
                      <h2>1</h2>
                    </div>
                  </div>
                </div>
                <div className="border rounded text-middark">
                  <div className="d-flex flex-column py-2">
                    <div className="d-flex fw-bold text-secondary border-bottom mb-2 pb-2 px-3">
                      <p>Mock Test History : RA correctness levels</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <b className="w-55"> Mock Tests</b>
                      <b className="w-15">Test Time </b>
                      <b className="w-15">Change </b>
                      <b className="w-15">RA Correctness </b>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p className="w-55">[VIP Full Test] Mock Test 30B (New 2h Format)</p>
                      <p className="w-15">2024-03-24</p>
                      <p className="w-15"><span className="border px-2 rounded">1</span></p>
                      <p className="w-15">11%</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p className="w-55">[VIP Full Test] Mock Test 30B (New 2h Format)</p>
                      <p className="w-15">2024-03-24</p>
                      <p className="w-15"><span className="border px-2 rounded">1</span></p>
                      <p className="w-15">11%</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p className="w-55">[VIP Full Test] Mock Test 30B (New 2h Format)</p>
                      <p className="w-15">2024-03-24</p>
                      <p className="w-15"><span className="border px-2 rounded">1</span></p>
                      <p className="w-15">11%</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p className="w-55">[VIP Full Test] Mock Test 30B (New 2h Format)</p>
                      <p className="w-15">2024-03-24</p>
                      <p className="w-15"><span className="border px-2 rounded">1</span></p>
                      <p className="w-15">11%</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-1 px-2">
                      <p className="w-55">[VIP Full Test] Mock Test 30B (New 2h Format)</p>
                      <p className="w-15">2024-03-24</p>
                      <p className="w-15"><span className="border px-2 rounded">1</span></p>
                      <p className="w-15">11%</p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}

export default PerformanceDetail;