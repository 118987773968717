import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AdminApi from "../../api/admin";
import AuthApi from "../../api/auth";
import DateRangePickerName from "../../components/Form/DateRangePickerName";
import { DropDown } from "../../components/Form/DropDown";
function EnquiryFilter({
  teamMemberList,
  allData,
  tableData,
  resetData,
  setData,
  showItemPerPage,
  setShowPage,
}) {
  let DropDownWidth = { width: "140px" };
  console.log("allData", allData);
  console.log(teamMemberList, "teachers11");
  const [examList, setExamList] = useState([]);
  console.log(examList, "examList");
  const examData = [
    ...new Set(
      examList
        .filter((item) => item?.name != undefined)
        .map((item) => item?.name?.toLowerCase())
    ),
  ];
  console.log(examData, "examData");
  const Student_type = [
    ...new Set(
      allData
        .filter(
          (item) => item?.users_details?.[0]?.stu_profile?.testType != undefined
        )
        .map((item) =>
          item?.users_details?.[0]?.stu_profile?.testType?.toLowerCase()
        )
    ),
  ];
  console.log(Student_type, "Student_type");
  const Status = [
    {
      value: "Done",
      id: true,
    },
    {
      value: "Pending",
      id: false,
    },
  ];
  const enquiryTypeArr = [
    "PTE",
    "PTE CORE",
    "IELTS",
    "CD IELTS",
    "TOEFL",
    "CALE",
    "CELPIP",
    "GRAMMER",
    "SPOKEN ENLGISH",
  ];
  const [filterDataObj, setFilterDatObj] = useState({});
  console.log("------------", filterDataObj);
  const handleDateChange = (e) => {
    let { value, name } = e.target;
    console.log("name##RR", value, name);
    setFilterDatObj({ ...filterDataObj, [name]: value });
  };

  let {
    startDate,
    endDate,
    starDateFollowUp,
    endDateFollowUp,
    name,
    teamMember,
    enquiryType,
    mobile,
  } = filterDataObj;
  const filterData = async () => {
    const filteredData = allData.filter((item) => {
      const itemDate = new Date(item?.createdAt);
      const endOfDay = new Date(endDate);
      const startOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);
      const itemDateFollowup = new Date(item?.nextFollowUpDate);
      const endOfDayFollowUP = new Date(endDateFollowUp);
      const startOfDayFollowUP = new Date(starDateFollowUp);
      endOfDayFollowUP.setHours(23, 59, 59, 999);
      console.log(item?.program, "item?.program ");
      console.log(
        !starDateFollowUp || itemDateFollowup >= startOfDayFollowUP,
        "22itemDate22",
        !endDateFollowUp || itemDateFollowup <= endOfDayFollowUP
      );
      console.log(teamMember, item?.teamMember, "team member111111111");
      return (
        (!startDate || itemDate >= startOfDay) &&
        (!endDate || itemDate <= endOfDay) &&
        (!starDateFollowUp || itemDateFollowup >= startOfDayFollowUP) &&
        (!endDateFollowUp || itemDateFollowup <= endOfDayFollowUP) &&
        (!mobile || item?.phone?.includes(mobile.trim())) &&
        //
        (!name ||
          (item?.name != null &&
          item?.name.toLowerCase().includes(name?.toLowerCase().trim()))) &&
        (!enquiryType ||
          (item?.program != null &&
            item?.program.toLowerCase() === enquiryType?.toLowerCase())) &&
        (!teamMember ||
          (item?.teamMember != null &&
            item?.teamMember.toLowerCase() === teamMember?.toLowerCase()))
      );
    });
    setData(filteredData);
    console.log("filteredData", filteredData);
  };
  let api = new AuthApi();
  let adminApi = new AdminApi();
  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
  }, []);
  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        if (res.data.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    get_all_exam();
  }, []);
  const get_all_exam = async () => {
    await adminApi
      .get_all_exam()
      .then((res) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="d-flex justify-content-start mb-3 flex-wrap">
        <div className="w-10 mb-3">
          Show
          <label class="form-label" style={{ visibility: "hidden" }}>
            No.
          </label>
          <Form.Group className="form-control-wrap me-2 ">
            <select
              className="form-select"
              onChange={(e) => setShowPage(e.target.value)}
              value={showItemPerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </Form.Group>
        </div>
        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Enquiry Date</Form.Label>
          <DateRangePickerName
            name
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            inputGrpname1={"startDate"}
            inputGrpname2={"endDate"}
          />
        </Form.Group>
        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Name</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="filter"
              placeholder="Search By Name"
              value={filterDataObj?.name}
              name="name"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="form-group w-25 mb-3">
          <Form.Label>Phone Number</Form.Label>
          <div className="form-control-wrap me-2">
            <Form.Control
              id="mobilefilter"
              placeholder="Search By Phone Number"
              value={filterDataObj?.mobile}
              name="mobile"
              onChange={handleDateChange}
            />
          </div>
        </Form.Group>
        <DropDown
          className="w-15 mb-3"
          data={teamMemberList}
          defaultSelectedOption={"All"}
          text="Team Member"
          name="teamMember"
          value={filterDataObj?.teamMember}
          onChange={handleDateChange}
        />
        <Form.Group className="form-group w-30 mb-3 me-2">
          <Form.Label>Filter by Next FollowUp Date</Form.Label>
          <DateRangePickerName
            startDate={starDateFollowUp}
            endDate={endDateFollowUp}
            onChange={handleDateChange}
            inputGrpname1={"starDateFollowUp"}
            inputGrpname2={"endDateFollowUp"}
          />
        </Form.Group>
        <DropDown
          className="w-25 mb-3"
          data={enquiryTypeArr}
          defaultSelectedOption={"All"}
          text="Enquiry Type"
          name="enquiryType"
          onChange={handleDateChange}
          width={DropDownWidth}
          value={filterDataObj?.enquiryType}
        />
        <Form.Group className="form-group d-flex w-20 ms-2 mb-3 align-items-end">
          <div className="form-control-wrap d-flex me-2 ">
            <Button
              className="me-2"
              onClick={(e) => {
                setFilterDatObj({
                  ...filterDataObj,
                  startDate: "",
                  endDate: "",
                  product: "",
                  batchTime: "",
                  name: "",
                  status: "",
                  testType: "",
                  teacher: "",
                  testname: "",
                  mobile: "",
                  enquiryType: "",
                  teamMember: "",
                  endDateFollowUp: "",
                  starDateFollowUp: "",
                });
                setData(allData);
              }}
              variant="secondary"
            >
              Reset
            </Button>
            <Button variant="primary" onClick={filterData}>
              Search
            </Button>
          </div>
        </Form.Group>
      </div>
    </>
  );
}
export default EnquiryFilter;
