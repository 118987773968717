import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Icon } from "../../components";
import DataTablePagination from "../../components/Pagination/DataTablePagination";

import Context from "../../context";
import ResultManagementFilter from "./ResultManagementFilter";
import exportFromJSON from "export-from-json";

import CollapsibleTable from "./CollapsibleTable";

// export file component
const Export = ({ data1 }) => {
  const fileName = "user-data";

  const myExcelData = new Array();
  data1?.map((item) => {
    const name = item?.users_details?.[0]?.name;
    const mobile = item?.users_details?.[0]?.mobile;
    const test = item?.papers?.[0]?.name;

    const batchTime = `${item?.users_details?.[0]?.stu_profile?.batchTime[0]?.starttime} to ${item?.users_details?.[0]?.stu_profile?.batchTime[0]?.endtime}`;
    const assignTeacher = item?.users_details?.[0]?.stu_profile?.assignTeacher;
    const status = item?.status ? "Done" : "Pending";

    const obj = {
      name,
      mobile,
      test,
      batchTime,
      assignTeacher,
      status,
    };

    myExcelData.push(obj);
  });

  // console.log(myExcelData, "myExcelData", data1);

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;

    exportFromJSON({
      data: myExcelData,
      fileName,
      fields: {
        name: "NAME/USERNAME",
        mobile: "MOBILE NUMBER",
        test: "EXAM NAME",
        assignTeacher: "TEACHER",
        batchTime: "BATCH",
        status: "STATUS",
      },
      exportType,
    });
  };

  return (
    <div className="export-options d-flex justify-content-end ">
      <Button variant="primary" onClick={() => exportExcel()}>
        Export
      </Button>
    </div>
  );
};

// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
));

function ResultManagementDataTable({
  pagination,
  data,
  columns,
  className,
  expandableRows,
  actions,
  tableClassName,
  selectableRows,
  isAdmin,
  instId,
  ...props
}) {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData.login_type, "userData?.login_type");
  console.log('columns :>> ', columns,tableData);

  useEffect(() => {
    setTableData(data);
  }, [data]);
  useEffect(() => {
    const filteredData = data.filter((item) =>
      item?.email?.toLowerCase().includes(searchText?.toLowerCase())
    );
    if (filteredData.length) {
      setTableData(filteredData);
    }
  }, [searchText]);

  console.log("tableData", tableData);

  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('showItemPerPage :>> ', showItemPerPage);
  return (
    <div className="data-table-wrapper">
      <ul className="d-flex justify-content-end mb-3">
        <li>
          <Button className="d-md-none" size="md" variant="primary">
            <Icon name="plus" />
            <span>Add</span>
          </Button>
        </li>
        {window.location.pathname == "/student-management/student-list" && (
          <li>
            <Link
              to="/student-management/add-student"
              className="d-none d-md-inline-flex btn btn-primary"
              variant="primary"
            >
              <Icon name="plus" />
              <span>Add Student</span>
            </Link>
          </li>
        )}
        {window.location.pathname == "/view-batch" && (
          <li>
            <Link
              to="/add-batch"
              className="d-none d-md-inline-flex btn btn-primary"
              variant="primary"
            >
              <Icon name="plus" />
              <span>Add Batch</span>
            </Link>
          </li>
        )}
        {window.location.pathname == "/view-branch" && isAdmin && (
          <li>
            <Link
              to="/add-branch"
              className="d-none d-md-inline-flex btn btn-primary"
              variant="primary"
            >
              <Icon name="plus" />
              <span>Add Branch</span>
            </Link>
          </li>
        )}
        {window.location?.pathname?.includes("/institute-detail") &&
          isAdmin && (
            <li>
              <Link
                to={`/add-instititute-branch/${instId}`}
                className="d-none d-md-inline-flex btn btn-primary"
                variant="primary"
              >
                <Icon name="plus" />
                <span>Add Branch</span>
              </Link>
            </li>
          )}
        <li></li>
      </ul>
      <Row>
        <Col lg="12">
          <div className="d-flex gap-3">
            <div className="data-table-action-wrap">
              {/* {actions && <Export data={data} />}  */}
              {window.location.pathname ==
                "/result-management/student-results" &&
                userData?.login_type == "1" && (
                  <div>
                    <Export data1={data} />
                    <ResultManagementFilter
                      allData={data}
                      tableData={tableData}
                      setData={(data) => setTableData(data)}
                      showItemPerPage={showItemPerPage}
                     
                      setShowPage={(value) => setShowItemPerPage(Number(value))}
                    />


                  </div>
                )}
            </div>
          </div>
        </Col>
      </Row>

      {/* <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination={!pagination ? false : true}
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
            />
          </div>
        )}
      /> */}
      {console.log("COLUMNS",columns)}
      {console.log("tableData",tableData)}
      <CollapsibleTable 
        columns={columns}
        data={tableData}
        showItemPerPage={showItemPerPage}
        />
    </div>
  );
}

export default ResultManagementDataTable;
