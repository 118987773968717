import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../../components/Form/DropDown";

const UpdateFollowupModal = ({
  setShow,

  singleFollowup,

  setSingleFollowup,
  setFollowupIdPayload,
  get_Single_followup,
  get_all_followup_list
}) => {
  console.log(singleFollowup, "setisFollowup");
  console.log(setShow, "setShow123");
  let api = new AuthApi();

  const [state, setState] = useState({
    data: {
      id: singleFollowup?._id ? singleFollowup?._id : "",
      remarks: singleFollowup?.remarks ? singleFollowup?.remarks : "",
      status: singleFollowup?.status ? singleFollowup?.status : "",
      nextFollowUpDate: singleFollowup?.nextFollowUpDate
        ? singleFollowup?.nextFollowUpDate
        : "",
    },
    errors: {},
  });
  

  let DropDownWidth = { width: "100%" };

  const statusType = [
    "WARM",
    "COLD",
    "HOT",
    "NO RESPONSE",
    "INTERESTED",
    "NOT INTERESTED",
    "IN PROCESS",
    "ENROLLED",
  ];
  const handleClose = () => {
    setSingleFollowup({});
    setFollowupIdPayload("");
  };
  const { data, errors } = state;
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [e.target.name]: e.target.value,
      },
    }));
  };

  console.log(data, "data11121232");
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.name);
    if (!data?.remarks) {
      newErrors.remarks = "Remarks required";
    }

    setState((prev) => ({
      ...prev,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");
      const date = new Date();
      data.nextFollowUpDate = data?.nextFollowUpDate + `T${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`
      console.log(data, "data##1");
      await api
        .add_followup(data)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            // setShow(false);
            document.getElementById("remarks").value = ""
            setState((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                remarks: "",
              },
            }));
            get_Single_followup();
            // navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };


  console.log(singleFollowup?.previous_followup?.[0]?.remarks, "errors1213");
  return (
    <>
      <Modal
        size="lg"
        show={Object.keys(singleFollowup).length > 0}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View FollowUp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="6">
                <DropDown
                  className="w-25 mb-3"
                  data={statusType}
                  defaultSelectedOption={"select"}
                  text="Select Status"
                  name="status"
                  onChange={handleChange}
                  width={DropDownWidth}
                  value={data.status}
                />

                <Form.Group className="form-group">
                  <Form.Label>Next FollowUp Date</Form.Label>
                  <div className="form-control-wrap">
                    <DatePickerOnChange
                      name={"nextFollowUpDate"}
                      onChange={handleChange}
                      value={data.nextFollowUpDate?.split(" ")?.[0]}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Remarks</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      as="textarea"
                      placeholder="Add New Remarks"
                      name="remarks"
                      id="remarks"
                      rows="3"
                      // value={data.remarks}
                      onChange={handleChange}
                      isInvalid={errors?.remarks}
                    ></Form.Control>

                    <Form.Control.Feedback type="invalid">
                      {errors.remarks}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit FollowUp
                </Button>
              </Col>
              <Col md="6" style={{ height: "450px", overflow: "auto" }}>
                <small className="text-menu-text">FollowUp History</small>

                {singleFollowup?.previous_followup?.map((item) => (
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-column bg-light px-2 py-1 rounded">
                      <p className="text-secondary">{item?.remarks}</p>
                      <small className="align-self-end text-secondary">
                        {item?.remarks_user_name}
                      </small>
                    </div>
                    <small className="align-self-end text-menu-text">
                    {item?.follow_up_date}
                    </small>
                  </div>
                ))}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateFollowupModal;
