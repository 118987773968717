import React from 'react'

const StudentHomePage = () => {
  return (
    <div>
      StudentHomePage
    </div>
  )
}

export default StudentHomePage
