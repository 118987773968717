// import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge, OverlayTrigger, Popover } from 'react-bootstrap';
// import { Icon } from '../../../../components';
// import parse from 'html-react-parser';
// import { useEffect, useRef, useState } from 'react';
// import { Colors } from "../../../../utilities/index";
// const page_title = "AI Pronunciation Trainer";
// const accuracy_colors = ["green", "orange", "red"];
// let badScoreThreshold = 30;
// let mediumScoreThreshold = 70;
// let currentSample = 0;
// let currentScore = 0.;
// let sample_difficult = 0;
// let scoreMultiplier = 1;
// let playAnswerSounds = true;
// let isNativeSelectedForPlayback = true;
// let isRecording = false;
// let serverIsInitialized = false;
// let serverWorking = true;
// let languageFound = true;
// let currentSoundRecorded = false;
// let currentText, currentIpa, real_transcripts_ipa, matched_transcripts_ipa;
// let wordCategories;
// let startTime, endTime;
// let voice_synth = null;
// let apiMainPathSTS = 'https://dev-playground.avatarintern.ai:3000' //'https://wrg7ayuv7i.execute-api.eu-central-1.amazonaws.com/Prod';// 'https://wrg7ayuv7i.execute-api.eu-central-1.amazonaws.com/Prod';

// let STScoreAPIKey = ''; //'rll5QsTiv83nti99BW6uCmvs9BDVxSB39SVFceYb'; // Public Key. If, for some reason, you would like a private one, send-me a message and we can discuss some possibilities
// let apiMainPathSample = 'https://dev-playground.avatarintern.ai:3000' //'https://a3hj0l2j2m.execute-api.eu-central-1.amazonaws.com/Prod';// 'http://127.0.0.1:3001';// 'https://a3hj0l2j2m.execute-api.eu-central-1.amazonaws.com/Prod';

// export const unblockUI = () => {
//   document.getElementById("recordAudio").classList.remove('disabled');
//   document.getElementById("playSampleAudio").classList.remove('disabled');
//   document.getElementById("buttonNext").onclick = () => getNextSample();
//   document.getElementById("nextButtonDiv").classList.remove('disabled');
//   document.getElementById("original_script").classList.remove('disabled');
//   document.getElementById("buttonNext").style["background-color"] = '#58636d';

//   if (currentSoundRecorded)
//       document.getElementById("playRecordedAudio").classList.remove('disabled');


// };
// let soundsPath = 'https://stscore-sounds-bucket.s3.eu-central-1.amazonaws.com';//'https://stscore-sounds-bucket.s3.eu-central-1.amazonaws.com';
// let soundFileGood = null;
// let soundFileOkay = null;

// const ctx = new AudioContext();
// export const cacheSoundFiles = async () => {
//   await fetch(soundsPath + '/ASR_good.wav').then(data => data.arrayBuffer()).
//       then(arrayBuffer => ctx.decodeAudioData(arrayBuffer)).
//       then(decodeAudioData => {
//           soundFileGood = decodeAudioData;
//       });

//   await fetch(soundsPath + '/ASR_okay.wav').then(data => data.arrayBuffer()).
//       then(arrayBuffer => ctx.decodeAudioData(arrayBuffer)).
//       then(decodeAudioData => {
//           soundFileOkay = decodeAudioData;
//       });

//   await fetch(soundsPath + '/ASR_bad.wav').then(data => data.arrayBuffer()).
//       then(arrayBuffer => ctx.decodeAudioData(arrayBuffer)).
//       then(decodeAudioData => {
//           soundFileBad = decodeAudioData;
//       });
// }

// export const blockUI = () => {

//   document.getElementById("recordAudio").classList.add('disabled');
//   document.getElementById("playSampleAudio").classList.add('disabled');
//   document.getElementById("buttonNext").onclick = null;
//   document.getElementById("original_script").classList.add('disabled');
//   document.getElementById("playRecordedAudio").classList.add('disabled');

//   document.getElementById("buttonNext").style["background-color"] = '#adadad';


// };

// export const UIError = () => {
//   blockUI();
//   document.getElementById("buttonNext").onclick = () => getNextSample(); //If error, user can only try to get a new sample
//   document.getElementById("buttonNext").style["background-color"] = '#58636d';

//   document.getElementById("recorded_ipa_script").innerHTML = "";
//   document.getElementById("single_word_ipa_pair").innerHTML = "Error";
//   document.getElementById("ipa_script").innerHTML = "Error"

//   document.getElementById("main_title").innerHTML = 'Server Error';
//   document.getElementById("original_script").innerHTML = 'Server error. Either the daily quota of the server is over or there was some internal error. You can try to generate a new sample in a few seconds. If the error persist, try comming back tomorrow or download the local version from Github :)';
// };
// // API related variables 
// let AILanguage = "en"; // Standard is German

// let soundFileBad = null;


// export const initializeServer = async () => {

//   var valid_response = false;
//    document.getElementById("main_title").innerHTML = 'Initializing server, this may take up to 2 minutes...';
//    let number_of_tries = 0;
//    let maximum_number_of_tries = 4;

//    while (!valid_response) {
//        if (number_of_tries > maximum_number_of_tries) {
//            serverWorking = false;
//            break;
//        }

//        try {
//            await fetch(apiMainPathSTS + '/GetAccuracyFromRecordedAudio', {
//                method: "post",
//                body: JSON.stringify({ "title": '', "base64Audio": '', "language": AILanguage }),
//                headers: { "X-Api-Key": STScoreAPIKey }

//            }).then(
//                valid_response = true);
//            serverIsInitialized = true;
//        }
//        catch
//        {
//            number_of_tries += 1;
//        }
//    }
// }
// function updateScore(currentPronunciationScore) {

//   if (isNaN(currentPronunciationScore))
//       return;
//   currentScore += currentPronunciationScore * scoreMultiplier;
//   currentScore = Math.round(currentScore);
// }
// export const getNextSample = async () => {



//   blockUI();

//   if (!serverIsInitialized)
//       await initializeServer();

//   if (!serverWorking) {
//       UIError();
//       return;
//   }

//   if (soundFileBad == null)
//       cacheSoundFiles();



//   updateScore(parseFloat(document.getElementById("pronunciation_accuracy").innerHTML));

//   document.getElementById("main_title").innerHTML = "Processing new sample...";


//   if (document.getElementById('lengthCat1').checked) {
//       sample_difficult = 0;
//       scoreMultiplier = 1.3;
//   }
//   else if (document.getElementById('lengthCat2').checked) {
//       sample_difficult = 1;
//       scoreMultiplier = 1;
//   }
//   else if (document.getElementById('lengthCat3').checked) {
//       sample_difficult = 2;
//       scoreMultiplier = 1.3;
//   }
//   else if (document.getElementById('lengthCat4').checked) {
//       sample_difficult = 3;
//       scoreMultiplier = 1.6;
//   }

//   try {
//       await fetch(apiMainPathSample + '/getSample', {
//           method: "post",
//           body: JSON.stringify({
//               "category": sample_difficult.toString(), "language": AILanguage
//           }),
//           headers: { "X-Api-Key": STScoreAPIKey }
//       }).then(res => res.json()).
//           then(data => {

//   //console.log(res);
//               let doc = document.getElementById("original_script");
//               currentText = data.real_transcript;
//               doc.innerHTML = currentText;
//               currentIpa = data.ipa_transcript

//               let doc_ipa = document.getElementById("ipa_script");
//               doc_ipa.innerHTML = "/ " + currentIpa + " /";
//               document.getElementById("recorded_ipa_script").innerHTML = ""
//               document.getElementById("pronunciation_accuracy").innerHTML = "";
//               // document.getElementById("single_word_ipa_pair").innerHTML = "Reference | Spoken"
//               document.getElementById("section_accuracy").innerHTML = "| Score: " + currentScore.toString() + " - (" + currentSample.toString() + ")";
//               currentSample += 1;

//               document.getElementById("main_title").innerHTML = page_title;
//   console.log(data.transcript_translation);
//               //document.getElementById("translated_script").innerHTML = data.transcript_translation;

//               currentSoundRecorded = false;
//               unblockUI();
//               document.getElementById("playRecordedAudio").classList.add('disabled');

//           })
//   }
//   catch
//   {
//       UIError();
//   }


// };


// export const UINotSupported = () => {
//   unblockUI();

//   document.getElementById("main_title").innerHTML = "Browser unsupported";

// }

// export const playWithMozillaApi = (text) => {
//   var synth = window.speechSynthesis;
//   if (languageFound) {
//       // blockUI();
//       if (voice_synth == null)
//           // changeLanguage(AILanguage);

//       var utterThis = new SpeechSynthesisUtterance(text);
//       utterThis.voice = voice_synth;
//       utterThis.rate = 0.7;
//       // utterThis.onend = function (event) {
//       //     unblockUI();
//       // }
//       synth.speak(utterThis);
//   }
//   else {
//       UINotSupported();
//   }
// }
// function ResultYourAnswerReadAloud({api_responseAll,audioUrl}) {
//   const [trying, setTrying] = useState([]);
//   const [state, setState] = useState({
//     activeKey: "all",
//     shareModal: false,
//     answer_data: [],
//     current_qst_data: {},
//     loader_modal: false,
//     currentText: "",
//     clicked_value_obj: {
//       original_word: "",
//       real_transcript: "",
//       user_transcript: "",
//       audioUrl: "",
//     },
//     real_transcripts_ipa: "",
//     matched_transcripts_ipa: "",
//     pair_accuracy_category: "",
//     api_res: {},
//     swtsmodal: false,
//     fibwrModal: false,
//     wfdsModal: false,
//     AI_modal: false,
//     answerGivenByUser: {},
//     answer_id: "",
//     api_response: {
//       content_score: 2,
//       form_score: 0,
//       grammar_score: 0,
//       vocabulary_score: 0,
//       total_score: 2,
//       max_score: 7,
//       actual_score: 27,
//       grammar: [
//         {
//           index: 10,
//           message: "Add a space between sentences.",
//           replacements: [" In"],
//         },
//         {
//           index: 15,
//           message: "Possible spelling mistake found.",
//           replacements: ["Canfield"],
//         },
//       ],
//     },
//   });
//   let {
//     shareModal,
//     activeKey,
//     answer_data,
//     loader_modal,
//     currentText,
//     current_qst_data,
//     real_transcripts_ipa,
//     pair_accuracy_category,
//     lettersOfWordAreCorrect,
//     matched_transcripts_ipa,
//     clicked_value_obj,
//     api_res,
//     answerGivenByUser,
//     answer_id,
//     swtsmodal,
//     fibwrModal,
//     wfdsModal,
//     AI_modal,
//     api_response,
//   } = state;
//   const[noShowOverlayTrigger,setnoShowOverlayTrigger] = useState(false)

//   const audioRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const start_time = Number(clicked_value_obj.start_time);
//   const end_time = Number(clicked_value_obj.end_time);

//   const handlePlay = () => {
//     if (audioRef.current) {
//       const audioElement = audioRef.current;
//       audioElement.currentTime = start_time;
//       audioElement.play();
//       setIsPlaying(true);
//     }
//   };

//   const handlePause = () => {
//     if (audioRef.current) {
//       const audioElement = audioRef.current;
//       audioElement.pause();
//       setIsPlaying(false);
//     }
//   };

//   const handleTimeUpdate = () => {
//     if (audioRef.current) {
//       const currentTime = audioRef.current.currentTime;

//       if (currentTime >= end_time) {
//         handlePause();
//       }
//     }
//   };

//   useEffect(() => {
//     if (audioRef.current) {
//       audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
//     }

//     return () => {
//       if (audioRef.current) {
//         audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
//       }
//     };
//   }, [clicked_value_obj]);


//   const handleClick = (event) => {

//     var id = event.target.parentElement.id;
//     var rnd = event.target.getAttribute("color");;
//     console.log("IDDD",id);
//     console.log("TRYINGID", trying[id]);
//     console.log("rnd",rnd);
//     console.log("IPHONE",matched_transcripts_ipa[id]);
//     if(id !== "text-area"){
//       setnoShowOverlayTrigger(true)
//         setState({
//           ...state,
//           clicked_value_obj: {
//             ...clicked_value_obj,
//             start_time: api_res?.start_time[id],
//             end_time: api_res?.end_time[id],
//             msg:
//               (rnd?.includes("red") && "Bad Pronunciation") || 
//               (rnd?.includes("green") && "Good Pronunciation") ||
//               (rnd?.includes("orange") && "Average pronunciation. Try to improve it") ||
//               ("Pause Detected."),
//               // (lettersOfWordAreCorrect[id] == "0" && "Good pronunciation") ||
//               // (lettersOfWordAreCorrect[id] == "1" &&
//               //   "Average pronunciation. Try to improve it") ||
//               // (lettersOfWordAreCorrect[id] == "2" && "Bad pronunciation.")||
//               // (lettersOfWordAreCorrect[id] == " " && "Pause Detected."),
//             user_transcript: matched_transcripts_ipa[id],
//             real_transcript: real_transcripts_ipa[id],
//             original_word: current_qst_data?.question_content?.split(" ")[id],
//           },
//         });
//     }
//     else{
//        setnoShowOverlayTrigger(false)
//     }
//   };

//   function setColorToTranscripts(original_transcript, user_transcript){
//     let ele = "";
//     for(var i=0;i<Math.min(original_transcript?.length,user_transcript?.length);i++){
//       if(original_transcript[i] == user_transcript[i]){
//         ele+=`<span><font color="green">${original_transcript[i]}</font></span>`
//       }
//       else{
//         ele+=`<span><font color="red">${user_transcript[i]}</font></span>`
//       }
//     }
//     console.log("ELECOLOR",ele)
//     return ele
//     console.log("ELECOLOR",ele)
//   }

//   const [activeProject, setactiveProject] = useState({
//     size: 110,
//     value: 0,
//     angleOffset: 0.4,
//     angleStart: 1,
//     angleEnd: 1,
//     colorFg: Colors.blue,
//     trackWidth: "0.15",
//   });

//   const coloredText = async (data, audioUrl) => {
//     console.log('coloredText :>> ', data);
//     // alert("*")
//     console.log('data?.real_transcripts :>> ', data?.real_transcripts);
//     setactiveProject({
//       ...activeProject,
//       value: Number(data.total_score),
//     });
//     var lettersOfWordAreCorrect = data?.is_letter_correct_all_words?.split(" ");
//     var startTime = data.start_time;
//     var endTime = data.end_time;
//     var real_transcripts_ipa = data?.real_transcripts_ipa?.split(" ");
//     var matched_transcripts_ipa = data.matched_transcripts_ipa?.split(" ");
//     var wordCategories = data.pair_accuracy_category?.split(" ");
//     setState({
//       ...state,
//       real_transcripts_ipa,
//       matched_transcripts_ipa,
//       pair_accuracy_category: wordCategories,
//       lettersOfWordAreCorrect:lettersOfWordAreCorrect,
//       api_res: { ...data },
//       clicked_value_obj: {
//         ...clicked_value_obj,
//         audioUrl: audioUrl,
//       },
//     });
//     let currentTextWords = data?.real_transcripts?.split(" ");
//     let pause = data?.pause;
    
//     var coloredWords = "";
//     var original_script = "";
//     console.log("currentTextWords",currentTextWords);
//     for (let word_idx = 0; word_idx < currentTextWords?.length; word_idx++) {
//         var wordTemp = "";
//         var color_letter = "black";

//         var color_letter = "";

//       if(matched_transcripts_ipa[word_idx] == "-"){
//         console.log("INSIDE");
//         color_letter = "red";

//       }

//       else{
//         if(lettersOfWordAreCorrect[word_idx] == "0"){
//           color_letter = "green"
//         }else if(lettersOfWordAreCorrect[word_idx] == "1"){
//           color_letter = "orange"
//         }else{
//           color_letter = "red";
//         }

//       }

//         // if (letter_is_correct) color_letter = "green";

//         // else color_letter = "red";

//         wordTemp +=
//           "<font color=" +
//           color_letter +
//           ">" +
//           currentTextWords[word_idx] +
//           "</font>";
//       // for (
//       //   let letter_idx = 0;
//       //   letter_idx < currentTextWords[word_idx].length;
//       //   letter_idx++
//       // ) {
        
//       // }

//       // currentTextWords[word_idx];
//       console.log("currentTextWords",currentTextWords);
//       console.log("wordTemp",wordTemp);
//       wordTemp = "<span id=" + word_idx + ">" + wordTemp + "</span>  ";

//       original_script +=  wordTemp;
//       if(pause[word_idx]?.includes('/')){

//         original_script += `<span id='${word_idx}'><font color=black>` +  pause[word_idx] + "</font></span>  ";

//       }
  

// /*
      
//       coloredWords +=
//         " " +
//         wrapWordForIndividualPlayback(
//           matched_transcripts_ipa,
//           real_transcripts_ipa,
//           wordTemp,
//           word_idx
//         );*/

//     }

//     console.log("original_script",original_script);
//     let newTrying = [];
   
//     newTrying = original_script.split("  ");
//     // newTrying.splice(0,1)
//     setTrying(newTrying)
//     // var originalScriptElement = document.getElementById("original_script");
//     // if (originalScriptElement) {
//     //   originalScriptElement.innerHTML = original_script;
//     // } else {
//     //   console.error("Element with ID 'original_script' not found");
//     // }
//   };

// useEffect(()=>{
//   coloredText(api_responseAll, audioUrl)
// },[])

// console.log('trying :>> ', trying);
//   return (
//     <>
//      <div>
//                     {loader_modal && (
//                       <div>
//                         <div className="ml-loader">
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                           <div></div>
//                         </div>
//                       </div>
//                     )}
//                     <div
//                       className="container-small flex expand"
//                       style={{
//                         alignItems: "center",
//                         textAlign: "center",
//                         verticalAlign: "middle",
//                       }}
//                     >
//                       {/* <p
//                         id="single_word_ipa_pair"
//                         className="expand ipa-text-small"
//                         style={{ textAlign: "center" }}
//                       ></p> */}
//                     </div>

//                     <img src="/images/ai_accuracy.svg" alt="Accuracy Stats" className="mb-2" />

//                     {/* TRYING TO OPEN FOR EVERY WORD */}
//                     {console.log("TRYING",trying)}
//                     {console.log("clickedVal",clicked_value_obj)}
//                     <div id="text-area" className="main-text-div">
//                       {trying.length > 0 && trying.map((ele, key) => {
                      
//                         return <> <OverlayTrigger
//                             trigger="click"
//                             placement="top"
//                             rootClose 
//                             overlay={
//                               <Popover id="popover-positioned-top" >
//                              { noShowOverlayTrigger &&  <Popover.Header as="h3">
//                                   Hilingo Alert
//                                 </Popover.Header>}
//                                 <Popover.Body>
//                                     {noShowOverlayTrigger &&(
//                                       <>
//                                       <p>{clicked_value_obj.msg}</p>
//                                   <p>{clicked_value_obj.msg?.includes("Pause Detected") ? "/" : parse(ele)}</p>
                                  
//                                   {/* not to display audio icon if pause is Detected in transcript */}
//                                   {!clicked_value_obj?.msg?.includes("Pause Detected.") &&
//                                     <>
//                                       <p>
//                                       [Yours]  {parse(setColorToTranscripts(clicked_value_obj?.real_transcript, clicked_value_obj?.user_transcript))}
//                                       {/* [Yours] {clicked_value_obj?.user_transcript} */}
//                                     </p>
//                                     <p>
//                                       [Org] / {clicked_value_obj?.real_transcript} /{" "}
//                                       <img
//                                         src="/images/headphone.svg"
//                                         style={{ width: "20px", marginLeft: "5px" }}
//                                         onClick={() => playWithMozillaApi(clicked_value_obj?.original_word)}
//                                       ></img>
//                                        </p>
//                                     <audio
//                                       ref={audioRef}
//                                       src={clicked_value_obj?.audioUrl}
                                      
//                                     />
//                                     </>
//                                   }
                                  
//                                       </>
//                                     )} 
                                  
//                                   {/* <p>[US]/ˈwɔtɚ, ˈwɑtɚ/ <img src="/images/headphone.svg" style={{width:"20px",marginLeft:"5px"}}></img></p> */}
//                                 </Popover.Body>
//                               </Popover>
//                             }
//                           >
//                             <p
//                               style={{ cursor: "pointer", display:"inline", fontFamily:"Roboto" }}
//                               id="original_script"
//                               onClick={handleClick}
//                               className=" bigger-text text-primary main-text "
                              
//                             >
//                               {parse(ele)}
//                             </p>
    
                            
//                           </OverlayTrigger>
//                           </>
//                     })}
//                     </div>
//                   </div>
//       <hr></hr>
//     </>
//   );
// }
// export default ResultYourAnswerReadAloud;


import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
import { useState } from 'react';
import PracticePteCommentsNew from '../../../practice-test/PTE/PracticePteCommentsNew';
function ResultYourAnswerReadAloud({api_responseAll,audioUrl, moduleName}) {



  const [scoreModal, setScoreModal] = useState(false);


  return (
    <>
      <div className='p-3 text-middark d-flex flex-column align-items-start gap-3'>
       

        <div className='d-flex gap-3'>
          {
            scoreModal &&
            <PracticePteCommentsNew moduleName={moduleName} scoreModal={scoreModal}setScoreModal={setScoreModal}api_responseAll={api_responseAll}audioUrl={audioUrl} />
          }

          {/* for Read aloud */}
          <Button variant='outline-assigned' size='sm'>RA S-ASIA V3i</Button>

          <Button variant='outline-primary' size='sm' onClick={()=>setScoreModal(true)}>Score Info 10/90</Button></div>

      </div>
      <hr></hr>
    </>
  );
}
export default ResultYourAnswerReadAloud;