import * as React from "react";
import {
  Card,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";
import { Colors } from "../../../utilities/index";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import Context from "../../../context";
import { useEffect, useState } from "react";
import AdminApi from "../../../api/admin";
import Navbar from "./Navbar";
import AnswerTab from "./Tabs/AnswerTab";
import ResultTab from "./Tabs/ResultTab";
import AnalyticTab from "./Tabs/AnalyticTab";
import ExamResultTab from "./Tabs/ExamResultTab";
import { MediaGroup, MediaText } from "../../../components";
import StudentResultContext from "../../../context/StudentResultContext/StudentResultContext";

export const StudentColumns = [
  {
    name: "SR No",
    selector: (row, i) => i,
    cell: (row, i) => <span className="text-middark">{i}</span>,
    sortable: true,
  },
  {
    name: "Section",
    selector: (row) => row.section,
    cell: (row) => <span className="text-middark">{row.section}</span>,
    sortable: true,
  },
  {
    name: "Item",
    selector: (row) => row.item,
    cell: (row) => <span className="text-middark">{row.item}</span>,
    sortable: true,
  },
  {
    name: "Contribute",
    selector: (row) => row.contribute,
    cell: (row) => <span className="text-middark">{row.contribute_score}</span>,
    sortable: true,
  },
  {
    name: "Contribute Score",
    grow: "3",
    selector: (row) => row.contribute,
    cell: (row) => <span className="text-middark">{row.contribute}</span>,
    sortable: true,
  },
  {
    name: "My Correctness",
    grow: "3",
    selector: (row) => row?.my_correctness,
    cell: (row) => <span className="text-middark">{row?.my_correctness}</span>,
    sortable: true,
  },
  {
    name: "Target",
    grow: "3",
    selector: (row) => row.target,
    cell: (row) => <span className="text-middark">{row.target}</span>,
    sortable: true,
  },
  {
    name: "status",
    selector: (row) => row.status,
    cell: (row, index, column) => {
      if (column === StudentColumns.length - 1) { // Check if it's the last column
        return <div>Yes</div>; // Print Yes for the last column
      } else {
        return (
          <span
            className={`badge text-bg-${row.status === "Good"
              ? "success"
              : row.status === "Bad"
                ? "danger"
                : row.status === "Average"
                  ? "warning"
                  : "primary"
              }`}
          >
            {row.status ? row.status : ""}
          </span>
        );
      }
    },
    sortable: true,
  },
];


function StudentResult() {
  let { paperId, answerId } = useParams();

  console.log("IDID",answerId)

  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  let api = new AdminApi();
  var user_id = userData?._id;

  const [state, setState] = useState({ result_data: {} });
  let { result_data } = state;
  var speaking_data = result_data?.speaking_score
    ? result_data?.speaking_score
    : [];
  var reading_data = result_data?.speaking_score
    ? result_data?.reading_score
    : [];
  var writing_data = result_data?.writing_score
    ? result_data?.writing_score
    : [];
  var listening_data = result_data?.listening_score
    ? result_data?.listening_score
    : [];
  useEffect(() => {
    if (paperId) {
      get_student_result();
    }
    if(answerId){
      get_student_result_analytics();
    }
  }, [paperId,answerId]);

  const get_student_result = async () => {
    await api
      .get_student_result({ exam_id: paperId, userID: user_id })
      .then((res) => {
        if (res.data.length > 0) {
          setState({ ...state, result_data: res.data[0] });
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

     const {setStudentResult} =useContext(StudentResultContext)
  const get_student_result_analytics = async () => {
    await api
      .get_student_result_analytics({ id: answerId })
      .then((res) => {
        console.log("res232134",res);
        if (Object.keys(res?.data)?.length > 0) {
          setStudentResult(res?.data)

          
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  

  console.log("result_data", result_data);
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Result Analytics</h1>

      <Tab.Container id="pills-tabs-example" defaultActiveKey="result">
        <Nav
          variant="pills"
          className="mb-5 justify-content-end gap-2"
          style={{ marginTop: "-50px" }}
        >
          <Nav.Item>
            <Nav.Link eventKey="result">Result</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="answer">Answer</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="analytics">Analytics</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          
              <Tab.Pane eventKey="result">
                <ExamResultTab Col={Col}
                  Card={Card}
                  Row={Row}
                  Tab={Tab}
                  Block={Block}
                  Navbar={Navbar}
                  speaking_data={speaking_data}
                  reading_data={reading_data}
                  writing_data={writing_data}
                  listening_data={listening_data}
                  overall_result={result_data?.overall_result}
                  data={result_data}
                  userData={userData}
                  exam={result_data?.exam_id}></ExamResultTab>
                {/* <ResultTab
              Col={Col}
              Card={Card}
              Row={Row}
              Tab={Tab}
              Block={Block}
              Navbar={Navbar}
              speaking_data={speaking_data}
              reading_data={reading_data}
              writing_data={writing_data}
              listening_data={listening_data}
              overall_result={result_data?.overall_result}
              exam={result_data?.exam_id}
            /> */}

              </Tab.Pane>
              <Tab.Pane eventKey="answer">
                <AnswerTab
                  Col={Col}
                  Card={Card}
                  Row={Row}
                  Tab={Tab}
                  Block={Block}
                  speaking_data={speaking_data}
                  reading_data={reading_data}
                  writing_data={writing_data}
                  listening_data={listening_data}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="analytics">
                <AnalyticTab
                  Colors={Colors}
                  StudentColumns={StudentColumns}
                  Col={Col}
                  Card={Card}
                  Row={Row}
                  Tab={Tab}
                  Block={Block}
                  speaking_data={speaking_data}
                  reading_data={reading_data}
                  writing_data={writing_data}
                  listening_data={listening_data}
                />
              </Tab.Pane>
           
        </Tab.Content>
      </Tab.Container>

    </Layout>
  );
}
export default StudentResult;
