import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Layout from '../../layout/fullpage';
import ImageSlider from "../ImageSlider";
import { Media, MediaGroup, Image, Logo } from '../../components';
import AuthApi from '../../api/auth';
import Swal from 'sweetalert2';
import Context from '../../context';

function StudentPassResetPage() {

    const [email, setEmail] = useState("")

    const api = new AuthApi()

    let {domainData } = useContext(Context);
    domainData = JSON.parse(domainData);


    async function handleResetPassword(){
        api.forget_password_through_email({email:email}).then((res) => {
            setEmail("")
            if(res?.status){
                Swal.fire({
                    icon: "warning",
                    title: "Good job!",
                    text: res?.msg,
                  });
                
            }
            else{
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res?.msg,
                });
            }
        })
    }


    useEffect(() => {
        if (domainData?.inst_profile?.color_code) {
            document.documentElement.style.setProperty('--theme-color', domainData?.inst_profile?.color_code);
          }
          else{
            document.documentElement.style.setProperty('--theme-color',"#1682FB");
          }

    },[])


    const [sliderImages, setSliderImages] = useState([
      {
         url: "/images/login-tree.svg",
      },
      {
         url: "/images/login-tree.svg",
      },
      {
         url: "/images/login-tree.svg",
      },
   ]);
   
    return (
        <Layout title="Forgot Password" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                    <Row className="g-0 flex-lg-row-reverse">
                        <Col lg="5">
                            <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                <div className="nk-block-head text-center">
                                    <div className="nk-block-head-content">
                                        <div className="login-logo">
                                            <Logo />
                                        </div>
                                        <h1 className="nk-block-title mb-2">Forgot your password?</h1>
                                        {/* <p className="small col-md-10 mx-auto">If you forgot your password, don't worry! we’ll email you instructions to reset your password.</p> */}
                                    </div>
                                </div>
                                <Form action="#">
                                    <Row className="gy-3">
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} id="email" placeholder="Email Id" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-8 mx-auto mt-5">
                                            <div className="d-grid">
                                                <Button onClick={() => handleResetPassword()}>Reset Password</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="text-center mt-4">
                                    <p className="small text-themetext">Back to<Link to="/auths/auth-login"> Login</Link></p>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col lg="7">
                <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                <Carousel
            bgColor="#00000000"
            width={500}
            height={200}
            showBullets={true}
            autoPlay={true}
            images={sliderImages}
            autoPlayDelay={3000} 
            onStartSlide={(index, length) => {
               // You can use index to track the current slide
            }}
           // Auto play delay in milliseconds
         >
            {sliderImages.map((item, index) => (
               <Carousel.Item key={index}>
                  <img className="d-block slider-img" style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}} src={item.url} alt={`Slide ${index}`} />
               </Carousel.Item>
            ))}
         </Carousel>
                </Card.Body>
              </Col>
                    </Row>
                </Card>
            </div>
        </Layout>
    )
}

export default StudentPassResetPage;
