import React from "react";
import { Row, Col, Card, Nav, Tab, Tabs, Form, Button } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { DatePicker, FileUpload, Icon } from "../../components";

function ExamHistory() {

  return (
    <Layout title="Analytics" content="container">
      <h1 className="mb-5">Exam History</h1>
      <Block>
        <Card className="h-100">
          <Card.Body>
            <Row>
              <Col md="6">
                <div className="d-flex flex-column gap-4">
                  <Card className="mb-3">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-end">
                        <img src="/images/ai-icon.svg" style={{ width: "80px" }}></img>
                        <div className="d-flex flex-column gap-2">
                          <h5>AI Score Report Analysis</h5>
                          <p className="text-middark text-center">Need to work on : Speaking RS, DI, RA, RL Reading FIB-R, FIB-RW</p>
                        </div>
                        <p className="d-flex justify-content-end text-middark mt-3">
                          <button className="btn btn-color-secondary btn-hover-secondary btn-icon">
                            <Icon name="chevron-right"></Icon>
                          </button>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                  <div>
                    <h5 className="mb-4">Exam Category</h5>
                    <Button variant="primary">Real Exam</Button>
                  </div>
                  <div>
                    <h5>Score Report</h5>
                    <button className="btn btn-color-primary btn-hover-primary btn-icon">
                      PDF
                    </button>
                  </div>
                  <div>
                    <h5>Exam Date</h5>
                    <div className="bg-light text-middark py-2 px-5 rounded-pill">2024-03-21</div>
                  </div>
                  <div>
                    <h5>Scores</h5>
                    <div className="d-flex justify-content-between mb-5">
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Overall</p>
                        <h2 className="text-hidanger">59</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Listening</p>
                        <h2>62</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Reading</p>
                        <h2>55</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Speaking</p>
                        <h2>55</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Writing</p>
                        <h2>55</h2>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Grammar</p>
                        <h2>59</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Fluency</p>
                        <h2>62</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Pronun</p>
                        <h2>55</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Spelling</p>
                        <h2>55</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Vocab</p>
                        <h2>55</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Discourse</p>
                        <h2>55</h2>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <Button variant="danger">Delete</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default ExamHistory;