import { Card, Form, Button } from 'react-bootstrap';
import ExamHeader from '../../../../layout/exam/Header';
import ExamFooter from '../../../../layout/exam/Footer';
import Tinymce from '../../../../components/Form/editors/Tinymce';
import PracticeTextArea from '../../../practice-test/PTE/PracticeComponents/PracticeTextArea';

function SummarizeWrittenText({ data }) {
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <br />
        <p>
          {data.question_content}
        </p>
        <br />
        <br />
        {/* <Form.Control as="textarea" placeholder="Textarea Placeholder" id="exampleFormControlTextarea8" rows="8"></Form.Control>
        <div className='d-flex justify-content-between'>
          <Button className="btn-soft" variant="outline-light">Cut</Button>
          <Button className="btn-soft" variant="outline-light">Copy</Button>
          <Button className="btn-soft" variant="outline-light">Paste</Button>
        </div> */}
        <PracticeTextArea/>
        <br />
        <br />
      </div>
    </>
  )
}
export default SummarizeWrittenText;