import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";
import exportFromJSON from "export-from-json";

import dateUtils from "../../utilities/dateUtils";





function formatDate(inputDateString, format) {
  const inputDate = new Date(inputDateString);

  const pad = (num) => (num < 10 ? `0${num}` : num);

  const formattedDate = format

    .replace("DD", pad(inputDate.getDate()))
    .replace("MM", pad(inputDate.getMonth() + 1))

    .replace("YYYY", inputDate.getFullYear());
  return formattedDate;
}
const Export = ({ data1 }) => {
  const fileName = "user-data";
  const myExcelData = new Array();
  data1?.map((item,i) => {
    // const enquiryDate = new Date(item?.createdAt).toISOString().split("T")[0];
    const srNO=i + 1;
    const date = formatDate(item.updatedAt, "DD-MM-YYYY ")
    const invoice = item?.payment_id
    const status = item?.order_status 
    const amount = item?.amount
   
    const obj = {
      srNO,
      date,
      invoice,
      status,
      amount,
    };
    myExcelData.push(obj);
  });
  // console.log(myExcelData, "myExcelData", data1);
  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
      data: myExcelData,
      fileName,
      fields: {
        srNO:'SR NO',
        date: "DATE",
        invoice: "INVOICE NO.",
        status: "STATUS",
        amount: "AMOUNT",
       
      },
      exportType,
    });
  };
  return (
    <div className="export-options d-flex align-items-center me-2">
      <Button variant="primary" onClick={() => exportExcel()}>
        Export
      </Button>
    </div>
  );
};

function InvoiceList() {
  let api = new AuthApi();
  let { userData } = useContext(Context);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    razorpay_invoice();
  }, []);

  const razorpay_invoice = async () => {
    await api
      .razorpay_invoice(JSON.parse(userData)?._id)
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("data", data);



  const handleDownloadClick = (invoiceId) => {

    if(invoiceId){

      // const downloadUrl = `http://127.0.0.1:8000/api/auth/download_invoice/${invoiceId}`;
      const downloadUrl = `https://api.hilingo.com/api/auth/download_invoice/${invoiceId}`;
      
      // Open the download URL in a new tab directly
      window.open(downloadUrl, '_blank');
    }
  };
  

  


  
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Invoice List</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <div className="d-flex ">
                <h5>Invoice List</h5>
                {/* <StudentDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  columns={StudentColumns}
                /> */}

                {/* You can use data list component */}

                <Form.Group style={{width: "85%"}} className="form-groupme-5 mb-2  d-flex justify-content-end">
              
                   
                  <div className="form-control-wrap">
                  <Export data1={data} />
                    </div>
                    
                  </Form.Group>

</div>
                <Table responsive striped bordered hover className="small">
                  <thead>
                    <tr className="bg-primary">
                      <th className="text-white">DATE</th>
                      <th className="text-white">INVOICE NO.</th>
                      <th className="text-white">Status</th>
                      <th className="text-white">Amount</th>
                      <th className="text-white">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length &&
                      data.map((item) => {
                        return (
                          <>
                            {" "}
                            <tr className="py-6">
                              <td>
                                {formatDate(item.updatedAt, "DD-MM-YYYY ")}
                              </td>
                              <td>{item.payment_id}</td>
                              <td>{item.order_status}</td>
                              <td>{item.amount}</td>
                              <td>
                              <Button className="border" size="sm" onClick={(e)=>handleDownloadClick(item?.invoice_response?.id)}>Download</Button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default InvoiceList;
