import React, { useContext, useState } from 'react';
import { ProgressBar, Spinner, Button } from 'react-bootstrap';
import ExamHeader from '../../../../layout/exam/Header';
import ExamFooter from '../../../../layout/exam/Footer';
import { Icon } from '../../../../components';
import NoUiSlider from '../../../../components/Form/NoUiSlider';
import Tinymce from '../../../../components/Form/editors/Tinymce';
import Timer from '../Timer';
import Audio from '../customAudio/audio';
import ModalContext from '../../../../context/ModalContext';
import OnlyRecorderUI from '../Istructions/ExamComponents/OnlyRecorderUI';
import PracticeTextArea from '../../../practice-test/PTE/PracticeComponents/PracticeTextArea';
import AudioUI from '../Istructions/ExamComponents/AudioUI';
function ListeningDictation({ data }) {
  let { popUpType, setPopUpType, setAnswerJson, answerJson } = useContext(ModalContext);
  const [selectedWords, setSelectedWords] = useState([]);
  const [startAudio, setStartAudio] = useState(false);
  const paragraphText = data?.question_content;
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <div className="d-flex justify-content-center align-items-center gap-4 my-5">
        <div className="d-flex flex-column gap-5 justify-content-start w-50 mb-5">
        <AudioUI
                timer={data.question_begin_time}
                audio={data.question_content_audio}
                startRecording={() => {
                  // setStartAudio(true);
                  // startRec();
                }}
              />
          </div>
        </div>
        <br />
        <br />
        <PracticeTextArea/>
        <br />
        <br />
      </div>
    </>
  )
}
export default ListeningDictation;