import React from 'react';

const BranchSvg = ({ fill}) => {
  console.log("fill", fill);
  return (
    
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
    
      viewBox='0 0 32 32'
    >
      <g fill={fill}>
        <path
          d='M11 21H3a1 1 0 01-1-1v-8a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 01-1 1zm-7-2h6v-6H4zm25 9h-8a1 1 0 01-1-1v-8a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 01-1 1zm-7-2h6v-6h-6zm7-14h-8a1 1 0 01-1-1V3a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 01-1 1zm-7-2h6V4h-6zm-6 22a1 1 0 01-1-1V1a1 1 0 112 0v30a1 1 0 01-1 1z'
          data-original='#000000'
        ></path>
        <path
          d='M16 17h-5a1 1 0 110-2h5a1 1 0 110 2zm5-9h-5a1 1 0 110-2h5a1 1 0 110 2zm0 16h-5a1 1 0 110-2h5a1 1 0 110 2z'
          data-original='#000000'
        ></path>
      </g>
    </svg>
  );
}

export default BranchSvg;
