import React, { useState } from 'react'
import OverrideContext from './OverrideContext'

const OverrideContextProvider = ({children}) => {
    const [overrideData,setOverrideData]=useState()
  return (
    <OverrideContext.Provider value={{overrideData,setOverrideData}}>
      {children}
    </OverrideContext.Provider>
  )
}

export default OverrideContextProvider
