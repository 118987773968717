import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";

function PracticeParagraph({selected_ques_Obj}) {

    return (
        <>
            <div className="practice-textarea d-flex align-items-end justify-content-between gap-2">
                <p className="practice-pte-question-newline practice-text-area">{selected_ques_Obj?.question_content}
                  </p>
                <button className="btn btn-color-assigned btn-icon">
                    <Icon name="vol"></Icon>
                </button>
            </div>
        </>
    )
}

export default PracticeParagraph;