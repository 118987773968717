const { default: BaseApi } = require("../../baseApi");

class ResultManagementApi extends BaseApi {
async get_students_results_by_inst(data) {
    return await this.API({
      url: `auth/get_students_results_by_inst`,
      data: data,
      method: "post",
    });
  }
  
}
export default ResultManagementApi