import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Nav,
  ProgressBar,
  Badge,
} from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import PracticePteComments from "../PracticePteComments";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";

function PracticePteListeningHIWS({ showAnwer, selected_ques_Obj }) {
  let { answerJson, setAnswerJson } = useContext(ModalContext);
  const [selectedWords, setSelectedWords] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);

  let paragraphText = selected_ques_Obj?.question_content;
  paragraphText = paragraphText?.replace(/\r\n\r\n/g, " ");
  console.log("selected_ques_Obj", selected_ques_Obj);
  const handleWordClick = (index, word) => {
    if (selectedWords.includes(word)) {
      setSelectedIndex(selectedIndex.filter((w) => w !== index));
      setSelectedWords(selectedWords.filter((w) => w !== word));
    } else {
      setSelectedIndex([...selectedIndex, index]);
      setSelectedWords([...selectedWords, word]);
    }
  };
  console.log("selectedWords", selectedWords);
  useEffect(() => {
    var s = selectedWords.join(",");
    setAnswerJson({ ...answerJson, answer: s });
  }, [selectedWords]);
  console.log("selectedWords", answerJson.answer);
  const checkAnswer = (word) => {
    return selected_ques_Obj?.question_options?.length
      ? selected_ques_Obj?.question_options?.find(
        (item) => item?.options == word
      )
      : {};
  };
  return (
    <div className="practice-textarea d-flex align-items-start gap-2 mb-5">
      <div className="">
        {!showAnwer && paragraphText?.split(" ").map((word, index) => (
          <span
            style={{ cursor: "pointer" }}
            key={index}
            className={selectedIndex.includes(index) ? "selected" : ""}
            onClick={() => handleWordClick(index, word)}
          >
            {!showAnwer && <>{word} </>}
          </span>
        ))}

        {showAnwer && paragraphText?.split(" ").map((word, index) => (

          <span
            style={{ cursor: "pointer" }}
            key={index}
            // className={selectedIndex.includes(index) ? "selected" : ""}
            onClick={() => handleWordClick(index, word)}
          >
            {showAnwer && checkAnswer(word) ? (
              <>
                {selectedWords.includes(word) ? <button onClick={(e) => { e.preventDefault(); handleWordClick(index, word) }} className="btn border-green-hiws">{word}</button> : <button className="btn btn-outline-light px-3 m-1" onClick={(e) => { e.preventDefault(); handleWordClick(index, word) }} >{word}</button>}
                <span className="text-warning">
                  (Answer: {checkAnswer(word)?.answer})
                </span>{" "}
              </>
            ) : (
              <>{selectedIndex.includes(index) ? <button className="btn border-red-hiws" onClick={() => handleWordClick(index, word)}> {word}</button> : word + " "}</>
            )}
          </span>

          //       


        ))}
      </div>
    </div>
  );
}

export default PracticePteListeningHIWS;
