import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";
import BatchDataTable from "./BatchDataTable";
import BatchFilter from "./BatchFilter";
import Swal from "sweetalert2";

function BatchList() {
  let api = new AuthApi();
  let { userData,branchId } = useContext(Context);
  userData = JSON.parse(userData)
let isAdmin = userData?.login_type == "0"
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [showItemPerPage, setShowItemPerPage] = useState(10);

  useEffect(() => {
    if(branchId){
    get_batch_all();
  }
  }, [branchId]);


const edit_batch_by_id = async (e,id) =>{
    let {name,checked} = e.target;
    var active = null;
    if(checked){
        active = true;
    }else{
        active = false
    }
    await api.edit_batch_by_id({active:active}, id).then((res) => {
        try {
          if (res.status) {
            get_batch_all();
          }
        } catch (err) {
          console.log(err);
        }
      });

}
  const get_batch_all = async () => {
    await api
      .get_batch_all(branchId)
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res?.data.reverse());
          setAllData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const delete_batch = async (id) =>{
    let flag = false;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.value) {
         api
        .delete_batch({id})
        .then((res) => {
          if (res?.data?.length > 0) {
  
            Swal.fire({
              icon:"success",
              title:"Good job!",
              text:"successfully deleted the batch"
  
            })
  
            get_batch_all()
            // setData(res?.data.reverse());
          }
          else{
            Swal.fire({
              icon:"error",
              title:"Oops...",
              text:res?.msg,
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
        }
    })
    
  }
  const BatchColumns = [
    {
      name: "SR No.",
      cell: (row, index) => (
        <span className="text-middark">{index + 1}</span>
      ),
      sortable: false,
    },
    {
      name: "Batch Name",
      grow: "1",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row.sub_type}</span>      
      ),
      sortable: true,
    },
    {
      name: "Program",
      grow: "2",
      selector: (row) => row.product,
      cell: (row) => <span className="text-middark">{row.type}</span>,
      sortable: true,
    },
   
    {
      name: "Duration",
      grow: "2",
      selector: (row) => row.username,
      cell: (row) => <span className="text-middark">{row.starttime+"-"+row.endtime
    }</span>,
      sortable: true,
    },

    
    {
      name: "action",
      cell: (row) => (
        <>
        {/* // <div className="text-end w-100 d-flex justify-content-between"> */}
       {/* <div className="form-check form-switch">
                        <input
                          className="form-check-input ms-3"
                          type="checkbox"
                          role="switch"
                          value={row.active}
                          checked={row.active}
                          onChange={(e)=>edit_batch_by_id(e,row._id)}
                          id="directMessage"
                          name="active"
                        />
                      </div> */}
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
   
            <LinkListItem to={`/edit-batch/${row._id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem>
               {/* {!row?.assign_status ?  */}
             <div className="del-btn" onClick={()=>delete_batch(row._id)}>
             <Icon name="trash" ></Icon>
           </div>
           {/* : */}
             {/* <div className="del-btn">
             <Icon name="trash" className="disabled"></Icon> */}
           {/* </div> */}
            {/* } */}
   
          </LinkList>
        {/* // </div> */}
        </>
      ),
      sortable: false,
    },
  ];

  console.log('dataBatchColumns :>> ', BatchColumns);
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Batch Details</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
             
                <div className="d-flex justify-content-end">
                <h5></h5>

             
          
            <Link
              to="/add-batch"
              className="d-none d-md-inline-flex btn btn-primary  add-btn"
              variant="primary"
            >
              <Icon name="plus" />
              <span>Add Batch</span>
            </Link>
          
          </div>
         
          <div class="data-table-wrapper">
                <BatchFilter
                
                allData={alldata}
            tableData={data}
            setData={(data) => setData(data)}
            showItemPerPage={showItemPerPage}
            setShowPage={(value) => setShowItemPerPage(Number(value))}
         
                />
                <BatchDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  isAdmin={isAdmin}
                  BatchColumns={BatchColumns}
                  showItemPerPage={showItemPerPage}
                  setShowItemPerPage={setShowItemPerPage}
                />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default BatchList;
