import { Card, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Icon } from '../../components';


function Invoice() {
    return (
        <Layout title="Add Student" content="container">
            <h1 className='mb-5'>Add Credit</h1>
            <Block>
                <Card className='h-100'>
                    <Card.Body>
                    </Card.Body>
                </Card>
            </Block>
        </Layout>
    )
}

export default Invoice;