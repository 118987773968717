import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col, Table, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";
import AdminApi from "../../api/admin";
import ResultsFilter from "./ResultsFilter";

function MockTestResults() {
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  let navigate = useNavigate();
  let api = new AdminApi();
  var matchingQuestionWitAttempted;

  const [upgradeModal, setUpgradeModal] = useState(false);
  const upgradeModalClose = () => setUpgradeModal(false);

  const [continueModal, setContinueModal] = useState(false);
  const continueModalClose = () => setContinueModal(false);
  const continueModalShow = () => setContinueModal(true);

  const [deleteModal, setDeleteModal] = useState({ status: false, id: null });
  const deleteModalClose = async (ok) => {
    if (!ok) {
      setDeleteModal({ ...deleteModal, status: false });
    } else {
      await api
        .delete_answer_sheet(deleteModal.id)
        .then((res) => {
          console.log("res",res)
          if (res.status) {
            getMyAttemptedExam();
            setDeleteModal({ ...deleteModal, status: false, id: null });
          }
        })
        .catch((err) => console.log(err));
    }
  };
  console.log("deleteModal", deleteModal);
  const deleteModalShow = (id) =>
    setDeleteModal({ ...deleteModal, status: true, id: id ? id : null });

  const [attemptedExam, setAttemptedExam] = useState([]);
  const [truncatedData, setTruncatedData] = useState([])
  const [filteredData, setFilteredData] = useState({
    showItemPerPage:5,
    test_type:[]
  })
  const [showDiv, setShowDiv] = useState({
    topDiv: true,
    midDiv: false,
    bottomDiv: true,
  });

  const [eventKeyList, SetEventKey] = useState("isMock");
  const [eventKeyModule, SetEventKeyModule] = useState("all-test");

  useEffect(() => {
    getMyAttemptedExam();
  }, [continueModal]);

  const getMyAttemptedExam = async () => {
    await api
      .get_complete_exam(userData._id)
      .then((res) => {
        if (res?.data?.length > 0) {
          setAttemptedExam(res.data.reverse());
        }else{
          setAttemptedExam([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const toggleCardDivs = () => {
    setShowDiv((prevState) => ({
      topDiv: !prevState.topDiv,
      midDiv: !prevState.midDiv,
      bottomDiv: !prevState.bottomDiv,
    }));
  };

  const formatedDate = (createdAtStr) => {
    var createdAt = new Date(createdAtStr);

    function addLeadingZero(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    }

    var formattedDate =
      createdAt.getFullYear() +
      "-" +
      addLeadingZero(createdAt.getMonth() + 1) +
      "-" +
      addLeadingZero(createdAt.getDate()) +
      " " +
      addLeadingZero(createdAt.getHours()) +
      ":" +
      addLeadingZero(createdAt.getMinutes());

    return formattedDate;
  };

  const resultColumns = [
    {
      name: "Name/Username",
      grow: "4",
      selector: (row) => row.name,
      cell: (row) => (
        <MediaGroup>
          <MediaText>
            <Link
              to={`/result-management/student-results/${row?.papers[0]?._id}`}
              className="title"
            >
              {userData?.name}
            </Link>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Test Name",
      grow: "3",
      selector: (row) => row?.papers[0]?.name,
      cell: (row) => <span className="text-middark">{row?.papers[0]?.name}</span>,
      sortable: true,
    },
    {
      name: "End Date",
      grow: "3",
      selector: (row) => row.product,
      cell: (row) => <span className="text-middark">{row?.product}</span>,
      sortable: true,
    },
    {
      name: "Total Score",
      grow: "2",
      selector: (row) => row.username,
      cell: (row) => <span className="text-middark">{row?.mobile}</span>,
      sortable: true,
    },
    {
        name: "status",
        selector: (row) => row.status,
        cell: (row) => (
            <span className={`badge text-bg-${row?.isActive  ? "success"
                : "danger"}`
            }>
                {row?.isActive ? "Active" : 'Inactive'}
            </span>
        ),
        sortable: true,
    },
  ];  
  useEffect(() => {
    const temp = JSON.parse(JSON.stringify(attemptedExam));
    temp.splice(filteredData?.showItemPerPage)
    setTruncatedData(temp)
  },[attemptedExam,filteredData])
  console.log("truncatedData", truncatedData);
  console.log("filteredData",filteredData);
  console.log("attemptedExam", attemptedExam);

  return (
    <Layout title="Add Product" content="container">
    <Block.Head>
      <Block.HeadBetween>
        <Block.HeadContent>
          <Block.Title tag="h2"> Results </Block.Title>
          {userData?.login_type == "1" &&
          <ResultsFilter />}
        </Block.HeadContent>
      </Block.HeadBetween>
    </Block.Head>

    <Block>
      {userData?.login_type == "2" && showDiv.topDiv && (
        <Card className="mb-5">
          <Card.Body>
          {window.location.pathname == "/result-management/student-results" && userData?.login_type == "1" && truncatedData.length>0  && (
            <>
                <ResultsFilter attemptedExam={attemptedExam} setAttemptedExam={setAttemptedExam} filteredData={filteredData} setFilteredData={setFilteredData}/>
                  <StudentDataTable
                    tableClassName="data-table-head-light table-responsive custom student-list"
                    data={truncatedData}
                    columns={resultColumns}
                  />
            </>
            )}
          
            {attemptedExam.length > 0 ? (
              attemptedExam.map((item, i) => {
                console.log("atteaaaaaaaaaaaaaaaaaaaaaaaaaaaaaamptedExam", attemptedExam);
                return (
                  <>
                    
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center border-bottom p-4">
                          <div className="d-flex flex-column gap-1">
                            <h5 className="text-secondary">
                              {item?.papers[0]?.test_name}
                            </h5>
            
                          </div>

                          <div className="d-flex gap-2">
                                
                              <Button
                                variant="success"
                                style={{ width: "130px" }}
                                onClick={() =>
                                  item.isPaperEvaluated ?
                                  navigate(`/result-management/student-results/${item.papers[0]?._id}/${item._id}`) : setContinueModal(true)
                                }
                              >
                                Check Result
                              </Button>
                          </div>
                        </div>
                      </div>
                    
                      {/* tochan */}
                 
                      <Modal show={continueModal} onHide={continueModalClose}>
                      <Modal.Header closeButton>
                        {/* <Modal.Title className="d-flex gap-2 align-items-center">
                          <Icon name="help"></Icon> Notice
                        </Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
                        <h5 className="d-flex align-items-center justify-content-center gap-3">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner> Evaluating Result
                          
                        </h5>
                        <p className="text-secondary">
                          Your Test Results Are Not Evaluated Yet, Come Back After Some time
                        </p>
                      </Modal.Body>
                    </Modal>
                    
                    {/* original */}
                    {/* <Modal show={continueModal} onHide={continueModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title className="d-flex gap-2 align-items-center">
                          <Icon name="help"></Icon> Notice
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
                        <p className="text-secondary">
                          You did not finish this test last time. Do you want
                          to continue from your saved session?
                        </p>
                        <div className="d-flex justify-content-end gap-2">
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              navigate(
                                `/exam/pte/pte-scored-test-intro/${item.id}/ans/${item.answerId}`
                              )
                            }
                          >
                            {" "}
                            Restart{" "}
                          </Button>
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={() =>
                              navigate(
                                `/exam/pte/pte-scored-test-intro/${item.id}/exam/${item.paperId}`
                              )
                            }
                          >
                            {" "}
                            Continue{" "}
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal> */}
                  </>
                );
              })
            ) : (
              <div className="text-center p-3 text-middark">
                {" "}
                No Record Found
              </div>
            )}

            {/* {attemptedExam.length > 0 && (
              <div className="d-flex justify-content-end">
                <Button variant="transparent" onClick={toggleCardDivs}>
                  <span>Show All Tests</span>{" "}
                  <Icon name="chevron-right"></Icon>
                </Button>
              </div>
            )} */}
          </Card.Body>
        </Card>
      )}
      {showDiv.midDiv && (
        <Card className="mb-5">
          <Card.Body>
            <h3>My Tests</h3>
            <div className="d-flex flex-column">
              {attemptedExam.length > 0 &&
                attemptedExam.map((item, i) => {
                  console.log("attemptedExam", attemptedExam);
                  return (
                    <>
                    
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center border-bottom p-4">
                          <div className="d-flex flex-column gap-1">
                            <h5 className="text-secondary">
                              【Free Full Test】{item?.papers[0]?.name} A (note: VIP
                              required for scoring)
                            </h5>
            
                          </div>

                          <div className="d-flex gap-2">
                                
                              <Button
                                variant="success"
                                style={{ width: "130px" }}
                                onClick={() =>
                                  item.isPaperEvaluated ?
                                  navigate(`/result-management/student-results/${item.papers[0]?._id}`) : setContinueModal(true)
                                }
                              >
                                Check Result
                              </Button>
                          </div>
                        </div>
                        {/* tochan */}
                 
                      <Modal show={continueModal} onHide={continueModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title className="d-flex gap-2 align-items-center">
                          <Icon name="help"></Icon> Notice
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
                        <h5 className="d-flex align-items-center justify-content-center gap-3">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner> Evaluating Result
                          
                        </h5>
                        <p className="text-secondary">
                          Your Test Results Are Not Evaluated Yet, Come Back After Some time
                        </p>
                      </Modal.Body>
                    </Modal>
                      </div>
                    </>
                  );
                })}
            </div>
          </Card.Body>
        </Card>
      )}
      
   <Modal show={deleteModal.status} onHide={deleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className="d-flex gap-2 align-items-center">
              <Icon name="help"></Icon> Notice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
            <p className="text-secondary">
              Are you sure to delete this test record?<br></br>
              It will be unrecoverable after deletion
            </p>
            <div className="d-flex justify-content-end gap-2">
              <Button size="sm" variant="primary" onClick={deleteModalClose}>
                {" "}
                Cancel{" "}
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  deleteModalClose("yes");
                }}
              >
                {" "}
                Delete{" "}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
    </Block>
  </Layout>
  );
}

export default MockTestResults;
