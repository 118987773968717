import { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import AuthApi from '../../../api/auth';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Icon, Select } from '../../../components';
import InstituteDataTable from './InstituteDataTable';
import InstituteData, { InstituteColumns } from './InstituteData';
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";
import DataTable from 'react-data-table-component';
import DataTablePagination from '../../../components/Pagination/DataTablePagination';
import InstituteFilter from './instituteFilter';
import Swal from 'sweetalert2';

function InstituteList() {
    let api = new AuthApi();
    const [showModal, setShowModal] = useState(false);
    const[data,setData] = useState([])
    const[alldata,setAllData] = useState([])
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [totalRows, setTotalRows] = useState(0);

useEffect(()=>{
    getInstituteList();
},[])

const getInstituteList = async () =>{
await api.institute_List().then((res) => {
    console.log("ress", res)
    if (res.data.length > 0 ) {
        setData(res.data.reverse())
        setAllData(res.data.reverse())
        setTotalRows(res?.data?.length)
    } 
  })
  .catch((error) => {
    console.log(error);
  });
}
console.log(data)

const handleChange = async(e, type, index, id) => {
    console.log("inside handler", index);



    // if (type != undefined && type == "check") {
    //   console.log("checkedddddddd :>> ");


    //   setAnnouncementData(prev => 
    //     prev.map((o, i) => 
    //     i === index
    //       ? { ...o, [e.target.name]: e.target.checked }
    //       : o
    //       // console.log('o,i,index, :>> ', o,i,index)
    //     )
    //   )
    //   setSelectedIndex(index)
    // --------------------


    if (type != undefined && type == "check") {
      console.log("checkedddddddd :>> ",e.target.checked);
      let checkedValue=e.target.checked

      if(!checkedValue ){
      const { value: data } = await Swal.fire({
        title: "Please enter the reason of deactivation",
        input: "text",       
        inputPlaceholder: "Reason",
        showCancelButton: true,
        inputValidator:(value)=>{
        if(!value){
            return "You need to write something!!"

        }
        }
       
      });
      console.log('value :>> ', data);
      
      if (data) {
        api
            .institute_active_inactive_by_admin( {    
  
                inst_id:id,
         
              inactive_remarks:data,
              is_deleted:checkedValue ? 0 : 1
             })
  
            
  
            .then((res) => {
              if (res?.status) {
              
                console.log("res", res);
                Swal.fire({
                  icon: "success",
                  title: "Good job!",
                  text: res?.msg,
                })
                getInstituteList()
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.msg,
                });
    
              }
            })
            .catch((error) => {
              console.log("inner catch",error);
            });
      
      }
      


    //   setAnnouncementData(prev =>
    //     prev.map((o, i) =>
    //       o?._id === id
    //         ? { ...o, [e.target.name]: e.target.checked }
    //         : o
    //       // console.log('o,i,index, :>> ', o,i,index)
    //     )
    //   )
    //   setSelectedIndex(id)
}
else{
  

     
        api
            .institute_active_inactive_by_admin( {  
  
                inst_id:id,
         
             
              is_deleted:checkedValue ? 0 : 1
             })
  
            
  
            .then((res) => {
              if (res?.status) {
              
                console.log("res", res);
                Swal.fire({
                  icon: "success",
                  title: "Good job!",
                  text: res?.msg,
                })
                getInstituteList()
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res?.msg,
                });
    
              }
            })
            .catch((error) => {
              console.log("inner catch",error);
            });
      
      
  
}

    }
  };




 const InstituteColumns = [
    {
        name: <Form.Check className="form-check" id="check-all" />,
        cell: (row) => (
            <div className="text-center w-100">
                <Form.Check className="form-check justify-content-center" />
            </div>
        ),
        sortable: false,
    },

    // {
    //     name: "SR No.",
    //     // cell: (row, index) => <span className="text-middark">{index + 1}</span>,
    //     cell: (row, index) => (currentPageNo - 1) * showItemPerPageInst + index + 1,
    //     sortable: false,
    //   }
      ,
    {
        name: "Institute Name",
        selector: (row) => row?.domain_name,
        cell: (row) => (
            <MediaGroup>
                <Media size="md" shape="circle" variant={row.theme && row.theme}>
                    {row?.inst_profile?.business_logo ?
                       <Image src={row?.inst_profile?.business_logo} staticImage /> :
                    <Image src={'/images/avatar/a.jpg'} staticImage /> 
                    }
                </Media>
                <MediaText>
                    <Link to={`/institute-detail/${row.domain_name}/${row?._id}`} className="title">{row?.inst_profile?.inst_business_name}</Link>
                </MediaText>
            </MediaGroup>
        ),
        sortable: true,
    },
    // {
    //     name: "Product",
    //     selector: (row) => row.product,
    //     cell: (row) => (
    //         <span className="text-middark">{row.product}</span>
    //     ),
    //     sortable: true,
    //     hide: "md",
    // },
    {
        name: "Username/email",
        selector: (row) => row?.email,
        cell: (row) => (
            <span className="text-middark">{row?.email}</span>
        ),
        sortable: true,
    },
    {
        name: "Owner Name ",
        selector: (row) => row.inst_profile?.inst_contact_firstname,
        cell: (row) => (
            <span className="text-middark">{row?.inst_profile?.inst_contact_firstname}</span>
        ),
        sortable: true,
    },
    // {
    //     name: "Assigned",
    //     selector: (row) => row.assigned,
    //     cell: (row) => (
    //         <span className="text-middark">{row.assigned}</span>
    //     ),
    //     sortable: true,
    //     hide: "lg",
    // },
    {
        name: "country",
        selector: (row) => row.attempt,
        cell: (row) => (
            <span className="text-middark">{row?.inst_profile?.inst_business_country}</span>
        ),
        sortable: true,
    },
    {
        name: "students",
        selector: (row) => row.score,
        cell: (row) => (
            <span className="text-middark">{row?.inst_profile?.inst_number_of_student}</span>
        ),
        sortable: true,
    },
    {
        name: "Credit",
        grow: "1",
        selector: (row) => row?.email,
        cell: (row) => (
            <span className="text-middark">{row?.credit}</span>
        ),
        sortable: true,
    },
    {
        name: "status",
        selector: (row) => row.status,
        // cell: (row) => (
        //     // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //     //     : row.status === "Inactive" ? "danger"
        //     //         : "primary"}`
        //     // }>
        //     <span className={`badge text-bg-${row.is_deleted == 0 ? "success"
        //         : row.is_deleted == 1? "danger"
        //             : "primary"}`
        //     }>
        //         {row.is_deleted == 0 ? "Active" : 'Inactive'}
        //     </span>
        // ),
        // sortable: true,
        cell: (row,index) => (
            <div className="ms-2">
                        <Form.Check type="switch" id="flexSwitchChecked"
                          name='isActive'
                          //  onChange={(e) => handleChange(e, "check",index,row?._id)}
                          onChange={(e) => handleChange(e, "check", (currentPageNo - 1) * showItemPerPageInst + index, row?._id)}
                          checked={row.is_deleted == 0   ? true : false}
        
                        />
                      </div>)
    },
    {
        name: "action",
        cell: (row) => (
            <div className="text-end w-100">
                <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
                    <LinkListItem to={`/institute-detail/${row.domain_name}/${row?._id}`} >
                        <Icon name="edit"></Icon>
                    </LinkListItem>             
                    {/* <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
                        <Icon name="trash"></Icon>
                    </LinkListItem> */}
                </LinkList>
            </div>
        ),
        sortable: false,
    },

];

const [showItemPerPageInst, setShowPageInst] = useState(10);
const [currentPageNo, setCurrentPageNo] = useState(1);



    return (
        <Layout title="Users List" content="container">
            <h1 className='mb-5'> Institute List</h1>
            <Block>
                <Row className="g-gs">
                    <Col md="12">
                        <Card>
                            <Card.Body>
                            <div class="data-table-wrapper">
                            <div className='w-25'><label class="form-label" >Per Page</label>
              <Form.Group className="form-control-wrap ms-1 me-1">
                <select
                  className="form-select"
                  onChange={(e) => setShowPageInst(e.target.value)}
                  value={showItemPerPageInst}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
                
              </Form.Group>
            </div>

                                {/* <InstituteDataTable tableClassName="data-table-head-light table-responsive institute-list" data={data} columns={InstituteColumns} showItemPerPageInst={showItemPerPageInst} setShowPageInst={(e) => setShowPageInst(e)}   /> */}


                                
                <InstituteFilter allData={data} allRecorddata={alldata} tableData={data} setData={(data) => setData(data)} showItemPerPage={showItemPerPageInst} setShowPage={(e) => setShowPageInst(e)} />
              


                                <DataTable
        columns={InstituteColumns}
        data={data}
        className={"data-table-head-light table-responsive institute-list"}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination
        
        onChangePage={setCurrentPageNo}
        paginationTotalRows={totalRows}
        
       
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPageInst}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowPageInst}
            />
          </div>
        )}
      />
      </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="firstname">First Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="firstname" type="text" placeholder="First name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="lastname">Last Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="lastname" type="text" placeholder="Last name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">Email Address</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="email" type="text" placeholder="Email address" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label>Status</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a status</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Active</option>
                                            <option value="3">Inactive</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group className="form-group">
                                    <Form.Label>Role</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a role</option>
                                            <option value="1">Administrator</option>
                                            <option value="2">Developer</option>
                                            <option value="3">Analyst</option>
                                            <option value="4">Support</option>
                                            <option value="5">Trial</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={handleCloseModal}>Add User</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Discard</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export default InstituteList;