import { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Tab, Nav } from "react-bootstrap";
import { Icon } from "../../components";
import QuestionsList from "./QuestionsList";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import TeamDataTable from "../admin/TeamDataTable";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import TimeSelector from "./TimeSelector ";
function CreateMockTest() {
  let api = new AdminApi();
  let navigate = useNavigate();
  const [manual, setManual] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [data, setData] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [pattern_list,setPattern_list] = useState([])
  const [paginate, setPaginate] = useState({offset:0, limit:20, currentPage:1})
  const [filters, setFilters] = useState({module_name:"",sub_module:"",test_type:"all",title:""})
  const [totalDocuments, setTotalDocuments] = useState(0)
  // const [filterObj, setfilterObj] = useState({ isExplantion: false});
  const itemsPerPage = 20;
  const [timingsForMockTest, setTimingsForMockTest] = useState({speaking_time:"",listening_time:"",reading_time:"",writing_time:""})
  console.log("timingsForMockTest",timingsForMockTest);
  const [json, setJson] = useState({
    questID: [],
    rquestID: [],
    wquestID: [],
    squestID: [],
    lquestID: [],
    squestID_json: {
      read_alouds: [],
      repeat_sentences: [],
      describe_images: [],
      retell_lectures: [],
      answer_questions: [],
    },
    lquestID_JSon: {
      ssts: [],
      l_mcm: [],
      l_fib: [],
      l_hcs: [],
      l_mcs: [],
      l_smw: [],
      hiws: [],
      wfds: [],
    },
    wquestID_json: {
      swts: [],
      essays: [],
    },
    rquestID_json: {
      fib_wr: [],
      r_mcm: [],
      ro: [],
      r_mcs: [],
      fib_rd: [],
    },
    test_type: "mock_test",
    speaking: {
      seleted_question: 0,
      read_alouds: 0,
      repeat_sentences: 0,
      total_question: 0,
      describe_images: 0,
      retell_lectures: 0,
      answer_questions: 0,
    },
    listening: {
      seleted_question: 0,
      total_question: 0,
      ssts: 0,
      l_mcm: 0,
      l_fib: 0,
      l_hcs: 0,
      l_mcs: 0,
      l_smw: 0,
      hiws: 0,
      wfds: 0,
    },
    writing: {
      seleted_question: 0,
      total_question: 0,
      swts: 0,
      essays: 0,
    },
    reading: {
      seleted_question: 0,
      total_question: 0,
      fib_wr: 0,
      r_mcm: 0,
      ro: 0,
      r_mcs: 0,
      fib_rd: 0,
    },
  });
  const [selected_question_count, set_Selected_question_count] = useState({
    speaking: {
      seleted_question: 0,
      read_alouds: 0,
      repeat_sentences: 0,
      total_question: 0,
      describe_images: 0,
      retell_lectures: 0,
      answer_questions: 0,
    },
    listening: {
      seleted_question: 0,
      total_question: 0,
      ssts: 0,
      l_mcm: 0,
      l_fib: 0,
      l_hcs: 0,
      l_mcs: 0,
      l_smw: 0,
      hiws: 0,
      wfds: 0,
    },
    writing: {
      seleted_question: 0,
      total_question: 0,
      swts: 0,
      essays: 0,
    },
    reading: {
      seleted_question: 0,
      total_question: 0,
      fib_wr: 0,
      r_mcm: 0,
      ro: 0,
      r_mcs: 0,
      fib_rd: 0,
    },
  });
  const [count_ques, setCount_ques] = useState({
    listening: 0,
    reading: 0,
    speaking: 0,
    writing: 0,
  });
  const [checkedTadio, setChecked] = useState("all")
  const [activeTab, setActiveTab] = useState("pills-manual")
  const handleChange = (
    e,
    selectPatternObj,
    selectedQuestionModule,
    selected_moduleName
  ) => {
    
    const { name, value, checked } = e.target;
    console.log("TARGETS",name,value,checked);
    if (name === "questID") {
      const updatedQuestionCount = { ...selected_question_count };

      const selectedModule = updatedQuestionCount[selectedQuestionModule];
      const selectedModuleValue = selectedModule[selected_moduleName];
      var rquestID = { ...json.rquestID_json };
      var wquestID = { ...json.wquestID_json };
      var squestID = { ...json.squestID_json };
      var lquestID = { ...json.lquestID_JSon };

      var rquestID_value = [...json?.rquestID];
      var wquestID_value = [...json?.wquestID];
      var squestID_value = [...json?.squestID];
      var lquestID_value = [...json?.lquestID];

      console.log("JSON",json);
      console.log("count_ques",count_ques);
      console.log("selected_question_count",selected_question_count);
      console.log("MODULES",selectedQuestionModule,selected_moduleName);
      //tochan
      console.log("PRINT",selected_question_count[selectedQuestionModule][selected_moduleName])

      console.log(console.log("EVENTTARGET"),e.target);

      if(e.target.checked == true && selected_question_count[selectedQuestionModule][selected_moduleName] >= json[selectedQuestionModule][selected_moduleName]){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Maximum Questions That You Can Add in ${selected_moduleName} is ${json[selectedQuestionModule][selected_moduleName]}` ,
        });
        return 
      }

      if (selectedQuestionModule == "listening") {
        // Check if selected_moduleName exists in lquestID
        if (!lquestID.hasOwnProperty(selected_moduleName)) {
          // If it doesn't exist, create a new array with the value
          lquestID[selected_moduleName] = [value];
        } else {
          // If it exists, check if the value is already in the array
          const index = lquestID[selected_moduleName].indexOf(value);
          if (index === -1) {
            // If the value is not in the array, push it
            lquestID[selected_moduleName].push(value);
          } else {
            // If the value is already in the array, remove it
            lquestID[selected_moduleName].splice(index, 1);
          }
        }
        lquestID_value = Object.values(lquestID).reduce((acc, currentValue) => {
          return acc.concat(currentValue);
        }, []);
      }

      if (selectedQuestionModule == "reading") {
        // Check if selected_moduleName exists in lquestID
        if (!rquestID.hasOwnProperty(selected_moduleName)) {
          // If it doesn't exist, create a new array with the value
          rquestID[selected_moduleName] = [value];
        } else {
          // If it exists, check if the value is already in the array
          const index = rquestID[selected_moduleName].indexOf(value);
          if (index === -1) {
            // If the value is not in the array, push it
            rquestID[selected_moduleName].push(value);
          } else {
            // If the value is already in the array, remove it
            rquestID[selected_moduleName].splice(index, 1);
          }
        }
        rquestID_value = Object.values(rquestID).reduce((acc, currentValue) => {
          return acc.concat(currentValue);
        }, []);
      }
      if (selectedQuestionModule == "speaking") {
        // Check if selected_moduleName exists in lquestID
        if (!squestID.hasOwnProperty(selected_moduleName)) {
          // If it doesn't exist, create a new array with the value
          squestID[selected_moduleName] = [value];
        } else {
          // If it exists, check if the value is already in the array
          const index = squestID[selected_moduleName].indexOf(value);
          if (index === -1) {
            // If the value is not in the array, push it
            squestID[selected_moduleName].push(value);
          } else {
            // If the value is already in the array, remove it
            squestID[selected_moduleName].splice(index, 1);
          }
        }
        squestID_value = Object.values(squestID).reduce((acc, currentValue) => {
          return acc.concat(currentValue);
        }, []);
      }
      if (selectedQuestionModule == "writing") {
        if (!wquestID.hasOwnProperty(selected_moduleName)) {
          // If it doesn't exist, create a new array with the value
          wquestID[selected_moduleName] = [value];
        } else {
          // If it exists, check if the value is already in the array
          const index = wquestID[selected_moduleName].indexOf(value);
          if (index === -1) {
            // If the value is not in the array, push it
            wquestID[selected_moduleName].push(value);
          } else {
            // If the value is already in the array, remove it
            wquestID[selected_moduleName].splice(index, 1);
          }
        }
        wquestID_value = Object.values(wquestID).reduce((acc, currentValue) => {
          return acc.concat(currentValue);
        }, []);
      }

      if (checked) {
        selectedModule[selected_moduleName] = selectedModuleValue + 1;
      } else {
        selectedModule[selected_moduleName] = selectedModuleValue - 1;
      }
      set_Selected_question_count(updatedQuestionCount);
      let selectedQuestion = [...json.questID];

      if (!json.questID.includes(value)) {
        switch (selectedQuestionModule) {
          case "listening":
            setCount_ques({
              ...count_ques,
              listening: count_ques.listening + 1,
            });
            break;
          case "reading":
            setCount_ques({ ...count_ques, reading: count_ques.reading + 1 });
            break;
          case "speaking":
            setCount_ques({ ...count_ques, speaking: count_ques.speaking + 1 });
            break;
          case "writing":
            setCount_ques({ ...count_ques, writing: count_ques.writing + 1 });
            break;
        }
        selectedQuestion.push(value);
      } else {
        switch (selectedQuestionModule) {
          case "listening":
            setCount_ques({
              ...count_ques,
              listening: count_ques.listening - 1,
            });
            break;
          case "reading":
            setCount_ques({ ...count_ques, reading: count_ques.reading - 1 });
            break;
          case "speaking":
            setCount_ques({ ...count_ques, speaking: count_ques.speaking - 1 });
            break;
          case "writing":
            setCount_ques({ ...count_ques, writing: count_ques.writing - 1 });
            break;
        }
        selectedQuestion = selectedQuestion.filter((item) => item != value);
      }
      setJson({
        ...json,
        questID: selectedQuestion,
        rquestID: rquestID_value,
        wquestID: wquestID_value,
        squestID: squestID_value,
        lquestID: lquestID_value,
        module: selectedModule,
        module_name: selected_moduleName,
        module: json.test_type == "isSection" ? selectedQuestionModule : null,
      });
    } else {
      if (name == "pattern_id") {
        setJson({
          ...json,
          ["pattern_id"]: value,
          test_type: selectPatternObj.pattern_type,
          _id: null,
          ...selectPatternObj,
        });
      } else {
        setJson({ ...json, [name]: value });
      }
    }
  };

  useEffect(() => {
    if (json.pattern_id && json.type == "manual" && json.test_name) {
      setManual(true);
    }
  }, [json]);

  const handleChangeForUI = (e, type, test_type) => {
    setValidate(true);
    let { name, value, checked } = e.target;
    if (type == "manual" && checked) {
      if (json.pattern_id && json.test_name) {
        setManual(true);
      }

      setJson({ ...json, questID: [], ["type"]: "manual" });
    }
    if (type == "auto" && checked) {
      setManual(false);
      setJson({ ...json, ["type"]: "automatic" });
    }
    if (type == "test_type") {
      setJson({ ...json, ["test_type"]: test_type });
    }
    if (type == "module") {
      setJson({ ...json, ["module"]: test_type });
    }
  };
  console.log("questID", json)
  const handleSubmitCreateTest = async (e) => {
    e.preventDefault();
    delete json?._id
    // json.remove
    await api
      .create_paper({ ...json,...timingsForMockTest ,questID: [] })
      .then((res) => {
        if (res?.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/view-test");
        } else {
          Swal.fire({
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getQuestionModuleNameWise = async (module_name, sub_module, e) => {
    setFilters({ ...filters, sub_module: sub_module, module_name: module_name, title: e?.target?.value || "" })
    await api
      .get_qst_all_admin(paginate, { ...filters, sub_module: sub_module, module_name: module_name, title: e?.target?.value || "" })
      .then((res) => {
        if (res.question.length > 0) {
          setData(res.question.reverse());
          setTotalDocuments(res?.length)
          setOldData(res.question.reverse());
        } else {
          setData([]);
          setOldData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("json", json);

  function handleAll(e){
    // if(e.target.checked == true){
    //   const ele = document?.getElementsByName("questID")
    //   let i = 0;
    //   for(let obj of ele){
    //     if(i < json[filters.module_name][filters.sub_module]){
    //       let e = {target:{name:"questID", checked:true, value:obj.defaultValue}}
    //       handleChange(e, null, filters.module_name, filters.sub_module);
    //     }
    //     i++
    //   }
    //   i = 0;
    //   for(let obj of ele){
    //     if(i < json[filters.module_name][filters.sub_module]){
    //       // let e = {target:{name:"questID", checked:true, value:obj.defaultValue}}
    //       obj.checked = true
    //       console.log("INSIDE");
    //       // handleChange(e, null, filters.module_name, filters.sub_module);
    //       // obj.click()
    //     }
    //     i++
    //   }
      
    // }
    // console.log("EVENTALL",e.target.id);
  }

  const QuestionColumns = [
    {
      name: <Form.Check className="form-check" onChange={(e) => {handleAll(e)}} id="check-all" />,
      cell: (row) => (
        <div className="text-start w-100">
          <Form.Check
            className="form-check"
            name="questID"
            checked={json.questID?.includes(row._id) && true}
            value={row._id}
            onChange={(e) => {
              handleChange(e, null, row.module, row.module_name);
            }}
          />
        </div>
      ),
      sortable: false,
    },

    {
      name: "Id",
      selector: (row) => row?.email,
      cell: (row) => (
        <span className="text-middark">{row?.id ? "#" + row?.id : "00"}</span>
      ),
      sortable: true,
    },
    {
      name: "Tags",
      grow: "3",
      selector: (row) => row?.domain_name,
      cell: (row) => (
        <MediaGroup>
          <MediaText>
            <Link to={`/institute-detail/${row.domain_name}`} className="title">
              {row?.tags}
            </Link>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row,
      cell: (row) => (
        <span className="text-middark">{(row?.exam_type).toUpperCase()}</span>
      ),
      sortable: true,
    },
    {
      name: "Module",
      grow: "3",
      selector: (row) => row.assigned,
      cell: (row) => (
        <span className="text-middark">{row.module?.toUpperCase()}</span>
      ),
      sortable: true,
    },

    {
      name: "Sub-Module",
      grow: "3",
      selector: (row) => row.attempt,
      cell: (row) => <span className="text-middark">{row?.module_name}</span>,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status,
      cell: (row) => (
        <span
          className={`badge text-bg-${row.is_deleted == 0
            ? "success"
            : row.is_deleted == 1
              ? "danger"
              : "primary"
            }`}
        >
          {row.is_deleted != 0 ? "Active" : "Inactive"}
        </span>
      ),
      sortable: true,
    },
  ];
  let speaking = [
    { value: "read_alouds", text: "Read Aloud" },
    { value: "repeat_sentences", text: "Repeat Sentence" },
    { value: "describe_images", text: "Describe Image" },
    { value: "retell_lectures", text: "Re-Tell Lecture" },
    { value: "answer_questions", text: "Answer Short Question" },
  ];

  let writing = [
    { value: "swts", text: "Summarize Writing Text" },
    { value: "essays", text: "Essay" },
  ];

  let reading = [
    { value: "fib_wr", text: "Reading and Writing Fill in the Blanks" },
    { value: "r_mcm", text: "Multiple Choice, Choose Multiple Answer" },
    { value: "ro", text: "Re-order Paragraphs" },
    { value: "r_mcs", text: "Multiple Choice, Choose Single Answer" },
    { value: "fib_rd", text: "Reading: Fill in the Blanks" },
  ];

  let listening = [
    { value: "ssts", text: "Summarize Spoken Text" },
    { value: "l_mcm", text: "Multiple Choice Multiple Answer" },
    { value: "l_fib", text: "Fill in the Blanks" },
    { value: "l_hcs", text: "Highlight Correct Summary" },
    { value: "l_mcs", text: "Multiple Choice Single Answer" },
    { value: "l_smw", text: "Select Missing Words" },
    { value: "hiws", text: "Highlight Incorrect Words" },
    { value: "wfds", text: "Write From Dictation" },
  ];

  const applyFilter = (e, type) => {
    if (type == "allQuestion") {
      setData(oldData);
      setChecked("all")
    }
    if (type == "predective") {
      let filTerArrya = data.filter(
        (item) => item.question_type == "prediction_wise_test"
      );
      setChecked("predective")
      setData(filTerArrya);
    }
  };

  useEffect(() => {
    getPatternDetail();
  }, []);

  const getPatternDetail = async () => {
    await api
      .get_pattern()
      .then((res) => {
        if (res.question.length > 0) {
          setPattern(res.question.reverse());
          setPattern_list(res.question.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkCorrectQuestionPattern = () => {
    
    if (
      json.speaking.total_question == count_ques.speaking &&
      json.reading.total_question == count_ques.reading &&
      json.writing.total_question == count_ques.writing &&
      json.listening.total_question == count_ques.listening
    ) {
      return false;
    } else {
      return true;
    }
  };
  const [validateFiled, setValidate] = useState(true);
  const validateVield = () => {
    if (json.pattern_id && json.type && json.test_name) {
      setValidate(false);
    } else {
      setValidate(true);
    }
  };
  useEffect(() => {
    validateVield();
    // setValidate(true)
  }, [json]);

  const handlePageChange = (event) => {
    console.log("PAGEEVENT", event.selected);
    if (event.target?.name == "goToPageNumber") {
      console.log("goToPageNumber", event.target.value);
      console.log("EVENT", event.target.value);
      event.target.value == "" ? setPaginate({ offset: 0, limit: 20, currentPage: event.target.value }) : setPaginate({ offset: (Number(event.target.value) - 1) * 20, limit: 20, currentPage: event.target.value })
    }
    else {
      setPaginate({ offset: event.selected * 20, limit: 20, currentPage: event.selected + 1 })
    }
    // scrollTop.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    getQuestionModuleNameWise(filters.module_name, filters.sub_module)
  }, [paginate])

  // useEffect(() => {
  //   getQuestionModuleNameWise(filters.module_name, filters.sub_module)
  // },[filters])


  return (
    <>
      <Layout title="Add Product" content="container">
        <Block.Head>
          <Block.HeadBetween>
            <Block.HeadContent>
              <Block.Title tag="h2">Create Your Mocktest</Block.Title>
            </Block.HeadContent>
          </Block.HeadBetween>
        </Block.Head>

        {!manual ? (
          <Block>
            <Form action="#">
              <Card className="card-gutter-md">
                <Card.Body>
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group w-50 mt-3">
                        <Form.Label htmlFor="fullname">
                          <h5>Mock Test Name</h5>
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="test_name"
                            value={data?.name}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                          // placeholder="Your Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <h5 className="mb-3">Select Test Type : </h5>
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          defaultChecked
                          // checked={data.pattern_type == "isMock" ? true : false}
                          onChange={(e) => setPattern_list(pattern)}
                          name="RadioButton1"
                          id="radioaddquestion-1 "
                          label="All"
                        />
                        <Form.Check
                          type="radio"
                          // checked={data.pattern_type == "isMock" ? true : false}
                          onClick={() => {
                            pattern.length &&
                              setPattern_list(pattern.filter((item) => item?.pattern_type == "isMock"))
                          }}
                          name="RadioButton1"
                          id="radioaddquestion-1 "
                          label="Mock Test"
                        />
                        <Form.Check
                          type="radio"
                          onClick={() => {
                            pattern.length &&
                              setPattern_list(pattern.filter((item) => item?.pattern_type == "isSection"))
                          }}
                          name="RadioButton1"
                          id="radioaddquestion-2 "
                          label="Section Wise test"
                        />
                        <Form.Check
                          type="radio"
                          onClick={(e) => {
                            pattern.length &&
                              setPattern_list(pattern.filter((item) => item?.pattern_type == "isQuestion"))
                          }}
                          name="RadioButton1"
                          id="radioaddquestion-2 "
                          label="Question Wise test"
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <h5 className="mb-3">Select Test : </h5>
                      {pattern_list.length > 0 ?
                        pattern_list.map((item, i) => {
                          return (
                            <>
                              <Form.Check
                                type="radio"
                                name="pattern_id"
                                value={item._id}
                                id={`radiotype-${i}`}
                                label={`${item.pattern} - Speaking : ${item.speaking?.total_question}, Writing :  ${item.writing?.total_question}, Reading : ${item.reading?.total_question}, Listening : ${item.listening?.total_question}`}
                                onChange={(e) => handleChange(e, item)}
                              />
                            </>
                          );
                        }) :
                        <span>No data found</span>}
                    </Col>
                    <Col md="12">
                      <h5 className="mb-3">Select Timings : </h5>
                      <TimeSelector setTimingsForMockTest={setTimingsForMockTest} json={json} />
                    </Col>
                    <Col md="12">
                      <h5 className="mb-3">Add Questions : </h5>
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          onChange={(e) => handleChangeForUI(e, "auto")}
                          name="RadioButton"
                          id="radioaddquestion-1 "
                          label="Auto"
                        />
                        <Form.Check
                          type="radio"
                          onChange={(e) => handleChangeForUI(e, "manual")}
                          name="RadioButton"
                          id="radioaddquestion-2 "
                          label="Manual"
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <Button
                        variant="primary"
                        onClick={handleSubmitCreateTest}
                        disabled={validateFiled}
                      >
                        Create
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </Block>
        ) : (
          <Block>
            <Form action="#">
              <Card className="card-gutter-md">
                <Card.Body>
                  <Row className="g-gs">
                    <Col md="6">
                      <Card
                        className={`service-box hisuccess h-100 ${json?.speaking?.total_question == 0 && `disable-div`
                          }`}
                      >
                        <Card.Body>
                          <h3>Speaking</h3>
                          {speaking.length > 0 &&
                            speaking.map((item, i) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    onChange={() =>
                                      getQuestionModuleNameWise(
                                        "speaking",
                                        item.value,
                                      )
                                    }
                                    name="btnradio-mock"
                                    id={`btnradio-hisuccess${i + 1}`}
                                    autoComplete="off"
                                  />
                                  <Button
                                    as="label"
                                      variant='outline-primary'
                                    htmlFor={`btnradio-hisuccess${i + 1}`}
                                    className={`d-flex justify-content-between ${json?.speaking[item.value] == 0 &&
                                      `disable-div`
                                      }`}
                                  >
                                    <span>{item.text}</span>
                                    <span className="d-flex gap-1">
                                      {selected_question_count?.speaking[
                                        item.value
                                      ] < 10
                                        ? "0" +
                                        selected_question_count?.speaking[
                                        item.value
                                        ]
                                        : selected_question_count?.speaking[
                                          item.value
                                        ]
                                          ? selected_question_count?.speaking[
                                          item.value
                                          ]
                                          : "00"}{" "}
                                      /
                                      {json?.speaking[item.value] < 10
                                        ? "0" + json?.speaking[item.value]
                                        : json?.speaking[item.value]
                                          ? json?.speaking[item.value]
                                          : "00"}{" "}
                                      {!json?.speaking[item.value] == 0 ? (
                                        <Icon name="check-circle"></Icon>
                                      ) : (
                                        <Icon
                                          name="cross"
                                        // className="btn-icon danger rounded-circle"
                                        ></Icon>
                                      )}
                                    </span>
                                  </Button>
                                </>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card
                        className={`service-box hidanger h-100  ${json?.writing?.total_question == 0 && `disable-div`
                          }`}
                      >
                        <Card.Body>
                          <h3>Writing</h3>
                          {writing.length > 0 &&
                            writing.map((item, i) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio-mock"
                                    onChange={() =>
                                      getQuestionModuleNameWise(
                                        "writing",
                                        item.value
                                      )
                                    }
                                    id={`btnradio-hidanger${i + 1}`}
                                    autoComplete="off"
                                  />
                                  <Button
                                 variant='outline-primary'
                                    as="label"
                                    htmlFor={`btnradio-hidanger${i + 1}`}
                                    className={`d-flex justify-content-between ${json?.writing[item.value] == 0 &&
                                      `disable-div`
                                      }`}
                                  >
                                    <span>{item.text}</span>
                                    <span className="d-flex gap-1">
                                      {selected_question_count?.writing[
                                        item.value
                                      ] < 10
                                        ? "0" +
                                        selected_question_count?.writing[
                                        item.value
                                        ]
                                        : selected_question_count?.writing[
                                          item.value
                                        ]
                                          ? selected_question_count?.writing[
                                          item.value
                                          ]
                                          : "00"}
                                      /
                                      {json?.writing[item.value] < 10
                                        ? "0" + json?.writing[item.value]
                                        : json?.writing[item.value]
                                          ? json?.writing[item.value]
                                          : "00"}
                                      {!json?.writing[item.value] == 0 ? (
                                        <Icon name="check-circle"></Icon>
                                      ) : (
                                        <Icon
                                          name="cross"
                                        // className="btn-icon danger rounded-circle"
                                        ></Icon>
                                      )}
                                    </span>
                                  </Button>
                                </>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card
                        className={`service-box hiwarning h-100 ${json?.reading?.total_question == 0 && `disable-div`
                          }`}
                      >
                        <Card.Body>
                          <h3>Reading</h3>
                          {reading.length &&
                            reading.map((item, i) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio-mock"
                                    onChange={() =>
                                      getQuestionModuleNameWise(
                                        "reading",
                                        item.value
                                      )
                                    }
                                    id={`btnradio-hiwarning${i + 1}`}
                                    autoComplete="off"
                                  />
                                  <Button
                                    variant='outline-primary'
                                    as="label"
                                    htmlFor={`btnradio-hiwarning${i + 1}`}
                                    className={`d-flex justify-content-between ${json?.reading[item.value] == 0 &&
                                      `disable-div`
                                      }`}
                                  >
                                    <span>{item.text}</span>
                                    <span className="d-flex gap-1">
                                      {selected_question_count?.reading[
                                        item.value
                                      ] < 10
                                        ? "0" +
                                        selected_question_count?.reading[
                                        item.value
                                        ]
                                        : selected_question_count?.reading[
                                          item.value
                                        ]
                                          ? selected_question_count?.reading[
                                          item.value
                                          ]
                                          : "00"}
                                      /
                                      {json?.reading[item.value] < 10
                                        ? "0" + json?.reading[item.value]
                                        : json?.reading[item.value]
                                          ? json?.reading[item.value]
                                          : "00"}
                                      {!json?.reading[item.value] == 0 ? (
                                        <Icon name="check-circle"></Icon>
                                      ) : (
                                        <Icon
                                          name="cross"
                                        // className="btn-icon danger rounded-circle"
                                        ></Icon>
                                      )}
                                    </span>
                                  </Button>
                                </>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card
                        className={`service-box hiprimary h-100 ${json?.listening?.total_question == 0 && `disable-div`
                          }`}
                      >
                        <Card.Body>
                          <h3>Listening</h3>
                          {listening.length > 0 &&
                            listening.map((item, i) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio-mock"
                                    onChange={() =>
                                      getQuestionModuleNameWise(
                                        "listening",
                                        item.value
                                      )
                                    }
                                    id={`btnradio-hirpimary${i + 1}`}
                                    autoComplete="off"
                                  />
                                  <Button
                                    as="label"
                                      variant='outline-primary'
                                    htmlFor={`btnradio-hirpimary${i + 1}`}
                                    className={`d-flex justify-content-between ${json?.listening[item.value] == 0 &&
                                      `disable-div`
                                      }`}
                                  >
                                    <span>{item?.text}</span>
                                    <span className="d-flex gap-1">
                                      {selected_question_count?.listening[
                                        item.value
                                      ] < 10
                                        ? "0" +
                                        selected_question_count?.listening[
                                        item.value
                                        ]
                                        : selected_question_count?.listening[
                                          item.value
                                        ]
                                          ? selected_question_count?.listening[
                                          item.value
                                          ]
                                          : "00"}
                                      /
                                      {json?.listening[item.value] < 10
                                        ? "0" + json?.listening[item.value]
                                        : json?.listening[item.value]
                                          ? json?.listening[item.value]
                                          : "00"}
                                      {!json?.listening[item.value] == 0 ? (
                                        <Icon name="check-circle"></Icon>
                                      ) : (
                                        <Icon
                                          name="cross"
                                        // className="btn-icon danger rounded-circle"
                                        ></Icon>
                                      )}
                                    </span>
                                  </Button>
                                </>
                              );
                            })}
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col sm="12" xxl="12" md="12" className="my-5">
                      <Card className="h-100">
                        <Card.Body className="my-5">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title">Select Question</h5>
                            </div>
                            <Button
                              variant="primary"
                              className="btn btn-primary"
                              disabled={
                                !json.questID.length > 0 ||
                                checkCorrectQuestionPattern()
                              }
                              onClick={handleSubmitCreateTest}
                            >
                              Create
                            </Button>
                          </div>

                          {/* <TimeSelector setTimingsForMockTest={setTimingsForMockTest} /> */}

                          {/* search bar to filter by api */}
                          <Form.Group className="form-group">
                            <div className=" d-flex w-40 mt-4">
                              <Form.Control
                                type="text"
                                id="inputId22"
                                value={filters?.title}
                                onChange={(e) => {
                                  // setFilterModules({...filterModules,tags:e.target.value})
                                  getQuestionModuleNameWise(filters.module_name, filters.sub_module, e)
                                  // setFilters({...filters,title:e.target.value})
                                }}
                                placeholder="Filter"
                              />
                            </div>
                          </Form.Group>
                          <div style={{ marginTop: "-80px" }}>
                            <TeamDataTable
                              tableClassName="data-table-head-light table-responsive"
                              data={data}
                              columns={QuestionColumns}
                            // filters={filters}
                            // paginate={paginate}
                            // setFilters={setFilters}
                            // setPaginate={setPaginate}  
                            // totalDocuments={totalDocuments}
                            />
                          </div>
                          {data?.length > 0 &&
                            <div className="react-pagination">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageChange}
                                pageRangeDisplayed={5}
                                // pageCount={Object.values(filterObj).filter(item => item === true).length > 0 ? (data?.questionsBy_module_name?.length/itemsPerPage) : Math.ceil(data?.totalDocuments / itemsPerPage)}
                                pageCount={Math.ceil(totalDocuments / itemsPerPage)}
                                previousLabel="<previous"
                                renderOnZeroPageCount={null}
                                pageClassName="toClick"

                              // initialPage={0}
                              // forcePage={0}
                              // forcePage={Object.values(filterObj).filter(item => item === true).length > 0 && 0}
                              />
                            </div>
                          }


                          <div className="d-none">
                            <Tab.Container
                              id="pills-tabs-example"
                              activeKey={activeTab} onSelect={(val) => setActiveTab(val)}
                            >
                              <Nav
                                variant="pills"
                                className="mb-3 justify-content-center gap-2"
                                style={{ marginTop: "-30px" }}
                              >
                                <Nav.Item>
                                  <Nav.Link eventKey="pills-auto">Auto</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="pills-manual">
                                    Manual
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <Tab.Content>
                                <Tab.Pane eventKey="pills-manual">
                                  <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
                                    <div className="d-flex gap-5">
                                      <span className="text-secondary">
                                        Filter Type 1
                                      </span>
                                      <Form.Check
                                        type="radio"
                                        checked={checkedTadio == "all" ? true : false}
                                        name="filter-11"
                                        onChange={(e) =>
                                          applyFilter(e, "allQuestion")
                                        }
                                        id="filter-1-1"
                                        label="All Ques"
                                      />
                                      <Form.Check
                                        type="radio"
                                        name="filter-11"
                                        checked={checkedTadio == "predective" ? true : false}
                                        id="filter-1-2"
                                        onChange={(e) =>
                                          applyFilter(e, "predective")
                                        }
                                        label="Prediction Ques"
                                      />
                                    </div>
                                    <Button
                                      variant="primary"
                                      className="w-auto m-auto btn btn-primary"
                                      disabled={
                                        !json.questID.length > 0 ||
                                        checkCorrectQuestionPattern()
                                      }
                                      onClick={handleSubmitCreateTest}
                                    >
                                      Add
                                    </Button>
                                  </div>

                                {/* search bar to filter by api */}
                                <Form.Group className="form-group">
                                  <div className=" d-flex w-40 mt-4">
                                    <Form.Control
                                      type="text"
                                      id="inputId22"
                                      value={filters?.title}
                                      onChange={(e) => {
                                        // setFilterModules({...filterModules,tags:e.target.value})
                                        getQuestionModuleNameWise(filters.module_name, filters.sub_module, e)
                                        // setFilters({...filters,title:e.target.value})
                                      }}
                                      placeholder="Filter"
                                    />
                                  </div>
                                </Form.Group>
                               <div style={{marginTop:"-80px"}}>
                                <TeamDataTable
                                  tableClassName="data-table-head-light table-responsive"
                                  data={data}
                                  columns={QuestionColumns}
                                  // filters={filters}
                                  // paginate={paginate}
                                  // setFilters={setFilters}
                                  // setPaginate={setPaginate}  
                                  // totalDocuments={totalDocuments}
                                />
                                </div> 
                                {data?.length > 0 &&
                                <div className="react-pagination">
                                  <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={handlePageChange}
                                    pageRangeDisplayed={5}
                                    // pageCount={Object.values(filterObj).filter(item => item === true).length > 0 ? (data?.questionsBy_module_name?.length/itemsPerPage) : Math.ceil(data?.totalDocuments / itemsPerPage)}
                                    pageCount={Math.ceil(totalDocuments / itemsPerPage)}
                                    previousLabel="<previous"
                                    renderOnZeroPageCount={null}
                                    pageClassName="toClick"
                                    
                                    // initialPage={0}
                                    // forcePage={0}
                                    // forcePage={Object.values(filterObj).filter(item => item === true).length > 0 && 0}
                                  />
                                </div>
                                }
                              </Tab.Pane>
                              <Tab.Pane eventKey="pills-auto">
                                <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
                                  <div className="d-flex gap-5">
                                    <span className="text-secondary">
                                      Filter Type 1
                                    </span>
                                    <Form.Check
                                      type="radio"
                                      defaultChecked
                                      name="filter-1"
                                      checked={checkedTadio=="all"}
                                      onChange={(e) =>
                                        applyFilter(e, "allQuestion")
                                      }
                                      id="filter-1-1"
                                      label="All Ques"
                                    />
                                    <Form.Check
                                      type="radio"
                                      name="filter-1"
                                      id="filter-1-2"
                                      checked={checkedTadio=="predective"}
                                      onChange={(e) =>
                                        applyFilter(e, "predective")
                                      }
                                      label="Prediction Ques"
                                    />
                                    {/* <Form.Check
                                      type="radio"
                                      name="filter-1"
                                      id="filter-1-3"
                                      label="New Ques"
                                    /> */}
                                    </div>
                                    {/* <Button
                                    variant="primary"
                                    className="w-auto m-auto btn btn-primary"
                                    onClick={handleSubmitCreateTest}
                                    disabled={data.length == 0}
                                  >
                                    Add
                                  </Button> */}
                                  </div>
                                  {data.length == 0 && (
                                    <TeamDataTable
                                      tableClassName="data-table-head-light table-responsive"
                                      data={data}
                                      columns={QuestionColumns}
                                    />
                                  )}
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </Block>
        )}
      </Layout>
    </>
  );
}

export default CreateMockTest;
