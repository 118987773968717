import React, { useState, useEffect, useContext } from "react";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import AudioComp from "./mocktest-components/AudioComp";
import ScoreComp from "./mocktest-components/ScoreComp";
import SubModuleHeadingComp from "./mocktest-components/SubModuleHeadingComp";
import GoToQuestionsComp from "./mocktest-components/GoToQuestionsComp";
import ParagraphComp from "./mocktest-components/ParagraphComp";

function ReTellLecComp() {

  return (
    <Layout title="Add Product" content="container">
      <Block>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex justify-content-between">
            <SubModuleHeadingComp></SubModuleHeadingComp>
            <GoToQuestionsComp></GoToQuestionsComp>
          </div>
          <AudioComp></AudioComp>
          <ParagraphComp></ParagraphComp>
          <h5>Your Answer</h5>
          <AudioComp></AudioComp>
          <ScoreComp></ScoreComp>
        </div>
        
      </Block>
    </Layout>
  );
}

export default ReTellLecComp;
