import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function RadioSelectionComp() {

  return (
    <div className='p-3 answer-checkbox'>
      <Form.Check type="radio" name="radio-check" id="radio-A" label="The chemical formula of DNA" />

      <Form.Check type="radio" name="radio-check" id="radio-B" label="The common fiber axis" />

      <Form.Check type="radio" name="radio-check" id="radio-C" label="The X-ray evidence" />

      <Form.Check type="radio" checked name="radio-check" id="radio-D" label="DNA consisting of two chains" />
    </div>
  );
}
export default RadioSelectionComp;