import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Modal,
  Form,
  ListGroup,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Nav,
  Table, Tooltip, OverlayTrigger,
  CloseButton,
} from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Icon, Image } from "../../components";
import InstituteDataTable from "../admin/institutepages/InstituteDataTable";
import InstituteData from "../admin/institutepages/InstituteData";
import SubModuleHeadingComp from "./mocktest-components/SubModuleHeadingComp";
import GoToQuestionsComp from "./mocktest-components/GoToQuestionsComp";
import ParagraphComp from "./mocktest-components/ParagraphComp";
import AudioComp from "./mocktest-components/AudioComp";
import DescImgComp from "./mocktest-components/DescImgComp";
import AnswerMarkComp from "./mocktest-components/AnswerMarkComp";
import SelectInputComp from "./mocktest-components/SelectInputComp";
import ScoreComp from "./mocktest-components/ScoreComp";
import MarkSelectionComp from "./mocktest-components/MarkSelectionComp";
import RadioSelectionComp from "./mocktest-components/RadioSelectionComp";
import SampleReOrderComp from "./mocktest-components/SampleReOrderComp";
import AnswerReOrderComp from "./mocktest-components/AnswerReOrderComp";
import SampleCheckboxComp from "./mocktest-components/SampleCheckboxComp";
import AnswerCheckboxComp from "./mocktest-components/AnswerCheckboxComp";
import RadioMarkComp from "./mocktest-components/RadioMarkComp";

function MockTestResult() {
  // const[data,setData] = useState([])
  // const ViewTestColumn = [
  //   {
  //     name: "Section",
  //     selector: (row) => row.section,
  //     cell: (row) => (
  //       <span className="text-middark">{row.section}</span>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "Item",
  //     selector: (row) => row.item,
  //     cell: (row) => (
  //       <span className="text-middark">{row.item}</span>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "My Correctness",
  //     selector: (row) => row.correct,
  //     cell: (row) => (
  //       <span className="text-middark">{row.correct}</span>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "Target",
  //     selector: (row) => row.target,
  //     cell: (row) => (
  //       <span className="text-middark">{row.target}</span>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     name: "status",
  //     selector: (row) => row.status,
  //     cell: (row) => (
  //       <span className={`badge text-bg-${row.status === "Active" ? "success"
  //           : row.status === "Pending" ? "warning"
  //             : row.status === "Inactive" ? "secondary"
  //               : "primary"}`
  //       }>
  //         {row.status ? row.status : 'General'}
  //       </span>
  //     ),
  //     sortable: true,
  //   }
  // ];
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Mock Test Result</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card className="mb-5">
          <Card.Body>
            <div className="d-flex justify-content-between mb-3">
              <div>
                <div class="ml-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <h5>【VIP Question Type Test】RL Test 5B - Score Report</h5>
              <p className="text-middark">Submitted at: 2023-10-10 12:04</p>
            </div>
            <Tab.Container id="speaking-tabs" defaultActiveKey="speaking">
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="speaking"><h3 className="text-primary">Speaking</h3></Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="speaking">
                  {/* <InstituteDataTable tableClassName="data-table-head-light table-responsive" data={data} columns={ViewTestColumn}></InstituteDataTable> */}
                  <Table responsive striped bordered hover className="small">
                    <thead>
                      <tr className="bg-primary">
                        <th className="text-white">Section</th>
                        <th className="text-white">Item</th>
                        <th className="text-white">My Correctness</th>
                        <th className="text-white">Target</th>
                        <th className="text-white">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Speaking</td>
                        <td>RL</td>
                        <td>11.0%</td>
                        <td>Buy VIP to see</td>
                        <td><span className="bg-danger rounded px-3 py-1 text-white"> Bad </span></td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <div className="d-flex justify-content-center mb-3">
              <Button variant="primary" ><Icon name="share"></Icon><span>Share Result</span></Button>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between mb-3">
              <h5>【VIP Question Type Test】RL Test 5B - Answer & Score Info</h5>
            </div>
            <Tab.Container id="speaking-ans" defaultActiveKey="speaking-ans">
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="speaking-ans">Speaking</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="writing-ans">Writing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="reading-ans">Reading</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="listening-ans">Listening</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="speaking-ans">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Read Aloud</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <ParagraphComp></ParagraphComp>
                    <AudioComp></AudioComp>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <AudioComp></AudioComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Repeat Sentence</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Actual Answer</h5>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <AudioComp></AudioComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Describe Image</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <DescImgComp></DescImgComp>
                    <h5>Sample Answer</h5>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <AudioComp></AudioComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Re Tell Lecture</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <AudioComp></AudioComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Answer Short Question</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Your Answer :</h5>
                    <AudioComp></AudioComp>
                    <ScoreComp></ScoreComp>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="writing-ans">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Summarize Written Text</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <ParagraphComp></ParagraphComp>
                    <h5>Sample Answer</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <h5>Your Answer :</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <MarkSelectionComp></MarkSelectionComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Writing Essay</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <h5>Essay Topic or Question</h5>
                    <h5>Sample Answer</h5>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <MarkSelectionComp></MarkSelectionComp>
                    <ScoreComp></ScoreComp>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="reading-ans">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Reading and Writing : Fill In The Blanks</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <ParagraphComp></ParagraphComp>
                    <h5>Sample Answer</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <h5>Your Answer :</h5>
                    <SelectInputComp></SelectInputComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Multiple Choice, Choose Multiple Answer</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <ParagraphComp></ParagraphComp>
                    <h5>Sample Answer</h5>
                    <SampleCheckboxComp></SampleCheckboxComp>
                    <h5>Your Answer :</h5>
                    <AnswerCheckboxComp></AnswerCheckboxComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Re-Order Pragraph</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <SampleReOrderComp></SampleReOrderComp>
                    <h5>Your Answer :</h5>
                    <AnswerReOrderComp></AnswerReOrderComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Reading: Fill In The Blanks</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <h5>Sample Answer</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <h5>Your Answer :</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Multiple Choice, Choose Single Answer</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <ParagraphComp></ParagraphComp>
                    <h5>Sample Answer</h5>
                    <RadioSelectionComp></RadioSelectionComp>
                    <h5>Your Answer :</h5>
                    <RadioSelectionComp></RadioSelectionComp>
                    <ScoreComp></ScoreComp>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="listening-ans">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Summarize Spoken Text</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Listening: Multiple Choice, Choose Multiple Answer</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <SampleCheckboxComp></SampleCheckboxComp>
                    <h5>Your Answer :</h5>
                    <AnswerCheckboxComp></AnswerCheckboxComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Listening: Fill In The Blanks</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <h5>Your Answer :</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Highlight Correct Summary</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <RadioSelectionComp></RadioSelectionComp>
                    <h5>Your Answer :</h5>
                    <RadioMarkComp></RadioMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Listening: Multiple Choice, Choose Single Answer</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <RadioSelectionComp></RadioSelectionComp>
                    <h5>Your Answer :</h5>
                    <RadioMarkComp></RadioMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Highlight Incorrect Words</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <h5>Your Answer :</h5>
                    <AnswerMarkComp></AnswerMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Select Missing Word</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <RadioSelectionComp></RadioSelectionComp>
                    <h5>Your Answer :</h5>
                    <RadioMarkComp></RadioMarkComp>
                    <ScoreComp></ScoreComp>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Write From Dictation</h5>
                      {/* <SubModuleHeadingComp></SubModuleHeadingComp> */}
                      <GoToQuestionsComp></GoToQuestionsComp>
                    </div>
                    <AudioComp></AudioComp>
                    <h5>Sample Answer</h5>
                    <ParagraphComp></ParagraphComp>
                    <h5>Your Answer :</h5>
                    <ParagraphComp></ParagraphComp>
                    <ScoreComp></ScoreComp>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default MockTestResult;
