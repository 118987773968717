import React, { useState, useEffect } from 'react';

const CountdownTimer = ({timer,setTimer,register}) => {
  const [time, setTime] = useState(timer); // 3 minutes = 180 seconds

  useEffect(() => {
    if (time > 0) {
        const timerId=setInterval(()=>{
          setTime((prev)=>prev -1)
        },1000)

        console.log('timerId :>> ', timerId);
    
      // Clear interval on component unmount
      return () => clearInterval(timerId);
    }
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    console.log('remainingSeconds :>> ', remainingSeconds,minutes);
    if(remainingSeconds == 0 && minutes == 0) {
      setTimer(false)
    
    }
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
     {

      
    
    register ?   <p className='text-danger'> Please wait for 2 minutes to resend otp: {formatTime(time)}</p>:
    <p className='text-danger'> Timer: {formatTime(time)}</p>
    
    }
      
    </div>
  );
};

export default CountdownTimer;
