import React, { useState } from 'react';
import { ProgressBar, Spinner, Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ExamHeader from '../../../../layout/exam/Header';
import ExamFooter from '../../../../layout/exam/Footer';
import { Icon } from '../../../../components';
import { Height } from '@mui/icons-material';

function ReTellLectureVideo({data}) {
    const [demoModal, setDemoModal] = useState(false);
    const demoModalClose = () => setDemoModal(false);
    const demoModalShow = () => setDemoModal(true);
    return (
        <>
            <ExamHeader />
            <div className='container my-5 text-secondary'>
                <h3><em>You will hear a lecture. After listening to the lecture, In 10 seconds, Please speak into the microphone and retell what you have just heard from the lecture in your own words. You wil have 40 seconds to give your response.</em></h3>
                <div className='d-flex justify-content-center align-items-center gap-4 my-5'>
                    <video width="500"   controls={false} autoplay>
                        <source src="/videos/trailer_hd.mp4" type="video/mp4" className="exam-img" />
                    </video>
                    <div className='d-flex flex-column w-50 gap-5 justify-content-start'>
                        <div><span className='border border-primary rounded-circle py-1 px-2 me-3'>23</span> The recording will start in 2 3 Seconds.</div>
                        {/* recording */}
                        <div className='d-flex gap-3 justify-content-center align-items-center w-75 exam'>
                            <button className="btn btn-color-danger btn-hover-danger btn-icon px-3">
                                <Icon name="stop-circle" className=" display-1"></Icon>
                            </button>
                            <div className='d-flex flex-column'> <small>00:09</small> <small>Recording</small></div>
                            <ProgressBar  variant="hiprimary" now={25} className='w-100' style={{ height: "25px" }} />
                            <small>00:35</small>
                        </div>
                    </div>
                </div>

                {/* After click on bottom right next button not on this button */}
                <Button variant="primary" onClick={demoModalShow}>
                    Launch demo modal
                </Button>
                <Modal show={demoModal} onHide={demoModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cannot Skip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-secondary d-flex justify-content-center align-items-center'><Icon name="info-fill" className="display-5 me-3"></Icon><span>You need to wait to finish this question's audio before going to the next.</span></Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button size="sm" variant="primary" onClick={demoModalClose}> Close </Button>
                    </Modal.Footer>
                </Modal>
                {/* <p>In classes your teachers will talk about topics that you are studying. The information that they provide will be important for you to know when you take tests. You must be able to take good written notes from what your teachers say.</p> */}
            </div>
            <ExamFooter />
        </>
    )
}

export default ReTellLectureVideo;