import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Select } from "../../../components";
import { useParams } from "react-router-dom";
import Question_Context from "../../../context/questionContext";

function TestTypeSelectBox({ grabNew, data, selectedOptions, updateState }) {
  const { quesData, setQuesData } = useContext(Question_Context);
  const { id } = useParams();
  const [dataaaa, setsssData] = useState([]);

  useEffect(() => {
    if (id) {
      let { isMock, isSection, isQuestion, isPrediction, isPractice } = data;
      let arr = [
        isMock && "isMock",
        isSection && "isSection",
        isQuestion && "isQuestion",
        isPrediction && "isPrediction",
      ];
      setsssData(arr.filter((item) => item != false));
      grabNew(arr.filter((item) => item != false));
    }
  }, [data, id]);

  const changeValue = (e) => {
    const options = e.target.options;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    if (selectedValues.includes("all")) {
      selectedValues.splice(selectedValues.indexOf("all"), 1);
      selectedValues.push("isMock", "isSection", "isQuestion", "isPrediction");
    }
    if (typeof updateState === "function") {
      setsssData(selectedValues);
      setQuesData({ select: selectedValues });
      setQuesData({ ...quesData, select: selectedValues });
      updateState(selectedValues);
    } else {
      updateState(selectedValues);
      setsssData(selectedValues);
      setQuesData({ ...quesData, select: selectedValues });
    }
  };
  // useEffect(()=>{

  //   updateState(dataaaa)
  // },[dataaaa])
  console.log("-------", data);
  return (
    <Form.Group className="form-group">
      <Form.Label>Choose Test Type</Form.Label>
      <div className="form-control-wrap">
        <Select
          id="question_type"
          name="question_type"
          onChange={(e) => changeValue(e)}
          multiple
          removeItemButton
          value={dataaaa}
        >
          <option value="">Choose Test Type</option>
          <option value="isMock">Mock Test</option>
          <option value="isSection">Section Wise Test</option>
          <option value="isQuestion">Question Wise Test</option>
          <option value="isPrediction">Prediction Wise Test</option>
          {/* <option value="isPractice">Practice Test</option> */}
          <option value="all">All</option>
        </Select>
      </div>
    </Form.Group>
  );
}

export default TestTypeSelectBox;
