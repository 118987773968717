import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Layout from '../layout/fullpage';

import { Media, MediaGroup, Image, OverlineTitle, Logo } from '../components';
import ImageSlider from './ImageSlider';
function InstituteRegister() {

    return (
        <Layout title="Register" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                    <Row className="g-0 flex-lg-row-reverse">
                        <Col lg="6">
                            <Card.Body>
                                <div className="nk-block-head text-center">
                                    <div className="nk-block-head-content">
                                        <Image
                                            src="/images/hilingo-logo.png"
                                            alt="logo"
                                            className="my-4 logo-img"
                                        />
                                        <h1 className="nk-block-title mb-1">
                                            Institute Register
                                        </h1>
                                    </div>
                                </div>
                                <Form action="#">
                                    <Row className="gy-3">
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" id="username" placeholder="Enter Institute Name" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control type="email" id="email" placeholder="Enter email address" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" id="password" placeholder="Enter password" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Check
                                                className="form-check-sm"
                                                type="checkbox"
                                                id="agreeCheckbox"
                                                label="I agree to privacy policy & terms"
                                            />
                                        </Col>
                                        <Col className="col-7 mx-auto">
                                            <div className="d-grid">
                                                <Button type="submit">Sign up</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="text-center my-4">
                                    <p className="small">Already have an account? <Link to="/auths/auth-login">Login</Link></p>
                                </div>
                                {/* <div className="my-3 text-center">
                                    <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                </div> */}
                                <Row className="g-2">
                                    <Col className="col-7 mx-auto">
                                        <Button
                                            href="#auth"
                                            variant="outline-light"
                                            className="w-100 btn-primary justify-content-start p-0"
                                        >
                                            <Image
                                                src="/images/icon/d.png"
                                                alt=""
                                                className="icon icon-bg"
                                            />
                                            <span className="fw-medium ms-4 text-white">
                                                Continue with Google
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col className="col-7 mx-auto">
                                        <Button
                                            href="#auth"
                                            variant="outline-light"
                                            className="w-100 btn-primary justify-content-start p-0"
                                        >
                                            <Image
                                                src="/images/icon/b.png"
                                                alt=""
                                                className="icon icon-bg"
                                            />
                                            <span className="fw-medium ms-4 text-white">
                                                Continue with Facebook
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Col>
                        <Col lg="6">
                            <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                                <ImageSlider className="login-slider"></ImageSlider>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Layout>
    )
}

export default InstituteRegister;
