import { Row, Col } from 'react-bootstrap';

function InvalidPage() {
  return (
    <>
      <Row className='g-gs invalid-page'>
      <Col md="12" className='d-flex justify-content-center'><img src='/images/hilingo-logo.png' style={{width:"300px"}}></img></Col>
      <Col md="6" className='d-flex align-content-center justify-content-end mt-0'><video loading="lazy" muted="muted" src="https://cdnl.iconscout.com/lottie/premium/thumb/man-clicking-photo-using-camera-7256600-5907650.mp4" type="video/mp4" autoplay="autoplay" loop="loop"></video></Col>
        <Col md="6" className='d-flex flex-column align-items-start justify-content-center'>          
          <h1 className='mb-3'>Congratulations</h1>
          <h3 className='mb-3'>This domain is available, Book this domain for your Institute</h3>
          <h3 className='mb-3'>Call : +91 911-116-2000</h3>
          <h3 className='mb-3'>Visit Site : <a href='https://hilingo.com/'>www.hilingo.com</a></h3>
          <h3 className='mb-3'>Social Links</h3>
          <div className='d-flex gap-2'>
            <a href='https://www.facebook.com/hilingo.a'><img src='/images/facebook.svg'></img></a>
            <a href='https://www.youtube.com/@HiLingo'><img src='/images/youtube.svg'></img></a>
            <a href='https://twitter.com/hi_lingio'><img src='/images/twitter.svg'></img></a>
            <a href='https://www.instagram.com/hilingo.english/'><img src='/images/instagram.svg'></img></a>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default InvalidPage;
