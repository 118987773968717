// import React from 'react';

// const TicketSvg = ({ fill = '#000000' }) => {
//   return (
//     <svg
//     xmlns='http://www.w3.org/2000/svg'
//     width='128'
//     height='128'

//     viewBox='0 0 128 128'
//   >
//     <path
//       d='M121.5 64c1 0 1.7-.8 1.8-1.7V40.5c0-1-.8-1.7-1.8-1.8h-4.8l-2.1-14.3c-.1-1-1-1.6-2-1.5l-27.1 4h-.1L6.2 38.8s-1.5.3-1.5 1.7v21.7c0 1 .8 1.7 1.7 1.8 4.4 0 7.9 3.5 7.9 7.9s-3.5 7.9-7.9 7.9c-1 0-1.7.8-1.8 1.7v21.7c0 1 .8 1.7 1.8 1.8h115c1 0 1.7-.8 1.8-1.8V81.6c0-1-.8-1.7-1.7-1.8-4.4 0-7.9-3.5-7.9-7.9s3.5-7.9 7.9-7.9zM84.2 30.7l.7 4.7c.1.9.9 1.5 1.7 1.5h.3c1-.1 1.6-1 1.5-2l-.7-4.7 23.7-3.5 1.8 12.1H30.1zm35.6 52.5v18.4H93.9v-4.8c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v4.8h-82V83.2c6.2-1 10.5-6.8 9.5-13-.8-4.9-4.6-8.8-9.5-9.5V42.3h82.1V47c0 1 .8 1.8 1.8 1.8S94 48 94 47v-4.8h25.9v18.4c-6.2 1-10.5 6.8-9.5 13 .6 5 4.4 8.8 9.4 9.6z'
//       data-original='#000000'
//     ></path>
//     <path
//       d='M92.1 74.6c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V76.4c0-1-.8-1.8-1.8-1.8zm0-20.4c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V55.9c0-.9-.8-1.7-1.8-1.7zM27.3 57H49c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7H27.3c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7zm43.4 7.6H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z'
//       data-original='#000000'
//     ></path>
//   </svg>
//   );
// }

// export default TicketSvg;



import React from 'react';

const SupportSvg = ({ fill }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" >
    <path d="M29.9993 24.3692C29.9993 22.5062 28.4891 20.9958 26.6259 20.9958H9.37332C7.51029 20.9958 6 22.5062 6 24.3692V25.2357C6 26.5749 6.47778 27.8702 7.34742 28.8885C9.69687 31.6398 13.2759 32.9976 17.9948 32.9976C22.7127 32.9976 26.2935 31.6403 28.6476 28.89C29.5198 27.8711 29.9993 26.5737 29.9993 25.2323V24.3692ZM9.37332 23.2458H26.6259C27.2463 23.2458 27.7493 23.7488 27.7493 24.3692V25.2323C27.7493 26.0372 27.4617 26.8155 26.9382 27.4269C25.0536 29.6289 22.1022 30.7476 17.9948 30.7476C13.8872 30.7476 10.9384 29.6289 9.05845 27.4274C8.53666 26.8164 8.25 26.0393 8.25 25.2357V24.3692C8.25 23.7488 8.75292 23.2458 9.37332 23.2458ZM25.4948 10.5029C25.4948 6.3608 22.1369 3.00293 17.9948 3.00293C15.519 3.00293 13.3234 4.20251 11.9575 6.05214C11.8513 6.01895 11.7384 6.00107 11.6213 6.00107H7.87464C7.25331 6.00105 6.74964 6.50474 6.74964 7.12607V15.3743C6.74964 17.6525 8.5965 19.4993 10.8747 19.4993H11.2497V19.4928C11.2548 19.4928 11.26 19.4928 11.2651 19.4928C12.0915 19.4928 12.7614 18.8229 12.7614 17.9966C12.7614 17.1702 12.0915 16.5003 11.2651 16.5003C10.7861 16.5003 10.3596 16.7255 10.0857 17.0757C9.44436 16.7778 8.99964 16.128 8.99964 15.3743V14.9985H10.1213C10.6923 14.9985 11.2208 14.8162 11.6516 14.5066C12.9806 16.6077 15.3247 18.003 17.9948 18.003C22.1369 18.003 25.4948 14.6451 25.4948 10.5029ZM10.4963 10.344C10.4952 10.3968 10.4947 10.4498 10.4947 10.5029C10.4947 10.556 10.4952 10.609 10.4963 10.6619V12.3735C10.4963 12.5806 10.3284 12.7485 10.1213 12.7485H8.99964V8.25107H10.4963V10.344ZM12.7463 10.6359V10.37C12.8169 7.53192 15.1397 5.25293 17.9948 5.25293C20.8942 5.25293 23.2448 7.60344 23.2448 10.5029C23.2448 13.4024 20.8942 15.753 17.9948 15.753C15.1397 15.753 12.8169 13.4739 12.7463 10.6359Z" fill={fill}/>
    </svg>
    
   
       
       
     );
}

export default SupportSvg;

