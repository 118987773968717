import { Card, Form, Row, Col, Button, ButtonGroup, Accordion, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TimePicker from '../../../../components/Form/TimePicker';
import Layout from '../../../../layout/default';
import Block from '../../../../components/Block/Block';
import { Icon, Select, QuillMinimal, FileUpload, Tags, ImageUpload } from '../../../../components';

function IeltsListeningTestPaper() {
    return (
        <Layout title="Add Product" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Add IELTS Listening Test Questions</Block.Title>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Block>
                <Form action="#">
                    <Card className="card-gutter-md">
                        <Card.Body>
                        <Row className="g-gs">
                                <Col md="9">
                                    <h5 className="mb-3">Add Questions</h5>
                                </Col>
                                <Col md="3">
                                    <Form.Group className="form-group">
                                        <Form.Label>Choose Test Type </Form.Label>
                                        <div className="form-control-wrap">
                                            <Select multiple removeItemButton>
                                                <option value="">Choose Test Type</option>
                                                <option value="1">Mock Test</option>
                                                <option value="2">Section Wise Test</option>
                                                <option value="3">Question Wise Test</option>
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-gs">
                                <Col md="9">
                                    <Row className="g-gs">
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="instruction">Add Instruction</Form.Label>
                                                <div className="form-control-wrap">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        Read the text below and answer question 1-10
                                                    </p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="addaudio">Add Audio</Form.Label>
                                                <div className="form-control-wrap">
                                                    <FileUpload iconName="upload" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="addparagraph">Add Paragraph</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control as="textarea" placeholder="Add Paragraph" id="addparagraph" rows="5"></Form.Control>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                    <h5 className="mb-3">Add Heading Blank Input Field</h5>
                                    <div className='d-flex gap-3 flex-wrap'>
                                        <Form.Group className="form-group">
                                            <div className="form-control-wrap">
                                                <Form.Control style={{ width: "150px" }} type="text" id="fillinblank" placeholder="Enter Text" />
                                            </div>
                                        </Form.Group>
                                        <Button variant="outline-primary" style={{ width: "50px" }}><Icon name="plus"></Icon></Button>
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <h5 className="mb-3">Add Number Input Field</h5>
                                    <div className='d-flex gap-3 flex-wrap'>
                                        <Form.Group className="form-group">
                                            <div className="form-control-wrap">
                                                <Form.Control style={{ width: "80px" }} type="number" id="fillinblank1" placeholder="Enter Numbers" />
                                            </div>
                                        </Form.Group>
                                        <Button variant="outline-primary" style={{ width: "50px" }}><Icon name="plus"></Icon></Button>
                                    </div>
                                </Col>
                                    </Row>
                                </Col>
                                
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="card-gutter-md my-5">
                        <Card.Body>
                            <h5 className="mb-3">Add Questions (Part 2)</h5>
                            <Row className="g-gs">
                                <Col md="9">
                                    <Row className="g-gs">
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="instruction">Add Instruction</Form.Label>
                                                <div className="form-control-wrap">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        Read the text below and answer question 11-20
                                                    </p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="addaudio1">Add Audio/Video</Form.Label>
                                                <div className="form-control-wrap">
                                                    <FileUpload iconName="upload" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="addparagraph1">Add Paragraph</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control as="textarea" placeholder="Add Paragraph" id="addparagraph1" rows="5"></Form.Control>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="exampleFormControlTextarea8">Add Questions 14-20</Form.Label>
                                                <div className="form-control-wrap text-middark">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        The text has seven section. Choose the correct heading for each section and move it into the gap.
                                                    </p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <h5 className="mb-3">Add Heading Blank Input Field</h5>
                                            <div className='d-flex flex-column gap-3'>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control style={{ width: "100%" }} type="text" id="fillinblank2" placeholder="Enter Text" />
                                                    </div>
                                                </Form.Group>
                                                <Button variant="outline-primary" style={{ width: "50px" }}><Icon name="plus"></Icon></Button>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <h5 className="mb-3">Add Number Input Field</h5>
                                            <div className='d-flex gap-3 flex-wrap'>
                                                <Form.Group className="form-group">
                                                    <div className="form-control-wrap">
                                                        <Form.Control style={{ width: "80px" }} type="number" id="fillinblank3" placeholder="Enter Numbers" />
                                                    </div>
                                                </Form.Group>
                                                <Button variant="outline-primary" style={{ width: "50px" }}><Icon name="plus"></Icon></Button>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="exampleFormControlTextarea8">Add Questions 21-26</Form.Label>
                                                <div className="form-control-wrap text-middark">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        Choose True if the statement agrees with the information given in the text. choose False if the statement contradicts the information. or choose Not Given if there is no information on this.
                                                    </p>
                                                </div>
                                                <div className="form-control-wrap">
                                                    <Accordion className='my-3' defaultActiveKey={['0']} alwaysOpen>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-6" id="radio-1" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-6" id="radio-2" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-6" id="radio-3" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-7" id="radio-4" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-7" id="radio-5" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-7" id="radio-6" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-8" id="radio-7" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-8" id="radio-8" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-8" id="radio-9" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-9" id="radio-10" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-9" id="radio-11" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-9" id="radio-12" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-10" id="radio-13" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-10" id="radio-14" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-10" id="radio-15" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="5">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-11" id="radio-16" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-11" id="radio-17" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-11" id="radio-18" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>

                    <Card className="card-gutter-md my-5">
                        <Card.Body>
                            <h5 className="mb-3">Add Questions (Part 3)</h5>
                            <Row className="g-gs">
                                <Col md="9">
                                    <Row className="g-gs">
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="instruction">Add Instruction</Form.Label>
                                                <div className="form-control-wrap">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        Read the text below and answer questions 27-40
                                                    </p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="addparagraph2">Add Paragraph</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control as="textarea" placeholder="Add Paragraph" id="addparagraph2" rows="5"></Form.Control>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <h5 className="mb-3">Questions 27-32</h5>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="instruction">Add Instruction</Form.Label>
                                                <div className="form-control-wrap text-middark">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        The text has seven paragraphs (A - G) which paragraph contains the following information?
                                                    </p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <h5 className="mb-3">Add Table Blank Input Field</h5>
                                            <Form.Group className="form-group">
                                                <div className="form-control-wrap">
                                                    <p>ADD DATA HERE</p>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                            <h5 className="mb-3">Questions 33-36</h5>
                                                <Form.Label htmlFor="instruction">Add Instruction</Form.Label>
                                                <div className="form-control-wrap text-middark">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        Choose Yes if the statement agrees with the claims of the writer of the text. choose No if the statement contradicts the claims of the writer, or choose Not Given if it is impossible to say what the writer thinks about this.
                                                    </p>
                                                </div>
                                                <div className="form-control-wrap">
                                                    <Accordion className='my-3' defaultActiveKey={['0']} alwaysOpen>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-12" id="radio-37" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-12" id="radio-38" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-12" id="radio-39" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-13" id="radio-40" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-13" id="radio-41" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-13" id="radio-42" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-14" id="radio-43" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-14" id="radio-44" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-14" id="radio-45" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-15" id="radio-46" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-15" id="radio-47" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-15" id="radio-48" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-16" id="radio-49" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-16" id="radio-50" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-16" id="radio-51" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="5">
                                                            <Accordion.Header>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Check type="radio" name="firstRadioButton-17" id="radio-52" label="True" />
                                                                <Form.Check type="radio" name="firstRadioButton-17" id="radio-53" label="False" />
                                                                <Form.Check type="radio" name="firstRadioButton-17" id="radio-54" label="Not Given" />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <h5 className="mb-3">Questions 37-38 and 39-40</h5>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="exampleFormControlTextarea8">Add Instruction (37-38)</Form.Label>
                                                <div className="form-control-wrap text-middark">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        The list below gives some positive benefits of good information design which TWO of these benefits are mentioned by the writer in paragraph C?
                                                    </p>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group my-2">
                                                <Form.Label htmlFor="exampleFormControlTextarea8">Add Multiple Answer Option</Form.Label>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>A. The lower paper price brings higher sales volumes to paper mills.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-1" />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>B. Many paper mill bosses are still hesitant about turning the machines off.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-2" />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>C. Paper mills are now reducing the newsprint production.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-3" />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>D. Many paper mills have started to use machines to make packaging.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-4" />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="exampleFormControlTextarea8">Add Instruction (39-40)</Form.Label>
                                                <div className="form-control-wrap text-middark">
                                                    <p className='border border-1 rounded p-3 text-middark my-3'>
                                                        The list below gives some positive benefits of good information design which TWO of these benefits are mentioned by the writer in paragraph C?
                                                    </p>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group my-2">
                                                <Form.Label htmlFor="exampleFormControlTextarea8">Add Multiple Answer Option</Form.Label>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>A. The lower paper price brings higher sales volumes to paper mills.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-5" />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>B. Many paper mill bosses are still hesitant about turning the machines off.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-6" />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>C. Paper mills are now reducing the newsprint production.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-7" />
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-1 gap-md-3 align-items-center my-2'>
                                                    <div className='p-3 text-middark border rounded d-flex gap-2 align-items-baseline w-75'><Icon name="square"></Icon><span>D. Many paper mills have started to use machines to make packaging.</span></div>
                                                    <div>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="plus-circle" variant="primary"></Icon>
                                                        </Button>
                                                        <Button className="btn-icon" variant="outline-transparent">
                                                            <Icon name="minus-circle" variant="danger"></Icon>
                                                        </Button>
                                                    </div>
                                                    <div className='border rounded px-2 py-1'>
                                                        <Form.Check type="checkbox" name="firstCheckboxButton" id="checkbox-8" />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="12" className='d-flex justify-content-between'>
                                            <a href="/questions/add-questions" className='btn btn-outline-primary fw-bold'><Icon name="arrow-left" className='me-2'></Icon> Previous </a>
                                            <Button className='fw-bold'>Submit <Icon name="arrow-right" className='ms-2'></Icon></Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Form>
            </Block>

        </Layout>
    )
}

export default IeltsListeningTestPaper;