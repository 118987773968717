import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Icon, Logo } from "../../components";
import Layout from "../../layout/fullpage";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthApi from "../../api/auth";
import Context from "../../context";
import PhoneFlag from "../../components/Form/PhoneFlag";
import CountdownTimer from "../../components/Form/CountdownTimer";
import SubmitButton from "../questions/PTE/SubmitButton";

const StudentRegisterPage = () => {
  const [sliderImages, setSliderImages] = useState([
    {
      url: "/images/login-tree.svg",
    },
    {
      url: "/images/login-tree.svg",
    },
    {
      url: "/images/login-tree.svg",
    },
  ]);

  useEffect(() => {
    document.documentElement.style.setProperty("--theme-color", "#1682FB");
  }, []);

  function isMobile() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  let searchIsMobileDevice = isMobile();
  console.log("searchIsMobileDevice :>> ", searchIsMobileDevice);

  const [data, setData] = useState({
    view_password: {
      new: false,
    },

    inst_id: "66960463adfb936bb83591c0",

    batchTime: "66977a0f4ebb73ff12aaa6c7",

    branch_id: "66960463adfb936bb83591c2",
    stu_profile: {
      inst_id: "66960463adfb936bb83591c0",
    },
    createdBy: "app",
    login_type: 2,
    external_student: 1,
    roleId: "64b26c44a4437fc38fcb3379",
    permission_routes: ["all"],
    device_type: searchIsMobileDevice ? "mobile" : "desktop",
    mockID: ["6 - 10 Mock Test"],
    sectionID: ["6 - 10 Section Wise Test"],
  });
  const [isLoading, setIsLoading] = useState(false);

  let api = new AuthApi();

  const instDomain = JSON.parse(localStorage?.getItem("instDomainHilingo"));
  console.log("faf", data);

  let navigate = useNavigate();
  const [error, setError] = useState();
  console.log(error,"error34234")
  const submitHandler = async () => {
    // e.preventDefault();
    // const isValid = validateForm(data);
    // if(isValid){

    setIsLoading(true);
    data.country_code = countryNumber;
    data.confirmPass = data.password;
    try {
      // const res = await api.external_student_register(data);
      const res = await api.student_register(data);
      console.log("res344", res);

      if (res?.status) {
        Swal.fire({
          icon: "success",
          text: "Registration Success",
        });
        navigate("/auths/student-login");
        setData({});
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res?.msg,
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    } finally {
      setIsLoading(false);
    }

    // }
  };

  const validateForm = (data) => {
    const newErrors = {};
    console.log("data.name=", data);
    if (!data?.name?.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(data.name)) {
      newErrors.name =
        "Name should be 3-16 characters and shouldn't include any special character!";
    }
    if (!data?.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
        data.email
      )
    ) {
      newErrors.email = "Email must be valid address eg:me@mydomain.com";
    }
    if (!data?.password?.trim()) {
      newErrors.password = "Password is required";
    } else if (!/^.{6,}$/.test(data.password)) {
      newErrors.password = "Password should be greater than 5 digits";
    }

    if (!data?.product?.trim()) {
      newErrors.product = "Product is required";
    }

    setError(newErrors);
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };

  const handleChange = (e,type) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });

    if (name == "product") {
      setError((prev) => ({ ...prev, [e.target.name]: "" }));
    }
    console.log("31", data?.mobile?.length);
    if (name == "mobile" && data?.mobile?.length > 10) {
      console.log("32", data?.mobile?.length);
      setDisableContinue(false);
    }
    if(type == "secondPageEmail"){
      setModifyEmail(true)
    
    }
    if(type == "secondPageMobile"){
      setModifyMobile(true)
    }
  };
 
  const handleInput = (e) => {
    let isValid = false;
    console.log(e.target.name, "dfdsaf");
    const { name, value } = e.target;
    //  const isValid = validateForm();
    if (name === "email") {
      isValid = validateEmail(value);
    } else if (name === "password") {
      isValid = validatePassword(value);
    } else if (name === "name") {
      isValid = validateName(value);
    }

    if (isValid) {
      setError((prev) => ({ ...prev, [e.target.name]: "" }));
    }

    // const isValid =validateForm({ ...data, [e.target.name]: e.target.value })
    // if(isValid){

    //   setError((prev)=>({...prev,[e.target.name]:""}))
    // }
  };
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState(null);
  const [VisibleDiv, setVisibleDiv] = useState(false);
  const [timer, setTimer] = useState(false);

  const [otpModalText, setoptModalText] = useState("");
  console.log(otpModalText, "otpModalText");

  async function generateRandomNumber(type,emailSendPage) {
    const isValid = validateForm(data);
    if (isValid && !emailExist) {
      // setoptModalText(type);
      setLoading(true);
      const min = 100000;
      const max = 999999;
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      console.log("randomNumber", randomNumber);
      await api
        .send_email_otp({
          request_id: randomNumber,
          name: data?.name,
          email: data?.email,

          type: "update_inst_mobile",
        })
        .then((res) => {
          console.log("otpres", res);
          if (res.status) {
            Swal.fire({
              icon: "send",
              title: res.msg,
            });
            setEnableEmail(true)
            if(emailSendPage =="secondPageEmail"){

              setModifyEmail(false)
              // setModifyMobile(false)
            }
         
            // Clear the input fields and reset the ref array
            inputRefs.current.forEach((input) => {
          
              if (input) input.value = "";
            });
            // inputRefs.current = [];
            setLoading(false);
            // setDemoModal(false)
            if (type == "mobile") {
              setOtpModal(true);
            } else if (type == "email") {
              setOtpModal(true);
            }
            // setOtpModal(true)
            // setDemoModal(false);

            setOtp(randomNumber);
            setVisibleDiv(true);
            // setTimer(false);
            setTimer(true);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
            if (
              res.message == "OTP attempts exceeded. Try Again After 3 minutes."
            ) {
              // setTimer(true)
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const [emailExist, setEmailExits] = useState(false);
  function isEmail(text) {
    var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    return text?.match(emailPattern);
  }

  const checkEmail = async () => {
    if (isEmail(data?.email)) {
      await api
        // .checkEmail_Mobile_external_stu({ email: data?.email })
        .checkEmail_Mobile_external_stu({ email: data?.email })
        .then((res) => {
          console.log("response email", res);
          if (res?.email) {
            setEmailExits(true);
          } else {
            setEmailExits(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  console.log(emailExist, "emailExist");
  useEffect(() => {
    checkEmail();
    // setEmailExits(null);
  }, [data?.email]);

  console.log(error?.name, "newErrorserror");
  const inputRefs = useRef([]);
  const [mobileOtpModalError, setMobileOtpModalError] = useState("");
  const [enableMobile, setEnableMobile] = useState(true);
  const [enableEmail, setEnableEmail] = useState(true);
  const [modifyEmail, setModifyEmail] = useState(false);
  const [modifyMobile, setModifyMobile] = useState(false);
  const [tempOtpValue, setTempOtpValue] = useState("");
  const [demoModalEmail, setDemoModalEmail] = useState(false);
  console.log(demoModalEmail, "setDemoModalEmail");
  const [otpValue, setOtpValue] = useState(new Array(6).fill(""));

  console.log(inputRefs, "inputRefs43");
  const handleChangeotp = (e, index) => {
    const value = e.target.value;

    // Update the specific index with the new value
    const newOtp = [...otpValue];
    console.log("newOtp :>>2 ", newOtp);
    newOtp[index] = value;
    console.log("newOtp :>> ", newOtp);
    // Update the OTP state

    // setOtpValue(newOtp?.join(''));
    setOtpValue(newOtp);

    // Log the updated OTP value
    // console.log("value34", newOtp.join('')?.length==6);
    if (newOtp.join("")?.length == 6) {
      setDisableContinue(false);
      setDisableContinueMob(false);
    } else {
      setDisableContinue(true);
      setDisableContinueMob(true);
    }
    // Convert the OTP to a number if all digits are entered
    // const otpNumber = newOtp.join('');
    // if (/^[0-9]{6}$/.test(otpNumber)) {
    //     console.log("OTP Number:", parseInt(otpNumber, 10));
    // }

    if (/^[0-9]$/.test(value)) {
      // Only accept a single digit and move to the next input if available
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      // Clear the input if it's not a single digit
      e.target.value = "";
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      // Move to the previous input on Backspace if current input is empty
      inputRefs.current[index - 1].focus();
    }
  };
  const [disableConstinue, setDisableContinue] = useState(true);
  const [disableConstinueLast, setDisableContinueLast] = useState(false);
  const [disableConstinueMob, setDisableContinueMob] = useState(true);
  const [mobileExist, setMobileExits] = useState(false);
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    console.log(pastedData, "pastedData");

    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      setDisableContinue(false);
      for (let i = 0; i < 6; i++) {
        inputRefs.current[i].value = pastedData[i];
      }
      setTempOtpValue(pastedData);
    }
  };

  useEffect(() => {
    // Update the main OTP state whenever the temporary OTP state changes
    setTempOtpValue(otpValue?.join(""));
  }, [otpValue]);

  const validatePassword = (password) => {
    if (!password?.trim()) {
      setError((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    } else if (!/^.{6,}$/.test(password)) {
      setError((prev) => ({
        ...prev,
        password: "Password should be greater than 5 digits",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, password: "" }));
      return true;
    }
  };

  const validateEmail = (email) => {
    if (!email?.trim()) {
      setError((prev) => ({ ...prev, email: "Email is required" }));
      return false;
    } else if (
      !/^([a-z\d._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(email)
    ) {
      setError((prev) => ({
        ...prev,
        email: "Email must be a valid address, e.g: me@mydomain.com",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, email: "" }));
      return true;
    }
  };

  const validateName = (name) => {
    if (!name?.trim()) {
      setError((prev) => ({ ...prev, name: "Name is required" }));
      return false;
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(name)) {
      setError((prev) => ({
        ...prev,
        name: "Name should be 3-16 characters and shouldn't include any special character!",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, name: "" }));
      return true;
    }
  };
  const checkMobile = async () => {
    if (data.mobile?.length == 10) {
      await api
        // .checkEmail_Mobile_external_stu({ mobile: data?.mobile })
        .checkEmail_Mobile_external_stu({ mobile: data?.mobile })
        .then((res) => {
          console.log("response mobile", res);
          if (res?.mobile) {
            setMobileExits(true);
            setDisableContinue(true);
          } else {
            setMobileExits(false);
            setDisableContinue(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    checkMobile();
  });

  console.log(disableConstinue, "disableConstinue", disableConstinueLast);
  const otpModalClose = () => setOtpModal(false);
  const [verified, setVerified] = useState(false);
  const [mobileModalResend, setMobileModalResend] = useState(false);

  const [otpModalMobile, setOtpModalMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryNumber, setCountryNumber] = useState("91");
  const resendOtpHandler = () => {
    // setOtpModal(false);
    // setMobileModal(false);
    setOtpModalMobile(false);
  };

  const verifyOtp = async () => {
    setLoading(true);
    if (otpValue.length == 6) {
      await api
        .validate_email_otp({
          request_id: otp,
          otp: tempOtpValue,
          email: data.email,
        })
        .then((res) => {
          console.log("res", res);
          setLoading(false)
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.msg,
            });
            // setCountStep(countStep + 1);
            // setDisable(!nextDisable);

            setDisableContinue(true);
            setDemoModalEmail(true);
            setOtpModal(false);

            setVerified(true);
            setVisibleDiv(false);
            // setCallUpdate(true)
            setMobileModalResend(false);
            if (otpModalText == "email") {
            }
            // submitForm();
            // setMobileModal(true)
            // submitForm();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setMobileOtpModalError("");
  };

  let { view_password } = data;

  useEffect(() => {
    console.log(data?.mobile?.length, "43432df");
    if (data?.mobile?.length < 10) {
      setDisableContinueLast(true);
    }
    if (data?.mobile?.length == 10) {
      setDisableContinueLast(false);
    }
  }, [data?.mobile]);
  async function generateRandomNumberMobile(type,emailSendPage) {
    setoptModalText(type);
    setLoading(true);
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .send_mobile_otp({
        request_id: randomNumber,
        name: data?.name,
        mobile: data?.mobile,
        institute_id: instDomain?._id,
        country_code: countryNumber,

        type: "enquiry_form",
      })
      .then((res) => {
        console.log("otpres", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });


          if(emailSendPage =="secondPageMobile"){

            setModifyMobile(false)
          }
          inputRefs.current.forEach((input) => {
            if (input) input.value = "";
          });
          setDisableContinue(true);
          setLoading(false);
          // setDemoModal(false)
          if (type == "mobile") {
            setOtpModal(false);
            setOtpModalMobile(true);
          } else if (type == "email") {
            setDemoModalEmail(false);
            setOtpModalMobile(true);
          }
          // setOtpModal(true)
          // setDemoModal(false);

          setOtp(randomNumber);
          setVisibleDiv(true);
          // setTimer(false);
          setTimer(true);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
          if (
            res.message == "OTP attempts exceeded. Try Again After 3 minutes."
          ) {
            // setTimer(true)
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const emailRef = useRef(null);
  const mobileRef = useRef(null);

  console.log(data?.mobile?.length > 9, "data?.mobile?.length>=9");
  const verifyOtpMobile = async () => {
    if (otpValue.length == 6 && disableConstinueLast == false) {
      await api
        .validate_mobile_otp({
          request_id: otp,
          otp: tempOtpValue,
          mobile: data.mobile,
        })
        .then((res) => {
          console.log("res", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.msg,
            });

         
            // setCountStep(countStep + 1);
            // setDisable(!nextDisable);
            setVerified(true);
            setVisibleDiv(false);
            // setCallUpdate(true)
            // setMobileModalResend(false);
            if (otpModalText == "mobile")
              // submitForm();
              setOtpModalMobile(false);
            if (otpModalText == "email") {
              setMobileModalResend(true);
              submitHandler();
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Layout title="Login" centered>
        {/* <Modal show={otpModalMobile}>
                        <Modal.Header closeButton>
                       
                          <Modal.Title>{otpModalText == "mobile"?"Change Mobile Number":"Change Email Address"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                            {otpModalText == "mobile"
                              ? "changing mobile number requires you to enter OTP send to your updated mobile"
                              : "changing email address requires you to enter OTP registerd mobile number."}
                          </p>
                          <div className="bg-hisuccess rounded p-3 text-white my-3">
                            OTP sent successfully.
                          </div>
                        
                          <div className="d-flex gap-3 my-3">
                            {[...Array(6)].map((_, index) => (
                              <Form.Control
                                key={index}
                                type="text"
                                maxLength={1}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onChange={(e) => handleChangeotp(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputMode="numeric"
                                onPaste={index === 0 ? handlePaste : undefined}  
                              />
                            ))}
                          </div>
                          <p className="text-center text-secondary my-3">
                           
                            Did not receive?{" "}
                            <span
                              className="text-info "
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                generateRandomNumberMobile("email");
                              }}
                            >
                              Resend
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            onClick={verifyOtpMobile}
                          >
                            continue
                          </Button>
                        </Modal.Body>



                      </Modal> */}

        {/*   <Modal
                        show={demoModalEmail}
                  
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Change Email Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                          Click on GET OTP button to receive an OTP on your registered mobile number.
                          </p>
                          <Form.Control
                          
                            type="tel"
                            maxLength={10}
                            id="phone"
                            name="mobile"
                            placeholder="Mobile No."
                            // value={data.phone}
                            value={data?.mobile}
                            onChange={(e) => handleChange(e)}
                            onInput={handlePhoneInput}
                            isInvalid={!!mobileOtpModalError}
                          />
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            disabled={loading}
                            // onClick={submitForm}
                            onClick={() => {
                              generateRandomNumberMobile("email");
                            }}
                          >
                           Get OTP
                          </Button>
                        </Modal.Body>
                      </Modal> */}

        {/* <Modal show={otpModal} onHide={otpModalClose}>
                        <Modal.Header closeButton>
                         
                          <Modal.Title>
                            {otpModalText == "mobile"
                              ? "Change Mobile Number"
                              : "Change Email Address"}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                            {otpModalText == "mobile"
                              ? "changing mobile number requires you to enter OTP sent over to your registerd email address."
                              : "changing email address requires you to enter OTP send over updated email."}
                          </p>
                          <div className="bg-hisuccess rounded p-3 text-white my-3">
                            OTP sent successfully.
                          </div>
                        
                          <div className="d-flex gap-3 my-3">
                            {[...Array(6)].map((_, index) => (
                              <Form.Control
                                key={index}
                                type="text"
                                maxLength={1}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onChange={(e) => handleChangeotp(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputMode="numeric"
                                onPaste={index === 0 ? handlePaste : undefined}  
                              />
                            ))}
                          </div>
                          <p className="text-center text-secondary my-3">
                        
                            Did not receive?{" "}
                            <span
                              className="text-info "
                              style={{ cursor: "pointer" }}
                              onClick={resendOtpHandler}
                            >
                              Resend
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            onClick={verifyOtp}
                          >
                            continue
                          </Button>
                        </Modal.Body>



                      </Modal> */}
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
              <Col lg="6">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <div className="login-logo">
                        <Logo />
                      </div>
                      <h1 className="nk-block-title mb-1">Create an Account</h1>
                    </div>
                  </div>
                  <Form action="#" onSubmit={(e) => submitHandler(e)}>
                    {otpModal === false &&
                    demoModalEmail == false &&
                    otpModalMobile == false ? (
                      <Row className="gy-3">
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <div className="form-control-wrap">
                              <Form.Select
                                id="selectproduct"
                                aria-label="Default select example"
                                name="product"
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!error?.product}
                              >
                                <option value="">Select Product</option>
                                <option value="PTE">PTE</option>
                                {/* <option value="IELTS">IELTS</option>
            <option value="TOFEL">TOFEL</option>
            <option value="CELPIP">CELPIP</option> */}
                              </Form.Select>

                              <Form.Control.Feedback type="invalid">
                                {error?.product}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col className="col-12">
                          <Form.Group className="form-group required">
                            <div className="form-control-wrap form-item">
                            <div className="form-control-icon start">
                      
                                <Icon name="user-alt"></Icon>
                              </div>

                              <Form.Control
                                required
                                type="text"
                                id="name"
                                name="name"
                                // placeholder="Name"
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!error?.name}
                                onInput={handleInput}
                              />
                              <Form.Label>Name</Form.Label>
                              <Form.Control.Feedback type="invalid">
                                {error?.name}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>

                       

                        <Col className="col-12">
                          <Form.Group className="form-group required">
                            <div className="form-control-wrap form-item">
                              <div className="form-control-icon start">
                              <Icon name="mail"></Icon>
                              </div>
                              <Form.Control
                                required
                                id="email"
                                name="email"
                                // placeholder="Email"
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!error?.email}
                                autoComplete="off"
                                onInput={handleInput}
                              />

                              <Form.Label>Email</Form.Label>
                              <Form.Control.Feedback type="invalid">
                                {error?.email}
                              </Form.Control.Feedback>
                            </div>

                            {emailExist && (
                              <small className="text-danger ms-1">
                                Email already exist
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="col-12">
                          <Form.Group className="form-group required">
                            <div className="form-control-wrap form-item">
                              <div
                                style={{ cursor: "pointer" }}
                                className=" form-control-icon end text-middark"
                                onClick={() =>
                                  setData({
                                    ...data,
                                    view_password: { new: !view_password.new },
                                  })
                                }
                              >
                                <Icon name="eye" className="me-4"></Icon>
                              </div>
                              <div className="form-control-icon start">
                              <Icon name="lock-alt"></Icon>
                              </div>
                              <Form.Control
                                required
                                type={!view_password.new ? "password" : "text"}
                                id="password"
                                name="password"
                                // placeholder="Password"
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!error?.password}
                                onInput={handleInput}
                              />
                              <Form.Label>Password</Form.Label>
                              <Form.Control.Feedback type="invalid">
                                {error?.password}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col className="col-12 text-center">
                          <small className="text-secondary">
                            By creating this account, you agree to our privacy
                            policy and terms of use.
                          </small>
                        </Col>
                        <Col className="col-7 mx-auto text-center">
                          <div className="d-grid">
                            {loading ? (
                              <Button variant="primary" disabled>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="ms-1">Loading...</span>
                              </Button>
                            ) : (
                              <Button
                                className="form-button"
                                onClick={() => {
                                  generateRandomNumber("mobile");
                                }}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                          <div className="text-center my-4">
                            <p className="small">
                              Already have an account?{" "}
                              <Link to="/auths/student-login">Login</Link>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    ) : otpModal === true ? (
                      <>
                        <div className="bg-hisuccess rounded p-3 text-white my-3">
                          OTP has been sent to your email.
                        </div>
                        <Form.Group className="form-group required">
                          <div className="form-control-wrap">
                          <InputGroup>
                            <Form.Control
                              id="email"
                              name="email"
                              placeholder="Email"
                              onChange={(e) => handleChange(e,"secondPageEmail")}
                              isInvalid={!!error?.email}
                              autoComplete="off"
                              disabled={enableMobile}
                              onInput={handleInput}
                              value={data?.email}
                              ref={emailRef} // Attach the ref here
                            />
                                  <InputGroup.Text id="basic-addon2">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>{

                                      setEnableMobile(false)
                                      setEnableEmail(false)
                                      setTimeout(() => {
                                        if (emailRef.current) {
                                          emailRef.current.focus();
                                        
                                        }
                                      }, 0); 
                                    }
                                    }
                                    name="edit"
                                  ></Icon>
                                </InputGroup.Text>
                                </InputGroup>
                            <Form.Control.Feedback type="invalid">
                              {error?.email}
                            </Form.Control.Feedback>
                          </div>

                          {emailExist && (
                              <small className="text-danger ms-1">
                                Email already exist
                              </small>
                            )}
                        </Form.Group>
                        {
                          enableEmail && 
                     
                        <div className="d-flex gap-3 my-3">
                          {[...Array(6)].map((_, index) => (
                            <Form.Control
                              key={index}
                              type="text"
                              maxLength={1}
                              ref={(el) => (inputRefs.current[index] = el)}
                              onChange={(e) => handleChangeotp(e, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              inputMode="numeric"
                              onPaste={index === 0 ? handlePaste : undefined}
                            />
                          ))}
                        </div>
                        
                      }
                        {!timer || !enableEmail && (

                  modifyEmail ?
                  <p className="text-center text-secondary my-3">

{loading ? 
                              <Button variant="primary"   size="sm"disabled>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="ms-1">Loading...</span>
                              </Button>:
                  <Button
                 
                  size="sm"
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    generateRandomNumber("mobile","secondPageEmail");
                    setEnableMobile(true)
                  }}
           
                >
                Get Otp
                </Button>
}
                </p>
                 :
                          <p className="text-center text-secondary my-3">
                            Did not receive?{" "}
                            <span
                              className="text-info"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                generateRandomNumber("mobile");
                                setEnableMobile(true)
                              }}
                            >
                              Resend
                            </span>
                          </p>
                        )}
                      
                    

                        {timer && enableEmail ? (
                           <p className="text-center text-secondary my-3">
                          <CountdownTimer
                            timer={120}
                            setTimer={setTimer}
                            register={true}
                          />
                          </p>
                        ) : (
                          ""
                        )}
                        {
                          !modifyEmail && 
                       
                        <p className="text-center text-secondary my-3">

                        {loading ? 
                              <Button variant="primary" disabled>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="ms-1">Loading...</span>
                              </Button> :
                        <Button
                          variant="primary"
                          className="mx-auto my-3"
                          disabled={disableConstinue}
                          onClick={verifyOtp}
                        >
                          Continue
                        </Button>
                      } 
                        </p>
}
                      </>
                    ) : demoModalEmail === true ? (
                      <>
                        <div className="d-flex gap-2">
                          <Col className="col-4">
                            <PhoneFlag setCountryNumber={setCountryNumber} />
                          </Col>
                          <Col className="col-8">
                            <Form.Control
                              type="tel"
                              maxLength={10}
                              id="phone"
                              name="mobile"
                              placeholder="Mobile No."
                              value={data?.mobile}
                              onChange={(e) => handleChange(e)}
                              onInput={handlePhoneInput}
                              isInvalid={!!mobileOtpModalError}
                            />
                          </Col>
                        </div>
                        <Col className="col-6">
                          <div></div>
                        </Col>
                        <Col className="col-12 ">
                          {mobileExist && (
                            <small className="text-danger ms-1 ">
                              Mobile number already exist
                            </small>
                          )}
                        </Col>

                        <Button
                          variant="primary"
                          className="mx-auto my-3"
                          // disabled={loading}
                          onClick={() => {
                            generateRandomNumberMobile("email");
                          }}
                          disabled={disableConstinue}
                        >
                          Get OTP
                        </Button>
                      </>
                    ) : (
                      otpModalMobile == true && (
                        <>
                          {" "}
                          <div className="bg-hisuccess rounded p-3 text-white my-3">
                            OTP has been sent to your whatsapp.
                          </div>
                          <Form.Group className="form-group required">
                            <div className="form-control-wrap">
                              <InputGroup>
                                <Form.Control
                                  type="tel"
                                  maxLength={10}
                                  id="phone"
                                  name="mobile"
                                  placeholder="Mobile No."
                                  value={data?.mobile}
                                  onChange={(e) => handleChange(e,"secondPageMobile")}
                             
                                  disabled={enableEmail}
                                  onInput={handlePhoneInput}
                                  isInvalid={!!mobileOtpModalError}
                              
                                  ref={mobileRef} 
                                />
                                <InputGroup.Text id="basic-addon2">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>{

                                      setEnableEmail(false)
                                      
                                      setTimeout(() => {
                                        if (mobileRef.current) {
                                          mobileRef.current.focus();
                                        }
                                        
                                        },0)}
                                    }
                                    name="edit"
                                  ></Icon>
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                          </Form.Group>
                          {disableConstinueLast ? (
                            <small className="text-danger ms-1">
                              Enter correct Mobile number
                            </small>
                          ) : (
                            mobileExist && (
                              <small className="text-danger ms-1 ">
                                Mobile number already exist
                              </small>
                            )
                          )}
                            {
                          enableEmail && 
                          <div className="d-flex gap-3 my-3">
                            {[...Array(6)].map((_, index) => (
                              <Form.Control
                                key={index}
                                type="text"
                                name="mobileOtp"
                                maxLength={1}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onChange={(e) => handleChangeotp(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputMode="numeric"
                                onPaste={index === 0 ? handlePaste : undefined}
                              />
                            ))}
                          </div>
}
                          {!timer || !enableEmail && (




modifyMobile ?
<p className="text-center text-secondary my-3">

{loading ? 
            <Button variant="primary"   size="sm"disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="ms-1">Loading...</span>
            </Button>:
<Button

size="sm"
className=""
style={{ cursor: "pointer" }}
onClick={() => {
  generateRandomNumberMobile("email","secondPageMobile");
  setEnableEmail(true)
}}

>
Get Otp
</Button>
}
</p>:
                            <p className="text-center text-secondary my-3">
                              {/* Did not receive? <a href="">Resend</a> */}
                              Did not receive?{" "}
                              <span
                                className="text-info "
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  generateRandomNumberMobile("email");
                                }}
                              >
                                Resend
                              </span>
                            </p>
                          )}


                          {timer && enableEmail  ? (
                              <p className="text-center text-secondary my-3">
                            <CountdownTimer
                              timer={120}
                              setTimer={setTimer}
                              register={true}
                            />
                          </p>
                          )
                          : (
                            ""
                          )}


              {   
                          !modifyMobile && 
                       
                           <p className="text-center text-secondary my-3">
                          
                          {loading ? 
                              <Button variant="primary" disabled>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="ms-1">Loading...</span>
                              </Button> :
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            onClick={verifyOtpMobile}
                            disabled={disableConstinueMob}
                          >
                            Continue
                          </Button>

}
                          </p>

              }
                        </>
                      )
                    )}

                    {/* <StudentRegisterEmailOtp />
                    <StudentRegisterMobileOtp /> */}
                  </Form>
                </Card.Body>
              </Col>
              <Col lg="6">
                <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                  <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                    <Carousel
                      bgColor="#00000000"
                      width={500}
                      height={200}
                      showBullets={true}
                      autoPlay={true}
                      images={sliderImages}
                      autoPlayDelay={3000}
                      onStartSlide={(index, length) => {
                        // You can use index to track the current slide
                      }}
                      // Auto play delay in milliseconds
                    >
                      {sliderImages.map((item, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block slider-img"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            src={item.url}
                            alt={`Slide ${index}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Card.Body>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default StudentRegisterPage;
