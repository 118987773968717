// import React from 'react';

// const TicketSvg = ({ fill = '#000000' }) => {
//   return (
//     <svg
//     xmlns='http://www.w3.org/2000/svg'
//     width='128'
//     height='128'

//     viewBox='0 0 128 128'
//   >
//     <path
//       d='M121.5 64c1 0 1.7-.8 1.8-1.7V40.5c0-1-.8-1.7-1.8-1.8h-4.8l-2.1-14.3c-.1-1-1-1.6-2-1.5l-27.1 4h-.1L6.2 38.8s-1.5.3-1.5 1.7v21.7c0 1 .8 1.7 1.7 1.8 4.4 0 7.9 3.5 7.9 7.9s-3.5 7.9-7.9 7.9c-1 0-1.7.8-1.8 1.7v21.7c0 1 .8 1.7 1.8 1.8h115c1 0 1.7-.8 1.8-1.8V81.6c0-1-.8-1.7-1.7-1.8-4.4 0-7.9-3.5-7.9-7.9s3.5-7.9 7.9-7.9zM84.2 30.7l.7 4.7c.1.9.9 1.5 1.7 1.5h.3c1-.1 1.6-1 1.5-2l-.7-4.7 23.7-3.5 1.8 12.1H30.1zm35.6 52.5v18.4H93.9v-4.8c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v4.8h-82V83.2c6.2-1 10.5-6.8 9.5-13-.8-4.9-4.6-8.8-9.5-9.5V42.3h82.1V47c0 1 .8 1.8 1.8 1.8S94 48 94 47v-4.8h25.9v18.4c-6.2 1-10.5 6.8-9.5 13 .6 5 4.4 8.8 9.4 9.6z'
//       data-original='#000000'
//     ></path>
//     <path
//       d='M92.1 74.6c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V76.4c0-1-.8-1.8-1.8-1.8zm0-20.4c-1 0-1.7.8-1.8 1.7v11.6c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8V55.9c0-.9-.8-1.7-1.8-1.7zM27.3 57H49c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7H27.3c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7zm43.4 7.6H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8zm0 11.1H27.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h43.4c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8z'
//       data-original='#000000'
//     ></path>
//   </svg>
//   );
// }

// export default TicketSvg;



import React from 'react';

const MaterialSvg = ({ fill }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" >
<path d="M30.038 18.2883C29.8154 18.6444 29.5205 18.9498 29.1725 19.1849L20.0977 25.3104C18.8299 26.1662 17.1694 26.1662 15.9016 25.3104L6.82677 19.1849C5.68528 18.4143 5.24263 16.9959 5.65807 15.7505L15.9194 22.5897C17.1004 23.3771 18.6164 23.4263 19.8392 22.7373L20.0797 22.5897L30.3403 15.7497C30.6128 16.5689 30.5314 17.499 30.038 18.2883ZM30.3403 20.6247C30.6128 21.4439 30.5314 22.374 30.038 23.1633C29.8154 23.5194 29.5205 23.8248 29.1725 24.0599L20.0977 30.1854C18.8299 31.0412 17.1694 31.0412 15.9016 30.1854L6.82677 24.0599C5.68528 23.2893 5.24263 21.8709 5.65807 20.6255L15.9194 27.4647C17.1004 28.2521 18.6164 28.3013 19.8392 27.6123L20.0797 27.4647L30.3403 20.6247ZM20.0797 5.13714L29.6275 11.5023C30.3169 11.9618 30.503 12.8931 30.0436 13.5824C29.9336 13.7472 29.7923 13.8886 29.6275 13.9985L20.0797 20.3637C18.8201 21.2034 17.1791 21.2034 15.9194 20.3637L6.37174 13.9985C5.68245 13.5389 5.49619 12.6076 5.95572 11.9183C6.06558 11.7536 6.20697 11.6122 6.37174 11.5023L15.9194 5.13714C17.1791 4.29738 18.8201 4.29738 20.0797 5.13714ZM17.3408 6.90972L17.1676 7.00925L8.55714 12.7504L17.1676 18.4916C17.6155 18.7901 18.1838 18.8232 18.6584 18.591L18.8317 18.4916L27.4406 12.7504L18.8317 7.00925C18.3838 6.71067 17.8154 6.67749 17.3408 6.90972Z" fill={fill}/>
</svg>

    
    
  );
}

export default MaterialSvg;

