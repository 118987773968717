import { useEffect, useState } from 'react';
import { DragDropBasic } from '../../components/Kanban/DragDrop';
import Board from 'react-trello'




function DragDropBoth({data, readAloudAnswer}) {
  // kanban data
  console.log("datadatadata",data)
  
  const [eventBus, setEventBus] = useState(undefined);
  const [objs,setObjs] = useState([]);
  
  // const temp = data?.question_options?.length ? data?.question_options.map((item,i)=>(
  //   objs.push({id:`Card${i}`,title:" ",description: item?.options, draggable:true})
    
  // )):[]
  
    useEffect(() => {
      const temp2 = []
      const temp = data?.question_options?.length ? data?.question_options.map((item,i)=>(
        temp2.push({id:`Card${i}`,title:" ",description: item?.options, draggable:true})
      )):[]
      setObjs(temp2)
    },[data])

    useEffect(() => {
      setData1({
        lanes: [
          {
            id: 'lane1',
            cards: objs,
            hideCardDeleteIcon:true,
            style:{height:"750px", width:"40%" },
            
            
          },
          {
            id: 'lane3',
            cards: [],
            style:{backgroundColor:'white', border:'none', width:"10%"}
          },
          {
            id: 'lane2',
            cards: [],
            style:{marginLeft:'0',height:"750px", width:"40%"}
          }
          ,
          {
            id: 'lane4',
            cards: [],
            style:{backgroundColor:'white', border:'none', width:"10%"}
          }
        ]
      })
    },[objs])
    
  const [data1, setData1] = useState({
    lanes: [
      {
        id: 'lane1',
        cards: objs,
        hideCardDeleteIcon:true,
        style:{height:"750px", },
      },
      {
        id: 'lane3',
        cards: [],
        style:{backgroundColor:'white', border:'none'}
        
      },
      {
        id: 'lane2',
        cards: [],
        style:{marginLeft:'5rem',height:"750px"}
      }
      ,
      {
        id: 'lane4',
        cards: [],
        style:{backgroundColor:'white', border:'none'}
      }
    ]
  });

  
  function removeColorWhenDragged(){
    const allElements = document.getElementsByClassName("smooth-dnd-draggable-wrapper");
      for(var i=0;i<allElements.length;i++){
        console.log("allElemts -> ",allElements[i].children);
        allElements[i].children[0].children[1].style.color = "black";
        allElements[i].children[0].style.backgroundColor = "white";
      }
  }

  function shouldReceiveNewData(nextData){
    setData1(nextData)
  }

  function shouldHandleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails){
    console.log("TARGETLANEID",targetLaneId);
    
    if (targetLaneId == 'lane3' || targetLaneId == 'lane4') {
      // Prevent movement to lane3
      return false;
    }
    removeColorWhenDragged();
  }

  function shouldHandleDragStart(cardId,laneId){
    const ele = document.querySelectorAll(`[data-id=${cardId}]`)[0]
    // ele.classList.add("background-color: #7fffd4,border: 1px dashed #a5916c, transform: rotate(2deg)")
    // const ele = document.querySelectorAll(`[data-id=${cardId}]`)[0].style.backgroundColor="ivory"
  }
  
  let currentLaneId = "";
  let currentCardId = "";
  function shouldCardClick(cardId, metadata, laneId){
    currentLaneId = laneId
    currentCardId = cardId
    removeColorWhenDragged();
    const ele = document.querySelectorAll(`[data-id=${currentCardId}]`)[0]
    ele.style.backgroundColor="var(--theme-color)"
    ele.children[0].style.display="none"
    ele.children[1].style.color="white"
    console.log("ele clicked",ele);
    console.log("ele inner",ele.children[1]);
    console.log(laneId);
  }

  // const onCardAddHandler = (card, laneId) => {
  //   eventBus.publish({
  //     type: 'ADD_CARD', //use this type instead of ADD_CARD
  //     laneId:laneId,
  //     card: {
  //       id: card.id,
  //       title: card.title,
  //       label: "1 sec",
  //       description: card.description
  //     }
  //   });
  // }

  const onCardMoveHandler = (fromLaneId,cardId, toLaneId, index) => {
    eventBus.publish({
      type: 'MOVE_CARD',
      fromLaneId:fromLaneId,
      toLaneId:toLaneId,
      index:index,
      cardId:cardId
    });
    removeColorWhenDragged();
  }

  function handleMoveRight(e){
    e.preventDefault();

    currentLaneId == "lane1" && onCardMoveHandler("lane1",currentCardId, "lane2", data1.lanes[2].cards.length)
  }

  function handleMoveLeft(e){
    e.preventDefault();

    currentLaneId == "lane2" && onCardMoveHandler("lane2",currentCardId, "lane1",data1.lanes[0].cards.length)

  }

  function handleMoveUp(e){
    e.preventDefault();

    console.log(currentCardId);
    const dataFromlaneTwo = data1.lanes[2].cards;
    let indexToMove = -1;
    for(var i=0;i<dataFromlaneTwo.length;i++){
      if(dataFromlaneTwo[i].id == currentCardId){
        indexToMove = i-1;
        break;
      }
    }
    console.log("indexToMove",indexToMove);
    if(indexToMove>=0){
      onCardMoveHandler("lane2",currentCardId, "lane2", indexToMove)
    }
  }

  function handleMoveDown(e){
    e.preventDefault();

    console.log(currentCardId);
    const dataFromlaneTwo = data1.lanes[2].cards;
    let indexToMove = -1;
    for(var i=0;i<dataFromlaneTwo.length;i++){
      if(dataFromlaneTwo[i].id == currentCardId){
        indexToMove = i+1;
        break;
      }
    }
    console.log("indexToMove",indexToMove);
    if(indexToMove>=0){
      onCardMoveHandler("lane2",currentCardId, "lane2", indexToMove)
    }

  }
  
  useEffect(() => {
    let deleteIcon = document?.getElementsByClassName("sc-gGfPwF bMksiG");
    if(deleteIcon.length>0){
      for(var i=0;i<deleteIcon.length;i++){
        deleteIcon[i].style.display = "none"
      }
    }
    

    let forWidth = document?.getElementsByClassName("smooth-dnd-container vertical");
  
    // console.log("temp",temp);
    let horizontalIcon = document?.getElementById("horizontalIcon");
    let verticalIcon = document?.getElementById("verticalIcon")
    console.log("horizontalIcon",horizontalIcon);
    console.log("verticalIcon",verticalIcon);

  
     
    let button = document.getElementById("verticalnext") 


    button.onclick((e) => {
      
      handleMoveUp(e)
    })
    
    if(forWidth.length > 0){
      forWidth[0].style.height = "600px";
      forWidth[2].style.height = "600px";
      const forFont =  document?.getElementsByClassName("sc-jgjOva ffqGoc")
      for(var i=0;i<forFont.length;i++){
        forFont[i].style.fontSize = "15px"
      }
     
    }

 

    
  },[data1,data])

  return (
    <>
     
      
       <div id="horizontalIcon" style={{marginLeft:"41%" , position:"absolute", zIndex:"1", marginTop:"20rem"}} >
        <button onClick={(e)=>handleMoveLeft(e)} style={{cursor:"pointer",marginRight:"2px"}}><img src="/images/exam-left-arrow.svg"></img></button>
        
        <button onClick={(e)=>handleMoveRight(e)} style={{cursor:"pointer"}}><img src="/images/exam-right-arrow.svg"></img></button>
      </div>


      <div id="verticalIcon" style={{marginLeft:"90%", position:"absolute", zIndex:"1",marginTop:"20rem"}} >
        <button onClick={(e)=>handleMoveUp(e)} id="verticalprev" style={{cursor:"pointer",marginRight:"3px", }}><img src="/images/exam-top-arrow.svg"></img></button>

        <button onClick={(e)=>handleMoveDown(e)} id="verticalnext" style={{cursor:"pointer",marginRight:"3px", }}><img src="/images/exam-bottom-arrow.svg"></img></button>
      </div>


       <Board data={data1} eventBusHandle={setEventBus} style={{height:"836px", backgroundColor:"white",zIndex:"-1" }} handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) => shouldHandleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails)}  onDataChange={shouldReceiveNewData} onCardClick={shouldCardClick} />
  


      {console.log("HELLOOO DATA")}
      {console.log("DATATATA", data1)}
    </>
  )
}

export default DragDropBoth;