
import { Card, Row, Col, Form } from 'react-bootstrap';
import InstituteDataTable from './admin/institutepages/InstituteDataTable';
import Layout from '../layout/default';
import { useContext, useEffect, useState } from 'react';
import AuthApi from '../api/auth';
import ModalContext from '../context/ModalContext';
import Context from '../context';
import { Icon, LinkList } from '../components';
import StudentTicket from './tickets/StudentTicket';
function ViewTickets() {
    let { userData } = useContext(Context);
    userData = JSON.parse(userData)
    const [data, setData] = useState([]);
    let api = new AuthApi();

    const [followupIdPayload, setFollowupIdPayload] = useState();
    const [singleFollowup, setSingleFollowup] = useState({});
    const editHandler = (id) => {
        console.log(id, "abcEdit");
    
        setFollowupIdPayload(id);
      };

      
      console.log("data--",data)
    const InstituteColumns = [
        {
            name: "Sno",
            grow: "5",
            selector: (row, index) => row.sno,
            cell: (row, index) => (
                <span className="text-middark">{index + 1}</span>
            ),
            sortable: true,
        },
        {
            name: "Subject",
            grow: "5",
            selector: (row) => row?.id,
            cell: (row) => (
                <span className="text-middark">{row?.subject}</span>
            ),
            sortable: true,
        },
        {
            name: "Department",
            grow: "5",
            selector: (row) => row?.tag,
            cell: (row) => (
                <span className="text-middark">{row?.department}</span>
            ),
            sortable: true,
        },
        {
            name: "Message",
            grow: "5",
            selector: (row) => row.type,
            cell: (row) => (
                <span className="text-middark">{row.message}</span>
            ),
            sortable: true,
        },

        {
            name: "Status",
            grow: "5",
            selector: (row) => row.status,
            cell: (row) => (
                <span className="text-middark">{row.status}</span>
            ),

            cell: (row) => (
                // <span className={`badge text-bg-${row.status === "Active" ? "success"
                //     : row.status === "Inactive" ? "danger"
                //         : "primary"}`
                // }>
                <span className={`badge text-bg-${row.status == "pending" || row.status == "reopen" ? "danger"
                    : "success"}`
                }>
                    {row.status == "pending" || row.status == "reopen" ? "Pending" : 'Complete'}
                </span>
            ),

            sortable: true,
        },

        {
            name: "Action",
            cell: (row) => (
              <div className="text-end w-100">
                <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
               
                    <div
                      className="del-btn"
                      onClick={() => editHandler(row?._id)}
                    >
                      {/* <Icon name="user-add"></Icon> */}
                      <Icon name="edit"></Icon>
                    </div>
                  
                  </LinkList>
                  </div>
            )
          }
    ];
    useEffect(() => {
        get_support_enquiry()
    }, [])
    const get_support_enquiry = async () => {
        await api.get_support_enquiry_student(userData?._id,"STUDENT").then((res) => {
            if (res.status) {
                setData(res.data)
            }
        })
    }



    
  const get_Single_followup = async () => {
    console.log("inside single floowup effect", followupIdPayload);
    await api
      .get_single_support_enquiry( followupIdPayload)
      .then((res) => {
        if (res?.data?.length > 0) {
          setSingleFollowup(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (followupIdPayload) {
      get_Single_followup();
    }
  }, [followupIdPayload]);


    return (
        <Layout title="Add Student" content="container">

{Object.keys(singleFollowup).length > 0 && (
                  <StudentTicket
                    // isFollowup={isFollowup}
                    // setisFollowup={(data) => setisFollowup(data)}
                    // get_all_followup_list={()=>get_all_followup_list()}
                    singleFollowup={singleFollowup}
                    setSingleFollowup={(data) => setSingleFollowup(data)}
                    setFollowupIdPayload={(data) => setFollowupIdPayload(data)}
                    get_Single_followup={() => get_Single_followup()}
                    get_support_enquiry={() => get_support_enquiry()}
                  />
                )}

            <h1 className='mb-5'>View Ticket</h1>
            <Row className="g-gs">
                <Col md="12">
                    <Card>
                        <Card.Body>
                            <InstituteDataTable tableClassName="data-table-head-light table-responsive" data={data} columns={InstituteColumns}></InstituteDataTable>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default ViewTickets;
