import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import Layout from "../layout/fullpage";
// import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../components/Form/DropDown";
import AuthApi from "../api/auth";
import DatePickerOnChange from "../components/Form/DatePickerOnChange";
import { Image } from "../components";
import Context from "../context";
import InstBranchesApi from "../api/branches/branches";
import PhoneFlag from "../components/Form/PhoneFlag";
import CountdownTimer from "../components/Form/CountdownTimer";

const EnquiryRequest = ({
  setShow,
  show,
  isEdit,
  setIsEdit,
  get_enquiriesArr,
}) => {
  // localStorage.clear("userHilingotoken");
  let navigate = useNavigate();

  console.log(setShow, "setShow123");
  let api = new AuthApi();
  let instBranchesApi = new InstBranchesApi();
  const [teamMember, setTeamMember] = useState([]);

  let { domainData, token } = useContext(Context);
  domainData = JSON.parse(domainData);
  console.log(domainData?.enquiry_otp_deduction, "domainData");
  console.log(domainData?._token, "userData12");

  const getTeamByInstituteId = async (instId) => {
    console.log("instid", instId, domainData?._token);
    await api
      .get_teachers_By_Inst({ id: instId })
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setTeamMember(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (domainData?._id && domainData?._token) {
      getTeamByInstituteId(domainData?._id);
    }
  }, []);

  const [state, setState] = useState({
    data: {
      name: "",
      email: "",
      phone: "",
      enquiry: "",
      gender: "",
    },
    errors: {},
  });
  let DropDownWidth = { width: "100%" };

  const { data, errors } = state;
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...prev.errors,
        [e.target.name]: "",
      },
    }));

    if (name == "mobile") {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          country_code: countryNumber,
          ["type"]: "enquiry_form",
        },
      }));
    }
  };

  const teachers = teamMember?.map((item) => item?.name.toLowerCase());

  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.name);
    if (!data?.name?.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(data.name)) {
      newErrors.name =
        "Name should be 3-16 characters and shouldn't include any special character!";
    }
    if (!data?.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
        data.email
      )
    ) {
      newErrors.email = "Email must be valid address eg: me@mydomain.com";
    }

    // if (data.email &&
    //   !/^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
    //     data.email
    //   )
    // ) {
    //   newErrors.email = "Email must be valid address eg: me@mydomain.com";
    // }

    if (!data?.mobile?.trim()) {
      newErrors.mobile = "Phone is required";
    } else if (data.mobile.length !== 10) {
      newErrors.mobile = "Phone number must be 10 digits";
    }
    // if (!data?.enquiry?.trim()) {
    //   newErrors.enquiry = "Enquiry is required";
    // }
    // if (!data?.mode) {
    //   newErrors.mode = "Mode is required";
    // }
    if (!data?.program) {
      newErrors.program = "Program is required";
    }

    // if (!data?.otp) {
    //   newErrors.otp = "Otp is required";
    // }

    // if (!data?.gender?.trim()) {
    //   newErrors.gender = "Gender is required";
    // }
    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      setState({
        data: {
          name: "",
          email: "",
          mobile: "",
          enquiry: "",
          gender: "",
          program: "",
          reference: "",
          mode: "",
          nextFollowUpDate: "",
          teamMember: "",
        },
        errors: {},
      });

      console.log(data, "data##");
      await api
        .add_enquiry(data)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            setShow(false);

            // get_enquiriesArr()
            // navigate("/lead-management/enquiry-list");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };

  const [countryNumber, setCountryNumber] = useState("91");

  const [VisibleDiv, setVisibleDiv] = useState(false);
  // const [loading,setLoading] = useState(false);
  const [timer,setTimer] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState(null);
  const [optError, setOptError] = useState("");
  async function generateRandomNumber() {
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);

    await api
      .send_mobile_otp({
        mobile: data?.mobile,
        request_id: randomNumber,
        name: data.name,
        email: data.email,
        institute_id:domainData?._id,
        type : "enquiry_form",
        country_code:countryNumber
      })
      .then((res) => {
        console.log("otpres", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });
          setOtp(randomNumber);
          setVisibleDiv(true);
          // setLoading(false)
          setTimer(false)

        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.message,
          });
          if(res.message == "OTP attempts exceeded. Try Again After 3 minutes."){

            setTimer(true)
          }
          // setLoading(false)
        }


      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [countStep, setCountStep] = useState(1);
  const [nextDisable, setDisable] = useState(true);

  const verifyOtp = async () => {
    await api
      .validate_mobile_otp({ request_id: otp, otp: data.otp, name: data.name })
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: res.msg,
          });
          setCountStep(countStep + 1);
          setDisable(!nextDisable);
          setVerified(true);
          setVisibleDiv(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(" value={data?.program} :>> ", data);

  let { userData } = useContext(Context);
  userData = JSON.parse(userData);

  console.log('userData :>> ', userData);

  return (
    <>
      <Layout title="Register" centered>
        <div className="container p-2 p-sm-4">
          <Row className="g-2 justify-content-center">
            <Col md="8" xs="12">
              <div className="nk-block-head text-center">
                <div className="nk-block-head-content">
                  <Image
                    src={domainData?.inst_profile?.business_logo}
                    alt="logo"
                    className="logo-img w-25 h-25"
                  />
                </div>
              </div>

              <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                <Card.Body>
                  <Form action="#">
                    <Row className="g-gs ">
                      <Row className="gy-3">
                        <h2 className="text-center text-secondary  mt-0">
                          Add Enquiry
                        </h2>
                      </Row>

                      <Col md="6">
                        <Form.Group className="form-group my-3  required">
                          <Form.Label htmlFor="title" className="control-label">
                            Student Name{" "}
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="name"
                              placeholder="Student Name"
                              name="name"
                              value={data.name}
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <div className="d-flex gap-1">
                          <div className="w-40">
                            <Form.Group className="form-group  required">
                              <Form.Label
                                htmlFor="tag"
                                className="control-label"
                              >
                                Phone Number
                              </Form.Label>
                              <PhoneFlag setCountryNumber={setCountryNumber} />
                            </Form.Group>
                          </div>
                          <div className="w-60">
                            <Form.Group className="form-group mt-3 ">
                              <Form.Label htmlFor="tag"> </Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="tel"
                                  maxLength={10}
                                  id="phone"
                                  name="mobile"
                                  disabled={verified }
                                  placeholder="Phone Number"
                                  // value={data.phone}
                                  value={
                                    data.mobile?.length < 11 ? data.mobile : ""
                                  }
                                  onChange={(e) => handleChange(e)}
                                  isInvalid={!!errors.mobile}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.mobile}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        {domainData?.enquiry_otp_deduction && (
                          <>
                            <div className="d-flex gap-1">
                              <div className="w-60"></div>
                              <div className="w-40">
                            


{
  verified ?
                                <Button
                                variant="outline-primary"
                                className={`border ${verified ? `border-success` :`border-primary`} border-2 fw-bold mt-2 w-100`}
                                disabled={verified }
                                onClick={() => {
                                    if (
                                      data?.mobile?.length == 10 &&
                                      data?.email?.length &&
                                      data?.name
                                    ) {
                                      generateRandomNumber();
                                      setOptError("");
                                    } else {
                                      setOptError(
                                        "Please enter name,email and valid mobile number to send otp"
                                      );
                                    }
                                  }}
                                  >
                                  
                         <span className="text-success"> Verified</span> 
                        
                                </Button>:
                                <>
<Button
variant="outline-primary"
className={`border ${verified ? `border-success` :`border-primary`} border-2 fw-bold mt-2 w-100`}
disabled={ timer}
onClick={() => {
  if (
    data?.mobile?.length == 10 &&
    data?.email?.length &&
    data?.name
  ) {
    // setLoading(true)
    generateRandomNumber();
    setOptError("");
  } else {
    setOptError(
      "Please enter name,email and valid mobile number to send otp"
    );
  }
}}
>
{
 VisibleDiv ? "Resend OTP" : "Send OTP"
}
</Button>

  {timer ? <CountdownTimer timer={180} setTimer={setTimer}/> :""}


</>

                            }


                            
                            
                              </div>
                              {optError != "" && (
                                <div className="invalid-formupload-feedback mt-1">
                                  {optError}
                                </div>
                              )}
                            </div>

                            <div className="invalid-formupload-feedback mt-1">
                              {errors.otp}
                            </div>
                          </>
                        )}



                        <Row className="mt-2">
                          <Col
                            className="col-lg-6"
                            style={{ display: VisibleDiv ? "block" : "none" }}
                          >
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="number"
                                  name="otp"
                                  value={data?.otp?.length < 7 && data.otp}
                                  onChange={(e) => handleChange(e)}
                                  id="exampleFormControlInputText1"
                                  placeholder="Enter OTP"
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col
                            className="col-lg-6 "
                            style={{ display: VisibleDiv ? "block" : "none" }}
                          >
                            <Button
                              variant="outline-primary"
                              className="border border-primary border-2 fw-bold"
                              onClick={verifyOtp}
                              style={{ width: "120px" }}
                            >
                                {verified ?  "Verified":"Verify OTP"}
                            </Button>
                          </Col>
                        </Row>

                        
          <div className="d-sm-block d-md-none">{/* Hide on md and larger screens */} 
            <Form.Group className="form-group required my-3">
              <Form.Label htmlFor="email" className="control-label">
                Email Id
              </Form.Label>
              <div className="form-control-wrap">
                <Form.Control
                  type="email"
                  id="emailid"
                  name="email"
                  placeholder="Email Id"
                  value={data.email}
                  onChange={(e) => handleChange(e)}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
       
       
          <div className="d-sm-block d-md-none"> {/* Hide on md and larger screens */}
            <Form.Group className="form-group my-3 required">
              <Form.Label htmlFor="program" className="control-label">
                Select Program
              </Form.Label>
              <div className="form-control-wrap">
                <Form.Select
                  id="program"
                  aria-label="Default select example"
                  onChange={(e) => handleChange(e)}
                  name="program"
                  isInvalid={!!errors.program}
                  value={data?.program}
                >
                  <option value="">Select</option>
                  <option value="Pte">PTE</option>
                  <option value="Pte core">PTE CORE</option>
                  <option value="Ilets">IELTS</option>
                  <option value="Cd Ielts">CD IELTS</option>
                  <option value="Toefl">TOEFL</option>
                  <option value="Cale">CALE</option>
                  <option value="Celpip">CELPIP</option>
                  <option value="Grammer">GRAMMER</option>
                  <option value="Spoken English">SPOKEN ENLGISH</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.program}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
        


                        <Form.Group className="form-group mb-3">
                          <Form.Label htmlFor="mode">Select Mode</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              id="mode"
                              aria-label="Default select example"
                              onChange={(e) => handleChange(e)}
                              name="mode"
                              isInvalid={!!errors.mode}
                              value={data?.mode}
                            >
                              <option value="">Select</option>
                              <option value="Telephonic">Telephonic</option>
                              <option value="Walk-in">Walk-in</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                              {errors.mode}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group my-3">
                          <Form.Label htmlFor="gender">
                            Select Gender{" "}
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              required
                              id="gender"
                              aria-label="Default select example"
                              onChange={(e) => handleChange(e)}
                              name="gender"
                              isInvalid={!!errors.gender}
                              value={data?.gender}
                            >
                              <option value="">Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.gender}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <DropDown
                          className="w-25 mb-3"
                          data={teachers}
                          defaultSelectedOption={"Select"}
                          text="Team Member Name"
                          name="teamMember"
                          onChange={handleChange}
                          width={DropDownWidth}
                          value={data?.teamMember}
                        />
                      </Col>
                      <Col md="6">


<div className="d-sm-none d-md-block "> {/* Hide on small screens, show on medium and above */}

      <Form.Group className="form-group required my-3">
        <Form.Label htmlFor="email" className="control-label">
          Email Id
        </Form.Label>
        <div className="form-control-wrap">
          <Form.Control
            type="email"
            id="emailid"
            name="email"
            placeholder="Email Id"
            value={data.email}
            onChange={(e) => handleChange(e)}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </div>
      </Form.Group>
      </div>
    
      <div className="d-sm-none d-md-block"> {/* Hide on small screens, show on medium and above */}
      <Form.Group className="form-group my-3 required">
        <Form.Label htmlFor="program" className="control-label">
          Select Program
        </Form.Label>
        <div className="form-control-wrap">
          <Form.Select
            id="program"
            aria-label="Default select example"
            onChange={(e) => handleChange(e)}
            name="program"
            isInvalid={!!errors.program}
            value={data?.program}
          >
            <option value="">Select</option>
            <option value="Pte">PTE</option>
            <option value="Pte core">PTE CORE</option>
            <option value="Ilets">IELTS</option>
            <option value="Cd Ielts">CD IELTS</option>
            <option value="Toefl">TOEFL</option>
            <option value="Cale">CALE</option>
            <option value="Celpip">CELPIP</option>
            <option value="Grammer">GRAMMER</option>
            <option value="Spoken English">SPOKEN ENLGISH</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.program}
          </Form.Control.Feedback>
        </div>
      </Form.Group>
    </div>
                        <Form.Group className="form-group my-3">
                          <Form.Label htmlFor="reference">
                            Select Reference Medium
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              id="reference"
                              aria-label="Default select example"
                              onChange={(e) => handleChange(e)}
                              name="reference"
                              value={data?.reference}
                            >
                              <option value="">Select</option>
                              <option value="Friend Reference">
                                Friend Reference
                              </option>
                              <option value="Direct Phone Call">
                                Direct Phone Call
                              </option>
                              <option value="Direct Walk In">
                                Direct Walk In
                              </option>
                              <option value="Existing Student Reference">
                                Existing Student Reference
                              </option>
                              <option value="Team Memeber Reference">
                                Team Memeber Reference
                              </option>
                              <option value="Advertisement">
                                Advertisement
                              </option>
                              <option value="Newspaper">Newspaper</option>
                              <option value="Instagram">Instagram</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Google">Google</option>
                              <option value="Webstie">Webstie</option>
                            </Form.Select>
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Next FollowUp Date</Form.Label>
                          <div className="form-control-wrap">
                            <DatePickerOnChange
                              onChange={(e) => handleChange(e)}
                              name={"nextFollowUpDate"}
                              value={data?.nextFollowUpDate}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group my-3">
                          <Form.Label htmlFor="tag">Comment</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              as="textarea"
                              placeholder="Enquiry"
                              name="enquiry"
                              id="enquiry"
                              rows="3"
                              value={data.enquiry}
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!errors.enquiry}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.enquiry}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col
                        md="12"
                        className="d-flex justify-content-center"
                      ></Col>

                      <Col className="col-6 mx-auto">
                        <div className="text-center">
                          <Button type="submit" onClick={handleSubmit}>
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Layout>
      {/* <Modal.Footer>
         
          <Button variant="primary" onClick={handleSubmit}>
            Add Equiry
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default EnquiryRequest;
