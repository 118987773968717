import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Pureknob } from "../../../../components";
import { Colors } from "../../../../utilities";
import { format_date } from "../../../../components/FormatDate/formatDate";
function ExamResultTab({
  Col,
  Card,
  Row,
  Tab,
  Block,
  speaking_data,
  reading_data,
  writing_data,
  listening_data,
  overall_result,
  data,
  Navbar,
  userData,
  exam,
}) {
  console.log("exam",data)
  let testName = exam?.test_name ? exam?.test_name : "";
  let format_dates = format_date(data?.createdAt)? format_date(data?.createdAt): "";
  let overall_result_score = overall_result?.length? overall_result[0]?.overall_score: "";

  const [WritingResult, setWritingResult] = useState({
    size: 100,
    value: writing_data?.[0]?.actual_total_contribute_scores,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.hidanger,
    trackWidth: "0.15",
  });

  const [SpeakingResult, setSpeakingResult] = useState({
    size: 100,
    value: 0,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.hisuccess,
    trackWidth: "0.15",
  });

  const [ListeningResult, setListeningResult] = useState({
    size: 100,
    value: 0,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.hiprimary,
    trackWidth: "0.15",
  });

  const [ReadingResult, setReadingResult] = useState({
    size: 100,
    value: 0,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.hiwarning,
    trackWidth: "0.15",
  });
  useEffect(() => {
    setWritingResult({
      ...WritingResult,
      value: writing_data?.[writing_data?.length-1]?.actual_total_contribute_scores,
    });
    setSpeakingResult({
      ...SpeakingResult,
      value: speaking_data?.[speaking_data?.length-1]?.actual_total_contribute_scores,
    });
    setListeningResult({
      ...ListeningResult,
      value: listening_data?.[listening_data?.length-1]?.actual_total_contribute_scores,
    });
    setReadingResult({
      ...ReadingResult,
      value: reading_data?.[reading_data.length-1]?.actual_total_contribute_scores,
    });
  }, [writing_data,speaking_data,listening_data,reading_data]);

  return (
    <>
      <Block>
        <Tab.Container id="pills-result" defaultActiveKey="analytics-speaking">
          <Tab.Content>
            <Tab.Pane eventKey="analytics-speaking">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex flex-column">
                        <div className="bg-primary p-3">
                          <div className="d-flex flex-column">
                            <p className="mb-0 text-white">
                              Test Name : {testName}
                            </p>
                            <p className="text-white">
                              Test Date : {format_dates}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex py-3">
                          <div className="d-flex align-items-center p-1 w-75 border-end">
                            <img
                              src="/images/avatar/avatar-placeholder.jpg"
                              className="rounded-circle border border-3"
                              style={{ width: "80px" }}
                            ></img>
                            <div className="ms-5">
                              <h4>{userData?.name}</h4>
                              <p className="mb-0 text-secondary">
                                <strong>Test Taker ID : </strong> PTE51475144
                              </p>
                              <p className="text-secondary">
                                <strong>Registration ID : </strong> 7171521
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center w-25">
                            <h4>Overall Score</h4>
                            <div
                              className="bg-primary d-flex align-items-center justify-content-center p-3 rounded-circle"
                              style={{ width: "70px", height: "70px" }}
                            >
                              <h3 className="text-white">
                                {overall_result_score}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <h3 className="mt-5"> Communicative Skills</h3>
                        <div className="d-flex align-items-center justify-content-around w-75 mx-auto my-5">
                          <div className="d-flex flex-column align-items-center">
                            <Pureknob
                              data={SpeakingResult}
                              key={SpeakingResult.value}
                              className="nk-chart-knob"
                            ></Pureknob>
                            <strong className="text-hisuccess">Speaking</strong>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <Pureknob
                              data={WritingResult}
                              key={WritingResult.value}
                            ></Pureknob>
                            <strong className="text-danger">Writing</strong>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <Pureknob
                              data={ReadingResult}
                              key={ReadingResult.value}
                            ></Pureknob>
                            <strong className="text-hiwarning">Reading</strong>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <Pureknob
                              data={ListeningResult}
                              key={ListeningResult.value}
                            ></Pureknob>
                            <strong className="text-hiprimary">
                              Listening
                            </strong>
                          </div>
                        </div>

                        <div className="d-flex">
                          <div className="d-flex flex-column w-60">
                            <h3 className="my-5"> Skills Breakdown</h3>
                            <div className="d-flex align-items-center">
                              <strong className="text-secondary w-20">
                                Speaking
                              </strong>
                              <ProgressBar
                                variant="hisuccess"
                                now={SpeakingResult?.value}
                                className="w-50 ms-3"
                                style={{ height: "15px" }}
                              ></ProgressBar>
                            </div>
                            <div className="d-flex align-items-center">
                              <strong className="text-secondary w-20">
                                Writing
                              </strong>
                              <ProgressBar
                                variant="hidanger"
                                now={WritingResult?.value }
                                className="w-50 ms-3"
                                style={{ height: "15px" }}
                              ></ProgressBar>
                            </div>
                            <div className="d-flex align-items-center">
                              <strong className="text-secondary w-20">
                                Reading
                              </strong>
                              <ProgressBar
                                variant="hiwarning"
                                now={ReadingResult?.value}
                                className="w-50 ms-3"
                                style={{ height: "15px" }}
                              ></ProgressBar>
                            </div>
                            <div className="d-flex align-items-center">
                              <strong className="text-secondary w-20">
                                Listening
                              </strong>
                              <ProgressBar
                                variant="hiprimary"
                                now={ListeningResult?.value}
                                className="w-50 ms-3"
                                style={{ height: "15px" }}
                              ></ProgressBar>
                            </div>
                          </div>
                          <div className="d-flex flex-column w-40">
                            <h3 className="my-5"> Candidate Information</h3>
                            <div className="text-secondary">
                              <strong>Date of Birth : </strong> 20 jan 2024
                            </div>
                            <div className="text-secondary">
                              <strong>Gender : </strong> Female
                            </div>
                            <div className="text-secondary">
                              <strong>Country of Citizenship : </strong> India
                            </div>
                            <div className="text-secondary">
                              <strong>Country of Residence : </strong> India
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="results-writing">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>Writing test</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="results-reading">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>Reading test</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="results-listening">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>Listening test</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Block>
    </>
  );
}

export default ExamResultTab;
