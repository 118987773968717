import React, { useEffect, useState } from "react";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import { ProgressBar, Spinner, Button, Form } from "react-bootstrap";
function InputCheckbox({ showAnwer, data, question_content }) {
  let { answerJson, setAnswerJson } = useContext(ModalContext);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [answerString, setAnswerString] = useState("");

  const handleOptionChange = (optionIndex) => {
    console.log("optionIndex", optionIndex);
    if (selectedAnswers.includes(optionIndex)) {
      setSelectedAnswers(
        selectedAnswers.filter((index) => index !== optionIndex)
      );
    } else {
      setSelectedAnswers([...selectedAnswers, optionIndex]);
    }
  };
  useEffect(() => {
    const selectedAnswerArray = data?.map((_, index) =>
      selectedAnswers.includes(index)
    );
    const newAnswerString = selectedAnswerArray
      ?.map((selected) => (selected ? "true" : "false"))
      .join(",");
    setAnswerString(newAnswerString);
  }, [selectedAnswers, data]);
  useEffect(() => {
    if (answerString && selectedAnswers.length) {
      setAnswerJson({ ...answerJson, answer: answerString });
    }
  }, [answerString]);

  console.log("selected", selectedAnswers);
  console.log("dataForTrue",data);
  return (
    <div className="d-flex flex-column">
      <p>{question_content ? question_content : question_content}</p>
      {data?.length > 0 && showAnwer
        ? data?.map((item, index) => {
            return (
              <div
                className={`d-flex gap-1 ${
                  item.answer == true && selectedAnswers.includes(index)
                    ? "hilightSuccessted"
                    : "hilightIncorrect"
                }`}
                key={index}
              >
                <Form.Check
                  type="checkbox"
                  id={index + 1}
                  className={item.answer == true && "hightLiteGray"}
                  label={item.options}
                  onChange={() => handleOptionChange(index)}
                  checked={selectedAnswers.includes(index)}
                />
              </div>
            );
          })
        : data?.map((item, index) => {
            return (
              <div className="d-flex gap-1 hilightcorrect" key={index}>
                <Form.Check
                  type="checkbox"
                  id={index + 1}
                  label={item.options}
                  onChange={() => handleOptionChange(index)}
                  checked={selectedAnswers.includes(index)}
                />
              </div>
            );
          })}
    </div>
  );
}

export default InputCheckbox;
