import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import Question_Context from "../../../../context/questionContext";
import { useState, useContext, useEffect } from "react";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteDescribeImage() {
  let { id } = useParams();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loader, setloader] = useState(false);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "speaking_repeat_sentences",
    instructions:"Look at the graph below. In 25 seconds, please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
    
    question_options: [
      {
        // answer: ["test"],
        answer: [],
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });

  console.log("data", data);
  const handleAdd = (name) => {
    const updatedOptions = [...data.question_options[0].answer];
    updatedOptions.push("");
    const newData = {
      ...data,
      question_options: [
        {
          ...data.question_options[0],
          answer: updatedOptions,
        },
      ],
    };
    setData(newData);
  };
  const trash = (type, index) => {
    if (type === "answer") {
      const updatedOptions = [...data.question_options[0].answer];
      updatedOptions.splice(index, 1);
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            answer: updatedOptions,
          },
        ],
      };
      setData(newData);
    }
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (e, index) => {
    let { name, value } = e.target;
    e.preventDefault();
    // if (name == "answer") {
    //   // const updatedOptions = [...data.question_options[0].answer];
    //   // updatedOptions[index] = value;
    //   const newData = {
    //     ...data,
    //     question_options: [
    //       {
    //         ...data.question_options[0],
    //         // answer: updatedOptions,
    //         answer: [value],
    //       },
    //     ],
    //   };
    //   setData(newData);
    // } else
     if (name == "file") {
      setData({ ...data, ["file"]: e.target.files[0] });
    } else {
      if (name == "question_type") {
        const options = e.target.options;
        const selectedValues = [];
        if (options.length == 0) {
          setSelectedOptions([]);
        }
        if (options.length == 0) {
          setSelectedOptions([]);
        }
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            selectedValues.push(options[i].value);
          }
          setSelectedOptions(selectedValues);
        }
        setData({
          ...data,
          isMock: selectedValues.includes("isMock") ? true : false,
          isPractice: selectedValues.includes("isPractice") ? true : false,
          isSection: selectedValues.includes("isSection") ? true : false,
          isQuestion: selectedValues.includes("isQuestion") ? true : false,
          isPrediction: selectedValues.includes("isPrediction") ? true : false,
        });
      } else {
        setData({ ...data, [name]: value });
      }
    }
  };
  let api = new AdminApi();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(!loader);
    let isImageFile = false;
    if (!data?.question_content_image) {
      if (data?.file?.length > 0 && data.file[0].type.startsWith("image/")) {
        isImageFile = true;
        formObject.append("question_content_image", data.file[0]);
      } else {
        Swal.fire({
          icon: "error",
          title: "File must be an image",
          text: "Please upload an image file.",
        });
        return;
      }
    }
    for (const [key, value] of Object.entries(data)) {
      if (key !== "file" && key !== "question_options") {
        if(value!=null){
          formObject.append(key, value);
        }
      } else if (key === "question_options") {
        value.forEach((option, index) => {
          option.answer.forEach((answer, subIndex) => {
            formObject.append(
              `question_options[${index}][answer][${subIndex}]`,
              answer
            );
          });
        });
      }
    }

    await api
      .add_Question_speaking(formObject)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/view-questions");
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
            // text: "Please upload an audio or video file.",
          });
        }
        setloader(!loader);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err,
        });
        console.log("err", err);
      });
  };

  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      image_type,
      answer,
      question_type,
      module_name,
      module,
      file,
      question,
      answer_time,
      question_begin_time,
      answer_prepare_time,
    } = data;
    return (
      instructions &&
      image_type &&
      (file || data?.question_content_image) &&
      tags &&
            (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      answer_time?.length === 5 &&
      answer_prepare_time?.length === 5
    );
  };
  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);

  useEffect(() => {
    if (id) {
      getQuesById();
      //   if(!data._id){
      //   setData({ ...data, _id: id });
      // }
    }
  }, [id]);
  console.log("selectedOptions", selectedOptions);
  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const types = [
    "Bar",
    "Line",
    "Pie",
    "Flow",
    "Table",
    "Map",
    "Pic",
    "Comb"
  ]
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add PTE Describe Image Questions</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => {
                      setSelectedOptions(e);
                    }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e);
                      setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    {id && (
                      <EditTimesFiled
                        data={data}
                        onDataChange={(name, val) =>
                          setData({ ...data, [name]: val })
                        }
                      />
                    )}

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="instruction"
                          className="ms-3 fw-bold"
                        >
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">
                          Add Image
                        </Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_image &&
                          data?.question_content_image != "null" ? (
                            <>
                              <div className="d-flex gap-2">
                                <img
                                  src={data?.question_content_image}
                                  width="160"
                                  height="155"
                                />
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_image: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                              filetype="image"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                        <Form.Group className="form-group">
        <Form.Label>Type</Form.Label>
        <div className="form-control-wrap ms-1 me-1" style={{ width: "140px" }}>
          <Form.Select
            removeItemButton
            value={data?.image_type}
            name="image_type"
            onChange={(e) => {
              setData({ ...data, image_type: e.target.value });
            }}
          >
            <option value="">All</option>
            {types?.length > 0 &&
              types.map((item) => {
                return (
                  <>
                    <option value={item}>{item}</option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <TranscriptExplanation
                        setState={(name) => setData({ ...data, [name]: "" })}
                        data={data}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Tag (#)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="addtag"
                            value={data.tags}
                            name="tags"
                            onChange={(e) => {
                              var removeHash = e.target.value.replaceAll("#", "");
                              removeHash = "#" + removeHash;
                              setData({ ...data, tags: removeHash });
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg="12">
                  <h5 className="mb-3">Add Sample Sample Field</h5>
                  <div className="d-flex gap-3 flex-wrap" id="blankfields">
                    {data?.question_options[0]?.answer != 0 &&
                      data?.question_options[0].answer?.map((item, i) => {
                        return (
                          <>
                            {" "}
                            <Form.Group className="form-group">
                              <div className="form-control-wrap" key={i}>
                                <Form.Control
                                  style={{ width: "150px" }}
                                  type="text"
                                  onChange={(e) => handleChange(e, i)}
                                  value={item != "test" ? item : null}
                                  name="answer"
                                  placeholder="Enter Text"
                                />
                                {data?.question_options?.[0]?.answer?.length !=
                                  1 && (
                                  <div
                                    className="form-control-icon end"
                                    onClick={() => trash("answer", i)}
                                  >
                                    <Icon variant="danger" name="trash"></Icon>
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          </>
                        );
                      })}
                    <Button
                      variant="outline-primary"
                      name="answer"
                      onClick={() => handleAdd("answer")}
                      style={{ width: "50px" }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </div>
                </Col> */}
                  </Row>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton
                    loader={loader}
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteDescribeImage;
