import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Nav,
  CloseButton,
} from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Image } from "../../components";
import FileUploadNew from "../../components/Form/FileUploadNew";
import { useEffect } from "react";
import Context from "../../context";
import { useContext } from "react";
import AuthApi from "../../api/auth";
import { useState } from "react";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useNavigate } from "react-router-dom";
function InstituteSettings() {
  let api = new AuthApi();
  let { userData, setDomainData, setUserData } = useContext(Context);
  const [data, setData] = useState({ banner_images: [] });
  userData = JSON.parse(userData);
  useEffect(() => {
    if (userData._id) {
      let id = userData._id;
      get_user_by_id(id);
    }
  }, [userData._id]);

  const get_user_by_id = async (id) => {
    await api.get_user_by_id(id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleChange = (e, inst_profile, index, banner) => {
    let { value, name } = e.target;
    console.log("nameee", name);
    console.log("namxxxxeee", value);
    if (name == "inst_business_name") {
      setData((prevData) => ({
        ...prevData,
        ["domain_name"]: value,
      }));
    }

    if (inst_profile) {
      if (name == "business_logo") {
        setData((prevData) => ({
          ...prevData,
          ["logo"]: "",
          ["inst_profile"]: {
            ...data.inst_profile,
            [name]: "",
          },
        }));
      } else {
        if (name == "deletebanner") {
          // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
          setData((prevData) => ({
            ...prevData,
            ["inst_profile"]: {
              ...data.inst_profile,
              ["banner_images"]: data?.inst_profile?.banner_images.filter(
                (item) => item.url != banner
              ),
            },
          }));
        } else if (name == "deletebanner_internal") {
          setData((prevData) => ({
            ...prevData,
            ["inst_profile"]: {
              ...data.inst_profile,
              ["internal_banner_images"]: data?.inst_profile?.internal_banner_images?.filter(
                (item) => item.url != banner
              ),
            },
          }));
        } else {
          if (name == "color_code") {
            setData((prevData) => ({
              ...prevData,
              ["inst_profile"]: {
                ...data.inst_profile,
                [name]: value,
              },
            }));
          } else {
            setData((prevData) => ({
              ...prevData,
              ["inst_profile"]: {
                ...data.inst_profile,
                [name]: "",
              },
            }));
          }
        }
      }
    } else {
      if (name == "logo" || name == "business_certificate") {
        setData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
      } else {
        if (name == "banner_images") {
          let tempArray = [...data?.banner_images];
          tempArray[index] = e.target.files[0];
          setData((prevData) => ({ ...prevData, [name]: tempArray }));
        } else {
          if (name == "domain_name") {
            setData((prevData) => ({
              ...prevData,
              [name]: value?.replace(/\s+/g, " ").trim(),
              ["inst_profile"]: {
                ...data.inst_profile,
                ["inst_business_name"]: value?.replace(/\s+/g, " ").trim(),
              },
            }));
          } else {
            setData((prevData) => ({
              ...prevData,
              [name]: value?.replace(/\s+/g, " ").trim(),
            }));
          }
        }
      }
    }
  };
  // const handleChange = (e) => {
  //   let {name,value} = e.target;
  //   if(name == "deletebanner"){
  //     let copyArray =
  //   }
  // };

  const formDataObject = new FormData();
  let navigate = useNavigate();
  delete data.createdAt
    delete data.otp
    delete data.updatedAt
    delete data.password
    delete data._token
    delete data.permission_routes
  const submitForm = async (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(data)) {
      if (key === "banner_images") {
        for (const imageFile of value) {
          formDataObject.append("banner_images", imageFile);
        }
      }
      else if (key === "internal_banner_images") {
        for (const imageFile of value) {
          formDataObject.append("internal_banner_images", imageFile);
        }
      }
      else if (key === "business_certificate" || key === "logo") {
        if (key === "logo") {
          formDataObject.append(key, value[0]);
        } else {
          formDataObject.append(key, value);
        }
      }
      else {
        if (typeof value === "object" && value !== null) {
          formDataObject.append(key, JSON.stringify(value));
        } else {
          formDataObject.append(key, value);
        }
      }
    }

    await api.institute_register(formDataObject).then((res) => {
      try {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.msg,
          });
          // navigate("/");
          if (res.data.length > 0 && userData.login_type != "0") {
            setUserData(JSON.stringify(res.data[0]));
            localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
            localStorage.setItem(
              "instDomainHilingo",
              JSON.stringify(res.data[0])
            );
            setDomainData(JSON.stringify(res.data[0]));
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const updateBannerImages = (updatedFiles, internal_banner_images) => {
    if (internal_banner_images) {
      if (data?.internal_banner_images?.length) {
        let previousArray = [...data.internal_banner_images];
        let updatedArray = previousArray.concat(updatedFiles);
        setData({
          ...data,
          internal_banner_images: updatedArray,
        });
      } else {
        setData({
          ...data,
          internal_banner_images: updatedFiles,
        });
      }
    } else {
      if (data?.banner_images?.length) {
        let previousArray = [...data.banner_images];
        let updatedArray = previousArray.concat(updatedFiles);
        setData({
          ...data,
          banner_images: updatedArray,
        });
      } else {
        setData({
          ...data,
          banner_images: updatedFiles,
        });
      }
    }
  };

  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Institute Settings</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <Card.Body>
            <Tab.Container
              id="custom-tabs-example"
              defaultActiveKey="additional-settings"
            >
              <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                <Nav.Item className="w-50">
                  <Nav.Link eventKey="additional-settings">
                    Additional Settings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="w-50">
                  <Nav.Link eventKey="theme-settings">Theme Settings</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="additional-settings">
                  <div className="d-flex gap-3 py-4">
                    <div>
                      <h6>Product Setting</h6>
                      <p className="text-secondary">
                        Click and customize your product setting to display in{" "}
                        <b>Add Student</b> and <b>Results.</b>
                      </p>
                    </div>
                    <div className="d-flex gap-3 w-75 align-items-center">
                      <ButtonGroup
                        aria-label="Basic checkbox toggle button group"
                        className="hilingo-institute-settings gap-3"
                      >
                        <input
                          type="checkbox"
                          className="btn-check"
                          name="btninstitute"
                          id="btninstitute-1"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="btninstitute-1"
                          className="rounded-pill"
                        >
                          <Icon name="check"></Icon> <span>PTE</span>
                        </Button>

                        <input
                          type="checkbox"
                          className="btn-check"
                          name="btninstitute"
                          id="btninstitute-2"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="btninstitute-2"
                          className="rounded-pill"
                        >
                          <Icon name="check"></Icon> <span>IELTS</span>
                        </Button>

                        <input
                          type="checkbox"
                          className="btn-check"
                          name="btninstitute"
                          id="btninstitute-3"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="btninstitute-3"
                          className="rounded-pill"
                        >
                          <Icon name="check"></Icon> <span>DUOLINGO</span>
                        </Button>

                        <input
                          type="checkbox"
                          className="btn-check"
                          name="btninstitute"
                          id="btninstitute-4"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="btninstitute-4"
                          className="rounded-pill"
                        >
                          <Icon name="check"></Icon> <span>CELPIP</span>
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="d-flex gap-3 py-4">
                    <div>
                      <h6>Lock/Unlock Feature</h6>
                      <p className="text-secondary">
                        By this feature you can control showing of mock &
                        section in bulk test student.
                      </p>
                    </div>
                    <div className="d-flex gap-3 w-75 align-items-center">
                      <div className="form-check form-switch">
                        <span className="text-light">OFF</span>
                        <input
                          className="form-check-input ms-3"
                          type="checkbox"
                          role="switch"
                          id="directMessage"
                          name="active"
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="theme-settings">
                  <div className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center py-4">
                    <div className="d-flex- flex-column align-items-center justify-content-center">
                      <h5>Banner</h5>
                      <p className="mb-0 text-middark">
                        Upload your login screen banner for branding
                      </p>
                      <p className="mb-0 text-middark">
                        You may upload a maximum of 4 banners
                      </p>
                      {/* <a href="" className="mt-0">click here to resize  image</a> */}
                    </div>
                    {data.inst_profile?.banner_images.length > 0 &&
                      data.inst_profile?.banner_images.map((item) => {
                        return (
                          <>
                            <div
                              className="p-2 border d-flex align-items-center justify-content-center upload-img"
                              style={{ width: "120px", height: "120px" }}
                            >
                              <CloseButton
                              id="deleteFromFiles"
                                className="close-btn"
                                value={item.url}
                                name="deletebanner"
                                onClick={(e) =>
                                  handleChange(
                                    e,
                                    "inst_profile",
                                    null,
                                    item.url
                                  )
                                }
                              />
                              <Image src={item?.url}></Image>
                            </div>
                          </>
                        );
                      })}
                    {data?.banner_images?.length > 0 &&
                      data?.banner_images.map((item, index) => {
                        return (
                          <>
                            <div
                              className="p-2 border d-flex align-items-center justify-content-center upload-img"
                              style={{ width: "120px", height: "120px" }}
                            >
                              <CloseButton
                              id="deleteFromFiles"
                                className="close-btn"
                                value={item.preview}
                                name="deletebanner"
                                onClick={(e) => {
                                  let remove = data?.banner_images.filter(
                                    (item, i) => i !== index
                                  );
                                  setData({ ...data, banner_images: remove });
                                }}
                              />
                              <Image src={item?.preview}></Image>
                            </div>
                          </>
                        );
                      })}
                    {data?.file?.length > 0 && (
                      <div
                        className="p-2 border d-flex align-items-center justify-content-center upload-img"
                        style={{ width: "120px", height: "120px" }}
                      >
                        <CloseButton
                          className="close-btn"
                          id="deleteFromFiles"
                          value={data?.file[0].preview}
                          name="deletebanner"
                          onClick={(e) =>
                            handleChange(
                              e,
                              "inst_profile",
                              null,
                              data?.file[0].preview
                            )
                          }
                        />
                        <Image src={data?.file[0].preview}></Image>
                      </div>
                    )}
                    {data.inst_profile?.banner_images.length +
                      (data?.banner_images ? data?.banner_images?.length : 0) <
                      4 && (
                        <div className="d-flex- flex-column align-items-center justify-content-center">
                          <div className="form-control-wrap">
                            <FileUploadNew
                              noImage={true}
                              filetype="image"
                              onChange={(updatedFiles) => {
                                if (
                                  data.inst_profile?.banner_images.length +
                                  (data?.banner_images
                                    ? data?.banner_images?.length
                                    : 0) >
                                  5
                                ) {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Max limit Four",
                                  });
                                } else {
                                  // setData({
                                  //   ...data,
                                  //   banner_images: updatedFiles,
                                  // });
                                  updateBannerImages(updatedFiles);
                                }
                              }}
                              name
                              iconName="upload"
                              // aspectRatioCalculate={1}
                              aspectRatio="Recommended Aspect ratio 1:1"
                              extensions={"Image must be JPG/JPEG or PNG"}
                            />
                            
                                               <Button  onClick={() => window.open('https://www.canva.com/instagram-posts/templates/', '_blank')} variant="primary" size="sm" className="mt-2  add-btn theme-colored " style={{marginLeft:"61px"}}>  Design Free banners </Button>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center py-4">
                    <div className="d-flex- flex-column align-items-center justify-content-center">
                      <h5>Student Dashboard Banner</h5>
                      <p className="mb-0 text-middark">
                        Upload your student dashboard banner for branding
                      </p>
                      <p className="mb-0 text-middark">
                        You may upload a maximum of 3 banners
                      </p>
                    </div>
                    {data.inst_profile?.internal_banner_images?.length > 0 &&
                      data.inst_profile?.internal_banner_images?.map((item) => {
                        return (
                          <>
                            <div
                              className="p-2 border d-flex align-items-center justify-content-center upload-img"
                              style={{ width: "120px", height: "120px" }}
                            >
                              <CloseButton
                              id="deleteFromFiles"
                                className="close-btn"
                                value={item.url}
                                name="deletebanner_internal"
                                onClick={(e) =>
                                  handleChange(
                                    e,
                                    "inst_profile",
                                    null,
                                    item.url
                                  )
                                }
                              />
                              <Image src={item?.url}></Image>
                            </div>
                          </>
                        );
                      })}
                    {data?.internal_banner_images?.length > 0 &&
                      data?.internal_banner_images?.map((item, index) => {
                        return (
                          <>
                            <div
                              className="p-2 border d-flex align-items-center justify-content-center upload-img"
                              style={{ width: "120px", height: "120px" }}
                            >
                              <CloseButton
                              id="deleteFromFiles"
                                className="close-btn"
                                value={item.preview}
                                name="deletebanner_internal"
                                onClick={(e) => {
                                  let remove = data?.internal_banner_images.filter(
                                    (item, i) => i !== index
                                  );
                                  setData({ ...data, internal_banner_images: remove });
                                }}
                              />
                              <Image src={item?.preview}></Image>
                            </div>
                          </>
                        );
                      })}
                    {data?.file2?.length > 0 && (
                      <div
                        className="p-2 border d-flex align-items-center justify-content-center upload-img"
                        style={{ width: "120px", height: "120px" }}
                      >
                        <CloseButton
                        id="deleteFromFiles"
                          className="close-btn"
                          value={data?.file2[0]?.preview}
                          name="deletebanner_internal"
                          onClick={(e) =>
                            handleChange(
                              e,
                              "inst_profile",
                              null,
                              data?.file2[0].preview
                            )
                          }
                        />
                        <Image src={data?.file2[0]?.preview}></Image>
                      </div>
                    )}
                    {((data.inst_profile?.internal_banner_images != undefined ? data.inst_profile?.internal_banner_images?.length : 0) +
                      ((data?.internal_banner_images != undefined && data?.internal_banner_images) ? data?.internal_banner_images?.length : 0)) <
                      4
                      && (
                        <div className="d-flex- flex-column align-items-center justify-content-center">
                          <div className="form-control-wrap">
                            <FileUploadNew
                              noImage={true}
                              filetype="image"
                              onChange={(updatedFiles) => {
                                if (
                                  data.inst_profile?.internal_banner_images?.length +
                                  (data?.internal_banner_images
                                    ? data?.internal_banner_images?.length
                                    : 0) >
                                  5
                                ) {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Max limit Four",
                                  });
                                } else {
                                  updateBannerImages(updatedFiles, "internal_banner_images");
                                }
                              }}
                              name
                              iconName="upload"
                              aspectRatioCalculate={4}
                              aspectRatio="Recommended Aspect ratio 4:1"
                              extensions={"Image must be JPG/JPEG or PNG"}

                            />
                          <Button  onClick={() => window.open('https://www.canva.com/linkedin-banners/templates/', '_blank')
                    }variant="primary" size="sm" className="mt-2  add-btn" style={{marginLeft:"61px"}}>  Design Free banners </Button>
                          
                          </div>
                        </div>
                      )}
                    {/* <div className="d-flex- flex-column align-items-center justify-content-center">
                        <div className="form-control-wrap">
                          <FileUpload
                            noImage={true}
                            filetype="image"
                            onChange={(updatedFiles) => {
                              if (
                                data.inst_profile?.internal_banner_images?.length +
                                  (data?.internal_banner_images
                                    ? data?.internal_banner_images?.length
                                    : 0) >
                                5
                              ) {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Max limit Four",
                                });
                              } else {
                                updateBannerImages(updatedFiles,"internal_banner_images");
                              }
                            }}
                            name
                            iconName="upload"
                          />
                        </div>
                      </div> */}
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-column flex-md-row gap-5 align-items-center py-4">
                    <div className="d-flex- flex-column align-items-center justify-content-center">
                      <h5>Brand Color</h5>
                      <p className="mb-0 text-middark">
                        Choose your brand color for panel.
                      </p>
                      <a href="" className="mt-0">
                        <Icon name="video-fill"></Icon> <span className="text-theme_text">Watch Now</span>
                      </a>
                    </div>
                    <Form.Group className="form-group">
                      <div className="form-control-wrap">
                        <Form.Control
                          type="color"
                          value={data?.inst_profile?.color_code}
                          name="color_code"
                          onChange={(e) => handleChange(e, "inst_profile")}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <div className="d-flex justify-content-end ">
              <button className="btn btn-primary px-5" onClick={submitForm}>
                Update
              </button>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default InstituteSettings;
