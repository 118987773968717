import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import Context from "../../../../../context";
import AdminApi from "../../../../../api/admin";
function InstructionTable({
  extractTime,
  examtype,
  all,
  section_wise,
  examsTime,
  id
}) {
  let { userData, setUserData, localHost, incorreCtDomain } =
    useContext(Context);

  userData = JSON.parse(userData);

  const [quesData, setQuesData] = useState([])
  const [matchedTexts, setMatchedTexts] = useState([]);
  console.log("MATCHEDTEXT",matchedTexts);

  console.log("quesData",quesData);
  let question_types = [
    { value: "read_alouds", text: "Read Aloud", type: "speaking" },
    { value: "repeat_sentences", text: "Repeat Sentence", type: "speaking" },
    { value: "describe_images", text: "Describe Image", type: "speaking" },
    { value: "retell_lectures", text: "Re-Tell Lecture", type: "speaking" },
    { value: "answer_questions", text: "Answer Short Question", type: "speaking" },
    { value: "swts", text: "Summarize Writing Text", type: "writing" },
    { value: "essays", text: "Essay", type: "writing" },
    { value: "fib_wr", text: "Reading & Writing: Fill in the blanks", type: "reading" },
    { value: "r_mcm", text: "Reading: Multiple Choice (Multiple)", type: "reading" },
    { value: "ro", text: "Re-order Paragraphs", type: "reading" },
    { value: "fib_rd", text: "Reading: Fill in the Blanks", type: "reading" },
    { value: "r_mcs", text: "Reading: Multiple Choice (Single)", type: "reading" },
    { value: "ssts", text: "Summarize Spoken Text", type: "listening" },
    { value: "l_mcm", text: "Listening: Multiple Choice (Multiple)", type: "listening" },
    { value: "l_fib", text: "Fill in the Blanks", type: "listening" },
    { value: "l_hcs", text: "Highlight Correct Summary", type: "listening" },
    { value: "l_mcs", text: "Listening: Multiple Choice (Single)", type: "listening" },
    { value: "l_smw", text: "Select Missing Word", type: "listening" },
    { value: "hiws", text: "Highlight Incorrect Words", type: "listening" },
    { value: "wfds", text: "Write From Dictation", type: "listening" }
  ];
  

  useEffect(() => {
    get_paper()
  },[])

  let api = new AdminApi();
  const get_paper = async () => {
    await api.get_paper(id).then((res) => {
      if (res.status) {
        console.log("GETPAPER");
        if(res?.data?.length){
          setQuesData(res?.data[0]?.questID)
        }
      }
    });
  };

  useEffect(() => {
    if(quesData.length>0){
      const matched = []
      for(let obj of question_types){
        for(let obj2 of quesData){
          if(obj2.module_name == obj.value){
            matched.push(obj.text)
            break
          }
        }
        setMatchedTexts(matched)
      }
    }
    // const matchedTextsArray = quesData.map(apiData => {
    //   const match = question_types.find(q => q.value === apiData.module_name);
    //   return match ? match.text : null;
    // }).filter(text => text !== null);

    // setMatchedTexts(matchedTextsArray);
  }, [quesData]);

  

  console.log("examexamsTimeexamsTimesTime", examsTime)
  return (
    <>
      <p className="bg-examblue text-white px-3 py-1">
        {/* <img src="/images/favicon.png" alt="logo" className="intro-logo" />{" "} */}
        HiLingo | PTE Academic
      </p>
      {examtype == all && (
        <Row className="my-3">
          <Col md="12" className="text-examheadtext">
            <h4 className="text-examheadtext">
              Welcome {userData?.name.toUpperCase()}.
            </h4>
            <p>
              The test is approximately {extractTime(examsTime.total_time)}{" "}
              long.
            </p>
            <Table
              responsive
              bordered
              striped
              hover
              className="small my-3 w-50 exam-table"
            >
              <thead className="bg-primary-soft">
                <tr>
                  <th className="text-examheadtext">Part</th>
                  <th className="text-examheadtext">Content</th>
                  <th className="text-examheadtext">Time Allowed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-examheadtext">Intro</td>
                  <td className="text-examheadtext">Introduction</td>
                  <td className="text-examheadtext">1 minutes</td>
                </tr>
                {extractTime(examsTime.speaking_and_Writing_time) != "0 seconds" && (
                  <tr>
                    <td className="text-examheadtext">Part 1</td>
                    <td className="text-examheadtext">Speaking and writing</td>
                    <td className="text-examheadtext">
                      {extractTime(examsTime.speaking_and_Writing_time)}
                    </td>
                  </tr>
                )}
                {/* {extractTime(examsTime.reading_time) != "0 seconds" && ( */}
                <tr>
                  <td className="text-examheadtext">Part 2</td>
                  <td className="text-examheadtext">Reading</td>
                  <td className="text-examheadtext">
                    {extractTime(examsTime.reading_time) != "0 seconds" ? extractTime(examsTime.reading_time) : "Note Timed"}
                  </td>
                </tr>
                {/* )} */}
                {extractTime(examsTime.listening_time) != "0 seconds" && (
                  <tr>
                    <td className="text-examheadtext">Part 3</td>
                    <td className="text-examheadtext">Listening</td>
                    <td className="text-examheadtext">
                      {extractTime(examsTime.listening_time)}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <p className="bg-examblue text-white px-3 mt-5">
              Click on the Next (N) button to continue
            </p>
          </Col>
        </Row>
      )}
      {examtype != all && examtype == section_wise && (
        <Row className="my-3">
          <Col md="12" className="text-examheadtext">
            <h4 className="text-examheadtext">
              Welcome {userData?.name.toUpperCase()}.
            </h4>
            <p>
              The test is approximately {extractTime(examsTime.total_time)}{" "}
              long.
            </p>
            {/* <h4 className="text-examheadtext">Part 3: Listening</h4>
           <ul className="mx-3" style={{ listStyleType: "disc" }}>
             <li>
               In this part you will be tested mainly on your listening
               skils
             </li>
             <li>
               Read the instructions to each question carefully and
               answer as directed{" "}
             </li>
           </ul> */}
            <Table
              responsive
              bordered
              striped
              hover
              className="small my-3 w-50"
            >
              <thead className="bg-primary-soft">
                {/* <tr>
                 <th colSpan="3">Part 1: Listening</th>
               </tr> */}
                <tr>
                  <th className="text-examheadtext">Part </th>
                  <th className="text-examheadtext">Content</th>
                  <th className="text-examheadtext">Time Allowed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="20" className="text-examheadtext">Part 1</td>
                  <td className="text-examheadtext ">
                    {matchedTexts.length > 0 && matchedTexts[0]}
                  </td>
                  <td rowSpan="20" className="text-examheadtext"> {extractTime(examsTime.total_time)}</td>
                </tr>
                {matchedTexts.length > 0 && matchedTexts.map((ele,key) => {
                  
                  return key>0 && <tr><td key={key} className="text-examheadtext">
                           {ele}
                        </td>
                        </tr>
                } )}
                {/* <tr>
                  <td className="text-examheadtext">
                    Repeat Sentence
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Describe Image
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Re-tell Lecture
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Answer Short Question
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Summarize Written Text
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Write Essay
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Reading & Writing：Fill in the blanks
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Multiple Choice, choose multiple answers (Reading)
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Re-order Paragraphs
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Reading：Fill in the Blanks
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Multiple Choice, choose single answer (Reading)
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Summarize spoken text
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Multiple-choice, choose multiple answers (Listening)
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Fill in the blanks
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Highlight correct summary
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Multiple-choice, choose single answer (Listening)
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Select Missing word
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Highlight incorrect words
                  </td>
                </tr>
                <tr>
                  <td className="text-examheadtext">
                    Write from dictation
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
}

export default InstructionTable;
