import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../layout/default";
import {
  Icon,
  Select,
  Block,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../../../components";
import Question_Context from "../../../../context/questionContext";
import { useState, useContext, useEffect } from "react";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteAnsShortQue() {
  let { id } = useParams();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [error, setErrror] = useState({
    image: false,
    audio: false,
  });
  const [loader, setloader] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type: "speaking_answer_questions",
    instructions:"You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
    question_options: [
      {
        answer: [""],
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  console.log("*quesData", quesData);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (e, index) => {
    let { name, value } = e.target;
    e.preventDefault();
    if (name == "file") {
      setData({ ...data, ["file"]: e.target.files[0] });
    } else {
      if (name !== "options") {
        if (name == "question_type") {
          const options = e.target.options;
          const selectedValues = [];
          if (options.length == 0) {
            setSelectedOptions([]);
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues);
          }
          setData({
            ...data,
            isMock: selectedValues.includes("isMock") ? true : false,
            isPractice: selectedValues.includes("isPractice") ? true : false,
            isSection: selectedValues.includes("isSection") ? true : false,
            isQuestion: selectedValues.includes("isQuestion") ? true : false,
            isPrediction: selectedValues.includes("isPrediction")
              ? true
              : false,
          });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };
  let api = new AdminApi();
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isAudio = false;
    let isVideo = false;
    let isImageFile = false;
    // if(!data.question_content_audio && !data.question_content_video  ){
    //     if (data?.file?.length > 0) {
    //       const fileType = data.file[0].type;
    //       if (fileType.startsWith("audio/")) {
    //         isAudio = true;
    //         formObject.append("question_content_audio", data.file[0]);
    //       } else if (fileType.startsWith("video/")) {
    //         isVideo = true;
    //         formObject.append("question_content_video", data.file[0]);
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "File must be an audio or video",
    //           text: "Please upload an audio or video file.",
    //         });
    //         return;
    //       }
    //     } else {
    //       Swal.fire({
    //         icon: "error",
    //         title: "File is required",
    //         text: "Please upload an audio or video file.",
    //       });
    //       return;
    //     }}

    // for Imageee----------------------------------

    if (data?.imagefile?.length > 0 && !data.question_content_image) {
      if (
        data?.imagefile?.length > 0 &&
        data.imagefile[0].type.startsWith("image/")
      ) {
        isImageFile = true;
        formObject.append("question_content_image", data.imagefile[0]);
        setErrror({ ...error, image: false });
      } else {
        setErrror({ ...error, image: true });
        Swal.fire({
          icon: "error",
          title: "File must be an image",
          text: "Please upload an image file.",
        });
        return;
      }
    }
    if (!data.question_content_audio) {
      if (data?.file?.length > 0) {
        const fileType = data.file[0].type;
        console.log("*", fileType);
        if (fileType.startsWith("audio/")) {
          isAudio = true;
          formObject.append("question_content_audio", data.file[0]);
          setErrror({ ...error, audio: false });
        } else {
          setErrror({ ...error, audio: true });
          Swal.fire({
            icon: "error",
            title: "File must be an audio ",
            text: "Please upload an audio file.",
          });
          return;
        }
      } else {
        if (!data?.question_content_audio && !data.question_content_video) {
          setErrror({ ...error, audio: true });
          Swal.fire({
            icon: "error",
            title: "File is required",
            text: "Please upload an audio file.",
          });
          return;
        }
      }
    }
    for (const [key, value] of Object.entries(data)) {
      if (key === "question_options") {
        console.log("value", value[0].answer[0]);
        formObject.append(`question_options[0][answer][0]`, value[0].answer[0]);
      } else {
        if (
          key !== "file" ||
          key != "question_content_audio" ||
          key != "question_content_video" ||
          key != "question_content_image" ||
          key != "question_options"
        ) {
          if (value != null) {
            formObject.append(key, value);
          }
        }
      }
      // else  {
      //   console.log("keyy",key === "question_options","---------", value[0]?.answer[0])
      //       formObject.append(
      //         `question_options[0][answer][0]`,
      //         value[0].answer[0]
      //       );
      // }
    }
    setloader(!loader);
    await api
      .add_Question_speaking(formObject)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/view-questions");
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
        setloader(!loader);
      })
      .catch((err) => {
        setloader(!loader);
        console.log("err", err);
      });
  };

  console.log("-", data);
  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      file,
      question,
      answer_time,
      question_begin_time,
      answer_prepare_time,
      question_options,
      question_transcript,
    } = data;
    return (
      instructions &&
      (file
        ? file
        : data.question_content_audio || data.question_content_video) &&
      tags &&
      question_transcript &&
      // question_options[0].answer[0] &&
      data?.sample_answer &&
      (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      answer_time?.length === 5 &&
      answer_prepare_time?.length === 5 &&
      question_begin_time?.length === 5
    );
  };
  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);

  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const grabNew = (e) => {
    console.log("-----", e);
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Add PTE Answer Short Questions</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => {
                      setSelectedOptions(e);
                    }}
                    data={data}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e);
                      setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                {id && (
                  <EditTimesFiled
                    data={data}
                    onDataChange={(name, val) =>
                      setData({ ...data, [name]: val })
                    }
                  />
                )}

                <Col md="9">
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="instruction"
                          className="ms-3 fw-bold"
                        >
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group
                        className={`form-group ${error.image && `error-Image`}`}
                      >
                        <Form.Label
                          htmlFor="addaudio"
                          className=" ms-3 fw-bold"
                        >
                          Add Image (Optional)
                        </Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_image &&
                          data.question_content_image != "null" ? (
                            <>
                              <div className="d-flex gap-2">
                                <img
                                  src={data?.question_content_image}
                                  width="160"
                                  height="155"
                                />
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_image: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                              filetype="image"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                console.log("updatedFiles", updatedFiles);
                                setErrror({
                                  ...error,
                                  image: false,
                                  audio: false,
                                });
                                setData({
                                  ...data,
                                  imagefile: updatedFiles,
                                  question_content_image: "",
                                });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group
                        className={`form-group ${error.audio && `error-Image`}`}
                      >
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">
                          Add Audio
                        </Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_audio ? (
                            <>
                              <div className="d-flex gap-2">
                                <audio
                                  src={data?.question_content_audio}
                                  controls
                                ></audio>
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                              filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setErrror({
                                  ...error,
                                  image: false,
                                  audio: false,
                                });
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="addaudio">
                            Add Audio/Video
                          </Form.Label>
                          <div className="form-control-wrap">
                          {data.question_content_audio ||
                            data.question_content_video ? (
                              <>
                                <div className="d-flex gap-2">
                                  {(data.question_content_video && (
                                    <video
                                      src={data?.question_content_video}
                                      width="200"
                                      height="155"
                                      controls={false}
                                    />
                                  )) ||
                                    (data.question_content_audio && (
                                      <audio
                                        src={data?.question_content_audio}
                                        controls
                                      />
                                    ))}
                                  <span className="d-flex align-items-center">
                                    <button
                                      className="btn btn-danger py-1"
                                      onClick={() =>
                                        setData({
                                          ...data,
                                          question_content_video: "",
                                          question_content_audio: "",
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <FileUpload
                                iconName="upload"
                                name="file"
                                onChange={(updatedFiles) => {
                                  setData({ ...data, file: updatedFiles });
                                }}
                              />
                            )}
                          </div>
                        </Form.Group>
                      </Col> */}
                    <Col lg="12">
                      <TranscriptExplanation
                        transcript={true}
                        sample_answer={true}
                        setState={(name) => setData({ ...data, [name]: "" })}
                        data={data}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">
                          Add Tag (#)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="addtag"
                            value={data.tags}
                            placeholder="#Tag"
                            name="tags"
                            onChange={(e) => {
                              var removeHash = e.target.value.replaceAll(
                                "#",
                                ""
                              );
                              removeHash = "#" + removeHash;
                              setData({ ...data, tags: removeHash });
                            }}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton
                    loader={loader}
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteAnsShortQue;
