import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";


const FollowupTab = ({FollowupsColumns,tableData,showItemPerPage,setShowItemPerPage,
  followupData,setTabledata}) => {
 
    

 
  const[selectedTab,setSelectedTab]=useState({
    all:true,        
    inProgress:false,
    entrolled:false,
    noInterest:false,
    data:""
})


const allClickHandler=()=>{
  setSelectedTab((prev)=>({...prev,
      all:true,
      inProgress:false,
      entrolled:false,
      noInterest:false,
      data:""
  }))

}

const inProgressClickHandler=()=>{
    setSelectedTab((prev)=>({...prev,
        totalEnq:false,
        inProgress:true,
        entrolled:false,
        noInterest:false,
        interest:false,
        data:"IN PROCESS",
        
    }))

}
const entrolledClickHandler=()=>{
    setSelectedTab((prev)=>({...prev,
        totalEnq:false,
        inProgress:false,
        entrolled:true,
        noInterest:false,
        interest:false,
        data:"ENROLLED",
       
    }))

}
const noInterestClickHandler=()=>{
    setSelectedTab((prev)=>({...prev,
        totalEnq:false,
        inProgress:false,
        entrolled:false,
        noInterest:true,
        interest:false,
        data:"NOT INTERESTED",
       
    }))

}

const interestClickHandler=()=>{
  setSelectedTab((prev)=>({...prev,
      totalEnq:false,
      inProgress:false,
      entrolled:false,
      noInterest:false,
      interest:true,
      data:"INTERESTED",
     
  }))

}


console.log(followupData,"filteredData##")
 


  const filterData = async () => {
    const filteredData = followupData?.filter((item) => {
      
      
  
      
  console.log('followupData :>> ', selectedTab?.data);
  return (
    
    
    (!selectedTab?.data ||
      (item?.status != null && 
        item?.status?.toLowerCase() === selectedTab?.data?.toLowerCase())) 
        
        
        );
      });
      console.log('filteredData :>> ', filteredData,selectedTab?.data=="","dataa" ); 
      if(selectedTab?.data==""){
      setTabledata(followupData);
      }
      else{
        setTabledata(filteredData)
      }
    }
    
  
useEffect(()=>{
  console.log("inside use");

  filterData()
},[selectedTab])

// const filterData = async () => {
  // const filteredData = followupData?.filter((item) => {




  //   return (

    
  //     (!selectedTab?.data ||
  //       (item?.status != "" &&
  //         item?.status.toLowerCase() === selectedTab?.data?.toLowerCase())) 

       
  //   );
  // });
  // setTabledata(filteredData);
//   console.log("filteredDatafollow", filteredData);
// };

console.log('table :>> ', tableData);
  return (
    <div>

<Tab.Container id="enquiry-tabs" defaultActiveKey="allData">
                  <Nav variant="pills" className="mb-5 justify-content-start gap-2 enquiry-tabs">
                 
                 
                 
                  <Nav.Item>
                      <Nav.Link eventKey="allData" onClick={allClickHandler}>All</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
              <Nav.Link eventKey="not-interested" onClick={noInterestClickHandler}>Not Interested</Nav.Link>
            </Nav.Item>

                    <Nav.Item>
              <Nav.Link eventKey="interested" onClick={interestClickHandler}>Interested</Nav.Link>
            </Nav.Item>


             
                    <Nav.Item>
                      <Nav.Link eventKey="inprocess" onClick={inProgressClickHandler}>In Process</Nav.Link>
                    </Nav.Item>
                 
                 
                    <Nav.Item>
              <Nav.Link eventKey="enrolled" onClick={entrolledClickHandler}>Enrolled</Nav.Link>
            </Nav.Item>
                 
           
           

            {/* <Nav.Item>
                {selectedTab?.entrolled &&

              <Nav.Link eventKey="result">{tableData?.length}</Nav.Link>
            }
           
                   {selectedTab?.inProgress &&
          

              <Nav.Link eventKey="result">inProgress</Nav.Link>
                   }
            

            {selectedTab?.noInterest &&
            

              <Nav.Link eventKey="result">noInterest</Nav.Link>
            
                }
                </Nav.Item> */}

<Nav.Item>
               


          
              <Nav.Link eventKey="result"  disabled >{tableData?.length}</Nav.Link>
      
                </Nav.Item>


{/* {selectedTab?.entrolled &&
            <Nav.Item>

              <Nav.Link eventKey="result">enrolled</Nav.Link>
            </Nav.Item>
                }
                   {selectedTab?.inProgress &&
            <Nav.Item>

              <Nav.Link eventKey="result">inProgress</Nav.Link>
            </Nav.Item>
                }

            {selectedTab?.noInterest &&
            <Nav.Item>

              <Nav.Link eventKey="result">noInterest</Nav.Link>
            </Nav.Item>
                } */}
                  </Nav>
                  <Tab.Content>

                  <Tab.Pane eventKey="allData">
                <DataTable
                  columns={FollowupsColumns}
                  data={tableData}
                  noDataComponent={
                    <div className="p-2">There are no records found.</div>
                  }
                  sortIcon={<div className="data-table-sorter"></div>}
                  pagination={true}
                  paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                  }) => (
                    <div className="data-table-bottom">
                      <DataTablePagination
                        showItemPerPage={showItemPerPage}
                        itemPerPage={rowsPerPage}
                        totalItems={rowCount}
                        paginate={onChangePage}
                        currentPage={currentPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        setShowItemPerPage={setShowItemPerPage}
                      />
                    </div>
                  )}
                />
                </Tab.Pane>


                    <Tab.Pane eventKey="inprocess">
                <DataTable
                  columns={FollowupsColumns}
                  data={tableData}
                  noDataComponent={
                    <div className="p-2">There are no records found.</div>
                  }
                  sortIcon={<div className="data-table-sorter"></div>}
                  pagination={true}
                  paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                  }) => (
                    <div className="data-table-bottom">
                      <DataTablePagination
                        showItemPerPage={showItemPerPage}
                        itemPerPage={rowsPerPage}
                        totalItems={rowCount}
                        paginate={onChangePage}
                        currentPage={currentPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        setShowItemPerPage={setShowItemPerPage}
                      />
                    </div>
                  )}
                />
                </Tab.Pane>

                <Tab.Pane eventKey="enrolled">   <DataTable
                  columns={FollowupsColumns}
                  data={tableData}
                  noDataComponent={
                    <div className="p-2">There are no records found.</div>
                  }
                  sortIcon={<div className="data-table-sorter"></div>}
                  pagination={true}
                  paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                  }) => (
                    <div className="data-table-bottom">
                      <DataTablePagination
                        showItemPerPage={showItemPerPage}
                        itemPerPage={rowsPerPage}
                        totalItems={rowCount}
                        paginate={onChangePage}
                        currentPage={currentPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        setShowItemPerPage={setShowItemPerPage}
                      />
                    </div>
                  )}
                /></Tab.Pane>
          <Tab.Pane eventKey="not-interested">

          <DataTable
                  columns={FollowupsColumns}
                  data={tableData}
                  noDataComponent={
                    <div className="p-2">There are no records found.</div>
                  }
                  sortIcon={<div className="data-table-sorter"></div>}
                  pagination={true}
                  paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                  }) => (
                    <div className="data-table-bottom">
                      <DataTablePagination
                        showItemPerPage={showItemPerPage}
                        itemPerPage={rowsPerPage}
                        totalItems={rowCount}
                        paginate={onChangePage}
                        currentPage={currentPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        setShowItemPerPage={setShowItemPerPage}
                      />
                    </div>
                  )}
                />

          </Tab.Pane>
          <Tab.Pane eventKey="interested">

<DataTable
        columns={FollowupsColumns}
        data={tableData}
        noDataComponent={
          <div className="p-2">There are no records found.</div>
        }
        sortIcon={<div className="data-table-sorter"></div>}
        pagination={true}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
            />
          </div>
        )}
      />

</Tab.Pane>



                </Tab.Content>
                    </Tab.Container>
      
    </div>
  )
}

export default FollowupTab
