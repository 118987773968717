import { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
function ExamFooter() {

    return (
        <div className='d-flex justify-content-between bg-hilight px-5 position-fixed bottom-0 w-100'>
            <p className='d-flex justify-content-center w-100 m-0 align-items-center text-middark'>Power By Hi-Lingo Technology</p>
            <Button variant='bg-transparent' className='text-secondary'> Next</Button>
        </div>
    )
}

export default ExamFooter              