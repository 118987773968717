import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';

// import getParents from '../../../utilities/getParents';
import getParents from '../../../utilities/getParents';
import slideDown from '../../../utilities/slideDown';
import slideUp from '../../../utilities/slideUp';

import { Link, NavLink } from 'react-router-dom';
import AuthApi from '../../../api/auth';
import DashBoardIconsSvg from '../../../components/SvgIcons/DashBoardIconsSvg';
import StudentManageSvg from '../../../components/SvgIcons/StudentManageSvg';
import Context from '../../../context';
import { checkPermissionRouteForSidebar } from '../../../utilities/checkPermissionRouteForSidebar';
import { Image } from '../../../components';
import HomeIconsSvg from '../../../components/SvgIcons/HomeIconsSvg';
import OverrideContext from '../../../context/OverrideContext/OverrideContext';
import AdminApi from '../../../api/admin';


function MenuHeading({ className, text, ...props }) {
    const compClass = classNames({
        'nk-menu-heading': true,
        [className]: className
    });
    return (
        <li className={compClass}>
            <h6 className="overline-title">{text || props.children}</h6>
        </li>
    )
}

// function MenuItemTemplate({ text, imageicon }) {
//     console.log(imageicon,"imageicon");
//     return (
//         <>
//             {imageicon && <span className="nk-menu-icon"><Image src={`/images/${imageicon}.svg`}></Image></span>}
//             {/* {icon && <span className="nk-menu-icon"><em className={`icon ni ni-${icon}`}></em></span>} */}
//             {text && <span className="nk-menu-text">{text}</span>}
//         </>
//     )
// }

function MenuItemTemplate({ text, imageicon, fill }) {

    // let IconComponent;
    // switch (imageicon) {
    //     case 'dashboard':
    //         IconComponent = <DashBoardIconsSvg fill={fill} />;
    //         break;
    //     // Add other cases for different icons
        

    //     case 'student-management':
    //         IconComponent = <StudentManageSvg fill={fill} />;
    //         break;
    //     default:
    //         IconComponent = null;
    //         break;
    // }

    return (
        <>{
                fill ? <>
            {imageicon && (
                <span className="nk-menu-icon">
                {/* {/ {icons[imageicon] || null} /} */}
                {<HomeIconsSvg imageicon={imageicon} fill={fill} />}
                </span>
            )}
            {text && <span className="nk-menu-text">{text}</span>}
            </>:<>
            {imageicon && <span className="nk-menu-icon"><Image src={`/images/${imageicon}.svg`}></Image></span>}
            {/* {icon && <span className="nk-menu-icon"><em className={`icon ni ni-${icon}`}></em></span>} */}
            {text && <span className="nk-menu-text">{text}</span>}
            </>
        }
        </>
    );
}



// function MenuItemLink({ text, imageicon, sub, to, blank, onClick }) {
//     return (
//         <>
//             {(!blank && !sub) && <NavLink className="nk-menu-link" to={to} activeClassName="active"><MenuItemTemplate imageicon={imageicon} text={text} /></NavLink>}
//             {blank && <Link className="nk-menu-link" to={to} target="_blank"><MenuItemTemplate imageicon={imageicon} text={text} /></Link>}
//             {sub && <a className="nk-menu-link nk-menu-toggle" onClick={onClick} href="#expand"><MenuItemTemplate imageicon={imageicon} text={text} /></a>}
//         </>
//     )
// }

function MenuItemLink({ text, imageicon, sub, to, blank, onClick, fill }) {
    return (
        <>  {
            fill ? <>
            {(!blank && !sub) && <NavLink className="nk-menu-link" to={to} activeClassName="active"><MenuItemTemplate imageicon={imageicon} text={text} fill={fill} /></NavLink>}
            {blank && <Link className="nk-menu-link" to={to} target="_blank"><MenuItemTemplate  imageicon={imageicon} text={text} fill={fill}/></Link>}
            {sub && <a className="nk-menu-link nk-menu-toggle" onClick={onClick} href="#expand"><MenuItemTemplate imageicon={imageicon} text={text} fill={fill}  /></a>}
            </>:<>
            
            {(!blank && !sub) && <NavLink className="nk-menu-link" to={to} activeClassName="active"><MenuItemTemplate imageicon={imageicon} text={text} /></NavLink>}
            {blank && <Link className="nk-menu-link" to={to} target="_blank"><MenuItemTemplate imageicon={imageicon} text={text} /></Link>}
            {sub && <a className="nk-menu-link nk-menu-toggle" onClick={onClick} href="#expand"><MenuItemTemplate imageicon={imageicon} text={text} /></a>}
            </>

        }
        </>
    )
}

function MenuItem({ sub, className, ...props }) {
    const compClass = classNames({
        'nk-menu-item': true,
        'has-sub': sub,
        [className]: className
    });
    return (
        <li className={compClass}>
            {props.children}
        </li>
    )
}

function MenuSub({ mega, className, ...props }) {
    const compClass = classNames({
        'nk-menu-sub': true,
        [className]: className
    });
    return (
        <ul className={compClass}>
            {props.children}
        </ul>
    )
}

function MenuList({ className, ...props }) {
    const compClass = classNames({
        'nk-menu': true,
        [className]: className
    });
    return (
        <ul className={compClass}>
            {props.children}
        </ul>
    )
}

function Menu() {
    const [userRoutes, setUserRoutes] = useState([]);
    const [institutePlan,setInstitutePlan] = useState({})
    let api = new AuthApi();
    // variables for Sidebar
    let menu = {
        classes: {
            main: 'nk-menu',
            item: 'nk-menu-item',
            link: 'nk-menu-link',
            toggle: 'nk-menu-toggle',
            sub: 'nk-menu-sub',
            subparent: 'has-sub',
            active: 'active',
            current: 'current-page'
        },
    };

 console.log('institutePlan :>> ', institutePlan);
    let currentLink = function (selector) {
        let elm = document.querySelectorAll(selector);
        elm.forEach(function (item) {
            var activeRouterLink = item.classList.contains('active');
            if (activeRouterLink) {
                let parents = getParents(item, `.${menu.classes.main}`, menu.classes.item);
                parents.forEach(parentElemets => {
                    parentElemets.classList.add(menu.classes.active, menu.classes.current);
                    let subItem = parentElemets.querySelector(`.${menu.classes.sub}`);
                    subItem !== null && (subItem.style.display = "block")
                })

            } else {
                item.parentElement.classList.remove(menu.classes.active, menu.classes.current);
            }
        })
    }


    // dropdown toggle
    let dropdownToggle = function (elm) {
        let parent = elm.parentElement;
        let nextelm = elm.nextElementSibling;
        let speed = nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400;
        if (!parent.classList.contains(menu.classes.active)) {
            parent.classList.add(menu.classes.active);
            slideDown(nextelm, speed);
        } else {
            parent.classList.remove(menu.classes.active);
            slideUp(nextelm, speed);
        }
    }

    // dropdown close siblings
    let closeSiblings = function (elm) {
        let parent = elm.parentElement;
        let siblings = parent.parentElement.children;
        Array.from(siblings).forEach(item => {
            if (item !== parent) {
                item.classList.remove(menu.classes.active);
                if (item.classList.contains(menu.classes.subparent)) {
                    let subitem = item.querySelectorAll(`.${menu.classes.sub}`);
                    subitem.forEach(child => {
                        child.parentElement.classList.remove(menu.classes.active);
                        slideUp(child, 400);
                    })
                }
            }
        });
    }

    let menuToggle = function (e) {
        e.preventDefault();
        let item = e.target.closest(`.${menu.classes.toggle}`)
        dropdownToggle(item);
        closeSiblings(item);
    }

    const {overrideData,setOverrideData}=useContext(OverrideContext)

    useEffect(() => {
        currentLink(`.${menu.classes.link}`);
        // eslint-disable-next-line
    }, [null])

    useEffect(() => {
        if (userData?.login_type === "0") {
          get_user_routes(1);
        }
        else{
          get_user_routes(2);
        
        }
      }, []

    );
    useEffect(()=>{
        if(overrideData!=undefined) 
            getInsitutePlan(userData?.login_type=="1" ?userData?._id:userData?.login_type=="2" && domainData?._id)
    },[overrideData])
    let adminApi = new AdminApi();
    const getInsitutePlan = async (instId) => {
        await adminApi
          .get_active_plans_by_inst(instId,overrideData)
          .then((res) => {
            console.log(res?.data,overrideData,"institutePlanoverrideData")
            if(overrideData == 0){
  
                setInstitutePlan(res.data)
              }
              else{
                setInstitutePlan(res.data?.plan_data)
              }
          })
          .catch((error) => {
            console.log(error);
          });
      };

      console.log(institutePlan,"institutePlan")
    async function get_user_routes(param) {
        try {
          let user_routes = await api.get_user_routes(param);
          const routes = user_routes.data;
          // console.log(routes);
          setUserRoutes(routes);
        } catch (error) {
          console.error(error);
        }
      }

    let { localHost, userData,domainData } = useContext(Context)
    userData = JSON.parse(userData)
    domainData = JSON.parse(domainData)
    let studentLogin = userData.login_type == "2"
    let instituteLogin = userData.login_type == "1"
    let name = (window.location.hostname).split(".")[0];



    console.log("selected_permission_routes",domainData);
    // const colorCodeStu=JSON.parse(localStorage?.getItem("instDomainHilingo")?.inst_profile?.color_code)
   const instDomain=JSON.parse(localStorage?.getItem("instDomainHilingo"))
   console.log("faf",instDomain?.inst_profile?.color_code)


   console.log("selected_permission32",institutePlan?.material_management_institute,userData?.permission_routes[0] == "all",
    checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Material Management"))
  const isOverride= JSON.parse(localStorage.getItem("isOverride"))
  console.log(isOverride,"isOverride==>")
    return (
        <MenuList className="hilingo-menu">
            <MenuItem>
            
                <MenuItemLink imageicon="dashboard" text="Dashboard" to="/" fill={userData?.inst_profile?.color_code || instDomain?.inst_profile?.color_code}/>
            </MenuItem>
            {name == "adm1n" || (name == "localhost" && localHost == "adm1n") ?
                <>
                    <MenuItem>
                        <MenuItemLink imageicon="new-request" text="New Request" to="/institute-request-list" />
                       
                    </MenuItem>

                    
                    <MenuItem>
                   
                        <MenuItemLink imageicon="institute-list" text="Institute List" to="/institute-list" />
                    </MenuItem>
                    <MenuItem sub>
                        <MenuItemLink imageicon="question" text="Questions" onClick={menuToggle} sub />
                        <MenuSub>
                            <MenuItem sub>
                                <MenuItemLink text="PTE" onClick={menuToggle} sub />
                                <MenuSub>
                                    <MenuItem sub>
                                        <MenuItem>
                                            <MenuItemLink text="Add Questions" to="/material-management/add-questions" />
                                            <MenuItemLink text="View Questions" to="/material-management/view-questions" />
                                        </MenuItem>
                                    </MenuItem>
                                </MenuSub>
                            </MenuItem>
                            {/* <MenuItem sub>
                                <MenuItemLink text="IELTS" onClick={menuToggle} sub />
                                <MenuSub>
                                    <MenuItem>
                                        <MenuItemLink text="Reading Test Paper" to="/material-management/ielts/reading/test-paper" />
                                    </MenuItem>
                                    <MenuItem>
                                        <MenuItemLink text="Listening Test Paper" to="/material-management/ielts/listening/test-paper" />
                                    </MenuItem>
                                </MenuSub>
                            </MenuItem> */}
                        </MenuSub>
                    </MenuItem>
                    <MenuItem sub>
                        <MenuItemLink imageicon="support" text="Staff"  to="/view-staff"  />
                        {/* <MenuSub>
                            <MenuItem sub>
                                <MenuItemLink text="View Staff" to="/view-staff" />
                            </MenuItem>
                            <MenuItem sub>
                                <MenuItemLink text="Create Staff" to="/admin/admin-add-staff" />
                            </MenuItem>
                        </MenuSub> */}
                    </MenuItem>
                    <MenuItem sub>
                        <MenuItemLink imageicon="assign-test" text="Mock Test" onClick={menuToggle} sub />
                        <MenuSub>
                            <MenuItem sub>
                                <MenuItemLink text="View Test" to="/view-test" />
                            </MenuItem>
                            <MenuItem sub>
                                <MenuItemLink text="Create Test" to="/create-test" />
                            </MenuItem>
                            <MenuItem sub>
                            </MenuItem>
                        </MenuSub>
                    </MenuItem>
                    <MenuItem sub>
                                <MenuItemLink  imageicon="plan-pricing" text="Manage Plan & Pricing" to="/view-plan-pricing" />
                            </MenuItem>
                    <MenuItem sub>
                        <MenuItem>
                            <MenuItemLink imageicon="assign-test" text="Manage Question Pattern" to="/view-question-pattern" />
                        </MenuItem>
                    </MenuItem>


                    <MenuItem sub>
                        <MenuItemLink imageicon="support" text="Score Feeback" to="/view-score-feedback" />
               
                    </MenuItem>

                    <MenuItem sub>
                        <MenuItemLink imageicon="promo-code" text="Manage Promocode" onClick={menuToggle} sub />
                        <MenuSub>
                            <MenuItem sub>
                                <MenuItemLink text="View Promocode" to="/view-promocode" />
                            </MenuItem>
                            {/* <MenuItem sub>
                                <MenuItemLink text="Add Promocode" to="/add-promocode" />
                            </MenuItem> */}
                            <MenuItem sub>
                            </MenuItem>
                        </MenuSub>
                    </MenuItem>
                        <MenuItem sub>
                            <MenuSub>
                            </MenuSub>
                            <MenuItem>
                                <MenuItemLink imageicon="branch-management" text="Branch Management" to="/view-branch" />
                            </MenuItem>
                        </MenuItem>
                </>

                : userData.login_type == "2" ?
                    <>
                        <MenuItem sub>
                            <MenuItemLink imageicon="support" text="Support" onClick={menuToggle} sub  fill={instDomain?.inst_profile?.color_code} />
                            <MenuSub>
                                <MenuItem>
                                    <MenuItemLink text="Ticket" to="/add-ticket" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="View ticket" to="/view-tickets" />
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>
                        <MenuItem sub>
                            <MenuItemLink imageicon="result-management" text="Result Management" onClick={menuToggle} sub fill={instDomain?.inst_profile?.color_code}/>
                            <MenuSub>
                            <MenuItem>
                            <MenuItemLink text="PTE" to="/result-management/student-results" />
                        </MenuItem>
                                {/* <MenuItem>
                                    <MenuItemLink text="IELTS" to="/otherpages/ielts" />
                                </MenuItem> */}
                            </MenuSub>
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink imageicon="exam-management" text="Exam" to="/student/mock/list" fill={instDomain?.inst_profile?.color_code} />
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink imageicon="settarget" text="Study Center" to="/student/set-target"  fill={instDomain?.inst_profile?.color_code}/>
                        </MenuItem>
                        {institutePlan?.material_management_institute &&
                        <MenuItem sub>
                            <MenuItemLink imageicon="material-management" text="Material Management" onClick={menuToggle} sub  fill={instDomain?.inst_profile?.color_code}/>
                            <MenuSub>
                                <MenuItem>
                                    <MenuItemLink text="Material List" to="/material-management/material-list" />
                                </MenuItem>
                                {/* <MenuItem>
                                    <MenuItemLink text="Add Material" to="/material-management/add-material" />
                                </MenuItem> */}
                            </MenuSub>
                        </MenuItem>
}
                        {/* <MenuItem>
                        <MenuItemLink imageicon="signout" text="Log Out" to="/exam/pte/pte-scored-test-intro" />
                    </MenuItem> */}
                    </>
                    :

                    <>
                        {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Student Management")) && <MenuItem sub>
                            <MenuItemLink imageicon="student-management"  fill={userData?.inst_profile?.color_code} text="Student Management" onClick={menuToggle} sub />
                            <MenuSub>
                                <MenuItem>
                                    <MenuItemLink text="Students" to="/student-management/student-list" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="Add Student" to="/student-management/add-student" />
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>}
                        {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Notification")) && <MenuItem sub>
                            <MenuItemLink imageicon="notification_icon" fill={userData?.inst_profile?.color_code} text="Notification" onClick={menuToggle} sub />
                            <MenuSub>
                                {/* <MenuItem>
                                    <MenuItemLink text="Add Notification" to="/add-notification" />
                                </MenuItem> */}
                                <MenuItem>
                                    <MenuItemLink text="Notifications" to="/view-notification" />
                                </MenuItem>
                                {/* <MenuItem>
                                    <MenuItemLink text="Add Announcement" to="/add-announcement" />
                                </MenuItem> */}
                                <MenuItem>
                                    <MenuItemLink text="Announcements" to="/view-announcement" />
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>
                        }
                        {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Lead Management")) && <MenuItem sub>
                            <MenuItemLink imageicon="lead-management"  fill={userData?.inst_profile?.color_code}  text="Lead Management" onClick={menuToggle} sub />
                            <MenuSub>

                                <MenuItem>
                                    <MenuItemLink text="Enquiries" to="/lead-management/enquiry-list" />
                                </MenuItem>
                            <MenuItem>
                                    <MenuItemLink text="Followups" to="/lead-management/followup-list" />
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>}

                            {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Batch Management")) &&
                            <MenuItem>
                                <MenuItemLink imageicon="batch-management" fill={userData?.inst_profile?.color_code}    text="Batch Management" to="/view-batch" />
                            </MenuItem>}
                            {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Branch Management")) &&
                            <MenuItem>
                                <MenuItemLink imageicon="branch-management" fill={userData?.inst_profile?.color_code}   text="Branch Management" to="/view-branch" />
                            </MenuItem>}
                            {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Result Management")) &&
                            <MenuItem>
                                <MenuItemLink imageicon="result-management" fill={userData?.inst_profile?.color_code} text="Result Management" to="/result-management/student-results" />
                            </MenuItem>}

                        {(userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Credit Management")) && <MenuItem sub>
                            <MenuItemLink imageicon="myaccount"  fill={userData?.inst_profile?.color_code} text="My Account" onClick={menuToggle} sub />
                            <MenuSub>
                                <MenuItem>
                                    <MenuItemLink text="Add Credit" to="/my-account/add-credits" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="Credit History" to="/my-account/credit-history-list" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="Invoice" to="/my-account/invoice-list" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="Plan & Pricing" to="/my-account/plan-pricing" />
                                </MenuItem>
                                <MenuItem>
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>}
                        
                 
                        <MenuItem>
                                <MenuItemLink imageicon="other-management" fill={userData?.inst_profile?.color_code || instDomain?.inst_profile?.color_code}  text="Institute Settings" to="/institute-settings" />
                            </MenuItem>
                            <MenuItem >
                                <MenuItemLink  imageicon="ticket" fill={userData?.inst_profile?.color_code || instDomain?.inst_profile?.color_code}   text="Tickets" to="/view-all-tickets" />
                            </MenuItem>

                       

                        {/* <MenuItem sub>
                        <MenuItemLink imageicon="question" text="Questions" onClick={menuToggle} sub />
                        <MenuSub>
                            <MenuItem sub>
                                <MenuItemLink text="PTE" onClick={menuToggle} sub />
                                <MenuSub>
                                    <MenuItem sub>
                                        <MenuItem>
                                            <MenuItemLink text="Add Questions" to="/material-management/add-questions" />
                                        </MenuItem>
                                    </MenuItem>
                                </MenuSub>
                            </MenuItem>
                            <MenuItem sub>
                                <MenuItemLink text="IELTS" onClick={menuToggle} sub />
                                <MenuSub>
                                    <MenuItem>
                                        <MenuItemLink text="Reading Test Paper" to="/material-management/ielts/reading/test-paper" />
                                    </MenuItem>
                                    <MenuItem>
                                        <MenuItemLink text="Listening Test Paper" to="/material-management/ielts/listening/test-paper" />
                                    </MenuItem>
                                </MenuSub>
                            </MenuItem>
                        </MenuSub>
                    </MenuItem> */}

                        {institutePlan?.material_management_institute && (userData?.permission_routes[0] == "all" || checkPermissionRouteForSidebar(userData?.selected_permission_routes, "Material Management")) && <MenuItem sub>
                            <MenuItemLink imageicon="material-management" text="Material Management" onClick={menuToggle} sub  fill={instDomain?.inst_profile?.color_code}/>
                            <MenuSub>
                                <MenuItem>
                                    <MenuItemLink text="Material List" to="/material-management/material-list" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="Add Material" to="/material-management/add-material" />
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>}
                             
                        <MenuItem sub>
                            <MenuItemLink imageicon="support" text="Admin Support" onClick={menuToggle} sub  fill={instDomain?.inst_profile?.color_code} />
                            <MenuSub>
                                <MenuItem>
                                    <MenuItemLink text="Ticket" to="/add-inst-ticket" />
                                </MenuItem>
                                <MenuItem>
                                    <MenuItemLink text="View ticket" to="/view-inst-tickets" />
                                </MenuItem>
                            </MenuSub>
                        </MenuItem>
                    </>
            }
        </MenuList>
    )
}

export default Menu