
import React, { useState } from 'react'
import { Routes, Route } from "react-router-dom";
import Blank from "../../pages/Blank";
import Home from "../../pages/Home";
import HomeEcommerce from "../../pages/HomeEcommerce";
import HomeProject from "../../pages/HomeProject";
import HomeMarketing from "../../pages/HomeMarketing";
import HomeNFT from "../../pages/HomeNFT";

// apps
import AppCalendar from "../../pages/AppCalendar";
import KanbanBasic from "../../pages/kanban/KanbanBasic";
import KanbanCustom from "../../pages/kanban/KanbanCustom";
import Chats from "../../pages/apps/chat/Chats";
import Inbox from "../../pages/apps/mailbox/Inbox";

// user manage
import UserList from "../../pages/user-manage/UserList";
import UserCards from "../../pages/user-manage/UserCards";
import UserProfile from "../../pages/user-manage/UserProfile";
import UserEdit from "../../pages/user-manage/UserEdit";

// admin
import Profile from "../../pages/admin/Profile";
import ProfileSettings from "../../pages/admin/ProfileSettings";

// ecommerce
import Products from "../../pages/ecommerce/Products";
import Categories from "../../pages/ecommerce/Categories";
import AddProduct from "../../pages/ecommerce/AddProduct";
import EditProduct from "../../pages/ecommerce/EditProduct";
import AddCategory from "../../pages/ecommerce/AddCategory";
import EditCategory from "../../pages/ecommerce/EditCategory";

// ui elements
import Accordion from "../../pages/components/Accordion";
import Alert from "../../pages/components/Alert";
import Badge from "../../pages/components/Badge";
import Breadcrumb from "../../pages/components/Breadcrumb";
import Buttons from "../../pages/components/Buttons";
import ButtonGroup from "../../pages/components/ButtonGroup";
import Cards from "../../pages/components/Cards";
import Carousel from "../../pages/components/Carousel";
import CloseButton from "../../pages/components/CloseButton";
import Collapse from "../../pages/components/Collapse";
import Dropdowns from "../../pages/components/Dropdowns";
import ListGroup from "../../pages/components/ListGroup";
import Modal from "../../pages/components/Modal";
import Tabs from "../../pages/components/Tabs";
import Offcanvas from "../../pages/components/Offcanvas";
import Pagination from "../../pages/components/Pagination";
import Placeholders from "../../pages/components/Placeholders";
import Popovers from "../../pages/components/Popovers";
import Progress from "../../pages/components/Progress";
import Spinners from "../../pages/components/Spinners";
import Toasts from "../../pages/components/Toasts";
import Tooltips from "../../pages/components/Tooltips";

// utilities
import Misc from "../../pages/utilities/Misc";
import Typography from "../../pages/utilities/Typography";
import Images from "../../pages/utilities/Images";
import Tables from "../../pages/utilities/Tables";
import Background from "../../pages/utilities/Background";
import Borders from "../../pages/utilities/Borders";
import Colors from "../../pages/utilities/Colors";
import Flex from "../../pages/utilities/Flex";
import Sizing from "../../pages/utilities/Sizing";
import Spacing from "../../pages/utilities/Spacing";

// layout
import Breakpoints from "../../pages/layout/Breakpoints";
import Containers from "../../pages/layout/Containers";
import Gutters from "../../pages/layout/Gutters";
// fomrs
import FormControl from "../../pages/forms/FormControl";
import FormSelect from "../../pages/forms/FormSelect";
import DateTime from "../../pages/forms/DateTime";
import FormUpload from "../../pages/forms/FormUpload";
import InputGroup from "../../pages/forms/InputGroup";
import FloatingLabels from "../../pages/forms/FloatingLabels";
import ChecksRadios from "../../pages/forms/ChecksRadios";
import FormRange from "../../pages/forms/FormRange";
import FormValidation from "../../pages/forms/FormValidation";
import FormLayout from "../../pages/forms/FormLayout";
import QuillPreview from "../../pages/forms/editors/QuillPreview";
import TinymcePreview from "../../pages/forms/editors/TinymcePreview";

// other pages
import DataTablePreview from "../../pages/DataTablePreview";
import ChartsPreview from "../../pages/ChartsPreview";
import Sweetalert from "../../pages/Sweetalert";
import NotFound from "../../pages/error/NotFound";
import IconsPreview from "../../pages/IconsPreview";
import StudentInbox from "../../pages/StudentInbox";
import SuperAdminDashboard from "../../pages/superadmin/SuperAdminDashboard";
import SuperAdminSectionWiseTest from "../../pages/superadmin/SuperAdminSectionWiseTest";
import SuperAdminSelectMockTest from "../../pages/superadmin/SuperAdminSelectMockTest";
import EditTeam from "../../pages/admin/EditTeam";
import AddSectionWiseTest from "../../pages/studentpages/AddSectionWiseTest";
import AddMockTest from "../../pages/studentpages/AddMockTest";
import StudentDetail from "../../pages/studentpages/StudentDetail";
import AdminDashboard from "../../pages/admin/AdminDashboard";
import AddQuestions from "../../pages/questions/AddQuestions";
import AddCredit from "../../pages/otherpages/AddCredit";
import AddEmployee from "../../pages/otherpages/AddEmployee";
import Batch from "../../pages/otherpages/Batch";
import CreditHistory from "../../pages/otherpages/CreditHistory";
import CustomSetting from "../../pages/otherpages/CustomSetting";
import Enquiry from "../../pages/otherpages/Enquiry";
import FollowupDetails from "../../pages/otherpages/FollowupDetails";
import Ielts from "../../pages/otherpages/Ielts";
import Invoice from "../../pages/otherpages/Invoice";
import MockTest from "../../pages/otherpages/MockTest";
import Profiles from "../../pages/otherpages/Profiles";
import Pte from "../../pages/otherpages/Pte";
import AddTicket from "../../pages/otherpages/AddTicket";
import InstituteRegister from "../../pages/InstituteRegister";
import PteReadAloud from "../../pages/questions/PTE/SpeakingTest/PteReadAloud";
import PteDescribeImage from "../../pages/questions/PTE/SpeakingTest/PteDescribeImage";
import PteAnsShortQue from "../../pages/questions/PTE/SpeakingTest/PteAnsShortQue";
import PteReTellLecture from "../../pages/questions/PTE/SpeakingTest/PteReTellLecture";
import PteRepeatSentence from "../../pages/questions/PTE/SpeakingTest/PteRepeatSentence";
import PteWritingEssay from "../../pages/questions/PTE/WritingTest/PteWritingEssay";
import PteSummarizeWrittenText from "../../pages/questions/PTE/WritingTest/PteSummarizeWrittenText";
import PteReadingFillinBlanks from "../../pages/questions/PTE/ReadingTest/PteReadingFillinBlanks";
import PteReadingMultiChoiceRadio from "../../pages/questions/PTE/ReadingTest/PteReadingMultiChoiceRadio";
import PteReadingMultiChoiceCheck from "../../pages/questions/PTE/ReadingTest/PteReadingMultiChoiceCheck";
import PterReadingCorrectOrder from "../../pages/questions/PTE/ReadingTest/PterReadingCorrectOrder";
import PteReadingWritingFillinBlanks from "../../pages/questions/PTE/ReadingTest/PteReadingWritingFillinBlanks";
import PteListeningSummarizeSpokenTest from "../../pages/questions/PTE/ListeningTest/PteListeningSummarizeSpokenTest";
import PteListeningMultiChoiceCheck from "../../pages/questions/PTE/ListeningTest/PteListeningMultiChoiceCheck";
import PteListeningFillinBlanks from "../../pages/questions/PTE/ListeningTest/PteListeningFillinBlanks";
import PteListeningCorrectSummary from "../../pages/questions/PTE/ListeningTest/PteListeningCorrectSummary";
import PteListeningMultiChoiceRadio from "../../pages/questions/PTE/ListeningTest/PteListeningMultiChoiceRadio";
import PteListeningSelectMissingWord from "../../pages/questions/PTE/ListeningTest/PteListeningSelectMissingWord";
import PteListeningHighlightIncorrectWords from "../../pages/questions/PTE/ListeningTest/PteListeningHighlightIncorrectWords";
import PteListeningDictation from "../../pages/questions/PTE/ListeningTest/PteListeningDictation";
import IeltsReadingTestPaper from "../../pages/questions/IELTS/ReadingTest/IeltsReadingTestPaper";
import IeltsListeningTestPaper from "../../pages/questions/IELTS/ListeningTest/IeltsListeningTestPaper";
import AddStudent from "../../pages/studentpages/AddStudent";
import StudentList from "../../pages/studentpages/StudentList";
import StudentDashboard from "../../pages/studentpages/StudentDashboard";
import StudentMockTest from '../../pages/studentpages/StudentMockTest';
import StudentResult from '../../pages/studentresult/StudentResult/StudentResults';
import ViewTeam from "../../pages/admin/viewTeam"


// for student exam routes start

import ReadAloud from '../../pages/exam/PTE/SpeakingTest/ReadAloud';
import AnswerShortQuestions from '../../pages/exam/PTE/SpeakingTest/AnswerShortQuestions';
import RepeatSenetence from '../../pages/exam/PTE/SpeakingTest/RepeatSentence';
import DescribeImage from '../../pages/exam/PTE/SpeakingTest/DescribeImage';
import ReTellLectureImage from '../../pages/exam/PTE/SpeakingTest/ReTellLectureImage';
import ReTellLectureVideo from '../../pages/exam/PTE/SpeakingTest/ReTellLectureVideo';
import SummarizeWrittenText from '../../pages/exam/PTE/WritingTest/SummarizeWrittenText';
import WriteEssay from '../../pages/exam/PTE/WritingTest/WriteEssay';
import ReadWriteFillinBlanks from '../../pages/exam/PTE/ReadingTest/ReadWriteFillinBlanks';
import CreateMockTest from '../../pages/mock-test/CreateMockTest';
import CreateOwnMockTest from '../../pages/mock-test/CreateOwnMockTest';
import QuestionsListMUI from '../../pages/mock-test/QuestionsListMUI';
import StudentChangePassword from '../../pages/studentpages/StudentChangePassword';
import ReadingMultichoice from '../../pages/exam/PTE/ReadingTest/ReadingMultichoice';
import ReadingMultichoiceSingle from '../../pages/exam/PTE/ReadingTest/ReadingMultichoiceSingle';
import ReadingReOrder from '../../pages/exam/PTE/ReadingTest/ReadingReOrder';
import SummarizeSpokenText from '../../pages/exam/PTE/ListeningTest/SummarizeSpokenText';
import MultiChoiceMulti from '../../pages/exam/PTE/ListeningTest/MultiChoiceMulti';
import FillInBlanks from '../../pages/exam/PTE/ListeningTest/FillInBlanks';
import MultiChoiceSingle from '../../pages/exam/PTE/ListeningTest/MultiChoiceSingle';
import SelectMissingWord from '../../pages/exam/PTE/ListeningTest/SelectMissingWord';
import Dictation from '../../pages/exam/PTE/ListeningTest/Dictation';
import HighlightCorrectSummary from '../../pages/exam/PTE/ListeningTest/HighlightCorrectSummary';
import HighlightIncorrectWord from '../../pages/exam/PTE/ListeningTest/HighlightIncorrectWord';
import ReadingFillInBlanks from '../../pages/exam/PTE/ReadingTest/ReadingFillInBlanks';
import PTEScoredTestIntro from '../../pages/exam/PTE/Istructions/PTEScoredTestIntro';

import InstituteSettings from '../../pages/admin/InstituteSettings';
import AssignTest from '../../pages/admin/AssignTest';
import NotificationPage from '../../pages/ViewTickets';
import PlanPricing from '../../pages/myaccount/PlanPricing';
import InvoiceList from '../../pages/myaccount/InvoiceList';
import CreditHistoryList from '../../pages/myaccount/CreditHistoryList';
import AddCredits from '../../pages/myaccount/AddCredits';
import MockTestResults from '../../pages/studentresult/MockTestResults';
import SectionWiseTestResults from '../../pages/studentresult/SectionWiseTestResults';
import MaterialList from '../../pages/material-management/MaterialList';
import AddMaterial from '../../pages/material-management/AddMaterial';
import AddFund from '../../pages/myaccount/AddFund';
import AddBatch from '../../pages/batch/AddBatch';

import BatchList from '../../pages/batch/ViewBatch';
import Addbranch from '../../pages/branch/AddBranch';
import BranchList from '../../pages/branch/ViewBranch';
// import PracticePteReadAloud from '../../pages/practice-test/PTE/SpeakingTest/PracticePteReadAloud';
// import PracticePteReapeatSentence from '../../pages/practice-test/PTE/SpeakingTest/PracticePteRepeatSentence';
// import PracticePteDescribeImage from '../../pages/practice-test/PTE/SpeakingTest/PracticePteDescribeImage';
// import PracticePteReTellLecture from '../../pages/practice-test/PTE/SpeakingTest/PracticePteReTellLecture';
// import PracticePteAnswerShortQue from '../../pages/practice-test/PTE/SpeakingTest/PracticePteAnswerShortQue';
// import PracticePteSummarizeText from '../../pages/practice-test/PTE/WrittingTest/PracticePteSummarizeText';
// import PracticePteWritingEssay from '../../pages/practice-test/PTE/WrittingTest/PracticePteWritingEssay';
// import PracticePteReadingWritingFIB from '../../pages/practice-test/PTE/ReadingTest/PracticePteReadingWritingFIB';
// import PracticePteReadingMultiChoiceCheck from '../../pages/practice-test/PTE/ReadingTest/PracticePteReadingMultiChoiceCheck';
// import PracticePteReadingMultiChoiceRadio from '../../pages/practice-test/PTE/ReadingTest/PracticePteReadingMultiChoiceRadio';
// import PracticePteReadingReOrder from '../../pages/practice-test/PTE/ReadingTest/PracticePteReadingReOrder';
// import PracticePteReadingFIB from '../../pages/practice-test/PTE/ReadingTest/PracticePteReadingFIB';
// import PracticePteSummarizeSpokenText from '../../pages/practice-test/PTE/ListeningTest/PracticePteSummarizeSpokenText';
// import PracticePteListeningMultiChoiceCheck from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningMultiChoiceCheck';
// import PracticePteListeningFIB from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningFIB';
// import PracticePteListeningMultiChoiceRadio from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningMultiChoiceRadio';
// import PracticePteListeningHighlightCorrectSummary from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningHighlightCorrectSummary';
// import PracticePteListeningSelectMissingWord from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningSelectMissingWord';
// import PracticePteListeningHIWS from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningHIWS';
// import PracticePteListeningWFDS from '../../pages/practice-test/PTE/ListeningTest/PracticePteListeningWFDS';
import PracticePte from '../../pages/practice-test/PTE/PracticePte';
import ModalContext from '../../context/ModalContext';
import InvalidPage from '../../pages/InvalidPage';
import AllTicketList from '../../pages/institutepages/AllTicketList';
import EnquiryList from '../../pages/studentpages/EnquiryList';
import AddNotification from '../../pages/otherpages/AddNotification';
import ViewNotification from '../../pages/ViewNotification';
import EnquiryDataTable from '../../pages/enquiry/EnquiryDataTable';
import FollowupsDataTable from '../../pages/followups/FollowupsDataTable';
import { AddReactionTwoTone } from '@mui/icons-material';
import AddAnnouncement from '../../pages/announcements/AddAnnouncement';
import ViewAnnouncement from '../../pages/announcements/ViewAnnouncement';
import EditNotification from '../../pages/otherpages/EditNotification';
import StudentListNotification from '../../pages/studentpages/StudentListNotification';
import NotificationContextProvider from '../../context/NotificationContext/NotificationContextProvider';
import EnquiryRequest from '../../pages/EnquiryRequest';

import ViewTicketsListPage from '../../pages/admin/ViewTicketsInst';
import OverrideContextProvider from '../../context/OverrideContext/OverrideContextProvider';
import AddInstTicket from '../../pages/otherpages/AddInstTicket';
import ViewInstTickets from '../../pages/ViewInstTickets';

// for student exam routes end

function InstituteRoutes() {
  return (
    <>
    {/* <OverrideContextProvider> */}
<NotificationContextProvider>
      <Routes>

        {/* add links in menu */}
        {/* <Route path="ro" element={<PracticePteReadingReOrder />} /> */}
        <Route path="institute-settings" element={<InstituteSettings />} />
        <Route path="view-all-tickets" element={<ViewTicketsListPage />} />

        <Route path="add-inst-ticket" element={<AddInstTicket />} />
          {/* <Route path="add-notification" element={<AddNotification/>}/> */}
          <Route path="view-inst-tickets" element={<ViewInstTickets />} />
        <Route path="assign-test" element={<AssignTest />} />
  

        {/* ------------------------------------------------------------------------- */}

        <Route path="blank" element={<Blank />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="invalid-page" element={<InvalidPage />} />
        <Route path="home-ecommerce" element={<HomeEcommerce />} />
        <Route path="home-project" element={<HomeProject />} />
        <Route path="home-marketing" element={<HomeMarketing />} />
        <Route path="home-nft" element={<HomeNFT />} />
        <Route path="view-team" element={<ViewTeam />} />


        <Route path="data-table" element={<DataTablePreview />} />
        <Route path="charts" element={<ChartsPreview />} />
        <Route path="sweetalert" element={<Sweetalert />} />

        <Route path="icons" element={<IconsPreview />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="/" element={<Home />} />

        {/* enquiry-request define here bcz if any institue is already login
         then below two public routes also work */}
        <Route path="enquiry-request/:branchId" element={<EnquiryRequest />} />
        <Route path="/enquiry-request" element={<EnquiryRequest />} />
        
        
        <Route path="*" element={<NotFound />} />


        <Route path="student-inbox" element={<StudentInbox />} />
        <Route path="institute-register" element={<InstituteRegister />} />
        <Route path="create-test" element={<CreateMockTest />} />
        <Route path="questions-list-mui" element={<QuestionsListMUI />} />


        <Route path="/add-batch" element={<AddBatch />} />
        <Route path="/view-batch" element={<BatchList />} />
        <Route path="/edit-batch/:_id" element={<AddBatch />} />

        {/* <Route path="/add-branch" element={<Addbranch />} /> */}
        <Route path="/view-branch" element={<BranchList />} />
        <Route path="/edit-branch/:_id" element={<Addbranch />} />



        {/* Student Management Start */}

        <Route path="/student-management/student-list-notification" element={<StudentListNotification />} />

        <Route path="student-management">
          <Route path="student-dashboard" element={<StudentDashboard />} />
          <Route path="student-list" element={<StudentList />} />
          {/* <Route path="student-list-notification" element={<StudentListNotification />} /> */}
          <Route path="student-detail/:id" element={<StudentDetail />} />
          <Route path="add-student" element={<AddStudent />} />
          <Route path="add-section-wise-test" element={<AddSectionWiseTest />} />
          <Route path="add-mock-test" element={<AddMockTest />} />
          <Route path="student-mock-test" element={<StudentMockTest />} />
          <Route path="student-change-password" element={<StudentChangePassword />} />
          <Route path="all-ticket-list" element={<AllTicketList />} />
        </Route>


        <Route path="lead-management">
          <Route path="enquiry-list" element={<EnquiryDataTable />} />
          <Route path="followup-list" element={<FollowupsDataTable />}/>
        
        </Route>

        {/* Student Management End */}


        {/* for student exam routes start */}

        {/* <Route path="exam">

            <Route path="pte">
              <Route path="pte-scored-test-intro" element={<PTEScoredTestIntro />} />
              <Route path="read_alouds" element={<ReadAloud />} />
              <Route path="answer_questions" element={<AnswerShortQuestions />} />
              <Route path="repeat_sentences" element={<RepeatSenetence />} />
              <Route path="describe_images" element={<DescribeImage />} />
              <Route path="retell_lectures" element={<ReTellLectureImage />} />
              <Route path="retell_lectures_video" element={<ReTellLectureVideo />} />
              <Route path="swts" element={<SummarizeWrittenText />} />
              <Route path="essays" element={<WriteEssay />} />
              <Route path="fib_wr" element={<ReadWriteFillinBlanks />} />
              <Route path="r_mcm" element={<ReadingMultichoice />} />
              <Route path="r_mcs" element={<ReadingMultichoiceSingle />} />
              <Route path="ro" element={<ReadingReOrder />} />
              <Route path="ssts" element={<SummarizeSpokenText />} />
              <Route path="l_mcm" element={<MultiChoiceMulti />} />
              <Route path="l_fib" element={<FillInBlanks />} />
              <Route path="l_mcs" element={<MultiChoiceSingle />} />
              <Route path="l_smw" element={<SelectMissingWord />} />
              <Route path="wfds" element={<Dictation />} />
              <Route path="l_hcs" element={<HighlightCorrectSummary />} />
              <Route path="hiws" element={<HighlightIncorrectWord />} />
              <Route path="fib_rd" element={<ReadingFillInBlanks />} />
            </Route>
          </Route> */}

        {/* for student exam routes end */}


        {/* for student Practice Test routes start */}

        {/* <Route path="practice">
            <Route path="pte">
              <Route path="practice_pte" element={<PracticePte />} />
              <Route path="read_alouds" element={<PracticePte />} />
              <Route path="repeat_sentences" element={<PracticePte />} />
              <Route path="describe_images" element={<PracticePte />} />
              <Route path="retell_lectures" element={<PracticePte />} />
              <Route path="answer_questions" element={<PracticePte />} />
              <Route path="swts" element={<PracticePte />} />
              <Route path="essays" element={<PracticePte />} />
              <Route path="fib_wr" element={<PracticePte />} />
              <Route path="r_mcm" element={<PracticePte />} />
              <Route path="ro" element={<PracticePte />} />
              <Route path="fib_rd" element={<PracticePte />} />
              <Route path="r_mcs" element={<PracticePte />} />
              <Route path="ssts" element={<PracticePte />} />
              <Route path="l_mcm" element={<PracticePte />} />
              <Route path="l_mcs" element={<PracticePte />} />
              <Route path="l_fib" element={<PracticePte />} />
              <Route path="l_hcs" element={<PracticePte />} />
              <Route path="l_smw" element={<PracticePte />} />
              <Route path="hiws" element={<PracticePte />} />
              <Route path="wfds" element={<PracticePte />} />
            </Route>
          </Route> */}

        {/* for student Practice Test routes end */}


        {/* My Account Tabs Start */}

        <Route path="my-account">
          <Route path="add-fund" element={<AddFund />} />
          <Route path="add-credits" element={<AddCredits />} />
          <Route path="credit-history-list" element={<CreditHistoryList />} />
          <Route path="invoice-list" element={<InvoiceList />} />
          <Route path="plan-pricing" element={<PlanPricing />} />
        </Route>

        {/* My Account Tabs End */}


        {/* Result Management Tabs Start */}

        <Route path="result-management">
          {/* <Route path="student-results" element={<StudentResult />} /> */}
          <Route path="student-results" element={<MockTestResults />} />
          {/* <Route path="sectionwise-results" element={<SectionWiseTestResults />} /> */}
        </Route>

        {/* Result Management Tabs End */}


        {/* Material Management Tabs Start */}

        <Route path="material-management">
          <Route path="add-material" element={<AddMaterial />} />
          <Route path="edit-material/:id" element={<AddMaterial />} />
          <Route path="material-list" element={<MaterialList />} />
        </Route>

        {/* Material Management Tabs End */}


        {/* Super Admin Tabs Start */}

        <Route path="superadmin">
          <Route path="super-admin-dashboard" element={<SuperAdminDashboard />} />
          <Route path="super-admin-section-wise-test" element={<SuperAdminSectionWiseTest />} />
          <Route path="super-admin-select-mock-test" element={<SuperAdminSelectMockTest />} />
        </Route>

        {/* Super Admin Tabs End */}

        <Route
          path="/edit-team/:_id"
          element={<EditTeam />}
        />
        {/* Admin Tabs Start */}
        <Route path="add-team" element={<EditTeam />} />


        <Route path="admin">
          <Route path="admin-profile" element={<AdminDashboard />} />
          <Route path="admin-edit-team" element={<EditTeam />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          <Route path="profile-settings" element={<ProfileSettings />} />
        </Route>

        <Route path="institute">
          <Route path="profile" element={<AdminDashboard />} />
          <Route path="addteam" element={<EditTeam />} />
          <Route path="change-password/:id" element={<StudentChangePassword />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          <Route path="profile-settings" element={<ProfileSettings />} />
        </Route>

        {/* Admin Tabs End */}


        {/*
        <Route path="material-management">
          <Route path="add-questions" element={<AddQuestions />} />
          <Route path="pte">
            <Route path="speaking">
              <Route path="read_alouds" element={<PteReadAloud />} />
              <Route path="repeat_sentences" element={<PteRepeatSentence />} />
              <Route path="describe_images" element={<PteDescribeImage />} />
              <Route path="retell_lectures" element={<PteReTellLecture />} />
              <Route path="answer_questions" element={<PteAnsShortQue />} />
            </Route>
            <Route path="writing">
              <Route path="swts" element={<PteSummarizeWrittenText />} />
              <Route path="essays" element={<PteWritingEssay />} />
            </Route>
            <Route path="reading">
              <Route path="fib_wr" element={<PteReadingWritingFillinBlanks />} />
              <Route path="r_mcm" element={<PteReadingMultiChoiceCheck />} />
              <Route path="ro" element={<PterReadingCorrectOrder />} />
              <Route path="r_mcs" element={<PteReadingMultiChoiceRadio />} />
              <Route path="fib_rd" element={<PteReadingFillinBlanks />} />
            </Route>
            <Route path="listening">
              <Route path="ssts" element={<PteListeningSummarizeSpokenTest />} />
              <Route path="l_mcm" element={<PteListeningMultiChoiceCheck />} />
              <Route path="l_fib" element={<PteListeningFillinBlanks />} />
              <Route path="l_hcs" element={<PteListeningCorrectSummary />} />
              <Route path="l_mcs" element={<PteListeningMultiChoiceRadio />} />
              <Route path="l_smw" element={<PteListeningSelectMissingWord />} />
              <Route path="hiws" element={<PteListeningHighlightIncorrectWords />} />
              <Route path="wfds" element={<PteListeningDictation />} />
            </Route>
          </Route>
          <Route path="ielts">
            <Route path="reading">
              <Route path="test-paper" element={<IeltsReadingTestPaper />} />
            </Route>
            <Route path="listening">
              <Route path="test-paper" element={<IeltsListeningTestPaper />} />
            </Route>
          </Route>
        </Route> */}


        {/* Other Pages Start */}
        <Route path="lead-management" element={<FollowupDetails />} />
        <Route path="add-ticket" element={<AddTicket />} />

        <Route path="otherpages">
          <Route path="add-credit" element={<AddCredit />} />
          <Route path="add-employee" element={<AddEmployee />} />
          <Route path="batch" element={<Batch />} />
          <Route path="credit-history" element={<CreditHistory />} />
          <Route path="custom-setting" element={<CustomSetting />} />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="lead-management" element={<FollowupDetails />} />
          <Route path="ielts" element={<Ielts />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="mock-test" element={<MockTest />} />
          <Route path="profiles" element={<Profiles />} />
          <Route path="pte" element={<Pte />} />

          <Route path="notification" element={<NotificationPage />} />
        </Route>

        {/* Other Pages End */}


        <Route path="apps">
          <Route path="calendar" element={<AppCalendar />} />
          <Route path="kanban/basic" element={<KanbanBasic />} />
          <Route path="kanban/custom" element={<KanbanCustom />} />
          <Route path="chats" element={<Chats />} />
          <Route path="inbox" element={<Inbox />} />
        </Route>

        <Route path="user-manage">
          <Route path="user-list" element={<UserList />} />
          <Route path="user-cards" element={<UserCards />} />
          <Route path="user-profile/:id" element={<UserProfile />} />
          <Route path="user-edit/:id" element={<UserEdit />} />
        </Route>

        <Route path="ecommerce">
          <Route path="products" element={<Products />} />
          <Route path="categories" element={<Categories />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route path="edit-product/:id" element={<EditProduct />} />
          <Route path="add-category" element={<AddCategory />} />
          <Route path="edit-category/:id" element={<EditCategory />} />
        </Route>

        <Route path="ui-elements">
          <Route path="accordion" element={<Accordion />} />
          <Route path="alert" element={<Alert />} />
          <Route path="badge" element={<Badge />} />
          <Route path="breadcrumb" element={<Breadcrumb />} />
          <Route path="buttons" element={<Buttons />} />
          <Route path="button-group" element={<ButtonGroup />} />
          <Route path="cards" element={<Cards />} />
          <Route path="carousel" element={<Carousel />} />
          <Route path="close-button" element={<CloseButton />} />
          <Route path="collapse" element={<Collapse />} />
          <Route path="dropdowns" element={<Dropdowns />} />
          <Route path="list-group" element={<ListGroup />} />
          <Route path="modal" element={<Modal />} />
          <Route path="tabs" element={<Tabs />} />
          <Route path="offcanvas" element={<Offcanvas />} />
          <Route path="pagination" element={<Pagination />} />
          <Route path="placeholders" element={<Placeholders />} />
          <Route path="popovers" element={<Popovers />} />
          <Route path="progress" element={<Progress />} />
          <Route path="spinners" element={<Spinners />} />
          <Route path="toasts" element={<Toasts />} />
          <Route path="tooltips" element={<Tooltips />} />
        </Route>

        <Route path="utilities">
          <Route path="misc" element={<Misc />} />
          <Route path="typography" element={<Typography />} />
          <Route path="images" element={<Images />} />
          <Route path="tables" element={<Tables />} />
          <Route path="background" element={<Background />} />
          <Route path="borders" element={<Borders />} />
          <Route path="colors" element={<Colors />} />
          <Route path="flex" element={<Flex />} />
          <Route path="sizing" element={<Sizing />} />
          <Route path="spacing" element={<Spacing />} />
        </Route>

        <Route path="layout">
          <Route path="breakpoints" element={<Breakpoints />} />
          <Route path="containers" element={<Containers />} />
          <Route path="gutters" element={<Gutters />} />
        </Route>

        <Route path="forms">
          <Route path="form-control" element={<FormControl />} />
          <Route path="form-select" element={<FormSelect />} />
          <Route path="date-time" element={<DateTime />} />
          <Route path="form-upload" element={<FormUpload />} />
          <Route path="input-group" element={<InputGroup />} />
          <Route path="floating-labels" element={<FloatingLabels />} />
          <Route path="checks-radios" element={<ChecksRadios />} />
          <Route path="form-range" element={<FormRange />} />
          <Route path="form-validation" element={<FormValidation />} />
          <Route path="form-layout" element={<FormLayout />} />
        </Route>

        <Route path="editors">
          <Route path="quill" element={<QuillPreview />} />
          <Route path="tinymce" element={<TinymcePreview />} />
        </Route>
        <Route path="add-notification" element={<AddNotification />} />
        <Route path="notification/:id" element={<EditNotification />} />
        <Route path="view-notification" element={<ViewNotification />} />
        <Route path="add-announcement" element={<AddAnnouncement />} />
        <Route path="view-announcement" element={<ViewAnnouncement/>} />
      </Routes>
      </NotificationContextProvider>
      {/* </OverrideContextProvider> */}
    </>
  )
}

export default InstituteRoutes
