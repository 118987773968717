import React, { useState } from 'react'
import NotificationContext from './NotificationContext'

const NotificationContextProvider = ({children}) => {
    const [notificationData,setNotificationData] =useState()
    const [notificationImage,setNotificationImage] =useState()
  return (
    <NotificationContext.Provider value={{notificationData,setNotificationData,setNotificationImage,notificationImage}}>

      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationContextProvider
