import React, { useState, useEffect, useContext } from "react";
import countryList1 from "../country";
import { Col, Card, Button, Alert } from "react-bootstrap";
import { Form, Row } from "react-bootstrap";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import AuthApi from "../api/auth";
import Layout from "../layout/fullpage";
import DatePicker from "../components/Form/DatePicker";
import Swal from "sweetalert2/src/sweetalert2.js";
import Swal2 from 'sweetalert2';

import AlertDismissible from "../components/UiElements/alert/AlertDismissible";
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
  Icon,
} from "../components";
import ImageSlider from "./ImageSlider";
import styled from "styled-components";
import TimePicker from "../components/Form/TimePicker";
import Context from "../context";
import PhoneFlag from "../components/Form/PhoneFlag";

function ContactInformationRequest() {
  let { id } = useParams();
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);


  useEffect(() => {
    
        document.documentElement.style.setProperty('--theme-color',"#1682FB");
     

},[])


let navigate = useNavigate();
  let api = new AuthApi();
  const [contactPage, setContactPage] = useState(true);
  const [countryList, setCountryList] = useState(countryList1);
  const [formData, setFormData] = useState({
    login_type: 1,
    roleId: "64b26bf0a4437fc38fcb3374",
    inst_profile: {
      inst_contact_method: "Call/SMS/Email",
      inst_contact_findout: "Social Media Platform",
      inst_client_service: "IELTS/TOFEL Coaching",
    
      color_code: "#000000",
    },
    permission_routes: ["all"],
    banner_images: [],
  });

  console.log("banner_images", formData);
  let { business_certificate, inst_profile } = formData;
  console.log('inst_profile?.inst_contact_source_country_student :>> ', countryList);
  useEffect(() => {
    if (!id) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setFormData((prevData) => ({
              ...prevData,
              ["inst_profile"]: {
                ...formData.inst_profile,
                ["latitude"]: position.coords.latitude.toString(),
                ["longitude"]: position.coords.longitude.toString(),
              },
            }));
          },
          (error) => {
            console.error(error);
          }
        );
      }
      else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else {
      getInstituteDetail();
    }
  }, []);

  const [validated, setValidated] = useState(false);
  const [countStep, setCountStep] = useState(1);
  const [fileName, setFileName] = useState({
    fileData: {
      business_certificate: "",

      logo: "",
    },
    banner_images: [],
  });

  const { fileData, banner_images: file_banner_image } = fileName
  const [prevUploadedFileName, setPrevUploadedFileName] = useState(false)

  const { inst_contact_firstname } = formData?.inst_profile
  const { banner_images } = formData
  console.log('error?.inst_contact_firstname :>> ', inst_contact_firstname);
  const validateForm = () => {
    let newError = {}
    if (!inst_contact_firstname || inst_contact_firstname === '') {
      newError.inst_contact_firstname = "Please enter Owner's First Name"
    }
    if (banner_images?.length == 0) {
      newError.banner_images = "At least One is required."

    }
    if (!business_certificate || business_certificate === '') {
      newError.business_certificate = "This is required."

    }

    return newError
  }


  const nextStep = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    const fromError = validateForm();
    if (Object.keys(fromError).length > 0) {
      setError(fromError)
    }

    // Check if all required fields are filled
    const requiredFields = form.querySelectorAll("[required]");
    let allFieldsFilled = true;

    requiredFields.forEach((field) => {
      if (!field.value.trim()) {
        allFieldsFilled = false;
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
      }
    });

    // Check if email fields have valid email format
    const emailFields = form.querySelectorAll('input[type="email"]');
    let allEmailsValid = true;

    emailFields.forEach((field) => {
      if (!field.checkValidity()) {
        allEmailsValid = false;
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
        field.classList.add("is-valid");
      }
    });

    // Check if mobile number fields have valid format (e.g., 10 digits)
    const mobileNumberFields = form.querySelectorAll('input[type="tel"]');
    let allMobileNumbersValid = true;

    mobileNumberFields.forEach((field) => {
      const mobileNumberRegex = /^\d{10}$/;
      if (!mobileNumberRegex.test(field.value.trim())) {
        allMobileNumbersValid = false;
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
        field.classList.add("is-valid");
      }
    });

    if (allFieldsFilled && allEmailsValid && allMobileNumbersValid) {
      let { business_certificate, email, password, mobile } = formData;
      if (
        business_certificate &&
        formData?.inst_profile?.inst_contact_firstname &&
        formData?.inst_profile?.inst_contact_lastname &&
        email &&
        password &&
        mobile &&
        formData?.inst_profile?.inst_contact_method &&
        formData?.inst_profile?.inst_contact_findout &&
        // formData?.inst_profile?.inst_contact_recuriting_students_year &&
        formData?.inst_profile?.inst_contact_source_country_student &&
        formData?.inst_profile?.inst_client_service
      ) {
        setContactPage(!contactPage);
        setCountStep(countStep + 1);
        setValidated(false);
      }
      // Perform form submission logic here (if needed)
      // form.submit();
    } else {
      setValidated(false);
    }
  };
  console.log("formData", formData);
  const prevStep = () => {
    setContactPage(!contactPage);
    setCountStep(countStep - 1);
    setPrevUploadedFileName(true)
  };
  let formDataObject = new FormData();

  const [error, setError] = useState({})
  const handleChange = (e, inst_profile, index) => {
    let { value, name } = e.target;
    if (name == "inst_business_name") {
      setFormData((prevData) => ({
        ...prevData,
        ["name"]: value?.replace(/\s+/g, " ").trim(),
        ["domain_name"]: value?.replace(/\s+/g, " ").trim(),
      }));
    }
    if (!!error[name]) {
      setError({ ...error, [name]: null })
    }

    if (inst_profile) {
      if (name == "business_logo") {
        setFormData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...formData.inst_profile,
            [name]: null,
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...formData.inst_profile,
            [name]: value?.replace(/\s+/g, " ").trim(),
            ['inst_contact_lastname']: "",
          },
        }));
      }
    } else {
      if (name == "logo" || name == "business_certificate") {

        if (isValidFileUploaded(e.target.files[0])) {
          setFormData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
          setFileName({ ...fileName, [name]: e.target.files[0].name })
          // if(name== "banner_images" ){
          //   setFileName((prev)=>({...prev,prev.banner_images}))
          // }
          setPrevUploadedFileName(false)
        }
        else {

          // Clear the file input
          e.target.value = null;
          setFormData((prevData) => ({ ...prevData, [name]: "" }));


          Swal2.fire({
            icon: "error",
            title: "Oops",

            text: "Image must be JPG/JPEG or PNG",
          })
        }

      } else {
        if (name == "banner_images") {
          if (isValidFileUploaded(e.target.files[0])) {
            let tempArray = [...formData?.banner_images];
            tempArray[index] = e.target.files[0];
            setFormData((prevData) => ({ ...prevData, [name]: tempArray }));
          }
          else {

            // Clear the file input
            e.target.value = null;
            setFormData((prevData) => ({ ...prevData, [name]: "" }));


            Swal2.fire({
              icon: "error",
              title: "Oops",

              text: "Image must be JPG/JPEG or PNG",
            })
          }
        } else {
          if (name == "credit") {
            setFormData((prevData) => ({
              ...prevData,
              [name]: Number(value),
            }))
          } else {
            setFormData((prevData) => ({
              ...prevData,
              [name]: value?.replace(/\s+/g, " ").trim(),
            }));
          }
        }
      }
    }
  };
  function removeElementByIndex(arr, index) {
    if (index >= 0 && index < arr.length) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)];
    } else {
      console.error("Index out of range");
      return arr;
    }
  }
  const [countryNumber, setCountryNumber] = useState('91');

  console.log('countryNumber :>> ', countryNumber);
  const submitForm = async (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(formData)) {
      console.log('key,value :>> ', key, value);
      if (key === "banner_images") {

        formDataObject.append("banner_images", null);
        formDataObject.append("business_certificate", null);
        formDataObject.append("logo", null);
        formDataObject.append("password", null);
        formDataObject.append("isDemoInstitute", true);
        

      } else if (key === "business_certificate" || key === "logo") {
        formDataObject.append(key, value);
      } else {
        if (typeof value === "object" && value !== null) {
          if (key == "inst_profile") {
            formDataObject.append("inst_profile", JSON.stringify(value))
          }
        } else {
          if(key == "mobile"){
            formDataObject.append(key,value );
            formDataObject.append("country_code",countryNumber );

          }
          else{
            formDataObject.append(key, value);
          }
        }
      }
    }
    
    if (id) {
      await api.institute_update(formDataObject).then((res) => {
        try {
          if (res) {
            Swal.fire({
              icon: "warning",
              title: "Good job!",
              text: "Institute update Successfully!",
            });
            navigate("/home");
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      try {
        // tochan
        // when institute book a demo the it should be deactivated, and admin can activate it
        // formDataObject.append("is_deleted",1)
        await api.institute_register(formDataObject).then((res) => {


          if (res?.status == false) {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: `${res?.msg}`,
            }).then((result) => {
              if (result.isConfirmed) {
                // Perform your onClick handler functionality here
                setContactPage(!contactPage);
                setCountStep(countStep - 1);
              }
            });
          }
          else if (res?.status == true) {
            Swal.fire({
              icon: "success",
              title: "Thanks For Your Interest In Hilingo",
              text: "Get ready to enhance teaching and learning with our platform.Check your email and whatsapp for demo details. Let's learn together!",
            }).then((result) => {
              if(result.isConfirmed){
                window.location.href = "https://hilingo.com/index.php/for-institute/"
              }
            });
            
            // navigate("https://hilingo.com/index.php/for-institute/");
          }
          else {

            formDataObject = new FormData()
          }
        })
      }
      catch (err) {


        console.log("ABC", err);
      }
    }

  };

  const getInstituteDetail = async () => {
    await api.get_user_by_id(id).then((res) => {
      if (res.status) {
        setFormData({ ...res?.data, banner_images: [] });
      }
    });
  };
  console.log("position.coords.longitude", formData);

  // const totalSteps = steps.length;

  // const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
  console.log('contactPage :>> ', contactPage);
  console.log('fileName :>> ', fileName, countStep, prevUploadedFileName);


  const isValidFileUploaded = (file) => {
    const validExtensions = ['png', 'jpeg', 'jpg']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
  }


  

  console.log(error, "errorw")
  return (
    <Layout title="Register" centered>
      <div className="container p-2 p-sm-4">
        <Row className="g-2 justify-content-center">
          <Col md="6" xs="12">
            <div className="nk-block-head text-center">
              <div className="nk-block-head-content">
                <Image
                  src="/images/hilingo-logo.png"
                  alt="logo"
                  className="logo-img"
                />
              </div>
            </div>

            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
              <Card.Body>
                <Form action="#" onSubmit={(e) => submitForm(e)}>
                  <Row className="gy-3">
                    <h2 className="text-center text-secondary">Get in touch</h2>
                    <h5 className="text-center text-secondary mb-5">Elevate your institue's success today! Join us for an amazing journey ahead</h5>
                  </Row>
                  <Row className="gy-3">

                    <Col className="col-6 " >
                      <Form.Group className="form-group">
                        <div className="form-control-wrap form-item">
                          <Form.Control
                            required
                            type="text"
                            id="lastname"
                            value={inst_profile?.inst_contact_firstname}
                            onChange={(e) => handleChange(e, "inst_profile")}
                            // onChange={(e) => handleChange(e, "inst_contact_firstname")}

                            name="inst_contact_firstname"
                            placeholder="Full Name"
                          // isInvalid={!!error?.inst_contact_firstname}
                          />
                          <Form.Label htmlFor="lastname">
                            Full Name
                          </Form.Label>
                          
                        </div>
                      </Form.Group>

                    </Col>
                    <Col className="col-6 " >
                      <Form.Group className="form-group">
                        <div className="form-control-wrap form-item">
                          <Form.Control
                            required
                            type="text"
                            id="lastname"
                            value={inst_profile?.inst_business_name}
                            onChange={(e) => handleChange(e, "inst_profile")}
                            name="inst_business_name"
                            placeholder="Institute Name"
                          />
                          <Form.Label htmlFor="lastname">
                            Institute Name
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            This is required.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>


                    <Col className="col-12">

                      <Form.Group className="form-group">
                        <div className="form-control-wrap form-item">
                          <Form.Control
                            required
                            type="email"
                            id="emailid"
                            value={formData?.email}
                            onChange={(e) => handleChange(e)}
                            // onChange={(e) => handleChange(e, "inst_contact_firstname")}

                            name="email"
                            placeholder="Email Id"
                          // isInvalid={!!error?.inst_contact_firstname}
                          />
                          <Form.Label htmlFor="emailid">
                            Email Id
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            This is not a valid Email .
                            {/* {error?.inst_contact_firstname} */}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>

                      {/* <Form.Group className="form-group">
                          <div className="form-control-wrap form-item">
                            <Form.Control
                              required
                              type="email"
                              id="emailid"
                              value={formData?.email}
                              onChange={(e) => handleChange(e)}
                              name="email"
                              placeholder="Email Id"
                            />
                            <Form.Label htmlFor="emailid">Email Id</Form.Label>
                            <Form.Control.Feedback type="invalid">
                              This is not a valid Email .
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group> */}
                    </Col>


                    <Col className="col-12">
<Form.Group className="form-group">
  <div className="form-control-wrap form-item">
    <Form.Control
      
      type="text"
      id="referral_code"
      value={formData?.refer_by}
      onChange={(e) => handleChange(e)}  
      name="refer_by"
      placeholder="Referral Code"    
    />
    <Form.Label htmlFor="refer_by">
    Referral Code ( optional )
    </Form.Label>

  </div>
</Form.Group>
</Col>

                    <Col className="col-12">
                      <Form.Group className="form-group">
                        {/* <Form.Label htmlFor="dateyear">Country</Form.Label> */}

                        <div className="form-control-wrap form-item institute-select">
                          <Form.Select
                            required
                            id="country"
                            value={
                              inst_profile?.inst_contact_source_country_student
                            }
                            aria-label="Default select example"
                            onChange={(e) => handleChange(e, "inst_profile")}
                            name="inst_contact_source_country_student"
                          >
                            <option value="">{"Country"}</option>
                            {countryList.length > 0 &&
                              countryList.map((item) => {
                                return (
                                  <>
                                    <option value={item.text}>
                                      {item.text}
                                    </option>
                                  </>
                                );
                              })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            This is required.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>


                        <Col class="col-6">
                        <PhoneFlag setCountryNumber={setCountryNumber}/>
                          </Col>    
                    <Col className="col-6">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap form-item">
                          <Form.Control
                            maxLength={10}
                            required
                            type="tel"
                            id="phonenumber"
                            value={
                              formData?.mobile?.length < 11
                                ? formData?.mobile
                                : ""
                            }
                            onChange={(e) => handleChange(e)}
                            placeholder="Phone Number"
                            name="mobile"
                          />
                         
                          <Form.Label htmlFor="phonenumber">
                            Phone Number
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            Phone Number Should be 10 digit.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="col-12">
                      <Form.Group className="form-group">
                        {/* <Form.Label htmlFor="studentnum">
                            Number of Students
                          </Form.Label> */}
                        <div className="form-control-wrap form-item institute-select">
                          <Form.Select
                             required
                            value={inst_profile?.inst_number_of_student}
                            name="inst_number_of_student"
                            onChange={(e) => handleChange(e, "inst_profile")}
                          >
                            <option value="">{"Number of Student"}</option>
                            <option value="1 - 10">1 - 10</option>
                            <option value="10 - 25">10 - 25</option>
                            <option value="25 - 50">25 - 50</option>
                            <option value="50 - 100">50 - 100</option>
                            <option value="100+">100+</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            This is required.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap form-item">
                          <Form.Control
                            required
                            type="text"
                            id="website"
                            value={inst_profile?.inst_business_url}
                            onChange={(e) => handleChange(e, "inst_profile")}
                            name="inst_business_url"

                          />
                          <Form.Label htmlFor="website">
                            Institute Facebook Page or Website Url
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="col-12">
                      <Form.Group className="form-group">
                        {/* <Form.Label htmlFor="message">Message</Form.Label> */}
                        <div className="form-control-wrap form-item">
                          <Form.Control
                            as="textarea"
                            onChange={(e) => handleChange(e, "inst_profile")}
                            name="msg"
                            value={inst_profile?.msg}
                            placeholder="message"
                            id="message"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col className="col-6 mx-auto">
                      <div className="text-center">

                        <Button type="submit">Book a Demo </Button>
                      </div>

                      
                    </Col>
                    <Row>
                      <h5 className="text-center text-secondary mt-2">
                        Experience our institue demo plan with FREE credits! Enjoy full access to all features and servers at absolutely no cost. Try before you buy!
                      </h5>
                      </Row>
                  </Row>
                </Form>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ContactInformationRequest;
