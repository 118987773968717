const { default: BaseApi } = require("../../baseApi");

class ScoreFeedbackApi extends BaseApi {
async scorefeebackregister(data) {
  console.log('newwwwdata :>> ', data);
    return await this.API({
      url: `auth/scorefeebackregister`,
      data: data,
      method: "post",
    });
  }


  async getScoreFeedback() {
    
      return await this.API({
        url: `auth/getScoreFeedback`,
    
        method: "get",
      });
    }

    async getScoreFeedbackById(id) {
    
      return await this.API({
        url: `auth/getScoreFeedbackById/${id}`,
    
        method: "get",
      });
    }
  

    async deleteScoreFeedbackById(id) {
    
      return await this.API({
        url: `auth/deleteScoreFeedbackById/${id}`,
    
        method: "delete",
      });
    }

    async updateScoreFeedback(data) {
      console.log('newwwwdata :>> ', data);
        return await this.API({
          url: `auth/updateScoreFeedback`,
          data: data,
          method: "post",
        });
      }
  
}
export default ScoreFeedbackApi