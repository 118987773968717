import { useState } from 'react';
import classNames from 'classnames';
import { Icon } from "../../../components";
function ExamHeader() {

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between bg-examtop align-items-center px-5'>
                <h3>PTE Academic - Palak</h3>
                <p className='d-flex flex-column text-secondary'>
                    <small><Icon name="clock"></Icon> <span>Time Remaining</span></small>
                    <small><Icon name="files"></Icon> <span>1 of 28</span></small>
                </p>
            </div>
            <p className='text-white bg-examblue px-5'><Icon name="notes-alt"></Icon><span> Notes</span></p>
        </div>
    )
}

export default ExamHeader