import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function RadioMarkComp() {

  return (
    <div className='p-3 answer-checkbox'>
      <Form.Check type="radio" checked className='wrong-ans' name="radio-checkbox" id="radio-A-1" label="The chemical formula of DNA" />

      <Form.Check type="radio" name="radio-checkbox" id="radio-B-1" label="The common fiber axis" />

      <Form.Check type="radio" name="radio-checkbox" id="radio-C-1" label="The X-ray evidence" />

      <Form.Check type="radio" name="radio-checkbox" id="radio-D-1" label="DNA consisting of two chains" />
    </div>
  );
}
export default RadioMarkComp;