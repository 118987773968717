import React, { useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
function SingleAnswer({click_Score, data, scoreModalShow,current_qst_data,answerSubmitted }) {

  useEffect(() =>{
    if(answerSubmitted && data?.length>0){

      
      // const releventDiv = document.getElementById("toScrollDiv");
      // console.log("releventDiv",releventDiv);
      // behavior: "smooth" parameter for smooth movement
      // releventDiv.scrollIntoView();
      // releventDiv.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      // window.scrollBy(0,700)
      console.log("CHLYA");
    }
  },[data,answerSubmitted])


  return (
    <>
      {data?.length > 0 &&
        data.map((item, index) => {
          return (
            <>
              <div id={index == 0 && "toScrollDiv"} className="practice-comments py-4">
                <div className="d-flex gap-3 align-items-center">
                  <div>
                    {/* <img
                      className="img-comment"
                      src="/images/listening-instructions.png"
                    ></img> */}
                    {/* <img src="/images/crown.svg" className="crown"></img> */}
                    <p className="no-logo-small">{item.user_id[0]?.name?.charAt(0)?.toUpperCase()}</p>
                  </div>
                  <small>
                    <b className="text-secondary">{item.user_id[0]?.name} </b>
                    <span className="text-middark ms-1">{item?.date}</span>
                  </small>
                </div>
                <div className="comment-container d-flex flex-column gap-3 mt-3">
                  {item?.audio_url && (
                    <audio controls>
                      <source src={item?.audio_url}></source>
                    </audio>
                  )}
                  {
                    item.sample_answer && <span>{item.sample_answer}</span>
                  }
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                 {(current_qst_data?.module === "speaking" && current_qst_data?.module_name !== "answer_questions") &&    <Button variant="outline-primary" size="sm">
                        HI S-ASIA V1i
                      </Button>}
                      {item?.api_response ? (
                        <Button
                          variant="outline-light"
                          size="sm"
                          onClick={() =>
                            scoreModalShow(null, null,null,item?.api_response,item?.audio_url,null,item)
                          }
                        >
                          Score Info <b className="ms-1"> {item.api_response.total_score}/{current_qst_data?.module_name=="answer_questions"? 1 : item.api_response.max_score || 90}</b>
                        </Button>
                      ) : (<>
                    {
                      (current_qst_data.module == "speaking" || current_qst_data.module == "writing" || current_qst_data.module_name =="ssts" )
                      ?<>
                      {click_Score && (current_qst_data.module_name=="swts" || current_qst_data.module_name=="essays" || current_qst_data.module_name=="ssts") ? <Dropdown>
                        <Dropdown.Toggle size="sm" variant="outline-light" id="dropdown-basic">
                          Click To Score
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => scoreModalShow(item.qst_id, item?.audio_url,item?.unique_id,null,item?.audio_url,null,item, "en-US")} >AI Score (American)</Dropdown.Item>
                          <Dropdown.Item onClick={() => scoreModalShow(item.qst_id, item?.audio_url,item?.unique_id,null,item?.audio_url,null,item, "en-GB")} >AI Score (British)</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> :
                    <Button
                    variant="outline-light"
                    size="sm"
                    onClick={() =>
                      scoreModalShow(item.qst_id, item?.audio_url,item?.unique_id,null,item?.audio_url,null,item)
                    }
                  >
                    Click to Score
                  </Button>
                    
                    }
                      </>
                    :
                      <Button
                      variant="outline-light"
                      size="sm"
                      onClick={() =>
                        scoreModalShow(item.qst_id, item?.audio_url,item?.unique_id,null,item?.audio_url,null,item)
                      }
                    >
                      Score Info <b className="ms-1"> {item?.score}</b>
                    </Button>
                    }
                       
                        </>
                      )}
                    </div>
                    <div className="d-flex gap-2">
                      <button className="btn btn-color-middark btn-hover-light btn-icon">
                        <img src="/images/create-link.svg"></img>
                      </button>
                      <button className="btn btn-color-middark btn-hover-light btn-icon">
                        <img src="/images/comment.svg"></img>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-3 align-items-center">
                      <div>
                        <img
                          className="img-comment"
                          src="/images/listening-instructions.png"
                        ></img>
                      </div>
                      <small>
                        <b className="text-secondary">manpreet </b>
                        <span className="text-middark ms-1">2023-05-31</span>
                        <p className="text-middark">hlo percy</p>
                      </small>
                    </div>
                    <div className="d-flex gap-2">
                      <button className="btn btn-color-middark btn-hover-light btn-icon">
                        <img src="/images/threedot.svg"></img>
                      </button>
                      <button className="btn btn-color-middark btn-hover-light btn-icon">
                        <img src="/images/comment.svg"></img>
                      </button>
                      <div className="d-flex align-items-center ">
                        <button className="btn btn-color-middark btn-hover-light btn-icon">
                          <img src="/images/like.svg"></img>
                        </button>{" "}
                        <span className="text-middark">20</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
}

export default SingleAnswer;
