import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimePicker from "../../../../components/Form/TimePicker";
import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../../components";
import { useContext, useState, useEffect } from "react";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteListeningHighlightIncorrectWords() {
  let { id } = useParams();
  let api = new AdminApi();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    test_type: "listening_l_mcs",
    exam_type: "pte",
    instructions: "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker said. Please click on the words that are different.",
    question_options: [
      {
        options: "",
        answer: "",
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setloader] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      file,
      question,
      answer_time,
      question_begin_time,
    } = data;
    let questionOptions = data.question_options.some(
      (option) => option.answer == ""
    );
    let questionOptions1 = data.question_options.some(
      (option) => option.options == ""
    );
    return (
      instructions &&
      (file ? file : data.question_content_audio) &&
      tags &&
      !questionOptions &&
      !questionOptions1 &&
            (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      question_begin_time.length ===5
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);
  // const isSubmitDisabled = () => {
  //   return (
  //     data.tags === "" ||
  //     data.module_name != "" ||
  //     data.module != "" ||
  //     data.question_type === "" ||
  //     data.instructions === "" ||
  //     data.question_content === "" ||
  //     data.question_options.some((option) => option.options === "")
  //   );
  // };
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) => item.options !== ""
    );
    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };

  const handleAdd = (parentIndex, childIndex, type) => {
    let handleAdd = [...data.question_options];
    handleAdd.push({
      options: "",
      answer: "",
    });
    setData({
      ...data,
      question_options: handleAdd,
    });
  };
  const handleRemove = (item, index) => {
    let handleAddRemove = [...data.question_options];
    handleAddRemove.splice(index, 1);
    setData({
      ...data,
      question_options: handleAddRemove,
    });
  };

  const handleChange = (e, i) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (i == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if (name == "options") {
      const updatedOptions = [...data.question_options];
      console.log("updatedOptions[i].answer", updatedOptions[i].answer);
      updatedOptions[i] = {
        ...updatedOptions[i],
        options: value,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "answer") {
      const updatedOptions = [...data.question_options];
      console.log("updatedOptions[i].answer", updatedOptions[i].answer);
      updatedOptions[i] = {
        ...updatedOptions[i],
        answer: value,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "file") {
      setData({ ...data, ["file"]: e.target.files[0] });
    } else {
      if (name !== "options" && name !== "answer") {
        if (name == "question_type") {
          const options = e.target.options;
          const selectedValues = [];
          if (options.length == 0) {
            setSelectedOptions([]);
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues);
          }
          setData({
            ...data,
            isMock: selectedValues.includes("isMock") ? true : false,
            isPractice: selectedValues.includes("isPractice") ? true : false,
            isSection: selectedValues.includes("isSection") ? true : false,
            isQuestion: selectedValues.includes("isQuestion") ? true : false,
            isPrediction: selectedValues.includes("isPrediction")
              ? true
              : false,
          });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isAudio = false;
    let isVideo = false;

    if (!data.question_content_audio) {
      if (data?.file?.length > 0) {
        const fileType = data.file[0].type;
        if (fileType.startsWith("audio/")) {
          isAudio = true;
          formObject.append("question_content_audio", data.file[0]);
        } else {
          Swal.fire({
            icon: "error",
            title: "File must be an audio ",
            text: "Please upload an audio file.",
          });
          return;
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "File is required",
          text: "Please upload an audio file.",
        });
        return;
      }
    }
    var data1 = removeBlankItems();
    for (const [key, value] of Object.entries(data1)) {
      if (
        key !== "file" &&
        key !== "question_options" &&
        key != "data.question_content_audio"
      ) {
        if(value!=null){
          formObject.append(key, value);
        }
      } else if (key === "question_options") {
        value.forEach((option, index) => {
          formObject.append(
            `question_options[${index}][options]`,
            option.options
          );
          formObject.append(
            `question_options[${index}][answer]`,
            option.answer
          );
        });
      }
    }
    setloader(!loader)
    await api
      .add_Question_Listening(formObject)
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
          ;
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
            // text: "Please upload an audio or video file.",
          });
        }
        setloader(!loader)
      })
      .catch((err) => {
        setloader(!loader)
        console.log("err", err);
      });
  };
  const trash = (type, index) => {
    const updatedOptions = [...data.question_options];
    updatedOptions.splice(index, 1);

    setData({
      ...data,
      question_options: updatedOptions,
    });
  };
  useEffect(() => {
    if (id) {
      getQuesById();
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Listening Highlight Incorrect Word Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    <Col md="9">
                      <h5 className="mb-3">Add Questions</h5>
                    </Col>
                    <Col md="3">
                      <TestTypeSlectBox
                        grabNew={(e) => { setSelectedOptions(e) }}
                        data={data}
                        handleChange={handleChange}
                        selectedOptions={selectedOptions}
                        updateState={(e) => {
                          setSelectedOptions(e); setData({
                            ...data,
                            isMock: e.includes("isMock") ? true : false,
                            isSection: e.includes("isSection") ? true : false,
                            isQuestion: e.includes("isQuestion") ? true : false,
                            isPrediction: e.includes("isPrediction") ? true : false,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* <Col md="3">
                                    <Form.Group className="form-group">
                                        <Form.Label>Choose Test Type </Form.Label>
                                        <div className="form-control-wrap">
                                            <Select multiple removeItemButton>
                                                <option value="">Choose Test Type</option>
                                                <option value="1">Mock Test</option>
                                                <option value="2">Section Wise Test</option>
                                                <option value="3">Question Wise Test</option>
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col> */}
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                  
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">Add Audio</Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_audio ? (
                            <>
                              <div className="d-flex gap-2">
                                <audio
                                  src={data?.question_content_audio}
                                  controls
                                ></audio>
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Add Paragraph</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="question_content"
                            onChange={(e) => handleChange(e)}
                            value={data.question_content}
                            placeholder="Add Paragraph"
                            id="paragraph"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <Col lg="12">
                  <h5 className="mb-3">Add Correct Answer Input Field</h5>
                  <div className="d-flex gap-3 flex-wrap">
                    {data.question_options.length > 0 &&
                      data.question_options.map((item, i) => {
                        return (
                          <>
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Form.Control
                                  onChange={(e) => handleChange(e, i)}
                                  name="answer"
                                  value={item.answer}
                                  style={{ width: "150px" }}
                                  type="text"
                                  id="fillinblank"
                                  placeholder="Enter Text"
                                />
                                {data?.question_options?.length != 1 && (
                                  <div
                                    className="form-control-icon end"
                                    onClick={() => trash("options", i)}
                                  >
                                    <Icon variant="danger" name="trash"></Icon>
                                  </div>
                                )}
                              </div>
                            </Form.Group>

                            {i == data.question_options.length - 1 && (
                              <Button
                                variant="outline-primary"
                                onClick={(e) => handleAdd(e)}
                                style={{ width: "50px" }}
                              >
                                <Icon name="plus"></Icon>
                              </Button>
                            )}
                          </>
                        );
                      })}
                  </div>
                </Col>

                <Col lg="12">
                  <h5 className="mb-3">Add Input Field Options</h5>
                  <div className="d-flex gap-3 flex-wrap">
                    {data.question_options.length > 0 &&
                      data.question_options.map((item, i) => {
                        return (
                          <>
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Form.Control
                                  onChange={(e) => handleChange(e, i)}
                                  name="options"
                                  value={item.options}
                                  style={{ width: "150px" }}
                                  type="text"
                                  id="fillinblank"
                                  placeholder="Enter Text"
                                />
                                {/* {data?.question_options?.[0].answer?.length !=
                                  1 && (
                                  <div
                                    className="form-control-icon end"
                                    onClick={() => trash("options", i)}
                                  >
                                    <Icon variant="danger" name="trash"></Icon>
                                  </div>
                                )} */}
                              </div>
                            </Form.Group>
                          </>
                        );
                      })}
                    {/* <Button variant="outline-primary" style={{ width: "50px" }}>
                      <Icon name="plus"></Icon>
                    </Button> */}
                  </div>
                </Col>
                <Col lg="9">
                  <TranscriptExplanation setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        value={data.tags}
                        placeholder="#Tag"
                        name="tags"
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid}/>
                
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteListeningHighlightIncorrectWords;
