
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../../components/Form/DropDown";
import { useNavigate } from "react-router-dom";
import users from "../../store/users/UsersData";
import Context from "../../context";

const statusType = [
  "WARM",
  "COLD",
  "HOT",
  "NO RESPONSE",
  "INTERESTED",
  "NOT INTERESTED",
  "IN PROCESS",
  "ENROLLED",
  
];

const AddFollowups = ({

  isFollowup,
  setIsFollowup,
  editFollowupId
}) => {


  let api = new AuthApi();

  let { userData, token } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData, "userdata123")
const [enquiryFollowUp, setEnquiryFollowUp] = useState({});

const get_Single_enquiryFollowup = async (id) => {
  await api
    .get_Single_enquiry(id)
    .then((res) => {
      if (res?.data) {
        setEnquiryFollowUp(res?.data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};


useEffect(() => {

  if (editFollowupId) {
    get_Single_enquiryFollowup(editFollowupId);
  }

}, [editFollowupId]);

 console.log(enquiryFollowUp, "enquiryFollowUp");

  const [state, setState] = useState({
    data: {
  
    },
    errors: {},
  });


      const navigate=useNavigate()

  useEffect(()=>{
    setState((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        enquiry_id: enquiryFollowUp?.[0]?._id , 
        // remarks:enquiryFollowUp?.[0]?.remarks,
        status:enquiryFollowUp?.[0]?.status,
        nextFollowUpDate:enquiryFollowUp?.[0]?.nFollowUpDate?.split(" ")?.[0]
      },
      errors: {
        ...prev.errors,
        
      },
    }));

  },[enquiryFollowUp])


  console.log("123state", state);

  let DropDownWidth = { width: "100%" };
  const handleClose = () => {
    setIsFollowup(false);
    setState({
      data: {},
      errors: {},
    });
  };
  const { data, errors } = state;
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        // "enquiry_id": enquiryFollowUp?.[0]?._id ,  //  use enquiryFollowUp?._id here working fine i.e inside onChange handler
        //    "name":   enquiryFollowUp?.[0]?.name,
        //   "email":    enquiryFollowUp?.[0]?.email,
        //   "phone":    enquiryFollowUp?.[0]?.phone,
        //   "program":enquiryFollowUp?.[0]?.program,
        //   "nextFollowUpDate":enquiryFollowUp?.[0]?.nextFollowUpDate,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...prev.errors,
        [e.target.name]: "",
      },
    }));
  };

  console.log(data, "data11121232");
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data);
    if (!data?.remarks) {
      newErrors.remarks = "Remarks required";
    }
    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");
      const date = new Date();
      // data.nextFollowUpDate = data?.nextFollowUpDate + `  ${String(date.getHours()).padStart(2, "0")} ${String(date.getMinutes()).padStart(2, "0")}`
      console.log(data, "data##1");
      let payload
      if(enquiryFollowUp?.[0]?.previous_followup?.[0]?.previous_followup?.length>0){
        payload={
          ...data,institute_id:userData?._id,id:enquiryFollowUp?.[0]?.followup_id,nextFollowUpDate:data?.nextFollowUpDate + `T${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`
        }
      }
      else{
        payload={
          ...data,institute_id:userData?._id,nextFollowUpDate:data?.nextFollowUpDate + `T${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`
        }
      }

      // if(enquiryFollowUp?.[0]?.previous_followup?.[0]?.previous_followup?.length>0){
      //   payload={
      //     ...data,id:enquiryFollowUp?.[0]?.followup_id
      //   }
      // }
      // else{
      //   payload={
      //     ...data
      //   }
      // }
      await api
        .add_followup(payload)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });

      
            get_Single_enquiryFollowup(editFollowupId);
            navigate("/lead-management/enquiry-list")
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };
  console.log('e32nquiryFollowUp :>> ', enquiryFollowUp);
  console.log('data4324 :>> ', data);
console.log('enquiryFollowUp?.previous_followup :>> ', enquiryFollowUp?.[0]?.nextFollowUpDate
);

  return (
    <>
      <Modal
        size="lg"
        show={isFollowup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add FollowUp</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="12">
                <DropDown
                  className="w-25 mb-3"
                  data={statusType}
                  // defaultSelectedOption={"All"}
                  text="Select Status"
                  name="status"
                  onChange={handleChange}
                  width={DropDownWidth}
                  value={data.status}
                />

                <Form.Group className="form-group">
                  <Form.Label>Next FollowUp Date</Form.Label>
                  <div className="form-control-wrap">
                    <DatePickerOnChange
                      onChange={(e) => handleChange(e)}
                      name="follow_up_date"
                      value={data.follow_up_date}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Remarks</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      as="textarea"
                      placeholder="Add New Remarks"
                      name="remarks"
                      rows="3"
                      value={data.remarks}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.remarks}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.remarks}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add FollowUp
          </Button>
        </Modal.Footer> */}

<Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="6">
                <DropDown
                  className="w-25 mb-3"
                  data={statusType}
                  defaultSelectedOption={"select"}
                  text="Select Status"
                  name="status"
                  onChange={handleChange}
                  width={DropDownWidth}
                  value={data.status}
                />

                <Form.Group className="form-group">
                  <Form.Label>Next FollowUp Date</Form.Label>
                  <div className="form-control-wrap">
                    <DatePickerOnChange
                      name={"nextFollowUpDate"}
                      onChange={handleChange}
                      value={data?.nextFollowUpDate}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Remarks</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      as="textarea"
                      placeholder="Add New Remarks"
                      name="remarks"
                      id="remarks"
                      rows="3"
                      value={data?.remarks}
                      onChange={handleChange}
                      isInvalid={errors?.remarks}
                    ></Form.Control>

                    <Form.Control.Feedback type="invalid">
                      {errors.remarks}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit FollowUp
                </Button>
              </Col>
              <Col md="6" style={{ height: "450px", overflow: "auto" }}>
                <small className="text-menu-text">FollowUp History</small>

                {enquiryFollowUp?.[0]?.previous_followup?.[0]?.previous_followup?.map((item) => (
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-column bg-light px-2 py-1 rounded">
                      <p className="text-secondary">{item?.remarks}</p>
                      <small className="align-self-end text-secondary">
                        {item?.remarks_user_name}
                      </small>
                    </div>
                    <small className="align-self-end text-menu-text">
                    {item?.follow_up_date}
                    </small>
                  </div>
                ))}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddFollowups;
