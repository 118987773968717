import { Form } from "react-bootstrap";

export function DropDown({
  object,
  value,
  defaultSelectedOption,
  text,
  name,
  data,
  onChange,
  width,
  disabled
}) {
  console.log('defaultSelectedOption :>> ', defaultSelectedOption,data,value);
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label>{text}</Form.Label>
        <div className="form-control-wrap me-2" style={width}>
          <Form.Select
            removeItemButton
            value={value} // if value available  then only show this one
            name={name}
            onChange={onChange}
            disabled={disabled}
          >
           
           
            <option value="">{defaultSelectedOption}</option> // if value not available  then only show this 
        
            {data?.length > 0 &&
              data.map((item) => {
                console.log('object,item :>> ', object,item);
                return (
                  <>
                    <option value={object ? item?.id : item}>
                      {object ? item?.value : item}
                    </option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
    </>
  );
}
