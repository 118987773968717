// import React, { useState, useEffect, useContext } from "react";
// import {
//   Card,
//   Form,
//   Row,
//   Col,
//   Nav,
//   Modal,
//   Tab,
//   Tabs,
//   Button,
//   Dropdown,
//   ButtonGroup,
//   InputGroup,
//   Badge,
//   Table,
//   CloseButton,
//   ToastContainer,
//   Toast,
//   Alert,
// } from "react-bootstrap";
// import Layout from "../../layout/default";
// import Block from "../../components/Block/Block";
// import FileUploadNew from "../../components/Form/FileUploadNew";
// // import dateUtils from '../../utilities/dateUtils';
// import {
//   Icon,
//   Image,
//   Media,
//   Pureknob,
//   ChartLegend,
//   CustomDropdownMenu,
//   CustomDropdownToggle,
//   MediaGroup,
//   LinkList,
//   LinkListItem,
//   MediaText,
// } from "../../components";
// import FileUpload from "../../pages/material-management/components/fileUpload";
// import TeamDataTable from "./TeamDataTable";
// import TeamData, { TeamColumns } from "./TeamData";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import AuthApi from "../../api/auth";
// import Context from "../../context";
// import Swal from "sweetalert2/src/sweetalert2.js";
// import BranchList from "../branch/ViewBranch";
// import { getTimeZones, getTimezoneOffset } from "../../utilities/timeZones";
// import countryList from "../../country";
// import BranchAdminDataTable from "./BranchAdminDataTable";
// import { colors } from "../../utilities/colorsToAvoid";
// import ColorPicker from "../../components/Form/ColorPicker";
// import { Button as ButtonCh, Wrap, WrapItem, useToast } from "@chakra-ui/react";
// import InstitutePlanPrice from "./InstitutePlanPrice";
// import { CountryState } from "../../components/Form/CountryState";
// // import  CountryState  from "../../components/Form/CountryState";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
// import dateUtils from "../../utilities/dateUtils";

// function AdminDashboard() {
//   let {
//     userData,
//     token,
//     domainData,
//     setUserData,
//     localHost,
//     setDomainData,
//     setToken,
//   } = useContext(Context);
//   userData = JSON.parse(userData);
//   let institute_profile = userData?.login_type == "1";
//   let admin_profile = userData?.login_type == "0";
//   let student_profile = userData?.login_type == "2";
//   console.log("institute_profile", institute_profile);
//   console.log("admin_profile", admin_profile);
//   console.log("student_profile", student_profile);
//   domainData = JSON.parse(domainData);

// console.log(domainData?.inst_profile?.color_code,"domainData?.inst_profile?.color_code")
//   const [demoModal, setDemoModal] = useState(false);

//   const demoModalClose = () => setDemoModal(false);
//   const demoModalShow = () => setDemoModal(true);

//   const [otpModal, setOtpModal] = useState(false);

//   const otpModalClose = () => setOtpModal(false);
//   const otpModalShow = () => setOtpModal(true) & setDemoModal(false);

//   const [mobileModal, setMobileModal] = useState(false);

//   const mobileModalClose = () => setMobileModal(false);
//   const mobileModalShow = () => setMobileModal(true) & setOtpModal(false);

//   const [billdetailModal, setBilldetailModal] = useState(false);

//   const billdetailModalClose = () => setBilldetailModal(false);
//   const billdetailModalShow = () => setBilldetailModal(true);

//   const [gstModal, setGstModal] = useState(false);

//   const gstModalClose = () => setGstModal(false);
//   const gstModalShow = () => setGstModal(true);

//   const [show, setShow] = useState(false);

//   let api = new AuthApi();
//   const { domain_name, inst_id } = useParams();
//   const [data, setData] = useState({
//     timeZone: userData?.timeZone
//   });

//   console.log('data432234 :>> ', inst_id);
//   const [data1, setData1] = useState({});
//   const [teamData, setTeamdata] = useState([]);
//   const [topUpModal, setTopUpTrue] = useState(false);
//   const [institutePlan,setInstitutePlan] = useState({})
//   const [institutePlanPrice,setInstitutePlanPrice] = useState({})
//   const handleFileUpload = (newFiles) => {
//     setData((prevData) => ({
//       ...prevData,
//       ["logo"]: newFiles,
//       ["inst_profile"]: {
//         ...data.inst_profile,
//         ["business_logo"]: "",
//       },
//     }));

//   }

//   const handleFileUploadFavicon = (newFiles) => {
//     setData((prevData) => ({
//       ...prevData,
//       ["inst_favicon_logo"]: newFiles,
//       ["inst_profile"]: {
//         ...data.inst_profile,
//         ["inst_favicon_logo"]: ""
//       },
//     }));

//   };

//   const handleBusinessCertificateUploadFavicon = (newFiles) => {
//     setData((prevData) => ({
//       ...prevData,
//       ["business_certificate"]: newFiles,
//       ["inst_profile"]: {
//         ...data.inst_profile,
//         ["business_certificate"]: ""
//       },
//     }));

//   };

//   console.log("FILESTOUPLOAD", data,);
//   let formDataObject = new FormData();
//   let navigate = useNavigate();

//   const submitForm = async (e) => {
//     e.preventDefault();

//     //remove unnecessary data from uploaded
//     delete data.createdAt
//     delete data.otp
//     delete data.updatedAt
//     delete data.password
//     delete data._token
//     delete data.permission_routes
//     delete data.otp_forget_password
//     // delete data.domain_name

//     console.log('domain_data :>> ', data);
//     for (const [key, value] of Object.entries(data)) {
//       if (key === "banner_images") {
//         for (const imageFile of value) {
//           formDataObject.append("banner_images", imageFile);
//         }
//       }
//       else if (key === "internal_banner_images") {
//         for (const imageFile of value) {
//           formDataObject.append("internal_banner_images", imageFile);
//         }
//       }

//       else if (key === "business_certificate" || key === "logo" || key === "inst_favicon_logo") {
//         if (key === "logo") {
//           formDataObject.append(key, value[0]);
//         } else {
//           console.log("MAC", key, value[0]);
//           formDataObject.append(key, value[0]);
//         }
//       } else {
//         if (typeof value === "object" && value !== null) {
//           if (key == "inst_profile") {
//             formDataObject.append("inst_profile", JSON.stringify(value));
//           }
//         } else {
//           formDataObject.append(key, value);
//         }
//       }
//     }

//     // if(window.location.pathname == "/institute/profile"){
//     //   formDataObject = new FormData();
//     //   for (const [key, value] of Object.entries(data)) {
//     //     if (key === "_id") {
//     //       formDataObject.append(key, value);
//     //     } else if (key === "logo") {
//     //       if (key === "logo") {
//     //         formDataObject.append(key, value[0]);
//     //       }
//     //     }
//     //   }
//     // }

//     await api.institute_register(formDataObject).then((res) => {
//       try {
//         console.log('res :>> ', res);
//         if (res.status) {
//           Swal.fire({
//             icon: "success",
//             title: "Good job!",
//             text: res.msg,
//           });
//           // navigate("/");
//           // isEdit(false)
//           if (res.data?.length > 0 && userData.login_type != "0") {
//             setUserData(JSON.stringify(res.data[0]));
//             localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
//             localStorage.setItem(
//               "instDomainHilingo",
//               JSON.stringify(res.data[0])
//             );
//             setDomainData(JSON.stringify(res.data[0]));
//           }
//           if (res.data?.length > 0 && userData.login_type == "0") {
//             console.log("inside if")
//             setIsEdit(false)
//             navigate(`/institute-detail/${res?.data?.[0]?.domain_name}/${res?.data?.[0]?._id}`)
//           }
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.msg,
//           });
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     });
//   };
//   useEffect(() => {
//     getInstittuteDetailByname();
//   }, [domain_name]);
//   const getInstittuteDetailByname = async () => {
//     await api
//       .get_instittute_By_Name({
//         name: domain_name ? domain_name : userData.domain_name,
//       })
//       .then((res) => {
//         if (res.data?.length > 0) {
//           setData1({
//             ...res.data[0],
//             ["inst_gst_number_active"]:
//               (res.data[0]?.inst_profile?.hasOwnProperty("inst_gst_number") && res.data[0]?.inst_profile?.hasOwnProperty("inst_company_name")) &&
//                 ((res.data[0]?.inst_profile?.inst_gst_number != "") || (res.data[0]?.inst_profile?.inst_company_name != ""))
//                 ? true
//                 : false,
//           });
//           setData({
//             ...res.data[0],
//             inst_profile: {
//               ...res.data[0]?.inst_profile,
//               internal_banner_images: res.data[0]?.inst_profile?.internal_banner_images
//                 ? res.data[0]?.inst_profile?.internal_banner_images
//                 : []
//             },

//             ["inst_gst_number_active"]:
//               (res.data[0]?.inst_profile?.hasOwnProperty("inst_gst_number") && res.data[0]?.inst_profile?.hasOwnProperty("inst_company_name")) &&
//                 ((res.data[0]?.inst_profile?.inst_gst_number != "") || (res.data[0]?.inst_profile?.inst_company_name != ""))
//                 ? true
//                 : false,
//           });
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   // console.log("dataaaa", data);
//   console.log("data._id", data._id);
//   useEffect(() => {
//     if (inst_id) {
//       getTeamByInstituteId(inst_id);
//     }
//     else if (data._id) {
//       getTeamByInstituteId(data._id)
//     }
//   }, [data?._id, inst_id]);

//   const removeTeamById = async (params) => {
//     Swal.fire({
//       title: 'Are you sure?',
//       text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Yes, delete it!'
//     }).then((result) => {
//       if (result.value) {
//         api
//           .inactive_user(params, token)
//           .then((res) => {
//             if (res.status) {
//               getTeamByInstituteId();
//             } else {
//               Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: res.msg,
//               });
//             }
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       }
//     })

//   };

//   const handleChange = (e, inst_profile, index, banner) => {
//     let { value, name,checked } = e?.target;
//     console.log("nameee", value);
//     //block light colors

//     if (name == "inst_business_name") {
//       setData((prevData) => ({
//         ...prevData,
//         ["domain_name"]: value?.replace(/\s+/g, " ").trim(),
//       }));
//     }

//     if (inst_profile) {
//       if (name == "business_logo") {
//         setData((prevData) => ({
//           ...prevData,
//           ["logo"]: "",
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: "",
//           },
//         }));
//       } else {
//         // setData((prevData) => ({
//         //   ...prevData,
//         //   ["inst_profile"]: {
//         //     ...data.inst_profile,
//         //     [name]: value?.replace(/\s+/g, " ").trim(),
//         //   },
//         // }));
//       }
//     } else {
//       if (name == "logo" || name == "business_certificate") {
//         setData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
//       } else {
//         if (name == "banner_images") {
//           let tempArray = [...data?.banner_images];
//           tempArray[index] = e.target.files[0];
//           // setData((prevData) => ({ ...prevData, [name]: tempArray }));
//         } else {
//           if (name == "domain_name") {
//             setData((prevData) => ({
//               ...prevData,
//               [name]: value?.replace(/\s+/g, " ").trim(),
//               ["inst_profile"]: {
//                 ...data.inst_profile,
//                 ["inst_business_name"]: value?.replace(/\s+/g, " ").trim(),
//               },
//             }));
//           } else {
//             setData((prevData) => ({
//               ...prevData,
//               [name]: value?.replace(/\s+/g, " ").trim(),
//             }));
//           }
//         }
//       }
//     }

//     if (name == "deletebanner") {
//       // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
//       setData((prevData) => ({
//         ...prevData,
//         ["inst_profile"]: {
//           ...data.inst_profile,
//           ["banner_images"]: data?.inst_profile?.banner_images.filter(
//             (item) => item.url != banner
//           ),
//         },
//       }));
//     }

//     if (name == "deleteInternalbanner") {
//       // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
//       setData((prevData) => ({
//         ...prevData,
//         ["inst_profile"]: {
//           ...data.inst_profile,
//           ["internal_banner_images"]: data?.inst_profile?.internal_banner_images.filter(
//             (item) => item.url != banner
//           ),
//         },
//       }));
//     }

//     if (name == "enquiry_otp_deduction") {
//       console.log("checkedddddddd :>> ",name);
//       setData((prev)=>({...prev,  enquiry_otp_deduction: checked }));
//     }

//     if (name == "color_code") {

//       console.log("COLRS",colors,value);
//       if(colors.includes(value)){
//         // alert("Don`t Pick Light Color");
//         setShow(true)

//         setData((prevData) => ({
//           ...prevData,
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: userData?.inst_profile?.color_code,
//           },
//         }));
//       }
//       else{
//         setData((prevData) => ({
//           ...prevData,
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: value,
//           },
//         }));
//       }

//     }
//     // if(name == "username"){

//     //   // data?.inst_profile?.inst_contact_firstname
//     //   setData((prev)=>({...prev,  ...inst_profile,inst_profile:{inst_contact_firstname: value }}));
//     // }

//      if(name == "username"){

//       // data?.inst_profile?.inst_contact_firstname
//       setData((prev)=>({...prev, inst_profile:{...prev?.inst_profile,inst_contact_firstname: value }}));
//     }

//     if(name == "inst_business_city"){

//       // data?.inst_profile?.inst_contact_firstname
//       setData((prev)=>({...prev, inst_profile:{...prev?.inst_profile,inst_business_city: value }}));
//     }

//     if(name == "inst_business_address"){

//       // data?.inst_profile?.inst_contact_firstname
//       setData((prev)=>({...prev, inst_profile:{...prev?.inst_profile,inst_business_address: value }}));
//     }

//   };

//   const selectCountry = (val) => {
//     console.log("va43l",val);

//     setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_country:val}}))

//   };

//   const selectRegion = (val) => {
//     console.log('val32322 :>> ', val);
//     setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:val}}))
//   };

//   const getTeamByInstituteId = async (instid) => {
//     console.log("instid", instid);
//     console.log("data._id", data._id);
//     await api
//       .get_teachers_By_Inst({ id: instid ? instid : data._id, token })
//       .then((res) => {
//         if (res?.data?.length > 0) {
//           console.log("GET TEACHERS BY INST ID -> ", res.data)
//           setTeamdata(res?.data.reverse());
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const getInsitutePlan = async (instId) => {
//     await api
//       .get_plans_by_inst(instId)
//       .then((res) => {
//         if (res.status) {
//           setInstitutePlan(res.data)
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const getInsitutePlanPrice = async (instId) => {
//     await api
//       .get_plans_by_inst(instId)
//       .then((res) => {
//         if (res.status) {
//           setInstitutePlanPrice(res.data)
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const givenDate = new Date(
//     student_profile ? userData?.createdAt : data1.createdAt
//   );
//   // Define an array of month names
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   // Extract year, month, and day from the Date object
//   const year = givenDate.getUTCFullYear();
//   const monthIndex = givenDate.getUTCMonth();
//   const day = givenDate.getUTCDate();

//   // Create the desired date format
//   const desiredDateFormat = `${day} ${monthNames[monthIndex]} ${year}`;

//   const TeamColumns = [
//     {
//       name: "SRNo",
//       cell: (row, index) => <span className="text-middark">{index + 1}</span>,
//       sortable: false,
//     },
//     // {
//     //   name: "Email",
//     //   selector: (row) => row.name,
//     //   cell: (row) => (
//     //     <MediaGroup>
//     //       <Media size="md" shape="circle" variant={row.theme && row.theme}>
//     //         {/* { row.avatar ?
//     //                     <Image src={row.avatar} staticImage/> :
//     //                     <span className="smaller fw-medium">{(row?.name)}</span>
//     //                 } */}
//     //       </Media>
//     //       <MediaText>
//     //         <Link to={`/user-manage/user-profile/${row._id}`} className="title">
//     //           {row?.email}
//     //         </Link>
//     //       </MediaText>
//     //     </MediaGroup>
//     //   ),
//     //   sortable: true,
//     // },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       cell: (row) => <span className="text-middark">{row.email}</span>,
//       sortable: true,
//     },
//     {
//       name: "Name",
//       selector: (row) => row.username,
//       cell: (row) => <span className="text-middark">{row.name}</span>,
//       sortable: true,
//     },
//     {
//       name: "Mobile",
//       selector: (row) => row.username,
//       cell: (row) => <span className="text-middark">{row.mobile}</span>,
//       sortable: true,
//     },
//     {
//       name: "status",
//       selector: (row) => row.status,
//       cell: (row) => (
//         // <span className={`badge text-bg-${row.status === "Active" ? "success"
//         //     : row.status === "Inactive" ? "danger"
//         //         : "primary"}`
//         // }>
//         <span
//           className={`badge text-bg-${row.is_deleted == 0
//             ? "success"
//             : row.is_deleted == 1
//               ? "danger"
//               : "primary"
//             }`}
//         >
//           {row.is_deleted == 0 ? "Active" : "Inactive"}
//         </span>
//       ),
//       sortable: true,
//     },
//     {
//       name: "Role",
//       selector: (row) => row?.teacher_profile?.teacher_role,
//       cell: (row) => (
//         <span className="text-middark">
//           {row?.teacher_profile?.teacher_role}
//         </span>
//       ),
//       sortable: true,
//     },
//     {
//       name: "action",
//       cell: (row) => (
//         <div className="w-100">
//           <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
//             <LinkListItem to={`/edit-team/${row._id}`}>
//               <Icon name="edit"></Icon>
//             </LinkListItem>

//             <div
//               className="link-list-hover-bg-primary link-list-md d-flex del-btn"
//               onClick={() => removeTeamById({ _id: row._id })}
//             >
//               <Icon name="trash"></Icon>
//             </div>
//             {/* <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
//                                         <Icon name="trash"></Icon>
//                                     </LinkListItem> */}
//           </LinkList>
//         </div>
//       ),
//       sortable: false,
//     },
//   ];
//   let isAdmin = userData?.login_type == "0"
//   const [activeKey, setActiveKey] = useState("custom-home");
//   const [topup_amount, setTopUpAmnt] = useState(0);
//   const handleSelect = (selectedKey) => {
//     setActiveKey(selectedKey);
//   };
//   const add_topup_credit = async () => {
//     await api
//       .add_topup_credit({ amount: Number(topup_amount), user_id: inst_id })
//       .then((res) => {
//         if (res?.status) {
//           Swal.fire({
//             icon: "success",
//             title: "Good job!",
//             text: res.message,
//           });
//           navigate("/institute-list");
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.msg,
//           });
//         }
//       })
//       .catch((er) => console.log(er));
//   };

//   const [staffList, setStaffList] = useState([]);
//   useEffect(() => {
//     staff_List();
//     getInsitutePlan(userData?._id)
//     getInsitutePlanPrice(inst_id)
//   }, []);

//   const staff_List = async () => {
//     await api
//       .staff_List()
//       .then((res) => {
//         console.log("STAFF LIST --> ", res);
//         if (res.data?.length > 0) {
//           setStaffList(res.data.reverse());
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   // useEffect(()=>{
//   // setData((prevData) => ({
//   //   ...prevData,
//   //   ["inst_profile"]: {
//   //     ...data.inst_profile,
//   //     ["inst_gst_number_active"]: data?.inst_profile?.inst_gst_number?true:false,
//   //   },
//   // }));
//   // },[data])

//   const updateBannerImages = (updatedFiles, internal_banner_images) => {

//     console.log('object :>> ', updatedFiles,internal_banner_images);
//     if (internal_banner_images) {
//       if (data?.internal_banner_images?.length) {
//         let previousArray = [...data.internal_banner_images];
//         let updatedArray = previousArray.concat(updatedFiles);
//         setData({
//           ...data,
//           internal_banner_images: updatedArray,
//         });
//       } else {
//         setData({
//           ...data,
//           internal_banner_images: updatedFiles,
//         });
//       }
//     } else {
//       if (data?.banner_images?.length) {
//         let previousArray = [...data.banner_images];
//         let updatedArray = previousArray.concat(updatedFiles);
//         setData({
//           ...data,
//           banner_images: updatedArray,
//         });
//       } else {
//         setData({
//           ...data,
//           banner_images: updatedFiles,
//         });
//       }
//     }
//   };
//   const [isEdit,setIsEdit] = useState(false)
//   const editHandler=()=>{
//     setIsEdit(true)

//   }
//   const toast = useToast()
//   const toastIdRef = React.useRef()

//   const [copyText,setCopyText] = useState("")
//   const handleCopyClick = (textToCopy) => {
// console.log('textToCopy :>> ', textToCopy);
//     navigator.clipboard.writeText(textToCopy)
//       .then(() => {
//         console.log('Text copied to clipboard');
//         setCopyText('Text copied to clipboard');
//       })
//       .catch(err => {
//         console.error('Failed to copy text: ', err);
//         setCopyText('Failed to copy text: ', err);
//       });

//   };

//   console.log('isEdit :>> ', institutePlanPrice);
//   return (
//     <>
//     <Row style={{position:"relative", zIndex:"9999",}}>
//     <Col xs={6}>
//     <ToastContainer className="p-3" position="top-end" containerPosition="fixed">
//     <Toast onClose={() => setShow(false)} show={show} className="custom-toast" delay={3000} autohide>
//         <Toast.Header>
//           <MediaGroup>
//             <Media size="tiny">
//               <Image src="/images/favicon.png"/>
//             </Media>
//             <MediaText>
//               <strong>Hilingo</strong>
//             </MediaText>
//           </MediaGroup>
//           {/* <small>11 mins ago</small> */}
//         </Toast.Header>
//         <Toast.Body className="text-secondary " style={{height:"120px",backgroundColor:"#E8E7EC", boxShadow:" 0 4px 6px rgba(0, 0, 0, 0.1)",fontSize: "1.1em"}}>Don`t Pick Light Color!!</Toast.Body>
//     </Toast>
//     </ToastContainer>
//     </Col>

//     </Row>

//     <Layout title="Add Student" content="container">
//       <h1
//         className="d-flex align-items-center mb-5"
//         style={{ cursor: "pointer" }}
//         onClick={() => navigate(-1)}
//       >
//         <Icon
//           name="arrow-left-circle-fill"
//           className="text-primary h1 me-2"
//         ></Icon>{" "}
//         Back
//       </h1>
//       <Block>
//         {!student_profile && (
//           <Card className="h-100 my-3">
//             <div className="d-flex justify-content-end align-items-center bg-primary">
//               <Image src='/images/myprofile.gif' className="profile-img"></Image>
//             </div>
//             <Card.Body>
//               <div className="d-flex flex-column align-items-start user-profile gap-3">
//                 <Media size="huge" shape="circle">
//                   {data1.inst_profile?.business_logo ? (
//                     <Image
//                       src={data1.inst_profile?.business_logo}
//                       staticImage
//                       thumbnail
//                       alt="user"
//                     />
//                   ) : (
//                     <Image
//                       src="/images/avatar/a.jpg"
//                       staticImage
//                       thumbnail
//                       alt="user"
//                     />
//                   )}
//                 </Media>
//                 <div className="mt-3 mt-md-0 ms-md-3 me-5 d-flex justify-content-between w-95">
//                   <div>
//                     <h3 className="title mb-1">
//                       {data1.inst_profile?.inst_contact_firstname +
//                         " " +
//                         data1.inst_profile?.inst_contact_lastname}
//                     </h3>
//                     <span className="small fw-bold">
//                          Expire Date:{dateUtils?.formatDateMonthString(data1?.plan_expiry)}
//                     </span><br />
//                     <Button onClick={() => navigate(isAdmin ? `/institute/change-password/${inst_id}` : `/institute/change-password/${userData?._id}`)} >Change Password</Button>
//                   </div>
//                   <div className="d-flex flex-column align-items-center">
//                     <h4>Select Time Zone</h4>

//                     <select className="form-select" value={data?.timeZone} onChange={(e) => { setData({ ...data, timeZone: e.target.value }) }}>
//                       {getTimeZones().map((ele) => {
//                         return <option>{ele} (UTC {getTimezoneOffset(ele)}:00)</option>
//                       })}
//                     </select>
//                   </div>
//                   <div className="d-flex flex-column align-items-end">
//                     <h3 className="title mb-1">Credits - {data1?.credit}</h3>

//                     {userData?.login_type == "0" && (
//                       <>
//                         {topUpModal && (
//                           <input
//                             type="text"
//                             onChange={(e) => setTopUpAmnt(e.target.value)}
//                           />
//                         )}
//                         <Button
//                           size="sm"
//                           variant="primary"
//                           onClick={() =>
//                             topUpModal
//                               ? add_topup_credit()
//                               : setTopUpTrue(!topUpModal)
//                           }
//                         >
//                           {" "}
//                           {topUpModal ? "Submit" : "Add Top Up"}
//                         </Button>
//                       </>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               <Row className="g-gs mb-5">
//                 <Col md="3">
//                   <p className="text-light mb-0">Created on : </p>{" "}
//                   <p className="text-secondary">{desiredDateFormat}</p>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Domain : </p>{" "}
//                     <div className="d-flex gap-2" >
//                       {/* <p className="text-middark"> */}
//                   {
//                     !isEdit?

//                     <span className="text-middark">{`https://${data1.domain_name}.hilingo.com`} </span>

//                       :

//                     <Form.Control
//                     className={isEdit?"":"no-border"}
//                     name="domain_name"
//                     type="text"
//                     style={{height:"30px"}}
//                     id="domain_name"
//                     value={data.domain_name}

//                     onChange={(e) => handleChange(e)}

//                     placeholder="Enter Domain Name"
//                     />
//                   }

// <div style={{cursor:"pointer"}}
//       onClick={() =>
//         toast({
//           title: 'Domain Copied',
//           // description: "We've created your account for you.",
//           status: 'success',
//           duration: 1000,
//           isClosable: true,
//         })
//       }
//     >
//                     <Icon name="copy" className="text-primary" onClick={()=>handleCopyClick(`https://${data1.domain_name}.hilingo.com`)}></Icon>

//                     </div>
//                     {/* {copyText && <Alert className='mt-4' variant="danger">{copyText}</Alert>} */}

//                       {userData?.login_type == "0" && (
//                     <div
//                   className="del-btn"
//                   onClick={() => {
//                     editHandler()
//                     // setEditData({ ...edit_data, id: row._id,test_name:row?.test_name });
//                   }}

//                 >
//                   <Icon name="edit"></Icon>
//                 </div>)}
//                 </div>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Username : </p>{" "}
//                   <Badge bg="hilight" className="text-secondary">
//                     {data1.email}
//                   </Badge>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Created by : </p>{" "}
//                   <p className="text-secondary">
//                     {data1.inst_profile?.inst_contact_firstname +
//                       " " +
//                       data1.inst_profile?.inst_contact_lastname}
//                   </p>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Location : </p>{" "}
//                   <Badge bg="hilight" className="text-secondary">
//                     {data1.inst_profile?.inst_business_city +
//                       " , " +
//                       data?.inst_profile?.inst_contact_source_country_student}
//                   </Badge>
//                 </Col>
//                 {userData?.login_type == "0" && (
//                   <>
//                     <Col md="3">
//                       <p className="text-light mb-0">Managed By </p>{" "}
//                       <div
//                         className="form-control-wrap ms-1 me-1"
//                         style={{ width: "208px" }}
//                       >
//                         <Form.Select
//                           removeItemButton
//                           value={data?.inst_profile?.manageBy}
//                           name="manageBy"
//                           onChange={(e) => handleChange(e, "inst_profile")}
//                         >
//                           <option value="">Select</option>
//                           {staffList?.length > 0 &&
//                             staffList.map((item) => {
//                               return (
//                                 <>
//                                   <option value={item._id}>{item.name}</option>
//                                 </>
//                               );
//                             })}
//                         </Form.Select>
//                       </div>
//                     </Col>
//                     <Col md="3"></Col>
//                     <Col md="3">
//                       <Button
//                         className="btn btn-success mt-4 px-10"
//                         onClick={submitForm}
//                       >
//                         Update
//                       </Button>
//                     </Col>
//                   </>
//                 )}
//               </Row>
//             </Card.Body>
//           </Card>
//         )}
//         {student_profile && (
//           <Card className="h-100 my-3">
//             <Image src="/images/bg.svg"></Image>
//             <Card.Body>
//               <div className="d-flex flex-column align-items-start user-profile gap-3">
//                 <Media size="huge" shape="circle">
//                   {userData.student_profile?.profile_pic ? (
//                     <Image
//                       src={userData.student_profile?.profile_pic}
//                       staticImage
//                       thumbnail
//                       alt="user"
//                     />
//                   ) : (
//                     <Image
//                       src="/images/avatar/a.jpg"
//                       staticImage
//                       thumbnail
//                       alt="user"
//                     />
//                   )}
//                 </Media>
//                 <div className="mt-3 mt-md-0 ms-md-3 me-5 d-flex justify-content-between w-95">
//                   <div>
//                     <h3 className="title mb-1">{userData?.name}</h3>
//                     <span className="small fw-bold">
//                       Expire Date: 17 Jun 2022
//                     </span>
//                   </div>
//                 </div>
//               </div>
//               <Row className="g-gs mb-5">
//                 <Col md="3">
//                   <p className="text-light mb-0">Created on : </p>{" "}
//                   <p className="text-secondary">{desiredDateFormat}</p>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Institute Name : </p>{" "}
//                   <p className="text-middark">
//                     <span> {userData?.stu_profile?.instituteName} </span>{" "}
//                   </p>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Username : </p>{" "}
//                   <Badge bg="hilight" className="text-secondary">
//                     {userData?.email}
//                   </Badge>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Created by : </p>{" "}
//                   <p className="text-secondary">
//                     {userData?.stu_profile?.createdBy}
//                   </p>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Teacher Name : </p>{" "}
//                   <p className="text-secondary">
//                     {userData?.stu_profile?.assignTeacher}
//                   </p>
//                 </Col>
//                 <Col md="3">
//                   <p className="text-light mb-0">Branch Name : </p>{" "}
//                   <Badge bg="hilight" className="text-secondary">
//                     {userData?.stu_profile?.branch_name}
//                   </Badge>
//                 </Col>
//               </Row>
//             </Card.Body>
//           </Card>
//         )}

//         {!student_profile && (
//           <Card className="h-100 my-3">
//             <Card.Body>
//               <Tab.Container
//                 id="custom-tabs-example"
//                 defaultActiveKey="custom-home"
//               >
//                 <Nav
//                   variant="tabs"
//                   className="nav-tabs-s1 mb-3 justify-content-around"
//                   activeKey={activeKey}
//                   onSelect={handleSelect}
//                 >
//                   <Nav.Item className="px-1">
//                     <Nav.Link eventKey="custom-home">My Profile</Nav.Link>
//                   </Nav.Item>
//                   <Nav.Item className="px-1">
//                     <Nav.Link eventKey="custom-profile">
//                       Customization
//                     </Nav.Link>
//                   </Nav.Item>
//                   {/* <Nav.Item className="px-1">
//                   <Nav.Link eventKey="custom-bil">Billing Information</Nav.Link>
//                 </Nav.Item> */}
//                   {(userData?.login_type == "0" || institutePlan?.institute_role_based_access) && <Nav.Item className="px-1">
//                     <Nav.Link eventKey="custom-team">Team</Nav.Link>
//                   </Nav.Item>
//                   }
//                   {(userData?.login_type == "0" || institutePlan?.multi_branch_management) && <Nav.Item className="px-1">
//                     <Nav.Link eventKey="custom-branch">Branches</Nav.Link>
//                   </Nav.Item>
//                   }
//                   {( userData?.login_type == "0")  && <Nav.Item className="px-1">

//                   <Nav.Link eventKey="plan-price">Plan and Price</Nav.Link>
//                   </Nav.Item>
//                   }

//                 </Nav>
//                 <Tab.Content>
//                   <Tab.Pane eventKey="custom-home">
//                     <Row className="g-gs">
//                       <Col md="4" className="p-3">
//                         <h5>Personal Profile</h5>
//                         <p className="text-middark">
//                           Change Name, Email, & Mobile Number of your profile.{" "}
//                         </p>
//                       </Col>
//                       <Col md="8" className="p-3">
//                         <Row className="g-gs">
//                           <Col lg="12">
//                             <Form.Group className="form-group">
//                               <Form.Label>Name</Form.Label>
//                               <div className="form-control-wrap">
//                                 <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     {/* <Icon name="edit-alt"></Icon> */}
//                                   </Button>
//                                 </div>
//                                 <Form.Control
//                                   type="text"
//                                   id="username"
//                                   name="username"
//                                   onChange={(e) => handleChange(e)}
//                                   // value={data?.inst_profile?.inst_contact_firstname+" "+data1?.inst_profile?.inst_contact_lastname}
//                                   value={data?.inst_profile?.inst_contact_firstname}
//                                   placeholder="User Name"
//                                 />
//                               </div>
//                             </Form.Group>
//                           </Col>
//                           <Col lg="12" className="mt-1">
//                             <Form.Group className="form-group">
//                               <Form.Label>Email Id</Form.Label>
//                               <div className="form-control-wrap">
//                                 {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                 <Form.Control
//                                   type="email"
//                                   // id="emailid"
//                                   name="email"
//                                   onChange={(e) => handleChange(e)}
//                                   value={data?.email}
//                                   placeholder="Email Id"
//                                 />
//                               </div>
//                             </Form.Group>
//                           </Col>
//                           <Col lg="12" className="mt-1">
//                             <Form.Group className="form-group">
//                               <Form.Label>Mobile No.</Form.Label>
//                               <div className="form-control-wrap">
//                                 {/* <div className="form-control-icon end">
//                                   <Button
//                                     variant="white"
//                                     // onClick={demoModalShow}
//                                     className="border-0"
//                                   >
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                 <Form.Control
//                                   type="number"
//                                   name="mobile"
//                                   value={
//                                     data?.mobile?.length < 11
//                                       ? data?.mobile
//                                       : ""
//                                   }
//                                   onChange={(e) => handleChange(e)}
//                                   id="mobile"
//                                   placeholder="Mobile No."
//                                 />
//                                 <Modal show={demoModal} onHide={demoModalClose}>
//                                   <Modal.Header closeButton>
//                                     <Modal.Title>
//                                       Change Mobile Number
//                                     </Modal.Title>
//                                   </Modal.Header>
//                                   <Modal.Body className="d-flex flex-column justify-content-center">
//                                     <p className="text-center text-middark">
//                                       Click on GET OTP button to receive on OTP
//                                       on your registered email address.{" "}
//                                     </p>
//                                     <Form.Control
//                                       type="email"
//                                       id="emailis"
//                                       value="test@gmail.com"
//                                       placeholder="Email Id"
//                                     />
//                                     <Button
//                                       variant="primary"
//                                       className="mx-auto my-3"
//                                       onClick={otpModalShow}
//                                     >
//                                       Get OTP
//                                     </Button>
//                                   </Modal.Body>
//                                 </Modal>
//                               </div>
//                             </Form.Group>
//                           </Col>
//                         </Row>
//                       </Col>
//                     </Row>
//                     <Modal show={mobileModal} onHide={mobileModalClose}>
//                       <Modal.Header closeButton>
//                         <Modal.Title>Change Mobile Number</Modal.Title>
//                       </Modal.Header>
//                       <Modal.Body className="d-flex flex-column justify-content-center">
//                         <p className="text-center text-middark">
//                           We will notify all the new software update/changes on
//                           updated mobile number.
//                         </p>
//                         <Form.Control
//                           type="number"
//                           id="mobile"
//                           value={data?.inst_profile?.inst_business_address}
//                           placeholder="Enter New Mobile Number"
//                         />
//                         <Button
//                           variant="primary"
//                           className="mx-auto my-3"
//                           onClick={submitForm}
//                         >
//                           Update
//                         </Button>
//                       </Modal.Body>
//                     </Modal>

//                     <Modal show={otpModal} onHide={otpModalClose}>
//                       <Modal.Header closeButton>
//                         <Modal.Title>Change Email Address</Modal.Title>
//                       </Modal.Header>
//                       <Modal.Body className="d-flex flex-column justify-content-center">
//                         <p className="text-center text-middark">
//                           changing email address requires you to enter OTP sent
//                           over to your registerd email address.
//                         </p>
//                         <div className="bg-hisuccess rounded p-3 text-white my-3">
//                           OTP sent successfully.
//                         </div>
//                         <div className="d-flex gap-3 my-3">
//                           <Form.Control type="number" id="otp1" />
//                           <Form.Control type="number" id="otp2" />
//                           <Form.Control type="number" id="otp3" />
//                           <Form.Control type="number" id="otp4" />
//                         </div>
//                         <p className="text-center text-secondary my-3">
//                           Did not receive? <a href="">Resend</a>
//                         </p>
//                         <Button
//                           variant="primary"
//                           className="mx-auto my-3"
//                           onClick={mobileModalShow}
//                         >
//                           Change Mobile Number
//                         </Button>
//                       </Modal.Body>
//                     </Modal>
//                     <hr></hr>
//                     <Row className="g-gs">
//                       <Col md="4" className="p-3">
//                         <h5>Address</h5>
//                         <p className="text-middark">
//                           Edit your institute address.{" "}
//                         </p>
//                       </Col>
//                       <Col md="8" className="p-3">
//                         <Row className="g-gs">
//                           <Col lg="12" className="mt-1">
//                             <Form.Group className="form-group">
//                               <Form.Label>Address</Form.Label>
//                               <div className="form-control-wrap">
//                                 {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                 <Form.Control
//                                   type="text"
//                                   value={
//                                     data?.inst_profile?.inst_business_address
//                                   }
//                                   name="inst_business_address"
//                                   onChange={(e) =>
//                                     handleChange(e, "inst_profile")
//                                   }
//                                   id="address"
//                                   placeholder="Address"
//                                 />
//                               </div>
//                             </Form.Group>
//                           </Col>
//                           <Col lg="6" className="mt-1">
//                             <Form.Group className="form-group">
//                               <Form.Label>City</Form.Label>
//                               <div className="form-control-wrap">
//                                 {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                 <Form.Control
//                                   type="text"
//                                   id="city"
//                                   onChange={(e) =>
//                                     handleChange(e, "inst_profile")
//                                   }
//                                   name="inst_business_city"
//                                   value={data?.inst_profile?.inst_business_city}
//                                   placeholder="City"
//                                 />
//                               </div>
//                             </Form.Group>
//                           </Col>
//                           <Col lg="6" className="mt-1">
//                             <Form.Group className="form-group">
//                               {
//                                 data?.inst_profile?.inst_business_state &&(
//                              <>
//                               <Form.Label>State</Form.Label>
//                               <div className="form-control-wrap">
//                                 {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                 {/* <Form.Control
//                                   type="text"
//                                   id="state"
//                                   onChange={(e) =>
//                                     handleChange(e, "inst_profile")
//                                   }
//                                   name="inst_business_state"
//                                   value={data?.inst_profile?.inst_business_state}
//                                   placeholder="State"
//                                 /> */}

//                                 <RegionDropdown
//                                 country={data?.inst_profile?.inst_business_country}
//         value={data?.inst_profile?.inst_business_state}
//         onChange={(val) => selectRegion(val)}
//         name="inst_business_state"
//         classes="state-dropdown"

//         />
//                               </div>
//                               </>
//                               )}
//                             </Form.Group>

//                           </Col>
//                           <Col lg="6" className="mt-1">
//                             <Form.Group className="form-group">
//                               <Form.Label>Country</Form.Label>
//                               <div className="form-control-wrap">
//                                 {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                 {/* <Form.Control
//                                   type="text"
//                                   id="state"
//                                   onChange={(e) =>
//                                     handleChange(e, "inst_profile")
//                                   }
//                                   name="inst_business_country"
//                                   value={data?.inst_profile?.inst_business_country}
//                                   placeholder="State"
//                                 /> */}

// <CountryDropdown
//         value={data?.inst_profile?.inst_business_country}
//         onChange={(val) => selectCountry(val)}
//         name="inst_business_country"
//         id="inst_business_country"
//         classes="state-dropdown"

//         />

//                               </div>
//                             </Form.Group>
//                           </Col>

//                           {/* <CountryState/> */}
//                           <Col lg="12">
//                             <Form.Group className="form-group">
//                               <h4>Company details</h4>
//                               <span className="text-middark">
//                                 This detail is for busssiness in <b>India</b>{" "}
//                                 Only.
//                               </span>
//                               <div className="mt-4">
//                                 {" "}
//                                 <div className="form-check">
//                                   <input
//                                     className="form-check-input"
//                                     type="radio"
//                                     name="flexRadioDefault"
//                                     id="flexRadioDefault1"
//                                     checked={
//                                       data?.inst_gst_number_active
//                                         ? false
//                                         : true
//                                     }
//                                     onChange={(e) => {
//                                       setData((prevData) => ({
//                                         ...prevData,
//                                         ["inst_gst_number_active"]: false,
//                                         ["inst_profile"]: {
//                                           ...data.inst_profile,
//                                           ["inst_company_name"]: "",
//                                           ["inst_gst_number"]: "",
//                                         },
//                                       }));
//                                     }}
//                                   />
//                                   <label
//                                     className="form-check-label"
//                                     for="flexRadioDefault1"
//                                   >
//                                     I don't have <b>GSTIN</b> Number
//                                   </label>
//                                 </div>
//                                 <div className="form-check">
//                                   <input
//                                     className="form-check-input"
//                                     type="radio"
//                                     name="flexRadioDefault"
//                                     id="flexRadioDefault2"
//                                     checked={
//                                       data?.inst_gst_number_active
//                                         ? true
//                                         : false
//                                     }
//                                     onChange={(e) => {
//                                       setData((prevData) => ({
//                                         ...prevData,
//                                         ["inst_gst_number_active"]: true,
//                                       }));
//                                     }}
//                                   />
//                                   <label
//                                     className="form-check-label"
//                                     for="flexRadioDefault2"
//                                   >
//                                     I have <b>GSTIN</b> Number
//                                   </label>
//                                 </div>

//                               </div>
//                             </Form.Group>
//                           </Col>
//                           {data?.inst_gst_number_active && (
//                             <>
//                               <Col lg="6">
//                                 <Form.Group className="form-group">
//                                   <Form.Label>Business Name</Form.Label>
//                                   <div className="form-control-wrap">
//                                     <Form.Control
//                                       required
//                                       onChange={(e) => {
//                                         handleChange(e, "inst_profile");
//                                       }}
//                                       name="inst_company_name"
//                                       value={
//                                         data?.inst_profile?.inst_company_name
//                                       }
//                                       placeholder="Business Name"
//                                     />
//                                   </div>
//                                 </Form.Group>
//                               </Col>
//                               <Col lg="6">
//                                 <Form.Group className="form-group">
//                                   <Form.Label>GST Number</Form.Label>
//                                   <div className="form-control-wrap">
//                                     <Form.Control
//                                       type="text"
//                                       id="city"
//                                       onChange={(e) => {
//                                         handleChange(e, "inst_profile");
//                                       }}
//                                       name="inst_gst_number"
//                                       value={
//                                         data?.inst_profile?.inst_gst_number
//                                           ?.length < 16
//                                           ? data?.inst_profile
//                                             ?.inst_gst_number
//                                           : ""
//                                       }
//                                       placeholder="GST Number"
//                                     />
//                                   </div>
//                                 </Form.Group>
//                               </Col>
//                             </>
//                           )}
//                         </Row>
//                       </Col>
//                     </Row>
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="custom-profile">
//                     <div className="d-flex mb-3 align-items-center">
//                       <div className="w-30">
//                         <div className="p-2 border d-flex align-items-center justify-content-center upload-img" style={{ width: "120px", height: "120px" }}>
//                           {data?.inst_profile?.business_logo && (
//                             <Image
//                               src={data?.inst_profile?.business_logo}
//                             ></Image>
//                           )}
//                           {data?.logo?.length > 0 && (
//                             <Image src={data?.logo[0].preview}></Image>
//                           )}
//                         </div>
//                       </div>
//                       <div className="d-flex flex-column align-items-start w-30">
//                         <h5>Company Logo</h5>
//                         <p className="mb-0 text-middark">
//                           Upload your company logo to display in your panel.
//                         </p>
//                         {/* <p className="mt-0">click here to resize image</p> */}
//                       </div>
//                       <div className="d-flex w-30">
//                         <div className="d-flex justify-content-center form-control-wrap">
//                           <FileUploadNew
//                             filetype="image"
//                             iconName="upload"
//                             onChange={handleFileUpload}
//                             name="logo" // Provide the appropriate name
//                             noImage={true}
//                             aspectRatio="Recommended Aspect ratio 1:1"
//                             extensions={"Image must be JPG/JPEG or PNG"}
//                           />
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-center w-10">
//                         {data?.inst_profile?.business_logo != "" ||
//                           data?.logo != "" ? (
//                           <Button
//                             variant="outline-primary"
//                             name="business_logo"
//                             onClick={(e) => handleChange(e, "inst_profile")}
//                           >
//                             Remove
//                           </Button>
//                         ) : (
//                           <Button
//                             variant="outline-primary"
//                             name="business_logo"
//                           // onClick={(e) => handleChange(e, "inst_profile")}
//                           >
//                             Removed
//                           </Button>
//                         )}
//                       </div>
//                     </div>
//                     <hr />
//                     <div className="d-flex mb-3 align-items-center">
//                       <div className="w-30">
//                         <div
//                           className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                           style={{ width: "120px", height: "120px" }}
//                         >
//                           {data?.inst_profile?.inst_favicon_logo?.length > 0 && (
//                             <Image
//                               src={data?.inst_profile?.inst_favicon_logo}
//                             ></Image>
//                           )}
//                           {data?.inst_favicon_logo?.length > 0 && (
//                             <Image src={data?.inst_favicon_logo[0].preview}></Image>
//                           )}
//                         </div>
//                       </div>
//                       <div class="d-flex flex-column align-items-start w-30">
//                         <h5>Company Favicon Logo</h5>
//                         <p className="mb-0 text-middark">
//                           Upload your company favicon logo to display in your tab.
//                         </p>
//                       </div>
//                       <div className="d-flex w-30">
//                         <div className="d-flex justify-content-center form-control-wrap">
//                           <FileUploadNew
//                             filetype="image"
//                             iconName="upload"
//                             onChange={handleFileUploadFavicon}
//                             name="inst_favicon_logo"
//                             noImage={true}
//                             aspectRatio="Recommended Aspect ratio 1:1"
//                             extensions={"Image must be JPG/JPEG or PNG"}
//                           />

//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-center w-10">
//                         {data?.inst_profile?.inst_favicon_logo != "" ||
//                           data?.inst_favicon_logo != "" ? (
//                           <Button
//                             variant="outline-primary"
//                             name="inst_favicon_logo"
//                             onClick={(e) => handleChange(e, "inst_profile")}
//                           >
//                             Remove
//                           </Button>
//                         ) : (
//                           <Button
//                             variant="outline-primary"
//                             name="inst_favicon_logo"

//                           >
//                             Removed
//                           </Button>
//                         )}
//                       </div>
//                     </div>
//                     <hr />
//                     {/* tochan
//                       buisness certificate */}
//                     <div className="d-flex mb-3 align-items-center">
//                       <div className="w-30">
//                         <div className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                           style={{ width: "120px", height: "120px" }}
//                         >
//                           {data?.inst_profile?.business_certificate?.length > 0 && (
//                             <Image
//                               src={data?.inst_profile?.business_certificate}
//                             ></Image>
//                           )}
//                           {data?.business_certificate?.length > 0 && (
//                             <Image src={data?.business_certificate[0].preview}></Image>
//                           )}
//                         </div>
//                       </div>
//                       <div className="d-flex flex-column align-items-start w-30">
//                         <h5>business_certificate</h5>
//                         <p className="mb-0 text-middark">
//                           Upload your business_certificate
//                         </p>

//                       </div>
//                       <div className="d-flex w-30">
//                         <div className="d-flex justify-content-center form-control-wrap">
//                           <FileUploadNew
//                             filetype="image"
//                             iconName="upload"
//                             onChange={handleBusinessCertificateUploadFavicon}
//                             name="business_certificate"
//                             noImage={true}
//                             aspectRatio="Recommended Aspect ratio 1:1"
//                             extensions={"Image must be JPG/JPEG or PNG"}
//                           />
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-center w-10">
//                         {data?.inst_profile?.business_certificate != "" ||
//                           data?.business_certificate != "" ? (
//                           <Button
//                             variant="outline-primary"
//                             name="business_certificate"
//                             onClick={(e) => handleChange(e, "inst_profile")}
//                           >
//                             Remove
//                           </Button>
//                         ) : (
//                           <Button
//                             variant="outline-primary"
//                             name="business_certificate"

//                           >
//                             Removed
//                           </Button>
//                         )}
//                       </div>
//                     </div>
//                     <hr />

//                     <div className="d-flex mb-3 align-items-center">
//                       <div className="d-flex w-30 flex-wrap">
//                         {data.inst_profile?.banner_images?.length > 0 &&
//                           data.inst_profile?.banner_images.map((item) => {
//                             return (
//                               <>
//                                 <div className="w-50 mb-2">
//                                   <div
//                                     className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                     style={{ width: "120px", height: "120px" }}
//                                   >
//                                     <CloseButton
//                                       id="deleteFromFiles"
//                                       className="close-btn"
//                                       value={item.url}
//                                       name="deletebanner"
//                                       onClick={(e) =>
//                                         handleChange(
//                                           e,
//                                           "inst_profile",
//                                           null,
//                                           item.url
//                                         )
//                                       }
//                                     />
//                                     <Image src={item?.url}></Image>
//                                   </div>
//                                 </div>
//                               </>
//                             );
//                           })}
//                         {data?.banner_images?.length > 0 &&
//                           data?.banner_images.map((item, index) => {
//                             return (
//                               <>
//                                 <div className="w-50 mb-2">
//                                   <div
//                                     className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                     style={{ width: "120px", height: "120px" }}
//                                   >
//                                     <CloseButton
//                                       id="deleteFromFiles"
//                                       className="close-btn"
//                                       value={item.preview}
//                                       name="deletebanner"
//                                       onClick={(e) => {
//                                         let remove = data?.banner_images.filter(
//                                           (item, i) => i !== index
//                                         );
//                                         setData({ ...data, banner_images: remove });
//                                       }}
//                                     />
//                                     <Image src={item?.preview}></Image>
//                                   </div>
//                                 </div>
//                               </>
//                             );
//                           })}
//                         {data?.file?.length > 0 && (
//                           <div className="w-50 mb-2">
//                             <div
//                               className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                               style={{ width: "120px", height: "120px" }}
//                             >
//                               <CloseButton
//                                 className="close-btn"
//                                 id="deleteFromFiles"
//                                 value={data?.file[0].preview}
//                                 name="deletebanner"
//                                 onClick={(e) =>
//                                   handleChange(
//                                     e,
//                                     "inst_profile",
//                                     null,
//                                     data?.file[0].preview
//                                   )
//                                 }
//                               />
//                               <Image src={data?.file[0].preview}></Image>
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                       <div className="d-flex flex-column align-items-start w-30">
//                         <h5>Banner</h5>
//                         <p className="mb-0 text-middark">
//                           Upload your login screen banner for branding
//                         </p>
//                         <p className="mb-0 text-middark">
//                           You may upload a maximum of 4 banners
//                         </p>
//                         {/* <a href="" className="mt-0">click here to resize  image</a> */}
//                       </div>
//                       {data.inst_profile?.banner_images?.length +
//                         (data?.banner_images ? data?.banner_images?.length : 0) <
//                         4 && (
//                           <div className="d-flex-column">
//                           <div className="d-flex">
//                             <div className="d-flex justify-content-center form-control-wrap">
//                               <FileUploadNew
//                                 noImage={true}
//                                 filetype="image"
//                                 onChange={(updatedFiles) => {
//                                   if (
//                                     data.inst_profile?.banner_images?.length +
//                                     (data?.banner_images
//                                       ? data?.banner_images?.length
//                                       : 0) >
//                                     5
//                                   ) {
//                                     Swal.fire({
//                                       icon: "error",
//                                       title: "Oops...",
//                                       text: "Max limit Four",
//                                     });
//                                   } else {
//                                     // setData({
//                                     //   ...data,
//                                     //   banner_images: updatedFiles,
//                                     // });
//                                     updateBannerImages(updatedFiles);
//                                   }
//                                 }}
//                                 name
//                                 iconName="upload"
//                                 // aspectRatioCalculate={1}
//                                 aspectRatio="Recommended Aspect ratio 1:1"
//                                 extensions={"Image must be JPG/JPEG or PNG"}
//                               />

//                             </div>
//                           </div>
//                             <Button  onClick={() => window.open('https://www.canva.com/instagram-posts/templates/', '_blank')} variant="primary" size="sm" className="mt-2  " style={{marginLeft:"123px" ,backgroundColor:domainData?.inst_profile?.color_code}}>  Design Free banners </Button>
//                             </div>
//                         )}
//                       <div className="w-10"></div>
//                     </div>
//                     <hr />
//                     <div className="d-flex mb-3 align-items-center">
//                       <div className="d-flex w-30 flex-wrap">
//                         {data.inst_profile?.internal_banner_images?.length > 0 &&
//                           data.inst_profile?.internal_banner_images.map((item) => {
//                             return (
//                               <>
//                                 <div className="w-50 mb-2">
//                                   <div
//                                     className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                     style={{ width: "120px", height: "120px" }}
//                                   >
//                                     <CloseButton
//                                       id="deleteFromFiles"
//                                       className="close-btn"
//                                       value={item.url}
//                                       name="deleteInternalbanner"
//                                       onClick={(e) =>
//                                         handleChange(
//                                           e,
//                                           "inst_profile",
//                                           null,
//                                           item.url
//                                         )
//                                       }
//                                     />
//                                     <Image src={item?.url}></Image>
//                                   </div>
//                                 </div>
//                               </>
//                             );
//                           })}
//                         {data?.internal_banner_images?.length > 0 &&
//                           data?.internal_banner_images.map((item, index) => {
//                             return (
//                               <>
//                                 <div className="w-50 mb-2">
//                                   <div
//                                     className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                     style={{ width: "120px", height: "120px" }}
//                                   >
//                                     <CloseButton
//                                       id="deleteFromFiles"
//                                       className="close-btn"
//                                       value={item.preview}
//                                       name="deletebanner"
//                                       onClick={(e) => {
//                                         let remove = data?.internal_banner_images.filter(
//                                           (item, i) => i !== index
//                                         );
//                                         setData({ ...data, internal_banner_images: remove });
//                                       }}
//                                     />
//                                     <Image src={item?.preview}></Image>
//                                   </div>
//                                 </div>
//                               </>
//                             );
//                           })}
//                         {data?.file2?.length > 0 && (
//                           <div className="w-50 mb-2">
//                             <div
//                               className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                               style={{ width: "120px", height: "120px" }}
//                             >
//                               <CloseButton
//                                 className="close-btn"
//                                 id="deleteFromFiles"
//                                 value={data?.file2[0].preview}
//                                 name="deleteInternalbanner"
//                                 onClick={(e) =>
//                                   handleChange(
//                                     e,
//                                     "inst_profile",
//                                     null,
//                                     data?.file2[0].preview
//                                   )
//                                 }
//                               />
//                               <Image src={data?.file2[0].preview}></Image>
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                       <div className="d-flex flex-column align-items-start w-30">
//                       <h5>Student Dashboard Banner</h5>
//                         <p className="mb-0 text-middark">
//                         Upload your student dashboard banner for branding
//                         </p>
//                         <p className="mb-0 text-middark">
//                         You may upload a maximum of 4 bannersrs
//                         </p>
//                         {/* <a href="" className="mt-0">click here to resize  image</a> */}
//                       </div>
//                       {data.inst_profile?.internal_banner_images?.length +
//                         (data?.internal_banner_images ? data?.internal_banner_images?.length : 0) <
//                         4 && (
//                           <div className="d-flex-column">
//                           <div className="d-flex ">
//                             <div className="d-flex justify-content-center form-control-wrap">
//                               <FileUploadNew
//                                 noImage={true}
//                                 filetype="image"
//                                 onChange={(updatedFiles) => {
//                                   if (
//                                     data.inst_profile?.internal_banner_images?.length +
//                                     (data?.internal_banner_images
//                                       ? data?.internal_banner_images?.length
//                                       : 0) >
//                                     5
//                                   ) {
//                                     Swal.fire({
//                                       icon: "error",
//                                       title: "Oops...",
//                                       text: "Max limit Four",
//                                     });
//                                   } else {
//                                     // setData({
//                                     //   ...data,
//                                     //   banner_images: updatedFiles,
//                                     // });
//                                     updateBannerImages(updatedFiles,"internal_banner_images");
//                                   }
//                                 }}
//                                 name
//                                 iconName="upload"
//                                 aspectRatioCalculate={4}
//                                 aspectRatio="Recommended Aspect ratio 4:1"
//                                 extensions={"Image must be JPG/JPEG or PNG"}
//                               />

//                             </div>
//                           </div>

//                           <Button  onClick={() => window.open('https://www.canva.com/linkedin-banners/templates/', '_blank')
//                     }variant="primary" size="sm" className="mt-2  " style={{marginLeft:"123px",backgroundColor:domainData?.inst_profile?.color_code}}>  Design Free banners </Button>

//                           </div>
//                         )}
//                       <div className="w-10"></div>
//                     </div>

//                     <hr />

//                     {/* <div className="d-flex flex-column flex-md-row gap-5 align-items-center py-4">
//                     <div>
//                     <div className="d-flex- flex-column align-items-center justify-content-center">
//                       <h5>Brand Color</h5>
//                       <p className="mb-0 text-middark">
//                         Choose your brand color for panel.
//                       </p>
//                       <a href="" className="mt-0">
//                         <Icon name="video-fill"></Icon> <span>Watch Now</span>
//                       </a>
//                     </div>
//                     <Form.Group className="form-group">
//                       <div className="form-control-wrap">

//                       <ColorPicker setData={setData} data={data}/>

//                       </div>
//                     </Form.Group>

//                   </div>
//                   <div className="d-flex text-secondary">
//                     <div>
//                     <p>Enqruiry Form OTP Feature</p>
//                     <p> This feature allows you to verify mobile numbers using an OTP in the enquiry Form.</p>

//                  <p>Note : This is a paid feature. you can charge ₹ 2 per OTP message, and the charge will be reflected in the credit History</p>
//                  </div>
//                  <div>

//               <div className="d-flex gap-2">
//               <div>{data?.enquiry_otp_deduction ?"ON":"OFF"} </div> <div>    <Form.Check type="switch" id="flexSwitchChecked"
//                   name='enquiry_otp_deduction'
//                   onChange={(e) => handleChange(e, "check")}
//                   checked={data?.enquiry_otp_deduction}

//                 />
//                 </div>
//               </div>

//                  </div>

//                   </div>

//                   </div> */}

//                   <div className="d-flex flex-column flex-md-row gap-2 align-items-center py-4">
//                         <div class="w-40 d-flex gap-4 align-items-center">
//                           <div className="d-flex- flex-column align-items-center justify-content-center">
//                             <h5>Brand Color</h5>
//                             <p className="mb-0 text-middark">
//                               Choose your brand color for panel.
//                             </p>
//                             <a href="" className="mt-0">
//                               <Icon name="video-fill"></Icon> <span>Watch Now</span>
//                             </a>
//                           </div>
//                           <Form.Group className="form-group">
//                             <div className="form-control-wrap">

//                               <ColorPicker setData={setData} data={data} />
//                               {/* <Form.Control
//                           type="color"
//                           value={data?.inst_profile?.color_code}
//                           name="color_code"
//                           onChange={(e) => handleChange(e, "inst_profile")}
//                         /> */}
//                             </div>
//                           </Form.Group>

//                         </div>
//                         <div className="d-flex text-secondary w-60 align-items-center gap-3">
//                           <div class="w-80">
//                             <p>Enquiry Form OTP Feature</p>
//                             <p> This feature allows you to verify mobile numbers using an OTP in the enquiry Form.</p>
//                             <p>Note : This is a paid feature. you can charge ₹ 2 per OTP message, and the charge will be reflected in the credit History</p>
//                           </div>
//                           <div class="w-20">
//                             <div className="d-flex gap-2">
//                               <div>{data?.enquiry_otp_deduction ? "ON" : "OFF"} </div> <div>    <Form.Check type="switch" id="flexSwitchChecked"
//                                 name='enquiry_otp_deduction'
//                                 onChange={(e) => handleChange(e, "check")}
//                                 checked={data?.enquiry_otp_deduction} />
//                               </div>
//                             </div>

//                           </div>

//                         </div>

//                       </div>

//                     <hr></hr>
//                     <Row className="g-gs">
//                       <Col md="12">
//                         <h5>Website</h5>
//                       </Col>
//                       {admin_profile && (
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">
//                            Edit your Domain Name.
//                           </Form.Label>
//                           <div className="form-control-wrap">
//                             {admin_profile && (
//                               <Form.Control
//                                 type="text"
//                                 id="username"
//                                 value={data?.domain_name}
//                                 name="domain_name"
//                                 onChange={(e) => handleChange(e)}
//                                 placeholder="website link"
//                               />
//                             )}
//                           </div>
//                         </Form.Group>
//                       </Col>)
// }
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Instagram Link</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               type="text"
//                               id="username"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_instagram"
//                               value={
//                                 data?.inst_profile?.inst_business_instagram
//                               }
//                               placeholder="instagram link"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Facebook Link</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               type="text"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_facebook"
//                               value={data?.inst_profile?.inst_business_facebook}
//                               id="username"
//                               placeholder="Facebook link"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">LinkedIn Link</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               type="text"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_linkedin"
//                               value={data?.inst_profile?.inst_business_linkedin}
//                               id="username"
//                               placeholder="LinkedIn link"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Helpline no</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               type="text"
//                               className="mt-1"
//                               onChange={(e) => handleChange(e)}
//                               name="mobile"
//                               value={data?.mobile}
//                               id="username"
//                               placeholder="mobile"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       {/*
//                         // accoridng to client requirements

//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Country</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Select
//                               required
//                               id="selectcountry"
//                               value={data?.inst_profile?.inst_business_country}
//                               aria-label="Default select example"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_country"
//                             >
//                               <option></option>
//                               {countryList.length > 0 &&
//                                 countryList.map((item) => {
//                                   return (
//                                     <>
//                                       <option value={item.text}>
//                                         {item.text}
//                                       </option>
//                                     </>
//                                   );
//                                 })}
//                             </Form.Select>
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Website Url</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="website"
//                               value={data?.inst_profile?.inst_business_url}
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_url"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Facebook Url</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="facebook"
//                               value={data?.inst_profile?.inst_facebook_url}
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_facebook_url"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Street Address</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="address"
//                               value={data?.inst_profile?.inst_business_address}
//                               placeholder="Street Address"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_address"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">City</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="city"
//                               value={data?.inst_profile?.inst_business_city}
//                               placeholder="City"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_city"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">State</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               value={data?.inst_profile?.inst_business_state}
//                               id="state"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_state"
//                               placeholder="State/Province"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Postal Code</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="number"
//                               id="pincode"
//                               value={data?.inst_profile?.inst_business_postal_code}
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_postal_code"
//                               placeholder="Postal Code"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       */}
//                     </Row>
//                   </Tab.Pane>

//                   <Tab.Pane eventKey="custom-team">
//                     <TeamDataTable
//                       tableClassName="data-table-head-light table-responsive team-edit"
//                       inst_id={inst_id}
//                       data={teamData}
//                       columns={TeamColumns}
//                     />
//                   </Tab.Pane>

//                   <Tab.Pane eventKey="custom-branch">
//                     <BranchAdminDataTable inst_id={inst_id || userData?._id}/>
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="plan-price">
//                   { Object.values(institutePlanPrice)?.length > 0 ?
//                   <InstitutePlanPrice /> :

//                   // <div className="text-secondary">No plan buy for this institute</div>
//                   <Alert className='mt-4' variant="danger">No plan buy for this institute</Alert>
// }
//                   </Tab.Pane>
//                 </Tab.Content>
//               </Tab.Container>

//               {activeKey != "custom-team" && activeKey != "plan-price" && (
//                 <div className="d-flex justify-content-center my-5">
//                   <Button
//                     variant="primary"
//                     className="w-25 mx-auto"
//                     onClick={submitForm}
//                   >
//                     Update
//                   </Button>
//                 </div>
//               )}
//             </Card.Body>
//           </Card>
//         )}
//       </Block>
//     </Layout>
//     </>
//   );
// }

// export default AdminDashboard;
// **********************************************************
// import React, { useState, useEffect, useContext, useRef } from "react";
// import {
//   Card,
//   Form,
//   Row,
//   Col,
//   Nav,
//   Modal,
//   Tab,
//   Tabs,
//   Button,
//   Dropdown,
//   ButtonGroup,
//   InputGroup,
//   Badge,
//   Table,
//   CloseButton,
//   ToastContainer,
//   Toast,
//   Alert,
// } from "react-bootstrap";
// import Layout from "../../layout/default";
// import Block from "../../components/Block/Block";
// import FileUploadNew from "../../components/Form/FileUploadNew";
// import { City, Country, State } from "country-state-city";
// // import dateUtils from '../../utilities/dateUtils';
// import {
//   Icon,
//   Image,
//   Media,
//   Pureknob,
//   ChartLegend,
//   CustomDropdownMenu,
//   CustomDropdownToggle,
//   MediaGroup,
//   LinkList,
//   LinkListItem,
//   MediaText,
// } from "../../components";
// import FileUpload from "../../pages/material-management/components/fileUpload";
// import TeamDataTable from "./TeamDataTable";
// import TeamData, { TeamColumns } from "./TeamData";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import AuthApi from "../../api/auth";
// import Context from "../../context";
// import Swal from "sweetalert2/src/sweetalert2.js";
// import BranchList from "../branch/ViewBranch";
// import { getTimeZones, getTimezoneOffset } from "../../utilities/timeZones";
// import countryList from "../../country";
// import BranchAdminDataTable from "./BranchAdminDataTable";
// import { colors } from "../../utilities/colorsToAvoid";
// import ColorPicker from "../../components/Form/ColorPicker";
// import {
//   Button as ButtonCh,
//   Wrap,
//   WrapItem,
//   color,
//   useToast,
// } from "@chakra-ui/react";
// import InstitutePlanPrice from "./InstitutePlanPrice";
// import { CountryState } from "../../components/Form/CountryState";
// // import  CountryState  from "../../components/Form/CountryState";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
// import dateUtils from "../../utilities/dateUtils";
// import CountryState2 from "../../components/Form/CountryState2";
// import FlagAndMobileOtp from "../../components/Form/FlagAndMobileOtp";
// // import CountryState2 from "../../components/Form/CountryState2";

// import { FaEdit } from "react-icons/fa"; // Adjust the import according to your icon library

// function AdminDashboard() {
//   let {
//     userData,
//     token,
//     domainData,
//     setUserData,
//     localHost,
//     setDomainData,
//     setToken,
//   } = useContext(Context);
//   userData = JSON.parse(userData);
//   let institute_profile = userData?.login_type == "1";
//   let admin_profile = userData?.login_type == "0";
//   let student_profile = userData?.login_type == "2";
//   console.log("institute_profile", institute_profile);
//   console.log("admin_profile", admin_profile);
//   console.log("student_profile", student_profile);
//   domainData = JSON.parse(domainData);

//   console.log(
//     domainData?.inst_profile?.color_code,
//     "domainData?.inst_profile?.color_code"
//   );
//   const [demoModal, setDemoModal] = useState(false);
//   const [demoModalEmail, setDemoModalEmail] = useState(false);

//   const demoModalClose = () => setDemoModal(false);
//   const demoModalCloseEmail = () => setDemoModalEmail(false);
//   const demoModalShow = () => setDemoModal(true);

//   const [otpModal, setOtpModal] = useState(false);
//   const [mobileOtpModalError, setMobileOtpModalError] = useState("");

//   const otpModalClose = () => setOtpModal(false);
//   const otpModalShow = () => setOtpModal(true) & setDemoModal(false);

//   const [mobileModal, setMobileModal] = useState(false);
//   const [mobileModalResend, setMobileModalResend] = useState(false);

//   const mobileModalClose = () => setMobileModal(false);
//   const mobileModalShow = () => setMobileModal(true) & setOtpModal(false);

//   const [billdetailModal, setBilldetailModal] = useState(false);

//   const billdetailModalClose = () => setBilldetailModal(false);
//   const billdetailModalShow = () => setBilldetailModal(true);

//   const [gstModal, setGstModal] = useState(false);

//   const gstModalClose = () => setGstModal(false);
//   const gstModalShow = () => setGstModal(true);

//   const [show, setShow] = useState(false);

//   const handlePhoneInput = (e) => {
//     e.target.value = e.target.value.replace(/[^0-9]/g, "");
//     setMobileOtpModalError("");
//   };

//   let api = new AuthApi();
//   const { domain_name, inst_id } = useParams();
//   const [data, setData] = useState({
//     timeZone: userData?.timeZone,
//     errors: {},
//   });

//   const [colorPickError, setColorPickError] = useState("");

//   console.log("data432234userData :>> ", userData);
//   const [countryNumber, setCountryNumber] = useState();

//   const { errors } = data;
//   const [data1, setData1] = useState({});
//   const [teamData, setTeamdata] = useState([]);
//   const [topUpModal, setTopUpTrue] = useState(false);
//   const [institutePlan, setInstitutePlan] = useState({});
//   const [institutePlanPrice, setInstitutePlanPrice] = useState({});
//   const [country, setCountry] = useState({});
//   const [stateSingle, setStateSingle] = useState({});
//   const [citySingle, setCitySingle] = useState({});
//   const [stateData, setStateData] = useState([]);

//   const [cityData, setCityData] = useState([]);

//   const resendOtpHandler = () => {
//     setOtpModal(false);
//     setMobileModal(false);
//   };

//   const handleFileUpload = (newFiles) => {
//     setData((prevData) => ({
//       ...prevData,
//       ["logo"]: newFiles,
//       ["inst_profile"]: {
//         ...data.inst_profile,
//         ["business_logo"]: "",
//       },
//     }));
//   };

//   const handleFileUploadFavicon = (newFiles) => {
//     setData((prevData) => ({
//       ...prevData,
//       ["inst_favicon_logo"]: newFiles,
//       ["inst_profile"]: {
//         ...data.inst_profile,
//         ["inst_favicon_logo"]: "",
//       },
//     }));
//   };

//   const handleBusinessCertificateUploadFavicon = (newFiles) => {
//     setData((prevData) => ({
//       ...prevData,
//       ["business_certificate"]: newFiles,
//       ["inst_profile"]: {
//         ...data.inst_profile,
//         ["business_certificate"]: "",
//       },
//     }));
//   };

//   console.log("FILESTOUPLOAD", data);
//   let formDataObject = new FormData();
//   let navigate = useNavigate();

//   const submitForm = async (e) => {
//     // e.preventDefault();

//     //remove unnecessary data from uploaded
//     delete data.createdAt;
//     delete data.otp;
//     delete data.updatedAt;
//     delete data.password;
//     delete data._token;
//     delete data.permission_routes;
//     delete data.otp_forget_password;
//     // delete data.domain_name

//     console.log("domain_data :>> ", data);
//     for (const [key, value] of Object.entries(data)) {
//       if (key === "banner_images") {
//         for (const imageFile of value) {
//           formDataObject.append("banner_images", imageFile);
//         }
//       } else if (key === "internal_banner_images") {
//         for (const imageFile of value) {
//           formDataObject.append("internal_banner_images", imageFile);
//         }
//       } else if (
//         key === "business_certificate" ||
//         key === "logo" ||
//         key === "inst_favicon_logo"
//       ) {
//         if (key === "logo") {
//           formDataObject.append(key, value[0]);
//         } else {
//           console.log("MAC", key, value[0]);
//           formDataObject.append(key, value[0]);
//         }
//       } else {
//         if (typeof value === "object" && value !== null) {
//           if (key == "inst_profile") {
//             formDataObject.append("inst_profile", JSON.stringify(value));
//           }
//         } else {
//           formDataObject.append(key, value);
//         }
//       }
//     }

//     // if(window.location.pathname == "/institute/profile"){
//     //   formDataObject = new FormData();
//     //   for (const [key, value] of Object.entries(data)) {
//     //     if (key === "_id") {
//     //       formDataObject.append(key, value);
//     //     } else if (key === "logo") {
//     //       if (key === "logo") {
//     //         formDataObject.append(key, value[0]);
//     //       }
//     //     }
//     //   }
//     // }

//     await api.institute_register(formDataObject).then((res) => {
//       try {
//         console.log("res :>> ", res);
//         if (res.status) {
//           Swal.fire({
//             icon: "success",
//             title: "Good job!",
//             text: res.msg,
//           });
//           // navigate("/");
//           // isEdit(false)
//           if (res.data?.length > 0 && userData.login_type != "0") {
//             setUserData(JSON.stringify(res.data[0]));
//             localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
//             localStorage.setItem(
//               "instDomainHilingo",
//               JSON.stringify(res.data[0])
//             );
//             setDomainData(JSON.stringify(res.data[0]));

//             setOtpModal(false);
//             setMobileModal(false);
//             setDemoModal(false);
//             setDemoModalEmail(false);
//           }
//           if (res.data?.length > 0 && userData.login_type == "0") {
//             console.log("inside if");
//             setIsEdit(false);
//             navigate(
//               `/institute-detail/${res?.data?.[0]?.domain_name}/${res?.data?.[0]?._id}`
//             );
//           }
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.msg,
//           });
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     });
//   };
//   useEffect(() => {
//     getInstittuteDetailByname();
//   }, [domain_name]);
//   const getInstittuteDetailByname = async () => {
//     await api
//       .get_instittute_By_Name({
//         name: domain_name ? domain_name : userData.domain_name,
//       })
//       .then((res) => {
//         if (res.data?.length > 0) {
//           setCountryNumber(res?.data[0]?.country_code);

//           setData1({
//             ...res.data[0],
//             ["inst_gst_number_active"]:
//               res.data[0]?.inst_profile?.hasOwnProperty("inst_gst_number") &&
//               res.data[0]?.inst_profile?.hasOwnProperty("inst_company_name") &&
//               (res.data[0]?.inst_profile?.inst_gst_number != "" ||
//                 res.data[0]?.inst_profile?.inst_company_name != "")
//                 ? true
//                 : false,
//           });
//           setData({
//             ...res.data[0],
//             inst_profile: {
//               ...res.data[0]?.inst_profile,
//               internal_banner_images: res.data[0]?.inst_profile
//                 ?.internal_banner_images
//                 ? res.data[0]?.inst_profile?.internal_banner_images
//                 : [],
//             },

//             ["inst_gst_number_active"]:
//               res.data[0]?.inst_profile?.hasOwnProperty("inst_gst_number") &&
//               res.data[0]?.inst_profile?.hasOwnProperty("inst_company_name") &&
//               (res.data[0]?.inst_profile?.inst_gst_number != "" ||
//                 res.data[0]?.inst_profile?.inst_company_name != "")
//                 ? true
//                 : false,
//           });

//           console.log("res?.data[0] :>> ", res?.data[0]?.country_code);
//           // setCountryNumber(res?.data[0]?.country_code)
//           const result = countryData.filter(
//             (c) =>
//               c.name.toLowerCase() ==
//               res.data[0]?.inst_profile?.inst_business_country.toLowerCase()
//           )[0];
//           console.log("SERVERPRO", result, countryData);
//           setCountry(result);

//           console.log("stateData :>> ", stateData);
//           const stateResult = State.getStatesOfCountry(result?.isoCode).filter(
//             (c) =>
//               c.name.toLowerCase() ==
//               res.data[0]?.inst_profile?.inst_business_state.toLowerCase()
//           )[0];
//           console.log("stateResult", stateResult);
//           setStateSingle(stateResult);

//           const cityResult = City.getCitiesOfState(
//             result?.isoCode,
//             stateResult?.isoCode
//           ).filter(
//             (c) =>
//               c.name.toLowerCase() ==
//               res.data[0]?.inst_profile?.inst_business_city.toLowerCase()
//           )[0];
//           console.log("cityResult :>> ", cityResult);
//           setCitySingle(cityResult);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   // console.log("dataaaa", data);
//   console.log("data._id", data._id);
//   useEffect(() => {
//     if (inst_id) {
//       getTeamByInstituteId(inst_id);
//     } else if (data._id) {
//       getTeamByInstituteId(data._id);
//     }
//   }, [data?._id, inst_id]);

//   const removeTeamById = async (params) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.value) {
//         api
//           .inactive_user(params, token)
//           .then((res) => {
//             if (res.status) {
//               getTeamByInstituteId();
//             } else {
//               Swal.fire({
//                 icon: "error",
//                 title: "Oops...",
//                 text: res.msg,
//               });
//             }
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       }
//     });
//   };

//   const handleChange = (e, inst_profile, index, banner) => {
//     let { value, name, checked } = e?.target;
//     console.log("nameee", value);
//     //block light colors

//     if (name == "inst_business_name") {
//       setData((prevData) => ({
//         ...prevData,
//         ["domain_name"]: value?.replace(/\s+/g, " ").trim(),
//       }));
//     }

//     if (inst_profile) {
//       if (name == "business_logo") {
//         setData((prevData) => ({
//           ...prevData,
//           ["logo"]: "",
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: "",
//           },
//         }));
//       } else {
//         setData((prevData) => ({
//           ...prevData,
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: value?.replace(/\s+/g, " ").trim(),
//           },
//         }));
//       }
//     } else {
//       if (name == "logo" || name == "business_certificate") {
//         setData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
//       } else {
//         if (name == "banner_images") {
//           let tempArray = [...data?.banner_images];
//           tempArray[index] = e.target.files[0];
//           // setData((prevData) => ({ ...prevData, [name]: tempArray }));
//         } else {
//           if (name == "domain_name") {
//             setData((prevData) => ({
//               ...prevData,
//               [name]: value?.replace(/\s+/g, " ").trim(),
//               ["inst_profile"]: {
//                 ...data.inst_profile,
//                 ["inst_business_name"]: value?.replace(/\s+/g, " ").trim(),
//               },
//             }));
//           } else {
//             setData((prevData) => ({
//               ...prevData,
//               [name]: value?.replace(/\s+/g, " ").trim(),
//             }));
//           }
//         }
//       }
//     }

//     if (name == "deletebanner") {
//       // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
//       setData((prevData) => ({
//         ...prevData,
//         ["inst_profile"]: {
//           ...data.inst_profile,
//           ["banner_images"]: data?.inst_profile?.banner_images.filter(
//             (item) => item.url != banner
//           ),
//         },
//       }));
//     }

//     if (name == "deleteInternalbanner") {
//       // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
//       setData((prevData) => ({
//         ...prevData,
//         ["inst_profile"]: {
//           ...data.inst_profile,
//           ["internal_banner_images"]:
//             data?.inst_profile?.internal_banner_images.filter(
//               (item) => item.url != banner
//             ),
//         },
//       }));
//     }

//     if (name == "enquiry_otp_deduction") {
//       console.log("checkedddddddd :>> ", name);
//       setData((prev) => ({ ...prev, enquiry_otp_deduction: checked }));
//     }

//     if (name == "color_code") {
//       console.log("COLRS", colors, value);
//       if (colors.includes(value)) {
//         // alert("Don`t Pick Light Color");
//         setShow(true);

//         setData((prevData) => ({
//           ...prevData,
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: userData?.inst_profile?.color_code,
//           },
//         }));
//       } else {
//         setData((prevData) => ({
//           ...prevData,
//           ["inst_profile"]: {
//             ...data.inst_profile,
//             [name]: value,
//           },
//         }));
//       }
//     }

//     if (name == "mobile") {
//       setData((prev) => ({
//         ...prev,
//         country_code: countryNumber,
//         ["type"]: "enquiry_form",
//         // institute_id:userData?._id
//       }));
//     }

//     // if(name == "username"){

//     //   // data?.inst_profile?.inst_contact_firstname
//     //   setData((prev)=>({...prev,  ...inst_profile,inst_profile:{inst_contact_firstname: value }}));
//     // }

//     if (name == "username") {
//       // data?.inst_profile?.inst_contact_firstname
//       setData((prev) => ({
//         ...prev,
//         inst_profile: { ...prev?.inst_profile, inst_contact_firstname: value },
//       }));
//     }

//     if (name == "inst_business_city") {
//       // data?.inst_profile?.inst_contact_firstname
//       setData((prev) => ({
//         ...prev,
//         inst_profile: { ...prev?.inst_profile, inst_business_city: value },
//       }));
//     }

//     if (name == "inst_business_address") {
//       // data?.inst_profile?.inst_contact_firstname
//       setData((prev) => ({
//         ...prev,
//         inst_profile: { ...prev?.inst_profile, inst_business_address: value },
//       }));
//     }
//   };

//   const selectCountry = (val) => {
//     console.log("va43l", val);

//     setData((prev) => ({
//       ...prev,
//       inst_profile: { ...prev?.inst_profile, inst_business_country: val },
//     }));
//   };

//   const selectRegion = (val) => {
//     console.log("val32322 :>> ", val);
//     setData((prev) => ({
//       ...prev,
//       inst_profile: { ...prev?.inst_profile, inst_business_state: val },
//     }));
//   };

//   const getTeamByInstituteId = async (instid) => {
//     console.log("instid", instid);
//     console.log("data._id", data._id);
//     await api
//       .get_teachers_By_Inst({ id: instid ? instid : data._id, token })
//       .then((res) => {
//         if (res?.data?.length > 0) {
//           console.log("GET TEACHERS BY INST ID -> ", res.data);
//           setTeamdata(res?.data.reverse());
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const getInsitutePlan = async (instId) => {
//     await api
//       .get_plans_by_inst(instId)
//       .then((res) => {
//         if (res.status) {
//           setInstitutePlan(res.data);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const getInsitutePlanPrice = async (instId) => {
//     await api
//       .get_plans_by_inst(instId)
//       .then((res) => {
//         if (res.status) {
//           setInstitutePlanPrice(res.data);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const givenDate = new Date(
//     student_profile ? userData?.createdAt : data1.createdAt
//   );
//   // Define an array of month names
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   // Extract year, month, and day from the Date object
//   const year = givenDate.getUTCFullYear();
//   const monthIndex = givenDate.getUTCMonth();
//   const day = givenDate.getUTCDate();

//   // Create the desired date format
//   const desiredDateFormat = `${day} ${monthNames[monthIndex]} ${year}`;

//   const TeamColumns = [
//     {
//       name: "SRNo",
//       cell: (row, index) => <span className="text-middark">{index + 1}</span>,
//       sortable: false,
//     },
//     // {
//     //   name: "Email",
//     //   selector: (row) => row.name,
//     //   cell: (row) => (
//     //     <MediaGroup>
//     //       <Media size="md" shape="circle" variant={row.theme && row.theme}>
//     //         {/* { row.avatar ?
//     //                     <Image src={row.avatar} staticImage/> :
//     //                     <span className="smaller fw-medium">{(row?.name)}</span>
//     //                 } */}
//     //       </Media>
//     //       <MediaText>
//     //         <Link to={`/user-manage/user-profile/${row._id}`} className="title">
//     //           {row?.email}
//     //         </Link>
//     //       </MediaText>
//     //     </MediaGroup>
//     //   ),
//     //   sortable: true,
//     // },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       cell: (row) => <span className="text-middark">{row.email}</span>,
//       sortable: true,
//     },
//     {
//       name: "Name",
//       selector: (row) => row.username,
//       cell: (row) => <span className="text-middark">{row.name}</span>,
//       sortable: true,
//     },
//     {
//       name: "Mobile",
//       selector: (row) => row.username,
//       cell: (row) => <span className="text-middark">{row.mobile}</span>,
//       sortable: true,
//     },
//     {
//       name: "status",
//       selector: (row) => row.status,
//       cell: (row) => (
//         // <span className={`badge text-bg-${row.status === "Active" ? "success"
//         //     : row.status === "Inactive" ? "danger"
//         //         : "primary"}`
//         // }>
//         <span
//           className={`badge text-bg-${
//             row.is_deleted == 0
//               ? "success"
//               : row.is_deleted == 1
//               ? "danger"
//               : "primary"
//           }`}
//         >
//           {row.is_deleted == 0 ? "Active" : "Inactive"}
//         </span>
//       ),
//       sortable: true,
//     },
//     {
//       name: "Role",
//       selector: (row) => row?.teacher_profile?.teacher_role,
//       cell: (row) => (
//         <span className="text-middark">
//           {row?.teacher_profile?.teacher_role}
//         </span>
//       ),
//       sortable: true,
//     },
//     {
//       name: "action",
//       cell: (row) => (
//         <div className="w-100">
//           <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
//             <LinkListItem to={`/edit-team/${row._id}`}>
//               <Icon name="edit"></Icon>
//             </LinkListItem>

//             <div
//               className="link-list-hover-bg-primary link-list-md d-flex del-btn"
//               onClick={() => removeTeamById({ _id: row._id })}
//             >
//               <Icon name="trash"></Icon>
//             </div>
//             {/* <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
//                                         <Icon name="trash"></Icon>
//                                     </LinkListItem> */}
//           </LinkList>
//         </div>
//       ),
//       sortable: false,
//     },
//   ];
//   let isAdmin = userData?.login_type == "0";
//   const [activeKey, setActiveKey] = useState("custom-home");
//   const [topup_amount, setTopUpAmnt] = useState(0);
//   const handleSelect = (selectedKey) => {
//     setActiveKey(selectedKey);
//   };
//   const add_topup_credit = async () => {
//     await api
//       .add_topup_credit({ amount: Number(topup_amount), user_id: inst_id })
//       .then((res) => {
//         if (res?.status) {
//           Swal.fire({
//             icon: "success",
//             title: "Good job!",
//             text: res.message,
//           });
//           navigate("/institute-list");
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.msg,
//           });
//         }
//       })
//       .catch((er) => console.log(er));
//   };

//   const [staffList, setStaffList] = useState([]);
//   useEffect(() => {
//     staff_List();
//     getInsitutePlan(userData?._id);
//     getInsitutePlanPrice(inst_id);
//   }, []);

//   const staff_List = async () => {
//     await api
//       .staff_List()
//       .then((res) => {
//         console.log("STAFF LIST --> ", res);
//         if (res.data?.length > 0) {
//           setStaffList(res.data.reverse());
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   // useEffect(()=>{
//   // setData((prevData) => ({
//   //   ...prevData,
//   //   ["inst_profile"]: {
//   //     ...data.inst_profile,
//   //     ["inst_gst_number_active"]: data?.inst_profile?.inst_gst_number?true:false,
//   //   },
//   // }));
//   // },[data])

//   const updateBannerImages = (updatedFiles, internal_banner_images) => {
//     console.log("object :>> ", updatedFiles, internal_banner_images);
//     if (internal_banner_images) {
//       if (data?.internal_banner_images?.length) {
//         let previousArray = [...data.internal_banner_images];
//         let updatedArray = previousArray.concat(updatedFiles);
//         setData({
//           ...data,
//           internal_banner_images: updatedArray,
//         });
//       } else {
//         setData({
//           ...data,
//           internal_banner_images: updatedFiles,
//         });
//       }
//     } else {
//       if (data?.banner_images?.length) {
//         let previousArray = [...data.banner_images];
//         let updatedArray = previousArray.concat(updatedFiles);
//         setData({
//           ...data,
//           banner_images: updatedArray,
//         });
//       } else {
//         setData({
//           ...data,
//           banner_images: updatedFiles,
//         });
//       }
//     }
//   };
//   const [isEdit, setIsEdit] = useState(false);
//   const editHandler = () => {
//     setIsEdit(true);
//   };
//   const toast = useToast();
//   const toastIdRef = React.useRef();

//   const [copyText, setCopyText] = useState("");
//   const handleCopyClick = (textToCopy) => {
//     console.log("textToCopy :>> ", textToCopy);
//     navigator.clipboard
//       .writeText(textToCopy)
//       .then(() => {
//         console.log("Text copied to clipboard");
//         setCopyText("Text copied to clipboard");
//       })
//       .catch((err) => {
//         console.error("Failed to copy text: ", err);
//         setCopyText("Failed to copy text: ", err);
//       });
//   };

//   console.log("isEdit :>> ", institutePlanPrice);

//   let countryData = Country.getAllCountries();

//   useEffect(() => {
//     if (country) {
//       setStateData(State.getStatesOfCountry(country.isoCode));
//     }
//   }, [country]);

//   useEffect(() => {
//     if (stateSingle) {
//       setCityData(
//         City.getCitiesOfState(country?.isoCode, stateSingle?.isoCode)
//       );
//     }
//   }, [stateSingle]);

//   const handleCountryChange = (e) => {
//     const selectedCountry = countryData.find(
//       (c) => c.isoCode === e.target.value
//     );
//     setCountry(selectedCountry);
//     setData((prev) => ({
//       ...prev,
//       inst_profile: {
//         ...prev.inst_profile,
//         inst_business_country: selectedCountry?.name,
//       },
//     }));
//   };

//   const handleStateChange = (e) => {
//     const selectedState = stateData.find((s) => s.isoCode === e.target.value);
//     setStateSingle(selectedState);
//     //   setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:state?.name}})) // not instant update
//     // const selectedCountry = countryData.find(c => c.isoCode === e.target.value);
//     // setCountry(selectedCountry);
//     setData((prev) => ({
//       ...prev,
//       inst_profile: {
//         ...prev.inst_profile,
//         inst_business_state: selectedState?.name,
//       },
//     }));
//   };

//   const handleCityChange = (e) => {
//     const selectedCity = cityData.find((s) => s.name === e.target.value);
//     setCitySingle(selectedCity);
//     //   setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:state?.name}})) // not instant update
//     // const selectedCountry = countryData.find(c => c.isoCode === e.target.value);
//     // setCountry(selectedCountry);
//     setData((prev) => ({
//       ...prev,
//       inst_profile: {
//         ...prev.inst_profile,
//         inst_business_city: selectedCity?.name,
//       },
//     }));
//   };

//   const [verified, setVerified] = useState(false);
//   const [otp, setOtp] = useState(null);
//   const [timer, setTimer] = useState(false);
//   const [VisibleDiv, setVisibleDiv] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [otpModalText, setoptModalText] = useState("");
//   async function generateRandomNumber(type) {
//     setoptModalText(type);
//     setLoading(true);
//     const min = 100000;
//     const max = 999999;
//     const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
//     console.log("randomNumber", randomNumber);
//     await api
//       .send_email_otp({
//         request_id: randomNumber,
//         name: userData?.inst_profile?.inst_contact_firstname,
//         email: type == "mobile" ? userData?.email : data?.email,

//         type: "update_inst_mobile",
//       })
//       .then((res) => {
//         console.log("otpres", res);
//         if (res.status) {
//           Swal.fire({
//             icon: "send",
//             title: res.msg,
//           });
//           setLoading(false);
//           // setDemoModal(false)
//           if (type == "mobile") {
//             setMobileModal(true);
//           } else if (type == "email") {
//             setOtpModal(true);
//           }
//           // setOtpModal(true)
//           // setDemoModal(false);

//           setOtp(randomNumber);
//           setVisibleDiv(true);
//           setTimer(false);
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.message,
//           });
//           setTimer(true);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   console.log(data, "countryNumber");

//   const inputRefs = useRef([]);
//   // const [setOtpValue,setOtpValue] =useState()



//   const [otpValue, setOtpValue] = useState(new Array(6).fill(""));

//   const [tempOtpValue, setTempOtpValue] = useState("");

//   // const handleChangeotp = (e, index) => {
//   //   const value = e.target.value;

//   //   // Update the specific index with the new value
//   //   const newOtp = [...otpValue];
//   //   newOtp[index] = value;

//   //   // Update the OTP state
//   //   setOtpValue(newOtp);

//   //   // Log the updated OTP value
//   //   console.log("value34", newOtp.join(''));
//   //   if (/^[0-9]$/.test(value)) {
//   //     // Only accept a single digit and move to the next input if available
//   //     if (index < inputRefs.current.length - 1) {
//   //       inputRefs.current[index + 1].focus();
//   //     }
//   //     setOtpValue(value)
//   //   } else {
//   //     // Clear the input if it's not a single digit
//   //     e.target.value = '';
//   //   }
//   // };
//   const handlePaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
// console.log(pastedData,"pastedData");

//     if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
//       for (let i = 0; i < 6; i++) {
//         inputRefs.current[i].value = pastedData[i];
//       }
//       setTempOtpValue(pastedData)
//     }
//   };

//   const handleChangeotp = (e, index) => {
//     const value = e.target.value;

//     // Update the specific index with the new value
//     const newOtp = [...otpValue];
//     console.log("newOtp :>>2 ", newOtp);
//     newOtp[index] = value;
//     console.log("newOtp :>> ", newOtp);
//     // Update the OTP state

//     // setOtpValue(newOtp?.join(''));
//     setOtpValue(newOtp);

//     // Log the updated OTP value
//     console.log("value34", newOtp.join(""));

//     // Convert the OTP to a number if all digits are entered
//     // const otpNumber = newOtp.join('');
//     // if (/^[0-9]{6}$/.test(otpNumber)) {
//     //     console.log("OTP Number:", parseInt(otpNumber, 10));
//     // }

//     if (/^[0-9]$/.test(value)) {
//       // Only accept a single digit and move to the next input if available
//       if (index < inputRefs.current.length - 1) {
//         inputRefs.current[index + 1].focus();
//       }
//     } else {
//       // Clear the input if it's not a single digit
//       e.target.value = "";
//     }
//   };

//   useEffect(() => {
//     // Update the main OTP state whenever the temporary OTP state changes
//     setTempOtpValue(otpValue?.join(""));
//   }, [otpValue]);

//   console.log("tempOtpValue :>> ", tempOtpValue, otpValue);
//   const handleKeyDown = (e, index) => {
//     if (e.key === "Backspace" && index > 0 && !e.target.value) {
//       // Move to the previous input on Backspace if current input is empty
//       inputRefs.current[index - 1].focus();
//     }
//   };

//   const [callUpdate, setCallUpdate] = useState(false);
//   const verifyOtp = async () => {
//     if (otpValue.length == 6) {
//       await api
//         .validate_email_otp({
//           request_id: otp,
//           otp: tempOtpValue,
//           email: data.email,
//         })
//         .then((res) => {
//           console.log("res", res);
//           if (res.status) {
//             Swal.fire({
//               icon: "success",
//               title: res.msg,
//             });
//             // setCountStep(countStep + 1);
//             // setDisable(!nextDisable);
//             setVerified(true);
//             setVisibleDiv(false);
//             // setCallUpdate(true)
//             setMobileModalResend(false);
//             submitForm();
//           } else {
//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: res.msg,
//             });
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };

//   // useEffect(()=>{
//   //   submitForm()
//   // },[ callUpdate])

//   console.log("mobileModalResend :>> ", mobileModalResend, demoModal);
//   return (
//     <>
//       <Row style={{ position: "relative", zIndex: "9999" }}>
//         <Col xs={6}>
//           <ToastContainer
//             className="p-3"
//             position="top-end"
//             containerPosition="fixed"
//           >
//             <Toast
//               onClose={() => setShow(false)}
//               show={show}
//               className="custom-toast"
//               delay={3000}
//               autohide
//             >
//               <Toast.Header>
//                 <MediaGroup>
//                   <Media size="tiny">
//                     <Image src="/images/favicon.png" />
//                   </Media>
//                   <MediaText>
//                     <strong>Hilingo</strong>
//                   </MediaText>
//                 </MediaGroup>
//                 {/* <small>11 mins ago</small> */}
//               </Toast.Header>
//               <Toast.Body
//                 className="text-secondary "
//                 style={{
//                   height: "120px",
//                   backgroundColor: "#E8E7EC",
//                   boxShadow: " 0 4px 6px rgba(0, 0, 0, 0.1)",
//                   fontSize: "1.1em",
//                 }}
//               >
//                 Don`t Pick Light Color!!
//               </Toast.Body>
//             </Toast>
//           </ToastContainer>
//         </Col>
//       </Row>

//       <Layout title="Add Student" content="container">
//         <h1
//           className="d-flex align-items-center mb-5"
//           style={{ cursor: "pointer" }}
//           onClick={() => navigate(-1)}
//         >
//           <Icon
//             name="arrow-left-circle-fill"
//             className="text-primary h1 me-2"
//           ></Icon>{" "}
//           Back
//         </h1>
//         <Block>
//           {!student_profile && (
//             <Card className="h-100 my-3">
//               <div className="d-flex justify-content-end align-items-center bg-primary">
//                 <Image
//                   src="/images/myprofile.gif"
//                   className="profile-img"
//                 ></Image>
//               </div>
//               <Card.Body>
//                 <div className="d-flex flex-column align-items-start user-profile gap-3">
//                   <Media size="huge" shape="circle">
//                     {data1.inst_profile?.business_logo ? (
//                       <Image
//                         src={data1.inst_profile?.business_logo}
//                         staticImage
//                         thumbnail
//                         alt="user"
//                       />
//                     ) : (
//                       <Image
//                         src="/images/avatar/a.jpg"
//                         staticImage
//                         thumbnail
//                         alt="user"
//                       />
//                     )}
//                   </Media>
//                   <div className="mt-3 mt-md-0 ms-md-3 me-5 d-flex justify-content-between w-95">
//                     <div>
//                       <h3 className="title mb-1">
//                         {data1.inst_profile?.inst_contact_firstname +
//                           " " +
//                           data1.inst_profile?.inst_contact_lastname}
//                       </h3>
//                       <span className="small fw-bold">
//                         Expire Date:
//                         {dateUtils?.formatDateMonthString(data1?.plan_expiry)}
//                       </span>
//                       <br />
//                       <Button
//                         onClick={() =>
//                           navigate(
//                             isAdmin
//                               ? `/institute/change-password/${inst_id}`
//                               : `/institute/change-password/${userData?._id}`
//                           )
//                         }
//                       >
//                         Change Password
//                       </Button>
//                     </div>
//                     <div className="d-flex flex-column align-items-center">
//                       <h4>Select Time Zone</h4>

//                       <select
//                         className="form-select"
//                         value={data?.timeZone}
//                         onChange={(e) => {
//                           setData({ ...data, timeZone: e.target.value });
//                         }}
//                       >
//                         {getTimeZones().map((ele) => {
//                           return (
//                             <option>
//                               {ele} (UTC {getTimezoneOffset(ele)}:00)
//                             </option>
//                           );
//                         })}
//                       </select>
//                     </div>
//                     <div className="d-flex flex-column align-items-end">
//                       <h3 className="title mb-1">Credits - {data1?.credit}</h3>

//                       {userData?.login_type == "0" && (
//                         <>
//                           {topUpModal && (
//                             <input
//                               type="text"
//                               onChange={(e) => setTopUpAmnt(e.target.value)}
//                             />
//                           )}
//                           <Button
//                             size="sm"
//                             variant="primary"
//                             onClick={() =>
//                               topUpModal
//                                 ? add_topup_credit()
//                                 : setTopUpTrue(!topUpModal)
//                             }
//                           >
//                             {" "}
//                             {topUpModal ? "Submit" : "Add Top Up"}
//                           </Button>
//                         </>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//                 <Row className="g-gs mb-5">
//                   <Col md="2">
//                     <p className="text-light mb-0">Created on : </p>{" "}
//                     <p className="text-secondary">{desiredDateFormat}</p>
//                   </Col>
//                   <Col md="4">
//                     <p className="text-light mb-0">Domain : </p>{" "}
//                     <div className="d-flex gap-2 align-items-center">
//                       {/* <p className="text-middark"> */}
//                       {!isEdit ? (
//                         <span
//                           className="text-middark"
//                           style={{ wordBreak: "break-all" }}
//                         >
//                           {`https://${data1.domain_name}.hilingo.com`}{" "}

//                         </span>
//                       ) : (
//                         <Form.Control
//                           className={isEdit ? "" : "no-border"}
//                           name="domain_name"
//                           type="text"
//                           style={{ height: "30px" }}
//                           id="domain_name"
//                           value={data.domain_name}
//                           onChange={(e) => handleChange(e)}
//                           placeholder="Enter Domain Name"
//                         />
//                       )}

//                       <div
//                         style={{ cursor: "pointer" }}
//                         onClick={() =>
//                           toast({
//                             title: "Domain Copied",
//                             // description: "We've created your account for you.",
//                             status: "success",
//                             duration: 1000,
//                             isClosable: true,
//                           })
//                         }
//                       >
//                         <Icon
//                           name="copy"
//                           className="text-primary"
//                           onClick={() =>
//                             handleCopyClick(
//                               `https://${data1.domain_name}.hilingo.com`
//                             )
//                           }
//                         ></Icon>
//                       </div>
//                       {/* {copyText && <Alert className='mt-4' variant="danger">{copyText}</Alert>} */}

//                       {userData?.login_type == "0" && (
//                         <div
//                           className="del-btn"
//                           onClick={() => {
//                             editHandler();
//                             // setEditData({ ...edit_data, id: row._id,test_name:row?.test_name });
//                           }}
//                         >
//                           <Icon name="edit"></Icon>
//                         </div>
//                       )}
//                     </div>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Username : </p>{" "}
//                     <span
//                       className="text-secondary bg-hilight px-2 rounded py-1"
//                       style={{ fontSize: "12px" }}
//                     >
//                       {" "}
//                       {data1.email}
//                     </span>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Created by : </p>{" "}
//                     <p className="text-secondary">
//                       {data1.inst_profile?.inst_contact_firstname +
//                         " " +
//                         data1.inst_profile?.inst_contact_lastname}
//                     </p>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Location : </p>{" "}
//                     <Badge bg="hilight" className="text-secondary">
//                       {data1.inst_profile?.inst_business_city +
//                         " , " +
//                         data?.inst_profile?.inst_contact_source_country_student}
//                     </Badge>
//                   </Col>
//                   {userData?.login_type == "0" && (
//                     <>
//                       <Col md="3">
//                         <p className="text-light mb-0">Managed By </p>{" "}
//                         <div
//                           className="form-control-wrap ms-1 me-1"
//                           style={{ width: "208px" }}
//                         >
//                           <Form.Select
//                             removeItemButton
//                             value={data?.inst_profile?.manageBy}
//                             name="manageBy"
//                             onChange={(e) => handleChange(e, "inst_profile")}
//                           >
//                             <option value="">Select</option>
//                             {staffList?.length > 0 &&
//                               staffList.map((item) => {
//                                 return (
//                                   <>
//                                     <option value={item._id}>
//                                       {item.name}
//                                     </option>
//                                   </>
//                                 );
//                               })}
//                           </Form.Select>
//                         </div>
//                       </Col>
//                       <Col md="3"></Col>
//                       <Col md="3">
//                         <Button
//                           className="btn btn-success mt-4 px-10"
//                           onClick={submitForm}
//                         >
//                           Update
//                         </Button>
//                       </Col>
//                     </>
//                   )}
//                 </Row>
//               </Card.Body>
//             </Card>
//           )}
//           {student_profile && (
//             <Card className="h-100 my-3">
//               <Image src="/images/bg.svg"></Image>
//               <Card.Body>
//                 <div className="d-flex flex-column align-items-start user-profile gap-3">
//                   <Media size="huge" shape="circle">
//                     {userData.student_profile?.profile_pic ? (
//                       <Image
//                         src={userData.student_profile?.profile_pic}
//                         staticImage
//                         thumbnail
//                         alt="user"
//                       />
//                     ) : (
//                       <Image
//                         src="/images/avatar/a.jpg"
//                         staticImage
//                         thumbnail
//                         alt="user"
//                       />
//                     )}
//                   </Media>
//                   <div className="mt-3 mt-md-0 ms-md-3 me-5 d-flex justify-content-between w-95">
//                     <div>
//                       <h3 className="title mb-1">{userData?.name}</h3>
//                       <span className="small fw-bold">
//                         Expire Date: 17 Jun 2022
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <Row className="g-gs mb-5">
//                   <Col md="3">
//                     <p className="text-light mb-0">Created on : </p>{" "}
//                     <p className="text-secondary">{desiredDateFormat}</p>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Institute Name : </p>{" "}
//                     <p className="text-middark">
//                       <span> {userData?.stu_profile?.instituteName} </span>{" "}
//                     </p>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Username : </p>{" "}
//                     <Badge bg="hilight" className="text-secondary">
//                       {userData?.email}
//                     </Badge>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Created by : </p>{" "}
//                     <p className="text-secondary">
//                       {userData?.stu_profile?.createdBy}
//                     </p>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Teacher Name : </p>{" "}
//                     <p className="text-secondary">
//                       {userData?.stu_profile?.assignTeacher}
//                     </p>
//                   </Col>
//                   <Col md="3">
//                     <p className="text-light mb-0">Branch Name : </p>{" "}
//                     <Badge bg="hilight" className="text-secondary">
//                       {userData?.stu_profile?.branch_name}
//                     </Badge>
//                   </Col>
//                 </Row>
//               </Card.Body>
//             </Card>
//           )}

//           {!student_profile && (
//             <Card className="h-100 my-3">
//               <Card.Body>
//                 <Tab.Container
//                   id="custom-tabs-example"
//                   defaultActiveKey="custom-home"
//                 >
//                   <Nav
//                     variant="tabs"
//                     className="nav-tabs-s1 mb-3 justify-content-around"
//                     activeKey={activeKey}
//                     onSelect={handleSelect}
//                   >
//                     <Nav.Item className="px-1">
//                       <Nav.Link eventKey="custom-home">My Profile</Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item className="px-1">
//                       <Nav.Link eventKey="custom-profile">
//                         Customization
//                       </Nav.Link>
//                     </Nav.Item>
//                     {/* <Nav.Item className="px-1">
//                   <Nav.Link eventKey="custom-bil">Billing Information</Nav.Link>
//                 </Nav.Item> */}
//                     {(userData?.login_type == "0" ||
//                       institutePlan?.institute_role_based_access) && (
//                       <Nav.Item className="px-1">
//                         <Nav.Link eventKey="custom-team">Team</Nav.Link>
//                       </Nav.Item>
//                     )}
//                     {(userData?.login_type == "0" ||
//                       institutePlan?.multi_branch_management) && (
//                       <Nav.Item className="px-1">
//                         <Nav.Link eventKey="custom-branch">Branches</Nav.Link>
//                       </Nav.Item>
//                     )}
//                     {userData?.login_type == "0" && (
//                       <Nav.Item className="px-1">
//                         <Nav.Link eventKey="plan-price">
//                           Plan and Price
//                         </Nav.Link>
//                       </Nav.Item>
//                     )}
//                   </Nav>
//                   <Tab.Content>
//                     <Tab.Pane eventKey="custom-home">
//                       <Row className="g-gs">
//                         <Col md="4" className="p-3">
//                           <h5>Personal Profile</h5>
//                           <p className="text-middark">
//                             Change Name, Email, & Mobile Number of your profile.{" "}
//                           </p>
//                         </Col>
//                         <Col md="8" className="p-3">
//                           <Row className="g-gs">
//                             <Col lg="12">
//                               <Form.Group className="form-group">
//                                 <Form.Label>Name</Form.Label>
//                                 <div className="form-control-wrap">
//                                   <div className="form-control-icon end">
//                                     <Button
//                                       variant="white"
//                                       className="border-0"
//                                     >
//                                       {/* <Icon name="edit-alt"></Icon> */}
//                                     </Button>
//                                   </div>
//                                   <Form.Control
//                                     type="text"
//                                     id="username"
//                                     name="username"
//                                     onChange={(e) => handleChange(e)}
//                                     // value={data?.inst_profile?.inst_contact_firstname+" "+data1?.inst_profile?.inst_contact_lastname}
//                                     value={
//                                       data?.inst_profile?.inst_contact_firstname
//                                     }
//                                     placeholder="User Name"
//                                   />
//                                 </div>
//                               </Form.Group>
//                             </Col>
//                             <Col lg="12" className="mt-1">
//                               <Form.Group className="form-group">
//                                 <Form.Label>Email Id</Form.Label>
//                                 <div className="form-control-wrap">
//                                   {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                   <InputGroup>
//                                     <Form.Control
//                                       disabled
//                                       type="email"
//                                       // id="emailid"
//                                       name="email"
//                                       onChange={(e) => handleChange(e)}
//                                       value={data?.email}
//                                       placeholder="Email Id"
//                                     />
//                                     <InputGroup.Text id="basic-addon2">
//                                       <Icon
//                                         style={{
//                                           color: "#1682FB",
//                                           cursor: "pointer",
//                                         }}
//                                         onClick={() => setDemoModalEmail(true)}
//                                         name="edit"
//                                       ></Icon>
//                                     </InputGroup.Text>
//                                   </InputGroup>
//                                 </div>
//                               </Form.Group>
//                             </Col>
//                             <Col lg="12" className="mt-1">
//                               <Form.Group className="form-group">
//                                 <Form.Label>Mobile No.</Form.Label>
//                                 <div className="form-control-wrap">
//                                   <InputGroup>
//                                     <Form.Control
//                                       disabled
//                                       type="number"
//                                       name="mobile"
//                                       value={
//                                         data?.mobile?.length < 11
//                                           ? data?.mobile
//                                           : ""
//                                       }
//                                       onChange={(e) => handleChange(e)}
//                                       id="mobile"
//                                       placeholder="Mobile No."
//                                     />
//                                     <InputGroup.Text id="basic-addon2">
//                                       <Icon
//                                         style={{
//                                           color: "#1682FB",
//                                           cursor: "pointer",
//                                         }}
//                                         onClick={() => setDemoModal(true)}
//                                         name="edit"
//                                       ></Icon>
//                                     </InputGroup.Text>
//                                   </InputGroup>
//                                   <Modal
//                                     show={demoModal}
//                                     onHide={demoModalClose}
//                                   >
//                                     <Modal.Header closeButton>
//                                       <Modal.Title>
//                                         Change Mobile Number
//                                       </Modal.Title>
//                                     </Modal.Header>
//                                     <Modal.Body className="d-flex flex-column justify-content-center">
//                                       <p className="text-center text-middark">
//                                         Click on GET OTP button to receive on
//                                         OTP on your registered email address.{" "}
//                                       </p>
//                                       <Form.Control
//                                         type="email"
//                                         id="emailis"
//                                         value={userData?.email}
//                                         placeholder="Email Id"
//                                       />
//                                       <Button
//                                         disabled={loading}
//                                         variant="primary"
//                                         className="mx-auto my-3"
//                                         // onClick={otpModalShow}
//                                         onClick={() => {
//                                           generateRandomNumber("mobile");
//                                         }}
//                                       >
//                                         Get OTP
//                                       </Button>
//                                     </Modal.Body>
//                                   </Modal>

//                                   <Modal
//                                     show={demoModalEmail}
//                                     onHide={demoModalCloseEmail}
//                                   >
//                                     <Modal.Header closeButton>
//                                       <Modal.Title>
//                                         Change Email Address
//                                       </Modal.Title>
//                                     </Modal.Header>
//                                     <Modal.Body className="d-flex flex-column justify-content-center">
//                                       <p className="text-center text-middark">
//                                         Click on GET OTP button to receive an
//                                         OTP on below email address.{" "}
//                                       </p>
//                                       <Form.Control
//                                         type="email"
//                                         id="emailis"
//                                         name="email"
//                                         value={data?.email}
//                                         placeholder="Email Id"
//                                         onChange={(e) => handleChange(e)}
//                                       />
//                                       <Button
//                                         disabled={loading}
//                                         variant="primary"
//                                         className="mx-auto my-3"
//                                         // onClick={otpModalShow}
//                                         onClick={() => {
//                                           generateRandomNumber("email");
//                                         }}
//                                       >
//                                         Get OTP
//                                       </Button>
//                                     </Modal.Body>
//                                   </Modal>
//                                 </div>
//                               </Form.Group>

//                               {/* <FlagAndMobileOtp data={data} errors={errors} handleChange={handleChange} userData={userData} setCountryNumber={setCountryNumber} countryNumber={countryNumber}/> */}
//                             </Col>
//                           </Row>
//                         </Col>
//                       </Row>
//                       <Modal
//                         show={mobileModal || mobileModalResend}
//                         onHide={mobileModalClose}
//                       >
//                         <Modal.Header closeButton>
//                           <Modal.Title>Change Mobile Number</Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body className="d-flex flex-column justify-content-center">
//                           <p className="text-center text-middark">
//                             We will notify all the new software update/changes
//                             on updated mobile number.
//                           </p>
//                           <Form.Control
//                             type="tel"
//                             maxLength={10}
//                             id="phone"
//                             name="mobile"
//                             placeholder="Mobile No."
//                             // value={data.phone}
//                             value={
//                               data?.mobile?.length < 11 ? data?.mobile : ""
//                             }
//                             onChange={(e) => handleChange(e)}
//                             onInput={handlePhoneInput}
//                             isInvalid={!!mobileOtpModalError}
//                           />
//                           <Button
//                             variant="primary"
//                             className="mx-auto my-3"
//                             // onClick={submitForm}
//                             onClick={() => {
//                               if (data?.mobile?.length == 10) {
//                                 setOtpModal(true);
//                               } else {
//                                 setMobileOtpModalError("must be 10 digits");
//                               }
//                             }}
//                           >
//                             Update
//                           </Button>
//                         </Modal.Body>
//                       </Modal>

//                       <Modal show={otpModal} onHide={otpModalClose}>
//                         <Modal.Header closeButton>
//                           {/* <Modal.Title>Change Email Address</Modal.Title> */}
//                           <Modal.Title>
//                             {otpModalText == "mobile"
//                               ? "Change Mobile Number"
//                               : "Change Email Address"}
//                           </Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body className="d-flex flex-column justify-content-center">
//                           <p className="text-center text-middark">
//                             {otpModalText == "mobile"
//                               ? "changing mobile number requires you to enter OTP sent over to your registerd email address."
//                               : "changing email address requires you to enter OTP"}
//                           </p>
//                           <div className="bg-hisuccess rounded p-3 text-white my-3">
//                             OTP sent successfully.
//                           </div>
//                           {/* <div className="d-flex gap-3 my-3">
//                             <Form.Control type="number" id="otp1" />
//                             <Form.Control type="number" id="otp2" />
//                             <Form.Control type="number" id="otp3" />
//                             <Form.Control type="number" id="otp4" />
//                             <Form.Control type="number" id="otp5" />
//                             <Form.Control type="number" id="otp6" />
//                           </div> */}
//                           <div className="d-flex gap-3 my-3">
//                             {[...Array(6)].map((_, index) => (
//                               <Form.Control
//                                 key={index}
//                                 type="text"
//                                 maxLength={1}
//                                 ref={(el) => (inputRefs.current[index] = el)}
//                                 onChange={(e) => handleChangeotp(e, index)}
//                                 onKeyDown={(e) => handleKeyDown(e, index)}
//                                 inputMode="numeric"
//                                 onPaste={index === 0 ? handlePaste : undefined}  
//                               />
//                             ))}
//                           </div>
//                           <p className="text-center text-secondary my-3">
//                             {/* Did not receive? <a href="">Resend</a> */}
//                             Did not receive?{" "}
//                             <span
//                               className="text-info "
//                               style={{ cursor: "pointer" }}
//                               onClick={resendOtpHandler}
//                             >
//                               Resend
//                             </span>
//                           </p>
//                           <Button
//                             variant="primary"
//                             className="mx-auto my-3"
//                             onClick={verifyOtp}
//                           >
//                             submit
//                           </Button>
//                         </Modal.Body>
//                       </Modal>
//                       <hr></hr>
//                       <Row className="g-gs">
//                         <Col md="4" className="p-3">
//                           <h5>Address</h5>
//                           <p className="text-middark">
//                             Edit your institute address.{" "}
//                           </p>
//                         </Col>
//                         <Col md="8" className="p-3">
//                           <Row className="g-gs">
//                             <Col lg="12" className="mt-1">
//                               <Form.Group className="form-group">
//                                 <Form.Label>Address</Form.Label>
//                                 <div className="form-control-wrap">
//                                   {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                   <Form.Control
//                                     type="text"
//                                     value={
//                                       data?.inst_profile?.inst_business_address
//                                     }
//                                     name="inst_business_address"
//                                     onChange={(e) =>
//                                       handleChange(e, "inst_profile")
//                                     }
//                                     id="address"
//                                     placeholder="Address"
//                                   />
//                                 </div>
//                               </Form.Group>
//                             </Col>
//                             <Col lg="6" className="mt-1">
//                               <Form.Group className="form-group">
//                                 <Form.Label>Country</Form.Label>
//                                 <div className="form-control-wrap">
//                                   <Form.Select
//                                     name="inst_business_country"
//                                     value={country?.isoCode || ""}
//                                     onChange={handleCountryChange}
//                                   >
//                                     {countryData.map((country) => (
//                                       <option
//                                         key={country.isoCode}
//                                         value={country.isoCode}
//                                       >
//                                         {country.name}
//                                       </option>
//                                     ))}
//                                   </Form.Select>
//                                 </div>
//                               </Form.Group>
//                             </Col>

//                             <Col lg="6" className="mt-1">
//                               <Form.Group className="form-group">
//                                 {data?.inst_profile?.inst_business_state && (
//                                   <>
//                                     <Form.Label>State</Form.Label>
//                                     <div className="form-control-wrap">
//                                       <Form.Select
//                                         name="inst_business_state"
//                                         value={stateSingle?.isoCode || ""}
//                                         onChange={handleStateChange}
//                                       >
//                                         {stateData.map((state) => (
//                                           <option
//                                             key={state.isoCode}
//                                             value={state.isoCode}
//                                           >
//                                             {state.name}
//                                           </option>
//                                         ))}
//                                       </Form.Select>
//                                     </div>
//                                   </>
//                                 )}
//                               </Form.Group>
//                             </Col>

//                             <Col lg="6" className="mt-1">
//                               <Form.Group className="form-group">
//                                 <Form.Label>City</Form.Label>
//                                 <div className="form-control-wrap">
//                                   {/* <div className="form-control-icon end">
//                                   <Button variant="white" className="border-0">
//                                     <Icon name="edit-alt"></Icon>
//                                   </Button>
//                                 </div> */}
//                                   {/* <Form.Control
//                                   type="text"
//                                   id="city"
//                                   onChange={(e) =>
//                                     handleChange(e, "inst_profile")
//                                   }
//                                   name="inst_business_city"
//                                   value={data?.inst_profile?.inst_business_city}
//                                   placeholder="City"
//                                 /> */}

//                                   <Form.Select
//                                     name="inst_business_city"
//                                     value={citySingle?.name || ""}
//                                     onChange={handleCityChange}
//                                   >
//                                     {cityData.map((city) => (
//                                       <option key={city.name} value={city.name}>
//                                         {city.name}
//                                       </option>
//                                     ))}
//                                   </Form.Select>
//                                 </div>
//                               </Form.Group>
//                             </Col>

//                             {/* <CountryState2/> */}
//                             <Col lg="12">
//                               <Form.Group className="form-group">
//                                 <h4>Company details</h4>
//                                 <span className="text-middark">
//                                   This detail is for busssiness in <b>India</b>{" "}
//                                   Only.
//                                 </span>
//                                 <div className="mt-4">
//                                   {" "}
//                                   <div className="form-check">
//                                     <input
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="flexRadioDefault"
//                                       id="flexRadioDefault1"
//                                       checked={
//                                         data?.inst_gst_number_active
//                                           ? false
//                                           : true
//                                       }
//                                       onChange={(e) => {
//                                         setData((prevData) => ({
//                                           ...prevData,
//                                           ["inst_gst_number_active"]: false,
//                                           ["inst_profile"]: {
//                                             ...data.inst_profile,
//                                             ["inst_company_name"]: "",
//                                             ["inst_gst_number"]: "",
//                                           },
//                                         }));
//                                       }}
//                                     />
//                                     <label
//                                       className="form-check-label"
//                                       for="flexRadioDefault1"
//                                     >
//                                       I don't have <b>GSTIN</b> Number
//                                     </label>
//                                   </div>
//                                   <div className="form-check">
//                                     <input
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="flexRadioDefault"
//                                       id="flexRadioDefault2"
//                                       checked={
//                                         data?.inst_gst_number_active
//                                           ? true
//                                           : false
//                                       }
//                                       onChange={(e) => {
//                                         setData((prevData) => ({
//                                           ...prevData,
//                                           ["inst_gst_number_active"]: true,
//                                         }));
//                                       }}
//                                     />
//                                     <label
//                                       className="form-check-label"
//                                       for="flexRadioDefault2"
//                                     >
//                                       I have <b>GSTIN</b> Number
//                                     </label>
//                                   </div>
//                                 </div>
//                               </Form.Group>
//                             </Col>
//                             {data?.inst_gst_number_active && (
//                               <>
//                                 <Col lg="6">
//                                   <Form.Group className="form-group">
//                                     <Form.Label>Business Name</Form.Label>
//                                     <div className="form-control-wrap">
//                                       <Form.Control
//                                         required
//                                         onChange={(e) => {
//                                           handleChange(e, "inst_profile");
//                                         }}
//                                         name="inst_company_name"
//                                         value={
//                                           data?.inst_profile?.inst_company_name
//                                         }
//                                         placeholder="Business Name"
//                                       />
//                                     </div>
//                                   </Form.Group>
//                                 </Col>
//                                 <Col lg="6">
//                                   <Form.Group className="form-group">
//                                     <Form.Label>GST Number</Form.Label>
//                                     <div className="form-control-wrap">
//                                       <Form.Control
//                                         type="text"
//                                         id="city"
//                                         onChange={(e) => {
//                                           handleChange(e, "inst_profile");
//                                         }}
//                                         name="inst_gst_number"
//                                         value={
//                                           data?.inst_profile?.inst_gst_number
//                                             ?.length < 16
//                                             ? data?.inst_profile
//                                                 ?.inst_gst_number
//                                             : ""
//                                         }
//                                         placeholder="GST Number"
//                                       />
//                                     </div>
//                                   </Form.Group>
//                                 </Col>
//                               </>
//                             )}
//                           </Row>
//                         </Col>
//                       </Row>
//                     </Tab.Pane>
//                     <Tab.Pane eventKey="custom-profile">
//                       <div className="d-flex mb-3 align-items-center">
//                         <div className="w-30">
//                           <div
//                             className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                             style={{ width: "120px", height: "120px" }}
//                           >
//                             {data?.inst_profile?.business_logo && (
//                               <Image
//                                 src={data?.inst_profile?.business_logo}
//                               ></Image>
//                             )}
//                             {data?.logo?.length > 0 && (
//                               <Image src={data?.logo[0].preview}></Image>
//                             )}
//                           </div>
//                         </div>
//                         <div className="d-flex flex-column align-items-start w-30">
//                           <h5>Company Logo</h5>
//                           <p className="mb-0 text-middark">
//                             Upload your company logo to display in your panel.
//                           </p>
//                           {/* <p className="mt-0">click here to resize image</p> */}
//                         </div>
//                         <div className="d-flex w-30">
//                           <div className="d-flex justify-content-center form-control-wrap">
//                             <FileUploadNew
//                               filetype="image"
//                               iconName="upload"
//                               onChange={handleFileUpload}
//                               name="logo" // Provide the appropriate name
//                               noImage={true}
//                               aspectRatio="Recommended Aspect ratio 1:1"
//                               extensions={"Image must be JPG/JPEG or PNG"}
//                             />
//                           </div>
//                         </div>
//                         <div className="d-flex justify-content-center w-10">
//                           {data?.inst_profile?.business_logo != "" ||
//                           data?.logo != "" ? (
//                             <Button
//                               variant="outline-primary"
//                               name="business_logo"
//                               onClick={(e) => handleChange(e, "inst_profile")}
//                             >
//                               Remove
//                             </Button>
//                           ) : (
//                             <Button
//                               variant="outline-primary"
//                               name="business_logo"
//                               // onClick={(e) => handleChange(e, "inst_profile")}
//                             >
//                               Removed
//                             </Button>
//                           )}
//                         </div>
//                       </div>
//                       <hr />
//                       <div className="d-flex mb-3 align-items-center">
//                         <div className="w-30">
//                           <div
//                             className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                             style={{ width: "120px", height: "120px" }}
//                           >
//                             {data?.inst_profile?.inst_favicon_logo?.length >
//                               0 && (
//                               <Image
//                                 src={data?.inst_profile?.inst_favicon_logo}
//                               ></Image>
//                             )}
//                             {data?.inst_favicon_logo?.length > 0 && (
//                               <Image
//                                 src={data?.inst_favicon_logo[0].preview}
//                               ></Image>
//                             )}
//                           </div>
//                         </div>
//                         <div class="d-flex flex-column align-items-start w-30">
//                           <h5>Company Favicon Logo</h5>
//                           <p className="mb-0 text-middark">
//                             Upload your company favicon logo to display in your
//                             tab.
//                           </p>
//                         </div>
//                         <div className="d-flex w-30">
//                           <div className="d-flex justify-content-center form-control-wrap">
//                             <FileUploadNew
//                               filetype="image"
//                               iconName="upload"
//                               onChange={handleFileUploadFavicon}
//                               name="inst_favicon_logo"
//                               noImage={true}
//                               aspectRatio="Recommended Aspect ratio 1:1"
//                               extensions={"Image must be JPG/JPEG or PNG"}
//                             />
//                           </div>
//                         </div>
//                         <div className="d-flex justify-content-center w-10">
//                           {data?.inst_profile?.inst_favicon_logo != "" ||
//                           data?.inst_favicon_logo != "" ? (
//                             <Button
//                               variant="outline-primary"
//                               name="inst_favicon_logo"
//                               onClick={(e) => handleChange(e, "inst_profile")}
//                             >
//                               Remove
//                             </Button>
//                           ) : (
//                             <Button
//                               variant="outline-primary"
//                               name="inst_favicon_logo"
//                             >
//                               Removed
//                             </Button>
//                           )}
//                         </div>
//                       </div>
//                       <hr />
//                       {/* tochan
//                       buisness certificate */}
//                       <div className="d-flex mb-3 align-items-center">
//                         <div className="w-30">
//                           <div
//                             className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                             style={{ width: "120px", height: "120px" }}
//                           >
//                             {data?.inst_profile?.business_certificate?.length >
//                               0 && (
//                               <Image
//                                 src={data?.inst_profile?.business_certificate}
//                               ></Image>
//                             )}
//                             {data?.business_certificate?.length > 0 && (
//                               <Image
//                                 src={data?.business_certificate[0].preview}
//                               ></Image>
//                             )}
//                           </div>
//                         </div>
//                         <div className="d-flex flex-column align-items-start w-30">
//                           <h5>business_certificate</h5>
//                           <p className="mb-0 text-middark">
//                             Upload your business_certificate
//                           </p>
//                         </div>
//                         <div className="d-flex w-30">
//                           <div className="d-flex justify-content-center form-control-wrap">
//                             <FileUploadNew
//                               filetype="image"
//                               iconName="upload"
//                               onChange={handleBusinessCertificateUploadFavicon}
//                               name="business_certificate"
//                               noImage={true}
//                               aspectRatio="Recommended Aspect ratio 1:1"
//                               extensions={"Image must be JPG/JPEG or PNG"}
//                             />
//                           </div>
//                         </div>
//                         <div className="d-flex justify-content-center w-10">
//                           {data?.inst_profile?.business_certificate != "" ||
//                           data?.business_certificate != "" ? (
//                             <Button
//                               variant="outline-primary"
//                               name="business_certificate"
//                               onClick={(e) => handleChange(e, "inst_profile")}
//                             >
//                               Remove
//                             </Button>
//                           ) : (
//                             <Button
//                               variant="outline-primary"
//                               name="business_certificate"
//                             >
//                               Removed
//                             </Button>
//                           )}
//                         </div>
//                       </div>
//                       <hr />

//                       <div className="d-flex mb-3 align-items-center">
//                         <div className="d-flex w-30 flex-wrap">
//                           {data.inst_profile?.banner_images?.length > 0 &&
//                             data.inst_profile?.banner_images.map((item) => {
//                               return (
//                                 <>
//                                   <div className="w-50 mb-2">
//                                     <div
//                                       className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                       style={{
//                                         width: "120px",
//                                         height: "120px",
//                                       }}
//                                     >
//                                       <CloseButton
//                                         id="deleteFromFiles"
//                                         className="close-btn"
//                                         value={item.url}
//                                         name="deletebanner"
//                                         onClick={(e) =>
//                                           handleChange(
//                                             e,
//                                             "inst_profile",
//                                             null,
//                                             item.url
//                                           )
//                                         }
//                                       />
//                                       <Image src={item?.url}></Image>
//                                     </div>
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           {data?.banner_images?.length > 0 &&
//                             data?.banner_images.map((item, index) => {
//                               return (
//                                 <>
//                                   <div className="w-50 mb-2">
//                                     <div
//                                       className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                       style={{
//                                         width: "120px",
//                                         height: "120px",
//                                       }}
//                                     >
//                                       <CloseButton
//                                         id="deleteFromFiles"
//                                         className="close-btn"
//                                         value={item.preview}
//                                         name="deletebanner"
//                                         onClick={(e) => {
//                                           let remove =
//                                             data?.banner_images.filter(
//                                               (item, i) => i !== index
//                                             );
//                                           setData({
//                                             ...data,
//                                             banner_images: remove,
//                                           });
//                                         }}
//                                       />
//                                       <Image src={item?.preview}></Image>
//                                     </div>
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           {data?.file?.length > 0 && (
//                             <div className="w-50 mb-2">
//                               <div
//                                 className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                 style={{ width: "120px", height: "120px" }}
//                               >
//                                 <CloseButton
//                                   className="close-btn"
//                                   id="deleteFromFiles"
//                                   value={data?.file[0].preview}
//                                   name="deletebanner"
//                                   onClick={(e) =>
//                                     handleChange(
//                                       e,
//                                       "inst_profile",
//                                       null,
//                                       data?.file[0].preview
//                                     )
//                                   }
//                                 />
//                                 <Image src={data?.file[0].preview}></Image>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                         <div className="d-flex flex-column align-items-start w-30">
//                           <h5>Banner</h5>
//                           <p className="mb-0 text-middark">
//                             Upload your login screen banner for branding
//                           </p>
//                           <p className="mb-0 text-middark">
//                             You may upload a maximum of 4 banners
//                           </p>
//                           {/* <a href="" className="mt-0">click here to resize  image</a> */}
//                         </div>
//                         {data.inst_profile?.banner_images?.length +
//                           (data?.banner_images
//                             ? data?.banner_images?.length
//                             : 0) <
//                           4 && (
//                           <div className="d-flex-column">
//                             <div className="d-flex">
//                               <div className="d-flex justify-content-center form-control-wrap">
//                                 <FileUploadNew
//                                   noImage={true}
//                                   filetype="image"
//                                   onChange={(updatedFiles) => {
//                                     if (
//                                       data.inst_profile?.banner_images?.length +
//                                         (data?.banner_images
//                                           ? data?.banner_images?.length
//                                           : 0) >
//                                       5
//                                     ) {
//                                       Swal.fire({
//                                         icon: "error",
//                                         title: "Oops...",
//                                         text: "Max limit Four",
//                                       });
//                                     } else {
//                                       // setData({
//                                       //   ...data,
//                                       //   banner_images: updatedFiles,
//                                       // });
//                                       updateBannerImages(updatedFiles);
//                                     }
//                                   }}
//                                   name
//                                   iconName="upload"
//                                   // aspectRatioCalculate={1}
//                                   aspectRatio="Recommended Aspect ratio 1:1"
//                                   extensions={"Image must be JPG/JPEG or PNG"}
//                                 />
//                               </div>
//                             </div>
//                             <Button
//                               onClick={() =>
//                                 window.open(
//                                   "https://www.canva.com/instagram-posts/templates/",
//                                   "_blank"
//                                 )
//                               }
//                               variant="primary"
//                               size="sm"
//                               className="mt-2  "
//                               style={{
//                                 marginLeft: "123px",
//                                 backgroundColor:
//                                   domainData?.inst_profile?.color_code,
//                               }}
//                             >
//                               {" "}
//                               Design Free banners{" "}
//                             </Button>
//                           </div>
//                         )}
//                         <div className="w-10"></div>
//                       </div>
//                       <hr />
//                       <div className="d-flex mb-3 align-items-center">
//                         <div className="d-flex w-30 flex-wrap">
//                           {data.inst_profile?.internal_banner_images?.length >
//                             0 &&
//                             data.inst_profile?.internal_banner_images.map(
//                               (item) => {
//                                 return (
//                                   <>
//                                     <div className="w-50 mb-2">
//                                       <div
//                                         className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                         style={{
//                                           width: "120px",
//                                           height: "120px",
//                                         }}
//                                       >
//                                         <CloseButton
//                                           id="deleteFromFiles"
//                                           className="close-btn"
//                                           value={item.url}
//                                           name="deleteInternalbanner"
//                                           onClick={(e) =>
//                                             handleChange(
//                                               e,
//                                               "inst_profile",
//                                               null,
//                                               item.url
//                                             )
//                                           }
//                                         />
//                                         <Image src={item?.url}></Image>
//                                       </div>
//                                     </div>
//                                   </>
//                                 );
//                               }
//                             )}
//                           {data?.internal_banner_images?.length > 0 &&
//                             data?.internal_banner_images.map((item, index) => {
//                               return (
//                                 <>
//                                   <div className="w-50 mb-2">
//                                     <div
//                                       className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                       style={{
//                                         width: "120px",
//                                         height: "120px",
//                                       }}
//                                     >
//                                       <CloseButton
//                                         id="deleteFromFiles"
//                                         className="close-btn"
//                                         value={item.preview}
//                                         name="deletebanner"
//                                         onClick={(e) => {
//                                           let remove =
//                                             data?.internal_banner_images.filter(
//                                               (item, i) => i !== index
//                                             );
//                                           setData({
//                                             ...data,
//                                             internal_banner_images: remove,
//                                           });
//                                         }}
//                                       />
//                                       <Image src={item?.preview}></Image>
//                                     </div>
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           {data?.file2?.length > 0 && (
//                             <div className="w-50 mb-2">
//                               <div
//                                 className="p-2 border d-flex align-items-center justify-content-center upload-img"
//                                 style={{ width: "120px", height: "120px" }}
//                               >
//                                 <CloseButton
//                                   className="close-btn"
//                                   id="deleteFromFiles"
//                                   value={data?.file2[0].preview}
//                                   name="deleteInternalbanner"
//                                   onClick={(e) =>
//                                     handleChange(
//                                       e,
//                                       "inst_profile",
//                                       null,
//                                       data?.file2[0].preview
//                                     )
//                                   }
//                                 />
//                                 <Image src={data?.file2[0].preview}></Image>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                         <div className="d-flex flex-column align-items-start w-30">
//                           <h5>Student Dashboard Banner</h5>
//                           <p className="mb-0 text-middark">
//                             Upload your student dashboard banner for branding
//                           </p>
//                           <p className="mb-0 text-middark">
//                             You may upload a maximum of 4 bannersrs
//                           </p>
//                           {/* <a href="" className="mt-0">click here to resize  image</a> */}
//                         </div>
//                         {data.inst_profile?.internal_banner_images?.length +
//                           (data?.internal_banner_images
//                             ? data?.internal_banner_images?.length
//                             : 0) <
//                           4 && (
//                           <div className="d-flex-column">
//                             <div className="d-flex ">
//                               <div className="d-flex justify-content-center form-control-wrap">
//                                 <FileUploadNew
//                                   noImage={true}
//                                   filetype="image"
//                                   onChange={(updatedFiles) => {
//                                     if (
//                                       data.inst_profile?.internal_banner_images
//                                         ?.length +
//                                         (data?.internal_banner_images
//                                           ? data?.internal_banner_images?.length
//                                           : 0) >
//                                       5
//                                     ) {
//                                       Swal.fire({
//                                         icon: "error",
//                                         title: "Oops...",
//                                         text: "Max limit Four",
//                                       });
//                                     } else {
//                                       // setData({
//                                       //   ...data,
//                                       //   banner_images: updatedFiles,
//                                       // });
//                                       updateBannerImages(
//                                         updatedFiles,
//                                         "internal_banner_images"
//                                       );
//                                     }
//                                   }}
//                                   name
//                                   iconName="upload"
//                                   aspectRatioCalculate={4}
//                                   aspectRatio="Recommended Aspect ratio 4:1"
//                                   extensions={"Image must be JPG/JPEG or PNG"}
//                                 />
//                               </div>
//                             </div>

//                             <Button
//                               onClick={() =>
//                                 window.open(
//                                   "https://www.canva.com/linkedin-banners/templates/",
//                                   "_blank"
//                                 )
//                               }
//                               variant="primary"
//                               size="sm"
//                               className="mt-2  "
//                               style={{
//                                 marginLeft: "123px",
//                                 backgroundColor:
//                                   domainData?.inst_profile?.color_code,
//                               }}
//                             >
//                               {" "}
//                               Design Free banners{" "}
//                             </Button>
//                           </div>
//                         )}
//                         <div className="w-10"></div>
//                       </div>

//                       <hr />

//                       {/* <div className="d-flex flex-column flex-md-row gap-5 align-items-center py-4">
//                     <div>
//                     <div className="d-flex- flex-column align-items-center justify-content-center">
//                       <h5>Brand Color</h5>
//                       <p className="mb-0 text-middark">
//                         Choose your brand color for panel.
//                       </p>
//                       <a href="" className="mt-0">
//                         <Icon name="video-fill"></Icon> <span>Watch Now</span>
//                       </a>
//                     </div>
//                     <Form.Group className="form-group">
//                       <div className="form-control-wrap">

//                       <ColorPicker setData={setData} data={data}/>
                    
//                       </div>
//                     </Form.Group>

               
//                   </div>
//                   <div className="d-flex text-secondary">
//                     <div>
//                     <p>Enqruiry Form OTP Feature</p>
//                     <p> This feature allows you to verify mobile numbers using an OTP in the enquiry Form.</p>
                 

//                  <p>Note : This is a paid feature. you can charge ₹ 2 per OTP message, and the charge will be reflected in the credit History</p>
//                  </div>
//                  <div>

//               <div className="d-flex gap-2">
//               <div>{data?.enquiry_otp_deduction ?"ON":"OFF"} </div> <div>    <Form.Check type="switch" id="flexSwitchChecked"
//                   name='enquiry_otp_deduction'
//                   onChange={(e) => handleChange(e, "check")}
//                   checked={data?.enquiry_otp_deduction}

//                 />
//                 </div>
//               </div>
          
//                  </div>

//                   </div>

//                   </div> */}

//                       <div className="d-flex flex-column flex-md-row gap-2 align-items-center py-4">
//                         <div class="w-40 d-flex gap-4 align-items-center">
//                           <div className="d-flex- flex-column align-items-center justify-content-center">
//                             <h5>Brand Color</h5>
//                             <p className="mb-0 text-middark">
//                               Choose your brand color for panel.
//                             </p>
//                             <a href="" className="mt-0">
//                               <Icon name="video-fill"></Icon>{" "}
//                               <span>Watch Now</span>
//                             </a>
//                           </div>
//                           <Form.Group className="form-group">
//                             <div className="form-control-wrap">
//                               <ColorPicker
//                                 setData={setData}
//                                 data={data}
//                                 setColorPickError={setColorPickError}
//                                 colorPickError={colorPickError}
//                               />

//                               {/* <Form.Control
//                           type="color"
//                           value={data?.inst_profile?.color_code}
//                           name="color_code"
//                           onChange={(e) => handleChange(e, "inst_profile")}
//                         /> */}
//                             </div>
//                           </Form.Group>
//                         </div>
//                         <div className="d-flex text-secondary w-60 align-items-center gap-3">
//                           <div class="w-80">
//                             <p>Enquiry Form OTP Feature</p>
//                             <p>
//                               {" "}
//                               This feature allows you to verify mobile numbers
//                               using an OTP in the enquiry Form.
//                             </p>
//                             <p>
//                               Note : This is a paid feature. you can charge ₹ 2
//                               per OTP message, and the charge will be reflected
//                               in the credit History
//                             </p>
//                           </div>
//                           <div class="w-20">
//                             <div className="d-flex gap-2">
//                               <div>
//                                 {data?.enquiry_otp_deduction ? "ON" : "OFF"}{" "}
//                               </div>{" "}
//                               <div>
//                                 {" "}
//                                 <Form.Check
//                                   type="switch"
//                                   id="flexSwitchChecked"
//                                   name="enquiry_otp_deduction"
//                                   onChange={(e) => handleChange(e, "check")}
//                                   checked={data?.enquiry_otp_deduction}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       <hr></hr>
//                       <Row className="g-gs">
//                         <Col md="12">
//                           <h5>Website</h5>
//                         </Col>
//                         {admin_profile && (
//                           <Col md="6">
//                             <Form.Group className="form-group">
//                               <Form.Label htmlFor="exampleFormControlInputText1">
//                                 Edit your Domain Name.
//                               </Form.Label>
//                               <div className="form-control-wrap">
//                                 {admin_profile && (
//                                   <Form.Control
//                                     type="text"
//                                     id="username"
//                                     value={data?.domain_name}
//                                     name="domain_name"
//                                     onChange={(e) => handleChange(e)}
//                                     placeholder="website link"
//                                   />
//                                 )}
//                               </div>
//                             </Form.Group>
//                           </Col>
//                         )}
//                         <Col md="6">
//                           <Form.Group className="form-group">
//                             <Form.Label htmlFor="exampleFormControlInputText1">
//                               Instagram Link
//                             </Form.Label>
//                             <div className="form-control-wrap">
//                               <Form.Control
//                                 type="text"
//                                 id="username"
//                                 onChange={(e) =>
//                                   handleChange(e, "inst_profile")
//                                 }
//                                 name="inst_business_instagram"
//                                 value={
//                                   data?.inst_profile?.inst_business_instagram
//                                 }
//                                 placeholder="instagram link"
//                               />
//                             </div>
//                           </Form.Group>
//                         </Col>
//                         <Col md="6">
//                           <Form.Group className="form-group">
//                             <Form.Label htmlFor="exampleFormControlInputText1">
//                               Facebook Link
//                             </Form.Label>
//                             <div className="form-control-wrap">
//                               <Form.Control
//                                 type="text"
//                                 onChange={(e) =>
//                                   handleChange(e, "inst_profile")
//                                 }
//                                 name="inst_business_facebook"
//                                 value={
//                                   data?.inst_profile?.inst_business_facebook
//                                 }
//                                 id="username"
//                                 placeholder="Facebook link"
//                               />
//                             </div>
//                           </Form.Group>
//                         </Col>
//                         <Col md="6">
//                           <Form.Group className="form-group">
//                             <Form.Label htmlFor="exampleFormControlInputText1">
//                               LinkedIn Link
//                             </Form.Label>
//                             <div className="form-control-wrap">
//                               <Form.Control
//                                 type="text"
//                                 onChange={(e) =>
//                                   handleChange(e, "inst_profile")
//                                 }
//                                 name="inst_business_linkedin"
//                                 value={
//                                   data?.inst_profile?.inst_business_linkedin
//                                 }
//                                 id="username"
//                                 placeholder="LinkedIn link"
//                               />
//                             </div>
//                           </Form.Group>
//                         </Col>
//                         <Col md="6">
//                           <Form.Group className="form-group">
//                             <Form.Label htmlFor="exampleFormControlInputText1">
//                               Helpline no
//                             </Form.Label>
//                             <div className="form-control-wrap">
//                               <Form.Control
//                                 type="text"
//                                 className="mt-1"
//                                 onChange={(e) => handleChange(e)}
//                                 name="mobile"
//                                 value={data?.mobile}
//                                 id="username"
//                                 placeholder="mobile"
//                               />
//                             </div>
//                           </Form.Group>
//                         </Col>
//                         {/* 
//                         // accoridng to client requirements
                              
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Country</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Select
//                               required
//                               id="selectcountry"
//                               value={data?.inst_profile?.inst_business_country}
//                               aria-label="Default select example"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_country"
//                             >
//                               <option></option>
//                               {countryList.length > 0 &&
//                                 countryList.map((item) => {
//                                   return (
//                                     <>
//                                       <option value={item.text}>
//                                         {item.text}
//                                       </option>
//                                     </>
//                                   );
//                                 })}
//                             </Form.Select>
//                           </div>
//                         </Form.Group>
//                       </Col> 
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Website Url</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="website"
//                               value={data?.inst_profile?.inst_business_url}
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_url"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Facebook Url</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="facebook"
//                               value={data?.inst_profile?.inst_facebook_url}
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_facebook_url"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Street Address</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="address"
//                               value={data?.inst_profile?.inst_business_address}
//                               placeholder="Street Address"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_address"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">City</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               id="city"
//                               value={data?.inst_profile?.inst_business_city}
//                               placeholder="City"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_city"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">State</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="text"
//                               value={data?.inst_profile?.inst_business_state}
//                               id="state"
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_state"
//                               placeholder="State/Province"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6">
//                         <Form.Group className="form-group">
//                           <Form.Label htmlFor="exampleFormControlInputText1">Postal Code</Form.Label>
//                           <div className="form-control-wrap">
//                             <Form.Control
//                               required
//                               type="number"
//                               id="pincode"
//                               value={data?.inst_profile?.inst_business_postal_code}
//                               onChange={(e) => handleChange(e, "inst_profile")}
//                               name="inst_business_postal_code"
//                               placeholder="Postal Code"
//                             />
//                           </div>
//                         </Form.Group>
//                       </Col>
//                       */}
//                       </Row>
//                     </Tab.Pane>

//                     <Tab.Pane eventKey="custom-team">
//                       <TeamDataTable
//                         tableClassName="data-table-head-light table-responsive team-edit"
//                         inst_id={inst_id}
//                         data={teamData}
//                         columns={TeamColumns}
//                       />
//                     </Tab.Pane>

//                     <Tab.Pane eventKey="custom-branch">
//                       <BranchAdminDataTable
//                         inst_id={inst_id || userData?._id}
//                       />
//                     </Tab.Pane>
//                     <Tab.Pane eventKey="plan-price">
//                       {Object.values(institutePlanPrice)?.length > 0 ? (
//                         <InstitutePlanPrice />
//                       ) : (
//                         // <div className="text-secondary">No plan buy for this institute</div>
//                         <Alert className="mt-4" variant="danger">
//                           No plan buy for this institute
//                         </Alert>
//                       )}
//                     </Tab.Pane>
//                   </Tab.Content>
//                 </Tab.Container>

//                 {activeKey != "custom-team" && activeKey != "plan-price" && (
//                   <div className="d-flex justify-content-center my-5">
//                     <Button
//                       variant="primary"
//                       className="w-25 mx-auto"
//                       onClick={submitForm}
//                     >
//                       Update
//                     </Button>
//                   </div>
//                 )}
//               </Card.Body>
//             </Card>
//           )}
//         </Block>
//       </Layout>
//     </>
//   );
// }

// export default AdminDashboard;

// **********************************************************

import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Nav,
  Modal,
  Tab,
  Tabs,
  Button,
  Dropdown,
  ButtonGroup,
  InputGroup,
  Badge,
  Table,
  CloseButton,
  ToastContainer,
  Toast,
  Alert,
} from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import FileUploadNew from "../../components/Form/FileUploadNew";
import { City, Country, State } from "country-state-city";
// import dateUtils from '../../utilities/dateUtils';
import {
  Icon,
  Image,
  Media,
  Pureknob,
  ChartLegend,
  CustomDropdownMenu,
  CustomDropdownToggle,
  MediaGroup,
  LinkList,
  LinkListItem,
  MediaText,
} from "../../components";
import FileUpload from "../../pages/material-management/components/fileUpload";
import TeamDataTable from "./TeamDataTable";
import TeamData, { TeamColumns } from "./TeamData";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthApi from "../../api/auth";
import Context from "../../context";
import Swal from "sweetalert2/src/sweetalert2.js";
import BranchList from "../branch/ViewBranch";
import { getTimeZones, getTimezoneOffset } from "../../utilities/timeZones";
import countryList from "../../country";
import BranchAdminDataTable from "./BranchAdminDataTable";
import { colors } from "../../utilities/colorsToAvoid";
import ColorPicker from "../../components/Form/ColorPicker";
import {
  Button as ButtonCh,
  Wrap,
  WrapItem,
  color,
  useToast,
} from "@chakra-ui/react";
import InstitutePlanPrice from "./InstitutePlanPrice";
import { CountryState } from "../../components/Form/CountryState";
// import  CountryState  from "../../components/Form/CountryState";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import dateUtils from "../../utilities/dateUtils";
import CountryState2 from "../../components/Form/CountryState2";
import FlagAndMobileOtp from "../../components/Form/FlagAndMobileOtp";
// import CountryState2 from "../../components/Form/CountryState2";

import { FaEdit } from "react-icons/fa"; // Adjust the import according to your icon library
import AdminApi from "../../api/admin";
import TicketInstAdminDataTable from "./TicketInstAdminDataTable";

function AdminDashboard() {
  let {
    userData,
    token,
    domainData,
    setUserData,
    localHost,
    setDomainData,
    setToken,
  } = useContext(Context);
  userData = JSON.parse(userData);
  let institute_profile = userData?.login_type == "1";
  let admin_profile = userData?.login_type == "0";
  let student_profile = userData?.login_type == "2";
  console.log("institute_profile", userData?.stu_profile?.instituteName);
  console.log("admin_profile", admin_profile);
  console.log("student_profile", student_profile);
  domainData = JSON.parse(domainData);

  console.log(
    domainData?.inst_profile?.color_code,
    "domainData?.inst_profile?.color_code"
  );
  const [demoModal, setDemoModal] = useState(false);
  const [demoModalEmail, setDemoModalEmail] = useState(false);

  const demoModalClose = () => setDemoModal(false);
  const demoModalCloseEmail = () => setDemoModalEmail(false);
  const demoModalShow = () => setDemoModal(true);

  const [otpModal, setOtpModal] = useState(false);
  const [otpModalMobile, setOtpModalMobile] = useState(false);
  const [mobileOtpModalError, setMobileOtpModalError] = useState("");

  const otpModalClose = () => setOtpModal(false);
  const otpModalCloseMobile = () => setOtpModalMobile(false);
  const otpModalShow = () => setOtpModal(true) & setDemoModal(false);

  const [mobileModal, setMobileModal] = useState(false);
  const [mobileModalResend, setMobileModalResend] = useState(false);

  const mobileModalClose = () => setMobileModal(false);
  const mobileModalShow = () => setMobileModal(true) & setOtpModal(false);

  const [billdetailModal, setBilldetailModal] = useState(false);

  const billdetailModalClose = () => setBilldetailModal(false);
  const billdetailModalShow = () => setBilldetailModal(true);

  const [gstModal, setGstModal] = useState(false);

  const gstModalClose = () => setGstModal(false);
  const gstModalShow = () => setGstModal(true);

  const [show, setShow] = useState(false);

  const handlePhoneInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    setMobileOtpModalError("");
  };

  let api = new AuthApi();
  const { domain_name, inst_id } = useParams();
  const [data, setData] = useState({
    timeZone: userData?.timeZone,
    errors: {},
  });

  const [colorPickError, setColorPickError] = useState("");

  console.log("data432234userData :>> ", userData.domain_name,domain_name,inst_id);
  const [countryNumber, setCountryNumber] = useState();

  const { errors } = data;
  const [data1, setData1] = useState({});
  const [teamData, setTeamdata] = useState([]);
  const [topUpModal, setTopUpTrue] = useState(false);
  const [institutePlan, setInstitutePlan] = useState({});
  const [currentInstitutePlan, setCurrentInstitutePlan] = useState({});
  const [institutePlanPrice, setInstitutePlanPrice] = useState({});
  const [country, setCountry] = useState({});
  const [stateSingle, setStateSingle] = useState({});
  const [citySingle, setCitySingle] = useState({});
  const [stateData, setStateData] = useState([]);

  const [cityData, setCityData] = useState([]);
  console.log(data,"currentInstitutePlan")

  const resendOtpHandler = () => {
    setOtpModal(false);
    // setMobileModal(false);
    setOtpModalMobile(false)
  };

  const resendOtpHandlerMobile = () => {
    setOtpModalMobile(false)
  };

  const handleFileUpload = (newFiles) => {
    setData((prevData) => ({
      ...prevData,
      ["logo"]: newFiles,
      ["inst_profile"]: {
        ...data.inst_profile,
        ["business_logo"]: "",
      },
    }));
  };

  const handleFileUploadFavicon = (newFiles) => {
    setData((prevData) => ({
      ...prevData,
      ["inst_favicon_logo"]: newFiles,
      ["inst_profile"]: {
        ...data.inst_profile,
        ["inst_favicon_logo"]: "",
      },
    }));
  };

  const handleBusinessCertificateUploadFavicon = (newFiles) => {
    setData((prevData) => ({
      ...prevData,
      ["business_certificate"]: newFiles,
      ["inst_profile"]: {
        ...data.inst_profile,
        ["business_certificate"]: "",
      },
    }));
  };

  console.log("FILESTOUPLOAD", data);
  let formDataObject = new FormData();
  let navigate = useNavigate();

  const submitForm = async (e) => {
    // e.preventDefault();

    //remove unnecessary data from uploaded
    delete data.createdAt;
    delete data.otp;
    delete data.updatedAt;
    delete data.password;
    delete data._token;
    delete data.permission_routes;
    delete data.otp_forget_password;
    // delete data.domain_name

    console.log("domain_data :>> ", data);
    for (const [key, value] of Object.entries(data)) {
      if (key === "banner_images") {
        for (const imageFile of value) {
          formDataObject.append("banner_images", imageFile);
        }
      } else if (key === "internal_banner_images") {
        for (const imageFile of value) {
          formDataObject.append("internal_banner_images", imageFile);
        }
      } else if (
        key === "business_certificate" ||
        key === "logo" ||
        key === "inst_favicon_logo"
      ) {
        if (key === "logo") {
          formDataObject.append(key, value[0]);
        } else {
          console.log("MAC", key, value[0]);
          formDataObject.append(key, value[0]);
        }
      } else {
        if (typeof value === "object" && value !== null) {
          if (key == "inst_profile") {
            formDataObject.append("inst_profile", JSON.stringify(value));
          }
        } else {
          formDataObject.append(key, value);
        }
      }
    }

    // if(window.location.pathname == "/institute/profile"){
    //   formDataObject = new FormData();
    //   for (const [key, value] of Object.entries(data)) {
    //     if (key === "_id") {
    //       formDataObject.append(key, value);
    //     } else if (key === "logo") {
    //       if (key === "logo") {
    //         formDataObject.append(key, value[0]);
    //       }
    //     }
    //   }
    // }

    await api.institute_register(formDataObject).then((res) => {
      try {
        console.log("res :>> ", res);
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.msg,
          });
          // navigate("/");
          // isEdit(false)
          if (res.data?.length > 0 && userData.login_type != "0") {
            setUserData(JSON.stringify(res.data[0]));
            localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
            localStorage.setItem(
              "instDomainHilingo",
              JSON.stringify(res.data[0])
            );
            setDomainData(JSON.stringify(res.data[0]));

            setOtpModal(false);
            setMobileModal(false);
            setDemoModal(false);
            setDemoModalEmail(false);
          }
          if (res.data?.length > 0 && userData.login_type == "0") {
            console.log("inside if");
            setIsEdit(false);
            navigate(
              `/institute-detail/${res?.data?.[0]?.domain_name}/${res?.data?.[0]?._id}`
            );
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  useEffect(() => {
    getInstittuteDetailByname();
  }, [domain_name]);
  const getInstittuteDetailByname = async () => {
    await api
      .get_instittute_By_Name({
        name: domain_name ? domain_name : userData.domain_name?userData.domain_name:domainData?.domain_name,
      })
      .then((res) => {
        if (res.data?.length > 0) {
          setCountryNumber(res?.data[0]?.country_code);

          setData1({
            ...res.data[0],
            ["inst_gst_number_active"]:
              res.data[0]?.inst_profile?.hasOwnProperty("inst_gst_number") &&
              res.data[0]?.inst_profile?.hasOwnProperty("inst_company_name") &&
              (res.data[0]?.inst_profile?.inst_gst_number != "" ||
                res.data[0]?.inst_profile?.inst_company_name != "")
                ? true
                : false,
          });
          setData({
            ...res.data[0],
            inst_profile: {
              ...res.data[0]?.inst_profile,
              internal_banner_images: res.data[0]?.inst_profile
                ?.internal_banner_images
                ? res.data[0]?.inst_profile?.internal_banner_images
                : [],
            },

            ["inst_gst_number_active"]:
              res.data[0]?.inst_profile?.hasOwnProperty("inst_gst_number") &&
              res.data[0]?.inst_profile?.hasOwnProperty("inst_company_name") &&
              (res.data[0]?.inst_profile?.inst_gst_number != "" ||
                res.data[0]?.inst_profile?.inst_company_name != "")
                ? true
                : false,
          });

          console.log("res?.data[0] :>> ", res?.data[0]?.country_code);
          // setCountryNumber(res?.data[0]?.country_code)
          const result = countryData.filter(
            (c) =>
              c.name.toLowerCase() ==
              res.data[0]?.inst_profile?.inst_business_country.toLowerCase()
          )[0];
          console.log("SERVERPRO", result, countryData);
          setCountry(result);

          console.log("stateData :>> ", stateData);
          const stateResult = State.getStatesOfCountry(result?.isoCode).filter(
            (c) =>
              c.name.toLowerCase() ==
              res.data[0]?.inst_profile?.inst_business_state.toLowerCase()
          )[0];
          console.log("stateResult", stateResult);
          setStateSingle(stateResult);

          const cityResult = City.getCitiesOfState(
            result?.isoCode,
            stateResult?.isoCode
          ).filter(
            (c) =>
              c.name.toLowerCase() ==
              res.data[0]?.inst_profile?.inst_business_city.toLowerCase()
          )[0];
          console.log("cityResult :>> ", cityResult);
          setCitySingle(cityResult);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log("dataaaa", data);
  console.log("data._id", data._id);
  useEffect(() => {
    if (inst_id) {
      getTeamByInstituteId(inst_id);
    } else if (data._id) {
      getTeamByInstituteId(data._id);
    }
  }, [data?._id, inst_id]);

  const removeTeamById = async (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        api
          .inactive_user(params, token)
          .then((res) => {
            if (res.status) {
              getTeamByInstituteId();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.msg,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleChange = (e, inst_profile, index, banner) => {
    let { value, name, checked } = e?.target;
    console.log("nameee", value);
    //block light colors

    if (name == "inst_business_name") {
      setData((prevData) => ({
        ...prevData,
        ["domain_name"]: value?.replace(/\s+/g, " ").trim(),
      }));
    }

    if (inst_profile) {
      if (name == "business_logo") {
        setData((prevData) => ({
          ...prevData,
          ["logo"]: "",
          ["inst_profile"]: {
            ...data.inst_profile,
            [name]: "",
          },
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...data.inst_profile,
            [name]: value?.replace(/\s+/g, " ").trim(),
          },
        }));
      }
    } else {
      if (name == "logo" || name == "business_certificate") {
        setData((prevData) => ({ ...prevData, [name]: e.target.files[0] }));
      } else {
        if (name == "banner_images") {
          let tempArray = [...data?.banner_images];
          tempArray[index] = e.target.files[0];
          // setData((prevData) => ({ ...prevData, [name]: tempArray }));
        } else {
          if (name == "domain_name") {
            setData((prevData) => ({
              ...prevData,
              [name]: value?.replace(/\s+/g, " ").trim(),
              ["inst_profile"]: {
                ...data.inst_profile,
                ["inst_business_name"]: value?.replace(/\s+/g, " ").trim(),
              },
            }));
          } else {
            setData((prevData) => ({
              ...prevData,
              [name]: value?.replace(/\s+/g, " ").trim(),
            }));
          }
        }
      }
    }

    if (name == "deletebanner") {
      // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
      setData((prevData) => ({
        ...prevData,
        ["inst_profile"]: {
          ...data.inst_profile,
          ["banner_images"]: data?.inst_profile?.banner_images.filter(
            (item) => item.url != banner
          ),
        },
      }));
    }

    if (name == "deleteInternalbanner") {
      // console.log("data",data.inst_profile.banner_images.length > 0 &&data.inst_profile.banner_images((item)=>item.url != banner))
      setData((prevData) => ({
        ...prevData,
        ["inst_profile"]: {
          ...data.inst_profile,
          ["internal_banner_images"]:
            data?.inst_profile?.internal_banner_images.filter(
              (item) => item.url != banner
            ),
        },
      }));
    }

    if (name == "enquiry_otp_deduction") {
      console.log("checkedddddddd :>> ", name);
      setData((prev) => ({ ...prev, enquiry_otp_deduction: checked }));
    }

    if (name == "color_code") {
      console.log("COLRS", colors, value);
      if (colors.includes(value)) {
        // alert("Don`t Pick Light Color");
        setShow(true);

        setData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...data.inst_profile,
            [name]: userData?.inst_profile?.color_code,
          },
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          ["inst_profile"]: {
            ...data.inst_profile,
            [name]: value,
          },
        }));
      }
    }

    if (name == "mobile") {
      setData((prev) => ({
        ...prev,
        country_code: countryNumber || userData?.mobile ,
        ["type"]: "enquiry_form",
        // institute_id:userData?._id
      }));
    }

    // if(name == "username"){

    //   // data?.inst_profile?.inst_contact_firstname
    //   setData((prev)=>({...prev,  ...inst_profile,inst_profile:{inst_contact_firstname: value }}));
    // }

    if (name == "username") {
      // data?.inst_profile?.inst_contact_firstname
      setData((prev) => ({
        ...prev,
        inst_profile: { ...prev?.inst_profile, inst_contact_firstname: value },
      }));
    }
    if (name == "usernameLast") {
      // data?.inst_profile?.inst_contact_firstname
      setData((prev) => ({
        ...prev,
        inst_profile: { ...prev?.inst_profile, inst_contact_lastname: value },
      }));
    }

    if (name == "inst_business_city") {
      // data?.inst_profile?.inst_contact_firstname
      setData((prev) => ({
        ...prev,
        inst_profile: { ...prev?.inst_profile, inst_business_city: value },
      }));
    }

    if (name == "inst_business_address") {
      // data?.inst_profile?.inst_contact_firstname
      setData((prev) => ({
        ...prev,
        inst_profile: { ...prev?.inst_profile, inst_business_address: value },
      }));
    }

    if (name == "inst_business_name1") {
      // data?.inst_profile?.inst_contact_firstname
      setData((prev) => ({
        ...prev,
        inst_profile: { ...prev?.inst_profile, inst_business_name: value },
      }));
    }
  };

  const selectCountry = (val) => {
    console.log("va43l", val);

    setData((prev) => ({
      ...prev,
      inst_profile: { ...prev?.inst_profile, inst_business_country: val },
    }));
  };

  const selectRegion = (val) => {
    console.log("val32322 :>> ", val);
    setData((prev) => ({
      ...prev,
      inst_profile: { ...prev?.inst_profile, inst_business_state: val },
    }));
  };

  const getTeamByInstituteId = async (instid) => {
    console.log("instid", instid);
    console.log("data._id", data._id);
    await api
      .get_teachers_By_Inst({ id: instid ? instid : data._id, token })
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log("GET TEACHERS BY INST ID -> ", res.data);
          setTeamdata(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInsitutePlan = async (instId) => {
    await api
      .get_plans_by_inst(instId)
      .then((res) => {
        if (res.status) {
          setInstitutePlan(res.data);
          console.log("aaaaaaa1")
          if(JSON.parse(localStorage.getItem("isOverride"))==0){

            setCurrentInstitutePlan(res?.data)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInsitutePlanPrice = async (instId) => {
    await api
      .get_plans_by_inst(instId)
      .then((res) => {
        if (res.status) {
          console.log("aaaaaaa",instId)
          setInstitutePlanPrice(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
console.log(data1,"data1")
  const givenDate = new Date(
    student_profile ? userData?.createdAt : data1.createdAt
  );
  // Define an array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract year, month, and day from the Date object
  const year = givenDate.getUTCFullYear();
  const monthIndex = givenDate.getUTCMonth();
  const day = givenDate.getUTCDate();

  // Create the desired date format
  const desiredDateFormat = `${day} ${monthNames[monthIndex]} ${year}`;

  const TeamColumns = [
    {
      name: "SRNo",
      cell: (row, index) => <span className="text-middark">{index + 1}</span>,
      sortable: false,
    },
    // {
    //   name: "Email",
    //   selector: (row) => row.name,
    //   cell: (row) => (
    //     <MediaGroup>
    //       <Media size="md" shape="circle" variant={row.theme && row.theme}>
    //         {/* { row.avatar ?
    //                     <Image src={row.avatar} staticImage/> :
    //                     <span className="smaller fw-medium">{(row?.name)}</span>
    //                 } */}
    //       </Media>
    //       <MediaText>
    //         <Link to={`/user-manage/user-profile/${row._id}`} className="title">
    //           {row?.email}
    //         </Link>
    //       </MediaText>
    //     </MediaGroup>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <span className="text-middark">{row.email}</span>,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.username,
      cell: (row) => <span className="text-middark">{row.name}</span>,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.username,
      cell: (row) => <span className="text-middark">{row.mobile}</span>,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status,
      cell: (row) => (
        // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //     : row.status === "Inactive" ? "danger"
        //         : "primary"}`
        // }>
        <span
          className={`badge text-bg-${
            row.is_deleted == 0
              ? "success"
              : row.is_deleted == 1
              ? "danger"
              : "primary"
          }`}
        >
          {row.is_deleted == 0 ? "Active" : "Inactive"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row?.teacher_profile?.teacher_role,
      cell: (row) => (
        <span className="text-middark">
          {row?.teacher_profile?.teacher_role}
        </span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`/edit-team/${row._id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem>

            <div
              className="link-list-hover-bg-primary link-list-md d-flex del-btn"
              onClick={() => removeTeamById({ _id: row._id })}
            >
              <Icon name="trash"></Icon>
            </div>
            {/* <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
                                        <Icon name="trash"></Icon>
                                    </LinkListItem> */}
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  let isAdmin = userData?.login_type == "0";
  const [activeKey, setActiveKey] = useState("custom-home");
  const [topup_amount, setTopUpAmnt] = useState(0);
  const handleSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };
  const add_topup_credit = async () => {
    await api
      .add_topup_credit({ amount: Number(topup_amount), user_id: inst_id })
      .then((res) => {
        if (res?.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          // navigate("/institute-list");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      })
      .catch((er) => console.log(er));
  };

  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    staff_List();
    getInsitutePlan(userData?._id);
    getInsitutePlanPrice(inst_id);
  }, []);

  const staff_List = async () => {
    await api
      .staff_List()
      .then((res) => {
        console.log("STAFF LIST --> ", res);
        if (res.data?.length > 0) {
          setStaffList(res.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(()=>{
  // setData((prevData) => ({
  //   ...prevData,
  //   ["inst_profile"]: {
  //     ...data.inst_profile,
  //     ["inst_gst_number_active"]: data?.inst_profile?.inst_gst_number?true:false,
  //   },
  // }));
  // },[data])

  const updateBannerImages = (updatedFiles, internal_banner_images) => {
    console.log("object :>> ", updatedFiles, internal_banner_images);
    if (internal_banner_images) {
      if (data?.internal_banner_images?.length) {
        let previousArray = [...data.internal_banner_images];
        let updatedArray = previousArray.concat(updatedFiles);
        setData({
          ...data,
          internal_banner_images: updatedArray,
        });
      } else {
        setData({
          ...data,
          internal_banner_images: updatedFiles,
        });
      }
    } else {
      if (data?.banner_images?.length) {
        let previousArray = [...data.banner_images];
        let updatedArray = previousArray.concat(updatedFiles);
        setData({
          ...data,
          banner_images: updatedArray,
        });
      } else {
        setData({
          ...data,
          banner_images: updatedFiles,
        });
      }
    }
  };
  const [isEdit, setIsEdit] = useState(false);
  const editHandler = () => {
    setIsEdit(true);
  };
  const toast = useToast();
  const toastIdRef = React.useRef();

  const [copyText, setCopyText] = useState("");
  const handleCopyClick = (textToCopy) => {
    console.log("textToCopy :>> ", textToCopy);
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
        setCopyText("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopyText("Failed to copy text: ", err);
      });
  };

  console.log("isEdit :>> ", institutePlanPrice);

  let countryData = Country.getAllCountries();

  useEffect(() => {
    if (country) {
      setStateData(State.getStatesOfCountry(country.isoCode));
    }
  }, [country]);

  useEffect(() => {
    if (stateSingle) {
      setCityData(
        City.getCitiesOfState(country?.isoCode, stateSingle?.isoCode)
      );
    }
  }, [stateSingle]);

  const handleCountryChange = (e) => {
    const selectedCountry = countryData.find(
      (c) => c.isoCode === e.target.value
    );
    setCountry(selectedCountry);
    setData((prev) => ({
      ...prev,
      inst_profile: {
        ...prev.inst_profile,
        inst_business_country: selectedCountry?.name,
      },
    }));
  };

  const handleStateChange = (e) => {
    const selectedState = stateData.find((s) => s.isoCode === e.target.value);
    setStateSingle(selectedState);
    //   setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:state?.name}})) // not instant update
    // const selectedCountry = countryData.find(c => c.isoCode === e.target.value);
    // setCountry(selectedCountry);
    setData((prev) => ({
      ...prev,
      inst_profile: {
        ...prev.inst_profile,
        inst_business_state: selectedState?.name,
      },
    }));
  };

  const handleCityChange = (e) => {
    const selectedCity = cityData.find((s) => s.name === e.target.value);
    setCitySingle(selectedCity);
    //   setData((prev)=>({...prev,inst_profile:{...prev?.inst_profile,inst_business_state:state?.name}})) // not instant update
    // const selectedCountry = countryData.find(c => c.isoCode === e.target.value);
    // setCountry(selectedCountry);
    setData((prev) => ({
      ...prev,
      inst_profile: {
        ...prev.inst_profile,
        inst_business_city: selectedCity?.name,
      },
    }));
  };

  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState(null);
  const [timer, setTimer] = useState(false);
  const [VisibleDiv, setVisibleDiv] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpModalText, setoptModalText] = useState("");
  async function generateRandomNumber(type) {
    setoptModalText(type);
    setLoading(true);
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .send_email_otp({
        request_id: randomNumber,
        name: userData?.inst_profile?.inst_contact_firstname,
        email: type == "mobile" ? userData?.email : data?.email,

        type: "update_inst_mobile",
      })
      .then((res) => {
        console.log("otpres", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });
          setLoading(false);
          // setDemoModal(false)
          if (type == "mobile") {
            setOtpModal(true);
          } 
          else if( type == "email"){
            setOtpModal(true)
          }
          // setOtpModal(true)
          // setDemoModal(false);

          setOtp(randomNumber);
          setVisibleDiv(true);
          setTimer(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
          if(res.message == "OTP attempts exceeded. Try Again After 3 minutes."){

            setTimer(true)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function generateRandomNumberMobile(type) {
    setoptModalText(type);
    setLoading(true);
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .send_mobile_otp({
        request_id: randomNumber,
        name: userData?.inst_profile?.inst_contact_firstname,
        mobile:  type == "mobile"?data?.mobile: userData?.mobile,
        institute_id:userData?._id,
        country_code:countryNumber,
        type: "enquiry_form",
      })
      .then((res) => {
        console.log("otpres", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });
          setLoading(false);
          // setDemoModal(false)
          if (type == "mobile") {
           
              setOtpModal(false);
              setOtpModalMobile(true)
          
          }
         else if (type == "email") {
           
          
          setOtpModalMobile(true)
        
        }
          // setOtpModal(true)
          // setDemoModal(false);

          setOtp(randomNumber);
          setVisibleDiv(true);
          setTimer(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
          if(res.message == "OTP attempts exceeded. Try Again After 3 minutes."){

            setTimer(true)
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log(data, "countryNumber");

  const inputRefs = useRef([]);
  // const [setOtpValue,setOtpValue] =useState()



  const [otpValue, setOtpValue] = useState(new Array(6).fill(""));

  const [tempOtpValue, setTempOtpValue] = useState("");

  // const handleChangeotp = (e, index) => {
  //   const value = e.target.value;

  //   // Update the specific index with the new value
  //   const newOtp = [...otpValue];
  //   newOtp[index] = value;

  //   // Update the OTP state
  //   setOtpValue(newOtp);

  //   // Log the updated OTP value
  //   console.log("value34", newOtp.join(''));
  //   if (/^[0-9]$/.test(value)) {
  //     // Only accept a single digit and move to the next input if available
  //     if (index < inputRefs.current.length - 1) {
  //       inputRefs.current[index + 1].focus();
  //     }
  //     setOtpValue(value)
  //   } else {
  //     // Clear the input if it's not a single digit
  //     e.target.value = '';
  //   }
  // };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
console.log(pastedData,"pastedData");

    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      for (let i = 0; i < 6; i++) {
        inputRefs.current[i].value = pastedData[i];
      }
      setTempOtpValue(pastedData)
    }
  };

  const handleChangeotp = (e, index) => {
    const value = e.target.value;

    // Update the specific index with the new value
    const newOtp = [...otpValue];
    console.log("newOtp :>>2 ", newOtp);
    newOtp[index] = value;
    console.log("newOtp :>> ", newOtp);
    // Update the OTP state

    // setOtpValue(newOtp?.join(''));
    setOtpValue(newOtp);

    // Log the updated OTP value
    console.log("value34", newOtp.join(""));

    // Convert the OTP to a number if all digits are entered
    // const otpNumber = newOtp.join('');
    // if (/^[0-9]{6}$/.test(otpNumber)) {
    //     console.log("OTP Number:", parseInt(otpNumber, 10));
    // }

    if (/^[0-9]$/.test(value)) {
      // Only accept a single digit and move to the next input if available
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      // Clear the input if it's not a single digit
      e.target.value = "";
    }
  };

  useEffect(() => {
    // Update the main OTP state whenever the temporary OTP state changes
    setTempOtpValue(otpValue?.join(""));
  }, [otpValue]);

  console.log("tempOtpValue :>> ", tempOtpValue, otpValue);
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !e.target.value) {
      // Move to the previous input on Backspace if current input is empty
      inputRefs.current[index - 1].focus();
    }
  };

  const [callUpdate, setCallUpdate] = useState(false);

  const [localStorageData, setLocalStorageData] = useState(() => {
    const storedValue = JSON.parse(window.localStorage.getItem("isOverride"));
    return storedValue !== null ? storedValue : null;
  });

  useEffect(() => {
    // Function to read from local storage and update state
    const updateLocalStorageData = () => {
      const storedValue = JSON.parse(window.localStorage.getItem("isOverride"));
      setLocalStorageData(storedValue);
    };

    // Initial read
    updateLocalStorageData();

    // Optionally use an interval to check for updates
    const intervalId = setInterval(updateLocalStorageData, 1000); // Poll every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once on mount

  const [expiry,setExpiry]=useState()
  let adminApi = new AdminApi();
  const getActivePlanPricing = async (isOverride,type) => {
    await adminApi.get_expire_date(userData?.login_type=="0"|| userData?.login_type=="2"?data1?._id:userData?._id,isOverride).then((res) => {
      console.log(userData?.login_type,"userData?.login_type");
      try {
        if (res.status) {
          console.log("res323", res?.data?.userId?.isOverride);
          setExpiry(res?.data?.plan_expiry)
        }
      } catch (err) {
        console.log(err);
      }
    });
  };


  const get_active_plans_by_inst = async (isOverride) => {
    await adminApi.get_active_plans_by_inst(userData?._id,isOverride).then((res) => {
      try {
        if (res.status) {
          if(JSON.parse(localStorage.getItem("isOverride"))==1){
          console.log(res?.data?.plan_data?.enquiry_form_otp_paid,"resactive")

          setCurrentInstitutePlan(res?.data?.plan_data) }
        }
      } catch (err) {
        console.log(err);
      }
    });
  };


  useEffect(() => {
    if (localStorageData !== null) {
      getActivePlanPricing(localStorageData);

        get_active_plans_by_inst(localStorageData)
     
      // getPlanPricing(0);
    }
    else{
      getActivePlanPricing(data1?.isOverride,"admin");
    }
  }, [localStorageData,data1?.isOverride]);

 
 const [disableInstName,setDisableInstName] = useState(true)
 const [disableName,setDisableName] = useState(true)
  const verifyOtpMobile = async () => {
    if (otpValue.length == 6) {
      await api
        .validate_mobile_otp({
          request_id: otp,
          otp: tempOtpValue,
          mobile: data.mobile,
        })
        .then((res) => {
          console.log("res", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.msg,
            });
            // setCountStep(countStep + 1);
            // setDisable(!nextDisable);
            setVerified(true);
            setVisibleDiv(false);
            // setCallUpdate(true)
            // setMobileModalResend(false);
            if(otpModalText == "mobile")
            submitForm();
            setOtpModalMobile(false)
            if(otpModalText == "email")
            setMobileModalResend(true)
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const verifyOtp = async () => {
    if (otpValue.length == 6) {
      await api
        .validate_email_otp({
          request_id: otp,
          otp: tempOtpValue,
          email: data.email,
        })
        .then((res) => {
          console.log("res", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.msg,
            });
            // setCountStep(countStep + 1);
            // setDisable(!nextDisable);
            setVerified(true);
            setVisibleDiv(false);
            // setCallUpdate(true)
            setMobileModalResend(false);
            setOtpModal(false)
            if(otpModalText == "email")
              submitForm();
            setMobileModal(true)
            // submitForm();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // useEffect(()=>{
  //   submitForm()
  // },[ callUpdate])

  console.log("mobileModalResend :>> ", mobileModalResend, demoModal);
  return (
    <>
      <Row style={{ position: "relative", zIndex: "9999" }}>
        <Col xs={6}>
          <ToastContainer
            className="p-3"
            position="top-end"
            containerPosition="fixed"
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              className="custom-toast"
              delay={3000}
              autohide
            >
              <Toast.Header>
                <MediaGroup>
                  <Media size="tiny">
                    <Image src="/images/favicon.png" />
                  </Media>
                  <MediaText>
                    <strong>Hilingo</strong>
                  </MediaText>
                </MediaGroup>
                {/* <small>11 mins ago</small> */}
              </Toast.Header>
              <Toast.Body
                className="text-secondary "
                style={{
                  height: "120px",
                  backgroundColor: "#E8E7EC",
                  boxShadow: " 0 4px 6px rgba(0, 0, 0, 0.1)",
                  fontSize: "1.1em",
                }}
              >
                Don`t Pick Light Color!!
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Col>
      </Row>

      <Layout title="Add Student" content="container">
        <h1
          className="d-flex align-items-center mb-5"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <Icon
            name="arrow-left-circle-fill"
            className="text-primary h1 me-2"
          ></Icon>{" "}
          Back
        </h1>
        <Block>
          {!student_profile && (
            <Card className="h-100 my-3">
              <div className="d-flex justify-content-end align-items-center bg-primary">
                <Image
                  src="/images/myprofile.gif"
                  className="profile-img"
                ></Image>
              </div>
              <Card.Body>
                <div className="d-flex flex-column align-items-start user-profile gap-3">
                  <Media size="huge" shape="circle">
                    {data1.inst_profile?.business_logo ? (
                      <Image
                        src={data1.inst_profile?.business_logo}
                        staticImage
                        thumbnail
                        alt="user"
                      />
                    ) : (
                      <Image
                        src="/images/avatar/a.jpg"
                        staticImage
                        thumbnail
                        alt="user"
                      />
                    )}
                  </Media>
                  <div className="mt-3 mt-md-0 ms-md-3 me-5 d-flex justify-content-between w-95">
                    <div>
                      <h3 className="title mb-1">
                        { data?.inst_profile?.inst_business_name}
                      </h3>
                      
                      <span className="small fw-bold text-theme-text">
                        Expire Date:
                        {/Z|([+-]\d{2}:\d{2})$/.test(userData?.plan_expiry)?dateUtils?.formate23August2024(userData?.plan_expiry):dateUtils?.formatDateMonthString(expiry)}
                      </span>
                      <br />
                      <Button
                        onClick={() =>
                          navigate(
                            isAdmin
                              ? `/institute/change-password/${inst_id}`
                              : `/institute/change-password/${userData?._id}`
                          )
                        }
                        style={{
                          backgroundColor: "var(--theme-color)",
                          cursor: "pointer",
                            border:"var(--theme-color)"
                        }}
                      >
                        Change Password
                      </Button>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <h4>Select Time Zone</h4>

                      <select
                        className="form-select"
                        value={data?.timeZone}
                        onChange={(e) => {
                          setData({ ...data, timeZone: e.target.value });
                        }}
                      >
                        {getTimeZones().map((ele) => {
                          return (
                            <option>
                              {ele} (UTC {getTimezoneOffset(ele)}:00)
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <h3 className="title mb-1">Credits - {data1?.credit}</h3>

                      {userData?.login_type == "0" && (
                        <>
                          {topUpModal && (
                            <input
                              type="text"
                              className="border ms-3 mb-3 text-secondary"
                              onChange={(e) => setTopUpAmnt(e.target.value)}
                            />
                          )}
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={() =>
                              topUpModal
                                ? add_topup_credit()
                                : setTopUpTrue(!topUpModal)
                            }
                          >
                            {" "}
                            {topUpModal ? "Submit" : "Add Top Up"}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <Row className="g-gs mb-5">
                  <Col md="2">
                    <p className="text-light mb-0">Created on : </p>{" "}
                    <p className="text-secondary">{desiredDateFormat}</p>
                  </Col>
                  <Col md="4">
                    <p className="text-light mb-0">Domain : </p>{" "}
                    <div className="d-flex gap-2 align-items-center">
                      {/* <p className="text-middark"> */}
                      {!isEdit ? (
                        <span
                          className="text-middark"
                          style={{ wordBreak: "break-all" }}
                        >
                          {`https://${data1.domain_name}.hilingo.com`}{" "}

                        </span>
                      ) : (
                        <Form.Control
                          className={isEdit ? "" : "no-border"}
                          name="domain_name"
                          type="text"
                          style={{ height: "30px" }}
                          id="domain_name"
                          value={data.domain_name}
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Domain Name"
                        />
                      )}

                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          toast({
                            title: "Domain Copied",
                            // description: "We've created your account for you.",
                            status: "success",
                            duration: 1000,
                            isClosable: true,
                          })
                        }
                      >
                        <Icon
                          name="copy"
                          className="text-primary"
                          onClick={() =>
                            handleCopyClick(
                              `https://${data1.domain_name}.hilingo.com`
                            )
                          }
                        ></Icon>
                      </div>
                      {/* {copyText && <Alert className='mt-4' variant="danger">{copyText}</Alert>} */}

                      {userData?.login_type == "0" && (
                        <div
                          className="del-btn"
                          onClick={() => {
                            editHandler();
                            // setEditData({ ...edit_data, id: row._id,test_name:row?.test_name });
                          }}
                        >
                          <Icon name="edit"></Icon>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Username : </p>{" "}
                    <span
                      className="text-secondary bg-hilight px-2 rounded py-1"
                      style={{ fontSize: "12px" }}
                    >
                      {" "}
                      {data1.email}
                    </span>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Created by : </p>{" "}
                    <p className="text-secondary">
                      {data1.inst_profile?.inst_contact_firstname +
                        " " +
                        data1.inst_profile?.inst_contact_lastname}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Location : </p>{" "}
                    <Badge bg="hilight" className="text-secondary">
                      {data1.inst_profile?.inst_business_city +
                        " , " +
                        data?.inst_profile?.inst_contact_source_country_student}
                    </Badge>
                  </Col>
                  {userData?.login_type == "0" && (
                    <>
                      <Col md="3">
                        <p className="text-light mb-0">Managed By </p>{" "}
                        <div
                          className="form-control-wrap ms-1 me-1"
                          style={{ width: "208px" }}
                        >
                          <Form.Select
                            removeItemButton
                            value={data?.inst_profile?.manageBy}
                            name="manageBy"
                            onChange={(e) => handleChange(e, "inst_profile")}
                          >
                            <option value="">Select</option>
                            {staffList?.length > 0 &&
                              staffList.map((item) => {
                                return (
                                  <>
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                          </Form.Select>
                        </div>
                      </Col>
                      <Col md="3"></Col>
                      <Col md="3">
                        <Button
                          className="btn btn-success mt-4 px-10"
                          onClick={submitForm}
                        >
                          Update
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          )}
          {student_profile && (
            <Card className="h-100 my-3">
              <Image src="/images/bg.svg"></Image>
              <Card.Body>
                <div className="d-flex flex-column align-items-start user-profile gap-3">
                  <Media size="huge" shape="circle">
                    {userData.student_profile?.profile_pic ? (
                      <Image
                        src={userData.student_profile?.profile_pic}
                        staticImage
                        thumbnail
                        alt="user"
                      />
                    ) : 
                    (
                      userData?.login_type == "2" && <div className="logo-wrap">
                      <p className="no-logo-small p-5">{userData?.name?.charAt(0)?.toUpperCase()}</p>
                 
                    </div>
                    
                  )
                    
                    }
                  </Media>
                  <div className="mt-3 mt-md-0 ms-md-3 me-5 d-flex justify-content-between w-95">
                    <div>
                      <h3 className="title mb-1">{userData?.name}</h3>
                      <span className="small fw-bold text-theme-text">
                        Expire Date:{/Z|([+-]\d{2}:\d{2})$/.test(userData?.plan_expiry)?dateUtils?.formate23August2024(userData?.plan_expiry):dateUtils?.formatDateMonthString(expiry)}
                      </span>
                      <br />
                      <Button
                        onClick={() =>
                          navigate(
                            isAdmin
                              ? `/institute/change-password/${inst_id}`
                              : `/student/change-password/${userData?._id}`
                          )
                        }
                        style={{
                          backgroundColor: "var(--theme-color)",
                          cursor: "pointer",
                            border:"var(--theme-color)"
                        }}
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>
                </div>
                <Row className="g-gs mb-5">
                  <Col md="3">
                    <p className="text-light mb-0">Created on : </p>{" "}
                    <p className="text-secondary">{desiredDateFormat}</p>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Institute Name : </p>{" "}
                    <p className="text-middark">
                      <span> {userData?.stu_profile?.instituteName} </span>{" "}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Username : </p>{" "}
                    <Badge bg="hilight" className="text-secondary">
                      {userData?.email}
                    </Badge>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Created by : </p>{" "}
                    <p className="text-secondary">
                      {userData?.stu_profile?.createdBy}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Teacher Name : </p>{" "}
                    <p className="text-secondary">
                      {userData?.stu_profile?.assignTeacher}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="text-light mb-0">Branch Name : </p>{" "}
                    <Badge bg="hilight" className="text-secondary">
                      {userData?.stu_profile?.branch_name}
                    </Badge>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          {!student_profile && (
            <Card className="h-100 my-3">
              <Card.Body>
                <Tab.Container
                  id="custom-tabs-example"
                  defaultActiveKey="custom-home"
                >
                  <Nav
                    variant="tabs"
                    className="nav-tabs-s1 mb-3 justify-content-around"
                    activeKey={activeKey}
                    onSelect={handleSelect}
                  >
                    <Nav.Item className="px-1">
                      <Nav.Link eventKey="custom-home" className="">My Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="px-1">
                      <Nav.Link eventKey="custom-profile" className="">
                        Customization
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="px-1">
                  <Nav.Link eventKey="custom-bil">Billing Information</Nav.Link>
                </Nav.Item> */}
                    {(userData?.login_type == "0" ||
                      currentInstitutePlan?.institute_role_based_access) && (
                      <Nav.Item className="px-1">
                        <Nav.Link eventKey="custom-team">Team</Nav.Link>
                      </Nav.Item>
                    )}
                    {(userData?.login_type == "0" ||
                      currentInstitutePlan?.multi_branch_management) && (
                      <Nav.Item className="px-1">
                        <Nav.Link eventKey="custom-branch">Branches</Nav.Link>
                      </Nav.Item>
                    )}
                    {userData?.login_type == "0" && (
                      <Nav.Item className="px-1">
                        <Nav.Link eventKey="plan-price">
                          Plan and Price
                        </Nav.Link>
                      </Nav.Item>
                    )}

<Nav.Item className="px-1">
                      <Nav.Link eventKey="tickets" className="">
                        Tickets
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="custom-home">
                      <Row className="g-gs">
                        <Col md="4" className="p-3">
                          <h5>Personal Profile</h5>
                          <p className="text-middark">
                            Change Name, Email, & Mobile Number of your profile.{" "}
                          </p>
                        </Col>
                        <Col md="8" className="p-3">
                          <Row className="g-gs">
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label>User's First Name</Form.Label>
                                <div className="form-control-wrap">
                                  {/* <div className="form-control-icon end">
                                    <Button
                                      variant="white"
                                      className="border-0"
                                    >
                              
                                    </Button>
                                  </div> */}
                               
                                  <Form.Control
                                    type="text"
                                    id="username"
                                    name="username"
                            
                               
                                    onChange={(e) => handleChange(e)}
                                    // value={data?.inst_profile?.inst_contact_firstname+" "+data1?.inst_profile?.inst_contact_lastname}
                                    value={
                                      data?.inst_profile?.inst_contact_firstname
                                    }
                                    placeholder="User Name"
                                  />
                                   
                                     
                                </div>
                              </Form.Group>
                            </Col>

                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label>User's Last Name</Form.Label>
                                <div className="form-control-wrap">
                                  {/* <div className="form-control-icon end">
                                    <Button
                                      variant="white"
                                      className="border-0"
                                    >
                              
                                    </Button>
                                  </div> */}
                               
                                  <Form.Control
                                    type="text"
                                    id="usernameLast"
                                    name="usernameLast"
                            
                               
                                    onChange={(e) => handleChange(e)}
                                    // value={data?.inst_profile?.inst_contact_firstname+" "+data1?.inst_profile?.inst_contact_lastname}
                                    value={
                                      data?.inst_profile?.inst_contact_lastname
                                    }
                                    placeholder="User Name"
                                  />
                                   
                                     
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <Form.Label>Institute Name</Form.Label>
                                <div className="form-control-wrap">

                               
                                <Form.Control
                                    type="text"
                                    id="institueName"
                                    name="inst_business_name1"
                                    disabled={ userData?.login_type == "0"?false:true }
                                   onChange={(e) => handleChange(e)}

                                 
                                    // value={data?.inst_profile?.inst_contact_firstname+" "+data1?.inst_profile?.inst_contact_lastname}
                                    value={
                                      data?.inst_profile?.inst_business_name
                                    }
                                    placeholder="Institute Name"
                                  />
                                 
                                 
                                
                                </div>
                              </Form.Group>
                            </Col>

                            <Col lg="12" className="mt-1">
                              <Form.Group className="form-group">
                                <Form.Label>Email Id</Form.Label>
                                <div className="form-control-wrap">
                                  {/* <div className="form-control-icon end">
                                  <Button variant="white" className="border-0">
                                    <Icon name="edit-alt"></Icon>
                                  </Button>
                                </div> */}
                                  <InputGroup>
                                    <Form.Control
                                      disabled={userData.login_type == "0"?false:true}
                                      type="email"
                                      // id="emailid"
                                      name="email"
                                      onChange={(e) => handleChange(e)}
                                      value={data?.email}
                                      placeholder="Email Id"
                                    />
                                    {userData.login_type !== "0" &&
                                    <InputGroup.Text id="basic-addon2">
                                      <Icon
                                        style={{
                                          color: "var(--theme-color)",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setDemoModalEmail(true)}
                                        name="edit"
                                      ></Icon>
                                    </InputGroup.Text>
}
                                  </InputGroup>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="12" className="mt-1">
                              <Form.Group className="form-group">
                                <Form.Label>Mobile No.</Form.Label>
                                <div className="form-control-wrap">
                                  <InputGroup>
                                    <Form.Control
                                      disabled={userData.login_type == "0"?false:true}
                                      type="number"
                                      name="mobile"
                                      value={
                                        data?.mobile?.length < 11
                                          ? data?.mobile
                                          : ""
                                      }
                                      onChange={(e) => handleChange(e)}
                                      id="mobile"
                                      placeholder="Mobile No."
                                    />
                                     {userData.login_type !== "0" &&
                                    <InputGroup.Text id="basic-addon2">
                                      <Icon
                                        style={{
                                          color: "var(--theme-color)",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setDemoModal(true)}
                                        name="edit"
                                      ></Icon>
                                    </InputGroup.Text>
                                     }
                                  </InputGroup>
                                  <Modal
                                    show={demoModal}
                                    onHide={demoModalClose}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Change Mobile Number
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-flex flex-column justify-content-center">
                                      <p className="text-center text-middark">
                                        Click on GET OTP button to receive on
                                        OTP on your registered email address.{" "}
                                      </p>
                                      <Form.Control
                                        type="email"
                                        id="emailis"
                                        value={userData?.email}
                                        placeholder="Email Id"
                                      />
                                      <Button
                                        disabled={loading}
                                        variant="primary"
                                        className="mx-auto my-3"
                                        // onClick={otpModalShow}
                                        onClick={() => {
                                          generateRandomNumber("mobile");
                                        }}
                                      >
                                        Get OTP
                                      </Button>
                                    </Modal.Body>
                                  </Modal>

                                  <Modal
                                    show={mobileModalResend}
                                    onHide={demoModalCloseEmail}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Change Email Address
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-flex flex-column justify-content-center">
                                      <p className="text-center text-middark">
                                        Click on GET OTP button to receive an
                                        OTP on below email address.{" "}
                                      </p>
                                      <Form.Control
                                        type="email"
                                        id="emailis"
                                        name="email"
                                        value={data?.email}
                                        placeholder="Email Id"
                                        onChange={(e) => handleChange(e)}
                                      />
                                      <Button
                                        disabled={loading}
                                        variant="primary"
                                        className="mx-auto my-3"
                                        // onClick={otpModalShow}
                                        onClick={() => {
                                          generateRandomNumber("email");
                                        }}
                                      >
                                        Get OTP
                                      </Button>
                                    </Modal.Body>
                                  </Modal>

                                  <Modal
                        show={demoModalEmail}
                        onHide={demoModalCloseEmail}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Change Email Address</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                          Click on GET OTP button to receive an OTP on your registered mobile number.
                          </p>
                          <Form.Control
                          disabled
                            type="tel"
                            maxLength={10}
                            id="phone"
                            name="mobile"
                            placeholder="Mobile No."
                            // value={data.phone}
                            value={
                              data?.mobile?.length < 11 ? data?.mobile : ""
                            }
                            onChange={(e) => handleChange(e)}
                            onInput={handlePhoneInput}
                            isInvalid={!!mobileOtpModalError}
                          />
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            disabled={loading}
                            // onClick={submitForm}
                            onClick={() => {
                              generateRandomNumberMobile("email");
                            }}
                          >
                           Get OTP
                          </Button>
                        </Modal.Body>
                      </Modal>
                                </div>
                              </Form.Group>

                              {/* <FlagAndMobileOtp data={data} errors={errors} handleChange={handleChange} userData={userData} setCountryNumber={setCountryNumber} countryNumber={countryNumber}/> */}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Modal
                        show={mobileModal }
                        onHide={mobileModalClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Change Mobile Number</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                            We will notify all the new software update/changes
                            on updated mobile number.
                          </p>
                          <Form.Control
                            type="tel"
                            maxLength={10}
                            id="phone"
                            name="mobile"
                            placeholder="Mobile No."
                            // value={data.phone}
                            value={
                              data?.mobile?.length < 11 ? data?.mobile : ""
                            }
                            onChange={(e) => handleChange(e)}
                            onInput={handlePhoneInput}
                            isInvalid={!!mobileOtpModalError}
                          />
                          <Button
                          disabled={loading}
                            variant="primary"
                            className="mx-auto my-3"
                            // onClick={submitForm}
                            onClick={() => {
                              generateRandomNumberMobile("mobile")
                            }}
                          >
                            Update
                          </Button>
                        </Modal.Body>
                      </Modal>

                      <Modal show={otpModal} onHide={otpModalClose}>
                        <Modal.Header closeButton>
                          {/* <Modal.Title>Change Email Address</Modal.Title> */}
                          <Modal.Title>
                            {otpModalText == "mobile"
                              ? "Change Mobile Number"
                              : "Change Email Address"}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                            {otpModalText == "mobile"
                              ? "changing mobile number requires you to enter OTP sent over to your registerd email address."
                              : "changing email address requires you to enter OTP send over updated email."}
                          </p>
                          <div className="bg-hisuccess rounded p-3 text-white my-3">
                            OTP sent successfully.
                          </div>
                          {/* <div className="d-flex gap-3 my-3">
                            <Form.Control type="number" id="otp1" />
                            <Form.Control type="number" id="otp2" />
                            <Form.Control type="number" id="otp3" />
                            <Form.Control type="number" id="otp4" />
                            <Form.Control type="number" id="otp5" />
                            <Form.Control type="number" id="otp6" />
                          </div> */}
                          <div className="d-flex gap-3 my-3">
                            {[...Array(6)].map((_, index) => (
                              <Form.Control
                                key={index}
                                type="text"
                                maxLength={1}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onChange={(e) => handleChangeotp(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputMode="numeric"
                                onPaste={index === 0 ? handlePaste : undefined}  
                              />
                            ))}
                          </div>
                          <p className="text-center text-secondary my-3">
                            {/* Did not receive? <a href="">Resend</a> */}
                            Did not receive?{" "}
                            <span
                              className="text-info "
                              style={{ cursor: "pointer" }}
                              onClick={resendOtpHandler}
                            >
                              Resend
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            onClick={verifyOtp}
                          >
                            continue
                          </Button>
                        </Modal.Body>



                      </Modal>

                      <Modal show={otpModalMobile} onHide={otpModalCloseMobile}>
                        <Modal.Header closeButton>
                          {/* <Modal.Title>Change Email Address</Modal.Title> */}
                          <Modal.Title>{otpModalText == "mobile"?"Change Mobile Number":"Change Email Address"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column justify-content-center">
                          <p className="text-center text-middark">
                            {otpModalText == "mobile"
                              ? "changing mobile number requires you to enter OTP send to your updated mobile"
                              : "changing email address requires you to enter OTP registerd mobile number."}
                          </p>
                          <div className="bg-hisuccess rounded p-3 text-white my-3">
                            OTP sent successfully.
                          </div>
                          {/* <div className="d-flex gap-3 my-3">
                            <Form.Control type="number" id="otp1" />
                            <Form.Control type="number" id="otp2" />
                            <Form.Control type="number" id="otp3" />
                            <Form.Control type="number" id="otp4" />
                            <Form.Control type="number" id="otp5" />
                            <Form.Control type="number" id="otp6" />
                          </div> */}
                          <div className="d-flex gap-3 my-3">
                            {[...Array(6)].map((_, index) => (
                              <Form.Control
                                key={index}
                                type="text"
                                maxLength={1}
                                ref={(el) => (inputRefs.current[index] = el)}
                                onChange={(e) => handleChangeotp(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputMode="numeric"
                                onPaste={index === 0 ? handlePaste : undefined}  
                              />
                            ))}
                          </div>
                          <p className="text-center text-secondary my-3">
                            {/* Did not receive? <a href="">Resend</a> */}
                            Did not receive?{" "}
                            <span
                              className="text-info "
                              style={{ cursor: "pointer" }}
                              onClick={resendOtpHandlerMobile}
                            >
                              Resend
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            className="mx-auto my-3"
                            onClick={verifyOtpMobile}
                          >
                            continue
                          </Button>
                        </Modal.Body>



                      </Modal>
                      <hr></hr>
                      <Row className="g-gs">
                        <Col md="4" className="p-3">
                          <h5>Address</h5>
                          <p className="text-middark">
                            Edit your institute address.{" "}
                          </p>
                        </Col>
                        <Col md="8" className="p-3">
                          <Row className="g-gs">
                            <Col lg="12" className="mt-1">
                              <Form.Group className="form-group">
                                <Form.Label>Address</Form.Label>
                                <div className="form-control-wrap">
                                  {/* <div className="form-control-icon end">
                                  <Button variant="white" className="border-0">
                                    <Icon name="edit-alt"></Icon>
                                  </Button>
                                </div> */}
                                  <Form.Control
                                    type="text"
                                    value={
                                      data?.inst_profile?.inst_business_address
                                    }
                                    name="inst_business_address"
                                    onChange={(e) =>
                                      handleChange(e, "inst_profile")
                                    }
                                    id="address"
                                    placeholder="Address"
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="6" className="mt-1">
                              <Form.Group className="form-group">
                                <Form.Label>Country</Form.Label>
                                <div className="form-control-wrap">
                                  <Form.Select
                                    name="inst_business_country"
                                    value={country?.isoCode || ""}
                                    onChange={handleCountryChange}
                                  >
                                    {countryData.map((country) => (
                                      <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>

                            <Col lg="6" className="mt-1">
                              <Form.Group className="form-group">
                                {data?.inst_profile?.inst_business_state && (
                                  <>
                                    <Form.Label>State</Form.Label>
                                    <div className="form-control-wrap">
                                      <Form.Select
                                        name="inst_business_state"
                                        value={stateSingle?.isoCode || ""}
                                        onChange={handleStateChange}
                                      >
                                        {stateData.map((state) => (
                                          <option
                                            key={state.isoCode}
                                            value={state.isoCode}
                                          >
                                            {state.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg="6" className="mt-1">
                              <Form.Group className="form-group">
                                <Form.Label>City</Form.Label>
                                <div className="form-control-wrap">
                                  {/* <div className="form-control-icon end">
                                  <Button variant="white" className="border-0">
                                    <Icon name="edit-alt"></Icon>
                                  </Button>
                                </div> */}
                                  {/* <Form.Control
                                  type="text"
                                  id="city"
                                  onChange={(e) =>
                                    handleChange(e, "inst_profile")
                                  }
                                  name="inst_business_city"
                                  value={data?.inst_profile?.inst_business_city}
                                  placeholder="City"
                                /> */}

                                  <Form.Select
                                    name="inst_business_city"
                                    value={citySingle?.name || ""}
                                    onChange={handleCityChange}
                                  >
                                    {cityData.map((city) => (
                                      <option key={city.name} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>

                            {/* <CountryState2/> */}
                            <Col lg="12">
                              <Form.Group className="form-group">
                                <h4>Company details</h4>
                                <span className="text-middark">
                                  This detail is for busssiness in <b>India</b>{" "}
                                  Only.
                                </span>
                                <div className="mt-4">
                                  {" "}
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      checked={
                                        data?.inst_gst_number_active
                                          ? false
                                          : true
                                      }
                                      onChange={(e) => {
                                        setData((prevData) => ({
                                          ...prevData,
                                          ["inst_gst_number_active"]: false,
                                          ["inst_profile"]: {
                                            ...data.inst_profile,
                                            ["inst_company_name"]: "",
                                            ["inst_gst_number"]: "",
                                          },
                                        }));
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      I don't have <b>GSTIN</b> Number
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={
                                        data?.inst_gst_number_active
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        setData((prevData) => ({
                                          ...prevData,
                                          ["inst_gst_number_active"]: true,
                                        }));
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      I have <b>GSTIN</b> Number
                                    </label>
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                            {data?.inst_gst_number_active && (
                              <>
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label>Business Name</Form.Label>
                                    <div className="form-control-wrap">
                                      <Form.Control
                                        required
                                        disabled
                                        onChange={(e) => {
                                          handleChange(e, "inst_profile");
                                        }}
                                        name="inst_company_name"
                                        value={
                                          // data?.inst_profile?.inst_company_name
                                          data?.inst_profile?.inst_business_name
                                        }
                                        placeholder="Business Name"
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label>GST Number</Form.Label>
                                    <div className="form-control-wrap">
                                      <Form.Control
                                        type="text"
                                        id="city"
                                        onChange={(e) => {
                                          handleChange(e, "inst_profile");
                                        }}
                                        name="inst_gst_number"
                                        value={
                                          data?.inst_profile?.inst_gst_number
                                            ?.length < 16
                                            ? data?.inst_profile
                                                ?.inst_gst_number
                                            : ""
                                        }
                                        placeholder="GST Number"
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="custom-profile">
                      <div className="d-flex mb-3 align-items-center">
                        <div className="w-30">
                          <div
                            className="p-2 border d-flex align-items-center justify-content-center upload-img"
                            style={{ width: "120px", height: "120px" }}
                          >
                            {data?.inst_profile?.business_logo && (
                              <Image
                                src={data?.inst_profile?.business_logo}
                              ></Image>
                            )}
                            {data?.logo?.length > 0 && (
                              <Image src={data?.logo[0].preview}></Image>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start w-30">
                          <h5>Company Logo</h5>
                          <p className="mb-0 text-middark">
                            Upload your company logo to display in your panel.
                          </p>
                          {/* <p className="mt-0">click here to resize image</p> */}
                        </div>
                        <div className="d-flex w-30">
                          <div className="d-flex justify-content-center form-control-wrap">
                            <FileUploadNew
                              filetype="image"
                              iconName="upload"
                              onChange={handleFileUpload}
                              name="logo" // Provide the appropriate name
                              noImage={true}
                              aspectRatio="Recommended Aspect ratio 1:1"
                              extensions={"Image must be JPG/JPEG or PNG"}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w-10">
                          {data?.inst_profile?.business_logo != "" ||
                          data?.logo != "" ? (
                            <Button
                              variant="outline-primary"
                              name="business_logo"
                              onClick={(e) => handleChange(e, "inst_profile")}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant="outline-primary"
                              name="business_logo"
                              // onClick={(e) => handleChange(e, "inst_profile")}
                            >
                              Removed
                            </Button>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex mb-3 align-items-center">
                        <div className="w-30">
                          <div
                            className="p-2 border d-flex align-items-center justify-content-center upload-img"
                            style={{ width: "120px", height: "120px" }}
                          >
                            {data?.inst_profile?.inst_favicon_logo?.length >
                              0 && (
                              <Image
                                src={data?.inst_profile?.inst_favicon_logo}
                              ></Image>
                            )}
                            {data?.inst_favicon_logo?.length > 0 && (
                              <Image
                                src={data?.inst_favicon_logo[0].preview}
                              ></Image>
                            )}
                          </div>
                        </div>
                        <div class="d-flex flex-column align-items-start w-30">
                          <h5>Company Favicon Logo</h5>
                          <p className="mb-0 text-middark">
                            Upload your company favicon logo to display in your
                            tab.
                          </p>
                        </div>
                        <div className="d-flex w-30">
                          <div className="d-flex justify-content-center form-control-wrap">
                            <FileUploadNew
                              filetype="image"
                              iconName="upload"
                              onChange={handleFileUploadFavicon}
                              name="inst_favicon_logo"
                              noImage={true}
                              aspectRatio="Recommended Aspect ratio 1:1"
                              extensions={"Image must be JPG/JPEG or PNG"}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w-10">
                          {data?.inst_profile?.inst_favicon_logo != "" ||
                          data?.inst_favicon_logo != "" ? (
                            <Button
                              variant="outline-primary"
                              name="inst_favicon_logo"
                              onClick={(e) => handleChange(e, "inst_profile")}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant="outline-primary"
                              name="inst_favicon_logo"
                            >
                              Removed
                            </Button>
                          )}
                        </div>
                      </div>
                      
                      {/* tochan
                      buisness certificate */}
                      {/* <div className="d-flex mb-3 align-items-center">
                        <div className="w-30">
                          <div
                            className="p-2 border d-flex align-items-center justify-content-center upload-img"
                            style={{ width: "120px", height: "120px" }}
                          >
                            {data?.inst_profile?.business_certificate?.length >
                              0 && (
                              <Image
                                src={data?.inst_profile?.business_certificate}
                              ></Image>
                            )}
                            {data?.business_certificate?.length > 0 && (
                              <Image
                                src={data?.business_certificate[0].preview}
                              ></Image>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start w-30">
                          <h5>business_certificate</h5>
                          <p className="mb-0 text-middark">
                            Upload your business_certificate
                          </p>
                        </div>
                        <div className="d-flex w-30">
                          <div className="d-flex justify-content-center form-control-wrap">
                            <FileUploadNew
                              filetype="image"
                              iconName="upload"
                              onChange={handleBusinessCertificateUploadFavicon}
                              name="business_certificate"
                              noImage={true}
                              aspectRatio="Recommended Aspect ratio 1:1"
                              extensions={"Image must be JPG/JPEG or PNG"}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w-10">
                          {data?.inst_profile?.business_certificate != "" ||
                          data?.business_certificate != "" ? (
                            <Button
                              variant="outline-primary"
                              name="business_certificate"
                              onClick={(e) => handleChange(e, "inst_profile")}
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant="outline-primary"
                              name="business_certificate"
                            >
                              Removed
                            </Button>
                          )}
                        </div>
                      </div> */}
                      <hr />

                      <div className="d-flex mb-3 align-items-center">
                        <div className="d-flex w-30 flex-wrap">
                          {data.inst_profile?.banner_images?.length > 0 &&
                            data.inst_profile?.banner_images.map((item) => {
                              return (
                                <>
                                  <div className="w-50 mb-2">
                                    <div
                                      className="p-2 border d-flex align-items-center justify-content-center upload-img"
                                      style={{
                                        width: "120px",
                                        height: "120px",
                                      }}
                                    >
                                      <CloseButton
                                        id="deleteFromFiles"
                                        className="close-btn"
                                        value={item.url}
                                        name="deletebanner"
                                        onClick={(e) =>
                                          handleChange(
                                            e,
                                            "inst_profile",
                                            null,
                                            item.url
                                          )
                                        }
                                      />
                                      <Image src={item?.url}></Image>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          {data?.banner_images?.length > 0 &&
                            data?.banner_images.map((item, index) => {
                              return (
                                <>
                                  <div className="w-50 mb-2">
                                    <div
                                      className="p-2 border d-flex align-items-center justify-content-center upload-img"
                                      style={{
                                        width: "120px",
                                        height: "120px",
                                      }}
                                    >
                                      <CloseButton
                                        id="deleteFromFiles"
                                        className="close-btn"
                                        value={item.preview}
                                        name="deletebanner"
                                        onClick={(e) => {
                                          let remove =
                                            data?.banner_images.filter(
                                              (item, i) => i !== index
                                            );
                                          setData({
                                            ...data,
                                            banner_images: remove,
                                          });
                                        }}
                                      />
                                      <Image src={item?.preview}></Image>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          {data?.file?.length > 0 && (
                            <div className="w-50 mb-2">
                              <div
                                className="p-2 border d-flex align-items-center justify-content-center upload-img"
                                style={{ width: "120px", height: "120px" }}
                              >
                                <CloseButton
                                  className="close-btn"
                                  id="deleteFromFiles"
                                  value={data?.file[0].preview}
                                  name="deletebanner"
                                  onClick={(e) =>
                                    handleChange(
                                      e,
                                      "inst_profile",
                                      null,
                                      data?.file[0].preview
                                    )
                                  }
                                />
                                <Image src={data?.file[0].preview}></Image>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-column align-items-start w-30">
                          <h5>Banner</h5>
                          <p className="mb-0 text-middark">
                            Upload your login screen banner for branding
                          </p>
                          <p className="mb-0 text-middark">
                            You may upload a maximum of 4 banners
                          </p>
                          {/* <a href="" className="mt-0">click here to resize  image</a> */}
                        </div>
                        {data.inst_profile?.banner_images?.length +
                          (data?.banner_images
                            ? data?.banner_images?.length
                            : 0) <
                          4 && (
                          <div className="d-flex-column">
                            <div className="d-flex">
                              <div className="d-flex justify-content-center form-control-wrap">
                                <FileUploadNew
                                  noImage={true}
                                  filetype="image"
                                  onChange={(updatedFiles) => {
                                    if (
                                      data.inst_profile?.banner_images?.length +
                                        (data?.banner_images
                                          ? data?.banner_images?.length
                                          : 0) >
                                      5
                                    ) {
                                      Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: "Max limit Four",
                                      });
                                    } else {
                                      // setData({
                                      //   ...data,
                                      //   banner_images: updatedFiles,
                                      // });
                                      updateBannerImages(updatedFiles);
                                    }
                                  }}
                                  name
                                  iconName="upload"
                                  // aspectRatioCalculate={1}
                                  aspectRatio="Recommended Aspect ratio 1:1"
                                  extensions={"Image must be JPG/JPEG or PNG"}
                                />
                              </div>
                            </div>
                            <Button
                              onClick={() =>
                                window.open(
                                  "https://www.canva.com/instagram-posts/templates/",
                                  "_blank"
                                )
                              }
                              variant="primary"
                              size="sm"
                              className="mt-2  "
                              style={{
                                marginLeft: "123px",
                                backgroundColor:
                                  domainData?.inst_profile?.color_code,
                              }}
                            >
                              {" "}
                              Design Free banners{" "}
                            </Button>
                          </div>
                        )}
                        <div className="w-10"></div>
                      </div>
                      <hr />
                      <div className="d-flex mb-3 align-items-center">
                        <div className="d-flex w-30 flex-wrap">
                          {data.inst_profile?.internal_banner_images?.length >
                            0 &&
                            data.inst_profile?.internal_banner_images.map(
                              (item) => {
                                return (
                                  <>
                                    <div className="w-50 mb-2">
                                      <div
                                        className="p-2 border d-flex align-items-center justify-content-center upload-img"
                                        style={{
                                          width: "120px",
                                          height: "120px",
                                        }}
                                      >
                                        <CloseButton
                                          id="deleteFromFiles"
                                          className="close-btn"
                                          value={item.url}
                                          name="deleteInternalbanner"
                                          onClick={(e) =>
                                            handleChange(
                                              e,
                                              "inst_profile",
                                              null,
                                              item.url
                                            )
                                          }
                                        />
                                        <Image src={item?.url}></Image>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          {data?.internal_banner_images?.length > 0 &&
                            data?.internal_banner_images.map((item, index) => {
                              return (
                                <>
                                  <div className="w-50 mb-2">
                                    <div
                                      className="p-2 border d-flex align-items-center justify-content-center upload-img"
                                      style={{
                                        width: "120px",
                                        height: "120px",
                                      }}
                                    >
                                      <CloseButton
                                        id="deleteFromFiles"
                                        className="close-btn"
                                        value={item.preview}
                                        name="deletebanner"
                                        onClick={(e) => {
                                          let remove =
                                            data?.internal_banner_images.filter(
                                              (item, i) => i !== index
                                            );
                                          setData({
                                            ...data,
                                            internal_banner_images: remove,
                                          });
                                        }}
                                      />
                                      <Image src={item?.preview}></Image>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          {data?.file2?.length > 0 && (
                            <div className="w-50 mb-2">
                              <div
                                className="p-2 border d-flex align-items-center justify-content-center upload-img"
                                style={{ width: "120px", height: "120px" }}
                              >
                                <CloseButton
                                  className="close-btn"
                                  id="deleteFromFiles"
                                  value={data?.file2[0].preview}
                                  name="deleteInternalbanner"
                                  onClick={(e) =>
                                    handleChange(
                                      e,
                                      "inst_profile",
                                      null,
                                      data?.file2[0].preview
                                    )
                                  }
                                />
                                <Image src={data?.file2[0].preview}></Image>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-column align-items-start w-30">
                          <h5>Student Dashboard Banner</h5>
                          <p className="mb-0 text-middark">
                            Upload your student dashboard banner for branding
                          </p>
                          <p className="mb-0 text-middark">
                            You may upload a maximum of 4 bannersrs
                          </p>
                          {/* <a href="" className="mt-0">click here to resize  image</a> */}
                        </div>
                        {data.inst_profile?.internal_banner_images?.length +
                          (data?.internal_banner_images
                            ? data?.internal_banner_images?.length
                            : 0) <
                          4 && (
                          <div className="d-flex-column">
                            <div className="d-flex ">
                              <div className="d-flex justify-content-center form-control-wrap">
                                <FileUploadNew
                                  noImage={true}
                                  filetype="image"
                                  onChange={(updatedFiles) => {
                                    if (
                                      data.inst_profile?.internal_banner_images
                                        ?.length +
                                        (data?.internal_banner_images
                                          ? data?.internal_banner_images?.length
                                          : 0) >
                                      5
                                    ) {
                                      Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: "Max limit Four",
                                      });
                                    } else {
                                      // setData({
                                      //   ...data,
                                      //   banner_images: updatedFiles,
                                      // });
                                      updateBannerImages(
                                        updatedFiles,
                                        "internal_banner_images"
                                      );
                                    }
                                  }}
                                  name
                                  iconName="upload"
                                  aspectRatioCalculate={4}
                                  aspectRatio="Recommended Aspect ratio 4:1"
                                  extensions={"Image must be JPG/JPEG or PNG"}
                                />
                              </div>
                            </div>

                            <Button
                              onClick={() =>
                                window.open(
                                  "https://www.canva.com/linkedin-banners/templates/",
                                  "_blank"
                                )
                              }
                              variant="primary"
                              size="sm"
                              className="mt-2  "
                              style={{
                                marginLeft: "123px",
                                backgroundColor:
                                  domainData?.inst_profile?.color_code,
                              }}
                            >
                              {" "}
                              Design Free banners{" "}
                            </Button>
                          </div>
                        )}
                        <div className="w-10"></div>
                      </div>

                      <hr />

                      {/* <div className="d-flex flex-column flex-md-row gap-5 align-items-center py-4">
                    <div>
                    <div className="d-flex- flex-column align-items-center justify-content-center">
                      <h5>Brand Color</h5>
                      <p className="mb-0 text-middark">
                        Choose your brand color for panel.
                      </p>
                      <a href="" className="mt-0">
                        <Icon name="video-fill"></Icon> <span>Watch Now</span>
                      </a>
                    </div>
                    <Form.Group className="form-group">
                      <div className="form-control-wrap">

                      <ColorPicker setData={setData} data={data}/>
                    
                      </div>
                    </Form.Group>

               
                  </div>
                  <div className="d-flex text-secondary">
                    <div>
                    <p>Enqruiry Form OTP Feature</p>
                    <p> This feature allows you to verify mobile numbers using an OTP in the enquiry Form.</p>
                 

                 <p>Note : This is a paid feature. you can charge ₹ 2 per OTP message, and the charge will be reflected in the credit History</p>
                 </div>
                 <div>

              <div className="d-flex gap-2">
              <div>{data?.enquiry_otp_deduction ?"ON":"OFF"} </div> <div>    <Form.Check type="switch" id="flexSwitchChecked"
                  name='enquiry_otp_deduction'
                  onChange={(e) => handleChange(e, "check")}
                  checked={data?.enquiry_otp_deduction}

                />
                </div>
              </div>
          
                 </div>

                  </div>

                  </div> */}

                      <div className="d-flex flex-column flex-md-row gap-2 align-items-center py-4">
                        <div class="w-40 d-flex gap-4 align-items-center">
                          <div className="d-flex- flex-column align-items-center justify-content-center">
                            <h5>Brand Color</h5>
                            <p className="mb-0 text-middark">
                              Choose your brand color for panel.
                            </p>
                            <a href="" className="mt-0">
                              <Icon name="video-fill"></Icon>{" "}
                              <span>Watch Now</span>
                            </a>
                          </div>
                          <Form.Group className="form-group">
                            <div className="form-control-wrap">
                              <ColorPicker
                                setData={setData}
                                data={data}
                                setColorPickError={setColorPickError}
                                colorPickError={colorPickError}
                              />

                              {/* <Form.Control
                          type="color"
                          value={data?.inst_profile?.color_code}
                          name="color_code"
                          onChange={(e) => handleChange(e, "inst_profile")}
                        /> */}
                            </div>
                          </Form.Group>
                        </div>
                        {currentInstitutePlan?.enquiry_form_otp_paid &&
                        <div className="d-flex text-secondary w-60 align-items-center gap-3">
                          <div class="w-80">
                            <p>Enquiry Form OTP Feature</p>
                            <p>
                              {" "}
                              This feature allows you to verify mobile numbers
                              using an OTP in the enquiry Form.
                            </p>
                            <p>
                              Note : This is a paid feature. you can charge ₹ 2
                              per OTP message, and the charge will be reflected
                              in the credit History
                            </p>
                          </div>
                          <div class="w-20">
                            <div className="d-flex gap-2">
                              <div>                               
                                {data?.enquiry_otp_deduction ? "ON" : "OFF"}{" "}
                              </div>{" "}
                              <div>
                                {" "}
                                <Form.Check
                                  type="switch"
                                  id="flexSwitchChecked"
                                  name="enquiry_otp_deduction"
                                  onChange={(e) => handleChange(e, "check")}
                                
                                  checked={data?.enquiry_otp_deduction}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
}
                      </div>

                      <hr></hr>
                      <Row className="g-gs">
                        <Col md="12">
                          <h5>Website</h5>
                        </Col>
                        {admin_profile && (
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlInputText1">
                                Edit your Domain Name.
                              </Form.Label>
                              <div className="form-control-wrap">
                                {admin_profile && (
                                  <Form.Control
                                    type="text"
                                    id="username"
                                    value={data?.domain_name}
                                    name="domain_name"
                                    onChange={(e) => handleChange(e)}
                                    placeholder="website link"
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                        )}
                        <Col md="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="exampleFormControlInputText1">
                              Instagram Link
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                id="username"
                                onChange={(e) =>
                                  handleChange(e, "inst_profile")
                                }
                                name="inst_business_instagram"
                                value={
                                  data?.inst_profile?.inst_business_instagram
                                }
                                placeholder="instagram link"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="exampleFormControlInputText1">
                              Facebook Link
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                onChange={(e) =>
                                  handleChange(e, "inst_profile")
                                }
                                name="inst_business_facebook"
                                value={
                                  data?.inst_profile?.inst_business_facebook
                                }
                                id="username"
                                placeholder="Facebook link"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="exampleFormControlInputText1">
                              Youtube Link
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                onChange={(e) =>
                                  handleChange(e, "inst_profile")
                                }
                                name="inst_business_linkedin"
                                value={
                                  data?.inst_profile?.inst_business_linkedin
                                }
                                id="username"
                                placeholder="LinkedIn link"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="exampleFormControlInputText1">
                              Helpline Number
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                className="mt-1"
                                onChange={(e) => handleChange(e, "inst_profile")}
                                name="inst_business_helpline_number"
                                value={data?.inst_profile?.inst_business_helpline_number}
                                id="username"
                                placeholder="Helpline Number"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        {/* 
                        // accoridng to client requirements
                              
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">Country</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              required
                              id="selectcountry"
                              value={data?.inst_profile?.inst_business_country}
                              aria-label="Default select example"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_country"
                            >
                              <option></option>
                              {countryList.length > 0 &&
                                countryList.map((item) => {
                                  return (
                                    <>
                                      <option value={item.text}>
                                        {item.text}
                                      </option>
                                    </>
                                  );
                                })}
                            </Form.Select>
                          </div>
                        </Form.Group>
                      </Col> 
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">Website Url</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="website"
                              value={data?.inst_profile?.inst_business_url}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_url"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">Facebook Url</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="facebook"
                              value={data?.inst_profile?.inst_facebook_url}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_facebook_url"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">Street Address</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="address"
                              value={data?.inst_profile?.inst_business_address}
                              placeholder="Street Address"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_address"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">City</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              id="city"
                              value={data?.inst_profile?.inst_business_city}
                              placeholder="City"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_city"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">State</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              value={data?.inst_profile?.inst_business_state}
                              id="state"
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_state"
                              placeholder="State/Province"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="exampleFormControlInputText1">Postal Code</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="number"
                              id="pincode"
                              value={data?.inst_profile?.inst_business_postal_code}
                              onChange={(e) => handleChange(e, "inst_profile")}
                              name="inst_business_postal_code"
                              placeholder="Postal Code"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      */}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="custom-team">
                      <TeamDataTable
                        tableClassName="data-table-head-light table-responsive team-edit"
                        inst_id={inst_id}
                        data={teamData}
                        columns={TeamColumns}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="custom-branch">
                      <BranchAdminDataTable
                        inst_id={inst_id || userData?._id}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="plan-price">
                      {Object.values(institutePlanPrice)?.length > 0 ? (
                        <InstitutePlanPrice institutePlanPrice={institutePlanPrice} Instdata={data}/>
                      ) : (
                        // <div className="text-secondary">No plan buy for this institute</div>
                        <Alert className="mt-4" variant="danger">
                          No plan buy for this institute
                        </Alert>
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="tickets">
                      <TicketInstAdminDataTable
                        inst_id={inst_id || userData?._id}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>

                {activeKey != "custom-team" && activeKey != "plan-price" && (
                  <div className="d-flex justify-content-center my-5">
                    <Button
                      variant="primary"
                      className="w-25 mx-auto"
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Block>
      </Layout>
    </>
  );
}

export default AdminDashboard;

