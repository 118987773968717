// import React, { useContext, useEffect, useState } from "react";
// import { Nav, Tab } from "react-bootstrap";
// import DataTable from "react-data-table-component";
// import DataTablePagination from "../../components/Pagination/DataTablePagination";
// import StudentFilter from "./StudentFilter";
// import Export from "../enquiry/Export";
// import { Link } from "react-router-dom";
// import { Icon } from "../../components";
// import { background } from "@chakra-ui/react";
// import AuthApi from "../../api/auth";
// import Context from "../../context";

// import { Card, Modal, Button } from 'react-bootstrap';
// const rowSelectCritera = (row) => row?.createdAt;
// const StudentSelectedFilterTab = ({
//   studentDetailsColumns,
//   tableData,
//   showItemPerPage,
//   setShowItemPerPage,
//   studentData,
//   setTabledata,
//   selectableRows,
  
// }) => {
//   let { userData } = useContext(Context);
//   userData = JSON.parse(userData);
//   let { _id } = userData;
//   console.log("_id :>> ", _id);

//   const [selectedTab, setSelectedTab] = useState({
//     all: true,

//     data: "",
//   });
//   const [saveAction,setSaveAction] = useState(false)

//   const [selectedRow, setSelectedRow] = useState([]);
//   const [slectedRowFilter,setSelectedRowFiler]=useState([]);
//   const [demoModal, setDemoModal] = useState(false);

//   const demoModalClose = () =>{

//     setDemoModal(false);
    
//   }
  

//   const saveHandler=()=>{
//     setSaveAction(!saveAction)
//     setDemoModal(false)
//   }

//   const activeClickHandler = () => {
//     setSelectedTab({
//       isactive: "true",
//       inst_id: _id,
//       isDeleted: "false",
//       isextend_validity: "false",
//     });

//     setDemoModal(true)
//   };

//   const extendClickHandler = () => {
//     setSelectedTab({
//       isactive: "false",
//       inst_id: _id,
//       isDeleted: "false",
//       isextend_validity: "true",
//     });
//     setDemoModal(true)
//   };

//   const inActiveClickHandler = () => {
//     setSelectedTab({
//       isactive: "false",
//       inst_id: _id,
//       isDeleted: "false",
//       isextend_validity: "false",
//     });
//     setDemoModal(true)
//   };

//   const deleteClickHandler = () => {
//     setSelectedTab({
//       isactive: "false",
//       inst_id: _id,
//       isDeleted: "true",
//       isextend_validity: "false",
//     });
//     setDemoModal(true)
//   };

//   let api = new AuthApi();

//   const filterData = async () => {
//     const filteredData = studentData?.filter((item) => {
//       return (
//         !selectedTab?.data ||
//         (item?.status != null &&
//           item?.status?.toLowerCase() === selectedTab?.data?.toLowerCase())
//       );
//     });
//     console.log(
//       "filteredData :>> ",
//       filteredData,
//       selectedTab?.data == "",
//       "dataa"
//     );
//     if (selectedTab?.data == "") {
//       setTabledata(studentData);
//     } else {
//       setTabledata(filteredData);
//     }
//   };

//   const student_filter = async () => {
//     await api
//       .student_filter({
//         inst_id: selectedTab?.inst_id,
//         isDeleted: selectedTab?.isDeleted,
//         isactive: selectedTab?.isactive,
//         isextend_validity: selectedTab?.isextend_validity,
//       })
//       .then((res) => {
//         if (res?.data?.length > 0) {
//           setSelectedRowFiler(res?.data);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   useEffect(() => {
//     student_filter();
//   }, [saveAction]);

//   const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
//     <div className="form-check" id={rest.name}>
//       <input
//         type="checkbox"
//         className="form-check-input"
//         ref={ref}
//         onClick={onClick}
//         {...rest}
//       />
//     </div>
//   ));

//   const slectedRowHandler = (state) => {
//     console.log("state :>> ", state);
//     setSelectedRow(state?.selectedRows);
//   };

//   console.log("tickTab ", selectedTab);

//   console.log(slectedRowFilter, "slectedRowFilter?.length");
//   return (
//     <div>
//       <div className="d-flex gap-2 mb-2">
//             <Button variant="info" onClick={() => activeClickHandler()}>
//             Active
//             </Button>

//             <Button variant="info" onClick={() => inActiveClickHandler()}>
//             InActive
//             </Button>
           

//             <Button variant="info" onClick={() => extendClickHandler()}>
//             Extend Validity
//             </Button>

//             <Button variant="info" onClick={() => deleteClickHandler()}>
//             Delete
//             </Button>
//             </div>
            

//             <Modal show={demoModal} onHide={demoModalClose}>
//               <Modal.Header closeButton>
//                 <Modal.Title></Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//                 {
//                selectedTab?.isactive=="true" && "Do you want to active all selected record"
//                 }
//                 {
//                  selectedTab?.isactive=="false" &&
//                   "Do you want to inactive all selected record"
//                 }
                 
//                  {/* {
//                  selectedTab?.isDeleted=="true" &&
//                   "Do you want to delete all selected record"
//                 } */}

                
//               </Modal.Body>
//               <Modal.Footer>
//                 <Button size="sm" variant="secondary" onClick={demoModalClose}>
//                   Close
//                 </Button>
//                 <Button size="sm" variant="primary" onClick={saveHandler}>
//                   Save Changes
//                 </Button>
//               </Modal.Footer>
//             </Modal>
      
     
//     </div>
//   );
// };

// export default StudentSelectedFilterTab;



import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import StudentFilter from "./StudentFilter";
import Export from "../enquiry/Export";
import { Link } from "react-router-dom";
import { Icon } from "../../components";
import { background } from "@chakra-ui/react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import {  useNavigate, useParams } from "react-router-dom";
import { Card, Modal, Button,Form } from 'react-bootstrap';
import Swal from "sweetalert2/src/sweetalert2";
const rowSelectCritera = (row) => row?.createdAt;
const StudentSelectedFilterTab = ({
  studentDetailsColumns,
  tableData,
  showItemPerPage,
  setShowItemPerPage,
  studentData,
  setTabledata,
  selectableRows,
  tableSelectedRow,
  get_student_by_inst,
  
}) => {
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  let { _id } = userData;
  console.log("_id :>> ", _id);

  const [selectedTab, setSelectedTab] = useState({
    
  });
  const [saveAction,setSaveAction] = useState(false)

  const [selectedRow, setSelectedRow] = useState([]);
  const [slectedRowFilter,setSelectedRowFiler]=useState([]);
  const [demoModal, setDemoModal] = useState(false);
  console.log('tableSelectedRow :>> ', tableSelectedRow);

  let selectedIdArr=new Array()
  tableSelectedRow?.map((item)=>{

    selectedIdArr.push(item?._id)


  })
  console.log('ids :>> ', selectedIdArr);
  const [validityDay, setValidityDay] = useState({});

  const handleChange = (e) => {
    
    let { value, name } = e.target;
  

   setValidityDay({ [name]: value }); 
    
    setSelectedTab((prev)=>({...prev,
      // action:"validity",
      // status:validityDay?.validity,
      status:Number(value),
     
    }))
    
  };

  console.log('validityDay ', validityDay?.validity);
  const demoModalClose = () =>{

    setDemoModal(false);
    
  }
  
  

  const saveHandler=()=>{
    setSaveAction(!saveAction)
    setDemoModal(false)
  }

  // const activeClickHandler = () => {
  //   setSelectedTab({
  //     action:"isActive",
  //     status:true,
  //     user_id:selectedIdArr
  //   });
  //   setDemoModal(true)
 
  // };
  const activeClickHandler = async (e) => {
    e.preventDefault();
  
    // console.log(creditSpent);
    Swal.fire({
      title:"Are you sure you want to active selected students? ",
 
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'


    }).then(async(result)=>{
      if(result.value){


          api
          .update_user_action( {    
            action:"isActive",
            status:true,
            user_id:selectedIdArr
           })

          

          .then((res) => {
            if (res?.status) {
            
              console.log("res", res);
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res?.message,
              })
              get_student_by_inst()
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
  
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
          
        
          
        
        
        }
      // else {
      //   Swal.fire('Cancelled', 'No selected students has been active', 'info')
      // }
    })

  
  };

  // const extendClickHandler = () => {
  //   setSelectedTab({
  //     action:"validity",
  //     // status:validityDay?.validity,
  //     status:validityDay?.validity,
  //     user_id:selectedIdArr
  //   });
  //   setDemoModal(true)
  // };

  var inputOptionsPromise = new Promise(function (resolve) {
    // get your data and pass it to resolve()
    setTimeout(function () {
      resolve({
        
     
        "7": '7',
        '14': '14',
        '21': '21',
        "30":"Full"
      })
    }, 2000)
  })

  
  const extendClickHandler= async (e) => {
    e.preventDefault();
    const { value: days } = await Swal.fire({
      title: "Please select number of days to extend",
      input: "select",
      inputOptions: {
       
          7: '7',
        14: '14',
        21: '21',
        30:"Full"
      },
      inputPlaceholder: "Select days",
      showCancelButton: true,
     
    });
    console.log('value :>> ', days);
    if (days) {
      api
          .update_user_action( {    

            action:"validity",
            // status:validityDay?.validity,
            // status:validityDay?.validity,
            status:Number(days),
            user_id:selectedIdArr
           })

          

          .then((res) => {
            if (res?.status) {
            
              console.log("res", res);
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res?.message,
              })
              get_student_by_inst()
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
  
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
    
    }
    // else {
    //   Swal.fire('Cancelled', 'No selected student has been extend validity', 'info')
    // }
  };

 

  // const inActiveClickHandler = () => {
  //   setSelectedTab({
  //     action:"isActive",
  //     status:false,
  //     user_id:selectedIdArr
  //   });
  //   setDemoModal(true)
  // };
  const inActiveClickHandler = async (e) => {
    e.preventDefault();
  
    // console.log(creditSpent);
    Swal.fire({
      title:"Are you sure you want to inactive selected students? ",
 
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

    }).then(async(result)=>{
      if(result.value){


          api
          .update_user_action( {    

                  action:"isActive",
      status:false,
      user_id:selectedIdArr
           })

          

          .then((res) => {
            if (res?.status) {
            
              console.log("res", res);
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res?.message,
              })
              get_student_by_inst()
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
  
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
          
        
          
        
        
        }
      // else {
      //   Swal.fire('Cancelled', 'No selected student has been inactive', 'info')
      // }
    })

  
  };

  // const deleteClickHandler = () => {
  //   setSelectedTab({
  //     action:"isDeleted",
  //     status:1,
  //     user_id:selectedIdArr
  //   });
  //   setDemoModal(true)
  // };



   const deleteClickHandler = async (e) => {
    e.preventDefault();
  
    // console.log(creditSpent);
    Swal.fire({
      title:"Are you sure you want to delete selected students? ",
      text: "Once deleted,data and credit cannot be revised!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

    }).then(async(result)=>{
      if(result.value){


          api
          .update_user_action( {     action:"isDeleted",
            status:1,
              user_id:selectedIdArr })

          

          .then((res) => {
            if (res?.status) {
            
              console.log("res", res);
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res?.message,
              })
              get_student_by_inst()
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
  
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
          
        
          
        
        
        }
      // else {
      //   Swal.fire('Cancelled', 'No selected student has been deleted', 'info')
      // }
    })

  
  };
  let api = new AuthApi();

 

  const update_user_action = async () => {









    await api
      .update_user_action({
        user_id: selectedTab?.user_id,
        status: selectedTab?.status,
        action: selectedTab?.action,
       
      })
      .then((res) => {
        if (res.status) {
         
          get_student_by_inst()
        }

        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.message,
          });
          // navigate("student-management/student-list");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    update_user_action();
  }, [saveAction]);

  const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check" id={rest.name}>
      <input
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
    </div>
  ));

  const slectedRowHandler = (state) => {
    console.log("state :>> ", state);
    setSelectedRow(state?.selectedRows);
  };

  console.log("tickTab ", selectedTab);

  console.log(slectedRowFilter, "slectedRowFilter?.length");
  return (
    <div>
      <div className="d-flex gap-2 mb-2">
            <Button variant="info" onClick={ activeClickHandler}  style={{
                                          backgroundColor: "var(--theme-color)",
                                          cursor: "pointer",
                                            border:"var(--theme-color)"
                                        }}>
            Active
            </Button>

            <Button variant="info" style={{
                                          backgroundColor: "var(--theme-color)",
                                          cursor: "pointer",
                                            border:"var(--theme-color)"
                                        }} onClick={(e) => inActiveClickHandler(e)}>
            InActive
            </Button>
           

            <Button variant="info" style={{
                                          backgroundColor: "var(--theme-color)",
                                          cursor: "pointer",
                                            border:"var(--theme-color)"
                                        }} onClick={extendClickHandler}>
            Extend Validity
            </Button>

            <Button variant="info"  style={{
                                          backgroundColor: "var(--theme-color)",
                                          cursor: "pointer",
                                          border:"var(--theme-color)"
                                        }}onClick={(e) => deleteClickHandler(e)}>
            Delete
            </Button>
            </div>
            

            <Modal show={demoModal} >
              <Modal.Header >
              {
                 selectedTab?.status ===1 &&
                 <Modal.Title>
                 Do you want to delete selected records
                 </Modal.Title>
               
                }
              </Modal.Header>
              <Modal.Body>
                {
               selectedTab?.status=== true &&
               <div className="text-secondary">
                Do you want to active selected records
                </div>
                }
                {
                 selectedTab?.status===false &&
                 <div className="text-secondary">
                  Do you want to inactive selected records
                  </div>
                }
                 
                 {
                 selectedTab?.status ===1 &&
                    <div className="text-secondary">
                 Do you want to delete selected records
                 </div>
                }
                {
                  selectedTab?.action==="validity" &&
                  <div className="d-flex align-items-center">
                  <span className="w-70 text-secondary ">Please select number of days to extend</span>
                  <Form.Select className="w-30"
                  id="validity"
                 
                  onChange={(e) => handleChange(e)}
                  name="validity"
               
                >
                    

                  <option value="">Select</option>
                  <option value="7">7</option>
                  <option value="14">14</option>
                  <option value="21">21</option>
                  <option value="30">Full</option>
                 
                </Form.Select>
                </div>
                }

                
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={demoModalClose}>
                  Close
                </Button>
                <Button size="sm" variant="primary" onClick={saveHandler}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
      
     
    </div>
  );
};

export default StudentSelectedFilterTab;



// import React, { useCallback, useState } from 'react';

// // import DataTable from '../../../src/index';
// // import data from '../../constants/sampleDesserts';
// import DataTable from "react-data-table-component";


// const StudentColumns = [

//   // {
//   //   name: <Form.Check className="form-check" id="check-all" />,
//   //   cell: (row) => (
//   //     <div className="text-start w-100">
//   //       <Form.Check className="form-check" />
//   //     </div>
//   //   ),
//   //   sortable: false,
//   // },
//   // {
//   //   name: "SRNo",
//   //   cell: (row, index) => (
//   //     <span className="text-middark">{index + 1}</span>
//   //   ),
//   //   sortable: false,
//   // },
  
//   {
//     name: "Name",
//     grow: "3",
//     selector: (row) => row.product,
//     cell: (row) => <span className="text-middark">{row.name}</span>,
//     sortable: true,
//   },
//   {
//     name: "Product",
//     grow: "3",
//     selector: (row) => row.product,
//     cell: (row) => <span className="text-middark">{row.product}</span>,
//     sortable: true,
//   },
//   {
//     name: "Mobile",
//     grow: "2",
//     selector: (row) => row.username,
//     cell: (row) => <span className="text-middark">{row.mobile}</span>,
//     sortable: true,
//   },
//   // {
//   //   name: "Student Type",
//   //   selector: (row) => row.studenttype,
//   //   cell: (row) => <span className="text-middark">{row.studenttype}</span>,
//   //   sortable: true,
//   //   hide: "lg",
//   // },
//   {
//     name: "Teachers",
//     grow: "3",
//     selector: (row) => row.assigned,
//     cell: (row) => <span className="text-middark">{row?.stu_profile?.assignTeacher}</span>,
//     sortable: true,
//   },
//   {
//     name: "Batch",
//     grow: "3",
//     selector: (row) => row.attempt,
//     cell: (row) => <span className="text-middark">{row?.stu_profile?.batchTime[0]?.starttime +" to "+row?.stu_profile?.batchTime[0]?.endtime}</span>,
//     sortable: true,
//   },
//   {
//     name: "Test",
//     grow: "3",
//     selector: (row) => row.score,
//     cell: (row) => <span className="text-middark">{row?.stu_profile?.mockTest}</span>,
//     sortable: true,
//   },
//   {
//       name: "status",
//       selector: (row) => row.status,
//       cell: (row) => (
//           // <span className={`badge text-bg-${row.status === "Active" ? "success"
//           //     : row.status === "Inactive" ? "danger"
//           //         : "primary"}`
//           // }>
//           <span className={`badge text-bg-${row.isActive  ? "success"
//               : "danger"}`
//           }>
//               {row.isActive ? "Active" : 'Inactive'}
//           </span>
//       ),
//       sortable: true,
//   },
  
// ];
// const rowSelectCritera = row =>console.log('row.fat  :>> ', row )



//  const StudentSelectedFilterTab = ({tableData}) => (

  
// 	<DataTable
// 		title="Desserts"
// 		columns={StudentColumns}
// 		data={tableData}
// 		selectableRows
// 		selectableRowSelected={rowSelectCritera}
//     onSelectedRowsChange={slectedRowHandler}
// 		pagination
// 	/>
// );
// export default StudentSelectedFilterTab



// const rowSelectCritera = row =>row?.createdAt
// const StudentSelectedFilterTab = ({tableData}) => {
//   const [selectedRow, setSelectedRow] = useState([])

//   const slectedRowHandler=(state)=>{
//     console.log('state :>> ', state);
//     setSelectedRow(state?.selectedRows)
//   }
//   console.log('4234234 :>> ', selectedRow);

//   const selectedCriteria = useCallback((row, index) => {
//     // console.log(row._id,index,"parm",arr,arr.includes(row._id ));
//     // // if(arr?.length>0){
//     // //   for(let i=0;i<arr?.length;i++){
//     // //     if(arr[i]._id==row._id){
//     // //       console.log('11111  true :>> ', arr[i]._id ,row._id);
//     // //       return true;
//     // //     } else{
//     // //       console.log('11111 false :>> ', arr[i]._id,row._id);
//     // //       return false
//     // //     } 

//     // //   }
      
//     // //   }
      
    
//     if (row?.createdAt) {
//       return true;
//     } else {
//       return false;
//     }

    
//   },[]);

//   return (
//     <div>
//       	<DataTable
// 		title="Desserts"
// 		columns={StudentColumns}
// 		data={tableData}
// 		selectableRows
// 		selectableRowSelected={selectedCriteria}
//     onSelectedRowsChange={slectedRowHandler}
// 		pagination
// 	/>
      
//     </div>
//   )
// }

// export default StudentSelectedFilterTab
