import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import StudentDataTable from "../studentpages/StudentDataTable";
import StudentData, { StudentColumns } from "../studentpages/StudentData";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import { useContext } from "react";
import Context from "../../context";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
function MaterialList() {
  let api = new AdminApi();
  let navigate = useNavigate();
  let { userData } = useContext(Context);
  userData= JSON.parse(userData)
  const [data, setData] = useState([]);
  const [deleteButton, setDelbutton] = useState({
    id: null,
    status: false,
  });
  useEffect(() => {
    get_material();
  }, []);
  let studentLogin = userData.login_type == "2"
  let instituteLogin = userData.login_type == "1"
  const get_material = async () => {
    await api
      .get_material(instituteLogin? (userData?.teacher_profile?.inst_id || userData?._id):userData?.stu_profile?.inst_id)
      .then((res) => {
        if (res?.data?.length > 0) {
          if(studentLogin){
            setData(res?.data.filter((item)=>item.status =="Active").length?res?.data.filter((item)=>item.status =="Active").reverse():[])
          }else{
          setData(res?.data.reverse());
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const delete_material = async () => {
    await api
      .delete_material(deleteButton?.id)
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          setData(res?.data.reverse());
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: res.message,
          });
          setData(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function formatDate(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Months array to get the three-letter abbreviation
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get day, month abbreviation, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  const inActiveMaterial = async (e, id) => {
    let { name, checked } = e.target;
    const active = checked ? "Active" : "Inactive";
    const formData = new FormData();
    formData.append('status', active);
    formData.append('id', id);
  
    try {
      const response = await api.add_material(formData);
  
      if (response.status) {
        if(checked){
        Swal.fire({
          icon: "success",
          title: "Good job!",
          text: "Activated Successfully",
        });
        }else{
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: "Deactivated Successfully",
          });
        }
        // Assuming response.status indicates success
        get_material();
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Material List</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
   {instituteLogin &&         <Col md="6" className="mb-3 justify-content-end align-items-end">
              <button
                className="btn btn-primary"
                onClick={() => navigate("/material-management/add-material")}
              >
                Add Materials
              </button>
            </Col>}
            <Card>
              <Card.Body>
                {data?.length > 0 ? (
                  <Table responsive striped bordered hover className="small">
                    <thead>
                      <tr className="bg-primary">
                        <th className="text-white">SNo</th>
                        <th className="text-white">Product</th>
                        <th className="text-white">Name</th>
                        <th className="text-white">Type</th>
                        <th className="text-white">Created At</th>
                        { instituteLogin &&      <th className="text-white">Status</th>}
                        <th className="text-white">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 &&
                        data.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.product}</td>
                                <td>{item?.name}</td>
                                <td>{item?.type}</td>
                                <td>{formatDate(item?.createdAt)}</td>
                                { instituteLogin &&      <td>
                                  {" "}
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input ms-3"
                                      type="checkbox"
                                      role="switch"
                                      value={item.status=="Active"?true:false}
                                      checked={item.status=="Active"?true:false}
                                      onChange={(e) =>
                                        inActiveMaterial(e, item._id)
                                      }
                                      id="directMessage"
                                      name="active"
                                    />
                                  </div>
                                </td>}
                                <td>
                                  <div className="text-end w-100">
                                    <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
                                    <div
                                        className="link-list-hover-bg-primary link-list-md d-flex justify-content-end del-btn"
                                        onClick={() => window.open(item?.url?item?.url:item?.link, '_blank')
                                        }
                                      >
                                        <Icon name="eye"></Icon>
                                      </div>
                               {  instituteLogin && (<>
                                <div
                                        className="link-list-hover-bg-primary link-list-md d-flex justify-content-end del-btn"
                                        onClick={() =>
                                          setDelbutton({
                                            ...deleteButton,
                                            status: true,
                                            id: item?._id,
                                          })
                                        }
                                      >
                                        <Icon name="trash"></Icon>
                                      </div>
                                      <div
                                        className="link-list-hover-bg-primary link-list-md d-flex justify-content-end del-btn"
                                        onClick={() =>
                                         navigate(`/material-management/edit-material/${item?._id}`)
                                        }
                                      >
                                        <Icon name="edit"></Icon>
                                      </div>
                               </>)    
                                      }
                              
                                    </LinkList>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                ) : (
                  <span>No Data found</span>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          show={deleteButton?.status}
          onClick={() =>
            setDelbutton({ ...deleteButton, status: false, id: null })
          }
        >
          <div className="modal-content">
            <div className="modal-body p-5 text-center">
              <h3>Are you sure want to delete ?</h3>
              <ul className="d-flex gap g-3 justify-content-center pt-1">
                <li>
                  <Button variant="success" onClick={() => delete_material()}>
                    Yes Delete it!
                  </Button>
                </li>
                <li>
                  <Button
                    variant="danger"
                    className="btn-soft"
                    onClick={() =>
                      setDelbutton({ ...deleteButton, status: false, id: null })
                    }
                  >
                    Cancel
                  </Button>
                </li>
              </ul>
            </div>
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 p-3"
              aria-label="Close"
            ></button>
          </div>
        </Modal>
      </Block>
    </Layout>
  );
}

export default MaterialList;
