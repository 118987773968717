import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import SubmitButton from "../questions/PTE/SubmitButton";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import NotificationContext from '../../context/NotificationContext/NotificationContext';
import Context from '../../context';
import AuthApi from '../../api/auth';
import { createTimestamp } from '../../utilities/timeZones';

const rowSelectCritera = row =>row?.createdAt
const NotificationStudentTable = ({tableData,StudentColumns,showItemPerPage,setShowItemPerPage}) => {
  
  const [selectedRow, setSelectedRow] = useState([])

  const slectedRowHandler=(state)=>{
    console.log('state :>> ', state);
    setSelectedRow(state?.selectedRows)
  }
  console.log('selectesdfsdfdRow :>> ', selectedRow);

  let { userData,domainData } = useContext(Context);
  userData = JSON.parse(userData);

  const navigate = useNavigate();

    
  let formdata = new FormData();
  const [data, setdata] = useState({
    user_id: [],
    sending_type: "now",
    status:"complete",
    inst_id: domainData?._id,
    inst_name: domainData?.inst_profile?.inst_business_name,
    hours:"00:00",
    minutes:"00:00",
  });
  

  let {notificationData,setNotificationData,setNotificationImage,notificationImage}=useContext(NotificationContext)
  console.log('mynotificationData :>> ', notificationData);
  if(notificationData && Object?.keys(notificationData)?.length>0){
  
    console.log('notificationData :>> ',JSON?.parse(notificationData));
  }
  
const sendTicket = async () => {

    // let selectedRow= JSON.parse(localStorage.getItem("selectedRow")).map((ele) => ele._id)
    // let notificationData= JSON.parse(localStorage.getItem("notificationData"))
    // let notificationImage= localStorage.getItem("recent-image")

    console.log('notificationImage :>> ', notificationImage);
    if(notificationData && Object?.keys(notificationData)?.length>0){
    notificationData=JSON?.parse(notificationData)
    let selectedRow1= selectedRow?.map((ele) => ele._id)
     
    setdata({...notificationData,attachment:notificationImage, user_id: selectedRow1})
    
   
  
    console.log('selectedRow32 3:>> ', data);
    }
  };
    
  console.log('selectedRow32 async 3:>> ', data);
  let api = new AuthApi();
  
  useEffect( ()=>{
  
    for (const [key, value] of Object.entries(data)) {
      console.log('data32 :>> ', data);
      formdata.append([key], value)
    }
    
    // console.log("FORMDATA",formdata);
  
   async function create_notification(){
    formdata.append("timestamp",createTimestamp(userData?.timeZone?.split(" ")?.[0], data?.date?.split("-")?.[0], data?.date?.split("-")?.[1], data?.date?.split("-")?.[2], data?.hours, data?.minutes))
    console.log("FORMDATA",formdata);
    await api.create_notification(formdata).then((res) => {
     
      if (res?.status) {
        Swal.fire({
          icon: "success",
          text: res.message,
          confirmButtonText: "Done",
        });
        navigate("/view-notification")
        setNotificationData({})
        setNotificationImage()
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: res.msg,
        });
      }
      console.log("res", res)
     
    }).catch((err) => {
      console.log(err)
    })
  }
  console.log('datauseeff :>> ', data,data?.user_id?.length>0);
  if(data?.user_id?.length>0){
  create_notification()
  }
  
  },[data])
    

  

  return (
    <div>
      	<DataTable
		title="Desserts"
		columns={StudentColumns}
		data={tableData}
		selectableRows
		selectableRowSelected={rowSelectCritera}
    onSelectedRowsChange={slectedRowHandler}
		pagination
        paginationComponent={({
            rowsPerPage,
            rowCount,
            onChangePage,
            onChangeRowsPerPage,
            currentPage,
          }) => (
            <div className="data-table-bottom">
              <DataTablePagination
                showItemPerPage={showItemPerPage}
                itemPerPage={rowsPerPage}
                totalItems={rowCount}
                paginate={onChangePage}
                currentPage={currentPage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                setShowItemPerPage={setShowItemPerPage}

              />
            </div>
          )}
	/>
     {

window.location.pathname == "/student-management/student-list-notification" && 
  <>
   
        <div className="d-flex justify-content-center">
        <SubmitButton
        text="Send"
        onClick={() => sendTicket()}
        loader={false}
      
        />
        </div>
        </>
      }



{/* <DataTable
              columns={StudentColumns}
              data={tableData}
            
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination
            
              selectableRows
            

           
              onSelectedRowsChange={slectedRowHandler}
              paginationComponent={({
                rowsPerPage,
                rowCount,
                onChangePage,
                onChangeRowsPerPage,
                currentPage,
              }) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setShowItemPerPage={setShowItemPerPage}

                  />
                </div>
              )}
            /> */}
      
    </div>
  )
}

export default NotificationStudentTable