import React from "react";
import StatusUI from "./StatusUI";
import { ProgressBar } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../../../../context/ModalContext";
import ProgressBarCustom from "../../CustomProgressbar";
function OnlyRecorderUI({
  startRecording,
  timer,
  NoRecording,
  startAudio,
  data,
  stopRecordings,
  initialTime,
}) {
  let { popUpType, setPopUpType, manageRecordings } = useContext(ModalContext);
  console.log("timer-",timer)
  const [minutes, seconds] = timer?.split(":").map(Number);
  const [time, setTime] = useState({
    minutes: timer.split(":").map(Number)[0],
    seconds: timer.split(":").map(Number)[1],
  });
  const [message, setMessage] = useState("Beginning");
  const [recordeDone, setrecordeDone] = useState(false);
  const [stopRecord, setStopRecord] = useState(false);
  useEffect(() => { 
    if(data.module_name){
    setPopUpType("prepare");
    setMessage("Beginning")
    setTime({...time,minutes: timer.split(":").map(Number)[0],seconds:timer.split(":").map(Number)[1]})
  }
  }, [data]);
  useEffect(() => {
    // setTime({...time,minutes: timer.split(":").map(Number)[0],seconds:timer.split(":").map(Number)[1]})
    // setStopRecord(false) 
    console.log("working")
    if(popUpType=="recordingStopped"){
      return
    }
    const timerInterval = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timerInterval);
        startRecording();
        setrecordeDone(true)
        setPopUpType("default");
        setMessage("Recording...");
      } else if (time.minutes === 0 && time.seconds === 1) {
        setMessage("Beginning in 1 second.");
        setTime({ minutes: 0, seconds: 0 });
      } else if (time.minutes === 0) {
        const newSeconds = Math.max(0, time.seconds - 1);

        setMessage(`Beginning in ${newSeconds} seconds.`);
        setTime({ minutes: 0, seconds: newSeconds });
      } else if (time.minutes !== 0 && time.seconds === 0) {
        setMessage(`Beginning in ${time.minutes} minutes.`);
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      } else {
        const minuteText = time.minutes === 1 ? "m" : "m";
        setMessage(
          `Beginning in ${time.minutes} ${minuteText} ${time.seconds} seconds.`
        );
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [time, timer,data]);
  return (
    <>
      <div className="audio-card my-3 bg-examrecord">
        <p className="text-center mb-0">Recorded Answer</p>

        <p className="mb-0">Current Status :</p>
        <p>{message ? message : stopRecord ? "Completed" : "Recording..."}</p>
        {stopRecord==false && startAudio && recordeDone && popUpType == "default" ? (
          <ProgressBarCustom
            data={data}
            stopRecordings={() => { setStopRecord(true) }}
            initialTime={initialTime}
          />
        ) : (
          <ProgressBar className="progress-ui" variant="hisuccess" now={0} style={{ height: "18px" }} />
        )}
      </div>
    </>
  );
}
export default OnlyRecorderUI;
