import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
function ResultSubmitAnswer({fib_wrAns}) {
  console.log('233fib_wrAns :>> ', fib_wrAns);

//   const res=fib_wrAns?.map((ansitem,index)=>{
//     return ansitem.answer?.split(",").map((ansEle) => {
 
//       return ansEle
//     }
//    )
//    })
// console.log('ResultSubmitAnswerres :>> ', res);
  return (
    <>
    <div className='p-3 text-middark d-flex flex-column align-items-start gap-3'>
        <b>Your Answer : </b>

    
        {/* {
        fib_wrAns?.map((item)=>{
          // console.log("item?.answer?.split",item?.answer?.split(","))
         return item?.answer?.split(",")?.map((ans,index)=>{
         

          return ans === 'true' && index+1 + ", "
          })
          
          })} */}

{
     
          // console.log("item?.answer?.split",item?.answer?.split(","))
          fib_wrAns?.answer?.split(",")?.map((ans,index)=>{
         

          return ans === 'true' && index+1 + ", "
          })
          
          }
        
        
      </div>
    </>
  );
}
export default ResultSubmitAnswer;