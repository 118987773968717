import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Nav,
  ProgressBar,
  Badge,
} from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import { useState, useEffect } from "react";

function PracticeTitleTime({ selected_ques_Obj }) {
  let {module,module_name} =selected_ques_Obj;
    let { timeForPractice, setTimeForPractice } = useContext(ModalContext);
   const [isMicConnected, setIsMicConnected] = useState(false);
  let{prepare,original} = timeForPractice
  let {isRunning} = timeForPractice;
  const [currentTime, setCurrentTime] = useState("00:00");
  const [timeOver, setTimeOver] = useState(false)
let reading = (module == "reading")
  useEffect(() => {
    if(module=="speaking" || module == "reading"){
      setTimeForPractice({ prepare: { status: reading?false:true, time: selected_ques_Obj?.answer_prepare_time?selected_ques_Obj?.answer_prepare_time:"00:02" },
      original: { status: reading?true:false, time: selected_ques_Obj?.answer_time },
      isRunning:true})
      setCurrentTime(selected_ques_Obj?.answer_prepare_time?selected_ques_Obj?.answer_prepare_time:"00:02")
    }
    if(module=="writing"|| module == "listening" ){
      setTimeForPractice({ prepare: { status: false, time: selected_ques_Obj?.answer_prepare_time?selected_ques_Obj?.answer_prepare_time:"00:02" },
      original: { status: true, time: selected_ques_Obj?.answer_time },
      isRunning:true})
      setCurrentTime(selected_ques_Obj?.selected_ques_Obj?.answer_time)
      // setTimeForPractice({time:selected_ques_Obj?.answer_prepare_time?selected_ques_Obj?.answer_prepare_time:"00:02",
      // status:"prepare",stop:false})

    }
  //   async function checkMicStatus() {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //       });
  //       if (stream) {
  //         setIsMicConnected(true);
  //         stream.getTracks().forEach((track) => track.stop());
  //       }
  //     } catch (error) {
  //       setIsMicConnected(false);
  //     }
  //   }

  //   checkMicStatus();
  }, [selected_ques_Obj]);
  useEffect(()=>{
    if(original.status ){
      if(module=="writing" || module_name=="ssts"){
      setCurrentTime(selected_ques_Obj.answer_time)
    }else{
        setCurrentTime("00:00")
      }
    }else if(prepare?.status){
      setCurrentTime(prepare?.time)
    }
  },[timeForPractice])
  console.log("timeForPractice",timeForPractice)
  useEffect(() => {
   let timer;
   if (isRunning) {
     timer = setInterval(() => {
      if(module=="writing"){
        setCurrentTime(prevTime => {
          if (prevTime === "00:00") {
            clearInterval(timer);
            setTimeForPractice(prevState => ({
             ...prevState,
             isRunning:false
           }))
           setCurrentTime("00:00")
            return "00:00";
          } else {
            const [minutes, seconds] = prevTime.split(":").map(Number);
            let newSeconds = seconds - 1;
            let newMinutes = minutes;
            if (newSeconds < 0) {
              newSeconds = 59;
              newMinutes -= 1;
            }
            return `${String(newMinutes).padStart(2, "0")}:${String(newSeconds).padStart(2, "0")}`;
          }
        });
        return
      }
      if(prepare.status){
        setCurrentTime(prevTime => {
          if (prevTime === "00:00") {
            clearInterval(timer);
            setTimeForPractice(prevState => ({
             ...prevState,
             prepare: { ...prevState.prepare, status: false },
             original: { ...prevState.original, status: true },
             isRunning:true
           }))
           setCurrentTime("00:00")
            return "00:00";
          } else {

           
            
            const [minutes, seconds] = prevTime.split(":").map(Number);
            var time = 60
            time = minutes*60+seconds
            console.log("TIME",time);
            let newSeconds = seconds - 1;
            let newMinutes = minutes;
            if (newSeconds < 0) {
              newSeconds = 59;
              newMinutes -= 1;
            }

            if(time <= 1){
              if(window.location.pathname.includes("/practice/pte/read_alouds") || window.location.pathname.includes("/practice/pte/describe_images")){
                document?.getElementsByClassName('btn btn-transparent btn-lg')[0]?.click()
              }
            }
            return `${String(newMinutes).padStart(2, "0")}:${String(newSeconds).padStart(2, "0")}`;
          }
        });
      }
      if(original.status){
      setCurrentTime(prevTime => {
        const [minutes, seconds] = prevTime.split(":").map(Number);
        let newSeconds = seconds + 1;
        let newMinutes = minutes;
        if (newSeconds > 59) {
          newMinutes += 1;
          newSeconds = 0
        }
        return `${String(newMinutes).padStart(2, "0")}:${String(newSeconds).padStart(2, "0")}`;
     //  }}
    });
  }
     }, 1000);
   }
    return () => clearInterval(timer);
  }, [timeForPractice]);

  console.log("CURRENT TIME",currentTime);
  console.log("IS RUNNING", isRunning);
  console.log("PREPARESTATUS",prepare.status);
  // useEffect(() => {
  //   if(currentTime == "00:00" && prepare.status == false){
  //     document.getElementsByClassName('btn btn-transparent btn-lg')[0].click()
  //     setTimeOver(true)
  //   }
  // },[currentTime,prepare.status]) 
  console.log("prepareSTATUS",prepare.status, timeOver, currentTime);
  useEffect(() => {
    if(prepare.status == false && timeOver == false && currentTime > selected_ques_Obj?.answer_time ){
      document?.getElementsByClassName('btn btn-transparent btn-lg')[0]?.click()
      setTimeOver(true)
    }
  },[prepare.status, currentTime])
  

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <span className="text-secondary">
          {/* {selected_ques_Obj.id} */}
          {selected_ques_Obj.tags}
          {selected_ques_Obj?.isExplained && (
          <Badge className="text-white ms-3"
                                          >
                                            Explain
                                          </Badge>
                                        )}
        </span>
      
        
        <div>
          {" "}
          <Icon name="bookmark-fill" variant="assigned"></Icon>{" "}
          <Button variant="assigned" size="sm" className="ms-3">
            Tested (20)
          </Button>
        </div>
      </div>
      {prepare.status ? (
        <p className="text-hidanger mb-3">Prepare : {currentTime} </p>
      ):(
        <p className="text-hidanger mb-3">{module=="writing"?"Remain":"Time"} : {currentTime}</p>
      )}
    </>
  );
}

export default PracticeTitleTime;
