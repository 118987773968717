import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
import StudentResultContext from '../../../../context/StudentResultContext/StudentResultContext';
function ResultChoice({fib_wrQuest}) {
  console.log("chioce,",fib_wrQuest);


  return (
    <div className='p-3 text-middark'>
      <b>Choices : </b>
      {/* For FIB-RW */}
      <ul>

        {/* {
          fib_wrQuest?.map((item)=>{
            return item?.question_options?.map((ele,index)=>{
              return <li>{index+1 +". " + ele?.options }</li>           })


          })

        } */}

{
 fib_wrQuest?.question_options?.map((ele,index)=>{
              return <li>{index+1 +". " + ele?.options }</li>           })


          }

        


        {/* <li>1. solder, person, staff, slave</li>
        <li>2. solder, helping, competent, slave</li>
        <li>3. reject, commit, endeavor, stick</li>
        <li>4. concealing, carrying, defining, confining</li>
        <li>5. rise, center, pin, span</li> */}
      </ul>

      {/* For FIB-R */}
      {/* <ul>
        <li>1. slave</li>
        <li>2. solder</li>
        <li>3. reject</li>
        <li>4. concealing</li>
        <li>5. rise</li>
        <li>6. warms</li>
      </ul> */}

    </div>

  );
}
export default ResultChoice;