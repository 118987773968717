import React from "react";
import { Row, Col, Card, Nav, Tab, Tabs, Form, Button } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { DatePicker, FileUpload, Icon } from "../../components";
import StudyInput from "./StudyInput";

function UploadScore() {

  return (
    <Layout title="Analytics" content="container">
      <h1 className="mb-5">Upload Score Report</h1>
      <Block>
        <Card className="h-100">
          <Card.Body>
            <Row>
              <Col md="12">
                <h5 className="mb-5">Exam Category</h5>
              </Col>
              <Col md="12" className="study-score">
                <Tab.Container id="pills-tabs-example" defaultActiveKey="real-exam">
                  <Nav variant="pills" className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="real-exam">Real Exam</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mock-A">Mock A</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mock-B">Mock B</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mock-C">Mock C</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mock-D">Mock D</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mock-E">Mock E</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="real-exam">
                      <h5 className="my-5">Score Report</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <FileUpload />
                        </Col>
                      </Row>
                      <h5 className="my-3">Exam Date</h5>
                      <Row>
                        <Col md="4" lg="4">
                          <DatePicker />
                        </Col>
                      </Row>
                      <h5 className="my-3">Score</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <OverAllScore />                          
                        </Col>
                        <Col md="12" lg="12">
                        <StudyInput />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mock-A">
                      <h5 className="my-5">Score Report</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <FileUpload />
                        </Col>
                      </Row>
                      <h5 className="my-3">Exam Date</h5>
                      <Row>
                        <Col md="4" lg="4">
                          <DatePicker />
                        </Col>
                      </Row>
                      <h5 className="my-3">Score</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <OverAllScore />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mock-B">
                      <h5 className="my-5">Score Report</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <FileUpload />
                        </Col>
                      </Row>
                      <h5 className="my-3">Exam Date</h5>
                      <Row>
                        <Col md="4" lg="4">
                          <DatePicker />
                        </Col>
                      </Row>
                      <h5 className="my-3">Score</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <OverAllScore />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mock-C">
                      <h5 className="my-5">Score Report</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <FileUpload />
                        </Col>
                      </Row>
                      <h5 className="my-3">Exam Date</h5>
                      <Row>
                        <Col md="4" lg="4">
                          <DatePicker />
                        </Col>
                      </Row>
                      <h5 className="my-3">Score</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <OverAllScore />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mock-D">
                      <h5 className="my-5">Score Report</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <FileUpload />
                        </Col>
                      </Row>
                      <h5 className="my-3">Exam Date</h5>
                      <Row>
                        <Col md="4" lg="4">
                          <DatePicker />
                        </Col>
                      </Row>
                      <h5 className="my-3">Score</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <OverAllScore />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mock-E">
                      <h5 className="my-5">Score Report</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <FileUpload />
                        </Col>
                      </Row>
                      <h5 className="my-3">Exam Date</h5>
                      <Row>
                        <Col md="4" lg="4">
                          <DatePicker />
                        </Col>
                      </Row>
                      <h5 className="my-3">Score</h5>
                      <Row>
                        <Col md="6" lg="6">
                          <OverAllScore />
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>

            <div className="d-flex justify-content-center mt-5">
              <Button variant="primary">
                Submit
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default UploadScore;
function OverAllScore({ data, onChange }) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-center">
          {/* <h2 className="text-hidanger">{data?.overall}</h2> */}
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                className="text-hidanger"
                type="text"
                value={data?.overall}
                onChange={onChange}
                name="overall"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Overall</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.listening}
                onChange={onChange}
                name="listening"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Listening</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.reading}
                onChange={onChange}
                name="reading"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Reading</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.speaking}
                onChange={onChange}
                name="speaking"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Speaking</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.writing}
                onChange={onChange}
                name="writing"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Writing</p>
        </div>
      </div>
    </>
  );
}