import React, { useState, useEffect } from "react";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { KanbanBasic } from "../../../../components/Kanban/Kanban";
import DraggableComponent from "./DraggableComponent";
import DropTargetComponent from "./DropTargetComponent";
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import Fib_rb from "../../../practice-test/PTE/ReadingTest/Fib_rb";
const Snippet = ({ text, data, setNewArray, optionsArr }) => {
  const [arr, setArr] = useState([]);
  var str = [];
  var answerlength = 0;
  var prevIndex = 0;
  if (data[0]?.answer?.length) {
    for (let i = 0; i < data[0].answer.length; i++) {
      var indexat = text?.indexOf(data[0].answer[i]);
      str[i] = text.substring(answerlength + prevIndex, indexat);

      prevIndex = indexat;
      answerlength = data[0].answer[i].length;
    }

    str[data[0].answer.length] = text.substring(
      answerlength + prevIndex,
      text.length
    );
  }

  const handleDrop = (e, i) => {
    console.log("setAnswerJson",e)
  };

  const handlechangeChange = (e, i) => {
    const newArr = [...arr];
    if (i < newArr.length) {
      newArr[i] = e.target.value;
    } else {
      newArr.push(e.target.value);
    }
    setNewArray(optionsArr.filter((item) => item != e.target.value));
    setArr(optionsArr.filter((item) => item != e.target.value));
  };
  return (
    <>
      {str.length > 0 &&
        str.map((item, i) => {
          return (
            <>
              {}
              {str.length - 2 >= i && (
                <div claitemssName="d-inline-block bg-hilight py-1 px-2 mx-1  practice-fib" >
                  <DropTargetComponent
                    id="myDIV"
                    key={i}
                    onDrop={(draggedItem) => {}}
                  >
                   <span style={{visibility:"hidden"}}>...............................</span>
                  </DropTargetComponent>
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

function ReadingFillInBlanks({ data }) {
  let { question_content } = data;
  const [options, setOptions] = useState(
    data?.question_options?.length > 0 &&
      data.question_options[0]?.options?.length > 0 &&
      data.question_options[0]?.options
  );
  const [htmlContent, setHtmlContent] = useState(question_content);
  const [text, setText] = useState(data?.question_content);
  useEffect(() => {
    setText(data.question_content);
  }, [data.question_content]);
  const handlechangeChange = (e) => {
    console.log("e.target",e);
  };

  const setNewArray = (arr) => {
    setOptions(arr);
    // data.question_options[0].options = arr
  };
  console.log("data?.question_options",options)
  return (
    <>
  {/* { data != undefined &&   <div className="container my-5 text-examheadtext">
        <h5><em>{data.instructions}</em></h5>
        <div className="d-flex flex-column justify-content-center align-items-center gap-4 my-5">
          <div className="">
           { question_content && <Snippet
              text={question_content}
              data={data?.question_options}
              optionsArr={options?.length ? options : []}
              setNewArray={setNewArray}
            />}
          </div>

          <div className="my-5 bg-black w-100 p-5">
            {options.length > 0 &&
              options.map((item) => {
                return (
                  <>
                    <div className="d-inline-block mx-2 exam-form mb-4">
                      <DraggableComponent
                        id={item}
                        text={item}
                        handlechangeChange={handlechangeChange}
                      />
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>} */}
      {window.location.pathname?.includes("pte-scored-test-intro") && <em className="pte-test-instructions-bold">{data?.instructions}</em>}
      { data != undefined &&   <div className="container my-5 text-examheadtext">
        <Fib_rb data={data}  />
      </div>}
    </>
  );
}
export default ReadingFillInBlanks;
