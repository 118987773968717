import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Icon, LinkList, LinkListItem } from '../../components';
import Mypagination from '../../components/Mypagination';
import { Button } from 'react-bootstrap';
import { ThemeProvider, createTheme } from '@mui/material';



function formatDate(dateString) {
  const date = new Date(dateString);
  console.log('date.getDate() :>> ', date.getDate(), `${("0" + date.getDate()).slice(-2)}`);

  // Format date
  const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;

  // Format time
  let hours = date.getHours();
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return `${formattedDate} | ${formattedTime}`;
}

function Row(props) {
  const { row, data: allData } = props;


  console.log('indexrow :>> ', row);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {/* <TableRow > */}
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width={"10%"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {row?.users_details[0]?.name?row?.users_details[0]?.name:"-"}
        </TableCell> */}
        <TableCell align="left" padding='checkbox'>{row?.users_details[0]?.name ? row?.users_details[0]?.name : "-"}</TableCell>
        {/* <TableCell align="right">{row?.papers[0]?.name?row?.papers[0]?.name:"-"}</TableCell> */}
        {/* <TableCell align="right">{formatDate(row?.createdAt)?formatDate(row?.createdAt):"-"}</TableCell> */}
        <TableCell align="right"> <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-end">
          <LinkListItem to={`/student-management/student-detail/${row?.users_details[0]?._id}`}>
            <Icon name="eye"></Icon>
          </LinkListItem>
        </LinkList></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" className='text-secondary'>
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Date</TableCell> */}

                    <TableCell align='center' >Test Name</TableCell>
                    <TableCell align='center' >End Date</TableCell>
                    <TableCell align='center' abbr='total_score'>Total Score</TableCell>
                    <TableCell align='center'>Result Status</TableCell>
                    <TableCell align='center' >Action</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData?.map((ele) => {
                    console.log("myele", ele?.userResultOverall?.[0]?.speaking_score?.find((item) => item?.section == "Speaking Total")?.actual_total_contribute_scores)

                    return (
                      ele?.users_details?.[0]?._id == row?.users_details?.[0]?._id && (ele?.papers?.length > 0 && (
                        <TableRow key={ele?.date}>
                          {/* <TableCell component="th" scope="row">
                        {ele?.date}
                      </TableCell> */}

                          {/* <TableCell align="right">{ele?.papers?.[0]?.name}</TableCell> */}
                          <TableCell align='center' >{ele?.papers?.[0]?.name}</TableCell>
                          <TableCell align='center' >{formatDate(ele?.createdAt) ? formatDate(ele?.createdAt) : "-"}</TableCell>
                          <TableCell abbr='total_score' align='center' >
                            <div className='d-flex align-items-center'>
                              <div className='w-30'>
                                {ele?.userResultOverall?.[0]?.overall_result?.[0]?.overall_score ? <h3 className='badge text-bg-light text-secondary fw-bold fs-4'>{ele?.userResultOverall?.[0]?.overall_result?.[0]?.overall_score}</h3> : <h3 className='badge text-bg-light text-secondary fw-bold fs-4'> __ </h3>}

                              </div>
                              <div className='d-flex flex-column w-70'>
                                <div className='d-flex justify-content-between w-100'>
                                  {/* {ele?.userResultOverall?.[0]?.overall_result?.[0]?.overall_score? <h3 className='badge text-bg-light fw-bold'>{ele?.userResultOverall?.[0]?.overall_result?.[0]?.overall_score}</h3>: <h3 className='badge text-bg-light fw-bold'> __ </h3>} */}
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center pte-section'><img src='/images/practice-icons/practice-speaking.svg' style={{ width: "15px", height: "15px" }}></img><span className='fw-bold text-hisuccess'>S</span></div>
                                    <span className='fw-bold'>{ele?.userResultOverall?.[0]?.speaking_score?.find((item) => item?.section == "Speaking Total")?.actual_total_contribute_scores ? ele?.userResultOverall?.[0]?.speaking_score?.find((item) => item?.section == "Speaking Total")?.actual_total_contribute_scores : "__"}</span>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center pte-section'><img src='/images/practice-icons/practice-writing.svg' style={{ width: "15px", height: "15px" }}></img><span className='fw-bold text-hidanger'>W</span></div>
                                    <span className='fw-bold'>{ele?.userResultOverall?.[0]?.writing_score?.find((item) => item?.section == "Writing Total")?.actual_total_contribute_scores ? ele?.userResultOverall?.[0]?.writing_score?.find((item) => item?.section == "Writing Total")?.actual_total_contribute_scores : "__"}</span>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center pte-section'><img src='/images/practice-icons/practice-reading.svg' style={{ width: "15px", height: "15px" }}></img><span className='fw-bold text-hiwarning'>R</span></div>
                                    <span className='fw-bold'>{ele?.userResultOverall?.[0]?.reading_score?.find((item) => item?.section == "Reading Total")?.actual_total_contribute_scores ? ele?.userResultOverall?.[0]?.reading_score?.find((item) => item?.section == "Reading Total")?.actual_total_contribute_scores : "__"}</span>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center pte-section'><img src='/images/practice-icons/practice-listening.svg' style={{ width: "15px", height: "15px" }}></img><span className='fw-bold text-hiprimary'>L</span></div>
                                    <span className='fw-bold'>{ele?.userResultOverall?.[0]?.listening_score?.find((item) => item?.section == "Listening Total")?.actual_total_contribute_scores ? ele?.userResultOverall?.[0]?.listening_score?.find((item) => item?.section == "Listening Total")?.actual_total_contribute_scores : "__"}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align='center'  ><span className={` badge text-bg-${ele?.isPaperEvaluated ? "success" : "danger"}`}>
                            {ele?.isPaperEvaluated ? "Done" : "Pending"}
                          </span></TableCell>

                          <TableCell align='center'>{ele?.isPaperEvaluated && <> <span
                            className={` badge text-bg-${ele?.isPaperEvaluated && "info"} `}
                            onClick={() => console.log("jij")}
                          >
                            Score Card
                          </span>
                            <span
                              className={`ms-2 badge text-bg-${ele?.isPaperEvaluated && "info"}`}
                            >
                              Analytics
                            </span></>}</TableCell>
                        </TableRow>))
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };



export default function CollapsibleTable({ data, columns, showItemPerPage }) {
  console.log('columns :>> ', columns, data, showItemPerPage);
  const City = [
    ...new Set(
      data

        .map((item) => item?.users_details?.[0]?._id)
    ),
  ];
  console.log('City :>> ', City);
  //   const City2 = [
  //     new Set(
  //       data

  //         .map((item) => item?.users_details?.[0]?._id)
  //     ),
  //   ];
  //   console.log('City2 :>> ', City2);



  //   City2[0].forEach(id => {
  //     console.log("City2ids..",id); // Output each unique _id value
  // });

  let unquieData = []

  const unquieId = new Set()
  data?.forEach((item) => {

    let id = item?.users_details?.[0]?._id
    let paperLenght = item?.papers.length
    if (id && !unquieId.has(id) && paperLenght > 0) {
      unquieId.add(id)
      unquieData.push(item)

    }

  })
  console.log('unquieData :>> ', unquieData);

  const [currentPage, setCurrentPage] = React.useState(1);

  const currentTableData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * showItemPerPage;
    const lastPageIndex = firstPageIndex + showItemPerPage;
    return unquieData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, unquieData, showItemPerPage]);

  console.log("currentTableData",currentTableData);

  const theme = createTheme({
    elevation:2
  });


  return (
    <ThemeProvider theme={theme}>
    <TableContainer component={Paper} >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            {
              columns.map((column) => (<>
                <TableCell align="right">{column?.name}</TableCell></>
              ))

              // <TableCell align="right">Calories</TableCell>
              // <TableCell align="right">Fat&nbsp;(g)</TableCell>
              // <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              // <TableCell align="right">Protein&nbsp;(g)</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {currentTableData.map((row, index) => (

            <Row key={row.index} row={row} data={data} />
          ))}
        </TableBody>
      </Table>
      <div className='my-2 ms-5'>
        <Mypagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={unquieData.length}
          pageSize={showItemPerPage}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
    </TableContainer>
    </ThemeProvider>
  );
}
