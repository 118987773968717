const { default: BaseApi } = require("../baseApi");

class AnnoucementApi extends BaseApi {
async create_announcement(data) {
    return await this.API({
      url: `auth/add_announcement`,
      data: data,
      method: "post",
    });
  }
  
  async get_announcement(params,isActive) {
      return await this.API({
          url: "auth/get_announcement",
          method: "get",
          params:{instId:params,isActive:isActive}
          
        });
    }




    async delete_annoucement(id) {
        return await this.API({
            url: `auth/delete_announcement/${id}`,
            method: "get",
       
            
          });
      }
  
  
      
  async get_Single_annoucement(params){
    return await this.API({
      url: `auth/get_announcement`,
      method: "get",
      params:{id:params}

    });
  }
}
export default AnnoucementApi