import React, { useState, useRef, useEffect, useContext } from "react";
import { ProgressBar, Spinner, Button, Form } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import ModalContext from "../../../../context/ModalContext";
import Timer from "../Timer";
import Audio from "../customAudio/audio";
import InputRadio from "../inputComponents/inputRadio";
import Video from "../Video";
import AudioUI from "../Istructions/ExamComponents/AudioUI";

function ListeningMultiChoiceSingle({ data, instructions }) {
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [selectedWords, setSelectedWords] = useState([]);
  const [startAudio, setStartAudio] = useState(false);
  const paragraphText = data?.question_content;

  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5>
        <em className="pte-test-instructions-bold">{data.instructions}</em>
        </h5>

        <div className="d-flex justify-content-between align-items-center gap-4 my-5">
          {data?.question_content_video && (
            <Video
              data={data}
              startRecording={() => {
                setStartAudio(true);
                // startRec();
              }}
            />
          )}
          {!data?.question_content_video &&
            data?.question_content_image &&
            data?.question_content_image !== "null" && (
              <img
                src={data?.question_content_image}
                className="exam-img"
                alt="no img"
              ></img>
            )}
          {/* <img
                    src="/images/delicious-ice-cream-cones.jpg"
                    className="exam-img"
                  ></img> */}
          <div className="d-flex flex-column w-50">
            <p>{data?.question} </p>
            <InputRadio data={data.question_options} />
          </div>
          {data.question_content_audio &&
            data.question_content_audio != "null" && (
              <div className="d-flex flex-column align-items-center w-50">
                <AudioUI
                  timer={
                    data.question_begin_time
                      ? data.question_begin_time
                      : "00:00"
                  }
                  audio={data.question_content_audio}
                  startRecording={() => {
                    setStartAudio(true);
                  }}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
}
export default ListeningMultiChoiceSingle;
