import { Card, Form, Row, Col, Button, ButtonGroup, Container, DropdownButton, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../components";
import { Fragment, useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import { useEffect } from "react";
import PermissionSelect from "../../utilities/PermissionSelect";

function AddStaff() {
  let { _id } = useParams();
  let api = new AuthApi();
  let { userData, branchId, setbranchId } = useContext(Context);
  userData = JSON.parse(userData);
  // console.log(userData);
  // console.log(userData._id);
  let navigate = useNavigate();
  const [userRoutes, setUserRoutes] = useState([]);
  const [permissionCategories, setPermissionCategories] = useState([]);
  const [selectedRoutesId, setSelectedRoutesId] = useState([]);
  const [totalSelectedRoutes, setTotalSelectedRoutes] = useState([])
  const [branchData, setBranchData] = useState([]);
  const [data, setData] = useState({
    // active: false,
    is_deleted:0,
    login_type: "0",
    roleId: "64de397404db72cbd184caa6",
    inst_id: (userData?.login_type === "0") && userData?._id, 
    branch_id: branchId ? branchId : null,
    team_profile: {
      permission: {
        my_plan: 0,
        student_overview: 0,
        student_permission: 0,
        setting: 0,
        invoice: 0,
        result: 0,
        help: 0,
        announcement: 0,
      },
      prefix:"hi_"
    },
  });

  useEffect(() => {

    if (_id) {
      get_user_by_id();
    }
  }, [_id]);

  useEffect(() => {
    get_branch_by_inst_id();
    get_user_routes();
    
  }, [])



  useEffect(() => {
    setData({ ...data, permission_routes: totalSelectedRoutes,  permission_routes_id:selectedRoutesId })
  }, [totalSelectedRoutes, selectedRoutesId])

  const get_user_by_id = async () => {
    await api.get_user_by_id(_id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const get_branch_by_inst_id = async () => {
    let api = new AuthApi();

    try {
      console.log(userData._id);
      const res = await api.get_branch_by_inst_id(userData?.teacher_profile?.inst_id || userData._id);
      console.log("BRANCH ID ", res);
      if (res.status) {
        setBranchData(res.data.reverse());
      }
    } catch (err) {

      console.error(err);
    }
  };
  console.log(branchData);

  async function get_user_routes() {
    try {
      let user_routes = await api.get_user_routes("1");
      const routes = user_routes.data;
      console.log(routes);
      setUserRoutes(routes);
      const categories = [];
      routes.map((ele) => { categories.push(ele.category) });
      const uniqueArray = [...new Set(categories)];
      setPermissionCategories(uniqueArray);
    } catch (error) {
      console.error(error);
    }
  }


  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "permissions") {
      let {
        my_plan,
        student_overview,
        student_permission,
        setting,
        invoice,
        result,
        help,
        announcement,
      } = data.team_profile.permission;
      if (value === "MY Plan") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              my_plan: my_plan ? 0 : 1,
            },
          },
        });
      } else if (value === "Student Overview") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              student_overview: student_overview ? 0 : 1,
            },
          },
        });
      } else if (value === "Student Permission") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              student_permission: student_permission ? 0 : 1,
            },
          },
        });
      } else if (value === "Setting") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              setting: setting ? 0 : 1,
            },
          },
        });
      } else if (value === "Invoice") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              invoice: invoice ? 0 : 1,
            },
          },
        });
      } else if (value === "Result") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              result: result ? 0 : 1,
            },
          },
        });
      } else if (value === "Help") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              help: help ? 0 : 1,
            },
          },
        });
      } else if (value === "Announcement") {
        setData({
          ...data,
          team_profile: {
            ...data.team_profile,
            permission: {
              ...data.team_profile.permission,
              announcement: announcement ? 0 : 1,
            },
          },
        });
      }
    } else {
      if (name === "is_deleted") {
        setData({ ...data, [name]: !data.is_deleted });
      } else {
        setData({ ...data, [name]: value });
      }
    }
  };

  console.log("data", data);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await api.staff_register(data).then((res) => {
      console.log("res",res);
      console.log(res?.status);
      console.log("branch",branchId);
      try {
        if (res?.status) {
          console.log("res", res);
          console.log("SUCCESS FIRe");
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate(-1);
        } else {
          console.log("DENIED FIRe");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleSubmitEditTeam = async (e) => {
    e.preventDefault();
    await api.edit_user_by_id(data, _id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const permission = [
    "MY Plan",
    "Student Overview",
    "Student Permission",
    "Setting",
    "Invoice",
    "Result",
    "Announcement",
    "Help",
  ];

  const permissionsEdit = [
    "All",
    "Edit",
    "Add",
    "Update",
    "Order",
    "Delete"
  ]

  const options = [
    { value: '/api/', label: 'All' },
    { value: '/api/', label: 'Edit' },
    { value: '/api/', label: 'Add' },
  ];
  return (
    <Layout title="Add Product" content="container">
      {console.log("totalSelectedRoutes",totalSelectedRoutes)}
      {console.log("selectedRoutesId",selectedRoutesId)}
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              {_id ? "Edit Staff" : "Add Staff"}
            </Block.Title>
          </Block.HeadContent>
          {/* <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                    <Icon name="eye" />
                                    <span>View</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                    <Icon name="eye" />
                                    <span>View Products</span>
                                </Link>
                            </li>
                        </ul>
                    </Block.HeadContent> */}
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              {/* <h5 className="mb-3">Select Product</h5> */}
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">Full Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="name"
                            value={data?.name}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="Your Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="mobile">Mobile</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="number"
                            name="mobile"
                            value={data.mobile?.length < 11 && data.mobile}
                            onChange={(e) => handleChange(e)}
                            id="mobile"
                            placeholder="Mobile"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="emailid">Email Id</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            name="email"
                            value={data.email}
                            onChange={(e) => handleChange(e)}
                            id="emailid"
                            placeholder="Email Id"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="password"
                            name="password"
                            value={data.password}
                            onChange={(e) => handleChange(e)}
                            id="password"
                            placeholder="Password"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="3"></Col>

                <Col md="12" className="d-flex justify-content-between">
                  <div>
                    <h5 className="my-3">Select Status</h5>
                    <div className="form-check form-switch">
                      <span className="text-light">Active</span>{" "}
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        id="directMessage"
                        name="is_deleted"
                        checked={!data?.is_deleted}
                        onChange={(e) => handleChange(e)}
                        value={data?.is_deleted}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg='12'>
                  <h5 className="my-3">Select Branch</h5>
                  {(userData?.login_type == "1" || userData?.login_type == "0") && <Form.Group className="form-group">
                    <div className="form-control-wrap w-20">
                      {/* <div className="form-control-icon start">
                        <Icon name="map-pin" variant="secondary"></Icon>
                      </div> */}
                      <Form.Select
                        id="exampleFormSelect1"
                        aria-label="Default select example"
                        value={branchId}
                        className="ps-5"
                        onChange={(e) => {
                          sessionStorage.setItem("@#branch_id", e.target.value);
                          setbranchId(e.target.value);
                        }}
                      >
                        {branchData.length > 0 &&
                          branchData.map((item) => {
                            return (
                              <>
                                <option value={item._id}>
                                  {item.name}, {item.location}
                                </option>
                              </>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </Form.Group>}
                </Col>
                <Col lg="3" md="3"> <h5>Select Type</h5></Col>
                <Col lg="9" md="9"><h5>Select Permission</h5></Col>
                <ButtonGroup
                  aria-label="Basic checkbox toggle button group"
                  className="hilingo-team gap-3 flex-column">
                  {permissionCategories.length > 0 &&
                    permissionCategories.map((item, i) => {
                      return (
                        <Fragment key={`btnradio${i + 1}`}>
                          <Row style={{height:"40px"}}>
                            <Col lg="2" md="2">
                              <span className="text-black">{item}</span>
                            </Col>
                            <Col lg="9" md="9">
                              <ButtonGroup
                                aria-label="Basic checkbox toggle button group"
                                className=" gap-3"
                              >
                                <PermissionSelect data={data} filterParam={item} userRoutes={userRoutes} totalSelectedRoutes={totalSelectedRoutes} setTotalSelectedRoutes={setTotalSelectedRoutes} selectedRoutesId={selectedRoutesId} setSelectedRoutesId={setSelectedRoutesId}    />
                              </ButtonGroup>
                            </Col>
                          </Row>
                        </Fragment>
                      );
                    })}
                </ButtonGroup>

                <Col className="" lg="">




                  {/* <ButtonGroup
                      aria-label="Basic checkbox toggle button group"
                      className="hilingo-team gap-3"
                    >
                      {data.students?.length > 0  && 
                        data?.students.map((item, i) => {
                          return (
                            <>
                              <input
                                type="checkbox"
                                className=""
                                value={item.route}
                                onChange={(e) => handleChange(e)}
                                name="permissions"
                                id={item._id}
                                autoComplete="off"
                              />
                              <label
                                as="label"
                                variant=""
                                htmlFor={item._id}
                              >
                                {item.module_name}
                              </label>
                            </>
                          );
                        })}
                    </ButtonGroup> */}
                </Col>


                <Col md="12" className="my-5">
                  {/* <Button
                      className="d-md-inline-flex fw-bold"
                      variant="outline-primary"
                    >
                      Update Team
                    </Button> */}
                  {_id ? (
                    <>
                      <Button
                        onClick={handleSubmitEditTeam}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleSubmit}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                      >
                        Add Staff
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default AddStaff;
