import { useEffect, useState } from "react";
import Swal from "sweetalert2/src/sweetalert2";
import {
  Card,
  Button,
  ButtonGroup,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
// import { Link } from 'react-router-dom';
import AuthApi from "../../api/auth";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Select } from "../../components";
import TeamDataTable from "../admin/TeamDataTable";
// import InstituteData, { InstituteColumns } from './InstituteData';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
} from "../../components";
import AdminApi from "../../api/admin";

function QuestionList() {
  let { examId } = useParams();
  let api = new AdminApi();
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [paginateDataFromApi, setPaginateDataFromApi] = useState([])
  const[deleted,setDeleted]=useState({
    status:false,
    id:null
  })
  let documentLength = 5;
  const [paginate, setPaginate] = useState({offset:0, limit:20, currentPage:1})
  const [filters, setFilters] = useState({module_name:"listening",sub_module:"all",test_type:"all",title:""})
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    getQuestionListByExamId();
    // geQuestionListByPagination();
  }, []);

  useEffect(() => {
    getAdminQuestionListAll();
  },[paginate, filters])

  const handlePageChange = (event) => {
    console.log("PAGEEVENT", event.selected);
    if(event.target?.name == "goToPageNumber"){
      console.log("goToPageNumber", event.target.value);
      console.log("EVENT",event.target.value);
      event.target.value == "" ? setPaginate({offset:0, limit:20, currentPage:event.target.value}) :  setPaginate({offset:(Number(event.target.value)-1) * 20, limit:20, currentPage:event.target.value})
    }
    else{
      setPaginate({offset:event.selected * 20, limit:20, currentPage:event.selected + 1})
    }
  }

  const getQuestionListByExamId = async () => {
    await api
      .get_qst(examId)
      .then((res) => {
        console.log("ress", res);
        if (res.question.length > 0) {
          setData(res.question.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAdminQuestionListAll = async () => {
    await api
      .get_qst_all_admin(paginate,filters)
      .then((res) => {
        console.log("ress", res);
        if (res.question.length > 0) {
          setData(res.question.reverse());
          setTotalDocuments(res?.length)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const geQuestionListByPagination = async () => {
    await api
      .get_qst_by_pagination(paginate)
      .then((res) => {
        console.log("ress", res);
        if (res.question.length > 0) {
          setData(res.question.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const inActiveQuestion = async (id) => {
    await api
      .delete_qst(id)
      .then((res) => {
        console.log("ress", res);
        if (res.question.length > 0) {
          setData(res.question.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const[prediction_payload,set_prediction_payload] = useState({
    qstID:[],
    isPrediction:null,
    table_view_data:[],
    selectAll:false
  })
  const date = new Date(); 
let {qstID,table_view_data,selectAll} = prediction_payload;
     const edit_question_by_id = async (e, _id,multiprediction,isPrediction) => {
      if(multiprediction!=undefined){
        await api.set_predective({ 'predective': isPrediction ,id:qstID,isPredUpdate:date} ).then((res) => {
          try {
            if (res.status) {
              Swal.fire({
                icon: "success",
                title: isPrediction?"Enabled":"Disabled",
                text: isPrediction?"Prediction Enabled successfully":"Prediction Disabled successfully",
              })
              set_prediction_payload({...prediction_payload,qstID:[],selectAll:false})
              getAdminQuestionListAll();
            }
          } catch (err) {
            console.log(err);
          }
        });
        return
      }
      let { name, checked } = e.target;
    var active = null;
    if (checked) {
      active = true;
    } else {
      active = false;
    }
  
    await api.set_predective({ 'predective': active ,id:[_id],isPredUpdate:date} ).then((res) => {
      try {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: active?"Enabled":"Disabled",
            text: active?"Prediction Enabled successfully":"Prediction Disabled successfully",
          })
          getAdminQuestionListAll();
        }
      } catch (err) {
        console.log(err);
      }
    });
  }; 
  const setQuestionId = (value,id) =>{
      if(value){
        set_prediction_payload({...prediction_payload,qstID:[...qstID,id]})
      }else{
        set_prediction_payload({...prediction_payload,qstID:qstID.filter((item)=>item!=id)})
      }
  }
  const QuestionColumns = [
    {
      name: <Form.Check className="form-check" id="check-all" value={selectAll} checked={selectAll} onChange={(e)=>set_prediction_payload({...prediction_payload,selectAll:e.target.checked,qstID:e.target.checked==true?table_view_data.map(item=>item._id):[]})}/>,
      cell: (row) => (
        <div className="text-start w-100">
          <Form.Check className="form-check" onChange={(e)=>setQuestionId(e.target.checked,row._id)} checked={qstID.includes(row._id)}/>
        </div>
      ),
      sortable: false,
    },
    {
      name: "Tags",
      selector: (row) => row?.domain_name,
      cell: (row) => (
        <MediaGroup>
          {/* <Media size="md" shape="circle" variant={row.theme && row.theme}>
                    {row?.inst_profile?.business_logo ?
                       <Image src={row?.inst_profile?.business_logo} staticImage /> :
                    <Image src={'/images/avatar/a.jpg'} staticImage /> 
                    }
                </Media> */}
          <MediaText>
            <Link target="_blank" to={`${window.location.origin}/material-management/${row?.exam_type}/${row?.module}/${row?.module_name}/${row._id}`} className="title">
              {row?.tags}
            </Link>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row,
      cell: (row) => (
        <span className="text-middark">{row?.exam_type?.toUpperCase()}</span>
      ),
      sortable: true,
    },
    {
      name: "Module",
      selector: (row) => row.assigned,
      cell: (row) => (
        <span className="text-middark">{row.module?.toUpperCase()}</span>
      ),
      sortable: true,
    },

    {
      name: "Sub-Module",
      grow: "3",
      selector: (row) => row.attempt,
      cell: (row) => (
        <span className="text-middark">
          {row?.module_label && row.module_label}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Prediction",
      selector: (row) => row.attempt,
      cell: (row) => (
        <div className="form-check form-switch">
        <input
          className="form-check-input ms-3"
          type="checkbox"
          role="switch"
          value={row?.isPrediction}
          checked={row?.isPrediction}
          onChange={(e) => edit_question_by_id(e, row._id)}
          id="directMessage"
          name="active"
        />
      </div>
      ),
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status,
      cell: (row) => (
        // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //     : row.status === "Inactive" ? "danger"
        //         : "primary"}`
        // }>
        <span
          className={`badge text-bg-${
            row.is_deleted == 0
              ? "success"
              : row.is_deleted == 1
              ? "danger"
              : "primary"
          }`}
        >
          {row.is_deleted != 0 ? "Active" : "Inactive"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
         
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            {/* <LinkListItem
              to={`/material-management/${row?.exam_type}/${row?.module}/${row?.module_name}/${row._id}`}
            >   */}
                 <div className="del-btn"  onClick={()=>window.open(`${window.location.origin}/material-management/${row?.exam_type}/${row?.module}/${row?.module_name}/${row._id}`, '_blank')} >
            <Icon name="edit"></Icon>
        </div>
            {/* </LinkListItem> */}
            {/* <LinkListItem > */}
            {!row?.isMock_examID &&
            !row?.isQuestion_examID &&
            !row?.isSection_examID ? (
              <div
                className="del-btn"
                onClick={() => setDeleted({status:true,id:row._id})}
              >
                {" "}
                <Icon name="trash"></Icon>{" "}
              </div>
            ) : (
              <div
                className="del-btn"
                onClick={() =>
                  Swal.fire({
                    icon: "error",
                    title: "Cannot Deleted",
                    text: "This Question Already Used in Exam",
                  })
                }
              >
                {" "}
                <Icon name="trash"></Icon>{" "}
              </div>
            )}
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];

const get_table_view_data = (table_view_data) =>{
  set_prediction_payload({...prediction_payload,qstID:[],table_view_data:table_view_data,selectAll:false})
}
  return (
    <Layout title="Users List" content="container">
      <div className="d-flex justify-content-between">
        <h1 className="mb-5"> Question List</h1>
        <ul className="d-flex">
          {window.location?.pathname.includes(
            "material-management/view-questions"
          ) && (
            <>
              <li>
                {" "}
                <Button
                  className="d-md-none"
                  size="md"
                  variant="outline-primary"
                  onClick={() => {
                    navigate("/material-management/add-questions");
                  }}
                >
                  <Icon name="plus" />
                  <span>Add Questions</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="outline-primary"
                  onClick={() => {
                    navigate("/material-management/add-questions");
                  }}
                >
                  <Icon name="plus" />
                  <span>Add Questions</span>
                </Button>
              </li>
            </>
          )}
        </ul>
      </div>

      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <TeamDataTable
                edit_question_by_id={(isPrediction)=>edit_question_by_id(null,null,"isPrediction",isPrediction)}
                prediction_payload={prediction_payload}
                  table
                  ClassName="data-table-head-light table-responsive"
                  send_table_data_to_parent={(value)=>get_table_view_data(value)}
                  data={data}
                  columns={QuestionColumns}
                  filters={filters}
                  paginate={paginate}
                setFilters={setFilters}
                setPaginate={setPaginate}  
                totalDocuments={totalDocuments}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

      <Modal show={deleted?.status} onClick={()=>setDeleted({...deleted,status:false,id:null})} >
              <div className="modal-content">
                  <div className="modal-body p-5 text-center">
                      <h3>Are you sure want to delete ?</h3>
                      <ul className="d-flex gap g-3 justify-content-center pt-1">
                          <li><Button variant="success" onClick={()=>inActiveQuestion(deleted?.id)} >Yes Delete it!</Button></li>
                          <li><Button variant="danger" className="btn-soft" onClick={()=>setDeleted({...deleted,status:false,id:null})} >Cancel</Button></li>
                      </ul>
                  </div>
                  <button type="button" className="btn-close position-absolute top-0 end-0 p-3"  aria-label="Close"></button>
              </div>
        </Modal>
    </Layout>
  );
}

export default QuestionList;
