import {react, useContext} from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
import StudentResultContext from '../../../../context/StudentResultContext/StudentResultContext';
import { Link, useNavigate } from 'react-router-dom';
function ResultHeading({tags,index,questionId,moduleName}) {



    const navigate =useNavigate()
  console.log('tags :>> ', tags);
  return (
    <div className='d-flex justify-content-between align-items-center m-0 p-3'>
      <div style={{ width: "85%" }}>
        <h5 className='text-secondary'>{index}. {tags}</h5>
      </div>
      {/* <Button onClick={ ()=> navigate(`/practice/pte/read_alouds/${questionId}`)} target='_blank' variant='primary' size="sm">Go to Question &gt;</Button> */}

      <Link       
                        className="btn btn-primary h-2"
                        to={`/practice/pte/${moduleName}/${questionId}`}
                        target="_blank"
                      >
                       Go to Question &gt;
                      </Link>

    </div>
  );
}
export default ResultHeading;