import React, { useContext, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Context from "../context";
import { ScrollToTop } from "../components";
import AdminRoutes from "./admin";
import InstituteRoutes from "./institutes";
import PublicRoute from "./publicRoute";
import Question_Context from "../context/questionContext";
import StudentRoutes from "./students";
import IncorrectDomain from "../pages/incorrectDomain";
import ExternalStudentRoutes from "./externalStudent";
import OverrideContext from "../context/OverrideContext/OverrideContext";

function Router() {
  let name = window.location.hostname.split(".")[0];
  const { userData, setUserData, localHost, incorreCtDomain } = useContext(Context);
  let privateroute = JSON.parse(userData)?.email;
  let instituteRoute = JSON?.parse(userData)?.login_type;
  const [quesData, setQuesData] = useState({});
  const value = { quesData, setQuesData };

  console.log('allrouterdata :>> ', privateroute,name,instituteRoute,incorreCtDomain,localHost,value);
  return (

    <ScrollToTop>
    
      {privateroute != undefined &&
      ((name != "adm1n" &&

        name != "localhost" &&
        instituteRoute != "0" &&
        instituteRoute == "1") ||
        instituteRoute == "2" ||
        localHost != "adm1n") ? (
        <>
          {instituteRoute == "1" ? (
            <InstituteRoutes />
          ) : (
            instituteRoute == "2" && <StudentRoutes />
          )}
        </>
      ) : privateroute != undefined &&
        (name == "adm1n" || localHost == "adm1n") ? (
        <>
          <Question_Context.Provider value={value}>
            <AdminRoutes />
          </Question_Context.Provider>
        </>
      ) 
      
      : privateroute != undefined &&
      (name == "onboarding") ? (
      <>
         <PublicRoute />
      </>
    )

    : (name == "app") ? (
    <>
     
       <ExternalStudentRoutes />
      
    </>
  )
    
    : incorreCtDomain ? (
        <>
          <IncorrectDomain />
        </>
      ) : (
        <PublicRoute />
      )}
    
    </ScrollToTop>
  );
}

export default Router;