export const ArrayAll = [
      { module_name: "read_alouds", text: "Read Aloud",img:"/images/practice-icons/read_aloud.svg" },
      { module_name: "repeat_sentences", text: "Repeat Sentence",img:"/images/practice-icons/repeat_sentences.svg" },
      { module_name: "describe_images", text: "Describe Image",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "retell_lectures", text: "Re-Tell Lecture",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "answer_questions", text: "Answer Short Question",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "swts", text: "Summarize Writing Text",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "essays", text: "Essay",img:"/images/practice-icons/practice-graduationcap.svg" },
      // Add more items for the 'writing' category
      { module_name: "ssts", text: "Summarize Spoken Text",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "l_mcm", text: "Multiple Choice Multiple Answer",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "l_fib", text: "Fill in the Blanks",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "l_hcs", text: "Highlight Correct Summary",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "l_mcs", text: "Multiple Choice Single Answer",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "l_smw", text: "Select Missing Words",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "hiws", text: "Highlight Incorrect Words",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "wfds", text: "Write From Dictation",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "fib_wr", text: "Reading and Writing Fill in the Blanks",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "r_mcm", text: "Multiple Choice, Choose Multiple Answer",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "ro", text: "Re-order Paragraphs",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "r_mcs", text: "Multiple Choice, Choose Single Answer",img:"/images/practice-icons/practice-graduationcap.svg" },
      { module_name: "fib_rd", text: "Reading: Fill in the Blanks",img:"/images/practice-icons/practice-graduationcap.svg" },
]