import React, { useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { useNavigate, useParams } from "react-router-dom";
import AuthApi from "../../api/auth";
import Swal from "sweetalert2/src/sweetalert2.js";
import DatePicker from "../../components/Form/DatePicker";
function Enquiry() {
    let { id } = useParams();
    const [state, setState] = useState({
        data: {
            name: "",
            email: "",
            phone: "",
            enquiry: ""
        },
        errors: {}
    });

    const { data, errors } = state;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            data: { ...prevState.data, [name]: value },
            errors: { ...prevState.errors, [name]: "" } // Clear the error when the field is modified
        }));
    };


    console.log("DATADATA",data);

    const validateForm = () => {
        const newErrors = {};
        if (!data.name.trim()) {
            newErrors.name = "Name is required";
        }
        if (!data.email.trim()) {
            newErrors.email = "Email is required";
        }
        if (!data.phone.trim()) {
            newErrors.phone = "Phone is required";
        } else if (data.phone.length !== 10) {
            newErrors.phone = "Phone number must be 10 digits";
        }
        if (!data.enquiry.trim()) {
            newErrors.enquiry = "Enquiry is required";
        }

        setState((prevState) => ({
            ...prevState,
            errors: newErrors
        }));

        return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
    };
    let navigate = useNavigate();
    let api = new AuthApi();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            if (!id) {
                await api.add_enquiry(data).then((res) => {
                    if (res.status) {
                        Swal.fire({
                            icon: "success",
                            title: "Good job!",
                            text: res.message,
                        });
                        navigate("/")
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res.message,
                        });
                    }
                }).catch((err) => { console.log(err) })
            } else {
                await api.add_enquiry({ ...data, id: id }).then((res) => {
                    if (res.status) {
                        Swal.fire({
                            icon: "success",
                            title: "Good job!",
                            text: res.message,
                        });
                        navigate("/")
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res.message,
                        });
                    }
                }).catch((err) => { console.log(err) })
            }
            // Perform the form submission logic here
            console.log("Form submitted:", data);
        } else {
            console.log("Form validation failed");
        }
    };

    return (
        <Layout title="Add Product" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Add Enquiry</Block.Title>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card>
                    <Card.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="g-gs my-3">
                                <Col md="6">
                                    <Form.Group className="form-group my-3">
                                        <Form.Label htmlFor="title">Student Name</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control
                                                type="text"
                                                id="name"
                                                placeholder="Student Name"
                                                name="name"
                                                value={data.name}
                                                onChange={(e) => handleChange(e)}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group my-3">
                                        <Form.Label htmlFor="tag">Email Id</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control
                                                type="email"
                                                id="emailid"
                                                name="email"
                                                placeholder="Email Id"
                                                value={data.email}
                                                onChange={(e) => handleChange(e)}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group my-3">
                                        <Form.Label htmlFor="tag">Phone Number</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control
                                                type="number"
                                                id="phoneno"
                                                name="phone"
                                                placeholder="Phone Number"
                                                value={data.phone}
                                                onChange={(e) => handleChange(e)}
                                                isInvalid={!!errors.phone}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phone}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group my-3">
                                        <Form.Label htmlFor="title">Team Member Name</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control
                                                type="text"
                                                id="teamname"
                                                placeholder="Team Member Name"
                                                name="teamname" />
                                            {/* <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback> */}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>Next FollowUp Date</Form.Label>
                                        <div className="form-control-wrap">
                                            {/* <DatePicker /> */}
                                            {/* tochan */}
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                placeholder="DateRange"
                                                value={data?.date}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="enqtype">Enquiry Type</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Select id="enqtype" aria-label="Default select example">
                                                <option value="">Select</option>
                                                <option value="1">PTE</option>
                                                <option value="2">IELTS</option>
                                            </Form.Select>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group my-3">
                                        <Form.Label htmlFor="tag">Enquiry</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Enquiry"
                                                name="enquiry"
                                                id="enquiry"
                                                rows="3"
                                                value={data.enquiry}
                                                onChange={(e) => handleChange(e)}
                                                isInvalid={!!errors.enquiry}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.enquiry}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md="6"></Col>
                                <Col md="6" className="d-flex justify-content-center">
                                    <Button variant="primary" type="submit">
                                        Add Enquiry
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Block>
        </Layout>
    );
}

export default Enquiry;
