import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Card, Form, Row, Col, Button, Tab, Nav, InputGroup } from "react-bootstrap";
import { Block, Icon } from '../../../components'
import Layout from "../../../layout/default";
import { useSearchParams } from 'react-router-dom';
import PracticePteSearchResults from './PracticePteSearchResults';
import Context from '../../../context';
import { getQuestion_by_module_name } from './PracticeTestFunctions';
import ReactPaginate from "react-paginate";
function PracticePteSearch() {
  const url = window.location.href
  const urlObj = new URL(url);

// Use URLSearchParams to get the value of the 'text' parameter
let textValue = urlObj.searchParams.get('text');

// If 'text' is empty, manually extract it from the URL
if (!textValue) {
    const queryString = url.split('?')[1];
    const params = new URLSearchParams(queryString);
    textValue = params.get('text');
}
  let [searchParams, setSearchParams] = useSearchParams("");
  const [title, setTitle] = useState("")
  const [data,setData] = useState({})
  const [errorMessage, setErrorMessage] = useState('');
  console.log("TEXTVALUE",textValue);
  const { userData, setUserData, localHost, incorreCtDomain } =
    useContext(Context);

  const [paginate, setPaginate] = useState({offset:0, limit:20, currentPage:1})
  const [filterObj, setfilterObj] = useState({  title:title, req_type:"pteSearch"});
  const itemsPerPage = 20;

  
  console.log("DATADATA",data)
  console.log("model", searchParams.get("model"));
  console.log("text", searchParams.get("text"));

    let speaking = [
        { value: "read_alouds", text: "Read Aloud" },
        { value: "repeat_sentences", text: "Repeat Sentence" },
        { value: "describe_images", text: "Describe Image" },
        { value: "retell_lectures", text: "Re-Tell Lecture" },
        { value: "answer_questions", text: "Answer Short Question" },
      ];
    
      let writing = [
        { value: "swts", text: "Summarize Writing Text" },
        { value: "essays", text: "Essay" },
      ];
    
      let reading = [
        { value: "fib_wr", text: "R&W : Fill in the Blanks" },
        { value: "r_mcm", text: "MCQ - Multiple" },
        { value: "ro", text: "Re-order Paragraphs" },
        { value: "fib_rd", text: "Reading : Fill in the Blanks" },
        { value: "r_mcs", text: "MCQ - Single" },
      ];
    
      let listening = [
        { value: "ssts", text: "Summarize Spoken Text" },
        { value: "l_mcm", text: "MCQ - Multiple" },
        { value: "l_fib", text: "Fill in the Blanks" },
        { value: "l_hcs", text: "Highlight Correct Summary" },
        { value: "l_mcs", text: "MCQ - Single" },
        { value: "l_smw", text: "Select Missing Word" },
        { value: "hiws", text: "Highlight Incorrect Words" },
        { value: "wfds", text: "Write From Dictation" },
      ];

      function handleSearchParams(e){
        console.log(e.target.id);
        console.log(e.target);
        if(e.target.id == "FORTEXTCHANGE"){
          // setSearchParams({model:searchParams.get("model"),text:title})
          setSearchParams({model:searchParams.get("model"), text:title})
          setfilterObj({...filterObj, title:title})
        }
        else{
          
          setSearchParams({model:e.target.value,text:searchParams.get("text")})
        }
        
      }

      const handlePageChange = (event) => {
        console.log("PAGEEVENT", event.selected);
        if(event.target?.name == "goToPageNumber"){
          console.log("goToPageNumber", event.target.value);
          console.log("EVENT",event.target.value);
          event.target.value == "" ? setPaginate({offset:0, limit:20, currentPage:event.target.value}) :  setPaginate({offset:(Number(event.target.value)-1) * 20, limit:20, currentPage:event.target.value})
        }
        else{
          setPaginate({offset:event.selected * 20, limit:20, currentPage:event.selected + 1})
        }
      };

      async function fetchData() {
        try {
          if(textValue.length > 49){
            setErrorMessage('Error: Your search text is too long');
            return
          }
          setErrorMessage('');
          const response = await getQuestion_by_module_name(
            searchParams.get("model"),
            JSON.parse(userData)?._id,
            paginate,
            {...filterObj,title:textValue}
          );
          if (response && response?.question?.length) {
            let arr = response.question;
            setData((prev) => ({
              ...prev,
              questionsBy_module_name: arr,
              questionsBy_module_name_for_filter: arr,
              totalDocuments:response?.length,
              isPracticedDoneCount:response?.isPracticedDoneCount
            }));
          } else {
            setData((prev) => ({
              ...prev,
              questionsBy_module_name: [],
              questionsBy_module_name_for_filter: [],
            }));
          }
        } catch (error) {
          console.error("Error", error);
        }
      }


      useEffect(() => {
        fetchData();
        setTitle(textValue)
      }, [paginate.offset, filterObj, searchParams]);

      useEffect(() => {
        if(title.length < 50){
          setErrorMessage("")
        }
      },[title])

      // useEffect(() => {
      //   let ele = document?.getElementById("to-search")
      //   ele.value = textValue
      // },[])

  console.log("SEARCHPARARMS",searchParams.get("prev_page"));

  return (
    <Layout title="Add Product" content="container">
      <h1 className="mb-5">Search</h1>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
          <Row className="g-gs mb-5 mt-0">
          <Col md="3"></Col>
              <Col md="6">
                <InputGroup>
                  <input
                    className="form-control" 
                    id="to-search"
                    placeholder="Question Title/Number"
                    type="text"
                    // maxLength={49}
                    value={title}
                    onChange={(e) => {setTitle(e.target.value)}}
                  />
                  {/* <div id="FORTEXTCHANGE" onClick={(e) => {handleSearchParams(e)}}>
                    <Button name='button' variant="outline-primary">
                      <Icon name="search"></Icon>
                    </Button>
                  </div> */}
                  <Button id="FORTEXTCHANGE" onClick={(e) => {handleSearchParams(e)}} variant="outline-primary">
                      Search
                    </Button>
                    
                </InputGroup>
                {errorMessage && <div style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</div>}
              </Col>
              </Row>
            <Row className="g-gs">
              <Col md="3">
                <Card className="service-box hisuccess h-100">
                  <Card.Body>
                    <h3><img src='/images/practice-icons/practice-speaking.svg' className='search-img'></img>Speaking</h3>
                    {speaking.map((ele,key) => {
                      return (
                        <Fragment key={key}>
                          <input type="radio" className="btn-check-search" name="btnradio-mock" onClick={(e) => {handleSearchParams(e)}} checked={searchParams.get("model") == ele.value} value={ele.value} id={`btnradio-hisuccess${key}`} autoComplete="off" />
                          <Button as="label"   variant='hisuccess' htmlFor={`btnradio-hisuccess${key}`} className="d-flex justify-content-between"><span>{ele.text}</span></Button>
                        </Fragment>
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
              <Col md="3">
                <Card className="service-box hidanger h-100">
                  <Card.Body>
                    <h3><img src='/images/practice-icons/practice-writing.svg' className='search-img'></img>Writing</h3>
                    {writing.map((ele,key) => {
                      return (
                        <Fragment key={key}>
                          <input type="radio" className="btn-check-search" onClick={(e) => {handleSearchParams(e)}} checked={searchParams.get("model") == ele.value} value={ele.value} name="btnradio-mock" id={`btnradio-hidanger${key}`} autoComplete="off" />
                          <Button as="label"  variant='hidanger' htmlFor={`btnradio-hidanger${key}`} className="d-flex justify-content-between"><span>{ele.text}</span></Button>
                        </Fragment>
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
              <Col md="3">
                <Card className="service-box hiwarning h-100">
                  <Card.Body>
                    <h3><img src='/images/practice-icons/practice-reading.svg' className='search-img'></img>Reading</h3>
                    {reading.map((ele,key) => {
                      return (
                        <Fragment key={key}>
                            <input type="radio" className="btn-check-search" onClick={(e) => {handleSearchParams(e)}} checked={searchParams.get("model") == ele.value} value={ele.value} name="btnradio-mock" id={`btnradio-hiwarning${key}`} autoComplete="off" />
                            <Button as="label"  variant='hiwarning' htmlFor={`btnradio-hiwarning${key}`} className="d-flex justify-content-between"><span>{ele.text}</span></Button>
                        </Fragment>
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
              <Col md="3">
                <Card className="service-box hiprimary h-100">
                  <Card.Body>
                    <h3><img src='/images/practice-icons/practice-listening.svg' className='search-img'></img>Listening</h3>
                    {listening.map((ele,key) => {
                      return (
                        <Fragment key={key}>
                            <input type="radio" className="btn-check-search" onClick={(e) => {handleSearchParams(e)}} checked={searchParams.get("model") == ele.value} value={ele.value} name="btnradio-mock" id={`btnradio-hirpimary${key}`} autoComplete="off" />
                            <Button as="label"  variant='hirpimary' htmlFor={`btnradio-hirpimary${key}`} className="d-flex justify-content-between"><span>{ele.text}</span></Button>
                        </Fragment>
                      )
                    })}
                    
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {data?.questionsBy_module_name?.map((ele,key) => {
              return <PracticePteSearchResults data={ele} key={key} />
            })}
            
            {/* <PracticePteSearchResults /> */}
            {data?.questionsBy_module_name?.length > 0 && (
                <div className="d-flex justify-content-between position-relative bottom-0 align-items-center react-pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={Object.values(filterObj).filter(item => item === true).length > 0 ? (data?.questionsBy_module_name?.length/itemsPerPage) : Math.ceil(data?.totalDocuments / itemsPerPage)}
                    // pageCount={Math.ceil(data?.totalDocuments / itemsPerPage)}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                  />
                  <div className="d-flex gap-2 align-items-center text-middark">
                    <span>GO TO </span>
                    <Form.Group className="form-group">
                      <div className="form-control-wrap">
                        <Form.Control
                          name="goToPageNumber"
                          type="text"
                          style={{ width: "80px" }}
                          value={Number(paginate.currentPage)}
                          onChange={(event) =>
                            handlePageChange(event)
                          }
                        />
                      </div>
                    </Form.Group>
                    <span>
                      /{" "}
                      {Math.ceil(Object.values(filterObj).filter(item => item === true).length > 0 ? (data?.questionsBy_module_name?.length/itemsPerPage) : Math.ceil(data?.totalDocuments / itemsPerPage))}
                      {/* {Math.ceil(data?.totalDocuments / itemsPerPage)} */}
                    </span>
                  </div>
                </div>
              )}

          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default PracticePteSearch