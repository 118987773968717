import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar, Badge } from "react-bootstrap";
import React from "react"
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";
import Select from '../../../../components/Form/Select';
import PracticePteComments from "../PracticePteComments";
import { useEffect, useState } from "react";
import DraggableComponent from "../../../exam/PTE/ReadingTest/DraggableComponent";
import DropTargetComponent from "../../../exam/PTE/ReadingTest/DropTargetComponent";
import Fib_rb from "./Fib_rb";
const Snippet = ({ text, data, setNewArray, optionsArr }) => {
  const [arr, setArr] = useState([]);
  var str = [];
  var answerlength = 0;
  var prevIndex = 0;

  if (data && data[0].answer.length) {
    for (let i = 0; i < data[0].answer.length; i++) {
      var indexat = text.indexOf(data[0].answer[i]);
      str[i] = text.substring(answerlength + prevIndex, indexat);

      prevIndex = indexat;
      answerlength = data[0].answer[i].length;
    }

    str[data[0].answer.length] = text.substring(
      answerlength + prevIndex,
      text.length
    );
  }

  const handleDrop = (e, i) => {
    console.log("setAnswerJson", e)
  };

  const handlechangeChange = (e, i) => {
    const newArr = [...arr];
    if (i < newArr.length) {
      newArr[i] = e.target.value;
    } else {
      newArr.push(e.target.value);
    }
    setNewArray(optionsArr.filter((item) => item != e.target.value));
    setArr(optionsArr.filter((item) => item != e.target.value));
  };
  console.log("arr",)
  return (
    <>
      {str.length > 0 &&
        str.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item}
              {str.length - 2 >= i && (
                <div className="d-inline-block bg-hilight py-1 px-2 mx-1 practice-fib">
                  <DropTargetComponent
                    id="myDIV"
                    key={i}
                    onDrop={(draggedItem) => handleDrop(draggedItem, i)}
                    value={item}
                    blankLength={data[0].answer?.length}
                  > <span style={{visibility:"hidden"}}>...............................</span>
                  </DropTargetComponent>
                </div>
              )}
            </React.Fragment>
          );
        })}

    </>
  );
};
function PracticePteReadingFIB({ data, switchChecked }) {
  let { question_content } = data;
  const [options, setOptions] = useState(data?.question_options?.length > 0 && data.question_options[0].options?.length > 0 &&
    data.question_options[0].options
  );
  const [htmlContent, setHtmlContent] = useState(question_content);
  const [text, setText] = useState(data?.question_content);
  useEffect(() => {
    setText(data.question_content);
  }, [data]);
  const handlechangeChange = (e) => {

    console.log("e.target", e);
  };

  const setNewArray = (arr) => {
    console.log("arr", arr)
    setOptions(arr);
    // data.question_options[0].options = arr
  };
  console.log("data.question_optionss", data)
  return (
    <div className="practice-textarea d-flex flex-column align-items-start gap-2 mb-5">

      <Fib_rb data={data} switchChecked={switchChecked} />
      {/* <p id="px1">

        <Snippet
          text={question_content}
          data={data.question_options}
          optionsArr={options.length ? options : []}
          setNewArray={setNewArray}
        />
      </p>
      <div className="d-flex gap-3 bg-hilight p-3 w-100">
        {data?.question_options?.length > 0 && data.question_options[0].options?.length > 0 &&
          data?.question_options[0].options.map((item) => {
            return (
              <>
                <div className="d-inline-block mx-2 exam-form mb-4">
                  <DraggableComponent
                    id={item}
                    text={item}
                    handlechangeChange={handlechangeChange}
                  />
                </div>
              </>
            );
          })}
      </div> */}
    </div>
  )
}

export default PracticePteReadingFIB;