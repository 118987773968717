import React from "react";
import { Row, Col, Card, Nav, Tab, Tabs, Form } from "react-bootstrap";
import NoUiSlider from '../../components/Form/NoUiSlider';

function StudyInput() {

  return (
        <Row className="mt-5">
          <Col md="12">
            <h5 className="mb-5">Skill Profile</h5>
          </Col>
          <Col md="12" className="study-input">
            <Tab.Container id="pills-tabs-example" defaultActiveKey="input-percentage">
              <Nav variant="pills" className="mb-3 justify-content-center gap-3">
                <Nav.Item>
                  <Nav.Link eventKey="input-percentage">Input Percentage</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="input-score">Input Score</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="input-percentage">
                  <p className="text-center text-secondary">How to measure skill percentages</p>
                  <p className="text-center text-secondary">If inconvenient to get the accurate scores, drag the progress bars to be similar to the score report. (You can update the score later)</p>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Open Response Speaking and Writing </p>
                      <NoUiSlider tooltip={true} start="40" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Open Response Speaking and Writing </p>
                      <NoUiSlider tooltip={true} start="40" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Reproducing Spoken and Written Language </p>
                      <NoUiSlider tooltip={true} start="0" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Extended Writing </p>
                      <NoUiSlider tooltip={true} start="23" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Short Writing </p>
                      <NoUiSlider tooltip={true} start="40" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Extended Speaking </p>
                      <NoUiSlider tooltip={true} start="10" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Short Speaking </p>
                      <NoUiSlider tooltip={true} start="10" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Multiple-skills Comprehension </p>
                      <NoUiSlider tooltip={true} start="50" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Single-skill Comprehension </p>
                      <NoUiSlider tooltip={true} start="0" id="Tooltip-Range" />
                    </div>
                    <span className="d-flex align-items-center gap-1"><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /><span className="text-secondary">%</span></span>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="input-score">
                  <p className="text-center text-secondary">How to measure skill percentages</p>
                  <p className="text-center text-secondary">If inconvenient to get the accurate scores, drag the progress bars to be similar to the score report. (You can update the score later)</p>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Open Response Speaking and Writing </p>
                      <NoUiSlider tooltip={true} start="40" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Open Response Speaking and Writing </p>
                      <NoUiSlider tooltip={true} start="40" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Reproducing Spoken and Written Language </p>
                      <NoUiSlider tooltip={true} start="0" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Extended Writing </p>
                      <NoUiSlider tooltip={true} start="23" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Short Writing </p>
                      <NoUiSlider tooltip={true} start="40" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Extended Speaking </p>
                      <NoUiSlider tooltip={true} start="10" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Short Speaking </p>
                      <NoUiSlider tooltip={true} start="10" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Multiple-skills Comprehension </p>
                      <NoUiSlider tooltip={true} start="50" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                  <div className="d-flex justify-content-between border rounded mb-3 p-2">
                    <div className="d-flex flex-column w-50 text-secondary mb-1">
                      <p> Single-skill Comprehension </p>
                      <NoUiSlider tooltip={true} start="0" id="Tooltip-Range" />
                    </div>
                    <span><Form.Control type="number" id="exampleFormControlInputText1" style={{ width: "70px" }} /></span>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
  );
}

export default StudyInput;