import { Form } from "react-bootstrap";

export function DropDownWithoutDefaultLabel({
  object,
  value,
  disabled,
  text,
  name,
  data,
  onChange,
  width,
}) {
 console.log(value,"value43")
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label>{text}</Form.Label>
        <div className="form-control-wrap me-2" style={width}>
          <Form.Select
            removeItemButton
           value={value }
            name={name}
            onChange={onChange}
            disabled={disabled}
          >
           
           
     
        
            {data?.length > 0 &&
              data.map((item) => {
                console.log('object,item :>> ', object,item);
                return (
                  <>
                   {/* item?.toLowerCase()   ------> item must be to lower case then only works */}
                    <option value={object ? item?.id : item?.toLowerCase()}>
                      {object ? item?.value : item}
                    </option>
                  </>
                );
              })}
          </Form.Select>
        </div>
      </Form.Group>
    </>
  );
}
