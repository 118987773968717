import { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Tab, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthApi from "../../api/auth";
import { Icon, LinkList } from "../../components";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import Layout from "../../layout/default";
import FollowupFilter from "./FollowupFilter";
import UpdateFollowupModal from "./UpdateFollowupModal";
import FollowupTab from "./FollowupTab";
import Context from "../../context";
function FollowupsDataTable() {
  let navigate = useNavigate();

  const [followupData, setFollowupData] = useState([]);
  console.log(followupData, "####");
  const [tableData, setTabledata] = useState(followupData);


  let { userData, token } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData, "userdata123")



  let api = new AuthApi();
  const get_all_followup_list = async () => {
    await api
      .get_all_followupByInst(userData?._id)
      .then((res) => {
        if (res.status) {
          setFollowupData(res?.data.reverse());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    get_all_followup_list();
  }, []);

  useEffect(() => {
    setTabledata(followupData);
  }, [followupData]);

  const [followupIdPayload, setFollowupIdPayload] = useState();

  const [singleFollowup, setSingleFollowup] = useState({});

  const editHandler = (id) => {
    console.log(id, "abcEdit");

    setFollowupIdPayload(id);
  };

  const [deleteId, setDeleteId] = useState("");

  const deleteHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if(result.value) {
          setDeleteId(id);
        }
    })
    // setDeleteId(id);
  };

  const delete_Single_followup = async (id) => {
    await api
      .delete_Single_followup(id)
      .then((res) => {
        if (res?.msg) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: `${res?.msg}`,
          });
          get_all_followup_list();

          navigate("/lead-management/followup-list");

          // }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (deleteId) {
      delete_Single_followup(deleteId);
    }
  }, [deleteId]);

  const [showItemPerPage, setShowItemPerPage] = useState(10);

  const FollowupsColumns = [
    {
      name: "Enquiry Date",
      selector: (row) => row.enqdate,
      cell: (row) => (
        <span className="text-middark">
          {new Date(row?.createdAt).toISOString().split("T")[0]}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Student Name",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row?.name ? row?.name : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phno,
      cell: (row) => (
        <span className="text-middark">{row?.phone ? row?.phone : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status,
      cell: (row) => (
        // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //     : row.status === "Inactive" ? "danger"
        //         : "primary"}`
        // }>
        <span
          className="text-middark"
          //   className={`badge text-bg-${row.status ? "success" : "danger"}`}
        >
          {row.status? row.status?.toUpperCase():"-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Last Followup Date",
      selector: (row) => row.nextdate,
      cell: (row) => (
        <span className="text-middark">
          {row?.nextFollowUpDate ? row?.prev_follow_up_date : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Next Followup Date",
      selector: (row) => row.nextdate,
      cell: (row) => (
        <span className="text-middark">
          {row?.nextFollowUpDate ? row?.nextFollowUpDate : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Enquiry Type",
      selector: (row) => row.action,
      cell: (row) => (
        <span className="text-middark">
          {row?.program ? row?.program?.toUpperCase() : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-center">

          <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="view_enquiry">View Followup</Tooltip>}
            >
            <div
              className="del-btn"
              onClick={() => editHandler(row?.enquiry_id)}
            >
              <Icon name="eye"></Icon>
            </div>
            </OverlayTrigger>


            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="view_enquiry">Delete</Tooltip>}
            >
            <div className="del-btn" onClick={() => deleteHandler(row?._id)}>
              {/* <Icon name="trash"></Icon> */}
              <Icon name="trash"></Icon>
            </div>
            </OverlayTrigger>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];

  // let api = new AuthApi();
  // const get_all_followup_list = async () => {
  //   await api
  //     .get_all_followup()
  //     .then((res) => {
  //       if (res.status) {
  //         setFollowupData(res?.data.reverse());

  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   get_all_followup_list();
  // }, []);

  const get_Single_followup = async () => {
    console.log("inside single floowup effect", followupIdPayload);
    await api
      .get_Single_followup({ enquiry_id: followupIdPayload })
      .then((res) => {
        if (res?.data?.length > 0) {
          setSingleFollowup(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (followupIdPayload) {
      get_Single_followup();
    }
  }, [followupIdPayload]);

  console.log(
    followupIdPayload,
    "followupIdPayload",
    Object.keys(singleFollowup).length > 0,
    singleFollowup
  ); // DEBUGGING
  console.log("followupData :>> ", followupData);
  console.log("followupDatatableData :>> ", tableData);

  return (
    <Layout title="Add Student" content="container">
      <h1 className="mb-5">Follow-Up Details</h1>
      <Row className="g-gs">
        <Col md="12">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-end gap-2">
                {Object.keys(singleFollowup).length > 0 && (
                  <UpdateFollowupModal
                    // isFollowup={isFollowup}
                    // setisFollowup={(data) => setisFollowup(data)}
                    get_all_followup_list={()=>get_all_followup_list()}
                    singleFollowup={singleFollowup}
                    setSingleFollowup={(data) => setSingleFollowup(data)}
                    setFollowupIdPayload={(data) => setFollowupIdPayload(data)}
                    get_Single_followup={() => get_Single_followup()}
                  />
                )}
              </div>

              <div className="data-table-wrapper">
                <Row>
                  <Col lg="12">
                    <div className="d-flex gap-3">
                      <div className="data-table-action-wrap my-3">
                        <FollowupFilter
                          followupData={followupData}
                          setTabledata={(data) => setTabledata(data)}
                          setShowPage={(data) => setShowItemPerPage(data)}
                          showItemPerPage={showItemPerPage}
                        />
                      </div>
                    </div>
                  </Col>

                  

                </Row>

                {/* <Tab.Container id="enquiry-tabs" defaultActiveKey="inprocess">
                  <Nav variant="pills" className="mb-5 justify-content-start gap-2 enquiry-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="inprocess">In Process</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="not-interested">Not Interested</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="inprocess">
                <DataTable
                  columns={FollowupsColumns}
                  data={tableData}
                  noDataComponent={
                    <div className="p-2">There are no records found.</div>
                  }
                  sortIcon={<div className="data-table-sorter"></div>}
                  pagination={true}
                  paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                  }) => (
                    <div className="data-table-bottom">
                      <DataTablePagination
                        showItemPerPage={showItemPerPage}
                        itemPerPage={rowsPerPage}
                        totalItems={rowCount}
                        paginate={onChangePage}
                        currentPage={currentPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        setShowItemPerPage={setShowItemPerPage}
                      />
                    </div>
                  )}
                />
                </Tab.Pane>
                </Tab.Content>
                    </Tab.Container> */}


                    <FollowupTab
                    FollowupsColumns={FollowupsColumns}
                    tableData={tableData}
                    showItemPerPage={showItemPerPage}
                    setShowItemPerPage={(data)=>setShowItemPerPage(data)}
                    followupData={followupData}
                    setTabledata={(data)=>setTabledata(data)}
                    
                    />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default FollowupsDataTable;
