import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimePicker from "../../../../components/Form/TimePicker";
import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../../components";
import { useContext, useState, useEffect } from "react";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteListeningFillinBlanks() {
  let { id } = useParams();
  let api = new AdminApi();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    test_type: "listening_l_hcs",
    exam_type: "pte",
    instructions: "You will hear a recording. Type the missing words in each blank.",
    question_options: [
      {
        answer: ["test"],
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isFormValid, setIsFormValid] = useState(false);
  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      file,
      question,
      answer_time,
      question_begin_time,
    } = data;
    let questionOptions = data.question_options.some(
      (option) => option.answer.includes("")
    );
    return (
      instructions &&
      (file ? file : (data.question_content_audio || data.question_content_video)) &&
      tags &&
      // !questionOptions &&
            (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module_name &&
      module &&
      question_begin_time.length ===5
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);
  // const isSubmitDisabled = () => {
  //   return (
  //     data.tags === "" ||
  //     data.module_name != "" ||
  //     data.module != "" ||
  //     data.question_type === "" ||
  //     data.instructions === "" ||
  //     data.question_content === "" ||
  //     data.question_options.some((option) => option.options === "")
  //   );
  // };
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) => item.answer !== ""
    );
    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };

  const handleAdd = (name) => {
    const updatedOptions = [...data.question_options[0].answer];
    updatedOptions.push("");
    const newData = {
      ...data,
      question_options: [
        {
          ...data.question_options[0],
          answer: updatedOptions,
        },
      ],
    };
    setData(newData);

  };

  const handleChange = (e, index) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (index == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if (name == "answer") {
      const updatedOptions = [...data.question_options[0].answer];
      updatedOptions[index] = value;
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            answer: updatedOptions,
          },
        ],
      };
      setData(newData);
    } else if (name == "file") {
      setData({ ...data, ["file"]: e.target.files[0] });
    } else {
      if (name !== "options" && name !== "answer") {
        if (name == "question_type") {
          const options = e.target.options;
          const selectedValues = [];
          if (options.length == 0) {
            setSelectedOptions([]);
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues);
          }
          setData({
            ...data,
            isMock: selectedValues.includes("isMock") ? true : false,
            isPractice: selectedValues.includes("isPractice") ? true : false,
            isSection: selectedValues.includes("isSection") ? true : false,
            isQuestion: selectedValues.includes("isQuestion") ? true : false,
            isPrediction: selectedValues.includes("isPrediction") ? true : false,
          });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };
  const trash = (type, index) => {
    if (type === "answer") {
      const updatedOptions = [...data.question_options[0].answer];
      updatedOptions.splice(index, 1);
      const newData = {
        ...data,
        question_options: [
          {
            ...data.question_options[0],
            answer: updatedOptions,
          },
        ],
      };
      setData(newData);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isAudio = false;

   if(!data.question_content_audio){ if (!data.question_content_audio && data?.file?.length > 0) {
      const fileType = data.file[0].type;
      if (fileType.startsWith("audio/")) {
        isAudio = true;
        formObject.append("question_content_audio", data.file[0]);
      } else {
        Swal.fire({
          icon: "error",
          title: "File must be an audio ",
          text: "Please upload an audio file.",
        });
        return;
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "File is required",
        text: "Please upload an audio file.",
      });
      return;
    }}
    var data1 = removeBlankItems();
    for (const [key, value] of Object.entries(data)) {
      if (key !== "file" && key !== "question_options") {
        if(value!=null){
          formObject.append(key, value);
        }
      } else if (key === "question_options") {
        value.forEach((option, index) => {
          option.answer.forEach((answer, subIndex) => {
            formObject.append(
              `question_options[${index}][answer][${subIndex}]`,
              answer
            );
          });
        });
      }
    }
    setloader(!loader)
    await api
      .add_Question_Listening(formObject)
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
          ;
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
            // text: "Please upload an audio or video file.",
          });
        }
        setloader(!loader)
      })
      .catch((err) => {
        setloader(!loader)
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("data?.question_options", data)
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Listening Fill in Blanks Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => { setSelectedOptions(e) }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e); setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                   
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">Add Audio</Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_audio ?
                            (<>
                              <div className="d-flex gap-2">
                                <audio src={data?.question_content_audio} controls></audio>
                                <span className="d-flex align-items-center"><button className="btn btn-danger py-1" onClick={() => setData({ ...data, question_content_audio: "" })}>Delete</button></span>
                              </div>

                            </>)
                            :
                            <FileUpload
                            filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          }
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Add Paragraph</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="question_content"
                            onChange={(e) => handleChange(e)}
                            value={data.question_content}
                            placeholder="Add Paragraph"
                            id="paragraph"
                            rows="5"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <h5 className="mb-3">Add Blank Input Field</h5>
                  <div className="d-flex gap-3 flex-wrap" id="blankfields">
                    {data?.question_options[0]?.answer != 0 &&
                      data?.question_options[0].answer?.map((item, i) => {
                        return (
                          <>
                            {" "}
                            <Form.Group className="form-group">
                              <div className="form-control-wrap" key={i}>
                                <Form.Control
                                  style={{ width: "150px" }}
                                  type="text"
                                  onChange={(e) => handleChange(e, i)}
                                  value={item != "test" ? item : null}
                                  name="answer"
                                  placeholder="Enter Text"
                                />
                                {data?.question_options?.[0]?.answer?.length !=
                                  1 && (
                                    <div
                                      className="form-control-icon end"
                                      onClick={() => trash("answer", i)}
                                    >
                                      <Icon variant="danger" name="trash"></Icon>
                                    </div>
                                  )}
                              </div>
                            </Form.Group>
                          </>
                        );
                      })}
                    <Button
                      variant="outline-primary"
                      name="answer"
                      onClick={() => handleAdd("answer")}
                      style={{ width: "50px" }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </div>
                </Col>
                <Col lg="9">
                      <TranscriptExplanation setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                    </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        value={data.tags}
                        placeholder="#Tag"
                        name="tags"
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between">
                  <PrevNextButton id={id} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid}/>
               
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteListeningFillinBlanks;
