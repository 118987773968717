import React, { useState } from 'react'
import StudentResultContext from './StudentResultContext'

const StudentResultContextProvider = ({children}) => {
    const [studentResult,setStudentResult] =useState()
  return (
    <StudentResultContext.Provider value={{studentResult,setStudentResult}}>

      {children}
    </StudentResultContext.Provider>
  )
}

export default StudentResultContextProvider
