import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import { Button, Form, Row, Col, ButtonGroup } from "react-bootstrap";
import { Icon } from "../../components";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

// export file component
const Export = ({ data }) => {
  const fileName = "user-data";

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="export-options d-flex align-items-center me-2">
      <div className="export-title small me-2">Export</div>
      <div className="btn-group">
        <Button variant="outline-light" onClick={() => exportCSV()}>
          CSV
        </Button>
        <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button>
      </div>
    </div>
  );
};

// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
));

function TeamDataTable({
  send_table_data_to_parent,
  inst_id,
  data,
  edit_question_by_id,
  prediction_payload,
  columns,
  className,
  expandableRows,
  actions,
  tableClassName,
  selectableRows,
  paginate,
  setPaginate,
  filters,
  setFilters,
  totalDocuments,
  ...props
}) {
  let location = useLocation()?.pathname;
  let navigate = useNavigate();
  const [tableData, setTableData] = useState(data);
  console.log("TABLEDATA",tableData);
  console.log("FILTERS",filters);
  
  
  const [tableData_shown, setTableData_shown] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState((location == "/material-management/view-questions" || location.includes("create-test"))?20:5);
  const [mobileView, setMobileView] = useState(false);
  const [selecvalue, setselecvalue] = useState("all");
  const [itemsPerPage,setItemsPerPage] = useState(20);
  const [currentPageForReactPaginate, setCurrentPageForReactPaginate] = useState(0);
  // console.log("table", data);
  // filter items by name
  useEffect(() => {
    setTableData(data);
  }, [data]);
  console.log("tableData", tableData);
  useEffect(() => {
    if (
      location == "/material-management/view-questions" ||
      location.includes("/exams_question")
    ) {
      send_table_data_to_parent(tableData);
    }
  }, [tableData]);

  // function to change the table design view to expanable under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [filterModules, setFilterModules] = useState({
    module: ["writing", "speaking", "reading", "listening"],
    module_name: "all",
    test_wise: "all",
    tags: "",
  });
  let { module_name, module, test_wise, tags } = filterModules;

  const handlePageChange = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setPaginate({ offset: newOffset, limit: itemsPerPage, currentPage: event.selected + 1 });
    setCurrentPageForReactPaginate(event.selected);
  };

  useEffect(() => {
    if(location.includes("/material-management/view-questions")){
      setPaginate({ offset: 0, limit: itemsPerPage, currentPage: 0 }); 
      setCurrentPageForReactPaginate(0)
    }
    
  },[itemsPerPage, filters])

  const applyFilter = (e, sec) => {

    

    const { name,value } = e.target;

    // TOCHAN
    console.log("filters",filters);
    console.log("filtersed",name,value);
    if(sec){
      setFilters({...filters,sub_module:value})
    }
    else{
      setFilters({...filters,[name]:value})
    }
    


    if (sec) {
      if (value === "all") {
        setFilterModules({...filterModules,module_name:"all"})
        if (filterModules.length === 0) {
          setTableData(data);
          setTableData_shown(data);
        } else {
          const filteredData = data.filter((item) =>
            filterModules?.module?.includes(item.module)
          );
          setTableData(filteredData);
          setTableData_shown(filteredData);
          setselecvalue("all");
        }
      } else {
        setFilterModules({...filterModules,module_name:value})
        const filteredData = tableData_shown.filter(
          (item) => item.module_name === value
        );
        setselecvalue(value);
        setTableData(filteredData);
      }
    } else {
      if (e.target.checked) {
        setFilterModules({...filterModules,module_name:"all"})
        setFilterModules({
          ...filterModules,
          module: [...filterModules.module, value],
        });
      } else {
        setFilterModules({...filterModules,module_name:"all"})
        setFilterModules({
          ...filterModules,
          module: filterModules.module.filter(
            (module, module_name) => module !== value
          ),
        });
      }
    }
  };
  const filterSwitchLogic = (item, test_wise) => {
    switch (test_wise) {
      case "isMock":
        return item?.isMock_examID !== null && item?.isMock_examID !== "null";
      case "isSection":
        return (
          item?.isSection_examID !== null && item?.isSection_examID !== "null"
        );
      case "isQuestion":
        return (
          item?.isQuestion_examID !== null && item?.isQuestion_examID !== "null"
        );
      default:
        return true;
    }
  };
  let valuesArray = tags.split(",");
  console.log("valuesArray",valuesArray)
  useEffect(() => {
    if (
      !window.location.pathname.includes("institute/profile") &&
      !window.location.pathname.includes("institute-detail") &&
      window.location.pathname.includes("material-management/view-questions")
    ) {
        const filteredData = data.filter((item) => {
          return (
            (!module_name ||
              module_name === "all" ||
              item.module_name === module_name) &&
            (!module.length || module?.includes(item.module)) &&
            (!test_wise ||
              test_wise === "all" ||
              filterSwitchLogic(item, test_wise)) &&
            (!tags ||
              valuesArray.some((val) =>
                item?.tags.toLowerCase().split(" ").includes(val.toLowerCase()) 
              ))
          );
        });

        console.log(filteredData,"filtered data")
        // setTableData(filteredData);  // filters work in backend
        setTableData_shown(filteredData);
    }
  }, [data, filterModules]);

  let speaking = [
    { value: "read_alouds", text: "Read Aloud" },
    { value: "repeat_sentences", text: "Repeat Sentence" },
    { value: "describe_images", text: "Describe Image" },
    { value: "retell_lectures", text: "Re-Tell Lecture" },
    { value: "answer_questions", text: "Answer Short Question" },
  ];

  let writing = [
    { value: "swts", text: "Summarize Writing Text" },
    { value: "essays", text: "Essay" },
  ];

  let reading = [
    { value: "fib_wr", text: "Reading and Writing Fill in the Blanks" },
    { value: "r_mcm", text: "Multiple Choice, Choose Multiple Answer" },
    { value: "ro", text: "Re-order Paragraphs" },
    { value: "r_mcs", text: "Multiple Choice, Choose Single Answer" },
    { value: "fib_rd", text: "Reading: Fill in the Blanks" },
  ];

  let listening = [
    { value: "ssts", text: "Summarize Spoken Text" },
    { value: "l_mcm", text: "Multiple Choice Multiple Answer" },
    { value: "l_fib", text: "Fill in the Blanks" },
    { value: "l_hcs", text: "Highlight Correct Summary" },
    { value: "l_mcs", text: "Multiple Choice Single Answer" },
    { value: "l_smw", text: "Select Missing Words" },
    { value: "hiws", text: "Highlight Incorrect Words" },
    { value: "wfds", text: "Write From Dictation" },
  ];
  const filterForTags = (e, test) => {
    let { value, name } = e.target;
    value = value?.toLowerCase();
    if (test) {
      // document.getElementById("inputId22").value = "";
        setFilterModules({...filterModules,test_wise:test})
    } else {
      if (value) {
        let valuesArray = value.split(",");
        let filterArray = tableData_shown.filter((item) =>
          valuesArray.some(
            (val) => item?.tags?.toLowerCase().includes(val)
            //  ||
            // item?.question_content?.toLowerCase().includes(val) ||
            // item?.question_transcript?.toLowerCase().includes(val)
          )
        );

        setTableData(filterArray);
      } else {
        setTableData(data);
      }
    }
  };

  
  return (
    <div className="data-table-wrapper">
      {location != "/material-management/view-questions" && (
        <div className="data-table-top justify-content-end">
          <div className="data-table-search d-none">
            <input
              className="form-control"
              placeholder="Search by name"
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="data-table-action-wrap d-none">
            {actions && <Export data={data} />}
            <div className="data-table-select">
              <span className="text-secondary mx-1">Entries</span>
              <select
                className="form-select"
                onChange={(e) => setShowItemPerPage(e.target.value)}
                value={showItemPerPage}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="100">100</option>
              </select>
              <span className="text-secondary mx-1">Show</span>
            </div>
          </div>
          {location == "/view-question-pattern" && (
            <Button
              className="d-none d-md-inline-flex"
              variant="outline-primary"
              onClick={() => {
                navigate("/add-pattern");
              }}
            >
              <Icon name="plus" />

              {/* {location == "/view-promocode" && ( */}
              <p>Add Pattern</p>
              {/* )} */}
            </Button>
          )}  




{location == "/view-score-feedback" && (
            <Button
              className="d-none d-md-inline-flex"
              variant="outline-primary"
              onClick={() => {
                navigate("/score-feedback");
              }}
            >
              <Icon name="plus" />

              {/* {location == "/view-promocode" && ( */}
              <p>Add Score Feedback</p>
              {/* )} */}
            </Button>
          )}



          {location == "/view-plan-pricing" && (
            <Button
              className="d-none d-md-inline-flex"
              variant="outline-primary"
              onClick={() => {
                navigate("/add-plan-pricing");
              }}
            >
              <Icon name="plus" />

              {/* {location == "/view-promocode" && ( */}
              <p>Add Plan & Pricing</p>
              {/* )} */}
            </Button>
          )}
          {location == "/view-promocode" && (
            <Button
              className="d-none d-md-inline-flex"
              variant="outline-primary"
              onClick={() => {
                navigate("/add-promocode");
              }}
            >
              <Icon name="plus" />

              {location == "/view-promocode" && <p>Add Promocde</p>}
            </Button>
          )}

          {location != "/create-test" &&
            location != "/view-promocode" &&
            location != "/view-question-pattern" &&
            !location.includes("/exams_question") &&
            location != "/material-management/view-questions" &&
          location != "/view-score-feedback"  &&
            location != "/view-plan-pricing" && (
              <Button
                className="d-none d-md-inline-flex"
                variant="outline-primary"
                onClick={() => {
                  return (
                    <>
                      {(location.includes("/institute-detail") ||
                        location.includes("institute/profile") ||
                        location.includes("/view-team")) &&
                      inst_id
                        ? navigate(`/add-team/${inst_id}`)
                        : navigate("/add-team")}
                      {location == "/view-staff" &&
                        navigate("/admin/admin-add-staff")}
                      {location == "/material-management/view-questions" &&
                        navigate("/material-management/add-questions")}
                    </>
                  );
                }}
              >
                <Icon name="plus" />
                {(location.includes("/institute-detail") ||
                  location.includes("institute/profile") ||
                  location.includes("/view-team")) && <p>Add Team</p>}
                {location == "/view-staff" && (
                  <p to="/admin/admin-add-staff">Add Staff</p>
                )}
                {/* {location == "/material-management/view-questions" && (
                  <p>Add Question</p>
                )} */}
              </Button>
            )}
        </div>
      )}

      <>
        {(location == "/material-management/view-questions" ||
          location.includes("/exams_question")) && (
          <>
            <Row className="g-gs mt-1 mb-5">
              {/* <Col md="12">
                <div className="data-table-select ">
                  <select
                    className="form-select"
                    onChange={(e) => setShowItemPerPage(e.target.value)}
                    value={showItemPerPage}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                  <span className="text-secondary mx-1">Show</span>
                </div>
              </Col> */}
              <Col md="8">
                <ButtonGroup
                  aria-label="Basic checkbox toggle button group"
                  className="d-flex gap-2 rounded-0"
                >
                  <input
                    type="radio"
                    name="module_name"
                    className="btn-check"
                    defaultChecked
                    id="btncheck4"
                    autoComplete="off"
                    value="listening"
                    onClick={(e) => applyFilter(e)}
                  />
                  <Button
                    as="label"
                    variant="outline-hiprimary"
                    htmlFor="btncheck4"
                    className="rounded-2"
                  >
                    Listening
                  </Button>

                  <input
                    type="radio"
                    name="module_name"
                    className="btn-check"
                    id="btncheck3"
                    
                    autoComplete="off"
                    value="reading"
                    onClick={(e) => applyFilter(e)}
                  />
                  <Button
                    as="label"
                    variant="outline-hiwarning"
                    htmlFor="btncheck3"
                    className="rounded-2"
                  >
                    Reading
                  </Button>

                  <input
                    type="radio"
                    name="module_name"
                    className="btn-check"
                    id="btncheck1"
                    
                    autoComplete="off"
                    value="speaking"
                    onClick={(e) => applyFilter(e)}
                  />
                  <Button
                    as="label"
                    variant="outline-hisuccess"
                    htmlFor="btncheck1"
                    className="rounded-2"
                  >
                    Speaking
                  </Button>

                  <input
                    type="radio"
                    name="module_name"
                    className="btn-check"
                    id="btncheck2"
                    
                    autoComplete="off"
                    value="writing"
                    onClick={(e) => applyFilter(e)}
                  />
                  <Button
                    as="label"
                    variant="outline-danger"
                    htmlFor="btncheck2"
                    className="rounded-2"
                  >
                    Writing
                  </Button>
                </ButtonGroup>
              </Col>
              <Col md="4">
                <div className="form-control-wrap">
                  <Form.Select
                    removeItemButton
                    name="module_name"
                    value={module_name}
                    onChange={(e) => applyFilter(e, "sec")}
                  >
                    <option value="all">All</option>
                    {filters?.module_name?.includes("speaking") &&
                      speaking.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    {filters?.module_name?.includes("writing") &&
                      writing.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    {filters?.module_name?.includes("reading") &&
                      reading.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    {filters?.module_name?.includes("listening") &&
                      listening.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>

              <Col md="7">
                <div className="d-flex gap-2">
                  <h5 className="mb-3">Select Test Wise : </h5>
                  <Form.Check
                    type="radio"
                    defaultChecked
                    checked={test_wise == "all" ? true : false}
                    
                    onChange={(e) => {
                      filterForTags(e, "all");
                      setFilters({...filters,test_type:"all"})
                    }}
                    name="RadioButton1"
                    id="radioaddquestion-1 "
                    label="All"
                  />
                  <Form.Check
                    type="radio"
                    checked={test_wise == "isMock" ? true : false}
                    onChange={(e) => {
                      filterForTags(e, "isMock")
                      setFilters({...filters,test_type:"isMock"})
                    }}
                    name="RadioButton1"
                    id="radioaddquestion-1 "
                    label="Mock Test"
                  />
                  <Form.Check
                    type="radio"
                    checked={test_wise == "isSection" ? true : false}
                    onChange={(e) => {
                      filterForTags(e, "isSection")
                      setFilters({...filters,test_type:"isSection"})
                    }}
                    name="RadioButton1"
                    id="radioaddquestion-2 "
                    label="Section Wise test"
                  />
                  <Form.Check
                    type="radio"
                    checked={test_wise == "isQuestion" ? true : false}
                    onChange={(e) => {
                      filterForTags(e, "isQuestion")
                      setFilters({...filters,test_type:"isQuestion"})
                    }}
                    name="RadioButton1"
                    id="radioaddquestion-2 "
                    label="Question Wise test"
                  />
                </div>
              </Col>
              <Col md="5">
                <div className="d-flex ">
                  <h5 className="mt-2">Selected Prediction : </h5>{" "}
                  <Button
                    disabled={!prediction_payload?.qstID?.length}
                    onClick={() => edit_question_by_id(true)}
                    className="btn btn-primary mx-4"
                  >
                    Enable{" "}
                  </Button>{" "}
                  <Button
                    disabled={!prediction_payload?.qstID?.length}
                    onClick={() => edit_question_by_id(false)}
                    className="btn btn-danger"
                  >
                    Disable{" "}
                  </Button>
                </div>
              </Col>
              <Col md="6">
                <div className="form-control-wrap ">
                  <Form.Group className="form-group">
                    <div className=" d-flex">
                      <Form.Control
                        type="text"
                        id="inputId22"
                        value={filters?.title}
                        onChange={(e) => {
                          // setFilterModules({...filterModules,tags:e.target.value})
                          setFilters({...filters,title:e.target.value})
                        }}
                        placeholder="Filter"
                      />
                    </div>
                  </Form.Group>
                </div>
              </Col>
              {location.includes("/material-management/view-questions") &&
                <Col>
                <div class="d-flex align-items-center mb-3 justify-content-end"><span className="text-secondary mx-1">Show</span><select
                  className="form-select"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value)
                    setCurrentPageForReactPaginate(0)
                  }}
                  value={itemsPerPage}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  {/* <option value="25">25</option> */}
                </select>
                
              </div>

                </Col>
              }

              <Col md="6">
                <div className="form-control-wrap ">
                  <Button className="btn btn-danger" onClick={()=>{setFilterModules({    module: ["writing", "speaking", "reading", "listening"],
    module_name: "all",
    test_wise: "all",
    tags: "",})}}>Clear</Button>
                </div>
              </Col>
              
              {location.includes("/material-management/view-questions") && itemsPerPage >= 100 && 
                <Col md="6">
                  <div className="react-pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(totalDocuments / itemsPerPage)}
                      // pageCount={Math.ceil(data?.totalDocuments / itemsPerPage)}
                      forcePage={currentPageForReactPaginate}
                      previousLabel="<previous"
                      renderOnZeroPageCount={null}
                      pageClassName="toClick"
                  /> 
                </div>
                </Col>
              }

            </Row>
          </>
        )}
      </>
      {/* </li>
        </ul> */}
        {location.includes("/create-test") && 
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-center mb-3 justify-content-end"><span className="text-secondary mx-1">Show</span><select
                  className="form-select"
                  onChange={(e) => setShowItemPerPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
          </div>
        </div>}
      <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination={location == "/material-management/view-questions" && false}
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            {(
          location.includes("/exams_question")) ? 
          <div className="react-pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageChange}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalDocuments / itemsPerPage)}
            // pageCount={Math.ceil(data?.totalDocuments / itemsPerPage)}
            forcePage={currentPageForReactPaginate}
            previousLabel="<previous"
            renderOnZeroPageCount={null}
            pageClassName="toClick"
        /> </div>:
            <div style={{ display: location === "/create-test" ? "none" : "block" }}>
              <DataTablePagination
                
                showItemPerPage={showItemPerPage}
                itemPerPage={rowsPerPage}
                totalItems={rowCount}
                paginate={onChangePage}
                currentPage={currentPage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                setShowItemPerPage={setShowItemPerPage}
              />
            </div>
          }
          </div>
        )}
      />
      <div className="react-pagination" style={{marginTop:"2rem"}}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageChange}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalDocuments / itemsPerPage)}
            // pageCount={Math.ceil(data?.totalDocuments / itemsPerPage)}
            forcePage={currentPageForReactPaginate}
            previousLabel="<previous"
            renderOnZeroPageCount={null}
            pageClassName="toClick"
        /> 
      </div>
    </div>
  );
}

export default TeamDataTable;
