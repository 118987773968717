import React, { useState } from 'react';
import { Placeholder, ProgressBar, Card, Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ExamHeader from '../../../../layout/exam/Header';
import ExamFooter from '../../../../layout/exam/Footer';
import { Icon } from '../../../../components';
import Tinymce from '../../../../components/Form/editors/Tinymce';
import { Height } from '@mui/icons-material';
import PracticeTextArea from '../../../practice-test/PTE/PracticeComponents/PracticeTextArea';

function WriteEssay({data}) {
    const [demoModal, setDemoModal] = useState(false);
    const demoModalClose = () => setDemoModal(false);
    const demoModalShow = () => setDemoModal(true);

    return (
        <>
               <div className="container my-5 text-examheadtext">
              <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
              <br />
              <p>
               {data.question_content}
              </p>
              <br />
              <br />
           <PracticeTextArea/>
              <br />
              <br />
            </div>
        </>
    )
}

export default WriteEssay;