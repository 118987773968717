

import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AuthApi from "../../api/auth";
import {
  Icon,
  LinkList,
  Select
} from "../../components";
import Block from "../../components/Block/Block";
import Context from "../../context";
import Layout from "../../layout/default";
import BranchDataTable from "../branch/BranchDataTable";
import BranchFilter from "../branch/BranchFilter";
import TicketsIntFilter from "./TicketsIntFilter";
import AddFollowups from "../followups/AddFollowups";
import StudentTicket from "../tickets/StudentTicket";


function ViewTicketsListPage({instId}) {
  let api = new AuthApi();
  let { userData,branchId } = useContext(Context);
  userData = JSON.parse(userData)
let isAdmin = userData?.login_type == "0"
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [alldata, setAllData] = useState([]);
  const [showItemPerPage, setShowItemPerPage] = useState(10);


  console.log('data :>> ', data);

  useEffect(() => {
    get_support_enquiry();
  }, []);
 
  const get_support_enquiry = async () => {
    await api
      .get_support_enquiry(userData._id,"STUDENT")
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res?.data.reverse());
          setAllData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [followupIdPayload, setFollowupIdPayload] = useState();
  const [singleFollowup, setSingleFollowup] = useState({});
  const [isFollowup, setIsFollowup] = useState(false);
  const editHandler = (id) => {
    console.log(id, "abcEdit");

    setFollowupIdPayload(id);
  };


  const get_Single_followup = async () => {
    console.log("inside single floowup effect", followupIdPayload);
    await api
      .get_single_support_enquiry( followupIdPayload)
      .then((res) => {
        if (res?.data?.length > 0) {
          setSingleFollowup(res?.data?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (followupIdPayload) {
      get_Single_followup();
    }
  }, [followupIdPayload]);

  const TicketsColumns = [

    {
      name: "SR No.",
      cell: (row, index) => <span className="text-middark">{index + 1}</span>,
      sortable: false,
    },
    {
      name: "Subject",
      grow: "3",
      selector: (row) => row.subject,
      cell: (row) => (
        <span className="text-middark">{row?.subject}</span>
    ),
    sortable: true,
    },
    {
      name: "Department",
      grow: "3",
      selector: (row) => row.department,
      cell: (row) => <span className="text-middark">{row.department}</span>,
      sortable: true,
    },

    {
      name: "Message",
      grow: "3",
      selector: (row) => row?.message,
      cell: (row) => <span className="text-middark">{row?.message}</span>,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Student",
      grow: "3",
      selector: (row) => row?.user_id?.name,
      cell: (row) => <span className="text-middark">{row?.user_id?.name}</span>,
      sortable: true,
      hide: "lg",
    },

  
    {
      name: "Status",
      grow: "5",
      selector: (row) => row.status,
      cell: (row) => (
          <span className="text-middark">{row.status}</span>
      ),

      cell: (row) => (
          // <span className={`badge text-bg-${row.status === "Active" ? "success"
          //     : row.status === "Inactive" ? "danger"
          //         : "primary"}`
          // }>
          <span className={`badge text-bg-${row.status == "pending"  || row.status == "reopen" ? "danger"
              : "success"}`
          }>
              {row.status == "pending"  || row.status == "reopen" ? "Pending" : 'Complete'}
          </span>
      ),

      sortable: true,
  },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
         
              <div
                className="del-btn"
                onClick={() => editHandler(row?._id)}
              >
                {/* <Icon name="user-add"></Icon> */}
                <Icon name="edit"></Icon>
              </div>
            
            </LinkList>
            </div>
      )
    }
  ];
  console.log("instId",)
  console.log('alldata :>> ', alldata,data);
  return (
  
  
    <Layout title="Users List" content="container">

{Object.keys(singleFollowup).length > 0 && (
                  <StudentTicket
                    // isFollowup={isFollowup}
                    // setisFollowup={(data) => setisFollowup(data)}
                    // get_all_followup_list={()=>get_all_followup_list()}
                    singleFollowup={singleFollowup}
                    setSingleFollowup={(data) => setSingleFollowup(data)}
                    setFollowupIdPayload={(data) => setFollowupIdPayload(data)}
                    get_Single_followup={() => get_Single_followup()}
                    get_support_enquiry={() => get_support_enquiry()}
                  />
                )}

      <h1 className="mb-5"> Ticket Details</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
              <div class="data-table-wrapper">
             {/* { data.length>0 && */}
              <TicketsIntFilter
                
                allData={alldata}
                setAllData={setAllData}
            tableData={data}
            setData={(data) => setData(data)}
            showItemPerPage={showItemPerPage}
            setShowPage={(value) => setShowItemPerPage(Number(value))}
         
                />
                {/* } */}
                <BranchDataTable
                  tableClassName="data-table-head-light table-responsive"
                  data={data}
                  isAdmin={isAdmin}
                  BatchColumns={TicketsColumns}
                  showItemPerPage={showItemPerPage}
                  setShowItemPerPage={setShowItemPerPage}
                />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
     
  )
   
}

export default ViewTicketsListPage;

