export function years(){
    const arr = []
    for(var i=2020;i<=2080;i++){
        arr.push(i)
    }
    return arr
}

// function months(){
//     for(var i=1;i<=12;i++){
//         return i
//     }
// }