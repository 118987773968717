import { Form, Row, Col, Button } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../components";
import { useEffect } from "react";

function TranscriptExplanation({
  transcript,
  sample_answer,
  explantion,
  data,
  onChange,
  setState,
}) {
  const [showContent, setShowContent] = useState({
    transcript: transcript,
    explanation: false,
    answer: sample_answer,
  });
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!show) {
      updateRecordingState();
    }
  }, [data]);
  const updateRecordingState = async () => {
    setShowContent({
      transcript:
        data?.question_transcript && data?.question_transcript != "null"
          ? true
          : transcript?transcript:false,
      explanation:
        data?.question_explanation && data?.question_explanation != "null"
          ? true
          : false,
      answer: data?.sample_answer && data?.sample_answer != "null" ? true : sample_answer?sample_answer:false,
    });
  };
  const toggleContent = (content) => {
    setShow(true);
    setShowContent({ ...showContent, [content]: !showContent[content] });
  };
  console.log("showContent", showContent);
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label className="ms-3 fw-bold">
          Add Transcript {!transcript && "(Optional)"}
          {!transcript && (
            <Button
              className="btn-icon"
              variant="outline-transparent"
              onClick={(e) => {
                setState("question_transcript");
                toggleContent("transcript");
              }}
            >
              <Icon
                name={showContent.transcript ? "minus-circle" : "plus-circle"}
                variant={showContent.transcript ? "danger" : "primary"}
              />
            </Button>
          )}
        </Form.Label>
        {showContent.transcript && (
          <div className="form-control-wrap">
            <Form.Control
              as="textarea"
              rows="5"
              type="text"
              id="addtranscript"
              placeholder="Transcript"
              name="question_transcript"
              onChange={(e)=>{setShow(true);onChange(e)}}
              value={data?.question_transcript}
            />
          </div>
        )}
      </Form.Group>

      <Form.Group className="form-group mt-3">
        <Form.Label className="ms-3 fw-bold">
          Add Sample Answer {!sample_answer && "(Optional)"}
          {!sample_answer && (
            <Button
              name="answer"
              className="btn-icon"
              variant="outline-transparent"
              onClick={(e) => {
                setState("sample_answer");
                toggleContent("answer");
              }}
            >
              <Icon
                name={showContent.answer ? "minus-circle" : "plus-circle"}
                variant={showContent.answer ? "danger" : "primary"}
              />
            </Button>
          )}
        </Form.Label>
        {showContent.answer && (
          <div className="form-control-wrap">
            <Form.Control
              as="textarea"
              rows="5"
              type="text"
              id="addanswer"
              onChange={(e)=>{setShow(true);onChange(e)}}
              placeholder="Sample answer"
              name="sample_answer"
              value={data?.sample_answer}
            />
          </div>
        )}
      </Form.Group>
      <Form.Group className="form-group mt-3">
        <Form.Label className="ms-3 fw-bold">
          Add Explanation {!explantion && "(Optional)"}
          {!explantion && (
            <Button
              name="question_explanation"
              className="btn-icon"
              variant="outline-transparent"
              onClick={(e) => {
                setState("question_explanation");
                toggleContent("explanation");
              }}
            >
              <Icon
                name={showContent.explanation ? "minus-circle" : "plus-circle"}
                variant={showContent.explanation ? "danger" : "primary"}
              />
            </Button>
          )}
        </Form.Label>
        {showContent.explanation && (
          <div className="form-control-wrap">
            <Form.Control
              as="textarea"
              rows="5"
              type="text"
              id="addexplanation"
              onChange={(e)=>{setShow(true);onChange(e)}}
              placeholder="Explanation"
              name="question_explanation"
              value={data?.question_explanation}
            />
          </div>
        )}
      </Form.Group>
      
    </>
  );
}

export default TranscriptExplanation;
