import { useState } from "react";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import TeamDataTable from "./TeamDataTable";
import { useEffect } from "react";
import AuthApi from "../../api/auth";
import Swal from 'sweetalert2/src/sweetalert2.js';
import {
    Icon,
    Image,
    Media,
    Pureknob,
    ChartLegend,
    CustomDropdownMenu,
    CustomDropdownToggle,
    MediaGroup,
    LinkList,
    LinkListItem,
    MediaText,
  } from "../../components";
  import {Link} from "react-router-dom"
function ViewTeam() {
    let api = new AuthApi();
    const [data,SetData] = useState([])

    useEffect(()=>{
        getTeamByInstituteId();
    },[])
    const removeTeamById = async (params) => {
        await api
          .inactive_user(params)
          .then((res) => {
            console.log("--", res);
            if (res.status) {
              getTeamByInstituteId();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.msg,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
    const getTeamByInstituteId = async () => {
        await api
          .get_teachers_By_Inst({ id: data._id })
          .then((res) => {
            if (res?.data?.length > 0) {
                SetData(res?.data.reverse());
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
      
      const TeamColumns = [
        {
          name: "Email",
          selector: (row) => row.name,
          cell: (row) => (
            <MediaGroup>
              <Media size="md" shape="circle" variant={row.theme && row.theme}>
                {/* { row.avatar ? 
                            <Image src={row.avatar} staticImage/> :
                            <span className="smaller fw-medium">{(row?.name)}</span>
                        } */}
              </Media>
              <MediaText>
                <Link to={`/edit-team/${row._id}`} className="title">
                  {row?.email}
                </Link>
              </MediaText>
            </MediaGroup>
          ),
          sortable: true,
        },
        {
          name: "Name",
          selector: (row) => row.username,
          cell: (row) => <span className="text-middark">{row.name}</span>,
          sortable: true,
        },
        {
          name: "Mobile",
          selector: (row) => row.username,
          cell: (row) => <span className="text-middark">{row.mobile}</span>,
          sortable: true,
        },
        {
          name: "status",
          selector: (row) => row.status,
          cell: (row) => (
            // <span className={`badge text-bg-${row.status === "Active" ? "success"
            //     : row.status === "Inactive" ? "danger"
            //         : "primary"}`
            // }>
            <span
              className={`badge text-bg-${
                row.is_deleted == 0
                  ? "success"
                  : row.is_deleted == 1
                  ? "danger"
                  : "primary"
              }`}
            >
              {row.is_deleted == 0 ? "Active" : "Inactive"}
            </span>
          ),
          sortable: true,
        },    {
          name: "Role",
          selector: (row) => row?.teacher_profile?.teacher_role,
          cell: (row) => (
              <span className="text-middark">{row?.teacher_profile?.teacher_role}</span>
          ),
          sortable: true,
          hide: "lg",
      },
        {
          name: "action",
          cell: (row) => (
            <div className="text-end w-100">
              <LinkList className="link-list-hover-bg-primary link-list-md d-flex justify-content-end">
                <LinkListItem to={`/edit-team/${row._id}`}>
                  <Icon name="edit"></Icon>
                </LinkListItem>
    
                <div
                  className="link-list-hover-bg-primary link-list-md d-flex justify-content-end del-btn"
                  onClick={() => removeTeamById({ _id: row._id })}
                >
                  <Icon name="trash"></Icon>
                </div>
                {/* <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
                                            <Icon name="trash"></Icon>
                                        </LinkListItem> */}
              </LinkList>
            </div>
          ),
          sortable: false,
        },
      ];
  return (
    <Layout title="Users List" content="container">
    <h1 className="mb-5"> Team List</h1>
    <Block>
      <Row className="g-gs">
        <Col md="12">
          <Card>
            <Card.Body>
              <h5>Team Details</h5>
              <TeamDataTable  
              tableClassName="data-table-head-light table-responsive"
                data={data}
                columns={TeamColumns}
                />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Block>

    {/* <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form action="#">
          <Row className="g-3">
            <Col lg="6">
              <Form.Group className="form-group">
                <Form.Label htmlFor="firstname">First Name</Form.Label>
                <div className="form-control-wrap">
                  <Form.Control
                    id="firstname"
                    type="text"
                    placeholder="First name"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group">
                <Form.Label htmlFor="lastname">Last Name</Form.Label>
                <div className="form-control-wrap">
                  <Form.Control
                    id="lastname"
                    type="text"
                    placeholder="Last name"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group">
                <Form.Label htmlFor="email">Email Address</Form.Label>
                <div className="form-control-wrap">
                  <Form.Control
                    id="email"
                    type="text"
                    placeholder="Email address"
                  />
                </div>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group">
                <Form.Label>Status</Form.Label>
                <div className="form-control-wrap">
                  <Select removeItemButton>
                    <option value="">Select a status</option>
                    <option value="1">Pending</option>
                    <option value="2">Active</option>
                    <option value="3">Inactive</option>
                  </Select>
                </div>
              </Form.Group>
            </Col>
            <Col lg="12">
              <Form.Group className="form-group">
                <Form.Label>Role</Form.Label>
                <div className="form-control-wrap">
                  <Select removeItemButton>
                    <option value="">Select a role</option>
                    <option value="1">Administrator</option>
                    <option value="2">Developer</option>
                    <option value="3">Analyst</option>
                    <option value="4">Support</option>
                    <option value="5">Trial</option>
                  </Select>
                </div>
              </Form.Group>
            </Col>
            <Col lg="12">
              <div className="d-flex gap g-2">
                <div className="gap-col">
                  <Button variant="primary" onClick={handleCloseModal}>
                    Add User
                  </Button>
                </div>
                <div className="gap-col">
                  <button
                    type="button"
                    className="border-0 btn"
                    onClick={handleCloseModal}
                  >
                    Discard
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal> */}
  </Layout>
  )
}

export default ViewTeam
