import { useLayoutEffect, useRef } from "react";
import { DateRangePicker } from "vanillajs-datepicker";
import { Form, InputGroup } from "react-bootstrap";

function DatePicker({ startDate, endDate, className, onChange, ...props }) {
  const {
    autoHide,
    clearBtn,
    format,
    maxView,
    pickLevel,
    startView,
    title,
    todayBtn,
    weekStart,
  } = props;
  const getFormattedToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };
  return (
    <InputGroup className={className}>
      <Form.Control
        placeholder="yyyy-mm-dd"
        type="date"
        value={startDate}
        autoComplete="off"
        onChange={onChange}
        name="start"
      />
      <InputGroup.Text>to</InputGroup.Text>
      <Form.Control
        placeholder="yyyy-mm-dd"
        type="date"
        value={endDate}
        autoComplete="off"
        onChange={onChange}
        name="end"
        max={getFormattedToday()}
      />
    </InputGroup>
  );
}

export default DatePicker;
