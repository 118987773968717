import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import { Button, Form } from "react-bootstrap";
import { Icon } from "../../../components";
import DataTablePagination from "../../../components/Pagination/DataTablePagination";
import InstituteFilter from "./instituteFilter";
import InstituteRequestFilter from "./instituteRequestFilter";

// export file component
const Export = ({ data }) => {
  const fileName = "user-data";

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="export-options d-flex align-items-center me-2">
      <div className="export-title small me-2">Export</div>
      <div className="btn-group">
        <Button variant="outline-light" onClick={() => exportCSV()}>
          CSV
        </Button>
        <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button>
      </div>
    </div>
  );
};

// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
));

function InstituteDataTable({
  data,
  columns,
  className,
  expandableRows,
  actions,
  tableClassName,
  selectableRows,
  showItemPerPageInst,
  setShowPageInst,
  ...props
}) {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    setTableData(data);
  }, [data]);

  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  let location = window.location.pathname;
  return (
    <div className="data-table-wrapper">
      <div className="data-table-top">
        <div className="data-table-search d-none">
          <input
            className="form-control"
            placeholder="Search by name"
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="d-flex gap-3">
          <div className="data-table-action-wrap">
            {actions && <Export data={data} />}
            {location == "/view-test" ? (
              <div className="data-table-select">
                <div className="form-control-wrap">
                  <Form.Select
                    style={{ width: "170px" }}
                    removeItemButton
                    onChange={(e) => {
                      setTableData(e.target.value == "" ? data : data.filter((item) => item?.pattern_id?.pattern_type == e.target.value))
                    }}
                    name="module_name"
                  >
                    <option value="">All</option>
                    <option value="isMock">Mock test</option>
                    <option value="isSection">Section Type</option>
                    <option value="isQuestion">Question Type</option>
                  </Form.Select>
                </div>
                <span className="text-secondary mx-1">Test Type</span>

                <select
                  className="form-select"
                  onChange={(e) => setShowItemPerPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
                <span className="text-secondary mx-1">Show</span>
              </div>
            ) : 
            location.includes("/view-notification") ||
               location.includes("view-tickets") ? (

              
              <div className="data-table-select">
                <select
                  className="form-select"
                  onChange={(e) => setShowItemPerPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
                <span className="text-secondary mx-1">Show</span>
              </div>
            ) : (<>

              

              { ( location.includes("/institute-request-list") )  &&
                <InstituteRequestFilter allData={data} tableData={tableData} setData={(data) => setTableData(data)} showItemPerPage={showItemPerPage} setShowPage={(e) => setShowItemPerPage(e)} />
              }
            </>)}
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
            />
          </div>
        )}
      />
    </div>
  );
}

export default InstituteDataTable;
