import { useContext,createContext } from "react";
const storedQuestionData = localStorage.getItem('questionData');
const parsedQuestionData = storedQuestionData ? JSON.parse(storedQuestionData) : {};

const Question_Context = createContext({
    quesData: parsedQuestionData,
    setQuesData: () => {}
});

export default Question_Context;
