import { Form, Col, Row } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import React, { useState, useEffect } from "react";
import InputRadio from "../inputComponents/inputRadio";

function ReadingMultichoiceSingle({ data }) {
  const { instructions, question_content, question_options } = data;
  return (
    <>
      <div className="container my-5 text-secondary">
        <Row className="g-gs">
          <Col md="6">{question_content}</Col>
          <Col md="1"></Col>
          <Col md="5">
            <h3><em className="pte-test-instructions-bold">{instructions}</em></h3>
            <p style={{lineHeight:"1.5",marginBottom:"0.4rem",marginTop:"0.4rem"}}>{data?.question}</p>
            <InputRadio data={question_options}/>
          </Col>
        </Row>
      </div>
      <ExamFooter />
    </>
  );
}

export default ReadingMultichoiceSingle;
