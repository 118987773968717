import React from 'react'
// import Nav from 'react-bootstrap/nav'

function Footer() {
  return (
    <>
      <div className="nk-footer w-100 position-fixed bottom-0">
        <div className="container-fluid">
          <div className="nk-footer-wrap justify-content-center">
            <div className="nk-footer-copyright text-menu-text">
              Power By Hi-Lingo Technology
            </div>
            {/* <div className="nk-footer-links">
                  <Nav as="ul" className="nav-sm">
                      <Nav.Item as="li">
                          <Nav.Link href="#link">About</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                          <Nav.Link href="#link">Support</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                          <Nav.Link href="#link">Blog</Nav.Link>
                      </Nav.Item>
                  </Nav>
                </div> */}
          </div>
        </div>
      </div>

    </>
  )
}

export default Footer