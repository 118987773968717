import React from "react";
import Navbar from "../Navbar";
import StudentDataTable from "../../../studentpages/StudentDataTable";
import { ChartLegend, Pureknob, Icon } from "../../../../components";
import { ChartPie } from "../../../../components/Chart/Charts";
function AnalyticTab({
  Colors,
  StudentColumns,
  Col,
  Card,
  Row,
  Tab,
  Block,
  speaking_data,
  reading_data,
  writing_data,
  listening_data,
}) {
  // users data
  const ValuesData = speaking_data

  const WritingData = writing_data;

  const ReadingData = reading_data;

  const ListeningData = listening_data;
  

  // pie chart data
  let SpeakingPieData = {
    labels: [
      "Read Aloud",
      "Repeat Sentence",
      "Describe Image",
      "Re-tell Lecture",
      "Answer Short Question",
    ],
    datasets: [
      {
        backgroundColor: [
          Colors.blue,
          Colors.red,
          Colors.yellow,
          Colors.green,
          Colors.purple,
        ],
        data: [20, 20, 20, 20, 20],
      },
    ],
  };

  let WritingPieData = {
    labels: ["Write Essay", "Summarize Written Text"],
    datasets: [
      {
        backgroundColor: [Colors.blue, Colors.red],
        data: [50, 50],
      },
    ],
  };

  let ReadingPieData = {
    labels: [
      "Multiple-choice, Choose Single Answer",
      "Multiple-choice, Choose Multiple Answer",
      "Re-order Paragraphs",
      "Reading: Fill in the Blanks",
      "Reading and Writing: Fill in the Blanks",
    ],
    datasets: [
      {
        backgroundColor: [
          Colors.blue,
          Colors.red,
          Colors.orange,
          Colors.green,
          Colors.purple,
        ],
        data: [20, 20, 20, 20, 20],
      },
    ],
  };

  let ListeningPieData = {
    labels: [
      "Summarize Spoken TextAI Score",
      "Multiple Choice (Multiple)",
      "Fill in the Blanks",
      "Highlight Correct Summary",
      "Multiple Choice (Single)",
      "Select Missing Word",
      "Highlight Incorrect Words",
      "Write From Dictation",
    ],
    datasets: [
      {
        backgroundColor: [
          Colors.blue,
          Colors.red,
          Colors.yellow,
          Colors.green,
          Colors.purple,
          Colors.assigned,
          Colors.orange,
          Colors.pink,
        ],
        data: [20, 20, 20, 20, 20, 20, 20, 20],
      },
    ],
  };
  // active project knob chart
  let SpeakingactiveProject = {
    size: 110,
    value: 96,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.assigned,
    trackWidth: "0.15",
  };

  let WritingactiveProject = {
    size: 110,
    value: 96,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.red,
    trackWidth: "0.15",
  };

  let ReadingactiveProject = {
    size: 110,
    value: 96,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.blue,
    trackWidth: "0.15",
  };

  let ListeningactiveProject = {
    size: 110,
    value: 96,
    angleOffset: 0.4,
    angleStart: 1,
    angleEnd: 1,
    colorFg: Colors.purple,
    trackWidth: "0.15",
  };

  return (
    <>
      <Block>
        <Tab.Container id="pills-result" defaultActiveKey="analytics-speaking">
          <Navbar
            speaking_data={speaking_data}
            reading_data={reading_data}
            writing_data={writing_data}
            listening_data={listening_data}
          />
          <Tab.Content>
            <Tab.Pane eventKey="analytics-speaking">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Details Analytics</h5>
                      <StudentDataTable
                       pagination={false}
                        tableClassName="data-table-head-light table-responsive analytic"
                        data={ValuesData}
                        columns={StudentColumns}
                      ></StudentDataTable>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Time Taken by Question Type</h5>
                      <Row className="align-items-center">
                        <Col xxl="6" sm="6">
                          <ChartLegend.Group className="flex-column justify-content-around ms-5 flex-wrap gap g-2">
                            <ChartLegend symbol="blue">Read Aloud</ChartLegend>
                            <ChartLegend symbol="red">
                              Repeat Sentence
                            </ChartLegend>
                            <ChartLegend symbol="yellow">
                              Describe Image
                            </ChartLegend>
                            <ChartLegend symbol="green">
                              Re-tell Lecture
                            </ChartLegend>
                            <ChartLegend symbol="purple">
                              Answer Short Question
                            </ChartLegend>
                          </ChartLegend.Group>
                        </Col>
                        <Col xxl="6" sm="6">
                          <div className="nk-chart-overview">
                            <ChartPie data={SpeakingPieData} />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <h5>Overall Speaking Score</h5>
                      <div className="d-flex justify-content-center align-items-end p-3">
                        <h1 className="mb-0">51</h1>
                        <small className="text-secondary">/90</small>
                      </div>
                      <p className="text-secondary">
                        Targeted Score : <b>79</b>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body>
                      <h5>Timing Analytics</h5>
                      <div className="d-flex flex-column justify-content-center align-items-center p-3">
                        <h3>Question Attempted: 27/27</h3>
                        <Pureknob
                          data={SpeakingactiveProject}
                          className="nk-chart-project-active"
                        />
                        <ChartLegend.Group className="flex-column justify-content-around flex-wrap gap g-2">
                          <ChartLegend symbol="assigned">
                            Que. Attempted
                          </ChartLegend>
                        </ChartLegend.Group>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="analytics-writing">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Details Analytics</h5>
                      <StudentDataTable
                      pagination={false}
                        tableClassName="data-table-head-light table-responsive analytic"
                        data={WritingData}
                        columns={StudentColumns}
                      ></StudentDataTable>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Time Taken by Question Type</h5>
                      <Row className="align-items-center">
                        <Col xxl="6" sm="6">
                          <ChartLegend.Group className="flex-column justify-content-around flex-wrap ms-5 gap g-2">
                            <ChartLegend symbol="blue">Write Essay</ChartLegend>
                            <ChartLegend symbol="red">
                              Summarize Written Text
                            </ChartLegend>
                          </ChartLegend.Group>
                        </Col>
                        <Col xxl="6" sm="6">
                          <div className="nk-chart-overview">
                            <ChartPie data={WritingPieData} />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <h5>Overall Speaking Score</h5>
                      <div className="d-flex justify-content-center align-items-end p-3">
                        <h1 className="mb-0">40</h1>
                        <small className="text-secondary">/90</small>
                      </div>
                      <p className="text-secondary">
                        Targeted Score : <b>79</b>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body>
                      <h5>Timing Analytics</h5>
                      <div className="d-flex flex-column justify-content-center align-items-center p-3">
                        <h3>Question Attempted: 3/3</h3>
                        <Pureknob
                          data={WritingactiveProject}
                          className="nk-chart-project-active"
                        />
                        <ChartLegend.Group className="flex-column justify-content-around flex-wrap gap g-2">
                          <ChartLegend symbol="red">Que. Attempted</ChartLegend>
                        </ChartLegend.Group>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="analytics-reading">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Details Analytics</h5>
                      <StudentDataTable
                       pagination={false}
                        tableClassName="data-table-head-light table-responsive analytic"
                        data={ReadingData}
                        columns={StudentColumns}
                      ></StudentDataTable>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Time Taken by Question Type</h5>
                      <Row className="align-items-center">
                        <Col xxl="6" sm="6">
                          <ChartLegend.Group className="flex-column justify-content-around flex-wrap ms-5 gap g-2">
                            <ChartLegend symbol="blue">
                              Multiple-choice, Choose Single Answer
                            </ChartLegend>
                            <ChartLegend symbol="red">
                              Multiple-choice, Choose Multiple Answer
                            </ChartLegend>
                            <ChartLegend symbol="orange">
                              Re-order Paragraphs
                            </ChartLegend>
                            <ChartLegend symbol="green">
                              Reading: Fill in the Blanks
                            </ChartLegend>
                            <ChartLegend symbol="purple">
                              Reading and Writing: Fill in the Blanks
                            </ChartLegend>
                          </ChartLegend.Group>
                        </Col>
                        <Col xxl="6" sm="6">
                          <div className="nk-chart-overview">
                            <ChartPie data={ReadingPieData} />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <h5>Overall Speaking Score</h5>
                      <div className="d-flex justify-content-center align-items-end p-3">
                        <h1 className="mb-0">33</h1>
                        <small className="text-secondary">/90</small>
                      </div>
                      <p className="text-secondary">
                        Targeted Score : <b>79</b>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body>
                      <h5>Timing Analytics</h5>
                      <div className="d-flex flex-column justify-content-center align-items-center p-3">
                        <h3>Question Attempted: 14/14</h3>
                        <Pureknob
                          data={ReadingactiveProject}
                          className="nk-chart-project-active"
                        />
                        <ChartLegend.Group className="flex-column justify-content-around flex-wrap gap g-2">
                          <ChartLegend symbol="blue">
                            Que. Attempted
                          </ChartLegend>
                        </ChartLegend.Group>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="analytics-listening">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Details Analytics</h5>
                      <StudentDataTable
                       pagination={false}
                        tableClassName="data-table-head-light table-responsive analytic"
                        data={ListeningData}
                        columns={StudentColumns}
                      ></StudentDataTable>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <h5>Time Taken by Question Type</h5>
                      <Row className="align-items-center">
                        <Col xxl="6" sm="6">
                          <ChartLegend.Group className="flex-column justify-content-around flex-wrap ms-5 gap g-2">
                            <ChartLegend symbol="blue">
                              Summarize Spoken TextAI Score
                            </ChartLegend>
                            <ChartLegend symbol="red">
                              Multiple Choice (Multiple)
                            </ChartLegend>
                            <ChartLegend symbol="yellow">
                              Fill in the Blanks
                            </ChartLegend>
                            <ChartLegend symbol="green">
                              Highlight Correct Summary
                            </ChartLegend>
                            <ChartLegend symbol="purple">
                              Multiple Choice (Single)
                            </ChartLegend>
                            <ChartLegend symbol="assigned">
                              Select Missing Word
                            </ChartLegend>
                            <ChartLegend symbol="orange">
                              Highlight Incorrect Words
                            </ChartLegend>
                            <ChartLegend symbol="pink">
                              Write From Dictation
                            </ChartLegend>
                          </ChartLegend.Group>
                        </Col>
                        <Col xxl="6" sm="6">
                          <div className="nk-chart-overview">
                            <ChartPie data={ListeningPieData} />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <h5>Overall Speaking Score</h5>
                      <div className="d-flex justify-content-center align-items-end p-3">
                        <h1 className="mb-0">39</h1>
                        <small className="text-secondary">/90</small>
                      </div>
                      <p className="text-secondary">
                        Targeted Score : <b>79</b>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" className="mb-5">
                  <Card className="h-100">
                    <Card.Body>
                      <h5>Timing Analytics</h5>
                      <div className="d-flex flex-column justify-content-center align-items-center p-3">
                        <h3>Question Attempted: 14/14</h3>
                        <Pureknob
                          data={ListeningactiveProject}
                          className="nk-chart-project-active"
                        />
                        <ChartLegend.Group className="flex-column justify-content-around flex-wrap gap g-2">
                          <ChartLegend symbol="blue">
                            Que. Attempted
                          </ChartLegend>
                        </ChartLegend.Group>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Block>
    </>
  );
}

export default AnalyticTab;
