
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthApi from "../../api/auth/index";
import Layout from "../../layout/fullpage";
import Swal from "sweetalert2/src/sweetalert2.js";
import Context from "../../context";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Media,
  MediaGroup,
  Image,
  OverlineTitle,
  Logo,
  Icon,
} from "../../components";
import ImageSlider from "../ImageSlider";
import "../../assets/scss/_variables.scss"
import { RECAPCHA_SITEKEY } from '../../config';
import ExternalStudentModalContext from "../../context/ExternalStudentModalContext/ExternalStudentModalContext";

const StudentLoginPage = () => {

  const { loginModal, setLoginModal } = useContext(ExternalStudentModalContext)
  // loginModal,setLoginModal


  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);



  useEffect(() => {
    console.log("inside theme effect1", loginModal)

    document.documentElement.style.setProperty('--theme-color', "#1682FB");
    localStorage.setItem("external_stu_modal", true);



  }, [])

  let name = window.location.hostname.split(".")[0];

  let localHostAndAdmin = name == "adm1n";

  let { domainData, setUserData, localHost, setToken } = useContext(Context);
  let inst_id = JSON.parse(domainData)?._id;
  let domainEmail = JSON.parse(domainData)?.email;
  let domain = JSON.parse(domainData)?.inst_profile;
  let navigate = useNavigate();
  console.log(domain?.color_code, "domain3213")

  const [data, setdata] = useState({
    view_password: {
      new: false,
    },


  });
  let api = new AuthApi();
  const handleChange = (e) => {
    let { name, value } = e.target;
    setdata({ ...data, [name]: value });
  };


  console.log("domain434w",data)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localHost) {
      await api
        .login({
          // .external_student_login({
          ...data,
          login_type: "2",
          inst_id: "66960463adfb936bb83591c0",

        })
        .then((res) => {
          console.log("ress", res);
          if (res.status) {
            setUserData(JSON.stringify(res.data[0]));
            localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
            setToken(res.token)
            localStorage.setItem("userHilingotoken", JSON.stringify(res.token));
            // localStorage.setItem("userHilingoisLogin", JSON.stringify(res.isLogin));
            navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "you don't have permission to login",
      });
    }
  };


  const [sliderImages, setSliderImages] = useState([
    {
      url: "/images/login-tree.svg",
    },
    {
      url: "/images/login-tree.svg",
    },
    {
      url: "/images/login-tree.svg",
    },
  ]);



  const [isLoading, setIsLoading] = useState(false);




  const [error, setError] = useState()
  const submitHandler = async (e) => {
    e.preventDefault();
    const isValid = validateForm(data);
    if (isValid) {

      setIsLoading(true);
      try {
        const res = await api.user_register(data);
        console.log("res344", res)

        if (res?.status) {
          Swal.fire({
            icon: "success",
            text: "Registration Success",
          });
          navigate("/auths/student-login");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.msg,
          });
        }
      } catch (error) {
        console.error("Error during registration:", error);
        Swal.fire({
          icon: "error",
          title: 'Oops...',
          text: error,
        });
      } finally {
        setIsLoading(false);
      }


    }

  };



  const validateForm = (data) => {
    const newErrors = {};
    console.log("data.name=", data);
    if (!data?.name?.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(data.name)) {
      newErrors.name =
        "Name should be 3-16 characters and shouldn't include any special character!";
    }
    if (!data?.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
        data.email
      )
    ) {
      newErrors.email = "Email must be valid address eg: mailto:me@mydomain.com";
    }
    if (!data?.password?.trim()) {
      newErrors.password = "Password is required";
    } else if (!/^.{6,}$/.test(data.password)) {
      newErrors.password =
        "Password should be greater than 5 digits";
    }

    if (!data?.product?.trim()) {
      newErrors.product = "Product is required";
    }


    setError(newErrors);
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };

  const handleInput = (e) => {
    console.log(e.target.name, "dfdsaf")
    //  const isValid = validateForm();
    const isValid = validateForm({ ...data, [e.target.name]: e.target.value })
    if (isValid) {

      setError((prev) => ({ ...prev, [e.target.name]: "" }))
    }

  };

  let { view_password } = data;
  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
              <Col lg="6">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <div className="login-logo">
                        <Logo />
                      </div>
                      {localHostAndAdmin ||
                        (name == "localhost" && localHost == "adm1n") ? (
                        <h1 className="nk-block-title mb-1">
                          Login to your Admin Account
                        </h1>
                      ) : (
                        <h1 className="nk-block-title mb-1">
                          Login to your Student Account
                        </h1>
                      )}
                    </div>
                  </div>
                  <Form action="#" onSubmit={handleSubmit}>
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group required">
                          <div className="form-control-wrap form-item">
                            <div className="form-control-icon start">
                              <Icon name="mail"></Icon>
                            </div>
                            <Form.Control
                              required
                              id="email"
                              name="email"
                              //placeholder="Email"
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!error?.email}
                              autoComplete="off"
                              onInput={handleInput}
                            />
                            <Form.Label>Email</Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {error?.email}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group required">
                          <div className="form-control-wrap form-item">
                            <div
                              style={{ cursor: "pointer" }}
                              className=" form-control-icon end text-middark"
                              onClick={() =>
                                setdata({
                                  view_password: { new: !view_password.new },
                                })
                              }
                            >
                              <Icon name="eye" className="me-4"></Icon>
                            </div>
                            <div className="form-control-icon start">
                              <Icon name="lock-alt"></Icon>
                            </div>
                            <Form.Control
                              required
                              id="password"
                              type={!view_password.new ? "password" : "text"}
                              name="password"
                              // placeholder="Password"
                              onChange={(e) => handleChange(e)}
                              isInvalid={!!error?.password}
                              onInput={handleInput}
                            />
                            <Form.Label>Password</Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {error?.password}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          <Form.Check
                            className="form-check-sm base-light"
                            type="checkbox"
                            id="rememberMe"
                            label="Remember Me"
                          />
                          <div style={{ marginTop: "40px" }} className="hide-captcha">     <ReCAPTCHA

                            // get sitekey from env
                            sitekey={RECAPCHA_SITEKEY}
                            // sitekey= "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            onChange={() => {
                              setRecaptchaVerified(true);
                            }} />
                          </div>
                          <Link to="/auths/student-pass-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <Col className="col-7 mx-auto">
                        <div className="d-grid">
                          <Button
                            type="submit"

                            disabled={!isRecaptchaVerified}
                          >
                            Sign In
                          </Button>


                        </div>
                      </Col>
                    </Row>
                  </Form>


                  {/* <div className="text-center my-4">
                      <div className="btn btn-success"style={{width: "312.656px"}}>
                        
                     
                        <Link to="/auths/register-new-student">Register</Link>
                      </div>
                    </div> */}

                  <div className="text-center my-4 d-grid col-7 mx-auto">
                    <Button variant="success" className=" btn btn-success "
                      onClick={() => window.location.href = "/auths/register-new-student"}

                    >
                      Register
                    </Button>

                  </div>


                  {/* <div className="my-3 text-center">
                                    <OverlineTitle className="overline-title-sep"><span>OR</span></OverlineTitle>
                                </div> */}
                  {!domain && (
                    <Row className="g-2">
                      <Col className="col-7 mx-auto">
                        <Button
                          href="#auth"
                          variant="outline-light"
                          className="w-100 btn-primary justify-content-start p-0"
                        >
                          <Image
                            src="/images/icon/d.png"
                            alt=""
                            className="icon icon-bg"
                          />
                          <span className="fw-medium ms-4 text-white">
                            Continue with Google
                          </span>
                        </Button>
                      </Col>
                      <Col className="col-7 mx-auto">
                        <Button
                          href="#auth"
                          variant="outline-light"
                          className="w-100 btn-primary justify-content-start p-0"
                        >
                          <Image
                            src="/images/icon/b.png"
                            alt=""
                            className="icon icon-bg"
                          />
                          <span className="fw-medium ms-4 text-white">
                            Continue with Facebook
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  )}

                </Card.Body>
              </Col>
              <Col lg="6">
                <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                  <Carousel
                    bgColor="#00000000"
                    width={500}
                    height={200}
                    showBullets={true}
                    autoPlay={true}
                    images={sliderImages}
                    autoPlayDelay={3000}
                    onStartSlide={(index, length) => {
                      // You can use index to track the current slide
                    }}
                  // Auto play delay in milliseconds
                  >
                    {sliderImages.map((item, index) => (
                      <Carousel.Item key={index}>
                        <img className="d-block slider-img" style={{ marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center" }} src={item.url} alt={`Slide ${index}`} />
                      </Carousel.Item>
                    ))}
                  </Carousel>

                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default StudentLoginPage;
