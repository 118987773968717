import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";

function PracticeDescribeImage({selected_ques_Obj}) {
console.log("selected_ques_Obj?.question_content_image",selected_ques_Obj)
    return (
        <>
            <div className="d-flex align-items-start gap-2 mb-3">
                <img src={selected_ques_Obj?.question_content_image} className="desc-image"></img>
            </div>
        </>
    )
}

export default PracticeDescribeImage;