import { Card, Form, Row, Col, Button, ButtonGroup, InputGroup, Badge } from 'react-bootstrap';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Icon } from '../../components';

function AddSectionWiseTest() {
    return (
        <Layout title="Add Student" content="container">
            <h1 className='mb-5'>Add More Test</h1>
            <Block>
                <Row className="g-gs">
                    <Col md="8">
                        <Card className='h-100'>
                            <Card.Body>
                            <h5 className='d-flex align-items-center'><Icon name="arrow-left-circle-fill" className="text-primary h2 me-2"></Icon> Select Section Wise Test</h5>
                                <hr></hr>
                                <ButtonGroup aria-label="Basic checkbox toggle button group" className='hilingo-radio-button'>
                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio1">0 - 5 Section Wise Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio2">6 - 10 Section Wise Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio3">11 - 15 Section Wise Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio4">16 - 20 Section Wise Test</Button>

                                    <input type="checkbox" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" />
                                    <Button as="label" variant="outline-primary" htmlFor="btnradio5">21 - 25 Section Wise Test</Button>
                                </ButtonGroup>
                                <p className="text-light my-3"><b>Note : </b>In each section Speaking, Writing, Reading & Listening</p>
                                <div className='d-flex justify-content-between mt-5'>
                                    <Button variant="outline-primary" className='border border-primary border-2 fw-bold'><Icon name="arrow-left" className='me-2'></Icon> Previous </Button>
                                    <Button variant="primary" className='border border-primary border-2 fw-bold'>Next <Icon name="arrow-right" className='ms-2'></Icon></Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md="4">
                        <Card className='h-100'>
                            <Card.Body>
                                <h5 className='mb-3'>Purchase Summary</h5>
                                <ul>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <span className='text-light'> Mock Test </span> <b className='text-primary'>0</b>
                                    </li>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <span className='text-light'> Section Wise Test </span> <b className='text-primary'>0</b>
                                    </li>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <span className='text-light'> Section Wise Test </span> <b className='text-primary'>4500+</b>
                                    </li>
                                    <hr></hr>
                                    <li className="mb-1 d-flex justify-content-between align-items-start">
                                        <b className='text-secondary'> Total Credit Spent </b> <b className='text-primary'>120</b>
                                    </li>
                                </ul>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Block>
        </Layout>
    )
}

export default AddSectionWiseTest;