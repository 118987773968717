import { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Nav,
  Table,
  CloseButton,
} from "react-bootstrap";
import validator from 'validator'
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon, Image } from "../../components";
import FileUpload from "./components/fileUpload";
import Select from "../../components/Form/Select";
import Context from "../../context";
import { Await, json, useNavigate, useParams } from "react-router-dom";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import SubmitButton from "../questions/PTE/SubmitButton";
function AddMaterial() {
  let {id} = useParams();
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  let api = new AdminApi();
  const [isValid, setisValid] = useState(true);
  const [tagList, setTagsList] = useState([]);
  const [data, setData] = useState({
    inst_id: userData?.teacher_profile?.inst_id || userData._id,
  });
const[loader,setLoader]= useState(false)
  const handleFileChange = (file) => {
    setData({ ...data, ["product"]: file.target.value }); 
  };

  const handleChange = (e) => {
    let { name, value, file } = e.target;
    if (name === "attachment") {
      setData({ ...data, [name]: file[0], link: null });
    } else if (name === "link") {
      setData({ ...data, [name]: value, attachment: null });
    } else {
      setisValid(true)
      if(name=="type"){
        setData({ ...data, [name]: value,url:"" });
      }else{
      setData({ ...data, [name]: value });
      }
    }
  };
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    const formData = new FormData();
    let tagAppended = false;
    for (const key in data) {
      if (key === "attachment" && data[key] && !tagAppended) {
        formData.append("attachment", data[key][0]);
        tagAppended = false;
      } else if (key === "newTag" && data[key] && !tagAppended) {
        formData.append("tag", data[key]);
        tagAppended = true;
      } else if (data[key]) {
        if(key !== "tag" && data[key]){
          formData.append(key, data[key]);
          tagAppended = false;
        }else{
          if(!tagAppended && data[key]){
            formData.append(key, data[key]);
            tagAppended = false;
          }
        }
      }}
      
    setLoader(!loader)
    await api
      .add_material(formData)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/material-list");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: res.message,
          });
        }
        setLoader(!loader)
      })
      .catch((err) => console.log(err));
  };
  
  useEffect(() => {
    let { product, type, name, tag, newtag, status, link,selecttag, attachment } = data;
    getTags();
    if(id){
      if (product && type && name  && status  ) {
        if(type == "link"  ){
          if( tag && link ){
            if(link && validator?.isURL(link)){
          setisValid(false)}
          }else{
            setisValid(true)
          }
        }else{
          if( attachment?.length || data?.url){
            setisValid(false)
          }else{
            setisValid(true)
          }
        }
      }else{
        setisValid(true)
      }
    }else{
    if (product && type && name  && status && selecttag ) {
      if(type == "link"  ){
        if(link && validator?.isURL(link)){
          if(selecttag=="newtag" && tag ){
            setisValid(false)
            }else{
              setisValid(false)
            }
        }else{
          setisValid(true)
        }
     
      }else{
        if( attachment?.length){
          setisValid(false)
        }else{
          setisValid(true)
        }
      }
    }else{
      setisValid(true)
    }
  }
  }, [data,id]);
  useEffect(() => {
    let { product, type, name, tag, newtag, status, link,selecttag, attachment } = data;
    getTags();
    if(id){
      get_material_by_id()
    }
  }, [id]);
console.log("-------------",data)
  const get_material_by_id = () =>{
    api.get_material_by_id(id).then((res)=>{
      if(res?.data){
        setData({...res.data,id:id})
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getTags = async() =>{
    await api.get_tags(userData?._id).then((res)=>{if(res?.status){setTagsList(res.data)}}).catch((err)=>console.log(err))
  }
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">{!id?"Add Material":"Edit Material"} </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          <Card.Body>
            <Row className="g-gs my-3">
            
              <Col md="6">
                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="selectproduct">Product</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="selectproduct"
                      aria-label="Default select example"
                      name="product"
                      value={data?.product}
                      onChange={(e) => handleFileChange(e)}
                    >
                      <option value="">Select Product</option>
                      <option value="PTE">PTE</option>
                      <option value="IELTS">IELTS</option>
                      <option value="TOFEL">TOFEL</option>
                      <option value="CELPIP">CELPIP</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="selecttype">Type</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="selecttype"
                      aria-label="Default select example"
                      value={data?.type}
                      name="type"
                      onChange={handleChange}
                    >
                      <option value="">Select Type</option>
                      <option value="video">Video</option>
                     
                      <option value="audio">Audio</option>
                      <option value="document">Document</option>
                      <option value="link">External Link</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="title">Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="text"
                      id="title"
                      name="name"
                      value={data?.name}
                      onChange={(e) => handleChange(e)}
                      placeholder="Name"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Tag</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="selecttag"
                      aria-label="Default select example"
                      value={data?.tag}
                      name="tag"
                      onChange={(e) =>
                        setData({ ...data, tag: e.target.value, newtag: "" ,selecttag:e.target.value})
                      }
                    >
                      <option value="">Select Tag</option>
                      <option value="newtag">Add New Tag</option>
                      <option value="video">Video</option>
                      <option value="audio">Audio</option>
                     
                      {tagList?.length> 0 && tagList.map((item)=>{
                        return(
                          <>
                           <option value={item}>{item}</option>
                          </>
                        )
                      })}
                     
                    </Form.Select>
                  </div>
                </Form.Group>
                {data?.tag === "newtag" ? (
                  <Form.Group className="form-group my-3">
                    <Form.Label htmlFor="newtag">Add New Tag</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="newtag"
                        name="newTag"
                        onChange={(e) =>
                          setData({ ...data, newTag: e.target.value })
                        }
                        placeholder="Add Tag"
                      />
                    </div>
                  </Form.Group>
                ) : null}
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="status">Status</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="status"
                      aria-label="Default select example"
                      name="status"
                      value={data?.status}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Form.Select>
                  </div>
                </Form.Group>

                {(data?.type === "video"  || data?.type === "audio" ||data?.type === "document" )&& !data?.url ? (
                  <Form.Group className="form-group w-100">
                    <div className="form-control-wrap d-flex justify-content-center align-items-center gap-2">
                      <FileUpload
                        iconName="file"
                        maxFiles={1}
                        filetype={data?.type}
                        errorText="Multiple files not supported, please upload single file."
                        name="attachment"
                        ty
                        onChange={(updatedFiles) => {
                          setData({
                            ...data,
                            attachment: updatedFiles,
                            link: null,
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                ) : data?.type === "link" ? (
                  <Form.Group className="form-group my-3">
                    <Form.Label htmlFor="link">Add Link</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="link"
                        name="link"
                        value={data?.link}
                        onChange={(e) => handleChange(e)}
                        placeholder="Link"
                      />
                    </div>
                  </Form.Group>
                ) : <div className="d-flex gap-2">
                {( data?.type=="video" && (
                  <video
                    src={data?.url}
                    width="200"
                    height="155"
                    controls={false}
                  />
                ))}
                {
                  (data?.type=="audio"&& (
                    <audio
                    src={data?.url}
                      controls
                    />
                  ))}
                {
                  (data?.type=="document"&& (
                    <div
                    className="link-list-hover-bg-primary link-list-md d-flex justify-content-end del-btn"
                    onClick={() => window.open(data?.url, '_blank')
                    }
                  >
                    Documents<Icon name="eye"></Icon>
                  </div>
                  ))}
             {  data?.type && <span className="d-flex align-items-center">
                  <button
                    className="btn btn-danger py-1"
                    onClick={() =>
                      setData({
                        ...data,
                        url: "",
                      })
                    }
                  >
                    Delete
                  </button>
                </span>}
              </div>}
                <div className="d-flex justify-content-center mt-3">
                  <SubmitButton loader={loader} disabled={isValid}
                    onClick={handleSubmit} text={!id?"Add Material":"Update Material"} />
                  {/* <Button
                    variant="primary"
                    disabled={isValid}
                    onClick={handleSubmit}
                  >
                    Add Material
                  </Button> */}
                </div>
              </Col>
              
            </Row>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddMaterial;
