import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";

function StudyTips() {

  return (
    <Layout title="Analytics" content="container">
      <Block>
      <Card className="h-100">
          <Card.Body>
        <Row>
          <Col md="12" lg="12">
            <div className="d-flex justify-content-between align-items-center">
              <img src="/images/hilingo-logo.png" style={{ width: "200px" }}></img>
              <div className="d-flex flex-column w-20 study-tip gap-4">
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-1 text-secondary align-items-center">
                    <Icon name="target"></Icon>
                    <h5>Target Set</h5>
                  </div>
                  <Icon name="check-circle-fill" variant="success"></Icon>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-1 text-secondary align-items-center">
                    <Icon name="reports-alt"></Icon>
                    <h5>Report Analysed</h5>
                  </div>
                  <Icon name="cross-circle-fill" variant="danger"></Icon>
                </div>
              </div>
            </div>
            <Card className="study-primary my-5">
              <Card.Body>
                <h5 className="mb-3">AI Study Tips</h5>
                <p className="text-middark">Focus on the important questions: Speaking RA, RS, DI, RL Writing SWT, WE Reading RO, FIB-R, FIB-RW Listening SST, FIB-L, HIW, WFD

                  Other non-important questions will not affect much of your scores.

                </p>
              </Card.Body>
            </Card>
            <Card className="study-hisuccess my-5">
              <Card.Body>
                <h5 className="mb-3">Speaking</h5>
                <p className="text-middark">Need to work on RA, RS, DI, RL. ASQ is not important since it won't affect yous scores much.

                  In order to achieve 65 in speaking, you need to have at least 55+ in pronunciation, and 65 in fluency.

                  When practicing in APEUni, the overall score should also be constantly above 65. But, scoring on some edge cases may not be accurate. If you have doubts about the scoring of your answers, it is best to consult APEUni tutor to get an accurate evaluation.

                </p>
              </Card.Body>
            </Card>
            <Card className="study-hidanger my-5">
              <Card.Body>
                <h5 className="mb-3">Writing</h5>
                <p className="text-middark">Need to focus on SWT, WE. These are the easiest questions in PTE. Pay attention to spelling and grammar details.

                  While practicing in APEUni, you should aim for at most 2 marks deduction in each answer.

                </p>
              </Card.Body>
            </Card>
            <Card className="study-hiwarning my-5">
              <Card.Body>
                <h5 className="mb-3">Reading</h5>
                <p className="text-middark">Need to build up your vocabulary to 6000+ words. Aim for at least 70% correctness in FIB-R, FIB-RW.

                  RO is one of the hardest questions in PTE, but luckily there are only 2 - 3 questions of it in exam. Thus it won't affect the overall reading score much. Learn the basic paragraph-sorting tips. It's good enough if you can score 1 - 2 points per question.

                  MCQ are not important. Plan well your time and leave enough time for FIB.

                </p>
              </Card.Body>
            </Card>
            <Card className="study-hiprimary my-5">
              <Card.Body>
                <h5 className="mb-3">Listening</h5>
                <p className="text-middark">Need to focus on SST, FIB-L, HIW, WFD.

                  SST is actually an easy question. While practicing in APEUni, you should aim for at most 2 marks deduction in each SST answer.

                  HIW: aim for 100% correctness. It is one of the easiest questions in PTE.

                  FIB and WFD: aim for at least 70% correctness.

                  MCQ are not important. Plan well your time and leave enough time for WFD in the end.

                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default StudyTips;