import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import "../../../assets/scss/libs/editors/quill.scss";
import parser from "html-react-parser"

export const NewQuill = ({
  placeholderValue,
  setdata,
  data,
  sendData,
  value,
  disabled,
  ...props
}) => {
  let placeholder = `${!placeholderValue ? "Hello World!" : placeholderValue}`;
  const { quill, quillRef } = useQuill({ placeholder });
  
  useEffect(() => {
    if (quill) {
      console.log('value :>> ', value);
      if(disabled) {
      quill.enable(false);
      }
if(value){

  quill.clipboard.dangerouslyPasteHTML(value);

  // parser(value)
}
      quill.on("text-change", (e) => {
        // console.log("@@quill.getText",quill.getText()); // Get text only
        // console.log("@@quill.getContents()",quill.getContents()); // Get delta contents
        // console.log("@@quill.root.innerHTML",quill.root.innerHTML); // Get innerHTML using quill
        // console.log("@@quillRef.current.firstChild.innerHTML",quillRef.current.firstChild.innerHTML);

        const text = quill.root.innerHTML;
        // const text = quill.getText();

        console.log("$$$qdata$$ :>> ", text);
        sendData(text);
      });
    }
  }, [quill]);

  console.log('quill :>> ', );
  return (
    <div style={{ width: "100%", height: "100%", color: "black" }}>
      <div ref={quillRef} />
    </div>
  );
};
