import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function GoToQuestionsComp() {
  return (
    <>
      <Button variant='transparent'><span>Go To Question</span><Icon name="chevron-right"></Icon></Button>
    </>
  );
}
export default GoToQuestionsComp;