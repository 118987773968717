import React from "react";
import {
  Card,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
function Navbar({speaking_data,reading_data,writing_data,listening_data}) {
  return (
    <div>
      <Nav
        variant="pills"
        className="mb-3 d-flex justify-content-between student-result p-3 bg-white"
      >
        <Nav.Item>
          <Nav.Link
            className="d-flex justify-content-center align-items-center gap-2"
            eventKey="analytics-speaking"
          >
            <img
              src="/images/analytics-speaking.svg"
              style={{ width: "26px", height: "26px" }}
              className="me-2"
            ></img>
            Speaking{" "}
            <Badge bg="hidanger" className="ms-1 text-white fw-bold">
              {speaking_data[speaking_data?.length-1]?.actual_total_contribute_scores}
            </Badge>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="d-flex justify-content-center align-items-center gap-2"
            eventKey="analytics-writing"
          >
            <img
              src="/images/analytics-writing.svg"
              style={{ width: "26px", height: "26px" }}
              className="me-2"
            ></img>
            Writing{" "}
            <Badge bg="hidanger" className="ms-1 text-white fw-bold">
              {writing_data[writing_data?.length-1]?.actual_total_contribute_scores}
            </Badge>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="d-flex justify-content-center align-items-center gap-2"
            eventKey="analytics-reading"
          >
            <img
              src="/images/analytics-reading.svg"
              style={{ width: "26px", height: "26px" }}
              className="me-2"
            ></img>
            Reading{" "}
            <Badge bg="hidanger" className="ms-1 text-white fw-bold">
            {reading_data[reading_data?.length-1]?.actual_total_contribute_scores}
            </Badge>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="d-flex justify-content-center align-items-center gap-2"
            eventKey="analytics-listening"
          >
            <img
              src="/images/analytics-listening.svg"
              style={{ width: "26px", height: "26px" }}
              className="me-2"
            ></img>
            Listening{" "}
            <Badge bg="hidanger" className="ms-1 text-white fw-bold">
            {listening_data[listening_data?.length-1]?.actual_total_contribute_scores}
            </Badge>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default Navbar;
