import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";

function PracticeAudio() {

    return (
        <>
            <audio className="my-3" controls>
                <source src="/images/audio.wav"></source>
            </audio>
        </>
    )
}

export default PracticeAudio;