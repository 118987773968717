import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../components";
import { useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import { useEffect } from "react";
import SubmitButton from "../questions/PTE/SubmitButton";
function AddPatten() {
  let { _id } = useParams();
  let api = new AuthApi();
  let { userData } = useContext(Context);
  let navigate = useNavigate();

  var speaking_obj = {
    read_alouds: 0,
    repeat_sentences: 0,
    total_question: 0,
    describe_images: 0,
    retell_lectures: 0,
    answer_questions: 0,
  };
  var listening_obj = {
    total_question: 0,
    ssts: 0,
    l_mcm: 0,
    l_fib: 0,
    l_hcs: 0,
    l_mcs: 0,
    l_smw: 0,
    hiws: 0,
    wfds: 0,
  };
  var writing_obj = {
    total_question: 0,
    swts: 0,
    essays: 0,
  };
  var reading_obj = {
    total_question: 0,
    fib_wr: 0,
    r_mcm: 0,
    ro: 0,
    r_mcs: 0,
    fib_rd: 0,
  };
  const[loader,setLoader] = useState(false)
  const [data, setData] = useState({
    speaking: {
      read_alouds: 0,
      repeat_sentences: 0,
      total_question: 0,
      describe_images: 0,
      retell_lectures: 0,
      answer_questions: 0,
    },
    listening: {
      total_question: 0,
      ssts: 0,
      l_mcm: 0,
      l_fib: 0,
      l_hcs: 0,
      l_mcs: 0,
      l_smw: 0,
      hiws: 0,
      wfds: 0,
    },
    writing: {
      total_question: 0,
      swts: 0,
      essays: 0,
    },
    reading: {
      total_question: 0,
      fib_wr: 0,
      r_mcm: 0,
      ro: 0,
      r_mcs: 0,
      fib_rd: 0,
    },
  });
  const [Selectetion, setSelectetion] = useState("");
  const [selectQuestionType, SetselectQuestionType] = useState("");
  useEffect(() => {
    if (_id) {
      get_patten_by_id();
    }
  }, [_id]);

  const get_patten_by_id = async () => {
    await api.get_patten_by_id(_id).then((res) => {
      try {
        if (res.status) {
          SetselectQuestionType(findTrueKey(res.data[res.data?.module]))
          setSelectetion(res.data?.module);
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  console.log("data", data);
  const handleChange = (e, type, fieldname, fieldvalue) => {
    let { name, value } = e.target;

    if (type == "number" && value.length < 11) {
      let d = { ...data.name };
      console.log("d", d);
      console.log("Selectetion", Selectetion);
      d[selectQuestionType] = value;
      // SetselectQuestionType(value)
      setData({ ...data, [Selectetion]: d });
      // setData({ ...data, [selectQuestionType]: value });
    } else {
      SetselectQuestionType(value);
      setData({
        ...data,
       speaking : speaking_obj,
       reading : reading_obj,
        writing : writing_obj,
        listening : listening_obj,
        module_name: fieldvalue ? "" : value,
      });

      if (fieldname) {
        setData({ ...data, [fieldname]: fieldvalue });
      } else {
        // setData({ ...data, [name]: value });
      }
    }
  };
  let defaultErrorJson = {
    speaking: {
      msg: "",
      status: false,
    },
    writing: {
      msg: "",
      status: false,
    },
    listening: {
      msg: "",
      status: false,
    },
    reading: {
      msg: "",
      status: false,
    },
    isSection: {
      msg: "",
      status: false,
    },
    isQuestion: {
      msg: "",
      status: false,
    },
  };
  const [errorJson, setErrorJson] = useState({
    speaking: {
      msg: "",
      status: false,
    },
    writing: {
      msg: "",
      status: false,
    },
    listening: {
      msg: "",
      status: false,
    },
    reading: {
      msg: "",
      status: false,
    },
    isSection: {
      msg: "",
      status: false,
    },
    isQuestion: {
      msg: "",
      status: false,
    },
  });
  const handleSubmit = async (e,type) => {
    e.preventDefault();
    var invalid_pattern_msg = "Invalid Pattern ";
    let { speaking, reading, writing, listening } = data;
    let {
      read_alouds,
      repeat_sentences,
      total_question,
      describe_images,
      retell_lectures,
      answer_questions,
    } = data.speaking;
    let { l_mcm, ssts, l_fib, l_hcs, l_mcs, l_smw, hiws, wfds } =
      data.listening;
    let { essays, swts } = data.writing;
    let sum_of_all_sub_module_READING =
      reading.fib_wr +
      reading.r_mcm +
      reading.ro +
      reading.r_mcs +
      reading.fib_rd;
    let sum_of_all_sub_module_SPEAKING =
      read_alouds +
      repeat_sentences +
      // total_question +
      describe_images +
      retell_lectures +
      answer_questions;
    let sum_of_all_sub_module_LISTENING =
      l_mcm + ssts + l_fib + l_hcs + l_mcs + l_smw + hiws + wfds;
    let sum_of_all_sub_module_WRITING = swts + essays;

    if (data.pattern_type == "isMock") {
      if (
        !reading.total_question ||
        !speaking.total_question ||
        !writing.total_question ||
        !listening.total_question
      ) {
        setErrorJson({
          ...errorJson,
          speaking: {
            msg: "At least one Question mandatory ",
            status: speaking.total_question ? false : true,
          },
          writing: {
            msg: "At least one Question mandatory ",
            status: writing.total_question ? false : true,
          },
          listening: {
            msg: "At least one Question mandatory ",
            status: listening.total_question ? false : true,
          },
          reading: {
            msg: "At least one Question mandatory ",
            status: reading.total_question ? false : true,
          },
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "At least one Question mandatory in each module ",
        });
      } else {
        let showAlert = true;

        if (
          reading.total_question != sum_of_all_sub_module_READING ||
          speaking.total_question != sum_of_all_sub_module_SPEAKING ||
          writing.total_question != sum_of_all_sub_module_WRITING ||
          listening.total_question != sum_of_all_sub_module_LISTENING
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: invalid_pattern_msg,
          });
          setErrorJson({
            ...errorJson,
            speaking: {
              msg: invalid_pattern_msg,
              status:
                speaking.total_question != sum_of_all_sub_module_SPEAKING
                  ? true
                  : false,
            },
            writing: {
              msg: invalid_pattern_msg,
              status:
                writing.total_question != sum_of_all_sub_module_WRITING
                  ? true
                  : false,
            },
            listening: {
              msg: invalid_pattern_msg,
              status:
                listening.total_question != sum_of_all_sub_module_LISTENING
                  ? true
                  : false,
            },
            reading: {
              msg: invalid_pattern_msg,
              status:
                reading.total_question != sum_of_all_sub_module_READING
                  ? true
                  : false,
            },
          });
          showAlert = false; // Set showAlert to false when this condition is true
        }
        if (showAlert) {
          setErrorJson(defaultErrorJson);
          if(type == "edit"){
            setLoader(!loader)
            await api.edit_pattern_by_id(data, _id).then((res) => {
              try {
                if (res.status) {
                  Swal.fire({
                    icon: "success",
                    title: "Good job!",
                    text: res?.msg,
                  });
                  navigate(-1);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res.msg,
                  });
                }
                setLoader(!loader)
              } catch (err) {
                setLoader(!loader)
                console.log(err);
              }
            });}else{
              setLoader(!loader)
            await api.add_pattern(data).then((res) => {
              try {
                if (res.status) {
                  Swal.fire({
                    icon: "success",
                    title: "Good job!",
                    text: res?.msg,
                  });
                  navigate(-1);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res.msg,
                  });
                }
                setLoader(!loader)
              } catch (err) {
                setLoader(!loader)
                console.log(err);
              }
            });}
        }
      }
    } else if (data.pattern_type == "isSection") {
      if (
        !reading.total_question &&
        !speaking.total_question &&
        !writing.total_question &&
        !listening.total_question
      ) {
        setErrorJson({
          ...errorJson,
          isSection: {
            msg: "At least one Question mandatory",
            status: true,
          },
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "At least one Question mandatory in each module ",
        });
      } else {
        if (
          (reading.total_question + sum_of_all_sub_module_READING == 0
            ? false
            : reading.total_question == sum_of_all_sub_module_READING) ||
          (speaking.total_question + sum_of_all_sub_module_SPEAKING == 0
            ? false
            : speaking.total_question == sum_of_all_sub_module_SPEAKING) ||
          (writing.total_question + sum_of_all_sub_module_WRITING == 0
            ? false
            : writing.total_question == sum_of_all_sub_module_WRITING) ||
          (listening.total_question + sum_of_all_sub_module_LISTENING == 0
            ? false
            : listening.total_question == sum_of_all_sub_module_LISTENING)
        ) {
          setErrorJson(defaultErrorJson);
          if(type == "edit"){
            setLoader(!loader)
            await api.edit_pattern_by_id(data, _id).then((res) => {
              try {
                if (res.status) {
                  Swal.fire({
                    icon: "success",
                    title: "Good job!",
                    text: res?.msg,
                  });
                  navigate(-1);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res.msg,
                  });
                  setLoader(!loader)
                }
              } catch (err) {
                setLoader(!loader)
                console.log(err);
              }
            });}else{
              setLoader(!loader)
            await api.add_pattern(data).then((res) => {
              try {
                if (res.status) {
                  Swal.fire({
                    icon: "success",
                    title: "Good job!",
                    text: res?.msg,
                  });
                  navigate(-1);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: res.msg,
                  });
                }
                setLoader(!loader)
              } catch (err) {
                setLoader(!loader)
                console.log(err);
              }
            });}
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: invalid_pattern_msg,
          });
          setErrorJson({
            ...errorJson,
            isSection: {
              msg: invalid_pattern_msg,
              status: true,
            },
          });
        }
      }
    } else {
      if (
        !reading.total_question &&
        !speaking.total_question &&
        !writing.total_question &&
        !listening.total_question
      ) {
        setErrorJson({
          ...errorJson,
          isQuestion: {
            msg: "At least one Question mandatory",
            status: true,
          },
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "At least one Question mandatory in each module ",
        });
      } else {
        setErrorJson(defaultErrorJson);
        if(type == "edit"){
          setLoader(!loader)
        await api.edit_pattern_by_id(data, _id).then((res) => {
          try {
            if (res.status) {
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res?.msg,
              });
              navigate(-1);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.msg,
              });
            }
            setLoader(!loader)
          } catch (err) {
            setLoader(!loader)
            console.log(err);
          }
        });}else{
          setLoader(!loader)
        await api.add_pattern(data).then((res) => {
          try {
            if (res.status) {
              Swal.fire({
                icon: "success",
                title: "Good job!",
                text: res?.msg,
              });
              navigate(-1);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.msg,
              });
            }
            setLoader(!loader)
          } catch (err) {
            setLoader(!loader)
            console.log(err);
          }
        });}
      }
    }

    console.log("data", data);
  };
  const handleSubmitEditTeam = async (e) => {
    e.preventDefault();

  };

  const permission = [
    "MY Plan",
    "Student Overview",
    "Student Permission",
    "Setting",
    "Invoice",
    "Result",
    "Announcement",
    "Help",
  ];

  let speaking = [
    { value: "read_alouds", text: "Read Aloud" },
    { value: "repeat_sentences", text: "Repeat Sentence" },
    { value: "describe_images", text: "Describe Image" },
    { value: "retell_lectures", text: "Re-Tell Lecture" },
    { value: "answer_questions", text: "Answer Short Question" },
  ];

  let writing = [
    { value: "swts", text: "Summarize Writing Text" },
    { value: "essays", text: "Essay" },
  ];

  let reading = [
    { value: "fib_wr", text: "Reading and Writing Fill in the Blanks" },
    { value: "r_mcm", text: "Multiple Choice, Choose Multiple Answer" },
    { value: "ro", text: "Re-order Paragraphs" },
    { value: "r_mcs", text: "Multiple Choice, Choose Single Answer" },
    { value: "fib_rd", text: "Reading: Fill in the Blanks" },
  ];

  let listening = [
    { value: "ssts", text: "Summarize Spoken Text" },
    { value: "l_mcm", text: "Multiple Choice Multiple Answer" },
    { value: "l_fib", text: "Fill in the Blanks" },
    { value: "l_hcs", text: "Highlight Correct Summary" },
    { value: "wfds", text: "Write From Dictation" },
    { value: "l_mcs", text: "Multiple Choice Single Answer" },
    { value: "l_smw", text: "Select Missing Words" },
    { value: "hiws", text: "Highlight Incorrect Words" },
    
  ];

  function findTrueKey(obj) {
    for (const key in obj) {
        if (key !== "total_question" && obj[key]) {
            return key;
        }
    }
}
  useEffect(()=>{
if(_id ){
// SetselectQuestionType(findTrueKey(data[Selectetion]))
}
  },[data[Selectetion]])
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">{_id ? "Edit Pattern" : ""}</Block.Title>
          </Block.HeadContent>
          {/* <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                    <Icon name="eye" />
                                    <span>View</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                    <Icon name="eye" />
                                    <span>View Products</span>
                                </Link>
                            </li>
                        </ul>
                    </Block.HeadContent> */}
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              {/* <h5 className="mb-3">Select Product</h5> */}

              <Row className="g-gs">
                <Col md="12">
                  <Col md="12">
                    <h5 className="mb-3">Select Pattern Type : </h5>
                    <div className="d-flex gap-3">
                      <Form.Check
                        type="radio"
                        checked={data.pattern_type == "isMock" ? true : false}
                        onChange={(e) =>
                          handleChange(e, "text", "pattern_type", "isMock")
                        }
                        name="RadioButton1"
                        id="radioaddquestion-1 "
                        label="Mock Test"
                      />
                      <Form.Check
                        type="radio"
                        checked={
                          data.pattern_type == "isSection" ? true : false
                        }
                        onChange={(e) =>
                          handleChange(e, "text", "pattern_type", "isSection")
                        }
                        name="RadioButton1"
                        id="radioaddquestion-2 "
                        label="Section Wise test"
                      />
                      <Form.Check
                        type="radio"
                        checked={
                          data.pattern_type == "isQuestion" ? true : false
                        }
                        onChange={(e) =>
                          handleChange(e, "text", "pattern_type", "isQuestion")
                        }
                        name="RadioButton1"
                        id="radioaddquestion-2 "
                        label="Question Wise test"
                      />
                    </div>
                  </Col>
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group w-50 mt-3">
                        <Form.Label htmlFor="fullname">
                          <h5>Pattern Name</h5>
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="pattern"
                            value={data?.pattern}
                            onChange={(e) =>
                              setData({ ...data, pattern: e.target.value })
                            }
                            type="text"
                            id="name"
                            // placeholder="Your Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    {data.pattern_type == "isMock" && (
                      <>
                        {" "}
                        <Col lg="12">
                          <Form.Group
                            className={`form-group w-50 ${
                              errorJson.speaking.status && "error-Image"
                            }`}
                          >
                            <Form.Label htmlFor="mobile">
                              <h5>Speaking</h5>
                            </Form.Label>

                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                name="total_question"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  if (
                                    /^[0-9]*$/.test(inputValue) &&
                                    inputValue.length <= 10
                                  ) {
                                    if (data["speaking"]) {
                                      let d = { ...data };
                                      d["speaking"]["total_question"] =
                                        Number(inputValue);
                                      setData(d);
                                    }
                                  }
                                  setErrorJson({
                                    ...errorJson,
                                    speaking: { status: false, msg: "" },
                                  });
                                }}
                                value={data?.speaking.total_question}
                                id="mobile"
                              />
                            </div>
                            {errorJson.speaking.status && (
                              <small className="fw-bold f-12">
                                {" "}
                                {errorJson.speaking.msg}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <>
                          <div className="d-flex gap-3 w-100 flex-wrap ">
                            {speaking.map((item) => (
                              <Form.Group
                                className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                key={item.value}
                              >
                                <Form.Label htmlFor="mobile">
                                  {item.text}
                                </Form.Label>
                                <Form.Control
                                  className="m-0"
                                  type="text"
                                  style={{ width: "50px" }}
                                  name={item.value}
                                  value={data.speaking[item.value]}
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    if (
                                      /^[0-9]*$/.test(inputValue) &&
                                      inputValue.length <= 10
                                    ) {
                                      let d = { ...data };
                                      d["speaking"][item.value] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                    setErrorJson({
                                      ...errorJson,
                                      speaking: { status: false, msg: "" },
                                    });
                                  }}
                                  id="mobile"
                                />
                              </Form.Group>
                            ))}
                          </div>
                        </>
                        <Col lg="12">
                          <Form.Group
                            className={`form-group w-50 ${
                              errorJson.writing.status && "error-Image"
                            }`}
                          >
                            <Form.Label htmlFor="emailid">
                              <h5>Writing</h5>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                name="total_question"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  if (
                                    /^[0-9]*$/.test(inputValue) &&
                                    inputValue.length <= 10
                                  ) {
                                    if (data["writing"]) {
                                      let d = { ...data };
                                      d["writing"]["total_question"] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                  }
                                  setErrorJson({
                                    ...errorJson,
                                    writing: { status: false, msg: "" },
                                  });
                                }}
                                value={data?.writing.total_question}
                                // onChange={(e) => handleChange(e, "number")}
                                id="mobile"
                              />
                            </div>
                            {errorJson.writing.status && (
                              <small className="fw-bold f-12">
                                {" "}
                                {errorJson.writing.msg}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <>
                          <div className="d-flex gap-3 w-100 flex-wrap">
                            {writing.map((item) => (
                              <Form.Group
                                className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                key={item.value}
                              >
                                <Form.Label htmlFor="mobile">
                                  {item.text}
                                </Form.Label>
                                <Form.Control
                                  className="m-0"
                                  type="text"
                                  style={{ width: "50px" }}
                                  name={item.value}
                                  value={data.writing[item.value]}
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    if (
                                      /^[0-9]*$/.test(inputValue) &&
                                      inputValue.length <= 10
                                    ) {
                                      let d = { ...data };
                                      d["writing"][item.value] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                    setErrorJson({
                                      ...errorJson,
                                      writing: { status: false, msg: "" },
                                    });
                                  }}
                                  id="mobile"
                                />
                              </Form.Group>
                            ))}
                          </div>
                        </>
                        <Col lg="12">
                          <Form.Group
                            className={`form-group w-50 ${
                              errorJson.reading.status && "error-Image"
                            }`}
                          >
                            <Form.Label htmlFor="password">
                              <h5>Reading</h5>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                name="total_question"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  if (
                                    /^[0-9]*$/.test(inputValue) &&
                                    inputValue.length <= 10
                                  ) {
                                    if (data["reading"]) {
                                      let d = { ...data };
                                      d["reading"]["total_question"] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                  }
                                  setErrorJson({
                                    ...errorJson,
                                    reading: { status: false, msg: "" },
                                  });
                                }}
                                value={data?.reading.total_question}
                                // onChange={(e) => handleChange(e, "number")}
                                id="mobile"
                              />
                            </div>
                            {errorJson.reading.status && (
                              <small className="fw-bold f-12">
                                {" "}
                                {errorJson.reading.msg}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <>
                          <div className="d-flex gap-3 w-100 flex-wrap">
                            {reading.map((item) => (
                              <Form.Group
                                className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                key={item.value}
                              >
                                <Form.Label htmlFor="mobile">
                                  {item.text}
                                </Form.Label>
                                <Form.Control
                                  className="m-0"
                                  type="text"
                                  style={{ width: "50px" }}
                                  name={item.value}
                                  value={data.reading[item.value]}
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    if (
                                      /^[0-9]*$/.test(inputValue) &&
                                      inputValue.length <= 10
                                    ) {
                                      let d = { ...data };
                                      d["reading"][item.value] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                    setErrorJson({
                                      ...errorJson,
                                      reading: { status: false, msg: "" },
                                    });
                                  }}
                                  id="mobile"
                                />
                              </Form.Group>
                            ))}
                          </div>
                        </>
                        <Col lg="12">
                          <Form.Group
                            className={`form-group w-50 ${
                              errorJson.listening.status && "error-Image"
                            }`}
                          >
                            <Form.Label htmlFor="password">
                              <h5>Listening</h5>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="text"
                                name="total_question"
                                onChange={(e) => {
                                  let inputValue = e.target.value;
                                  if (
                                    /^[0-9]*$/.test(inputValue) &&
                                    inputValue.length <= 10
                                  ) {
                                    if (data["listening"]) {
                                      let d = { ...data };
                                      d["listening"]["total_question"] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                  }
                                  setErrorJson({
                                    ...errorJson,
                                    listening: { status: false, msg: "" },
                                  });
                                }}
                                value={data?.listening.total_question}
                                id="mobile"
                              />
                            </div>
                            {errorJson.listening.status && (
                              <small className="fw-bold f-12">
                                {" "}
                                {errorJson.listening.msg}
                              </small>
                            )}
                          </Form.Group>
                        </Col>
                        <>
                          <div className="d-flex gap-3 w-100 flex-wrap">
                            {listening.map((item) => (
                              <Form.Group
                                className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                key={item.value}
                              >
                                <Form.Label htmlFor="mobile">
                                  {item.text}
                                </Form.Label>
                                <Form.Control
                                  className="m-0"
                                  type="text"
                                  style={{ width: "50px" }}
                                  name={item.value}
                                  value={data.listening[item.value]}
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    if (
                                      /^[0-9]*$/.test(inputValue) &&
                                      inputValue.length <= 10
                                    ) {
                                      let d = { ...data };
                                      d["listening"][item.value] = Number(
                                        e.target.value
                                      );
                                      setData(d);
                                    }
                                  }}
                                  id="mobile"
                                />
                              </Form.Group>
                            ))}
                          </div>
                        </>
                      </>
                    )}

                    {data.pattern_type == "isSection" && (
                      <>
                        <Col lg="12">
                          <Form.Group className={`form-group w-50 `}>
                            <Form.Label>
                              <h5>Select Section </h5>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Select
                                value={data.module}
                                name="module"
                                onChange={(e) => {
                                  setSelectetion(e.target.value);
                                  if (e.target.value == "listening") {
                                    SetselectQuestionType("ssts");

                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "ssts",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }
                                  if (e.target.value == "reading") {
                                    SetselectQuestionType("fib_wr");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "fib_wr",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }
                                  if (e.target.value == "writing") {
                                    SetselectQuestionType("swts");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "swts",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }
                                  if (e.target.value == "speaking") {
                                    SetselectQuestionType("read_alouds");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "read_alouds",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }

                                  // setData({ ...data, [e.target.value]: {},listening:{},reading:{},writing:{},speaking:{} });
                                }}
                                removeItemButton
                              >
                                <option value="">Select Test</option>
                                <option value="speaking">Speaking</option>
                                <option value="writing">Writing</option>
                                <option value="reading">Reading</option>
                                <option value="listening">Listening</option>
                              </Select>
                            </div>
                          </Form.Group>
                        </Col>

                        {Selectetion && (
                          <>
                            {/* <Form.Group
                              className={`form-group w-50 ${
                                errorJson.isSection.status && "error-Image"
                              }`}
                            >
                              <Form.Label htmlFor="mobile">
                                <h5>Total Question</h5>
                              </Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="text"
                                  name="total_question"
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    if (
                                      /^[0-9]*$/.test(inputValue) &&
                                      inputValue.length <= 10
                                    ) {
                                      if (data[Selectetion]) {
                                        let d = { ...data };
                                        d[Selectetion]["total_question"] =
                                          Number(e.target.value);
                                        setData(d);
                                      }
                                    }
                                    setErrorJson({
                                      ...errorJson,
                                      isSection: { status: false, msg: "" },
                                    });
                                  }}
                                  value={data[Selectetion]["total_question"]}
                                  // value={data?.speaking}
                                  // onChange={(e) => handleChange(e, "number")}
                                  id="mobile"
                                  // placeholder="0,1,2 etc"
                                />
                              </div>
                              {errorJson?.isSection?.status && (
                                <small className="fw-bold f-12">
                                  {" "}
                                  {errorJson?.isSection?.msg}
                                </small>
                              )}
                            </Form.Group> */}

                            {Selectetion && (
                              <>
                                <div className="d-flex gap-3 w-100 flex-wrap">
                                  {Selectetion == "speaking" && <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.speaking.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="mobile">
                                        <h5>Speaking</h5>
                                      </Form.Label>

                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["speaking"]) {
                                                let d = { ...data };
                                                d["speaking"]["total_question"] =
                                                  Number(inputValue);
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              speaking: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.speaking.total_question}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.speaking.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.speaking.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>}
                                  {Selectetion == "speaking" &&
                                    speaking.map((item) => (
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.speaking[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["speaking"][item.value] =
                                                Number(e.target.value);
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}

                                  {Selectetion == "writing" && <Col lg="12">
                                        <Form.Group
                                  className={`form-group w-50 ${
                                    errorJson.writing.status && "error-Image"
                                  }`}
                                >
                                  <Form.Label htmlFor="emailid">
                                    <h5>Writing</h5>
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      name="total_question"
                                      onChange={(e) => {
                                        let inputValue = e.target.value;
                                        if (
                                          /^[0-9]*$/.test(inputValue) &&
                                          inputValue.length <= 10
                                        ) {
                                          if (data["writing"]) {
                                            let d = { ...data };
                                            d["writing"]["total_question"] = Number(
                                              e.target.value
                                            );
                                            setData(d);
                                          }
                                        }
                                        setErrorJson({
                                          ...errorJson,
                                          writing: { status: false, msg: "" },
                                        });
                                      }}
                                      value={data?.writing.total_question}
                                      // onChange={(e) => handleChange(e, "number")}
                                      id="mobile"
                                    />
                                  </div>
                                  {errorJson.writing.status && (
                                    <small className="fw-bold f-12">
                                      {" "}
                                      {errorJson.writing.msg}
                                    </small>
                                  )}
                                </Form.Group>
                                  </Col>}
                                  {Selectetion == "writing" &&
                                    writing.map((item) => (
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.writing[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["writing"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))
                                  }
                                  {/* tochan */}
                                  {Selectetion=="writing" &&
                                  <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.reading.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="password">
                                        <h5>Reading</h5>
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["reading"]) {
                                                let d = { ...data };
                                                d["reading"]["total_question"] = Number(
                                                  e.target.value
                                                );
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              reading: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.reading.total_question}
                                          // onChange={(e) => handleChange(e, "number")}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.reading.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.reading.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>
                                    }
                                  {Selectetion == "writing" && reading.map((item) => (
                                      item.value == "fib_wr" &&
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.reading[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["reading"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}
                                    {Selectetion=="writing" &&
                                    <Col lg="12">
                                      <Form.Group
                                        className={`form-group w-50 ${
                                          errorJson.listening.status && "error-Image"
                                        }`}
                                      >
                                        <Form.Label htmlFor="password">
                                          <h5>Listening</h5>
                                        </Form.Label>
                                        <div className="form-control-wrap">
                                          <Form.Control
                                            type="text"
                                            name="total_question"
                                            onChange={(e) => {
                                              let inputValue = e.target.value;
                                              if (
                                                /^[0-9]*$/.test(inputValue) &&
                                                inputValue.length <= 10
                                              ) {
                                                if (data["listening"]) {
                                                  let d = { ...data };
                                                  d["listening"]["total_question"] = Number(
                                                    e.target.value
                                                  );
                                                  setData(d);
                                                }
                                              }
                                              setErrorJson({
                                                ...errorJson,
                                                listening: { status: false, msg: "" },
                                              });
                                            }}
                                            value={data?.listening.total_question}
                                            id="mobile"
                                          />
                                        </div>
                                        {errorJson.listening.status && (
                                          <small className="fw-bold f-12">
                                            {" "}
                                            {errorJson.listening.msg}
                                          </small>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    }
                                  {Selectetion == "writing" && listening.map((item) => (
                                      (item.value == "ssts" || item.value == "l_fib" || item.value == "wfds") &&
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.listening[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["listening"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}

                                  {Selectetion == "reading" && <Col lg="12">
                                      <Form.Group
                                        className={`form-group w-50 ${
                                          errorJson.reading.status && "error-Image"
                                        }`}
                                      >
                                        <Form.Label htmlFor="password">
                                          <h5>Reading</h5>
                                        </Form.Label>
                                        <div className="form-control-wrap">
                                          <Form.Control
                                            type="text"
                                            name="total_question"
                                            onChange={(e) => {
                                              let inputValue = e.target.value;
                                              if (
                                                /^[0-9]*$/.test(inputValue) &&
                                                inputValue.length <= 10
                                              ) {
                                                if (data["listening"]) {
                                                  let d = { ...data };
                                                  d["reading"]["total_question"] = Number(
                                                    e.target.value
                                                  );
                                                  setData(d);
                                                }
                                              }
                                              setErrorJson({
                                                ...errorJson,
                                                reading: { status: false, msg: "" },
                                              });
                                            }}
                                            value={data?.reading.total_question}
                                            id="mobile"
                                          />
                                        </div>
                                        {errorJson.listening.status && (
                                          <small className="fw-bold f-12">
                                            {" "}
                                            {errorJson.listening.msg}
                                          </small>
                                        )}
                                      </Form.Group>
                                    </Col>}
                                  {Selectetion == "reading" &&
                                    reading.map((item) => (
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.reading[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["reading"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}{" "}

                                  {Selectetion == "reading" && <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.reading.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="password">
                                        <h5>Speaking</h5>
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["speaking"]) {
                                                let d = { ...data };
                                                d["speaking"]["total_question"] = Number(
                                                  e.target.value
                                                );
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              speaking: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.speaking.total_question}
                                          // onChange={(e) => handleChange(e, "number")}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.speaking.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.speaking.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>}
                                  {Selectetion == "reading" && speaking.map((item) => (
                                      item.value == "read_alouds" &&
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.speaking[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["speaking"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}

                                {Selectetion == "reading" && <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.reading.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="password">
                                        <h5>Writing</h5>
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["writing"]) {
                                                let d = { ...data };
                                                d["writing"]["total_question"] = Number(
                                                  e.target.value
                                                );
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              writing: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.writing.total_question}
                                          // onChange={(e) => handleChange(e, "number")}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.writing.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.writing.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>}

                                  {Selectetion == "reading" && writing.map((item) => (
                                      item.value == "swts" &&
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.writing[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["writing"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}

                                {Selectetion == "reading" && <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.reading.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="password">
                                        <h5>Listening</h5>
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["listening"]) {
                                                let d = { ...data };
                                                d["listening"]["total_question"] = Number(
                                                  e.target.value
                                                );
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              listening: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.listening.total_question}
                                          // onChange={(e) => handleChange(e, "number")}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.listening.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.listening.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>}
                                  
                                  {Selectetion == "reading" && listening.map((item) => (
                                      (item.value == "l_hcs" || item.value == "hiws") &&
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.listening[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["listening"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}

                                  {Selectetion=="listening" && <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.reading.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="password">
                                        <h5>Listening</h5>
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["listening"]) {
                                                let d = { ...data };
                                                d["listening"]["total_question"] = Number(
                                                  e.target.value
                                                );
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              listening: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.listening.total_question}
                                          // onChange={(e) => handleChange(e, "number")}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.listening.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.listening.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>}

                                  {Selectetion == "listening" &&
                                    listening.map((item) => (
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.listening[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["listening"][item.value] =
                                                Number(e.target.value);
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}

                                {Selectetion == "listening" && <Col lg="12">
                                    <Form.Group
                                      className={`form-group w-50 ${
                                        errorJson.reading.status && "error-Image"
                                      }`}
                                    >
                                      <Form.Label htmlFor="password">
                                        <h5>Speaking</h5>
                                      </Form.Label>
                                      <div className="form-control-wrap">
                                        <Form.Control
                                          type="text"
                                          name="total_question"
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              if (data["speaking"]) {
                                                let d = { ...data };
                                                d["speaking"]["total_question"] = Number(
                                                  e.target.value
                                                );
                                                setData(d);
                                              }
                                            }
                                            setErrorJson({
                                              ...errorJson,
                                              speaking: { status: false, msg: "" },
                                            });
                                          }}
                                          value={data?.speaking.total_question}
                                          // onChange={(e) => handleChange(e, "number")}
                                          id="mobile"
                                        />
                                      </div>
                                      {errorJson.speaking.status && (
                                        <small className="fw-bold f-12">
                                          {" "}
                                          {errorJson.speaking.msg}
                                        </small>
                                      )}
                                    </Form.Group>
                                  </Col>}
                                
                                {Selectetion == "listening" && speaking.map((item) => (
                                      (item.value == "repeat_sentences" || item.value == "retell_lectures" || item.value=="answer_questions") &&
                                      <Form.Group
                                        className="form-group d-flex gap-2 align-items-center border py-1 px-2 rounded justify-content-between"
                                        key={item.value}
                                      >
                                        <Form.Label htmlFor="mobile">
                                          {item.text}
                                        </Form.Label>
                                        <Form.Control
                                          className="m-0"
                                          type="text"
                                          style={{ width: "50px" }}
                                          name={item.value}
                                          value={data.speaking[item.value]}
                                          onChange={(e) => {
                                            let inputValue = e.target.value;
                                            if (
                                              /^[0-9]*$/.test(inputValue) &&
                                              inputValue.length <= 10
                                            ) {
                                              let d = { ...data };
                                              d["speaking"][item.value] = Number(
                                                e.target.value
                                              );
                                              setData(d);
                                            }
                                          }}
                                          id="mobile"
                                        />
                                      </Form.Group>
                                    ))}


                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {data.pattern_type == "isQuestion" && (
                      <>
                        <Col lg="12">
                          <Form.Group className="form-group w-50">
                            <Form.Label>
                              <h5>Select Section </h5>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Select
                                name="module"
                                value={data.module}
                                onChange={(e) => {
                                  setSelectetion(e.target.value);
                                  if (e.target.value == "listening") {
                                    SetselectQuestionType("ssts");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "ssts",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }
                                  if (e.target.value == "reading") {
                                    SetselectQuestionType("fib_wr");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "fib_wr",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }
                                  if (e.target.value == "writing") {
                                    SetselectQuestionType("swts");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "swts",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }
                                  if (e.target.value == "speaking") {
                                    SetselectQuestionType("read_alouds");
                                    setData({
                                      ...data,
                                      ["module"]: e.target.value,
                                      ["module_name"]: "read_alouds",
                                      reading: { ...reading_obj },
                                      listening: { ...listening_obj },
                                      writing: { ...writing_obj },
                                      speaking: { ...speaking_obj },
                                    });
                                  }

                                  // setData({ ...data, [e.target.value]: {},listening:{},reading:{},writing:{},speaking:{} });
                                }}
                                removeItemButton
                              >
                                <option value="">Select Test</option>
                                <option value="speaking">Speaking</option>
                                <option value="writing">Writing</option>
                                <option value="reading">Reading</option>
                                <option value="listening">Listening</option>
                              </Select>
                            </div>
                          </Form.Group>
                        </Col>

                        {Selectetion && (
                          <>
                            <Col lg="12">
                              <Form.Group className="form-group w-50">
                                <Form.Label>
                                  <h5>Select Question Type</h5>
                                </Form.Label>
                                <div className="form-control-wrap">
                                  <Form.Select
                                    removeItemButton
                                    name={Selectetion}
                                    value={selectQuestionType}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    {Selectetion === "speaking" &&
                                      speaking.map((item, i) => (
                                        item.value != "answer_questions" &&
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                    {Selectetion === "writing" &&
                                      writing.map((item, i) => (
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                    {Selectetion === "reading" &&
                                      reading.map((item, i) => (
                                        (item.value != "r_mcm" && item.value != "r_mcs") &&
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                      {/* (item.value != "l_mcm" && item.value != "l_mcs" && item.value != "ssts" && item.value != "l_hcs" && item.value != "l_smw") */}
                                    {Selectetion === "listening" &&
                                      listening.map((item, i) => (
                                        (item.value != "l_mcm" && item.value != "l_mcs" && item.value != "l_hcs" && item.value != "l_smw") &&
                                        <option
                                          key={i}
                                          value={item.value}
                                          data-text={item.text}
                                        >
                                          {item.text}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg="12">
                              <Form.Group
                                className={`form-group w-50 ${
                                  errorJson.isQuestion.status && "error-Image"
                                }`}
                              >
                                <Form.Label htmlFor="mobile">
                                  <h5>Total Question</h5>
                                </Form.Label>
                                <div className="form-control-wrap">
                                  <Form.Control
                                    type="text"
                                    value={
                                      data[Selectetion][
                                        selectQuestionType
                                      ]
                                    }
                                    onChange={(e) => {
                                      let inputValue = e.target.value;
                                      if (
                                        /^[0-9]*$/.test(inputValue) &&
                                        inputValue.length <= 10
                                      ) {
                                        if (data[Selectetion]) {
                                          let d = { ...data };
                                          // d[Selectetion]["total_question"] = Number(
                                          //   e.target.value
                                          // );
                                          d[Selectetion][selectQuestionType] = Number(e.target.value)
                                          d[Selectetion][
                                            "total_question"
                                          ] = Number(e.target.value);
                                          // d.total_question
                                          setData(d);
                                        }
                                      }
                                      setErrorJson({
                                        ...errorJson,
                                        isQuestion: { status: false, msg: "" },
                                      });
                                    }}
                                    id="mobile"
                                    // placeholder="0,1,2 etc"
                                  />
                                </div>
                                {errorJson?.isQuestion?.status && (
                                  <small className="fw-bold f-12">
                                    {" "}
                                    {errorJson?.isQuestion?.msg}
                                  </small>
                                )}
                              </Form.Group>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </Col>
                <Col md="12" className="my-5">
                  {/* <Button
                      className="d-md-inline-flex fw-bold"
                      variant="outline-primary"
                    >
                      Update Team
                    </Button> */}
                  {}
                  {_id ? (
                    <>
                       {   loader?       <Button className="fw-bold" type="button" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </Button>:
                      <Button
                        onClick={(e)=>handleSubmit(e,"edit")}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={!data.pattern || !data.pattern_type}
                      >
                        Save
                      </Button>}
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
           {   loader?       <Button className="fw-bold" type="button" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </Button>:
                      <Button
                        onClick={(e)=>handleSubmit(e,"add")}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={!data.pattern || !data.pattern_type}
                      >
                        Save
                      </Button>}
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default AddPatten;
