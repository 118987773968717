import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../../components/Form/DropDown";
import Context from "../../context";
import { DropDownWithoutDefaultLabel } from "../../components/Form/DropDownWithoutDefaultLabel";

const InstTicket = ({
  setShow,

  singleFollowup,

  setSingleFollowup,
  setFollowupIdPayload,
  get_Single_followup,
  
//   get_all_followup_list
get_support_enquiry
}) => {
  console.log(singleFollowup, "setisFollowup");
  console.log(setShow, "setShow123");
  let api = new AuthApi();

  const [state, setState] = useState({
    data: {
        message: singleFollowup?.message ? singleFollowup?.message : "",
        subject: singleFollowup?.subject ? singleFollowup?.subject : "",
    
        attachment: singleFollowup?.attachment
        ? singleFollowup?.attachment
        : "",
        institute_id: singleFollowup?.institute_id
        ? singleFollowup?.institute_id
        : "",
        user_name: singleFollowup?.user_id?.name
        ? singleFollowup?.user_id?.name
        : "",
        status:singleFollowup?.status
    },
    errors: {},
  });
  

  let DropDownWidth = { width: "100%" };
  const [disableSubmitBtn,setDisableSubmitBtn] =useState (false)

  const statusType = [
    "WARM",
    "COLD",
    "HOT",
    "NO RESPONSE",
    "INTERESTED",
    "NOT INTERESTED",
    "IN PROCESS",
    "ENROLLED",
  ];

  let {  userData, } = useContext(Context);
  userData = JSON.parse(userData);
  const handleClose = () => {
    setSingleFollowup({});
    setFollowupIdPayload("");
  };
  const { data, errors } = state;
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
      ...data,
        [e.target.name]: e.target.value,
      },
    }));
    if(name === "status" && value == "complete") {
      setState((prev) => ({
        ...prev,
        errors:"",
      }));
    
    }
  };

  console.log(data, "data11121232",);
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.name);
    if (!data?.remarks && data?.status == "pending") {
      newErrors.remarks = "Remarks required";
    }
   else if (!data?.remarks && data?.status == "reopen") {
      newErrors.remarks = "Remarks required";
    }

    setState((prev) => ({
      ...prev,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleSubmit = async (e) => {
    var time = new Date();

   
// Extract the year, month, and day
var year = time.getFullYear();
var month = time.getMonth() + 1; // Months are zero-indexed (0-11)
var day = time.getDate();

// Format the date as a string
var formattedDate = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);

console.log(formattedDate);

    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");
     
      data.id = singleFollowup?._id
      data.users_id=  userData?.login_type == "1" && singleFollowup?.institute_id || userData?.login_type == "0" && 
      userData?._id
      
      data.date=`${formattedDate} ${time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
      if(data?.status == "complete"){
        data.remarks="Complete!!"

      }
      // else if(data?.status == "reopen"){
      //  data.remarks="ReOpening!!!!! "
      // }
      console.log(data, "data##1");
      await api
        .add_support(data)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            // setShow(false);
            document.getElementById("remarks").value = ""
            setState((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                remarks: "",
              },
            }));
            get_Single_followup();
            get_support_enquiry();
            // navigate("/");

            if(data?.status == "complete"){
              setDisableSubmitBtn(true)
              }

              get_support_enquiry()
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };

  const enquiryTypeArr = [
    "Pending",
    "Complete",
    "ReOpen"
    
  ];

  const enquiryTypeArrStu = [
    "Pending",
   
    "ReOpen"
    
  ];
  const handleInput = (e) => {
    console.log(e.target.name,"dfdsaf")
  


    setState((prev) => ({
      ...prev,
      errors: {[e.target.name]:""}
    }));
     
   };
  console.log(data, "errors1213");
  return (
    <>
      <Modal
        size="lg"
        show={Object.keys(singleFollowup).length > 0}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="6">
              {/* <Col md="12"> */}
              <Form.Group className="form-group">
                      <Form.Label>Institute</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                        disabled
                          name="student"
                          onChange={(e) => handleChange(e)}
                          value={data?.user_name}
                          type="text"
                          id="student"
                          placeholder="Enter Student Name."
                        />
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="form-group">
                      <Form.Label>Department</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                        disabled
                          name="department"
                          onChange={(e) => handleChange(e)}
                          value={data?.department}
                          type="text"
                          id="department"
                          placeholder="Enter department."
                        />
                      </div>
                    </Form.Group> */}

                    <Form.Group className="form-group">
                      <Form.Label>Message</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                        disabled
                          name="message"
                          onChange={(e) => handleChange(e)}
                          value={data?.message}
                          type="text"
                          id="message"
                          placeholder="Enter message."
                        />
                      </div>
                    </Form.Group>

                    <DropDownWithoutDefaultLabel
          className="w-25 mb-3"
          data={userData?.login_type == "0"? enquiryTypeArr:enquiryTypeArrStu}
          // disabled={ userData?.login_type == "1" }
          text="Staus"
          name="status"
          onChange={handleChange}
          width={DropDownWidth}
          value={data?.status}
         
        />


                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Remarks</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      as="textarea"
                      disabled={data?.status=="complete"}
                      placeholder="Add New Remarks"
                      name="remarks"
                      id="remarks"
                      rows="3"
                      // value={data.remarks}
                      onChange={handleChange}
                      isInvalid={errors?.remarks}
               
                    ></Form.Control>

                    <Form.Control.Feedback type="invalid">
                      {errors.remarks}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Button   variant="primary" onClick={handleSubmit}>
                  Submit 
                </Button>
              </Col>
              <Col md="6" style={{ height: "450px", overflow: "auto" }}>
                <small className="text-menu-text">Remarks History</small>

                {singleFollowup?.previous_remarks?.map((item) => (
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex flex-column bg-light px-2 py-1 rounded mb-2">
                      <p className="text-secondary">{item?.remarks}</p>
                      <small className="align-self-end text-secondary">
                        {item?.user_detail?.name}
                      </small>
                    </div>
                    <small className="align-self-end text-menu-text">
                    {item?.date}
                    </small>
                  </div>
                ))}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default InstTicket;
