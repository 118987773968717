import React, { useContext, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";

import Layout from '../../layout/fullpage';

import { Media, MediaGroup, Image, Logo } from '../../components';
import Swal from 'sweetalert2';
import AuthApi from '../../api/auth';
import ImageSlider from '../ImageSlider';
import Context from '../../context';

function AuthConfirmPassword() {

    const params = useParams()

    const [validated, setValidated] = useState(false);
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const api = new AuthApi()

    console.log("PARAMS",params)

    let {  domainData } = useContext(Context);

    domainData = JSON.parse(domainData);
    
    if (domainData?.inst_profile?.color_code) {
        document.documentElement.style.setProperty('--theme-color', domainData?.inst_profile?.color_code);
      }
      else{
        document.documentElement.style.setProperty('--theme-color',"#1682FB");
      }


    async function handleChangePassword(e){
        // e.preventDefault()
        if(newPassword != confirmPassword){
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "New Password Must Match With Confirm Password",
            });
        }
        else if(newPassword.length < 8){
            Swal.fire({
                icon: "error",
                title: "",
                text: "Password Must Be Greater Than 8 Characters.",
            });
        }
        else{
            api.verify_password_sent_through_email({_id:params._id, otp_forget_password:params.otp, newPassword:newPassword}).then((res) => {
                setNewPassword("")
                setConfirmPassword("")
                if(res?.status){
                    Swal.fire({
                        icon: "warning",
                        title: "Good job!",
                        text: res?.msg,
                      });
                }
                else{
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res?.msg,
                    });
                }
            })
        }
    }

    return (
        <Layout title="Forgot Password" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                    <Row className="g-0 flex-lg-row-reverse">
                        <Col lg="5">
                            <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                <div className="nk-block-head text-center mb-0">
                                    <div className="nk-block-head-content">
                                        <div className="login-logo">
                                            <Logo />
                                        </div>
                                        <h1 className="nk-block-title mb-2">Change your Password</h1>
                                        {/* <p className="small col-md-10 mx-auto">If you forgot your password, don't worry! we’ll email you instructions to reset your password.</p> */}
                                    </div>
                                </div>
                                <Form>
                                    <Row className="gy-3">
                                        <Col className="col-12">

                                            <Form.Group className="form-group mt-3">
                                                <Form.Label htmlFor="curpass">New Password</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type='password' id="newpass" minLength={8} value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}} placeholder="New Password" />
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="form-group mt-3">
                                                <Form.Label htmlFor="curpass">Confirm Password</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type='password' id="confirmpass" minLength={8} value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} placeholder="Confirm Password" />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-8 mx-auto mt-5">
                                            <div className="d-grid">
                                                <Button onClick={handleChangePassword} >Change Password</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="text-center mt-4">
                                    <p className="small text-themetext">Back to<Link to="/auths/auth-login"> Login</Link></p>
                                </div>
                            </Card.Body>
                        </Col>
                        {/* <Col lg="7">
                            <Card.Body className="bg-lighter is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                                <div className="mask mask-1"></div>
                            </Card.Body>
                        </Col> */}
                        <Col lg="7">
                <Card.Body className="bg-lighter h-100 d-flex flex-column justify-content-center align-items-center login-slider">
                  <ImageSlider className="login-slider"></ImageSlider>
                </Card.Body>
              </Col>
                    </Row>
                </Card>
            </div>
        </Layout>
    )
}

export default AuthConfirmPassword;
