import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import { Icon, LinkList, LinkListItem } from "../../components";
import Block from "../../components/Block/Block";
import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../../components/Form/DropDown";
import Context from "../../context";
import Layout from "../../layout/default";
import EnquiryDataTable from "../enquiry/EnquiryDataTable";
import exportFromJSON from "export-from-json";
const Export = ({ data1 }) => {
  const fileName = "user-data";
  const myExcelData = new Array();
  data1?.map((item) => {
    const enquiryDate = new Date(item?.createdAt).toISOString().split("T")[0];
    const studentName = item?.name;
    const phone = item?.phone;
    const email = item?.email;
    const teamMember = item?.teamMember;
    const nextFollowup = item?.nextFollowUpDate;
    const enquiryType = item?.program;
    const obj = {
      enquiryDate,
      studentName,
      phone,
      email,
      teamMember,
      nextFollowup,
      enquiryType,
    };
    myExcelData.push(obj);
  });
  // console.log(myExcelData, "myExcelData", data1);
  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({
      data: myExcelData,
      fileName,
      fields: {
        enquiryDate: "ENQUIRY DATE",
        studentName: "STUDENT NAME",
        phone: "PHONE NUMBER",
        email: "EMAIL ID",
        teamMember: "TEAM MEMBER",
        nextFollowup: "NEXT FOLLOWUP DATE",
        enquiryType: "ENQUITY TYPE",
      },
      exportType,
    });
  };
  return (
    <div className="export-options d-flex align-items-center me-2">
      <Button variant="primary" onClick={() => exportExcel()}>
        Export
      </Button>
    </div>
  );
};
function EnquiryList() {
  let navigate = useNavigate();
  let api = new AuthApi();
  const [show, setShow] = useState(false);
  const [teamMember, setTeamMember] = useState([]);
  const [enquiriesData, setEnquiriesData] = useState([]);
  const handleShow = () => setShow(true);
  const [state, setState] = useState({
    data: {
      name: "",
      email: "",
      phone: "",
      enquiry: "",
      gender: "",
    },
    errors: {},
  });
  const teachers = teamMember?.map((item) => item?.name.toLowerCase());
  let DropDownWidth = { width: "100%" };
  const handleClose = () => {
    setShow(false);
    setState({
      data: {},
      errors: {},
    });
  };
  let { userData, token } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData?._id, "userData11");
  console.log(token, "userData12");
  // if(userData?._id && token){
  //   console.log('working.....')
  const getTeamByInstituteId = async (instId) => {
    console.log("instid", instId, token);
    await api
      .get_teachers_By_Inst({ id: instId, token })
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setTeamMember(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (userData?._id && token) getTeamByInstituteId(userData?._id);
  }, []);
  const get_enquiriesArr = async () => {
    await api
      .get_enquiries()
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "bn enquiry");
          setEnquiriesData(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    get_enquiriesArr();
  }, []);
  // }
  const { data, errors } = state;
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "naem...");
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...prev.errors,
        [e.target.name]: "",
      },
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.name);
    if (!data?.name?.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(data.name)) {
      newErrors.name =
        "Name should be 3-16 characters and shouldn't include any special character!";
    }
    if (!data?.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
        data.email
      )
    ) {
      newErrors.email = "Email must be valid address eg: me@mydomain.com";
    }
    if (!data?.phone?.trim()) {
      newErrors.phone = "Phone is required";
    } else if (data.phone.length !== 10) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!data?.enquiry?.trim()) {
      newErrors.enquiry = "Enquiry is required";
    }
    if (!data?.gender?.trim()) {
      newErrors.gender = "Gender is required";
    }
    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  console.log("DAAta", data);
  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");
      await api
        .add_enquiry(data)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };
  const enquiryColumns = [
    {
      name: "Enquiry Date",
      selector: (row) => row.enqdate,
      cell: (row) => (
        <span className="text-middark">
          {new Date(row?.createdAt).toISOString().split("T")[0]}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Student Name",
      selector: (row) => row.name,
      cell: (row) => (
        <span className="text-middark">{row?.name ? row?.name : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phno,
      cell: (row) => (
        <span className="text-middark">{row?.phone ? row?.phone : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Email Id",
      selector: (row) => row.emailid,
      cell: (row) => (
        <span className="text-middark">{row?.email ? row?.email : "-"}</span>
      ),
      sortable: true,
    },
    {
      name: "Team Member",
      selector: (row) => row.action,
      cell: (row) => (
        <span className="text-middark">
          {row?.teamMember ? row?.teamMember : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Next Followup Date",
      selector: (row) => row.nextdate,
      cell: (row) => (
        <span className="text-middark">
          {row?.nextFollowUpDate ? row?.nextFollowUpDate : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Enquiry Type",
      selector: (row) => row.action,
      cell: (row) => (
        <span className="text-middark">
          {row?.program ? row?.program?.toUpperCase() : "-"}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-end w-100">
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
              <Icon name="edit"></Icon>
            </LinkListItem>
            <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
              <Icon name="trash"></Icon>
            </LinkListItem>
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  console.log(errors, "errors");
  return (
    <Layout title="Users List" content="container">
      <h1 className="mb-5"> Enquiry List</h1>
      <Block>
        <Row className="g-gs">
          <Col md="12">
            <Card>
              <Card.Body>
                <ul className="d-flex justify-content-end">
                  <li>
                    <Export data1={enquiriesData} />
                    <Button
                      className="d-none d-md-inline-flex btn btn-primary"
                      variant="primary"
                      // onClick={()=>navigate('/otherpages/enquiry')}
                      onClick={handleShow}
                    >
                      <Icon name="plus" /> <span> Add Enquiries</span>
                    </Button>
                  </li>
                </ul>
                <Modal
                  size="lg"
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Enquiry</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#">
                      <Row className="g-gs ">
                        <Col md="6">
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="title">
                              Student Name
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                required
                                type="text"
                                id="name"
                                placeholder="Student Name"
                                name="name"
                                value={data.name}
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="tag">Phone Number</Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="number"
                                id="phone"
                                name="phone"
                                placeholder="Phone Number"
                                // value={data.phone}
                                value={
                                  data.phone?.length < 11 ? data.phone : ""
                                }
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!errors.phone}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.phone}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="mode">Select Mode</Form.Label>
                            <div className="form-control-wrap">
                              <Form.Select
                                id="mode"
                                aria-label="Default select example"
                                onChange={(e) => handleChange(e)}
                                name="mode"
                              >
                                <option value="">Select</option>
                                <option value="Telephonic">Telephonic</option>
                                <option value="Walk-in">Walk-in</option>
                              </Form.Select>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="gender">
                              Select Gender{" "}
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Select
                                required
                                id="gender"
                                aria-label="Default select example"
                                onChange={(e) => handleChange(e)}
                                name="gender"
                                isInvalid={!!errors.gender}
                              >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.gender}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>

                          <DropDown
                            className="w-25 mb-3"
                            data={teachers}
                            defaultSelectedOption={"Select"}
                            text="Team Member Name"
                            name="teamMember"
                            onChange={handleChange}
                            width={DropDownWidth}
                          />
                        </Col>
                        <Col md="6">
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="email">Email Id</Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                type="email"
                                id="emailid"
                                name="email"
                                placeholder="Email Id"
                                value={data.email}
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="program">
                              Select Program
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Select
                                id="program"
                                aria-label="Default select example"
                                onChange={(e) => handleChange(e)}
                                name="program"
                              >
                                <option value="">Select</option>
                                <option value="Pte">PTE</option>
                                <option value="Pte core">PTE CORE</option>
                                <option value="Ilets">ILETS</option>
                                <option value="Cd Ielts">CD IELTS</option>
                                <option value="Tofel">TOEFL</option>
                                <option value="Cale">CALE</option>
                                <option value="Celpip">CELPIP</option>
                                <option value="Grammer">GRAMMER</option>
                                <option value="Spoken English">
                                  SPOKEN ENLGISH
                                </option>
                              </Form.Select>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="reference">
                              Select Reference Medium
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Select
                                id="reference"
                                aria-label="Default select example"
                                onChange={(e) => handleChange(e)}
                                name="reference"
                              >
                                <option value="">Select</option>
                                <option value="Friend Reference">
                                  Friend Reference
                                </option>
                                <option value="Direct Phone Call">
                                  Direct Phone Call
                                </option>
                                <option value="Direct Walk In">
                                  Direct Walk In
                                </option>
                                <option value="Existing Student Reference">
                                  Existing Student Reference
                                </option>
                                <option value="Team Memeber Reference">
                                  Team Memeber Reference
                                </option>
                                <option value="Advertisement">
                                  Advertisement
                                </option>
                                <option value="Newspaper">Newspaper</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Google">Google</option>
                                <option value="Webstie">Webstie</option>
                              </Form.Select>
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>Next FollowUp Date</Form.Label>
                            <div className="form-control-wrap">
                              <DatePickerOnChange
                                onChange={(e) => handleChange(e)}
                                name={"nextFollowUpDate"}
                              />
                            </div>
                          </Form.Group>
                          <Form.Group className="form-group my-3">
                            <Form.Label htmlFor="tag">
                              Comment(Optional)
                            </Form.Label>
                            <div className="form-control-wrap">
                              <Form.Control
                                as="textarea"
                                placeholder="Enquiry"
                                name="enquiry"
                                id="enquiry"
                                rows="3"
                                value={data.enquiry}
                                onChange={(e) => handleChange(e)}
                                isInvalid={!!errors.enquiry}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.enquiry}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col
                          md="12"
                          className="d-flex justify-content-center"
                        ></Col>
                      </Row>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Add Equiry
                    </Button>
                  </Modal.Footer>
                </Modal>
                <EnquiryDataTable
                  teamMemberList={teachers}
                  pagination={true}
                  tableClassName="data-table-head-light table-responsive enquiry-list"
                  data={enquiriesData}
                  columns={enquiryColumns}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}
export default EnquiryList;
