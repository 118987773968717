import React from 'react';

const MicroPhoneSvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" style={style}>
    <circle cx="36" cy="36" r="36" fill={fill}/>
    <path d="M48.5 34C49.2594 34 49.887 34.5644 49.9864 35.2964L50 35.5V36.5C50 43.6188 44.49 49.4508 37.502 49.9634L37.5 54.5C37.5 55.3284 36.8284 56 36 56C35.2406 56 34.613 55.4356 34.5136 54.7036L34.5 54.5V49.9636C27.6665 49.4632 22.2457 43.876 22.0081 36.9726L22 36.5V35.5C22 34.6716 22.6716 34 23.5 34C24.2594 34 24.887 34.5644 24.9863 35.2964L25 35.5V36.5C25 42.154 29.4689 46.7642 35.0672 46.9912L35.5 47H36.5C42.154 47 46.7642 42.5312 46.9912 36.9328L47 36.5V35.5C47 34.6716 47.6716 34 48.5 34ZM36 16C40.4182 16 44 19.5817 44 24V36C44 40.4182 40.4182 44 36 44C31.5817 44 28 40.4182 28 36V24C28 19.5817 31.5817 16 36 16ZM36 19C33.2386 19 31 21.2386 31 24V36C31 38.7614 33.2386 41 36 41C38.7614 41 41 38.7614 41 36V24C41 21.2386 38.7614 19 36 19Z" fill="white"/>
    </svg>
    
  );
}

export default MicroPhoneSvg;
