// import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import Swal from "sweetalert2/src/sweetalert2.js";

// import Layout from "../../layout/default";
// import Block from "../../components/Block/Block";
// import {
//   Icon,
//   Select,
//   QuillMinimal,
//   FileUpload,
//   Tags,
//   ImageUpload,
// } from "../../components";
// import { useContext, useState } from "react";
// import AuthApi from "../../api/auth";
// import Context from "../../context";
// import React, { useRef, useEffect } from "react";
// import AdminApi from "../../api/admin";
// function InstitutePlanPrice() {
//   let {inst_id} = useParams();

//   console.log('inst_id :>> ', inst_id);
//   const input1Ref = useRef(null);
//   let api = new AuthApi();
//   let adminApi = new AdminApi();
//   let { userData } = useContext(Context);
//   let navigate = useNavigate();

//   const [data, setData] = useState({
//     scored_section_wise_test: true,
//     scored_mock_test: true,
//     scored_question_wise_practice: true,
//     daily_institute_report_on_whatsapp:true,
//     multi_branch_management:true,
//     qr_code_enquiry_form:true,
//     institute_role_based_access:true,
//     material_management_institute:true,
//     enquiry_form_otp_paid:true
//   });

//   useEffect(() => {
//     if (inst_id) {
//       get_plan();
//     }
//   }, [inst_id]);

//   const get_plan = async () => {
//     await adminApi.get_plans_by_inst(inst_id).then((res) => {
//       try {
//         if (res.status) {
//           console.log("res", res);
//           setData({ ...res.data });
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     });
//   };

//   const handleChange = (e, check, type) => {
//     let { name, value, checked } = e.target;
// console.log("value",value)
//     if (type === "number") {
//       var newValue = e.target.value.replace(/\D/g, "");
//       if (newValue < 0) {
//         newValue = 0;
//       }
//       if (newValue.toString().length > 10) {
//         newValue = newValue.toString().slice(0, 10);
//       }
//       setData({ ...data, [name]: newValue });
//     } else {
//       if (check) {
//         setData({ ...data, [name]: checked });
//       } else {
//         setData({ ...data, [name]: value });
//       }
//     }
//   };

//   console.log("-----", data);
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await adminApi.add_plan(data).then((res) => {
//       try {
//         if (res.status) {
//           Swal.fire({
//             icon: "success",
//             title: "Good job!",
//             text: res?.message,
//           });
//           navigate(-1);
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.message,
//           });
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     });
//   };
//   const handleSubmitEditTeam = async (e) => {
//     e.preventDefault();
//     console.log('data :>> ', data);
//     await api.edit_plan_by_id(data,data?._id).then((res) => {
//       try {
//         console.log("res", res);
//         if (res.status) {
//           console.log("res", res);
//           Swal.fire({
//             icon: "success",
//             title: "Good job!",
//             text: res?.msg,
//           });
//           // navigate("/view-plan-pricing");
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: res.msg,
//           });
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     });
//   };

//   let scrollTimer;

//   useEffect(() => {
//     const handleScroll = () => {
//       // Clear previous scroll timer
//       clearTimeout(scrollTimer);

//       // Set a new timer to handle scroll event
//       scrollTimer = setTimeout(() => {
//         // Check if the first input is focused
//         if (document.activeElement === input1Ref.current) {
//           // Remove focus from the first input
//           input1Ref.current.blur();
//           // Set focus to the second input
//         }
//       }, 100); // Adjust the debounce time as needed
//     };

//     // Add scroll event listener to the window
//     window.addEventListener("scroll", handleScroll);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const handleWheel = (e) => {
//     e.preventDefault(); // Prevent the default scroll behavior
//   };
//   const month = ["0","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
//   const days = ['0','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
//   return (

 

   
//               <Row className="g-gs">
//                 <Col md="9">
//                   <Row className="g-gs">
//                     {/* <Col lg="12">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="fullname">Plan Name</Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="plan_name"
//                             value={data?.plan_name}
//                             onChange={(e) => handleChange(e)}
//                             type="text"
//                             id="name"
//                             placeholder="Basic,Growth etc"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="12">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="mobile">Plan Amount (INR)</Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="plan_amount"
//                             value={data?.plan_amount}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="mobile"
//                             placeholder="Ex - 10000"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col> */}
//                     <Col lg="12">
//                       <Form.Group className="form-group ">
//                         <Form.Label htmlFor="emailid">Plan Validity Left</Form.Label>
//                         <div className="form-control-wrap d-flex gap-3">
//                           <div>
//                             {" "}
//                             <Form.Control
//                               name="validity"
//                               value={data.validity}
//                               onChange={(e) => handleChange(e, null, "number")}
//                               id="emailid"
//                               placeholder="Ex - 1"
//                             />
//                             <small className="text-middark">type year</small>
//                           </div>
//                           <div className=" w-25">
//                             {/* <Form.Control
                           
//                             name="validity"
//                             value={data.validity}
//                             onChange={(e) => handleChange(e)}
//                             id="emailid"
//                             placeholder="Ex - 1"
//                           /> */}
//                             <Form.Group className="form-group">
//                               <div className="form-control-wrap">
//                                 <Select
//                                   name="validity_month"
//                                   value={data?.validity_month}
//                                   onChange={(e)=>handleChange(e)}
//                                   removeItemButton
//                                 >
//                                   <option value="">Select month</option>
//                                   {month.map((item) => {
//                                     return (
//                                       <option value={item}>{item} month</option>
//                                     );
//                                   })}
//                                 </Select>
//                               </div>
//                             </Form.Group>
//                             <small className="text-middark">Select Month</small>
//                           </div>
//                           <div className=" w-25">
//                             {/* <Form.Control
                           
//                             name="validity"
//                             value={data.validity}
//                             onChange={(e) => handleChange(e)}
//                             id="emailid"
//                             placeholder="Ex - 1"
//                           /> */}
//                             <Form.Group className="form-group">
//                               <div className="form-control-wrap">
//                                 <Select
//                                   name="validity_days"
//                                   value={data?.validity_days}
//                                   onChange={(e)=>handleChange(e)}
//                                   removeItemButton
//                                 >
//                                   <option value="">Select Days</option>
//                                   {days.map((item) => {
//                                     return (
//                                       <option value={item}>{item} days</option>
//                                     );
//                                   })}
//                                 </Select>
//                               </div>
//                             </Form.Group>
//                             <small className="text-middark">Select Days</small>
//                           </div>
//                         </div>
//                       </Form.Group>
//                     </Col>  
//                     <Col lg="12"><h4> Full Student Plan</h4></Col>               
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                           30 days Price (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_full_30_price"
//                             value={data?.student_full_30_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         90 days Price (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_full_90_price"
//                             value={data?.student_full_90_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         180 days Price (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_full_180_price"
//                             value={data?.student_full_180_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     {/* <Col lg="12"><h4> Limited Student Plan</h4></Col>   
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                          30 days Price (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_limited_30_price"
//                             value={data?.student_limited_30_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         90 days Price (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_limited_90_price"
//                             value={data?.student_limited_90_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         180 days Price (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_limited_180_price"
//                             value={data?.student_limited_180_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col> */}
//                     <Col lg="12"><h4> Retail Student Plan</h4></Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         Mock Test Cost (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="mock_test_cost"
//                             value={data?.mock_test_cost}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         Section Test Cost (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="section_test_cost"
//                             value={data?.section_test_cost}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="4">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                         Free Practice Cost (INR)
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="free_practice_price"
//                             value={data?.free_practice_price}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 350 INR"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="12">
//                       <Form.Group className="form-group">
//                         <Form.Label htmlFor="password">
//                           Student Account validity
//                         </Form.Label>
//                         <div className="form-control-wrap">
//                           <Form.Control
//                             name="student_account_validity"
//                             value={data?.student_account_validity}
//                             onChange={(e) => handleChange(e, null, "number")}
//                             id="password"
//                             placeholder="Ex - 60 Days"
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col lg="12">
//                       <div>
//                         {/* <h5 className="my-3">Mock Test Score</h5> */}
//                         {/* <div className="form-check form-switch">
//                           <span className="text-light">Mock Test Score</span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             id="directMessage"
//                             checked={data?.scored_mock_test}
//                             name="scored_mock_test"
//                             onChange={(e) => handleChange(e, "check")}
//                             value={data?.scored_mock_test}
//                           />
//                         </div>
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Section Wise Test Score
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             id="directMessage"
//                             checked={data?.scored_section_wise_test}
//                             name="scored_section_wise_test"
//                             onChange={(e) => handleChange(e, "check")}
//                             value={data?.scored_section_wise_test}
//                           />
//                         </div>
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Question Wise Practice Test Score
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.scored_question_wise_practice}
//                             id="directMessage"
//                             checked={data?.scored_question_wise_practice}
//                             name="scored_question_wise_practice"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div> */}
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Role-Based Access: Institute Admin Controls Staff/Team Access
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.institute_role_based_access}
//                             id="directMessage"
//                             checked={data?.institute_role_based_access}
//                             name="institute_role_based_access"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div>
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Material Management: Institutes can upload their own study materials Daily
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.material_management_institute}
//                             id="directMessage"
//                             checked={data?.material_management_institute}
//                             name="material_management_institute"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div>
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Daily Institute Report On Whatsapp
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.daily_institute_report_on_whatsapp}
//                             id="directMessage"
//                             checked={data?.daily_institute_report_on_whatsapp}
//                             name="daily_institute_report_on_whatsapp"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div>
                        
                        
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Multi Branch Management
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.multi_branch_management}
//                             id="directMessage"
//                             checked={data?.multi_branch_management}
//                             name="multi_branch_management"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div>
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             QR Code Enquiry Form
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.qr_code_enquiry_form}
//                             id="directMessage"
//                             checked={data?.qr_code_enquiry_form}
//                             name="qr_code_enquiry_form"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div>
//                         <div className="form-check form-switch">
//                           <span className="text-light">
//                             Enquiry Form OTP Mobile Number Verify(Paid)
//                           </span>{" "}
//                           <input
//                             className="form-check-input ms-3"
//                             type="checkbox"
//                             role="switch"
//                             value={data?.enquiry_form_otp_paid}
//                             id="directMessage"
//                             checked={data?.enquiry_form_otp_paid}
//                             name="enquiry_form_otp_paid"
//                             onChange={(e) => handleChange(e, "check")}
//                             // value={data?.scored_mock_test}
//                           />
//                         </div>
//                       </div>
//                     </Col>
//                   </Row>
//                 </Col>
//                 <Col md="3"></Col>

//                 <Col md="12" className="my-5 d-flex  justify-content-center">
//                   {inst_id && (
//                     <>
                   

// <Button
//                     variant="primary"
//                     className="w-25 mx-auto"
//                     onClick={handleSubmitEditTeam}
//                   >
//                           Update
//                       </Button>
                     
//                     </>
//                   )}
//                 </Col>
//               </Row>
        
   
//   );
// }

// export default InstitutePlanPrice;

// -------------------------


import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../components";
import { useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import React, { useRef, useEffect } from "react";
import AdminApi from "../../api/admin";
import { DropDown } from "../../components/Form/DropDown";
import { isAlpha } from "validator";
function InstitutePlanPrice({institutePlanPrice,Instdata}) {
  let {inst_id} = useParams();
  let name = window.location.hostname.split(".")[0];

  console.log('inst_id :>> ', Instdata);
  const input1Ref = useRef(null);
  let api = new AuthApi();
  let adminApi = new AdminApi();
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData?.isOverride,"userData?.isOverride")
  let navigate = useNavigate();

  const [data, setData] = useState({
    scored_section_wise_test: true,
    scored_mock_test: true,
    scored_question_wise_practice: true,
    daily_institute_report_on_whatsapp:true,
    multi_branch_management:true,
    qr_code_enquiry_form:true,
    institute_role_based_access:true,
    material_management_institute:true,
    enquiry_form_otp_paid:true,
 
  });
  console.log('data@# :>> ', data);
  const [planId,setPlanId]=useState()
  const [appPlanData,setAppPlanData]=useState({})
  const [planIdSelected,setPlanIdSelected]=useState()

  const [instOverrideData,setInstOverrideData]=useState()
  console.log(instOverrideData,"instOverrideData")
  useEffect(() => {
    if (inst_id) {
    
      get_plan();
    }
  }, [inst_id]);

  const get_plan = async () => {
    let cond=instOverrideData && name=='app'
    console.log(instOverrideData,"instOverrideData434",cond)
    await adminApi.get_active_plans_by_inst(inst_id,true).then((res) => {
      try {
        if (res.status) {
          console.log("res3231", res?.data);
          setData({ ...res?.data?.plan_data });
          setInstOverrideData(res?.data?.userId?.isOverride)
          setPlanId(res?.data?.plan_data?._id)
          setAppPlanData(res?.data?.plan_data)
          setPlanIdSelected(res?.data?.plan_data?._id)
        }
      } catch (err) {
        console.log(err);
      }
    });
  };


  const get_active_plans_by_inst = async () => {
    let cond=instOverrideData && name=='app'
    console.log(instOverrideData,"instOverrideData43455",cond)
    await adminApi.get_active_plans_by_inst(inst_id,instOverrideData).then((res) => {
      try {
        if (res.status) {
          console.log("res3231", res?.data);
          setData({ ...res?.data?.plan_data });
         
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  useEffect(()=>{
    get_active_plans_by_inst()
  },[instOverrideData])


  const get_plan_by_id = async () => {
    await adminApi.get_plan(planIdSelected).then((res) => {
      try {
        if (res.status) {
          console.log("res323", res?.data?.userId?.isOverride);
          setData({ ...res?.data });
        
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  useEffect(()=>{
    if(planId!==planIdSelected)
    get_plan_by_id()
  },[planIdSelected])


console.log(planId,"planId")

  const [overrideCheck,setOverrideCheck]=useState(false)

  const [override,setOverride]=useState({
    planValidity:!instOverrideData

  })

  const [overrideCheckCall,setOverrideCheckCall]=useState(false)
  console.log(override,"data?.plan_name")

 
  
  const handleChangeOverride = (e, check, type) => {
    let { name, value, checked } = e.target;
    if(name == "override"){
      setOverride({...override, planValidity: !checked})
      setOverrideCheckCall(true)
      
      
    }



  }

  async function callOverrideApi(){
    console.log('data :>> ', data);
    await api.override_plan_brought_by_id({override:!override?.planValidity,instId:inst_id})
    .then((res) => {
      try {
        console.log("res", res);
        if (res.status) {
          console.log("res", res);
          if (inst_id) {
            get_plan();
          }
    
          setOverrideCheckCall(false)
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          // navigate("/view-plan-pricing");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  useEffect(()=>{

   if(overrideCheckCall ==true)
    callOverrideApi()
    
  },[overrideCheckCall])
  const handleChange = (e, check, type) => {
    let { name, value, checked } = e.target;
console.log("value",value)
 

setOverrideCheck(true)
    if (type === "number") {
      var newValue = e.target.value.replace(/\D/g, "");
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue.toString().length > 10) {
        newValue = newValue.toString().slice(0, 10);
      }
      setData({ ...data, [name]: newValue });
    } else {
      if (check) {
        setData({ ...data, [name]: checked });
      } else {
        setData({ ...data, [name]: value });
      }
    }
    // if(name =="plan_name"){
    //   let planName= plans?.find((item)=>item?.id == value)?.plan
    //   setData({ ...data, [name]: planName });
    // }

    if (name === "plan_name") {
      setPlanIdSelected(plans?.find((item) => item?.plan?.toLowerCase()?.trim() == value?.toLowerCase()?.trim())?.id)
      setData({ ...data, [name]: value?.trim() });
    }

  };

  console.log("-----", data);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await adminApi.add_plan(data).then((res) => {
      try {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.message,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleSubmitEditTeam = async (e) => {
    e.preventDefault();
    console.log('data :>> ', data);
    if(planIdSelected){

      await api.edit_plan_brought_by_id({...data,override:!override?.planValidity,instId:inst_id,_id:planIdSelected},planIdSelected).then((res) => {
        try {
          console.log("res", res);
          if (res.status) {
            console.log("res", res);
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res?.msg,
            });
            // navigate("/view-plan-pricing");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  let scrollTimer;

  useEffect(() => {
    const handleScroll = () => {
      // Clear previous scroll timer
      clearTimeout(scrollTimer);

      // Set a new timer to handle scroll event
      scrollTimer = setTimeout(() => {
        // Check if the first input is focused
        if (document.activeElement === input1Ref.current) {
          // Remove focus from the first input
          input1Ref.current.blur();
          // Set focus to the second input
        }
      }, 100); // Adjust the debounce time as needed
    };

    // Add scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleWheel = (e) => {
    e.preventDefault(); // Prevent the default scroll behavior
  };
  const month = ["0","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  const days = ['0','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

  const [plan,setPlan]=useState([])
  let DropDownWidth = { width: "175%" };

  const get_plan_admin = async () => {

    await adminApi
      .get_plan_admin()
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setPlan(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(()=>{

get_plan_admin()


  },[])
  const plans = plan?.map((item) => {return{plan:item?.plan_name?.trim(),id:item?._id}});

  console.log('data32 :>> ', plans);

  useEffect(()=>{
   
    if(instOverrideData == 0){
      setData(institutePlanPrice)
    }

  },[instOverrideData])

  useEffect(()=>{
    if(Instdata?.domain_name=="app"){

      setData(appPlanData)
    }
  },[appPlanData])
  return (

 

   
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    {/* <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">Plan Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="plan_name"
                            value={data?.plan_name}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="Basic,Growth etc"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="mobile">Plan Amount (INR)</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="plan_amount"
                            value={data?.plan_amount}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="mobile"
                            placeholder="Ex - 10000"
                          />
                        </div>
                      </Form.Group>
                    </Col> */}

<div className="d-flex gap-2 text-secondary mt-4">
                              <div>Override </div> <div>   
                                 <Form.Check type="checkbox" id="flexSwitchChecked"
                                //  disabled={overrideCheck}
                                name='override'
                                onChange={(e) => handleChangeOverride(e)}
                                checked={instOverrideData}
                                />
                              </div>
                            </div>

    <Col lg="12"><h4>Current Plan:   <span className="text-middark">{data?.plan_name}</span></h4> </Col>


    <Col lg="4">

    {/* <Form.Group className="form-group mt-3">
  <Form.Label>Change Plan</Form.Label>
  <div className="form-control-wrap me-2" style={DropDownWidth}>
    <Form.Select
      name="plan_name"
      onChange={handleChange}
      value={data?.plan_name}
      disabled={!instOverrideData}
    >
      <option value="">{"Select"}</option>
      {plans?.length > 0 &&
        plans.map((item) => {
          // value={item.plan} -->  this value pass as
          return (
            <option value={item.id} key={item.id}>
              {item.plan}
            </option>
          );
        })}
    </Form.Select>
  </div>
</Form.Group> */}

{/* <Form.Group className="form-group mt-3">
  <Form.Label>Change Plan</Form.Label>
  <div className="form-control-wrap me-2" style={DropDownWidth}>
    <Form.Select
      name="plan_name"
      onChange={handleChange}
      value={data?.plan_name}
      disabled={!instOverrideData}
    >
      <option value="">{"Select"}</option>
      {plans?.length > 0 &&
        plans.map((item) => {
          return (
            <option value={item.plan_name} key={item.id}>
              {item.plan}
            </option>
          );
        })}
    </Form.Select>
  </div>
</Form.Group> */}

<Form.Group className="form-group mt-3">
  <Form.Label>Change Plan</Form.Label>
  <div className="form-control-wrap me-2" style={DropDownWidth}>
    <Form.Select
      name="plan_name"
      onChange={handleChange}
      value={data?.plan_name?.trim()} // Ensure the value is trimmed
      disabled={!instOverrideData}
    >
      <option value="">{"Select"}</option>
      {plans?.length > 0 &&
        plans.map((item) => (
          <option value={item.plan} key={item.id}>
            {item.plan}
          </option>
        ))}
    </Form.Select>
  </div>
</Form.Group>

{/* <DropDown
  disabled={!instOverrideData}
                  className="w-25 mb-3"
                  data={plans}
                  defaultSelectedOption={"Select"}
                  text="Change Plan"
                  // name="plan_name"
                  name="plan_name"
                  onChange={handleChange}
                  width={DropDownWidth}
                  value={data?.plan_name}
             
                /> */}
                  </Col>

                       
                    <Col lg="12">
                      <Form.Group className="form-group ">
                        <Form.Label htmlFor="emailid">Plan Validity Left</Form.Label>



                        <div className="form-control-wrap d-flex gap-3">
                          <div>
                            {" "}
                            <Form.Control
                            disabled={!instOverrideData}
                              name="validity"
                              value={data.validity}
                              onChange={(e) => handleChange(e, null, "number")}
                              id="emailid"
                              placeholder="Ex - 1"
                            />
                            <small className="text-middark">type year</small>
                          </div>
                          <div className=" w-25">
                            {/* <Form.Control
                           
                            name="validity"
                            value={data.validity}
                            onChange={(e) => handleChange(e)}
                            id="emailid"
                            placeholder="Ex - 1"
                          /> */}
                            <Form.Group className="form-group">
                              {console.log("OVEROVERRIDE",override)}
                              <div className="form-control-wrap">
                                <Select
                                   disabled={!instOverrideData}
                                  name="validity_month"
                                  value={data?.validity_month}
                                  onChange={(e)=>handleChange(e)}
                                  removeItemButton
                                >
                                  <option value="">Select month</option>
                                  {month.map((item) => {
                                    return (
                                      <option  value={item}>{item} month</option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Form.Group>
                            <small className="text-middark">Select Month</small>
                          </div>
                          <div className=" w-25">
                            {/* <Form.Control
                           
                            name="validity"
                            value={data.validity}
                            onChange={(e) => handleChange(e)}
                            id="emailid"
                            placeholder="Ex - 1"
                          /> */}
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Select
                                 disabled={!instOverrideData}
                                  name="validity_days"
                                  value={data?.validity_days}
                                  onChange={(e)=>handleChange(e)}
                                  removeItemButton
                                >
                                  <option value="">Select Days</option>
                                  {days.map((item) => {
                                    return (
                                      <option value={item}>{item} days</option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Form.Group>
                            <small className="text-middark">Select Days</small>
                          </div>

                    
                        </div>
                      </Form.Group>


                 

                    </Col>  

              
                    <Col lg="12"><h4> Full Student Plan</h4></Col>               
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                          30 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="student_full_30_price"
                            value={data?.student_full_30_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        90 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="student_full_90_price"
                            value={data?.student_full_90_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        180 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="student_full_180_price"
                            value={data?.student_full_180_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="12"><h4> Retail Student Plan</h4></Col>  
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        Mock Test Cost (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="mock_test_cost"
                            value={data?.mock_test_cost}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        Section Test Cost (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="section_test_cost"
                            value={data?.section_test_cost}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        Free Practice Cost (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="free_practice_price"
                            value={data?.free_practice_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* <Col lg="12"><h4> Limited Student Plan</h4></Col>   
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                         30 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="student_limited_30_price"
                            value={data?.student_limited_30_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        90 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="student_limited_90_price"
                            value={data?.student_limited_90_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        180 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="student_limited_180_price"
                            value={data?.student_limited_180_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col> */}
                  
                    <div class="w-20">
                            <div className="d-flex gap-2 text-secondary mt-2">
                              <div>Free Single Practice Q </div> <div>   
                                 <Form.Check type="checkbox" id="flexSwitchChecked"
                                  disabled={!instOverrideData}
                                name='free_single_practice'
                                onChange={(e) => handleChange(e, "check")}
                                // checked={data?.enquiry_otp_deduction}
                                />
                              </div>
                            </div>

                          </div>
                    


                          <Col lg="12"><h4> Demo Plan(1 Scored Mock Test)</h4></Col>   
                    <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                         Rate for single Mock test demo plan
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="student_limited_30_price"
                            value={data?.student_limited_30_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    
                    {/* <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        Mock test in Demo Student Plan
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="section_test_cost"
                            value={data?.section_test_cost}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Mock test"
                          />
                        </div>
                      </Form.Group>
                    </Col> */}
 {/* 
<Col lg="4">
                     <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        90 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="student_limited_90_price"
                            value={data?.student_limited_90_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col> */}
                    {/* <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        Section test in Demo Student Plan
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="free_practice_price"
                            value={data?.free_practice_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Section test"
                          />
                        </div>
                      </Form.Group>
                    </Col> */}


{/* <Col lg="4">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                        180 days Price (INR)
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                          
                          disabled={!instOverrideData}
                            name="student_limited_180_price"
                            value={data?.student_limited_180_price}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 350 INR"
                          />
                        </div>
                      </Form.Group>
                    </Col>  */}

                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">
                          Student Account validity
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                           disabled={!instOverrideData}
                            name="student_account_validity"
                            value={data?.student_account_validity}
                            onChange={(e) => handleChange(e, null, "number")}
                            id="password"
                            placeholder="Ex - 60 Days"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <div>
                        {/* <h5 className="my-3">Mock Test Score</h5> */}
                        {/* <div className="form-check form-switch">
                          <span className="text-light">Mock Test Score</span>{" "}
                          <input
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            id="directMessage"
                            checked={data?.scored_mock_test}
                            name="scored_mock_test"
                            onChange={(e) => handleChange(e, "check")}
                            value={data?.scored_mock_test}
                          />
                        </div>
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Section Wise Test Score
                          </span>{" "}
                          <input
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            id="directMessage"
                            checked={data?.scored_section_wise_test}
                            name="scored_section_wise_test"
                            onChange={(e) => handleChange(e, "check")}
                            value={data?.scored_section_wise_test}
                          />
                        </div>
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Question Wise Practice Test Score
                          </span>{" "}
                          <input
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.scored_question_wise_practice}
                            id="directMessage"
                            checked={data?.scored_question_wise_practice}
                            name="scored_question_wise_practice"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div> */}
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Role-Based Access: Institute Admin Controls Staff/Team Access
                          </span>{" "}
                          <input
                           disabled={!instOverrideData}
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.institute_role_based_access}
                            id="directMessage"
                            checked={data?.institute_role_based_access}
                            name="institute_role_based_access"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div>
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Material Management: Institutes can upload their own study materials Daily
                          </span>{" "}
                          <input
                            disabled={!instOverrideData}
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.material_management_institute}
                            id="directMessage"
                            checked={data?.material_management_institute}
                            name="material_management_institute"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div>
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Daily Institute Report On Whatsapp
                          </span>{" "}
                          <input
                            disabled={!instOverrideData}
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.daily_institute_report_on_whatsapp}
                            id="directMessage"
                            checked={data?.daily_institute_report_on_whatsapp}
                            name="daily_institute_report_on_whatsapp"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div>
                        
                        
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Multi Branch Management
                          </span>{" "}
                          <input
                            disabled={!instOverrideData}
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.multi_branch_management}
                            id="directMessage"
                            checked={data?.multi_branch_management}
                            name="multi_branch_management"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div>
                        <div className="form-check form-switch">
                          <span className="text-light">
                            QR Code Enquiry Form
                          </span>{" "}
                          <input
                            disabled={!instOverrideData}
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.qr_code_enquiry_form}
                            id="directMessage"
                            checked={data?.qr_code_enquiry_form}
                            name="qr_code_enquiry_form"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div>
                        <div className="form-check form-switch">
                          <span className="text-light">
                            Enquiry Form OTP Mobile Number Verify(Paid)
                          </span>{" "}
                          <input
                            disabled={!instOverrideData}
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            value={data?.enquiry_form_otp_paid}
                            id="directMessage"
                            checked={data?.enquiry_form_otp_paid}
                            name="enquiry_form_otp_paid"
                            onChange={(e) => handleChange(e, "check")}
                            // value={data?.scored_mock_test}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="3"></Col>

                <Col md="12" className="my-5 d-flex  justify-content-center">
                  {inst_id && (
                    <>
                   

<Button
                    variant="primary"
                    className="w-25 mx-auto"
                    onClick={handleSubmitEditTeam}
                    disabled={!instOverrideData}
                  >
                          Update
                      </Button>
                     
                    </>
                  )}
                </Col>
              </Row>
        
   
  );
}

export default InstitutePlanPrice;
