// import React from 'react'

// const PhoneFlag = () => {

//   <select id="country_list">
//                         <option value="" hidden>Select Country</option> 
//                         <option countryCode="AF" value="93">Afghanistan (+93)</option>
//                         <option countryCode="AL" value="355">Albania (+355)</option>
//                         <option countryCode="DZ" value="213">Algeria (+213)</option>
//                         <option countryCode="AS" value="1684">American Samoa (+1684)</option>
//                         <option countryCode="AD" value="376">Andorra (+376)</option>
//                         <option countryCode="AO" value="244">Angola (+244)</option>
//                         <option countryCode="AI" value="1264">Anguilla (+1264)</option>
//                         <option countryCode="AQ" value="672">Antartica (+672)</option>
//                         <option countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
//                         <option countryCode="AR" value="54">Argentina (+54)</option>
//                         <option countryCode="AM" value="374">Armenia (+374)</option>
//                         <option countryCode="AW" value="297">Aruba (+297)</option>
//                         <option countryCode="AU" value="61">Australia (+61)</option>
//                         <option countryCode="AT" value="43">Austria (+43)</option>
//                         <option countryCode="AZ" value="994">Azerbaijan (+994)</option>
//                         <option countryCode="BS" value="1242">Bahamas (+1242)</option>
//                         <option countryCode="BH" value="973">Bahrain (+973)</option>
//                         <option countryCode="BD" value="880">Bangladesh (+880)</option>
//                         <option countryCode="BB" value="1246">Barbados (+1246)</option>
//                         <option countryCode="BY" value="375">Belarus (+375)</option>
//                         <option countryCode="BE" value="32">Belgium (+32)</option>
//                         <option countryCode="BZ" value="501">Belize (+501)</option>
//                         <option countryCode="BJ" value="229">Benin (+229)</option>
//                         <option countryCode="BM" value="1441">Bermuda (+1441)</option>
//                         <option countryCode="BT" value="975">Bhutan (+975)</option>
//                         <option countryCode="BO" value="591">Bolivia (+591)</option>
//                         <option countryCode="BA" value="387">Bosnia &amp; Herzegovina (+387)</option>
//                         <option countryCode="BW" value="267">Botswana (+267)</option>
//                         <option countryCode="BR" value="55">Brazil (+55)</option>
//                         <option countryCode="IO" value="246">British India Ocean Terrirory (+246)</option>
//                         <option countryCode="VG" value="1284">British Virgin Islands (+1284)</option>
//                         <option countryCode="BN" value="673">Brunei (+673)</option>
//                         <option countryCode="BG" value="359">Bulgaria (+359)</option>
//                         <option countryCode="BF" value="226">Burkina Faso (+226)</option>
//                         <option countryCode="BI" value="257">Burundi (+257)</option>
//                         <option countryCode="KH" value="855">Cambodia (+855)</option>
//                         <option countryCode="CM" value="237">Cameroon (+237)</option>
//                         <option countryCode="CA" value="1">Canada (+1)</option>
//                         <option countryCode="CV" value="238">Cape Verde Islands (+238)</option>
//                         <option countryCode="KY" value="1345">Cayman Islands (+1345)</option>
//                         <option countryCode="CF" value="236">Central African Republic (+236)</option>
//                         <option countryCode="TD" value="235">Chad (+235)</option>
//                         <option countryCode="CL" value="56">Chile (+56)</option>
//                         <option countryCode="CN" value="86">China (+86)</option>
//                         <option countryCode="CX" value="61">Christmas Island (+61)</option>
//                         <option countryCode="CC" value="61">Cocos Islands (+61)</option>
//                         <option countryCode="CO" value="57">Colombia (+57)</option>
//                         <option countryCode="KM" value="269">Comoros (+269)</option>
//                         <option countryCode="CK" value="682">Cook Islands (+682)</option>
//                         <option countryCode="CR" value="506">Costa Rica (+506)</option>
//                         <option countryCode="HR" value="385">Croatia (+385)</option>
//                         <option countryCode="CU" value="53">Cuba (+53)</option>
//                         <option countryCode="CW" value="599">Curacao (+599)</option>
//                         <option countryCode="CY" value="90">Cyprus - North (+90)</option>
//                         <option countryCode="CY" value="357">Cyprus - South (+357)</option>
//                         <option countryCode="CZ" value="420">Czech Republic (+420)</option>
//                         <option countryCode="CD" value="243">Democratic Republic of Congo (+243)</option>
//                         <option countryCode="DK" value="45">Denmark (+45)</option>
//                         <option countryCode="DJ" value="253">Djibouti (+253)</option>
//                         <option countryCode="DM" value="1809">Dominica (+1809)</option>
//                         <option countryCode="DO" value="1809">Dominican Republic (+1809)</option>
//                         <option countryCode="TL" value="670">East Timor (+670)</option>
//                         <option countryCode="EC" value="593">Ecuador (+593)</option>
//                         <option countryCode="EG" value="20">Egypt (+20)</option>
//                         <option countryCode="SV" value="503">El Salvador (+503)</option>
//                         <option countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
//                         <option countryCode="ER" value="291">Eritrea (+291)</option>
//                         <option countryCode="EE" value="372">Estonia (+372)</option>
//                         <option countryCode="ET" value="251">Ethiopia (+251)</option>
//                         <option countryCode="FK" value="500">Falkland Islands (+500)</option>
//                         <option countryCode="FO" value="298">Faroe Islands (+298)</option>
//                         <option countryCode="FJ" value="679">Fiji (+679)</option>
//                         <option countryCode="FI" value="358">Finland (+358)</option>
//                         <option countryCode="FR" value="33">France (+33)</option>
//                         <option countryCode="GF" value="594">French Guiana (+594)</option>
//                         <option countryCode="PF" value="689">French Polynesia (+689)</option>
//                         <option countryCode="GA" value="241">Gabon (+241)</option>
//                         <option countryCode="GM" value="220">Gambia (+220)</option>
//                         <option countryCode="GE" value="7880">Georgia (+7880)</option>
//                         <option countryCode="DE" value="49">Germany (+49)</option>
//                         <option countryCode="GH" value="233">Ghana (+233)</option>
//                         <option countryCode="GI" value="350">Gibraltar (+350)</option>
//                         <option countryCode="GR" value="30">Greece (+30)</option>
//                         <option countryCode="GL" value="299">Greenland (+299)</option>
//                         <option countryCode="GD" value="1473">Grenada (+1473)</option>
//                         <option countryCode="GP" value="590">Guadeloupe (+590)</option>
//                         <option countryCode="GU" value="671">Guam (+671)</option>
//                         <option countryCode="GT" value="502">Guatemala (+502)</option>
//                         <option countryCode="GG" value="44">Guernsey (+44)</option>
//                         <option countryCode="GN" value="224">Guinea (+224)</option>
//                         <option countryCode="GW" value="245">Guinea-Bissau (+245)</option>
//                         <option countryCode="GY" value="592">Guyana (+592)</option>
//                         <option countryCode="HT" value="509">Haiti (+509)</option>
//                         <option countryCode="HN" value="504">Honduras (+504)</option>
//                         <option countryCode="HK" value="852">Hong Kong (+852)</option>
//                         <option countryCode="HU" value="36">Hungary (+36)</option>
//                         <option countryCode="IS" value="354">Iceland (+354)</option>
//                         <option countryCode="IN" value="91">India (+91)</option>
//                         <option countryCode="ID" value="62">Indonesia (+62)</option>
//                         <option countryCode="IR" value="98">Iran (+98)</option>
//                         <option countryCode="IQ" value="964">Iraq (+964)</option>
//                         <option countryCode="IE" value="353">Ireland (+353)</option>
//                         <option countryCode="IM" value="44">Isle of Man (+44)</option>
//                         <option countryCode="IL" value="972">Israel (+972)</option>
//                         <option countryCode="IT" value="39">Italy (+39)</option>
//                         <option countryCode="CI" value="225">Ivory Coast (+225)</option>
//                         <option countryCode="JM" value="1876">Jamaica (+1876)</option>
//                         <option countryCode="JP" value="81">Japan (+81)</option>
//                         <option countryCode="JE" value="44">Jersey (+44)</option>
//                         <option countryCode="JO" value="962">Jordan (+962)</option>
//                         <option countryCode="KZ" value="7">Kazakhstan (+7)</option>
//                         <option countryCode="KE" value="254">Kenya (+254)</option>
//                         <option countryCode="KI" value="686">Kiribati (+686)</option>
//                         <option countryCode="XK" value="383">Kosovo (+383)</option>
//                         <option countryCode="KW" value="965">Kuwait (+965)</option>
//                         <option countryCode="KG" value="996">Kyrgyzstan (+996)</option>
//                         <option countryCode="LA" value="856">Laos (+856)</option>
//                         <option countryCode="LV" value="371">Latvia (+371)</option>
//                         <option countryCode="LB" value="961">Lebanon (+961)</option>
//                         <option countryCode="LS" value="266">Lesotho (+266)</option>
//                         <option countryCode="LR" value="231">Liberia (+231)</option>
//                         <option countryCode="LY" value="218">Libya (+218)</option>
//                         <option countryCode="LI" value="417">Liechtenstein (+417)</option>
//                         <option countryCode="LT" value="370">Lithuania (+370)</option>
//                         <option countryCode="LU" value="352">Luxembourg (+352)</option>
//                         <option countryCode="MO" value="853">Macao (+853)</option>
//                         <option countryCode="MK" value="389">Macedonia (+389)</option>
//                         <option countryCode="MG" value="261">Madagascar (+261)</option>
//                         <option countryCode="MW" value="265">Malawi (+265)</option>
//                         <option countryCode="MY" value="60">Malaysia (+60)</option>
//                         <option countryCode="MV" value="960">Maldives (+960)</option>
//                         <option countryCode="ML" value="223">Mali (+223)</option>
//                         <option countryCode="MT" value="356">Malta (+356)</option>
//                         <option countryCode="MH" value="692">Marshall Islands (+692)</option>
//                         <option countryCode="MQ" value="596">Martinique (+596)</option>
//                         <option countryCode="MR" value="222">Mauritania (+222)</option>
//                         <option countryCode="YT" value="269">Mayotte (+269)</option>
//                         <option countryCode="MX" value="52">Mexico (+52)</option>
//                         <option countryCode="FM" value="691">Micronesia (+691)</option>
//                         <option countryCode="MD" value="373">Moldova (+373)</option>
//                         <option countryCode="MC" value="377">Monaco (+377)</option>
//                         <option countryCode="MN" value="976">Mongolia (+976)</option>
//                         <option countryCode="ME" value="382">Montengro (+382)</option>
//                         <option countryCode="MS" value="1664">Montserrat (+1664)</option>
//                         <option countryCode="MA" value="212">Morocco (+212)</option>
//                         <option countryCode="MZ" value="258">Mozambique (+258)</option>
//                         <option countryCode="MN" value="95">Myanmar (+95)</option>
//                         <option countryCode="NA" value="264">Namibia (+264)</option>
//                         <option countryCode="NR" value="674">Nauru (+674)</option>
//                         <option countryCode="NP" value="977">Nepal (+977)</option>
//                         <option countryCode="NL" value="31">Netherlands (+31)</option>
//                         <option countryCode="AN" value="599">Netherlands Antilles (+599)</option>
//                         <option countryCode="NC" value="687">New Caledonia (+687)</option>
//                         <option countryCode="NZ" value="64">New Zealand (+64)</option>
//                         <option countryCode="NI" value="505">Nicaragua (+505)</option>
//                         <option countryCode="NE" value="227">Niger (+227)</option>
//                         <option countryCode="NG" value="234">Nigeria (+234)</option>
//                         <option countryCode="NU" value="683">Niue (+683)</option>
//                         <option countryCode="KP" value="850">North Korea (+850)</option>
//                         <option countryCode="NF" value="672">Norfolk Islands (+672)</option>
//                         <option countryCode="NP" value="670">Northern Marianas (+670)</option>
//                         <option countryCode="NO" value="47">Norway (+47)</option>
//                         <option countryCode="OM" value="968">Oman (+968)</option>
//                         <option countryCode="PK" value="92">Pakistan (+92)</option>
//                         <option countryCode="PW" value="680">Palau (+680)</option>
//                         <option countryCode="PS" value="970">Palestine (+970)</option>
//                         <option countryCode="PA" value="507">Panama (+507)</option>
//                         <option countryCode="PG" value="675">Papua New Guinea (+675)</option>
//                         <option countryCode="PY" value="595">Paraguay (+595)</option>
//                         <option countryCode="PE" value="51">Peru (+51)</option>
//                         <option countryCode="PH" value="63">Philippines (+63)</option>
//                         <option countryCode="PN" value="64">Pitcairn (+64)</option>
//                         <option countryCode="PL" value="48">Poland (+48)</option>
//                         <option countryCode="PT" value="351">Portugal (+351)</option>
//                         <option countryCode="PR" value="1787">Puerto Rico (+1787)</option>
//                         <option countryCode="QA" value="974">Qatar (+974)</option>
//                         <option countryCode="CG" value="242">Republic of the Congo (+242)</option>
//                         <option countryCode="RE" value="262">Reunion (+262)</option>
//                         <option countryCode="RO" value="40">Romania (+40)</option>
//                         <option countryCode="RU" value="7">Russia (+7)</option>
//                         <option countryCode="RW" value="250">Rwanda (+250)</option>
//                         <option countryCode="BL" value="590">Saint Barthelemy (+590)</option>
//                         <option countryCode="SH" value="290">Saint Helena (+290)</option>
//                         <option countryCode="KN" value="1869">Saint Kitts &amp; Nevis (+1869)</option>
//                         <option countryCode="SC" value="1758">Saint Lucia (+1758)</option>
//                         <option countryCode="SR" value="597">Suriname (+597)</option>
//                         <option countryCode="MF" value="590">Saint Martin (+590)</option>
//                         <option countryCode="PM" value="508">Saint Saint Pierre and Miquelon (+508)</option>
//                         <option countryCode="VC" value="1784">Saint Vincent and the Grenadines (+1784)</option>
//                         <option countryCode="WS" value="685">Samoa (+685)</option>
//                         <option countryCode="SM" value="378">San Marino (+378)</option>
//                         <option countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
//                         <option countryCode="SA" value="966">Saudi Arabia (+966)</option>
//                         <option countryCode="SN" value="221">Senegal (+221)</option>
//                         <option countryCode="CS" value="381">Serbia (+381)</option>
//                         <option countryCode="SC" value="248">Seychelles (+248)</option>
//                         <option countryCode="SL" value="232">Sierra Leone (+232)</option>
//                         <option countryCode="SG" value="65">Singapore (+65)</option>
//                         <option countryCode="SX" value="1721">Sint Maarten (+1721)</option>
//                         <option countryCode="SK" value="421">Slovakia (+421)</option>
//                         <option countryCode="SI" value="386">Slovenia (+386)</option>
//                         <option countryCode="SB" value="677">Solomon Islands (+677)</option>
//                         <option countryCode="SO" value="252">Somalia (+252)</option>
//                         <option countryCode="ZA" value="27">South Africa (+27)</option>  
//                         <option countryCode="KR" value="82">South Korea (+82)</option>
//                         <option countryCode="SS" value="211">South Sudan (+211)</option>
//                         <option countryCode="ES" value="34">Spain (+34)</option>
//                         <option countryCode="LK" value="94">Sri Lanka (+94)</option>
//                         <option countryCode="SD" value="249">Sudan (+249)</option>
//                         <option countryCode="SR" value="597">Suriname (+597)</option>
//                         <option countryCode="SJ" value="47">Svalbard &amp; Jan Mayen (+47)</option>
//                         <option countryCode="SZ" value="268">Swaziland (+268)</option>
//                         <option countryCode="SE" value="46">Sweden (+46)</option>
//                         <option countryCode="CH" value="41">Switzerland (+41)</option>
//                         <option countryCode="SY" value="963">Syria (+963)</option>
//                         <option countryCode="TW" value="886">Taiwan (+886)</option>
//                         <option countryCode="TJ" value="992">Tajikistan (+992)</option>
//                         <option countryCode="TZ" value="255">Tanzania (+255)</option>
//                         <option countryCode="TH" value="66">Thailand (+66)</option>
//                         <option countryCode="TG" value="228">Togo (+228)</option>
//                         <option countryCode="TO" value="676">Tonga (+676)</option>
//                         <option countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
//                         <option countryCode="TN" value="216">Tunisia (+216)</option>
//                         <option countryCode="TR" value="90">Turkey (+90)</option>
//                         <option countryCode="TM" value="993">Turkmenistan (+993)</option>
//                         <option countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
//                         <option countryCode="TV" value="688">Tuvalu (+688)</option>
//                         <option countryCode="UG" value="256">Uganda (+256)</option>
//                         <option countryCode="UA" value="380">Ukraine (+380)</option>
//                         <option countryCode="AE" value="971">United Arab Emirates (+971)</option>
//                         <option countryCode="GB" value="44">United Kingdom (+44)</option>
//                         <option countryCode="US" value="1">United States (+1)</option>
//                         <option countryCode="UY" value="598">Uruguay (+598)</option>
//                         <option countryCode="UZ" value="998">Uzbekistan (+998)</option>
//                         <option countryCode="VU" value="678">Vanuatu (+678)</option>
//                         <option countryCode="VA" value="379">Vatican City (+379)</option>
//                         <option countryCode="VE" value="58">Venezuela (+58)</option>
//                         <option countryCode="VN" value="84">Vietnam (+84)</option>
//                         <option countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
//                         <option countryCode="YE" value="969">Yemen (North)(+969)</option>
//                         <option countryCode="YE" value="967">Yemen (South)(+967)</option>
//                         <option countryCode="ZM" value="260">Zambia (+260)</option>
//                         <option countryCode="ZW" value="263">Zimbabwe (+263)</option>
//                       </select>
//   return (
//     <div>

//     </div>
//   )
// }

// export default PhoneFlag

// import React, { Component } from 'react';
// import './App.css';
// export class App extends Component {
// 	state = {
// 		flagImg: 'https://raw.githubusercontent.com/MeRahulAhire/intl-tel-code-select/master/phone_icon.png',
// 		countryCodeValue: '',
// 		phone: ''
// 	};
// 	selectCountryHandler = (e) => {
// 		this.setState({ countryCodeValue: e.target.value });
// 	};
// 	phoneHandler = (e) => {
// 		this.setState({ phone: e.target.value });
// 	};
// 	countryFlagHandler = () => {
// 		const select = document.querySelector('#country');
// 		this.setState({
// 			flagImg: `https://flagpedia.net/data/flags/h80/${select.selectedOptions[0].dataset.countrycode.toLowerCase()}.webp`
// 		});
// 	};

// 	render() {
// 		return (
// 			<div className="page">
// 				<div className="container">
// 					<h1 className="heading">Tel-picker in React</h1>
// 					<div className="tel-box">
// 						<div className="select-box" onChange={this.countryFlagHandler}>
// 							<img src={this.state.flagImg} alt="country-flag" id="img" className="flag-img" />
// 							<select
// 								id="country"
// 								onChange={this.selectCountryHandler}
// 								defaultValue={this.state.countryCodeValue}
// 							>
// 								<option value="" hidden>
// 									Select Country
// 								</option>
// 								<option countryCode="AF" value="93">
// 									Afghanistan (+93)
// 								</option>
// 								<option countryCode="AL" value="355">
// 									Albania (+355)
// 								</option>

// 								<option countryCode="ZW" value="263">
// 									Zimbabwe (+263)
// 								</option>
// 							</select>
// 						</div>
// 						<input
// 							type="tel"
// 							placeholder="945 678 0391"
// 							className="tel"
// 							onChange={this.phoneHandler}
// 							defaultValue={this.state.phone}
// 						/>
// 					</div>
// 					<div className="output">
// 						<h2>Phone no:</h2>
// 						<span className="result">
// 							+{this.state.countryCodeValue}
// 							{this.state.phone}
// 						</span>
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

// export default App;




import React, { useEffect, useState } from 'react';


const PhoneFlag = ({setCountryNumber,selectedCountryCode}) => {
	console.log('selectedCountryCode :>> ', selectedCountryCode);
	const [flagImg, setFlagImg] = useState(`https://flagpedia.net/data/flags/h80/in.webp`);
	// const [countryCodeValue, setCountryCodeValue] = useState(selectedCountryCode == undefined ?'91':selectedCountryCode);




	  const [countryCodeValue, setCountryCodeValue] = useState(selectedCountryCode == undefined ? '91':selectedCountryCode);

	// const [countryNumber, setCountryNumber] = useState('91');
	const [countryCode, setCountryCode] = useState('IN');
	//  const [flagImg, setFlagImg] = useState(`https://flagpedia.net/data/flags/h80/${countryCode?.toLowerCase()}.webp`);
	const [phone, setPhone] = useState('');

	const selectCountryHandler = (e) => {
		console.log("slecet inside");
		const { name, value } = e.target
		const countryCodeVal = e.target.options[e.target.selectedIndex].getAttribute('countryCode');
		const selectedOption = e.target.selectedOptions[0];
		const countryCode2 = selectedOption?.textContent?.split(/\(|\)/)?.[1]; // Assuming the country code is separated by a space
		console.log(countryCode2, "countryCode2");
		console.log('object :>> ', e.target.value);
		setCountryCodeValue(countryCode2);
		// setCountryNumber(countryCode2);
		setCountryNumber(e.target.value);
		
		setCountryCode(countryCodeVal)
	};

	const phoneHandler = (e) => {
		setPhone(e.target.value);
	};

	const countryFlagHandler = () => {



		setFlagImg(`https://flagpedia.net/data/flags/h80/${countryCode?.toLowerCase()}.webp`);
	};


	useEffect(() => {
		countryFlagHandler()
	}, [countryCode])

	console.log(flagImg, "flagImg");
	return (
		<div className="page">
				<div className="tel-box">
					<div className="select-box">
						<div className='d-flex gap-1 select-num'>
							<img src={flagImg} alt="country-flag" id="img" style={{ width: '20px', height: '20px' }} />
							<select className='w-80'
								// value={countryNumber}
								id="country" onChange={selectCountryHandler} defaultValue={countryCodeValue}>
								<option countryCode="AF" value="93">
									Afghanistan (+93)
								</option>
								<option countryCode="AL" value="355">
									Albania (+355)
								</option>
								<option countryCode="DZ" value="213">
									Algeria (+213)
								</option>
								<option countryCode="AS" value="1684">
									American Samoa (+1684)
								</option>
								<option countryCode="AD" value="376">
									Andorra (+376)
								</option>
								<option countryCode="AO" value="244">
									Angola (+244)
								</option>
								<option countryCode="AI" value="1264">
									Anguilla (+1264)
								</option>
								<option countryCode="AQ" value="672">
									Antartica (+672)
								</option>
								<option countryCode="AG" value="1268">
									Antigua &amp; Barbuda (+1268)
								</option>
								<option countryCode="AR" value="54">
									Argentina (+54)
								</option>
								<option countryCode="AM" value="374">
									Armenia (+374)
								</option>
								<option countryCode="AW" value="297">
									Aruba (+297)
								</option>
								<option countryCode="AU" value="61">
									Australia (+61)
								</option>
								<option countryCode="AT" value="43">
									Austria (+43)
								</option>
								<option countryCode="AZ" value="994">
									Azerbaijan (+994)
								</option>
								<option countryCode="BS" value="1242">
									Bahamas (+1242)
								</option>
								<option countryCode="BH" value="973">
									Bahrain (+973)
								</option>
								<option countryCode="BD" value="880">
									Bangladesh (+880)
								</option>
								<option countryCode="BB" value="1246">
									Barbados (+1246)
								</option>
								<option countryCode="BY" value="375">
									Belarus (+375)
								</option>
								<option countryCode="BE" value="32">
									Belgium (+32)
								</option>
								<option countryCode="BZ" value="501">
									Belize (+501)
								</option>
								<option countryCode="BJ" value="229">
									Benin (+229)
								</option>
								<option countryCode="BM" value="1441">
									Bermuda (+1441)
								</option>
								<option countryCode="BT" value="975">
									Bhutan (+975)
								</option>
								<option countryCode="BO" value="591">
									Bolivia (+591)
								</option>
								<option countryCode="BA" value="387">
									Bosnia &amp; Herzegovina (+387)
								</option>
								<option countryCode="BW" value="267">
									Botswana (+267)
								</option>
								<option countryCode="BR" value="55">
									Brazil (+55)
								</option>
								<option countryCode="IO" value="246">
									British India Ocean Terrirory (+246)
								</option>
								<option countryCode="VG" value="1284">
									British Virgin Islands (+1284)
								</option>
								<option countryCode="BN" value="673">
									Brunei (+673)
								</option>
								<option countryCode="BG" value="359">
									Bulgaria (+359)
								</option>
								<option countryCode="BF" value="226">
									Burkina Faso (+226)
								</option>
								<option countryCode="BI" value="257">
									Burundi (+257)
								</option>
								<option countryCode="KH" value="855">
									Cambodia (+855)
								</option>
								<option countryCode="CM" value="237">
									Cameroon (+237)
								</option>
								<option countryCode="CA" value="1">
									Canada (+1)
								</option>
								<option countryCode="CV" value="238">
									Cape Verde Islands (+238)
								</option>
								<option countryCode="KY" value="1345">
									Cayman Islands (+1345)
								</option>
								<option countryCode="CF" value="236">
									Central African Republic (+236)
								</option>
								<option countryCode="TD" value="235">
									Chad (+235)
								</option>
								<option countryCode="CL" value="56">
									Chile (+56)
								</option>
								<option countryCode="CN" value="86">
									China (+86)
								</option>
								<option countryCode="CX" value="61">
									Christmas Island (+61)
								</option>
								<option countryCode="CC" value="61">
									Cocos Islands (+61)
								</option>
								<option countryCode="CO" value="57">
									Colombia (+57)
								</option>
								<option countryCode="KM" value="269">
									Comoros (+269)
								</option>
								<option countryCode="CK" value="682">
									Cook Islands (+682)
								</option>
								<option countryCode="CR" value="506">
									Costa Rica (+506)
								</option>
								<option countryCode="HR" value="385">
									Croatia (+385)
								</option>
								<option countryCode="CU" value="53">
									Cuba (+53)
								</option>
								<option countryCode="CW" value="599">
									Curacao (+599)
								</option>
								<option countryCode="CY" value="90">
									Cyprus - North (+90)
								</option>
								<option countryCode="CY" value="357">
									Cyprus - South (+357)
								</option>
								<option countryCode="CZ" value="420">
									Czech Republic (+420)
								</option>
								<option countryCode="CD" value="243">
									Democratic Republic of Congo (+243)
								</option>
								<option countryCode="DK" value="45">
									Denmark (+45)
								</option>
								<option countryCode="DJ" value="253">
									Djibouti (+253)
								</option>
								<option countryCode="DM" value="1809">
									Dominica (+1809)
								</option>
								<option countryCode="DO" value="1809">
									Dominican Republic (+1809)
								</option>
								<option countryCode="TL" value="670">
									East Timor (+670)
								</option>
								<option countryCode="EC" value="593">
									Ecuador (+593)
								</option>
								<option countryCode="EG" value="20">
									Egypt (+20)
								</option>
								<option countryCode="SV" value="503">
									El Salvador (+503)
								</option>
								<option countryCode="GQ" value="240">
									Equatorial Guinea (+240)
								</option>
								<option countryCode="ER" value="291">
									Eritrea (+291)
								</option>
								<option countryCode="EE" value="372">
									Estonia (+372)
								</option>
								<option countryCode="ET" value="251">
									Ethiopia (+251)
								</option>
								<option countryCode="FK" value="500">
									Falkland Islands (+500)
								</option>
								<option countryCode="FO" value="298">
									Faroe Islands (+298)
								</option>
								<option countryCode="FJ" value="679">
									Fiji (+679)
								</option>
								<option countryCode="FI" value="358">
									Finland (+358)
								</option>
								<option countryCode="FR" value="33">
									France (+33)
								</option>
								<option countryCode="GF" value="594">
									French Guiana (+594)
								</option>
								<option countryCode="PF" value="689">
									French Polynesia (+689)
								</option>
								<option countryCode="GA" value="241">
									Gabon (+241)
								</option>
								<option countryCode="GM" value="220">
									Gambia (+220)
								</option>
								<option countryCode="GE" value="7880">
									Georgia (+7880)
								</option>
								<option countryCode="DE" value="49">
									Germany (+49)
								</option>
								<option countryCode="GH" value="233">
									Ghana (+233)
								</option>
								<option countryCode="GI" value="350">
									Gibraltar (+350)
								</option>
								<option countryCode="GR" value="30">
									Greece (+30)
								</option>
								<option countryCode="GL" value="299">
									Greenland (+299)
								</option>
								<option countryCode="GD" value="1473">
									Grenada (+1473)
								</option>
								<option countryCode="GP" value="590">
									Guadeloupe (+590)
								</option>
								<option countryCode="GU" value="671">
									Guam (+671)
								</option>
								<option countryCode="GT" value="502">
									Guatemala (+502)
								</option>
								<option countryCode="GG" value="44">
									Guernsey (+44)
								</option>
								<option countryCode="GN" value="224">
									Guinea (+224)
								</option>
								<option countryCode="GW" value="245">
									Guinea-Bissau (+245)
								</option>
								<option countryCode="GY" value="592">
									Guyana (+592)
								</option>
								<option countryCode="HT" value="509">
									Haiti (+509)
								</option>
								<option countryCode="HN" value="504">
									Honduras (+504)
								</option>
								<option countryCode="HK" value="852">
									Hong Kong (+852)
								</option>
								<option countryCode="HU" value="36">
									Hungary (+36)
								</option>
								<option countryCode="IS" value="354">
									Iceland (+354)
								</option>
								<option countryCode="IN" value="91">
									India (+91)
								</option>
								<option countryCode="ID" value="62">
									Indonesia (+62)
								</option>
								<option countryCode="IR" value="98">
									Iran (+98)
								</option>
								<option countryCode="IQ" value="964">
									Iraq (+964)
								</option>
								<option countryCode="IE" value="353">
									Ireland (+353)
								</option>
								<option countryCode="IM" value="44">
									Isle of Man (+44)
								</option>
								<option countryCode="IL" value="972">
									Israel (+972)
								</option>
								<option countryCode="IT" value="39">
									Italy (+39)
								</option>
								<option countryCode="CI" value="225">
									Ivory Coast (+225)
								</option>
								<option countryCode="JM" value="1876">
									Jamaica (+1876)
								</option>
								<option countryCode="JP" value="81">
									Japan (+81)
								</option>
								<option countryCode="JE" value="44">
									Jersey (+44)
								</option>
								<option countryCode="JO" value="962">
									Jordan (+962)
								</option>
								<option countryCode="KZ" value="7">
									Kazakhstan (+7)
								</option>
								<option countryCode="KE" value="254">
									Kenya (+254)
								</option>
								<option countryCode="KI" value="686">
									Kiribati (+686)
								</option>
								<option countryCode="XK" value="383">
									Kosovo (+383)
								</option>
								<option countryCode="KW" value="965">
									Kuwait (+965)
								</option>
								<option countryCode="KG" value="996">
									Kyrgyzstan (+996)
								</option>
								<option countryCode="LA" value="856">
									Laos (+856)
								</option>
								<option countryCode="LV" value="371">
									Latvia (+371)
								</option>
								<option countryCode="LB" value="961">
									Lebanon (+961)
								</option>
								<option countryCode="LS" value="266">
									Lesotho (+266)
								</option>
								<option countryCode="LR" value="231">
									Liberia (+231)
								</option>
								<option countryCode="LY" value="218">
									Libya (+218)
								</option>
								<option countryCode="LI" value="417">
									Liechtenstein (+417)
								</option>
								<option countryCode="LT" value="370">
									Lithuania (+370)
								</option>
								<option countryCode="LU" value="352">
									Luxembourg (+352)
								</option>
								<option countryCode="MO" value="853">
									Macao (+853)
								</option>
								<option countryCode="MK" value="389">
									Macedonia (+389)
								</option>
								<option countryCode="MG" value="261">
									Madagascar (+261)
								</option>
								<option countryCode="MW" value="265">
									Malawi (+265)
								</option>
								<option countryCode="MY" value="60">
									Malaysia (+60)
								</option>
								<option countryCode="MV" value="960">
									Maldives (+960)
								</option>
								<option countryCode="ML" value="223">
									Mali (+223)
								</option>
								<option countryCode="MT" value="356">
									Malta (+356)
								</option>
								<option countryCode="MH" value="692">
									Marshall Islands (+692)
								</option>
								<option countryCode="MQ" value="596">
									Martinique (+596)
								</option>
								<option countryCode="MR" value="222">
									Mauritania (+222)
								</option>
								<option countryCode="YT" value="269">
									Mayotte (+269)
								</option>
								<option countryCode="MX" value="52">
									Mexico (+52)
								</option>
								<option countryCode="FM" value="691">
									Micronesia (+691)
								</option>
								<option countryCode="MD" value="373">
									Moldova (+373)
								</option>
								<option countryCode="MC" value="377">
									Monaco (+377)
								</option>
								<option countryCode="MN" value="976">
									Mongolia (+976)
								</option>
								<option countryCode="ME" value="382">
									Montengro (+382)
								</option>
								<option countryCode="MS" value="1664">
									Montserrat (+1664)
								</option>
								<option countryCode="MA" value="212">
									Morocco (+212)
								</option>
								<option countryCode="MZ" value="258">
									Mozambique (+258)
								</option>
								<option countryCode="MN" value="95">
									Myanmar (+95)
								</option>
								<option countryCode="NA" value="264">
									Namibia (+264)
								</option>
								<option countryCode="NR" value="674">
									Nauru (+674)
								</option>
								<option countryCode="NP" value="977">
									Nepal (+977)
								</option>
								<option countryCode="NL" value="31">
									Netherlands (+31)
								</option>
								<option countryCode="AN" value="599">
									Netherlands Antilles (+599)
								</option>
								<option countryCode="NC" value="687">
									New Caledonia (+687)
								</option>
								<option countryCode="NZ" value="64">
									New Zealand (+64)
								</option>
								<option countryCode="NI" value="505">
									Nicaragua (+505)
								</option>
								<option countryCode="NE" value="227">
									Niger (+227)
								</option>
								<option countryCode="NG" value="234">
									Nigeria (+234)
								</option>
								<option countryCode="NU" value="683">
									Niue (+683)
								</option>
								<option countryCode="KP" value="850">
									North Korea (+850)
								</option>
								<option countryCode="NF" value="672">
									Norfolk Islands (+672)
								</option>
								<option countryCode="NP" value="670">
									Northern Marianas (+670)
								</option>
								<option countryCode="NO" value="47">
									Norway (+47)
								</option>
								<option countryCode="OM" value="968">
									Oman (+968)
								</option>
								<option countryCode="PK" value="92">
									Pakistan (+92)
								</option>
								<option countryCode="PW" value="680">
									Palau (+680)
								</option>
								<option countryCode="PS" value="970">
									Palestine (+970)
								</option>
								<option countryCode="PA" value="507">
									Panama (+507)
								</option>
								<option countryCode="PG" value="675">
									Papua New Guinea (+675)
								</option>
								<option countryCode="PY" value="595">
									Paraguay (+595)
								</option>
								<option countryCode="PE" value="51">
									Peru (+51)
								</option>
								<option countryCode="PH" value="63">
									Philippines (+63)
								</option>
								<option countryCode="PN" value="64">
									Pitcairn (+64)
								</option>
								<option countryCode="PL" value="48">
									Poland (+48)
								</option>
								<option countryCode="PT" value="351">
									Portugal (+351)
								</option>
								<option countryCode="PR" value="1787">
									Puerto Rico (+1787)
								</option>
								<option countryCode="QA" value="974">
									Qatar (+974)
								</option>
								<option countryCode="CG" value="242">
									Republic of the Congo (+242)
								</option>
								<option countryCode="RE" value="262">
									Reunion (+262)
								</option>
								<option countryCode="RO" value="40">
									Romania (+40)
								</option>
								<option countryCode="RU" value="7">
									Russia (+7)
								</option>
								<option countryCode="RW" value="250">
									Rwanda (+250)
								</option>
								<option countryCode="BL" value="590">
									Saint Barthelemy (+590)
								</option>
								<option countryCode="SH" value="290">
									Saint Helena (+290)
								</option>
								<option countryCode="KN" value="1869">
									Saint Kitts &amp; Nevis (+1869)
								</option>
								<option countryCode="SC" value="1758">
									Saint Lucia (+1758)
								</option>
								<option countryCode="SR" value="597">
									Suriname (+597)
								</option>
								<option countryCode="MF" value="590">
									Saint Martin (+590)
								</option>
								<option countryCode="PM" value="508">
									Saint Saint Pierre and Miquelon (+508)
								</option>
								<option countryCode="VC" value="1784">
									Saint Vincent and the Grenadines (+1784)
								</option>
								<option countryCode="WS" value="685">
									Samoa (+685)
								</option>
								<option countryCode="SM" value="378">
									San Marino (+378)
								</option>
								<option countryCode="ST" value="239">
									Sao Tome &amp; Principe (+239)
								</option>
								<option countryCode="SA" value="966">
									Saudi Arabia (+966)
								</option>
								<option countryCode="SN" value="221">
									Senegal (+221)
								</option>
								<option countryCode="CS" value="381">
									Serbia (+381)
								</option>
								<option countryCode="SC" value="248">
									Seychelles (+248)
								</option>
								<option countryCode="SL" value="232">
									Sierra Leone (+232)
								</option>
								<option countryCode="SG" value="65">
									Singapore (+65)
								</option>
								<option countryCode="SX" value="1721">
									Sint Maarten (+1721)
								</option>
								<option countryCode="SK" value="421">
									Slovakia (+421)
								</option>
								<option countryCode="SI" value="386">
									Slovenia (+386)
								</option>
								<option countryCode="SB" value="677">
									Solomon Islands (+677)
								</option>
								<option countryCode="SO" value="252">
									Somalia (+252)
								</option>
								<option countryCode="ZA" value="27">
									South Africa (+27)
								</option>
								<option countryCode="KR" value="82">
									South Korea (+82)
								</option>
								<option countryCode="SS" value="211">
									South Sudan (+211)
								</option>
								<option countryCode="ES" value="34">
									Spain (+34)
								</option>
								<option countryCode="LK" value="94">
									Sri Lanka (+94)
								</option>
								<option countryCode="SD" value="249">
									Sudan (+249)
								</option>
								<option countryCode="SR" value="597">
									Suriname (+597)
								</option>
								<option countryCode="SJ" value="47">
									Svalbard &amp; Jan Mayen (+47)
								</option>
								<option countryCode="SZ" value="268">
									Swaziland (+268)
								</option>
								<option countryCode="SE" value="46">
									Sweden (+46)
								</option>
								<option countryCode="CH" value="41">
									Switzerland (+41)
								</option>
								<option countryCode="SY" value="963">
									Syria (+963)
								</option>
								<option countryCode="TW" value="886">
									Taiwan (+886)
								</option>
								<option countryCode="TJ" value="992">
									Tajikistan (+992)
								</option>
								<option countryCode="TZ" value="255">
									Tanzania (+255)
								</option>
								<option countryCode="TH" value="66">
									Thailand (+66)
								</option>
								<option countryCode="TG" value="228">
									Togo (+228)
								</option>
								<option countryCode="TO" value="676">
									Tonga (+676)
								</option>
								<option countryCode="TT" value="1868">
									Trinidad &amp; Tobago (+1868)
								</option>
								<option countryCode="TN" value="216">
									Tunisia (+216)
								</option>
								<option countryCode="TR" value="90">
									Turkey (+90)
								</option>
								<option countryCode="TM" value="993">
									Turkmenistan (+993)
								</option>
								<option countryCode="TC" value="1649">
									Turks &amp; Caicos Islands (+1649)
								</option>
								<option countryCode="TV" value="688">
									Tuvalu (+688)
								</option>
								<option countryCode="UG" value="256">
									Uganda (+256)
								</option>
								<option countryCode="UA" value="380">
									Ukraine (+380)
								</option>
								<option countryCode="AE" value="971">
									United Arab Emirates (+971)
								</option>
								<option countryCode="GB" value="44">
									United Kingdom (+44)
								</option>
								<option countryCode="US" value="1">
									United States (+1)
								</option>
								<option countryCode="UY" value="598">
									Uruguay (+598)
								</option>
								<option countryCode="UZ" value="998">
									Uzbekistan (+998)
								</option>
								<option countryCode="VU" value="678">
									Vanuatu (+678)
								</option>
								<option countryCode="VA" value="379">
									Vatican City (+379)
								</option>
								<option countryCode="VE" value="58">
									Venezuela (+58)
								</option>
								<option countryCode="VN" value="84">
									Vietnam (+84)
								</option>
								<option countryCode="WF" value="681">
									Wallis &amp; Futuna (+681)
								</option>
								<option countryCode="YE" value="969">
									Yemen (North)(+969)
								</option>
								<option countryCode="YE" value="967">
									Yemen (South)(+967)
								</option>
								<option countryCode="ZM" value="260">
									Zambia (+260)
								</option>
								<option countryCode="ZW" value="263">
									Zimbabwe (+263)
								</option>
							</select>
						</div>
					</div>
					{/* <input
            type="tel"
            placeholder="945 678 0391"
            className="tel"
            onChange={phoneHandler}
            defaultValue={phone}
          /> */}
				</div>
		</div>
	);
};

export default PhoneFlag;
