import React, { useEffect, useState, useRef, useContext } from "react";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import ModalContext from "../../../../context/ModalContext";
function Audio({ audio }) {
  let { popUpType, setPopUpType,setAnswerJson ,answerJson,setManageRecordings,manageRecordings} = useContext(ModalContext);
  const [isSpeakerEnabled, setIsSpeakerEnabled] = useState(true);
  const [count, setCount] = useState(100);
  const audioRef = useRef(null);
  function handleVolumeChange(val) {
    const volumeValue = Number(val);  
    setCount(volumeValue);
    if(audioRef.current){
      audioRef.current.volume = volumeValue / 100;
    }
    
  }
  

  const [currentTime, setCurrentTime] = useState(0);
useEffect(()=>{
if(currentTime >= 100){
  setPopUpType("default")
  setManageRecordings({...manageRecordings,start:true})
}else{
  setPopUpType("prepare")
}
},[currentTime])
  useEffect(() => {
    if (audio) {
      var playPromise = audioRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
      // if (audioRef.current) {
      //     audioRef.current.play();
      //   }
    }
  }, [audio]);

  const myFunction = () => {
    setCurrentTime(
      audioRef.current.currentTime * (100 / audioRef.current?.duration)
    );
  };
  console.log("currentTime",currentTime)
  return (
    <>
    <p className="w-100 text-secondary exam-vol">
            {/* Volume : {count}% */}
            <span className="w-25">Volume</span>
            <input
              className="w-75"
              type="range"
              value={count}
              onChange={(e) => handleVolumeChange(e.target.value)}
              max="100"
              step="1"
              id="volume-control"
            />
            </p>
    <ProgressBar className='progress-ui' now={currentTime} variant="hisuccess"  />
      {audio && (
        <audio
          className="d-none"
          onTimeUpdate={myFunction}
          ref={audioRef}
          src={audio}
          controls
        ></audio>
      )}
    </>
  );
}

export default Audio;
