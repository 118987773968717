import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
import StudentResultContext from '../../../../context/StudentResultContext/StudentResultContext';
function ResultHIW({fib_wrQuest,ansItem}) {

  // const {studentResult}=React.useContext(StudentResultContext)
  // let fib_wrQuest=studentResult?.questions?.filter((item)=>
  // item?.module_name == "fib_wr")
  
  console.log('fib_wrQu23est :>> ',fib_wrQuest);
  
  // let fib_wrAns=studentResult?.answers?.filter((item)=>
  // item?.submodule == "fib_wr")
  
  console.log('fib_wrAnsansItem :>> ',ansItem)
  // const res=fib_wrAns?.map((ansitem,index)=>{
  //  return ansitem.answer?.split(",").map((ansEle) => {

  //    return ansEle
  //  }
  // )
  // })

  const res= ansItem?.answer?.split(",").map((ansEle) => {
 
      return ansEle
    }
   )
   

  



  console.log('afsdfres :>> ', res);


  // let fib_wrQuestAll=studentResult?.filter((item)=>
  // item?.module_name == "fib_wr" && item?.submodule == "fib_wr")
  

  
  // const jsxResult = fib_wrQuest?.question_content?.split(" ")?.map((ele, index) => {
  //   const matchedOption = fib_wrQuest?.question_options?.find(item => item.options === ele);

  //   console.log('matchedOption :>> ', matchedOption);
    
  //   return matchedOption ? (
  //     <span key={index} className='text-danger fw-bold'>{ele} <span  key={index} className='text-success fw-bold'>{matchedOption?.answer}</span> </span>
  //   ) : (
  //     <span key={index}>{ele} </span>
  //   );
  // });


  return (
    <>
    {/* <div className='p-3 text-middark'>
      Learning to write well in college means learning (or re-learning) how to write clearly and plainly. Now that doesn't mean that plainness is the only good style, or that you should become a
      <div className='res-ans text-success'><span><img src='/images/right-ans.svg'></img> slave</span> (Answer : slave)</div>
      to spare, unadorned writing. Formality and ornateness have their place, and in
      <div className='res-ans text-danger'><span><img src='/images/wrong-ans.svg'></img> helping</span> (Answer : competent)</div>
      hands complexity can take us on a dizzying, breathtaking journey. But most students, most of the time should
      <div className='res-ans text-danger'><span><img src='/images/wrong-ans.svg'></img> reject</span> (Answer : endeavor)</div>
      to be sensibly simple to develop a baseline style of short words, active verbs and relatively simple sentence
      <div className='res-ans text-success'><span><img src='/images/right-ans.svg'></img> carrying</span> (Answer : carrying)</div>
      clear actions or identities. It's faster, it makes arguments easier to follow, it increases the chances a busy reader will bother to pay attention, and it lets you
      <div className='res-ans text-success'><span><img src='/images/right-ans.svg'></img> center</span> (Answer : center)</div>
      more attention on your moments of rhetorical flourish which I do not advise abandoning altogether.
    </div> */}


    {/* <div className='p-3 text-middark'>
   {
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")
    })
   }
  </div> */}

{/* map inside map */}
      {/* <div className='p-3 text-middark'>
   {
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((item)=>{
        return item + " "
      })
    })
   }
  </div>
   */}



  
      {/* <div className='p-3 text-middark'>
      {
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((ele)=>{
        return item?.answer?.split(",")?.includes(ele) ? <span className='text-success fw-bold'>(Answer:{ele}) </span> : <span>{ele} </span>
      })
    })
   }
  </div> */}
     {/* <div className='p-3 text-middark'>
      {
    fib_wrQuest?.question_content?.split(" ")?.map((ele)=>{
      
      return fib_wrQuest?.question_options?.some(item=>item?.options === ele) ? <span className='text-danger fw-bold'>{ele}() </span> : <span>{ele} </span>
     
    })
   }
  </div> */}
<div className='p-3 text-middark'>
{  fib_wrQuest?.question_content?.split(" ")?.map((ele, index) => {
    const matchedOption = fib_wrQuest?.question_options?.find(item => item.options === ele);

    console.log('matchedOption :>> ', matchedOption);
    
    return matchedOption ? (
      <span key={index} className='text-danger fw-bold'>{ele} <span  key={index} className='text-success fw-bold'>{`(Answer: ${matchedOption?.answer})`}</span> </span>
    ) : (
      <span key={index}>{ele} </span>
    );
  })
}
</div>
  


{/* {fib_wrQuest?.[0]?.question_content?.split(" ")?.map((ele) => {return fib_wrQuest?.[0]?.answer?.split(",")?.includes(ele) ? <span className='border-bottom border-danger'>{ele} </span> : <span>{ele} </span>} )} */}




{/* 

{arrayOfArrays.map((array, index) => (
        <ul key={index}>
          {array.map((item, subIndex) => (
            <li key={subIndex}>{item}</li>
          ))}
        </ul>
      ))}
   */}
  {/* <div className='p-3 text-middark'>
      {
        fib_wrAns?.map((ansitem,index)=>(
          <ul key={index}>
  

       {ansitem.answer?.split(",").map((ansEle, idx) => (
       <span key={idx}>

     {
      
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((ele)=>{
       
        return item?.answer?.split(",")?.includes(ele) ? <span className='text-success'>({ansEle})(Answer:{ele}) </span> : <span>{ele} </span>
      })
    })}
    </span>))}
    </ul>))

    
   }




  </div> */}
 

{/* <div className='p-3 text-middark'>
      {
        fib_wrAns?.map((ansitem,index)=>(
          <ul key={index}>
  

       {ansitem.answer?.split(",").map((ansEle, idx) => (
       <span key={idx}>

     {
      
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((ele)=>{
       
        return   item?.answer?.split(",")?.includes(ele) ? <span className='text-success'>({res?.[0]?.[idx]})(Answer:{ele}) </span> : <span>{ele} </span>
      })
    })}
    </span>))}
    </ul>))

    
   }



  </div> */}

  

      {/* {console.log("ext-middar",fib_wrQuest?.length>0 && fib_wrQuest[0].question_content?.split(" "))} */}
  {/* <div className='p-3 text-middark'>
   {
    fib_wrQuest?.length>0 && fib_wrQuest[0].question_content?.split(" ")?.map((item)=>{
      return item + " "
    })
   }
  </div> */}

  </>
  );
}
export default ResultHIW;