export const format_date = (date) =>{
    const givenDate = new Date(date);
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    
      // Extract year, month, and day from the Date object
      const year = givenDate.getUTCFullYear();
      const monthIndex = givenDate.getUTCMonth();
      const day = givenDate.getUTCDate();
    
      // Create the desired date format
      const desiredDateFormat = `${day} ${monthNames[monthIndex]} ${year}`;
      return desiredDateFormat
}

export function convertTimeToMinutesAndSeconds(timeString) {
    const [hours, minutes, seconds] = timeString?.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    return `${totalMinutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }
  
  // Example usage:
//   const originalTime = "01:10:00";
//   const formattedTime = convertTimeToMinutesAndSeconds(originalTime);
//   console.log(formattedTime); // Output: "70:00"
  