import CryptoJS from 'crypto-js';
const secretKey = 'OQuh4sfvIo2oncBylg3yBkV3OQuh4sfv';
export function decryptResponse(text) {
  const [iv, encryptedText] = text.split(':');
  const ivBytes = CryptoJS.enc.Hex.parse(iv);
  const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedText);
  const keyBytes = CryptoJS.enc.Utf8.parse(secretKey);
  
  const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      keyBytes,
      { iv: ivBytes }
  );
  return CryptoJS.enc.Utf8.stringify(decrypted);
}