import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function QuestionDescribeImage({img}) {

  return (
      <div className='d-flex justify-content-start my-5'>
        {/* <img src='/images/des-img.png' className='desc-image'></img> */}
        <img src={`${img}`} className='desc-image'></img>
      </div>
  );
}
export default QuestionDescribeImage;