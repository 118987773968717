import React, { useState, useEffect } from 'react';

const TimeSelector = ({ setTimingsForMockTest, json }) => {
  const [readingMinutes, setReadingMinutes] = useState('00');
  const [readingSeconds, setReadingSeconds] = useState('00');
  const [readingTime, setReadingTime] = useState('00:00:00');

  const [speakingMinutes, setSpeakingMinutes] = useState('00');
  const [speakingSeconds, setSpeakingSeconds] = useState('00');
  const [speakingTime, setSpeakingTime] = useState('00:00:00');

  const [listeningMinutes, setListeningMinutes] = useState('00');
  const [listeningSeconds, setListeningSeconds] = useState('00');
  const [listeningTime, setListeningTime] = useState('00:00:00');

  const [writingMinutes, setWritingMinutes] = useState('00');
  const [writingSeconds, setWritingSeconds] = useState('00');
  const [writingTime, setWritingTime] = useState('00:00:00');

  useEffect(() => {
    const newTime = `00:${readingMinutes}:${readingSeconds}`;
    setReadingTime(newTime);
    setTimingsForMockTest(prev => ({ ...prev, reading_time: newTime }));
  }, [readingMinutes, readingSeconds, setTimingsForMockTest]);

  useEffect(() => {
    const newTime = `00:${speakingMinutes}:${speakingSeconds}`;
    setSpeakingTime(newTime);
    setTimingsForMockTest(prev => ({ ...prev, speaking_time: newTime }));
  }, [speakingMinutes, speakingSeconds, setTimingsForMockTest]);

  useEffect(() => {
    const newTime = `00:${listeningMinutes}:${listeningSeconds}`;
    setListeningTime(newTime);
    setTimingsForMockTest(prev => ({ ...prev, listening_time: newTime }));
  }, [listeningMinutes, listeningSeconds, setTimingsForMockTest]);

  useEffect(() => {
    const newTime = `00:${writingMinutes}:${writingSeconds}`;
    setWritingTime(newTime);
    setTimingsForMockTest(prev => ({ ...prev, writing_time: newTime }));
  }, [writingMinutes, writingSeconds, setTimingsForMockTest]);

  const handleMinutesChange = (setMinutes) => (e) => {
    setMinutes(e.target.value.padStart(2, '0'));
  };

  const handleSecondsChange = (setSeconds) => (e) => {
    setSeconds(e.target.value.padStart(2, '0'));
  };

  return (
    <div className="container">
      <div className="row">
        {json.reading.total_question > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Reading Minutes:</label>
              <select value={readingMinutes} onChange={handleMinutesChange(setReadingMinutes)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Reading Seconds:</label>
              <select value={readingSeconds} onChange={handleSecondsChange(setReadingSeconds)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <strong>Reading Time: </strong>
              {readingTime}
            </div>
          </div>
        )}

        {json.speaking.total_question > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Speaking Minutes:</label>
              <select value={speakingMinutes} onChange={handleMinutesChange(setSpeakingMinutes)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Speaking Seconds:</label>
              <select value={speakingSeconds} onChange={handleSecondsChange(setSpeakingSeconds)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <strong>Speaking Time: </strong>
              {speakingTime}
            </div>
          </div>
        )}

        {json.listening.total_question > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Listening Minutes:</label>
              <select value={listeningMinutes} onChange={handleMinutesChange(setListeningMinutes)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Listening Seconds:</label>
              <select value={listeningSeconds} onChange={handleSecondsChange(setListeningSeconds)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <strong>Listening Time: </strong>
              {listeningTime}
            </div>
          </div>
        )}

        {/* {json.writing.total_question > 0 && (
          <div className="col-md-3">
            <div className="form-group">
              <label>Writing Minutes:</label>
              <select value={writingMinutes} onChange={handleMinutesChange(setWritingMinutes)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Writing Seconds:</label>
              <select value={writingSeconds} onChange={handleSecondsChange(setWritingSeconds)} className="form-control">
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <strong>Writing Time: </strong>
              {writingTime}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default TimeSelector;
