import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimePicker from "../../../../components/Form/TimePicker";
import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../../components";
import { useContext, useState, useEffect } from "react";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import EditTimesFiled from "../EditTimesFiled";
import SubmitButton from "../SubmitButton";

function PteListeningSelectMissingWord() {
  let { id } = useParams();
  let api = new AdminApi();
  let navigate = useNavigate();
  let formObject = new FormData();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState({
    ...quesData,
    test_type: "listening_l_mcs",
    exam_type: "pte",
    instructions: "You will hear a recording about fiction writing. At the end of the recording the lost word or group of words has been replaced by a beep. Select the correct option to complete the recording.",
    question_options: [
      {
        options: "",
        answer: true,
      },
    ],
    isMock: false,
    isPractice: false,
    isSection: false,
    isQuestion: false,
    isPrediction: false,
  });
  const [isFormValid, setIsFormValid] = useState('falsesssss');
  const checkFormValidity = () => {
    const {
      instructions,
      tags,
      answer,
      question_type,
      module_name,
      module,
      file,
      question_transcript,
      question,
      answer_time,
      question_begin_time,
    } = data;
    console.log("selectedOptions",selectedOptions.length > 0)
    let questionOptions = data.question_options.some(
      (option) => option.options == ""
    );
    return (
      instructions &&
      question_transcript 
      &&
      // data.question_options[0]?.answer?.length &&
      // data.question_options[0]?.answer[0] &&
      data?.sample_answer &&
      // (file ? file : data.question_content_audio) &&
      (file?.length ? file : (data.question_content_audio || data.question_content_video) ) &&
      tags &&
      module_name &&
      (data.isMock || data.isPrediction || data.isQuestion || data.isSection) &&
      module &&
      question_begin_time.length ===5
    );
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [data]);
  console.log("selecteAdd PTE Listening Select Missing Word QuestionsAdd PTE Listening Select Missing Word QuestionsdOptions",selectedOptions)
  // const isSubmitDisabled = () => {
  //   return (
  //     data.tags === "" ||
  //     data.module_name != "" ||
  //     data.module != "" ||
  //     data.question_type === "" ||
  //     data.instructions === "" ||
  //     data.question_content === "" ||
  //     data.question_options.some((option) => option.options === "")
  //   );
  // };
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) => item.options !== ""
    );
    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };

  const handleAdd = (parentIndex, childIndex, type) => {
    let handleAdd = [...data.question_options];
    handleAdd.push({
      options: "",
      answer: false,
    });
    setData({
      ...data,
      question_options: handleAdd,
    });
  };
  const handleRemove = (item, index) => {
    let handleAddRemove = [...data.question_options];
    handleAddRemove.splice(index, 1);
    setData({
      ...data,
      question_options: handleAddRemove,
    });
  };

  const handleChange = (e, i) => {
    e.preventDefault();
    let { name, value,checked } = e.target;
    if (i == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if (name == "options") {
      const updatedOptions = [...data.question_options];
      console.log("updatedOptions[i].answer", updatedOptions[i].answer);
      updatedOptions[i] = {
        ...updatedOptions[i],
        options: value,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "answer") {
      console.log("value",checked)
      const updatedOptions = data.question_options.map((option, index) => ({
        ...option,
        answer: i === index ? checked : false, // Only set the selected option to true
      }));
      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "file") {
      setData({ ...data, ["file"]: e.target.files[0] });
    } else {
      if (name !== "options" && name !== "answer") {
        if (name == "question_type") {
          const options = e.target.options;
          const selectedValues = [];
          if (options.length == 0) {
            setSelectedOptions([]);
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues);
          }
          setData({
            ...data,
            isMock: selectedValues.includes("isMock") ? true : false,
            isPractice: selectedValues.includes("isPractice") ? true : false,
            isSection: selectedValues.includes("isSection") ? true : false,
            isQuestion: selectedValues.includes("isQuestion") ? true : false,
            isPrediction: selectedValues.includes("isPrediction") ? true : false,
          });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isAudio = false;
    let isVideo = false;
    if(!data.question_content_audio && !data.question_content_video  ){    if (data?.file?.length > 0) {
      const fileType = data.file[0].type;
      if (fileType.startsWith("audio/")) {
        isAudio = true;
        formObject.append("question_content_audio", data.file[0]);
      } else if (fileType.startsWith("video/")) {
        isVideo = true;
        formObject.append("question_content_video", data.file[0]);
      } else {
        Swal.fire({
          icon: "error",
          title: "File must be an audio or video",
          text: "Please upload an audio or video file.",
        });
        return;
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "File is required",
        text: "Please upload an audio or video file.",
      });
      return;
    }}
    // if (!data.question_content_audio) {
    //   if (data?.file?.length > 0) {
    //     const fileType = data.file[0].type;
    //     if (fileType.startsWith("audio/")) {
    //       isAudio = true;
    //       formObject.append("question_content_audio", data.file[0]);
    //     } else {
    //       Swal.fire({
    //         icon: "error",
    //         title: "File must be an audio ",
    //         text: "Please upload an audio file.",
    //       });
    //       return;
    //     }
    //   } else {
    //     if(!data?.question_content_audio && !data.question_content_video ){
    //       Swal.fire({
    //         icon: "error",
    //         title: "File is required",
    //         text: "Please upload an audio file.",
    //       });
    //       return
    //   }
    //   }
    // }
    var data1 = removeBlankItems();
    for (const [key, value] of Object.entries(data1)) {
      if (key !== "file" && key !== "question_options" && key != "data.question_content_audio") {
        if(value!=null){
          formObject.append(key, value);
        }
      } else if (key === "question_options") {
        value.forEach((option, index) => {
          formObject.append(
            `question_options[${index}][options]`,
            option.options
          );
          formObject.append(
            `question_options[${index}][answer]`,
            option.answer
          );
        });
      }
    }
    setloader(!loader)
    await api
      .add_Question_Listening(formObject)
      .then((res) => {
       if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          }); navigate("/material-management/view-questions")
          ;
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
            // text: "Please upload an audio or video file.",
          });
        }
        setloader(!loader)
      })
      .catch((err) => {
        setloader(!loader)
        console.log("err", err);
      });
  };

  console.log("---", data);
  useEffect(() => {
    if (id) {
      getQuesById();
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Listening Select Missing Word Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                  <TestTypeSlectBox
                    grabNew={(e) => { setSelectedOptions(e) }}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {
                      setSelectedOptions(e); setData({
                        ...data,
                        isMock: e.includes("isMock") ? true : false,
                        isSection: e.includes("isSection") ? true : false,
                        isQuestion: e.includes("isQuestion") ? true : false,
                        isPrediction: e.includes("isPrediction") ? true : false,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                  
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    {/* <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio" className="ms-3 fw-bold">Add Audio</Form.Label>
                        <div className="form-control-wrap">
                          {data.question_content_audio ?
                            (<>
                              <div className="d-flex gap-2">
                                <audio src={data?.question_content_audio} controls></audio>
                                <span className="d-flex align-items-center"><button className="btn btn-danger py-1" onClick={() => setData({ ...data, question_content_audio: "" })}>Delete</button></span>
                              </div>

                            </>)
                            :
                            <FileUpload
                            filetype="audio"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles, question_content_audio: "" });
                              }}
                            />
                          }
                        </div>
                      </Form.Group>
                    </Col> */}
                       <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="addaudio">
                          Add Audio/Video
                        </Form.Label>
                        <div className="form-control-wrap">
                        {data.question_content_audio ||
                          data.question_content_video ? (
                            <>
                              <div className="d-flex gap-2">
                                {( data.question_content_video !== "null"  && (
                                  <video
                                    src={data?.question_content_video}
                                    width="200"
                                    height="155"
                                    controls={false}
                                  />
                                ))}{
                                  (data.question_content_audio && (
                                    <audio
                                      src={data?.question_content_audio}
                                      controls
                                    />
                                  ))}
                                <span className="d-flex align-items-center">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() =>
                                      setData({
                                        ...data,
                                        question_content_video: "",
                                        question_content_audio: "",
                                      })
                                    }
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            </>
                          ) : (
                            <FileUpload
                            filetype="audio_video"
                              iconName="upload"
                              name="file"
                              onChange={(updatedFiles) => {
                                setData({ ...data, file: updatedFiles });
                              }}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="9">
                      <Form.Group className="form-group">
                        <Form.Label className="ms-3 fw-bold">Add Question (#)</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            type="text"
                            id="addtag"
                            placeholder="Add Question"
                            name="question"
                            value={data?.question}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <Form.Group className="form-group my-2">
                    <Row className="g-gs">
                      <Col md="10">
                        <Form.Label htmlFor="multiradio" className="ms-3 fw-bold">
                          Add Options
                        </Form.Label>
                      </Col>
                      <Col md="2">
                        <Form.Label htmlFor="multiradio">
                          Add Correct Answer
                        </Form.Label>
                      </Col>
                    </Row>
                    {data?.question_options.length > 0 &&
                      data?.question_options.map((item, i) => {
                        return (
                          <>
                            <div className="d-flex gap-1 gap-md-3 align-items-center my-2">
                              <Form.Group className="form-group w-75">
                                <div className="form-control-wrap">
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Options"
                                    id="option-1"
                                    name="options"
                                    value={item.options}
                                    onChange={(e) => handleChange(e, i)}
                                  ></Form.Control>
                                </div>
                              </Form.Group>
                              <div>
                                {data?.question_options.length - 1 == i ? (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                    onClick={handleAdd}
                                  >
                                    <Icon
                                      name="plus-circle"
                                      variant="primary"
                                    ></Icon>
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                  ></Button>
                                )}
                                {data?.question_options.length > 1 && (
                                  <Button
                                    className="btn-icon"
                                    variant="outline-transparent"
                                    onClick={(e) => handleRemove(e, i)}
                                  >
                                    <Icon
                                      name="minus-circle"
                                      variant="danger"
                                    ></Icon>
                                  </Button>
                                )}
                              </div>{" "}
                              <div className="border rounded px-2 py-1">
                                <Form.Check
                                  type="radio"
                                  value={((item?.answer == 'false' || item?.answer == false) && true )|| ((item?.answer == 'true' || item?.answer == true)&& false  )}
                                  onChange={(e) => handleChange(e, i)}
                                  checked={((item?.answer == 'true' || item?.answer == true ) && true )|| ((item?.answer == 'false' || item?.answer == false)&& false  )}
                                  name="answer"
                                  id={`option-${i}`}
                                />
                              </div>
                              {/* <div className="border rounded px-2 py-1">
                                <Form.Check
                                  value={!item?.answer}
                                  onChange={(e) => handleChange(e, i)}
                                  checked={item?.answer}
                                  name="answer"
                                  type="checkbox"
                                  id="option-1"
                                />
                              </div> */}
                            </div>
                          </>
                        );
                      })}
                  </Form.Group>
                </Col>
                <Col lg="9">
                  <TranscriptExplanation transcript={true} sample_answer={true} setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        placeholder="#Tag"
                        name="tags"
                        value={data.tags}
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between my-5">
                  <PrevNextButton id={id} />
                  <SubmitButton loader={loader} onClick={handleSubmit} disabled={!isFormValid}/>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PteListeningSelectMissingWord;
