import { useContext, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import Block from "../../components/Block/Block";
import FileUpload from "../../components/Form/FileUpload";
import Layout from "../../layout/default";
import AnnoucementApi from "../../api/auth/annoucement";
import FileUploadAnnoucement from "../../components/Form/FileUploadAnnoucement";
import Accept from "../../components/Form/FileUploadAnnoucement";
import FileUploadImageExtension from "../../components/Form/FileUploadImageExtension";
import FileUploadImageExtensionNew from "../../components/Form/FileUploadImageExtensionNew";
import FileUploadImageExtensionOld from "../../components/Form/FileUploadImageExtensionOld";
import Context from "../../context";

function AddAnnouncement() {
  let formdata = new FormData();
  const [loader, setloader] = useState(false);
  const [data, setdata] = useState({
    title: "",
    priority: "",
    attachment: "",
    isActive: true,
  });
  const [error, setError] = useState({});

  let {  userData} = useContext(Context);
  userData = JSON.parse(userData);

  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data.title);
    if (!data?.title) {
      newErrors.title = "Title required";
    }
    if (!data?.priority) {
      newErrors.priority = "Priority required";
    }
    if (!data?.attachment) {
      newErrors.attachment = "Banner Image required , Image must be JPG/JPEG or PNG (Aspect ratio 2:1)";
    }
    setError((prev) => ({ ...prev, newErrors }));

    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const handleChange = (e, type) => {
    console.log("inside handler");
    let { value, name, checked } = e.target;
    console.log("name,vlaue :>> ", name, "value", value, checked, type);

    setdata({ ...data, [name]: value });
    if (type != undefined && type == "check") {
      console.log("checkedddddddd :>> ");
      setdata({ ...data, isActive: checked });
    }
  };

  let annoucementApi = new AnnoucementApi();
  let api = new AuthApi();
  let navigate = useNavigate();

  const addAnnoucementHandler = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setloader(true);
      console.log('data32 :>> ', data);

      for (const [key, value] of Object.entries(data)) {
        formdata.append([key], value);
      }
      formdata.append("institue_id",userData?._id)

      console.log("formdata12", formdata);
      await annoucementApi
        .create_announcement(formdata)
        .then((res) => {
          setloader(false);
          if (res?.status) {
            Swal.fire({
              icon: "success",
              text: res.message,
            });
            navigate("/view-announcement");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: res.msg,
            });
          }
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("Form submitted:");
    } else {
      console.log("Form validation failed");
    }
  };
  console.log("error :>> ", error);
  console.log('data?.attachment :>> ', data?.attachment);
  return (
    <Layout title="Add Student" content="container">
      <h1 className="mb-5">Add Announcement</h1>
      <Block>
        <Card className="h-100">
          <Card.Body>
            <Row className="g-gs">
              <Col md="9">
                <Row className="g-gs">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Title</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="title"
                          name="title"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter a title here..."
                          isInvalid={!!error?.newErrors?.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {error?.newErrors?.title}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label>Banner Image</Form.Label>
                      <div className="form-control-wrap ">
                        <FileUploadImageExtensionOld
                         
                          iconName="files"
                          name="attachment"
                          filetype="image"
                         
                          maxFiles={1}
                          ImageData={data}
                          setdata={(data)=>setdata(data)}
                          //  maxSize={4194304} //--> if use this line then comment the fileType props
                          //  errorText="File size is too large, please upload file size within (12MB)"

                          onChange={(uploadDocData) =>
                            setdata({ ...data, attachment: uploadDocData[0] })
                          }
                        />
                     
                        <div className="invalid-formupload-feedback">
                          {error?.newErrors?.attachment}
                        </div>
                    <Button onClick={() => window.open('https://www.canva.com/templates/?query=banner-landscape-72x36', '_blank')
                    } variant="primary" size="sm" className="mt-2 add-btn " style={{marginLeft:"92px"}}>  Design Free banners </Button>
                      </div>
                    </Form.Group>

                    {/* {
                      Object.keys(error).length == 0 &&

                      <div className="mt-2">
                    <small>
                    Image must be JPG/JPEG or PNG (Aspect ratio 2:1)
                    </small>
                    </div>
                    } */}
                    {/* <Form.Group className="form-group">
                        <Form.Label>Limited Size Dropzone "4MB"</Form.Label>
                        <div className="form-control-wrap">
                          <FileUpload iconName="files" maxSize={4194304} errorText="File size is too large, please upload file size within (4MB)"/>
                        </div>
                    </Form.Group> */}
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Priority </Form.Label>
                     
                     
                      <div className="form-control-wrap">

                      <Form.Select
                      id="priority"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="priority"
                      isInvalid={!!error?.newErrors?.priority}
                    >
                        {/* <Form.Control
                          type="number"
                          name="priority"
                          id="priority"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter a Priority here..."
                          isInvalid={!!error?.newErrors?.priority}
                        /> */}

                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="2">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {error?.newErrors?.priority}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="redirectUrl">redirectUrl</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="redirectUrl"
                          name="redirectUrl"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter a Redirect Url"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                    <Form.Group className="form-group">
                      <div className="mt-4">
                        <div className="form-check form-switch">
                          <span className="text-light">Is Active</span>
                          <input
                            className="form-check-input ms-3"
                            type="checkbox"
                            role="switch"
                            id="directMessage"
                            name="active"
                            defaultChecked
                            
                            onChange={(e) => handleChange(e, "check")}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="3"></Col>
              <Col md="12" className="my-5">
                <Button onClick={addAnnoucementHandler}>Save</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddAnnouncement;
