// import { useState } from "react";
// import { Button, Col, Form, Modal, Row } from "react-bootstrap";
// import AuthApi from "../../../api/auth";
// import { FileUpload, Image } from "../../../components";


// const GetAllAnnoucementsModal = ({
//   singleAnnoucement,

//   singleFollowup,


//   setShowModal,
//   showModal,
//   annoucements,
//   announcementIndex,
//   setCurrentAnnoucementIndex,
//   currentAnnoucementIndex,
//   mapIndex
  
// }) => {
//   console.log(currentAnnoucementIndex, "announcementIndex",annoucements.length);
//   // setCurrentAnnoucementIndex(mapIndex)

//  ;
//   const handleClose = () => {
//   // setShowModal(false)
//   // announcementIndex =announcementIndex-1
//   setCurrentAnnoucementIndex((prev)=>prev+1)

//   setShowModal(false)
//   };

 

//   console.log(currentAnnoucementIndex, "currentAnnoucementIndex",mapIndex);
//   return (
//     <>
//       <Modal
//         size="lg"
//         show={true}
//         onHide={handleClose}
//         backdrop="static"
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Announcement</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form action="#">
//           <Row className="g-gs ">
//               <Col md="12">
//                 <Form.Group className="form-group my-3">
//                   <Form.Label htmlFor="title">Title</Form.Label>
//                   <div className="form-control-wrap">
//                     <Form.Control
//                       required
//                       type="text"
//                       id="title"
//                       placeholder="Title"
//                       name="title"
//                       value={ singleAnnoucement?.title}
                   
//                     />
                   
//                   </div>
//                 </Form.Group>
//                 <Form.Group className="form-group my-3">
//                   <Form.Label htmlFor="priority">Priority</Form.Label>
//                   <div className="form-control-wrap">
//                     <Form.Control
//                       required
//                       type="text"
//                       id="priority"
//                       placeholder="Title"
//                       name="priority"
//                       value={ singleAnnoucement?.priority}
                     
//                     />
                   
//                   </div>
//                 </Form.Group>

//                 <div className="d-flex justify-content-start gap-3">
//                   {singleAnnoucement?.image ? (
//                     <div
//                       className="p-2 border d-flex align-items-center justify-content-center"
//                       style={{ width: "160px", height: "160px" }}
//                     >
//                       <Image src={singleAnnoucement?.image}></Image>
//                     </div>
//                   ) : (
//                     <div
//                       className={`${
//                         singleAnnoucement?.image != "" &&
//                         "p-2 border d-flex align-items-center justify-content-center",
//                         singleAnnoucement?.image != null &&
//                         "p-2 border d-flex align-items-center justify-content-center"
//                       }`}
//                       // style={{ width: "160px", height: "160px" }}
//                       style={{
//                         width: `${
//                           singleAnnoucement?.image == "" || singleAnnoucement?.image == null  ? "0px" : "160px"
//                         }`,
//                         height: `${
//                           singleAnnoucement?.image == "" || singleAnnoucement?.image == null   ? "0px" : "160px"
//                         }`,
//                       }}
//                     >
//                       <Image src={singleAnnoucement?.image}></Image>
//                     </div>
//                   )}


//                 </div>
//               </Col>
//             </Row>
            
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
          
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };
// export default GetAllAnnoucementsModal;


import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AuthApi from "../../../api/auth";
import { FileUpload, Image } from "../../../components";
import { Link } from "react-router-dom";


const GetAllAnnoucementsModal = ({
  singleAnnoucement,

  singleFollowup,


  setShowModal,
  showModal,
  annoucements,
  announcementIndex,
  setCurrentAnnoucementIndex,
  currentAnnoucementIndex,
  mapIndex
  
}) => {
  console.log(currentAnnoucementIndex, "announcementIndex",annoucements.length);
  console.log("ANNOUNCEMENT",singleAnnoucement);
  // setCurrentAnnoucementIndex(mapIndex)

 ;
  const handleClose = () => {
  // setShowModal(false)
  // announcementIndex =announcementIndex-1
  setCurrentAnnoucementIndex((prev)=>prev+1)

  setShowModal(false)
  };

 

  console.log(currentAnnoucementIndex, "currentAnnoucementIndex",mapIndex);
  return (
    <>
      <Modal
        size="lg"
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='announcement-popup'
      >
        <Modal.Header closeButton>
          <Modal.Title>{ singleAnnoucement?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Link target="_blank" style={{width:"-webkit-fill-available"}} to={singleAnnoucement?.redirectUrl}><Image src={singleAnnoucement?.image}></Image></Link>
        {/* <Image href={singleAnnoucement?.redirectUrl} src={singleAnnoucement?.image}></Image> */}
        </Modal.Body>
       
      </Modal>
    </>
  );
};
export default GetAllAnnoucementsModal;