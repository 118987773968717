import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import AuthApi from "../../api/auth";
import { Icon, Select } from "../../components";
import DatePickerOnChange from "../../components/Form/DatePickerOnChange";
import { DropDown } from "../../components/Form/DropDown";
import InstBranchesApi from "../../api/branches/branches";
import Context from "../../context";
import PhoneFlag from "../../components/Form/PhoneFlag";
import CountdownTimer from "../../components/Form/CountdownTimer";

const AddEnquiryModal = ({ teachers, setShow, show, isEdit, setIsEdit, get_enquiriesArr }) => {
  let navigate = useNavigate();
  console.log(teachers, "teachers");
  console.log(setShow, 'setShow123')
  let api = new AuthApi();

  const [state, setState] = useState({
    data: {
      name: "",
      email: "",
      mobile: "",
      enquiry: "",
      gender: "",
    },
    errors: {},
  });
  let DropDownWidth = { width: "100%" };
  const handleClose = () => {
    if (setShow != undefined) {

      setShow(false);
    }
    if (setIsEdit != undefined) {
      setIsEdit(false)
    }
    setState({
      data: {},
      errors: {},
    });
  };

  let { userData, token } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData, "userdata123")

  const [countryNumber, setCountryNumber] = useState('91');




  let instBranchesApi = new InstBranchesApi();
  const [branches, setBranches] = useState([]);


  const get_branch_by_inst_id = async (instId) => {
    console.log("instid", instId, token);
    await instBranchesApi
      .get_branch_by_inst_id(instId)
      .then((res) => {
        if (res?.data?.length > 0) {
          console.log(res, "aaaa");
          setBranches(res?.data.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    if (userData?._id && token) {

      get_branch_by_inst_id(userData?._id)

    }
  }, []);

  const Instbranches = branches?.map((item) => ({ name: item?.name.toLowerCase(), id: item?._id }));
  // const Instbranches= branches?.map((item) => {return({name:item?.name.toLowerCase(),id:item?._id})});

  console.log('Instbranches :>> ', Instbranches);
  const { data, errors } = state;
  console.log("state=", state);
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("name, value", name, value);


    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        institue_id:userData?._id,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...prev.errors,
        [e.target.name]: "",
      },
    }));
    if (name == "branchName") {
      const selectedOption = e?.target?.selectedOptions?.[0];
      const selectedId = selectedOption.id;
      console.log(name, value, selectedId, "naem...");

      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          branch_id: selectedId,
        },

      }));

    }


    if (name == "mobile") {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          country_code : countryNumber,
          ["type"] : "enquiry_form",
          // institute_id:userData?._id
        }

      }));
    }
  };
  const validateForm = () => {
    const newErrors = {};
    console.log("data.name=", data);
    if (!data?.name?.trim()) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z0-9 ]{3,16}$/.test(data.name)) {
      newErrors.name =
        "Name should be 3-16 characters and shouldn't include any special character!";
    }
    if (!data?.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
        data.email
      )
    ) {
      newErrors.email = "Email must be valid address eg: mailto:me@mydomain.com";
    }

    // if (data.email &&
    //   !/^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(
    //     data.email
    //   )
    // ) {
    //   newErrors.email = "Email must be valid address eg: mailto:me@mydomain.com";
    // }

    if (!data?.mobile?.trim()) {
      newErrors.mobile = "Phone is required";
    } else if (data.mobile.length !== 10) {
      newErrors.mobile = "Phone number must be 10 digits";
    }
    // if (!data?.enquiry?.trim()) {
    //   newErrors.enquiry = "Enquiry is required";
    // }
    // if (!data?.mode) {
    //   newErrors.mode = "Mode is required";
    // }
    if (!data?.program) {
      newErrors.program = "Program is required";
    }

    // if (!data?.otp) {
    //   newErrors.otp = "Otp is required";
    // }

    // if (!data?.branchName) {
    //   newErrors.branchName = "BranchName is required";
    // }

    // if (!data?.gender?.trim()) {
    //   newErrors.gender = "Gender is required";
    // }
    setState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));
    console.log(newErrors, "newErrors");
    return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
  };
  const [VisibleDiv, setVisibleDiv] = useState(false);
  const [timer,setTimer] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState(null);
  const [optError, setOptError] = useState("")
  async function generateRandomNumber() {
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .send_mobile_otp({
        mobile: data?.mobile,
        request_id: randomNumber,
        name: data.name,
        email:data.email,
        institute_id:userData?._id,
        type : "enquiry_form",
        country_code:countryNumber
      })
      .then((res) => {
        console.log("otpres", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });
          setOtp(randomNumber);
          setVisibleDiv(true);
          setTimer(false)
      
        }
           else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.message,
          });
          if(res.message == "OTP attempts exceeded. Try Again After 3 minutes."){

            setTimer(true)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [countStep, setCountStep] = useState(1);
  const [nextDisable, setDisable] = useState(true);

  const verifyOtp = async () => {
    await api
      .validate_mobile_otp({ request_id: otp, otp: data.otp, name: data.name })
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: res.msg,
          });
          setCountStep(countStep + 1);
          setDisable(!nextDisable);
          setVerified(true);
          setVisibleDiv(false)
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    console.log("SUMITTTTTT");
    e.preventDefault();
    const isValid = validateForm();
    console.log("isValid=", isValid);
    if (isValid) {
      console.log(data, "data##");
      await api
        .add_enquiry(data)
        .then((res) => {
          console.log("res##", res);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Good job!",
              text: res.message,
            });
            setShow(false);
            get_enquiriesArr()
            navigate("/lead-management/enquiry-list");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err##");
        });
      // Perform the form submission logic here
      console.log("Form submitted:", data);
    } else {
      console.log("Form validation failed");
    }
  };

  console.log('data?.mobile :>> ', data, errors);
  return (
    <>

      <Modal
        size="lg"
        show={show || isEdit}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-gs ">
              <Col md="6">
                <Form.Group className="form-group my-3 required">
                  <Form.Label htmlFor="title" className="control-label">Student Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      required
                      type="text"
                      id="name"
                      placeholder="Student Name"
                      name="name"
                      value={data.name}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <div className="d-flex gap-1">
                  <div className="w-40">
                    <Form.Group className="form-group required ">
                      <Form.Label htmlFor="tag" className="control-label">Phone Number</Form.Label>
                      <PhoneFlag setCountryNumber={setCountryNumber}  />
                    </Form.Group>
                  </div>
                  <div className="w-60">
                    <Form.Group className="form-group mt-3 ">
                      <Form.Label htmlFor="tag">{" "}</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="tel"
                          maxLength={10}
                          disabled={verified }
                          id="phone"
                          name="mobile"
                          placeholder="Phone Number"
                          // value={data.phone}
                          value={data.mobile?.length < 11 ? data.mobile : ""}
                          onChange={(e) => handleChange(e)}
                          isInvalid={!!errors.mobile}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobile}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {
                  userData?.enquiry_otp_deduction && <>

                    <div className="d-flex gap-1">
                      <div className="w-60"></div>
                      <div className="w-40">
                      {
  verified ?
                                <Button
                                variant="outline-primary"
                                className={`border ${verified ? `border-success` :`border-primary`} border-2 fw-bold mt-2 w-100`}
                                disabled={verified }
                                onClick={() => {
                                    if (
                                      data?.mobile?.length == 10 &&
                                      data?.email?.length &&
                                      data?.name
                                    ) {
                                      generateRandomNumber();
                                      setOptError("");
                                    } else {
                                      setOptError(
                                        "Please enter name,email and valid mobile number to send otp"
                                      );
                                    }
                                  }}
                                  >
                                  
                         <span className="text-success"> Verified</span> 
                        
                                </Button>:
                                <>
<Button
variant="outline-primary"
className={`border ${verified ? `border-success` :`border-primary`} border-2 fw-bold mt-2 w-100`}
disabled={ timer}
onClick={() => {
  if (
    data?.mobile?.length == 10 &&
    data?.email?.length &&
    data?.name
  ) {
    // setLoading(true)
    generateRandomNumber();
    setOptError("");
  } else {
    setOptError(
      "Please enter name,email and valid mobile number to send otp"
    );
  }
}}
>
{
 VisibleDiv ? "Resend OTP" : "Send OTP"
}
</Button>

  {timer ? <CountdownTimer timer={180} setTimer={setTimer}/> :""}


</>

                            }
                      </div>
                      {
                        optError != "" &&
                        <div className="invalid-formupload-feedback mt-1">
                          {optError}
                        </div>
                      }
                    </div>

                    <div className="invalid-formupload-feedback mt-1">
                      {errors.otp}
                    </div>
                  </>
                }
                <div className="d-flex gap-1">
                  <div className="w-60" style={{ display: VisibleDiv ? "block" : "none" }}>
                    <Form.Group className="form-group">
                      <div className="form-control-wrap">
                        <Form.Control
                          type="number"
                          name="otp"
                          value={data?.otp?.length < 7 && data.otp}
                          onChange={(e) => handleChange(e)}
                          id="exampleFormControlInputText1"
                          placeholder="Enter OTP"
                          isInvalid={!!errors.otp}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.otp}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="w-40" style={{ display: VisibleDiv ? "block" : "none" }}>
                    <Button
                      variant="outline-primary"
                      className="border border-primary border-2 fw-bold w-100"
                      onClick={verifyOtp}
                    >
                      {verified ?  "Verified":"Verify OTP"}

                    </Button>
                  </div>
                </div>
                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="mode">Select Mode</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="mode"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="mode"
                      isInvalid={!!errors.mode}
                    >
                      <option value="">Select</option>
                      <option value="Telephonic">Telephonic</option>
                      <option value="Walk-in">Walk-in</option>
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {errors.mode}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="gender">Select Gender </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      required
                      id="gender"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="gender"
                      isInvalid={!!errors.gender}
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>

                    {/* <Select
                                  name="gender"
                                  value={data?.validity_days}
                                  onChange={(e)=>handleChange(e)}
                                  removeItemButton
                                >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select> */}
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <DropDown
                  className="w-25 mb-3"
                  data={teachers}
                  defaultSelectedOption={"Select"}
                  text="Team Member Name"
                  name="teamMember"
                  onChange={handleChange}
                  width={DropDownWidth}
                />


                {/* <DropDown
                  className="w-25 mt-3"
                  data={Instbranches?.name}
                  defaultSelectedOption={"Select"}
                  text="Branch"
                  name="branchName"
                  onChange={handleChange}
                  width={DropDownWidth}
                /> */}

                <Form.Group className="form-group mt-3">
                  <Form.Label>Branch</Form.Label>
                  <div className="form-control-wrap me-2" style={DropDownWidth}>
                    <Form.Select
                      removeItemButton

                      name={"branchName"}
                      onChange={handleChange}
                      isInvalid={!!errors.branchName}
                    >


                      <option value="">{"Select"}</option>

                      {Instbranches?.length > 0 &&
                        Instbranches.map((item) => {
                          ;
                          return (
                            <>
                              <option value={item?.name} id={item?.id} key={item?.id}>
                                {item?.name}
                              </option>
                            </>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.branchName}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group my-3 required">
                  <Form.Label htmlFor="email" className="control-label">Email Id</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      type="email"
                      id="emailid"
                      name="email"
                      placeholder="Email Id"
                      value={data.email}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3 required">
                  <Form.Label htmlFor="program" className="control-label">Select Program</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="program"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="program"
                      isInvalid={!!errors.program}
                    >
                      <option value="">Select</option>
                      <option value="Pte">PTE</option>
                      <option value="Pte core">PTE CORE</option>
                      <option value="Ilets">IELTS</option>
                      <option value="Cd Ielts">CD IELTS</option>
                      <option value="Toefl">TOEFL</option>
                      <option value="Cale">CALE</option>
                      <option value="Celpip">CELPIP</option>
                      <option value="Grammer">GRAMMER</option>
                      <option value="Spoken English">SPOKEN ENLGISH</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.program}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="reference">
                    Select Reference Medium
                  </Form.Label>
                  <div className="form-control-wrap">
                    <Form.Select
                      id="reference"
                      aria-label="Default select example"
                      onChange={(e) => handleChange(e)}
                      name="reference"
                    >
                      <option value="">Select</option>
                      <option value="Friend Reference">Friend Reference</option>
                      <option value="Direct Phone Call">
                        Direct Phone Call
                      </option>
                      <option value="Direct Walk In">Direct Walk In</option>
                      <option value="Existing Student Reference">
                        Existing Student Reference
                      </option>
                      <option value="Team Memeber Reference">
                        Team Memeber Reference
                      </option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Newspaper">Newspaper</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Google">Google</option>
                      <option value="Webstie">Webstie</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Next FollowUp Date</Form.Label>
                  <div className="form-control-wrap">
                    <DatePickerOnChange
                      onChange={(e) => handleChange(e)}
                      name={"nextFollowUpDate"}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group my-3">
                  <Form.Label htmlFor="tag">Comment</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      as="textarea"
                      placeholder="Enquiry"
                      name="enquiry"
                      id="enquiry"
                      rows="5"
                      value={data.enquiry}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!errors.enquiry}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.enquiry}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col md="12" className="d-flex justify-content-center"></Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Equiry
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddEnquiryModal;
