import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
function DescImgComp() {
  return (
    <>
      <div className="desc-image">
        <img src='/images/des-img.png' className='w-100'></img>
      </div>
    </>
  );
}
export default DescImgComp;