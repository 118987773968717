import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimePicker from "../../../../components/Form/TimePicker";
import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../../../components";
import { useContext, useState ,useEffect} from "react";
import Question_Context from "../../../../context/questionContext";
import AdminApi from "../../../../api/admin";
import Swal from "sweetalert2/src/sweetalert2";
import PrevNextButton from "../PrevNextButton";
import TestTypeSlectBox from "../../SelectBox/TestTypeSlectBox";
import TranscriptExplanation from "../TranscriptExplanation";
import { EditAttributes } from "@mui/icons-material";
import EditTimesFiled from "../EditTimesFiled";

function PterReadingCorrectOrder() {
  let { id } = useParams();
  let navigate = useNavigate();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [data, setData] = useState({
    ...quesData,
    exam_type: "pte",
    test_type:"Re-Orders Paragraphs",
    instructions:"The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.",
    question_options: [
      {
        options: "",
        answer: "",
      },
    ],
    isMock:false,
    isPractice:false,
    isSection:false,
    isQuestion:false,
    isPrediction:false
  });
  const [loader, setloader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const[error,setError]= useState({
    correct_answer_order:false
  })
  let {correct_answer_order} = error
  const removeBlankItems = () => {
    const updatedOptions = data.question_options.filter(
      (item) => item.options !== "" ||  item.answer !== "" 
    );
    let removeBlank = {
      ...data,
      question_options: updatedOptions,
    };
    return removeBlank;
  };

  const handleAdd = () => {
    let handleAdd = [...data.question_options];
    handleAdd.push({
      options: "",
      answer: "",
    });
    setData({
      ...data,
      question_options: handleAdd,
    });
  };
  const handleRemove = (item, index) => {
    let handleAddRemove = [...data.question_options];
    handleAddRemove.splice(index, 1);
    setData({
      ...data,
      question_options: handleAddRemove,
    });
  };

  const handleChange = (e, i) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (i == undefined && name == "answer") {
      setData({ ...data, [name]: value });
    return;
  }
    if (name == "options") {
      const updatedOptions = [...data.question_options];
      console.log("updatedOptions[i].answer", updatedOptions[i].answer);
      updatedOptions[i] = {
        ...updatedOptions[i],
        options: value,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else if (name == "answer") {
      const updatedOptions = [...data.question_options];
      updatedOptions[i] = {
        ...updatedOptions[i],
        answer: value ,
      };

      setData({
        ...data,
        question_options: updatedOptions,
      });
    } else {
      if (name !== "options" && name !== "answer") {
        if(name == "question_type"){
          const options = e.target.options;
          const selectedValues = [];
          if(options.length == 0){
            setSelectedOptions([])
          }
          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selectedValues.push(options[i].value);
            }
            setSelectedOptions(selectedValues)
          }
          setData({ ...data, isMock:selectedValues.includes("isMock") ?true:false
            ,isPractice:(selectedValues.includes("isPractice")?true:false)
            ,isSection:(selectedValues.includes("isSection")?true:false)
            ,isQuestion:(selectedValues.includes("isQuestion")?true:false)
            ,isPrediction:selectedValues.includes("isPrediction")?true:false,
          });
        }else{
        setData({ ...data, [name]: value });
         } }
    }
  };

  let api = new AdminApi();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(!loader)
    await api
      .add_Question(removeBlankItems())
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res.message,
          });
          navigate("/material-management/view-questions");
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
        }
        setloader(!loader)
      })
      .catch((err) => {
        setloader(!loader)
        console.log("err", err);
      });
  };
 let answerOrder = data.question_options.every((item, index) => {
    const answerInRange = parseInt(item.answer) >= 1 && parseInt(item.answer) <= data.question_options?.length;
    const uniqueAnswer = data.question_options.findIndex((otherItem, otherIndex) => index !== otherIndex && item.answer === otherItem.answer) === -1;
    return answerInRange && uniqueAnswer;
})

useEffect(()=>{
if(answerOrder){
  setError({...error,correct_answer_order:true})
}else{
  setError({...error,correct_answer_order:false})
}
},[answerOrder])
  const isSubmitDisabled = () => {
    return (
      data.module == ""||
      data.module_name == ""||
      data?.answer_time?.length!==5  ||
      !(data.isMock || data.isPrediction || data.isQuestion || data.isSection) ||
      data.tags === "" ||
      data.instructions === "" ||
      data.question_options.some(
        (option) => option.options === "" || option.answer === ""
      )
      || 
      answerOrder == false
      
    );
  };
  useEffect(() => {
    if (id) {
      getQuesById();
      setData({ ...data, _id: id });
    }
  }, [id]);

  const getQuesById = async () => {
    await api
      .get_qst_by_id(id)
      .then((res) => {
        if (res.status) {
          setData({ ...res.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log("data", data);
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              Add PTE Reading Correct Order Questions
            </Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              <Row className="g-gs">
                <Col md="9">
                  <h5 className="mb-3">Add Questions</h5>
                </Col>
                <Col md="3">
                   <TestTypeSlectBox
                      grabNew={(e) => {setSelectedOptions(e)}}
                    data={data}
                    handleChange={handleChange}
                    selectedOptions={selectedOptions}
                    updateState={(e) => {setSelectedOptions(e);  setData({
                      ...data,
                      isMock: e.includes("isMock") ? true : false,
                      isSection: e.includes("isSection") ? true : false,
                      isQuestion: e.includes("isQuestion") ? true : false,
                      isPrediction: e.includes("isPrediction") ? true : false,
                    });}}
                  />
                </Col>
              </Row>
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                  {id &&  <EditTimesFiled data={data} onDataChange={(name,val)=>setData({...data,[name]:val})}/>}
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="instruction" className="ms-3 fw-bold">
                          Add Instruction
                        </Form.Label>
                        <div className="form-control-wrap">
                           <Form.Control
                            as="textarea"
                            name="instructions"
                            value={data.instructions}
                            onChange={(e) => handleChange(e)}
                            placeholder="Add Instructions"
                            id="instruction"
                            rows="3"
                          ></Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <Form.Group className="form-group my-2">
                    <Row className="g-gs">
                      <Col md="10">
                        <Form.Label htmlFor="multiradio" className="ms-3 fw-bold">
                          Add Text Box
                        </Form.Label>
                      </Col>
                      <Col md="2">
                        <Form.Label htmlFor="multiradio">
                          Add Correct Answer
                        </Form.Label>
                      </Col>
                    </Row>
                    {data?.question_options.length > 0 &&
                      data?.question_options.map((item, i) => {
                        return (
                          <>
                            <div className="d-flex gap-1 gap-md-3 align-items-center my-2">
                              <Form.Group className="form-group w-75">
                                <div className="form-control-wrap">
                                  <Form.Control
                                  key={i}
                                    type="text"
                                    placeholder="Enter Options"
                                    id="option-1"
                                    name="options"
                                    value={item?.options?.replace(/\s+/g, ' ').trim()}
                                    onChange={(e) => handleChange(e, i)}
                                  ></Form.Control>
                                </div>
                              </Form.Group>
                              <div>
                              {data?.question_options.length-1 == i ?      <Button
                                  className="btn-icon"
                                  variant="outline-transparent"
                                  onClick={handleAdd}
                                >
                                  <Icon
                                    name="plus-circle"
                                    variant="primary"
                                  ></Icon>
                                </Button>:
                               <Button
                                  className="btn-icon"
                                  variant="outline-transparent"
                                >
                              
                                </Button>
                      }
                      { data?.question_options.length > 1 &&               <Button
                                  className="btn-icon"
                                  variant="outline-transparent"
                                  onClick={(e) => handleRemove(e, i)}
                                >
                                  <Icon
                                    name="minus-circle"
                                    variant="danger"
                                  ></Icon>
                                </Button>}
                              </div>
                              <Form.Control
                              value={item?.answer}
                                type="number"
                                min="1"
                                max="4"
                                name="answer"
                                onChange={(e) => handleChange(e, i)}
                                id="correctorder-1"
                                style={{ width: "70px" }}
                              />
                            </div>
                          </>
                        );
                      })}
                    {!correct_answer_order &&  <h6 className="text-danger">Please Select Correct Answer Pattern </h6>}
                  </Form.Group>
                </Col>
                <Col lg="9">
                      <TranscriptExplanation setState={(name) => setData({ ...data, [name]: "" })} data={data} onChange={(e) => handleChange(e)} />
                    </Col>
                <Col lg="9">
                  <Form.Group className="form-group">
                    <Form.Label className="ms-3 fw-bold">Add Tag (#)</Form.Label>
                    <div className="form-control-wrap">
                      <Form.Control
                        type="text"
                        id="addtag"
                        placeholder="#Tag"
                        name="tags"
                        value={data.tags}
                        onChange={(e) => {
                          var removeHash = e.target.value.replaceAll("#", "");
                          removeHash = "#" + removeHash;
                          setData({ ...data, tags: removeHash });
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12" className="d-flex justify-content-between my-5">
            <PrevNextButton id={id}/>
                  <Button className="fw-bold" onClick={handleSubmit} disabled={isSubmitDisabled()}>
                    Submit <Icon name="arrow-right" className="ms-2"></Icon>
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PterReadingCorrectOrder;
