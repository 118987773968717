import React, { useContext, useState, useRef, useEffect } from "react";
import { ProgressBar, Spinner, Button, Form } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import ModalContext from "../../../../context/ModalContext";
import Timer from "../Timer";
import Audio from "../customAudio/audio";
import OnlyRecorderUI from "../Istructions/ExamComponents/OnlyRecorderUI";
import AudioUI from "../Istructions/ExamComponents/AudioUI";
const Snippet = ({ text, answer, setNewArray, optionsArr }) => {
  const [arr, setArr] = useState([]);
  var str = [];
  var answerlength = 0;
  var prevIndex = 0;

  if (answer.length) {
    for (let i = 0; i < answer.length; i++) {
      var indexat = text.indexOf(answer[i]);
      str[i] = text.substring(answerlength + prevIndex, indexat);

      prevIndex = indexat;
      answerlength = answer[i].length;
    }

    str[answer.length] = text.substring(answerlength + prevIndex, text.length);
  }

  return (
    <>
      {str.length > 0 &&
        str.map((item, i) => {
          return (
            <>
              {item}
              {str.length - 2 >= i && (
                <div className="d-inline-block mx-2 exam-form">
                  <Form.Control type="text" id="fib-1" placeholder="" />
                </div>
              )}
            </>
          );
        })}
    </>
  );
};
function ListeningFillInBlanks({ data }) {
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [startAudio, setStartAudio] = useState(false);
  var { question_options } = data;
  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <div className="d-flex justify-content-center align-items-center gap-4 my-5">
        <div className="d-flex flex-column gap-5 justify-content-start w-50 mb-5">
        <AudioUI
                timer={data.question_begin_time}
                audio={data.question_content_audio}
                startRecording={() => {
                  // setStartAudio(true);
                  // startRec();
                }}
              />
          
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center gap-4 my-5">
          <div className="">
            <Snippet text={data.question_content} answer={question_options[0].answer} />
            {/* <div className="d-inline-block mx-2 exam-form">
                    <Form.Control type="text" id="fib-1" placeholder="" />
                  </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default ListeningFillInBlanks;
