import React from 'react';

const PracticeMeSvg = ({ fill,style }) => {
  console.log("fillacc", fill);
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6545 3.3327C11.5507 2.39544 10.7559 1.6665 9.79102 1.6665H6.87435C5.90939 1.6665 5.11469 2.39544 5.01084 3.3327L3.54102 3.33317C2.50548 3.33317 1.66602 4.17264 1.66602 5.20817V16.4582C1.66602 17.4937 2.50548 18.3332 3.54102 18.3332H10.674C10.3187 17.9629 10.0155 17.5423 9.77618 17.0832H3.54102C3.19584 17.0832 2.91602 16.8033 2.91602 16.4582V5.20817C2.91602 4.863 3.19584 4.58317 3.54102 4.58317L5.31518 4.5833C5.65155 5.08573 6.22432 5.4165 6.87435 5.4165H9.79102C10.441 5.4165 11.0138 5.08575 11.3502 4.58334L13.1243 4.58317C13.4695 4.58317 13.7493 4.863 13.7493 5.20817V9.23017C14.021 9.18825 14.2993 9.1665 14.5827 9.1665C14.7228 9.1665 14.8618 9.17184 14.9993 9.18225V5.20817C14.9993 4.17264 14.1598 3.33317 13.1243 3.33317L11.6545 3.3327ZM11.6617 3.41313L11.666 3.5415C11.666 3.49835 11.6646 3.45555 11.6617 3.41313ZM6.87435 2.9165H9.79102C10.1362 2.9165 10.416 3.19633 10.416 3.5415C10.416 3.88668 10.1362 4.1665 9.79102 4.1665H6.87435C6.52917 4.1665 6.24935 3.88668 6.24935 3.5415C6.24935 3.19633 6.52917 2.9165 6.87435 2.9165Z" fill={fill}/>
<path d="M19.1667 14.5833C19.1667 17.1146 17.1147 19.1667 14.5833 19.1667C12.052 19.1667 10 17.1146 10 14.5833C10 12.052 12.052 10 14.5833 10C17.1147 10 19.1667 12.052 19.1667 14.5833ZM17.378 12.622C17.2153 12.4593 16.9514 12.4593 16.7887 12.622L13.75 15.6607L12.378 14.2887C12.2152 14.126 11.9514 14.126 11.7887 14.2887C11.626 14.4514 11.626 14.7152 11.7887 14.878L13.4553 16.5446C13.6181 16.7074 13.8819 16.7074 14.0447 16.5446L17.378 13.2113C17.5407 13.0486 17.5407 12.7848 17.378 12.622Z" fill={fill}/>
<path d="M8.95833 10H5.625C5.27983 10 5 10.2798 5 10.625C5 10.9702 5.27983 11.25 5.625 11.25H8.95833C9.3035 11.25 9.58333 10.9702 9.58333 10.625C9.58333 10.2798 9.3035 10 8.95833 10Z" fill={fill}/>
<path d="M10.625 6.66667H5.625C5.27983 6.66667 5 6.9465 5 7.29167C5 7.63683 5.27983 7.91667 5.625 7.91667H10.625C10.9702 7.91667 11.25 7.63683 11.25 7.29167C11.25 6.9465 10.9702 6.66667 10.625 6.66667Z" fill={fill}/>
</svg>

    
    
  );
}

export default PracticeMeSvg;
