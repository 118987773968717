import { useContext, useEffect, useState } from "react";
import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import TimePicker from "../../components/Form/TimePicker";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  Image,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../components";
import Context from "../../context";
import Question_Context from "../../context/questionContext";

function AddQuestions() {
  let navigate = useNavigate();
  const { quesData, setQuesData } = useContext(Question_Context);
  const [A, setAnsFirstTime] = useState(false);
  const [question_begin_time_FirstTimE, setQuestion_begin_time_FirstTimE] =
    useState(false);
  const [answer_prepare_time_FirstTimE, setanswer_prepare_time_FirstTimE] =
    useState(false);
  const [data, setData] = useState({
    module: "",
    answer_time: "",
  });
  console.log("data", data);
  useEffect(() => {
    // localStorage.setItem("questionData", JSON.stringify(data));
    setQuesData(data);
  }, [data]);

  let allModuleArray = [
    {
      value: "read_alouds",
      answer_time: "00:40",
      answer_prepare_time: "00:35",
      question_begin_time: "",
      text: "Read Aloud",
    },
    {
      value: "repeat_sentences",
      answer_time: "00:15",
      answer_prepare_time: "00:01",
      question_begin_time: "00:03",
      text: "Repeat Sentence",
    },
    {
      value: "describe_images",
      answer_time: "00:40",
      answer_prepare_time: "00:25",
      question_begin_time: "",
      text: "Describe Image",
    },
    {
      value: "retell_lectures",
      answer_time: "00:40",
      answer_prepare_time: "00:10",
      question_begin_time: "00:03",
      text: "Re-Tell Lecture",
    },
    {
      value: "answer_questions",
      answer_time: "00:10",
      answer_prepare_time: "00:01",
      question_begin_time: "00:03",
      text: "Answer Short Question",
    },
    {
      value: "swts",
      answer_time: "10:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Summarize Writing Text",
    },
    {
      value: "essays",
      answer_time: "20:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Essay",
    },
    {
      value: "fib_wr",
      answer_time: "00:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Reading and Writing Fill in the Blanks",
    },
    {
      value: "r_mcm",
      answer_time: "00:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Multiple Choice, Choose Multiple Answer",
    },
    {
      value: "ro",
      answer_time: "00:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Re-order Paragraphs",
    },
    {
      value: "r_mcs",
      answer_time: "00:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Multiple Choice, Choose Single Answer",
    },
    {
      value: "fib_rd",
      answer_time: "00:00",
      answer_prepare_time: "",
      question_begin_time: "",
      text: "Reading: Fill in the Blanks",
    },
    {
      value: "ssts",
      answer_time: "10:00",
      answer_prepare_time: "",
      question_begin_time: "00:10",
      text: "Summarize Spoken Text",
    },
    {
      value: "l_mcm",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:05",
      text: "Multiple Choice Multiple Answer",
    },
    {
      value: "l_fib",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:07",
      text: "Fill in the Blanks",
    },
    {
      value: "l_hcs",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:10",
      text: "Highlight Correct Summary",
    },
    {
      value: "l_mcs",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:05",
      text: "Multiple Choice Single Answer",
    },
    {
      value: "l_smw",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:05",
      text: "Select Missing Words",
    },
    {
      value: "hiws",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:10",
      text: "Highlight Incorrect Words",
    },
    {
      value: "wfds",
      answer_time: "",
      answer_prepare_time: "",
      question_begin_time: "00:06",
      text: "Write From Dictation",
    },
  ];

  const handleChange =async  (e) => {
    let { value, name, type } = e.target;

    if (value === "reading") {
      setData({
        ...data,
        [name]: value,
        answer_time: "00:00",
        answer_prepare_time: "",
        question_begin_time: "",
        module_name: "fib_wr",
        module_label: "Reading and Writing Fill in the Blanks",
      });
    } else if (value === "speaking") {
      setData({
        ...data,
        [name]: value,
        answer_time: "00:40",
        answer_prepare_time: "00:35",
        question_begin_time: "",
        module_name: "read_alouds",
        module_label: "Read Aloud",
      });
    } else if (value === "writing") {
      setData({
        ...data,
        [name]: value,
        answer_time: "10:00",
        answer_prepare_time: "",
        question_begin_time: "",
        module_name: "swts",
        module_label: "Summarize Writing Text",
      });
    } else if (value === "listening") {
      setData({
        ...data,
        [name]: value,
        answer_time: "10:00",
        answer_prepare_time: "",
        question_begin_time: "00:10",
        module_name: "ssts",
        module_label: "Summarize Spoken Text",
      });
    } else {
      if (name === "answer_time") {
        if (parseInt(value, 10) < 60 && !value.includes(":")) {
          if (A) {
            value = value;
            setAnsFirstTime(!A);
          } else {
            if (value.length == 2) {
              setAnsFirstTime(!A);
            }

            value = value.length == 2 ? value + ":" : value;
          }

          setData({ ...data, [name]: value });
        } else {
          if (!value.split(":")[1]) {
            if (value == ":") {
              setData({ ...data, [name]: "00:" });
            } else {
              if (value.includes(":") && value.length == 2) {
                setData({ ...data, [name]: "0" + value });
              } else {
                if (value.includes(":") && value.length == 3) {
                  setData({ ...data, [name]: value });
                } else {
                  setData({ ...data, [name]: "" });
                }
              }
            }
          } else {
            if (
              value.split(":")[1].length < 3 &&
              value.split(":")[0].length < 3
            ) {
              setData({
                ...data,
                [name]:
                  value.split(":")[1] < 60 && value.split(":")[0] < 60
                    ? value
                    : "",
              });
            }
          }
        }
      } else if (name == "question_begin_time") {
        if (parseInt(value, 10) < 60 && !value.includes(":")) {
          if (question_begin_time_FirstTimE) {
            value = value;
            setQuestion_begin_time_FirstTimE(!question_begin_time_FirstTimE);
          } else {
            if (value.length == 2) {
              setQuestion_begin_time_FirstTimE(!question_begin_time_FirstTimE);
            }
            value = value.length == 2 ? value + ":" : value;
          }

          setData({ ...data, [name]: value });
        } else {
          if (!value.split(":")[1]) {
            if (value == ":") {
              setData({ ...data, [name]: "00:" });
            } else {
              if (value.includes(":") && value.length == 2) {
                setData({ ...data, [name]: "0" + value });
              } else {
                if (value.includes(":") && value.length == 3) {
                  setData({ ...data, [name]: value });
                } else {
                  setData({ ...data, [name]: "" });
                }
              }
            }
          } else {
            if (
              value.split(":")[1].length < 3 &&
              value.split(":")[0].length < 3
            ) {
              setData({
                ...data,
                [name]:
                  value.split(":")[1] < 60 && value.split(":")[0] < 60
                    ? value
                    : "",
              });
            }
          }
        }
      } else if (name == "answer_prepare_time") {
        if (parseInt(value, 10) < 60 && !value.includes(":")) {
          if (answer_prepare_time_FirstTimE) {
            value = value;
            setanswer_prepare_time_FirstTimE(!answer_prepare_time_FirstTimE);
          } else {
            if (value.length == 2) {
              setanswer_prepare_time_FirstTimE(!answer_prepare_time_FirstTimE);
            }
            value = value.length == 2 ? value + ":" : value;
          }

          setData({ ...data, [name]: value });
        } else {
          if (!value.split(":")[1]) {
            if (value == ":") {
              setData({ ...data, [name]: "00:" });
            } else {
              if (value.includes(":") && value.length == 2) {
                setData({ ...data, [name]: "0" + value });
              } else {
                if (value.includes(":") && value.length == 3) {
                  setData({ ...data, [name]: value });
                } else {
                  setData({ ...data, [name]: "" });
                }
              }
            }
          } else {
            if (
              value.split(":")[1].length < 3 &&
              value.split(":")[0].length < 3
            ) {
              setData({
                ...data,
                [name]:
                  value.split(":")[1] < 60 && value.split(":")[0] < 60
                    ? value
                    : "",
              });
            }
          }
        }
      } else {
        if (name == "module_name") {
          const selectedOption = e.target.options[e.target.selectedIndex];
          const itemText = selectedOption.getAttribute("data-text");
          let values =  allModuleArray.find((item)=>item.text == itemText)
          setData({ ...data, [name]: value, module_label: itemText, answer_time: values?.answer_time,
          answer_prepare_time: values?.answer_prepare_time,
          question_begin_time: values?.question_begin_time });
        } else {
          setData({ ...data, [name]: value });
        }
      }
    }
  };
  console.log("dataa", data);
  const handleSaveAndNext = () => {
    let { module, module_name } = data;
    let link = "";
    if (module === "speaking") {
      navigate(
        `/material-management/pte/${module}/${
          module_name ? module_name : "read_alouds"
        }`
      );
    } else if (module === "writing") {
      navigate(
        `/material-management/pte/${module}/${
          module_name ? module_name : "swts"
        }`
      );
    } else if (module === "reading") {
      navigate(
        `/material-management/pte/${module}/${
          module_name ? module_name : "fib_wr"
        }`
      );
    } else if (module === "listening") {
      navigate(
        `/material-management/pte/${module}/${
          module_name ? module_name : "ssts"
        }`
      );
    }
    console.log("module_name", module_name);
  };

  let speaking = [
    { value: "read_alouds", text: "Read Aloud" },
    { value: "repeat_sentences", text: "Repeat Sentence" },
    { value: "describe_images", text: "Describe Image" },
    { value: "retell_lectures", text: "Re-Tell Lecture" },
    { value: "answer_questions", text: "Answer Short Question" },
  ];

  let writing = [
    { value: "swts", text: "Summarize Writing Text" },
    { value: "essays", text: "Essay" },
  ];

  let reading = [
    { value: "fib_wr", text: "Reading and Writing Fill in the Blanks" },
    { value: "r_mcm", text: "Multiple Choice, Choose Multiple Answer" },
    { value: "ro", text: "Re-order Paragraphs" },
    { value: "r_mcs", text: "Multiple Choice, Choose Single Answer" },
    { value: "fib_rd", text: "Reading: Fill in the Blanks" },
  ];

  let listening = [
    { value: "ssts", text: "Summarize Spoken Text" },
    { value: "l_mcm", text: "Multiple Choice Multiple Answer" },
    { value: "l_fib", text: "Fill in the Blanks" },
    { value: "l_hcs", text: "Highlight Correct Summary" },
    { value: "l_mcs", text: "Multiple Choice Single Answer" },
    { value: "l_smw", text: "Select Missing Words" },
    { value: "hiws", text: "Highlight Incorrect Words" },
    { value: "wfds", text: "Write From Dictation" },
  ];

  const isValidDate = () => {
    var ans_pep = document.getElementById("ans_pep");
    var ques_beg = document.getElementById("ques_beg");
    var ans_time = document.getElementById("ans_time");

    var isValidAnsPep = ans_pep
      ? ans_pep != null && ans_pep.value?.length == 5
      : true;
    var isValidQuesBeg = ques_beg
      ? ques_beg != null && ques_beg.value?.length == 5
      : true;
    var isValidAnsTime = ans_time
      ? ans_time != null && ans_time.value?.length == 5
      : true;
    console.log("isValidAnsPep", isValidAnsPep);
    return isValidAnsPep && isValidQuesBeg && isValidAnsTime;
  };

  return (
    <>
      <Layout title="Add Product" content="container">
        <Block.Head>
          <Block.HeadBetween>
            <Block.HeadContent>
              <Block.Title tag="h2">Add Question</Block.Title>
            </Block.HeadContent>
          </Block.HeadBetween>
        </Block.Head>

        <Block>
          <Form action="#">
            <Card className="card-gutter-md">
              <Card.Body>
                <Row className="g-gs">
                  <Col md="9">
                    <Row className="g-gs" id="">
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label className="ms-3 fw-bold">
                            Single Test Type
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Select
                              name="module"
                              onChange={(e) => handleChange(e)}
                              removeItemButton
                            >
                              <option value="">Select Test</option>
                              <option value="speaking">Speaking</option>
                              <option value="writing">Writing</option>
                              <option value="reading">Reading</option>
                              <option value="listening">Listening</option>
                            </Select>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <Form.Label className="ms-3 fw-bold">
                            Select Question Type
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Select
                              removeItemButton
                              name="module_name"
                              onChange={(e) => handleChange(e)}
                            >
                              {data?.module === "speaking" &&
                                speaking.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.value}
                                    data-text={item.text}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                              {data?.module === "writing" &&
                                writing.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.value}
                                    data-text={item.text}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                              {data?.module === "reading" &&
                                reading.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.value}
                                    data-text={item.text}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                              {data?.module === "listening" &&
                                listening.map((item, i) => (
                                  <option
                                    key={i}
                                    value={item.value}
                                    data-text={item.text}
                                  >
                                    {item.text}
                                  </option>
                                ))}
                            </Form.Select>
                          </div>
                        </Form.Group>
                      </Col>
                      {/* {((data.module === "listening" &&
                        data.module_name == "hiws" &&
                        data.module_name !== "wfds") || */}
                      {(data.module_name == "l_mcm" ||
                        data.module_name == "l_mcs" ||
                        data.module == "listening" ||
                        (data.module === "speaking" &&
                          data.module_name === "repeat_sentences") ||
                        data.module_name === "answer_questions" ||
                        data.module_name === "retell_lectures") && (
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label className="ms-3 fw-bold">
                              Question Beginning Time
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div className="form-control-icon end">
                                <Image src="/images/stopwatch.svg"></Image>
                              </div>
                              <Form.Control
                                name="question_begin_time"
                                id="ques_beg"
                                value={data?.question_begin_time}
                                onChange={(e) => handleChange(e)}
                                type="text"
                                placeholder="00:00"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      )}
                      {data.module === "speaking" && (
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label className="ms-3 fw-bold">
                              Answer Preparation Time
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div className="form-control-icon end">
                                <Image src="/images/stopwatch.svg"></Image>
                              </div>
                              <Form.Control
                                type="text"
                                id="ans_pep"
                                name="answer_prepare_time"
                                value={data.answer_prepare_time}
                                onChange={(e) => handleChange(e)}
                                placeholder="00:00"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      )}
                      {(data.module != "listening" ||
                        (data.module == "listening" &&
                          data.module_name == "ssts")) && (
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label className="ms-3 fw-bold">
                              Answer Time
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div className="form-control-icon end">
                                <Image src="/images/stopwatch.svg"></Image>
                              </div>
                              <Form.Control
                                type="text"
                                id="ans_time"
                                value={data?.answer_time}
                                name="answer_time"
                                onChange={(e) => handleChange(e)}
                                placeholder="00:00"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      )}
                      <Col lg="12">
                        <Button
                          variant="primary"
                          onClick={handleSaveAndNext}
                          disabled={
                            data.module && data.module_name && isValidDate()
                              ? false
                              : true
                          }
                        >
                          <span>Save & Next</span>
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="3"></Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Block>
      </Layout>
    </>
  );
}

export default AddQuestions;
