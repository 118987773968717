import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function QuestionReadAloud({question,questionAudio}) {

  return (
    <p className='p-3 text-middark'>
     {question}
     {
        questionAudio &&
       <audio src={questionAudio} controls ></audio>
     }
    </p>
  );
}
export default QuestionReadAloud;