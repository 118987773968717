import React, { useState } from 'react';
import { ProgressBar, Spinner, Button } from 'react-bootstrap';
import ExamHeader from '../../../../layout/exam/Header';
import ExamFooter from '../../../../layout/exam/Footer';
import { Icon } from '../../../../components';
import NoUiSlider from '../../../../components/Form/NoUiSlider';
import Tinymce from '../../../../components/Form/editors/Tinymce';
import OnlyRecorderUI from '../Istructions/ExamComponents/OnlyRecorderUI';
import PracticeTextArea from '../../../practice-test/PTE/PracticeComponents/PracticeTextArea';
import AudioUI from '../Istructions/ExamComponents/AudioUI';

function ListeningSummarizeSpokenText({data}) {
  const [startAudio, setStartAudio] = useState(false);
    return (
        <>
             <div className="container my-5 text-examheadtext">
              <h3 className='text-black' style={{fontFamily:"Roboto"}}>
              <em className="pte-test-instructions-bold">
                {data.instructions}
                </em>
              </h3>
              <div className="d-flex justify-content-center align-items-center gap-4 my-5">
              <div className="d-flex flex-column align-items-center w-50">
            {data.question_content_audio != "null" && (
              <AudioUI
                timer={
                  data.question_begin_time
                    ? data.question_begin_time
                    : "00:00"
                }
                audio={data?.question_content_audio}
                startRecording={() => {
                  setStartAudio(true);
                }}
              />
            )}
          </div>
        </div>
              <br />
              <br />
              <PracticeTextArea/>
              <br />
              <br />
            </div>
        </>
    )
}
export default ListeningSummarizeSpokenText;