import React, { useState, useRef, useEffect, useContext } from "react";
import { Card, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import Swal from "sweetalert2/src/sweetalert2.js";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import FileUpload from "../../components/Form/FileUpload";
import Select from "../../components/Form/Select";
import Context from "../../context";
import SubmitButton from "../questions/PTE/SubmitButton";
import AuthApi from "../../api/auth";
import { useNavigate } from "react-router-dom";

function AddTicket() {
  let { userData, domainData } = useContext(Context);
  userData = JSON.parse(userData);
  domainData = JSON.parse(domainData);
  let navigate = useNavigate()
let api = new AuthApi();
  const [data, setdata] = useState({
    user_id:userData?._id,
    institute_id:domainData?._id,
    inst_name:domainData?.inst_profile?.inst_business_name,
    status:"pending",
  });
  console.log("domainData",domainData)
  const [loader, setloader] = useState(false);
  let formdata = new FormData();
  const sendTicket = async () => {
    setloader(true)
    formdata.append("type","STUDENT")
    for(const[key,value] of Object.entries(data)){
        formdata.append([key],value)
        
    }
    await api.add_support(formdata).then((res)=>{
        setloader(false)
        if(res?.status){
            Swal.fire({
                icon: "success",
                title: "Ticket Created ",
                text: res.message,
                confirmButtonText: "Done",
              });
              navigate("/view-tickets")
        }else{
            Swal.fire({
                icon: "error",
                title: "Oops",
                text: res.message,
              });
        }
        console.log("res",res)
    }).catch((err)=>{
        console.log(err)
    })

  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    setdata({ ...data, [name]: value });
  };
  const checkValidation = () => {
    return (
      data.subject &&
      data.department &&
      data?.message &&
      data?.attachment
    );
  };
  return (
    <Layout title="Add Student" content="container">
      <h1 className="mb-5">Add Ticket</h1>
      <Block>
        <Card className="h-100">
          <Card.Body>
            <Row className="g-gs">
              <Col md="9">
                <Row className="g-gs">
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="fullname">Subject</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          name="subject"
                          onChange={(e) => handleChange(e)}
                          value={data?.subject}
                          type="text"
                          id="subject"
                          placeholder="Enter a subject here..."
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label>Department</Form.Label>
                      <div className="form-control-wrap">
                        <Select
                          onChange={(e) => handleChange(e)}
                          name="department"
                          value={data?.department}
                        >
                          <option value="">Select</option>
                          <option value="Billing">Billing</option>
                          <option value="Sales">Press</option>
                          <option value="Sales">Sales</option>
                          <option value="Technical">Technical</option>
                        </Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="message">Message</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          onChange={(e) => handleChange(e)}
                          name="message"
                          value={data?.message}
                          as="textarea"
                          placeholder="Type your message here..."
                          id="message"
                          rows="3"
                        ></Form.Control>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group className="form-group">
                      <Form.Label>Upload an attachment</Form.Label>
                      <div className="form-control-wrap">
                        <FileUpload
                          filetype="document"
                          iconName="file"
                          name="attachment"
                          maxFiles={1}
                          onChange={(updatedFiles) => {
                            setdata({ ...data, attachment: updatedFiles[0] });
                          }}
                          errorText="Multiple files not supported, please upload single file."
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="3"></Col>
              <Col md="12" className="my-5">
                <SubmitButton
                  text="Send Ticket"
                  onClick={() => sendTicket()}
                  loader={loader}
                  disabled={!checkValidation()}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}

export default AddTicket;
