import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
  TimePicker,
} from "../../components";
import { useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import { useEffect } from "react";
function Addbranch(props) {
  let { _id,instId } = useParams();
  let api = new AuthApi();
  let { userData } = useContext(Context);
  userData=JSON.parse(userData)
  let navigate = useNavigate();

  const [data, setData] = useState({
    inst_id: instId?instId:(userData)._id,
    latitute: "31.1471",
    longitude: "75.3412",
  });
  useEffect(() => {
    const fetchMarkerPosition = async () => {
      const response = await fetch("YOUR_API_ENDPOINT_HERE");
      const data = await response.json();
      //   setMarkerPosition({ lat: data.lat, lng: data.lng });
    };

    fetchMarkerPosition();
  }, []);
console.log("data",data)
  const onMarkerClick = (props, marker, e) => {
    console.log(
      "Marker clicked:",
      marker.getPosition().lat(),
      marker.getPosition().lng()
    );
  };
  const onMapClick = (mapProps, map, clickEvent) => {
    const lat = clickEvent.latLng.lat();
    const lng = clickEvent.latLng.lng();
    // setMarkerPosition({ lat, lng });
    setData({ ...data, latitute: String(lat), longitude: String(lng) });
  };
  //   console.log(markerPosition);

  console.log("-------", data);
  useEffect(() => {
    if (_id) {
      get_branch_by_id();
    }
  }, [_id]);

  const get_branch_by_id = async () => {
    await api.get_branch_by_id(_id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await api.add_branch(data).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleSubmitEditBranch = async (e) => {
    e.preventDefault();
    await api.edit_branch_by_id(data, _id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const permission = [
    "MY Plan",
    "Student Overview",
    "Student Permission",
    "Setting",
    "Invoice",
    "Result",
    "Announcement",
    "Help",
  ];
  let course = ["PTE", "TOEFL", "CELPIP", "CD-IELTS"];
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              {_id ? "Edit Branch" : "Add Branch"}
            </Block.Title>
          </Block.HeadContent>
          {/* <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                    <Icon name="eye" />
                                    <span>View</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                    <Icon name="eye" />
                                    <span>View Products</span>
                                </Link>
                            </li>
                        </ul>
                    </Block.HeadContent> */}
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              {/* <h5 className="mb-3">Select Product</h5> */}
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label>Branch name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="name"
                            value={data?.name}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="Branch name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">Address </Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="address"
                            value={data?.address}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="Full Address"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">City Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="location"
                            value={data?.location}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="City Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">
                          Select Lat long
                        </Form.Label>
                        <div className="form-control-wrap">
                          <Map
                            google={props.google}
                            style={{ width: "100%", height: "120px" }} // Add this style
                            initialCenter={{
                              lat: Number(data.latitute),
                              lng: Number(data.longitude),
                            }}
                            center={{
                              lat: Number(data.latitute),
                              lng: Number(data.longitude),
                            }}
                            zoom={10}
                            onClick={onMapClick}
                          >
                            <Marker
                              onClick={onMarkerClick}
                              name={"Current location"}
                              position={{
                                lat: Number(data.latitute),
                                lng: Number(data.longitude),
                              }}
                            />
                          </Map>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="3"></Col>

                <Col md="12" className="my-5 map-btn">
                  {/* <Button
                      className="d-md-inline-flex fw-bold"
                      variant="outline-primary"
                    >
                      Update Team
                    </Button> */}
                  {_id ? (
                    <>
                      <Button
                        onClick={handleSubmitEditBranch}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={
                          !data.name ||
                          !data.location ||
                          !data.address ||
                          !data.inst_id
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleSubmit}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                        disabled={
                          !data.name ||
                          !data.location ||
                          !data.address ||
                          !data.inst_id
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCmcF0iGEVC9BzdBh8mlwA1LR6LOqNotWI", // Replace with your Google Maps API key
})(Addbranch);
