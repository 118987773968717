import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Nav,
  Tab,
  Tabs,
  Col,
  Card,
  Modal, Button,
  Form,
  Dropdown,
  Table,
  Tooltip,
  OverlayTrigger,
  ListGroup, Badge
} from "react-bootstrap";
import { Colors } from "../../utilities/index";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Media,
  MediaGroup,
  MediaText,
  Pureknob,
  Icon,
  ChartLabel,
  ChartLegend,
  CustomDropdownMenu,
  CustomDropdownToggle,
  Image,
  DatePicker,
} from "../../components";
import {
  ChartBar,
  ChartLine,
  ChartDoughnut,
} from "../../components/Chart/Charts";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useContext } from "react";
import Context from "../../context";
import ImageSlider from "../ImageSlider";
import AuthApi from "../../api/auth";
import Swal from "sweetalert2/src/sweetalert2.js";
import StudyTips from "./StudyTips";
function StudentTargets() {
  let api = new AuthApi();
  let { localHost, userData } = useContext(Context);
  userData = JSON.parse(userData);
  const [demoModal, setDemoModal] = useState(false);
  const [data, setData] = useState({
    user_id: userData?._id,
    speaking: "65",
    writing: "65",
    listening: "65",
    reading: "65",
    overall: "",
  });

  const demoModalClose = () => setDemoModal(false);
  const demoModalShow = () => setDemoModal(true);

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (value.length > 2 && name != "date") {
      return
    }
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    const updatedData = { ...data };
    for (const [key, value] of Object.entries(updatedData)) {
      switch (key) {
        case "speaking":
        case "writing":
        case "listening":
        case "reading":
        case "overall":
          if (value && value < 10) {
            updatedData[key] = 10;
          } else if (value && value > 90) {
            updatedData[key] = 90;
          }
          break;
        default:
          break;
      }
    }
    await api.add_user_target(updatedData).then((res) => {
      if (res?.status) {
        Swal.fire({
          icon: "success",
          title: "Success ",
          text: res.message,
        });
        get_user_target();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: res.message,
        });
      }
      demoModalClose();
    })
  }

  const get_user_target = async () => {
    await api.get_user_target(userData?._id).then((res) => {

      if (res.data.length > 0) {
        setData({
          ...data, date: res.data[0].date, speaking: res.data[0]?.speaking,
          writing: res.data[0]?.writing,
          listening: res.data[0]?.listening,
          reading: res.data[0]?.reading,
          overall: res.data[0]?.overall, id: res.data[0]?._id,
          listening_target: res.data[0]?.listening_target,
          reading_target: res.data[0]?.reading_target,
          speaking_target: res.data[0]?.speaking_target,
          writing_target: res.data[0]?.writing_target,
        })

      }
    })
  }
  useEffect(() => {
    get_user_target();
  }, [])

  console.log("DATATA", data);

  return (
    <Layout title="Analytics" content="container">
      <Block>
        <Card className="h-100">
          <Card.Body>
            <Row className="my-3">
              <Col md="12">
                <h5 className="mb-3">Target</h5>
              </Col>
              <Col md="3"></Col>
              <Col md="6">
                <Card className="mb-3">
                  <Card.Body className="d-flex justify-content-between align-items-center p-3 gap-2">
                    <img src="/images/ai-icon.svg" style={{ width: "80px" }}></img>
                    <p className="text-middark text-center m-0 w-75">Focus on the important questions : Speaking RA, RS, DI,RL Writing...</p>
                    <p className="d-flex justify-content-end text-middark w-20">
                      <Link to="/student/study-tips"> View All &gt;</Link>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="3"></Col>
              <Col md="6">
                <Card
                  className="mb-3"
                  onClick={demoModalShow}
                  style={{ cursor: "pointer" }}
                >
                  <div className="bg-primary py-1 px-3 d-flex justify-content-between text-white rounded-top">
                    <p className="mb-0">Exam Date : {data?.date}</p>
                    {/* <span> 100 Days Left</span> */}
                  </div>
                  <Card.Body className="d-flex flex-column p-3 pt-5">
                    <OverAllScore data={data} />
                    <p className="d-flex justify-content-end text-middark mt-3">
                      Set Target &gt;
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6">
                <Card className="mb-3">
                  <div className="bg-primary py-1 px-3 d-flex justify-content-between text-white rounded-top">
                    <p className="mb-0">Exam Date : 13-02-2024</p>
                    <span>Real Exam</span>
                  </div>
                  <Card.Body className="d-flex flex-column p-3 pt-5">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column align-items-center">
                        <h2 className="text-hidanger">50</h2>
                        <p className="text-middark">Overall</p>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <h2>80</h2>
                        <p className="text-middark">Listening</p>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <h2>65</h2>
                        <p className="text-middark">Reading</p>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <h2>70</h2>
                        <p className="text-middark">Speaking</p>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <h2>65</h2>
                        <p className="text-middark">Writing</p>
                      </div>
                    </div>
                    <p className="d-flex justify-content-end text-middark mt-3">
                      <Link to="/student/exam-history"> Score Report Analysis &gt;</Link>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="12" className="my-5 ">
                <h5>Important Questions' Correctness Req</h5>
                <p className="text-middark">
                  According to your target scores, you're suggested to focus on
                  the following question types and achieve their correctness
                  requirements. Click on the progress bars to see more details
                </p>
              </Col>
              <Col md="6">
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <div className="d-flex justify-content-between mb-4">
                      <h5>Speaking</h5>
                      <p className="text-light">
                        {" "}
                        Target : Suggested Commonness Levels
                      </p>
                    </div>
                    {data?.speaking_target?.length > 0 && data?.speaking_target.map((ele, key) => {
                      return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                        <Media
                          size="md"
                          shape="sequre"
                          className="bg-hisuccess text-white"
                        >
                          {ele?.module_name}
                        </Media>
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex justify-content-between w-100 text-light mb-1">
                            <span>
                              My : {Math.floor(Math.random() * ele?.target)}%{" "}
                              {/* <span className="text-hidanger">(-27)</span> */}
                            </span>
                            <span>Target : {ele?.target}%</span>
                          </div>
                          <ProgressBar style={{ height: "8px" }}>
                            <ProgressBar variant="hisuccess" now={10} />
                            <ProgressBar
                              variant="hisuccess"
                              className="opacity-25"
                              now={ele?.target - 10}
                              key={2}
                            />
                          </ProgressBar>
                        </div>
                      </div>
                    })}
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <h5>Reading</h5>
                    {data?.reading_target?.length > 0 && data?.reading_target.map((ele, key) => {
                      return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                        <Media
                          size="md"
                          shape="sequre"
                          className="bg-hiwarning text-white"
                        >
                          {ele?.module_name}
                        </Media>
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex justify-content-between w-100 text-light mb-1">
                            <span>
                              My : {Math.floor(Math.random() * ele?.target)}%{" "}
                              {/* <span className="text-hidanger">(-27)</span> */}
                            </span>
                            <span>Target : {ele?.target}%</span>
                          </div>
                          <ProgressBar style={{ height: "8px" }}>
                            <ProgressBar variant="hiwarning" now={Math.floor(Math.random() * 90) + 1} key={1} />
                            <ProgressBar
                              variant="hiwarning"
                              className="opacity-25"
                              now={ele?.target - 10}
                              key={2}
                            />
                          </ProgressBar>
                        </div>
                      </div>
                    })}
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <h5>Today's Records</h5>
                    <div className="border rounded text-middark">
                      <div className="d-flex flex-column py-2">
                        <div className="d-flex fw-bold text-secondary justify-content-between align-items-center border-bottom mb-2 pb-2 px-3">
                          <p className="mb-0">12345</p>
                          <p>sdfgsad</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0">  <img src="/images/practice-icons/read_alouds.svg" className="me-3" style={{ width: "30px" }}></img>12345</p>
                          <p>sdfgsad </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0">  <img src="/images/practice-icons/l_smw.svg" className="me-3" style={{ width: "30px" }}></img>12345</p>
                          <p>sdfgsad </p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className="d-flex justify-content-between mb-3">
                  <h5>History Records</h5>
                  <p className="d-flex justify-content-end text-middark w-20">
                    <Link to="/student/history-records"> More Records &gt;</Link>
                  </p>
                </div>
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <div className="border rounded text-middark">
                      <div className="d-flex flex-column py-2">
                        <div className="d-flex fw-bold text-secondary justify-content-between align-items-center border-bottom mb-2 pb-2 px-3">
                          <p className="mb-0">2024-03-28</p>
                          <p>Total Practiced : 2 Qs</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0"> <img src="/images/practice-icons/repeat_sentences.svg" className="me-3" style={{ width: "30px" }}></img>Repeat Sentences</p>
                          <p>Practiced 1 Qs </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0"> <img src="/images/practice-icons/read_alouds.svg" className="me-3" style={{ width: "30px" }}></img>Read Alouds</p>
                          <p>Practiced 1 Qs </p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <div className="border rounded text-middark">
                      <div className="d-flex flex-column py-2">
                        <div className="d-flex fw-bold text-secondary justify-content-between align-items-center border-bottom mb-2 pb-2 px-3">
                          <p className="mb-0">2024-03-27</p>
                          <p>Total Practiced : 2 Qs</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0">
                            <img src="/images/practice-icons/retell_lectures.svg" className="me-3" style={{ width: "30px" }}></img>
                            Re-tell Lectures</p>
                          <p>Practiced 1 Qs </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0"> <img src="/images/practice-icons/read_alouds.svg" className="me-3" style={{ width: "30px" }}></img>Read Alouds</p>
                          <p>Practiced 1 Qs </p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <div className="border rounded text-middark">
                      <div className="d-flex flex-column py-2">
                        <div className="d-flex fw-bold text-secondary justify-content-between align-items-center border-bottom mb-2 pb-2 px-3">
                          <p className="mb-0">2024-03-27</p>
                          <p>Total Practiced : 2 Qs</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0">
                            <img src="/images/practice-icons/retell_lectures.svg" className="me-3" style={{ width: "30px" }}></img>
                            Re-tell Lectures</p>
                          <p>Practiced 1 Qs </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-1 px-2">
                          <p className="mb-0"> <img src="/images/practice-icons/read_alouds.svg" className="me-3" style={{ width: "30px" }}></img>Read Alouds</p>
                          <p>Practiced 1 Qs </p>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md="6">
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <h5>Writing</h5>
                    {data?.writing_target?.length > 0 && data?.writing_target.map((ele, key) => {
                      return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                        <Media
                          size="md"
                          shape="sequre"
                          className="bg-hidanger text-white"
                        >
                          {ele?.module_name}
                        </Media>
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex justify-content-between w-100 text-light mb-1">
                            <span>
                              My : {Math.floor(Math.random() * ele?.target)}%{" "}
                              {/* <span className="text-hidanger">(-27)</span> */}
                            </span>
                            <span>Target : {ele?.target}%</span>
                          </div>
                          <ProgressBar style={{ height: "8px" }}>
                            <ProgressBar variant="hidanger" now={Math.floor(Math.random() * 90) + 1} key={1} />
                            <ProgressBar
                              variant="hidanger"
                              className="opacity-25"
                              now={ele?.target - 10}
                              key={2}
                            />
                          </ProgressBar>
                        </div>
                      </div>
                    })}
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body className="d-flex flex-column">
                    <h5>Listening</h5>
                    {data?.listening_target?.length > 0 && data?.listening_target.map((ele, key) => {
                      return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                        <Media
                          size="md"
                          shape="sequre"
                          className="bg-hiprimary text-white"
                        >
                          {ele?.module_name}
                        </Media>
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex justify-content-between w-100 text-light mb-1">
                            <span>
                              My : {Math.floor(Math.random() * ele?.target)}%{" "}
                              {/* <span className="text-hidanger">(-27)</span> */}
                            </span>
                            <span>Target : {ele?.target}%</span>
                          </div>
                          <ProgressBar style={{ height: "8px" }}>
                            <ProgressBar variant="hiprimary" now={Math.floor(Math.random() * 90) + 1} key={1} />
                            <ProgressBar
                              variant="hiprimary"
                              className="opacity-25"
                              now={ele?.target - 10}
                              key={2}
                            />
                          </ProgressBar>
                        </div>
                      </div>
                    })}
                  </Card.Body>
                </Card>
                <p className="text-middark">
                  tips: Click on the progress bars to see more details
                </p>
                <Card className="mb-3">
                  <Card.Body>
                    <h5>Exam Histories</h5>
                    <div className="d-flex justify-content-around align-items-center px-5 py-3 icon-100">
                      <Link to="/student/upload-score"><Icon name="plus-circle-fill"></Icon></Link>
                      <h5>Add Score Reports. Get AI score report Analysis.</h5>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <div className="bg-primary py-1 px-3 d-flex justify-content-between text-white rounded-top">
                    <p className="mb-0">Exam Date : 13-02-2024</p>
                    <Button variant="light" size="sm"> Question Mock</Button>
                  </div>
                  <Card.Body className="d-flex flex-column p-3 pt-5">
                    <div className="d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Item</p>
                        <h2 className="text-hidanger">RO</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">My Correctness</p>
                        <h2>11%</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Target</p>
                        <h2>25.0%</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Status</p>
                        <Badge bg="primary">Bad</Badge>
                      </div>
                    </div>
                    <p className="d-flex justify-content-end text-middark mt-3">
                      Detail  &gt;
                    </p>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <div className="bg-primary py-1 px-3 d-flex justify-content-between text-white rounded-top">
                    <p className="mb-0">Exam Date : 13-02-2024</p>
                      <Button variant="light" size="sm">  Question Mock</Button>
                  </div>
                  <Card.Body className="d-flex flex-column p-3 pt-5">
                    <div className="d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Item</p>
                        <h2 className="text-hidanger">RO</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">My Correctness</p>
                        <h2>11%</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Target</p>
                        <h2>25.0%</h2>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-middark">Status</p>
                        <Badge bg="primary">Bad</Badge>
                      </div>
                    </div>
                    <p className="d-flex justify-content-end text-middark mt-3">
                      Detail  &gt;
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* <StudyTips/> */}
      </Block>
      <Modal show={demoModal} onHide={demoModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Target</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Exam Date</h5>
          <div className="form-control-wrap w-50">
            <Form.Control
              type="date"
              onChange={(e) => handleChange(e)}
              value={data?.date}
              name="date"
              placeholder="yyyy-mm-dd"
              autoComplete="off"
            />
            {/* <DatePicker id="eventStartDate"  placeholder="yyyy-mm-dd" /> */}
          </div>
          <h5 className="my-3">Score Target</h5>
          <div>
            <Tab.Container
              id="pills-tabs-example"
              defaultActiveKey="65"
            >
              <Nav
                variant="pills"
                className="mb-3 student-tabs"
                onSelect={(value) => {
                  if (value == "pills-overall") {
                    setData({
                      ...data,
                      speaking: "",
                      writing: "",
                      listening: "",
                      reading: "",
                      overall: "30",
                    });
                  } else {
                    setData({
                      ...data,
                      speaking: value,
                      writing: value,
                      listening: value,
                      reading: value,
                      overall: "",
                    });
                  }
                }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="65">All 65</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="79">All 79</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="50">All 50</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="pills-overall">Overall 30</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="65">
                  <OverAllScore data={data} onChange={(e) => handleChange(e)} />
                </Tab.Pane>
                <Tab.Pane eventKey="79">
                  <OverAllScore data={data} onChange={(e) => handleChange(e)} />
                </Tab.Pane>
                <Tab.Pane eventKey="50">
                  <OverAllScore data={data} onChange={(e) => handleChange(e)} />
                </Tab.Pane>
                <Tab.Pane eventKey="pills-overall" onChange={(e) => handleChange(e)}>
                  <OverAllScore data={data} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={demoModalClose}>
            Close
          </Button>
          <Button size="sm" variant="primary" onClick={() => handleSubmit()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default StudentTargets;

function OverAllScore({ data, onChange }) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-center">
          {/* <h2 className="text-hidanger">{data?.overall}</h2> */}
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                className="text-hidanger"
                type="text"
                value={data?.overall}
                onChange={onChange}
                name="overall"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Overall</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.listening}
                onChange={onChange}
                name="listening"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Listening</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.reading}
                onChange={onChange}
                name="reading"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Reading</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.speaking}
                onChange={onChange}
                name="speaking"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Speaking</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.writing}
                onChange={onChange}
                name="writing"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Writing</p>
        </div>
      </div>
    </>
  );
}
