import { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Row, Col, Badge, Spinner } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import AuthApi from '../../../api/auth';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Icon, Select } from '../../../components';
import InstituteDataTable from './InstituteDataTable';
import InstituteData, { InstituteColumns } from './InstituteData';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { MediaGroup, Media, MediaText, Image, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../../components";
import Swal from 'sweetalert2';

function InstituteRequests() {
    let api = new AuthApi();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([])
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        getInstituteList();
    }, [])

    const getInstituteList = async () => {
        setLoader(true)
        await api.institute_List().then((res) => {
            console.log("ress", res)
            if (res.data.length > 0) {
                let filterData = res?.data?.filter((item) => item?.isDemoInstitute == true)
                console.log('filterData :>> ', filterData);
                if (filterData.length > 0){

                    setData(filterData.reverse())
                    setLoader(false)
                }
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }

    let formDataObject = new FormData();
    async function institute_active(e) {
        let { name, checked, id } = e.target;
        api.edit_institute_active_status_by_id({ is_deleted: 0 }, id).then((res) => {
            try {
                console.log("RESPONSEES", res);
                if (res) {
                    Swal.fire({
                        icon: `${res.status && "success"}`,
                        title: "",
                        text: `${res.msg}`,
                    });
                    if (res.status == false) {
                        navigate(`/institute-detail/${res?.data?.domain_name}/${res?.data?._id}`)
                    }
                    else {
                        getInstituteList()
                    }

                }
            } catch (err) {
                console.log(err);
            }
        });
    }

    console.log(data)

    const InstituteColumns = [
        {
            name: <Form.Check className="form-check" id="check-all" />,
            cell: (row) => (
                <div className="text-center w-100">
                    <Form.Check className="form-check justify-content-center" />
                </div>
            ),
            sortable: false,
        },
        {
            name: "Institute Name",
            selector: (row) => row?.domain_name,
            cell: (row) => (
                <MediaGroup>
                    <Media size="md" shape="circle" variant={row.theme && row.theme}>
                        {row?.inst_profile?.business_logo ?
                            <Image src={row?.inst_profile?.business_logo} staticImage /> :
                            <Image src={'/images/avatar/a.jpg'} staticImage />
                        }
                    </Media>
                    <MediaText>
                        <Link to={`/institute-detail/${row.domain_name}/${row?._id}`} className="title">{row?.inst_profile?.inst_business_name}</Link>
                    </MediaText>
                </MediaGroup>
            ),
            sortable: true,
        },
        // {
        //     name: "Product",
        //     selector: (row) => row.product,
        //     cell: (row) => (
        //         <span className="text-middark">{row.product}</span>
        //     ),
        //     sortable: true,
        //     hide: "md",
        // },
        {
            name: "Username/email",
            selector: (row) => row?.email,
            cell: (row) => (
                <span className="text-middark">{row?.email}</span>
            ),
            sortable: true,
        },
        {
            name: "Owner Name ",
            selector: (row) => row.inst_profile?.inst_contact_firstname,
            cell: (row) => (
                <span className="text-middark">{row?.inst_profile?.inst_contact_firstname}</span>
            ),
            sortable: true,
        },
        // {
        //     name: "Assigned",
        //     selector: (row) => row.assigned,
        //     cell: (row) => (
        //         <span className="text-middark">{row.assigned}</span>
        //     ),
        //     sortable: true,
        //     hide: "lg",
        // },
        {
            name: "country",
            selector: (row) => row.attempt,
            cell: (row) => (
                <span className="text-middark">{row?.inst_profile?.inst_contact_source_country_student}</span>
            ),
            sortable: true,
        },
        {
            name: "students",
            selector: (row) => row.score,
            cell: (row) => (
                <span className="text-middark">{row?.inst_profile?.inst_number_of_student}</span>
            ),
            sortable: true,
        },
        {
            name: "Credit",
            grow: "1",
            selector: (row) => row?.email,
            cell: (row) => (
                <span className="text-middark">{row?.credit}</span>
            ),
            sortable: true,
        },
        {
            name: "status",
            selector: (row) => row.status,
            cell: (row) => (
                // <span className={`badge text-bg-${row.status === "Active" ? "success"
                //     : row.status === "Inactive" ? "danger"
                //         : "primary"}`
                // }>
                <span className={`badge text-bg-${row.is_deleted == 0 ? "success"
                    : row.is_deleted == 1 ? "danger"
                        : "primary"}`
                }>
                    {row.is_deleted == 0 ? "Activated" : 'Deactivated'}
                </span>
            ),
            sortable: true,
        },
        // {
        //     name: "Change Status",
        //     selector: (row) => row.status,
        //     cell: (row) => (
        //         // <span className={`badge text-bg-${row.status === "Active" ? "success"
        //         //     : row.status === "Inactive" ? "danger"
        //         //         : "primary"}`
        //         // }>
        //         <div className="form-check form-switch">
        //             <input
        //               className="form-check-input"
        //               type="checkbox"
        //               role="switch"
        //               value={row?.is_deleted}
        //               checked={!row?.is_deleted}
        //               onChange={(e) => institute_active(e)}
        //               id={row?._id}
        //               name="active"
        //             />
        //           </div>
        //         // <span className={`badge text-bg-${row.is_deleted == 0 ? "success"
        //         //     : row.is_deleted == 1? "danger"
        //         //         : "primary"}`
        //         // }>
        //         //     {row.is_deleted == 0 ? "Activated" : 'Deactivated'}
        //         // </span>
        //     ),
        //     sortable: true,
        // },
        {
            name: "action",
            cell: (row) => (
                <div className="text-end w-100">
                    <LinkList className="link-list-hover-bg-primary link-list-md d-flex align-items-center">
                        <Button style={{cursor:"pointer"}} size='sm' className='me-1' id={row?._id} onClick={(e) => institute_active(e)}>Activate</Button>
                        <LinkListItem to={`/institute-detail/${row.domain_name}/${row?._id}`} >
                            <Icon name="edit"></Icon>
                        </LinkListItem>
                    </LinkList>
                </div>
            ),
            sortable: false,
        },

    ];
    return (
        <Layout title="Users List" content="container">
            <h1 className='mb-5'> Requests</h1>
            <Block>
                <Row className="g-gs">
                    <Col md="12">
                        <Card>
                            <Card.Body>

                            {loader ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                                <InstituteDataTable tableClassName="data-table-head-light table-responsive institute-list" data={data} columns={InstituteColumns} />
                )}
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Block>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="#">
                        <Row className="g-3">
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="firstname">First Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="firstname" type="text" placeholder="First name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="lastname">Last Name</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="lastname" type="text" placeholder="Last name" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="email">Email Address</Form.Label>
                                    <div className="form-control-wrap">
                                        <Form.Control id="email" type="text" placeholder="Email address" />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="form-group">
                                    <Form.Label>Status</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a status</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Active</option>
                                            <option value="3">Inactive</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group className="form-group">
                                    <Form.Label>Role</Form.Label>
                                    <div className="form-control-wrap">
                                        <Select removeItemButton>
                                            <option value="">Select a role</option>
                                            <option value="1">Administrator</option>
                                            <option value="2">Developer</option>
                                            <option value="3">Analyst</option>
                                            <option value="4">Support</option>
                                            <option value="5">Trial</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <div className="d-flex gap g-2">
                                    <div className="gap-col">
                                        <Button variant="primary" onClick={handleCloseModal}>Add User</Button>
                                    </div>
                                    <div className="gap-col">
                                        <button type="button" className="border-0 btn" onClick={handleCloseModal}>Discard</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </Layout>
    )
}

export default InstituteRequests;