import React, { useContext } from "react";


import {
  Card,
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
import Navbar from "../Navbar";
import AllQuestionHeading from "../../answerscomponents/AllQuestionHeading";
import MainQuestions from "../../answerscomponents/MainQuestions";
import QuestionAudio from "../../answerscomponents/QuestionAudio";
import QuestionReadAloud from "../../answerscomponents/QuestionReadAloud";
import QuestionDescribeImage from "../../answerscomponents/QuestionDescribeImage";
import QuestionAudioAnswer from "../../answerscomponents/QuestionAudioAnswer";
import QuestionParagraph from "../../answerscomponents/QuestionParagraph";
import SampleAnswerReading from "../../answerscomponents/SampleAnswerReading";
import SampleAnswerParagraph from "../../answerscomponents/SampleAnswerParagraph";
import SampleAnswerCheckbox from "../../answerscomponents/SampleAnswerCheckbox";
import SampleAnswerRadioBtn from "../../answerscomponents/SampleAnswerRadioBtn";
import SampleAnswerReOrder from "../../answerscomponents/SampleAnswerReOrder";
import YourAnswerAudio from "../../answerscomponents/YourAnswerAudio";
import YourAnswerWriting from "../../answerscomponents/YourAnswerWriting";
import YourAnswerCheckbox from "../../answerscomponents/YourAnswerCheckbox";
import YourAnswerRadioBtn from "../../answerscomponents/YourAnswerRadioBtn";
import YourAnswerSpeaking from "../../answerscomponents/YourAnswerSpeaking";
import YourAnswerWFD from "../../answerscomponents/YourAnswerWFD";
import YourAnswerReading from "../../answerscomponents/YourAnswerReading";
import YourAnswerReOrder from "../../answerscomponents/YourAnswerReOrder";
import ResultHeading from "../../answerscomponents/MockTestResult/ResultHeading";
import ResultFIBRW from "../../answerscomponents/MockTestResult/ResultFIBRW";
import ResultChoice from "../../answerscomponents/MockTestResult/ResultChoice";
import ResultYourAnswer from "../../answerscomponents/MockTestResult/ResultYourAnswer";
import ResultSampleAnswer from "../../answerscomponents/MockTestResult/ResultSampleAnswer";
import ResultSampleAnswerNumber1 from "../../answerscomponents/MockTestResult/ResultSampleAnswerNumber";
import QuestionMC from "../../answerscomponents/QuestionMC";
import StudentResultContext from "../../../../context/StudentResultContext/StudentResultContext";
import ResultSubmitAnswer from "../../answerscomponents/MockTestResult/ResultSubmitAnswer";
import ResultSubmitAnswerNumber from "../../answerscomponents/MockTestResult/ResultSubmitAnswerNumber";
import ResultSampleAnswerBooleanString from "../../answerscomponents/MockTestResult/ResultSampleAnswerBooleanString";
import ResultHIW from "../../answerscomponents/MockTestResult/ResultHIW";
import ResultFIBRWNew from "../../answerscomponents/MockTestResult/ResultFIBRWNew";
import ResultSampleAnswerWriting from "../../answerscomponents/MockTestResult/ResultSampleAnswerWriting ";
import ResultYourAnswerReadAloud from "../../answerscomponents/MockTestResult/ResultYourAnswerReadAloud";
import VolumeUI from "../../../exam/PTE/Istructions/ExamComponents/VolumeUI";
import { ProgressBar } from 'react-bootstrap';
import ResultYourAnswerReadingModal from "../../answerscomponents/MockTestResult/ResultYourAnswerReadingModal";
function AnswerTab({
  Col,
  Card,
  Row,
  Tab,
  Block,
  speaking_data,
  reading_data,
  writing_data,
  listening_data,

}) {


  const { studentResult } = useContext(StudentResultContext)

  console.log('studentResult4324 :>> ', studentResult);
  let readAloud = studentResult?.questions?.filter((item) =>
    item?.module_name == "read_alouds")

  let readAloudAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "read_alouds")
  console.log('studentResultreadAloud :>> ', readAloud?.[0]?.id);



  let repeatQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "repeat_sentences")

  let repeateAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "repeat_sentences")
  console.log('repeatQuest :>> ', repeatQuest?.length > 0);




  let describeQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "describe_images")

  let describeAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "describe_images")
  console.log('studentResult :>> ', readAloud, readAloudAns);

  let retellQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "retell_lectures")

  let retellAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "retell_lectures")
  console.log('studentResult :>> ', readAloud, readAloudAns);


  let answer_questionsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "answer_questions")

  let answer_questionsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "answer_questions")
  console.log('studentResult :>> ', readAloud, readAloudAns);



  let summarizeQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "swts")

  let summarizeAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "swts")
  console.log('summarizeAns :>> ', summarizeAns);


  let essaysQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "essays")

  let essaysAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "essays")
  console.log('studentResult :>> ', readAloud, readAloudAns);


  let fib_wrQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "fib_wr")

  let fib_wrAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "fib_wr")


  console.log('fib_wrAnswe43 :>> ', fib_wrAns);


  let fib_rdQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "fib_rd")

  let fib_rdAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "fib_rd")






  let fib_roQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "ro")
  console.log('fib_wrQuest :>> ', fib_roQuest);

  let fib_roAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "ro")



  let fib_r_mcmQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "r_mcm")
  console.log('fib_r_mcmQuest :>> ', fib_r_mcmQuest);

  let fib_r_mcmAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "r_mcm")


  let fib_r_mcsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "r_mcs")
  console.log('fib_r_mcmQuest :>> ', fib_r_mcmQuest);

  let fib_r_mcsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "r_mcs")


  let sstsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "ssts")


  let sstsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "ssts")
  console.log('studentResult :>> ', readAloud, readAloudAns);




  let mcmQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "l_mcm")


  let mcmAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "l_mcm")
  console.log('studentResult :>> ', readAloud, readAloudAns);



  let mcsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "l_mcs")


  let mcsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "l_mcs")


  let l_fibQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "l_fib")


  let l_fibAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "l_fib")

  const l_fibAnsCon = l_fibAns?.[0]?.answer?.split(",")?.filter(Boolean)
  console.log('l_fibAnsCon :>> ', l_fibAnsCon);


  let l_hcsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "l_hcs")


  let l_hcsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "l_hcs")


  let l_smwQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "l_smw")


  let l_smwAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "l_smw")


  let hiwsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "hiws")


  let hiwsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "hiws")

  let wfdsAns = studentResult?.answers?.filter((item) =>
    item?.submodule == "wfds")

  let wfdsQuest = studentResult?.questions?.filter((item) =>
    item?.module_name == "wfds")

  return (
    <>
      <Block>
        <Card>
          <Card.Body>
            <Tab.Container id="pills-answers" defaultActiveKey="answers-speaking">
              {/* <Navbar
            speaking_data={speaking_data}
            reading_data={reading_data}
            writing_data={writing_data}
            listening_data={listening_data}
          /> */}
              <Nav
                variant="pills"
                className="mb-3 d-flex justify-content-between student-result p-3 bg-white"
              >
                <Nav.Item>
                  <Nav.Link
                    className="d-flex justify-content-center align-items-center gap-2"
                    eventKey="answers-speaking"
                  >
                    <img
                      src="/images/analytics-speaking.svg"
                      style={{ width: "26px", height: "26px" }}
                      className="me-2"
                    ></img>
                    Speaking{" "}
                    <Badge bg="hidanger" className="ms-1 text-white fw-bold">
                      10
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="d-flex justify-content-center align-items-center gap-2"
                    eventKey="answers-writing"
                  >
                    <img
                      src="/images/analytics-writing.svg"
                      style={{ width: "26px", height: "26px" }}
                      className="me-2"
                    ></img>
                    Writing{" "}
                    <Badge bg="hidanger" className="ms-1 text-white fw-bold">
                      10
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="d-flex justify-content-center align-items-center gap-2"
                    eventKey="answers-reading"
                  >
                    <img
                      src="/images/analytics-reading.svg"
                      style={{ width: "26px", height: "26px" }}
                      className="me-2"
                    ></img>
                    Reading{" "}
                    <Badge bg="hidanger" className="ms-1 text-white fw-bold">
                      10
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="d-flex justify-content-center align-items-center gap-2"
                    eventKey="answers-listening"
                  >
                    <img
                      src="/images/analytics-listening.svg"
                      style={{ width: "26px", height: "26px" }}
                      className="me-2"
                    ></img>
                    Listening{" "}
                    <Badge bg="hidanger" className="ms-1 text-white fw-bold">
                      10
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="answers-speaking">
                  <Row className="g-gs">
                    {/* Read Aloud */}
                    {readAloud?.length > 0 &&
                      <>
                        <Col md="12">
                          {
                            readAloud?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                                <QuestionReadAloud question={item?.question_content} />
                                {
                                  readAloudAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      //  return <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} />
                                      //  return <ResultYourAnswerReadAloud ans={ansItem?.answer} audioUrl={ansItem?.audio_url} />
                                      return <ResultYourAnswerReadAloud moduleName={item?.module_name} api_responseAll={ansItem?.api_response} audioUrl={ansItem?.audio_url} />

                                    }
                                  })
                                }

                              </>
                            )
                          }
                        </Col>
                      </>
                    }

                    {/* Repeat Sentence */}
                    {repeatQuest?.length > 0 &&
                      <>
                        <Col md="12">
                          {

                            repeatQuest?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />


                                <QuestionReadAloud questionAudio={item?.question_content_audio} />
                                {
                                  repeateAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      //  return <ResultYourAnswer  />
                                      return <ResultYourAnswerReadAloud moduleName={item?.module_name} api_responseAll={ansItem?.api_response} audioUrl={ansItem?.audio_url} />

                                      //  return <ResultYourAnswerReadAloud api_responseAll={repeateAns?.[0]?.api_response} audioUrl={repeateAns?.[0]?.audio_url} /> 
                                    }
                                  })
                                }

                              </>
                            )

                          }
                        </Col>
                      </>
                    }

                    {/* <Col md="12">
                      <h2>Describe Image</h2>
                      <ResultHeading  />
                      <QuestionDescribeImage />
                      <ResultSampleAnswer />
                      <ResultYourAnswer />
                    </Col>  */}

                    {describeQuest?.length > 0 &&
                      <>
                        <Col md="12">
                          {

                            describeQuest?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />


                                <QuestionDescribeImage img={item?.question_content_image} />
                                {
                                  describeAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      //  return <ResultYourAnswer  />

                                      //  return <ResultYourAnswerReadAloud api_responseAll={repeateAns?.[0]?.api_response} audioUrl={repeateAns?.[0]?.audio_url} /> 
                                    }
                                  })
                                }

                              </>
                            )

                          }
                        </Col>
                      </>
                    }





                    {/* { describeQuest?.length>0 &&
                    <>
                    <h2>Describe Image</h2>
                    <Col md="12">
                      {
                        
                        describeQuest?.map((item,index)=>
                         
                         <>
              
                          <ResultHeading  tags={item?.tags} index={index+1} />
            
                      <QuestionReadAloud  question={item?.question_content}/>
                          {
                          describeAns?.map((ansItem)=>
                          
                          {
                            if(ansItem?.qst_id == item._id){

                             return <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} />
                            }
                          })
                        }

                       </>
                    )
                  
                   }
                    </Col>
                    </>
} */}

                    {/* Re-Tell Lectures */}
                    {/* <Col md="12">
                      <h2>Re-Tell Lectures</h2>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <ResultYourAnswer />
                    </Col> */}


                    {retellQuest?.length > 0 &&
                      <>
                        <Col md="12">
                          {

                            retellQuest?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />




                                <video
                                  src={item?.question_content_video}
                                  width="200"
                                  height="155"
                                  controls={true}
                                />
                                {/* <VolumeUI></VolumeUI>
      <ProgressBar className='progress-ui w-100' variant='success' now={25} /> */}


                                {/* <div className='d-flex flex-column align-items-center gap-3'>
      <video className='desc-image'
        src={item?.question_content_video}
        autoPlay
        muted // Mute the video for autoplay in some browsers
        playsInline // Play inline for iOS
      >
        <source src={item?.question_content_video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <VolumeUI></VolumeUI>
      <ProgressBar className='progress-ui w-100' variant='success' now={25} />
    </div> */}
                                {
                                  retellAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      //  return <ResultYourAnswer  />

                                      //  return <ResultYourAnswerReadAloud api_responseAll={repeateAns?.[0]?.api_response} audioUrl={repeateAns?.[0]?.audio_url} /> 
                                      return <ResultYourAnswerReadAloud moduleName={item?.module_name} api_responseAll={ansItem?.api_response} audioUrl={ansItem?.audio_url} />
                                    }
                                  })
                                }

                              </>
                            )

                          }
                        </Col>
                      </>
                    }
                    {/* Answer Short Question */}
                    {/* <Col md="12">
                      <h2>Answer Short Question</h2>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <ResultSampleAnswer />
                      <ResultYourAnswer />
                    </Col> */}



                    {answer_questionsQuest?.length > 0 &&
                      <>
                        <Col md="12">
                          {

                            answer_questionsQuest?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />


                                <QuestionReadAloud questionAudio={item?.question_content_audio} />
                                {
                                  answer_questionsAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      return <ResultYourAnswerReadAloud moduleName={item?.module_name} api_responseAll={ansItem?.api_response} audioUrl={ansItem?.audio_url} />

                                      //  return <ResultYourAnswerReadAloud api_responseAll={repeateAns?.[0]?.api_response} audioUrl={repeateAns?.[0]?.audio_url} /> 
                                    }
                                  })
                                }
                              </>
                            )

                          }
                        </Col>
                      </>
                    }

                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="answers-writing">
                  <Row className="g-gs">
                    {/* Summarize Writing Text */}
                    {/* <Col md="12">
                      <h2>Summarize Writing Text</h2>
                      <ResultHeading />
                      <QuestionReadAloud />
                      <ResultYourAnswer />
                    </Col> */}

                    {summarizeQuest?.length > 0 &&
                      <>
                        <Col md="12">
                          {

                            summarizeQuest?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                                <QuestionReadAloud question={item?.question_content} />
                                {
                                  summarizeAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      return <>
                                        {
                                          item?.sample_answer &&
                                          <ResultSampleAnswerWriting fib_wrQuest={item?.sample_answer} />
                                        }
                                        {/* <ResultYourAnswer api_responseAll={summarizeAns?.[0]?.api_response} audioUrl={summarizeAns?.[0]?.audio_url} /> */}
                                        <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} moduleName={item?.module_name} api_responseAll={ansItem?.api_response} />
                                        {/* return <ResultYourAnswerReadAloud  moduleName={item?.module_name} api_responseAll={ansItem?.api_response} audioUrl={ansItem?.audio_url} />  */}
                                        {/* <ResultYourAnswerReadAloud api_responseAll={summarizeAns?.[0]?.api_response} audioUrl={summarizeAns?.[0]?.audio_url} /> */}
                                      </>

                                    }
                                  })
                                }

                              </>
                            )

                          }
                        </Col>
                      </>
                    }

                    {/* Writing Essay */}
                    {/* <Col md="12">
                      <h2>Writing Essay</h2>
                      <ResultHeading />
                      <QuestionReadAloud />
                      <ResultYourAnswer />
                    </Col> */}

                    {essaysQuest?.length > 0 &&
                      <>
                        <Col md="12">
                          {

                            essaysQuest?.map((item, index) =>

                              <>

                                <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                                <QuestionReadAloud question={item?.question_content} />
                                {
                                  essaysAns?.map((ansItem) => {
                                    if (ansItem?.qst_id == item._id) {

                                      return <>
                                        {
                                          item?.sample_answer &&
                                          <ResultSampleAnswerWriting fib_wrQuest={item?.sample_answer} />
                                        }
                                        {/* <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} /> */}
                                        <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} moduleName={item?.module_name} api_responseAll={ansItem?.api_response} />
                                      </>
                                    }
                                  })
                                }

                              </>
                            )

                          }
                        </Col>
                      </>
                    }

                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="answers-reading">
                  <Row className="g-gs">

                    {/* {
                        readAloud?.map((item,index)=>
                         
                         <>
              
                          <ResultHeading  tags={item?.tags} index={index+1} />
            
                      <QuestionReadAloud  question={item?.question_content}/>
                          {
                          readAloudAns?.map((ansItem)=>
                          
                          {
                            if(ansItem?.qst_id == item._id){

                             return <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} />
                            }
                          })
                        }

                       </>
                    )
                  } */}

                    {/* Reading & Writing: Fill In The Blanks */}
                    {
                      fib_wrQuest?.length > 0 &&
                      fib_wrQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            {
                              fib_wrAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {
                                  console.log('ansItem :>> ', ansItem);
                                  return (<><ResultFIBRW fib_wrQuest={item} ansItem={ansItem} />
                                  </>)
                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                            {/* <ResultYourAnswerReadingModal /> */}
                          </Col>
                        </>)
                    }


                    {/* { summarizeQuest?.length>0 &&
                    <>
                    <Col md="12">
                    <h2>Summarize Writing Text</h2>
                     {
                        
                        summarizeQuest?.map((item,index)=>
                           
                           <>
                
                            <ResultHeading  tags={item?.tags} index={index+1} />
              
                        <QuestionReadAloud  question={item?.question_content}/>
                            {
                            summarizeAns?.map((ansItem)=>
                            
                            {
                              if(ansItem?.qst_id == item._id){
  
                               return <ResultYourAnswer ans={ansItem?.answer} audioUrl={ansItem?.audio_url} />
                              }
                            })
                          }
  
                         </>
                      )
                    
                     }
                    </Col>
                    </>
} */}


                    {/* Fill In The Blanks Reading*/}


                    {
                      fib_rdQuest?.length > 0 &&
                      fib_rdQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />
                            {
                              fib_rdAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {

                                  return (<><ResultFIBRW fib_wrQuest={item} ansItem={ansItem} />
                                    <ResultChoice fib_wrQuest={item} /></>)

                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                          </Col>
                        </>)
                    }


                    {/* Reading Re-Order*/}

                    {
                      fib_roQuest?.length > 0 &&
                      fib_roQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />
                            {
                              fib_roAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {

                                  return (<> <ResultFIBRW fib_wrQuest={item} ansItem={ansItem} />
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswerNumber1 fib_wrQuest={item} />
                                    <ResultSubmitAnswerNumber fib_wrAns={ansItem} />
                                  </>)
                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                          </Col>
                        </>)
                    }


                    {/* Multiple Choice, Choose Multiple Answer*/}




                    {
                      fib_r_mcmQuest?.length > 0 &&
                      fib_r_mcmQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />
                            {
                              fib_r_mcmAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {

                                  return (<>   <ResultFIBRW fib_wrQuest={item} ansItem={ansItem} />
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswer fib_wrQuest={item} />
                                    <ResultSubmitAnswer fib_wrAns={ansItem} /></>)
                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                          </Col>
                        </>)
                    }

                    {/* Multiple Choice, Choose Single Answer*/}



                    {
                      fib_r_mcsQuest?.length > 0 &&
                      fib_r_mcsQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />
                            {
                              fib_r_mcsAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {

                                  return (<>    <ResultFIBRW fib_wrQuest={item} ansItem={ansItem} />
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswer fib_wrQuest={item} />
                                    <ResultSubmitAnswer fib_wrAns={ansItem} /></>)
                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                          </Col>
                        </>)
                    }


                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="answers-listening">
                  <Row className="g-gs">
                    {/* Summarize Spoken Text */}
                    {/* <Col md="12">
                      <h3>SST</h3>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <ResultYourAnswer />
                    </Col> */}


                    {
                      sstsQuest?.length > 0 &&
                      sstsQuest?.map((item, index) =>

                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              sstsAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item._id) {

                                  return <ResultYourAnswer ans={ansItem?.answer} moduleName={item?.module_name} api_responseAll={ansItem?.api_response} />

                                }
                              })
                            }
                          </Col>
                        </>
                      )
                    }

                    {/* Listening: Multiple Choice, Choose Multiple Answer */}


                    {
                      mcmQuest?.length > 0 &&
                      mcmQuest?.map((item, index) =>

                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              mcmAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item._id) {



                                  return (<>
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswer fib_wrQuest={item} />
                                    <ResultSubmitAnswer fib_wrAns={ansItem} /></>)
                                }
                              })
                            }
                          </Col>
                        </>
                      )
                    }

                    {/* Listening: Multiple Choice, Choose Single Answer */}
                    {/* <Col md="12">
                      <h3>MCS-L</h3>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <QuestionMC />
                      <ResultChoice />
                      <ResultSampleAnswer />
                      <ResultYourAnswer />
                    </Col> */}

                    {
                      mcsQuest?.length > 0 &&
                      mcsQuest?.map((item, index) =>

                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              mcsAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item._id) {



                                  return (<>
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswer fib_wrQuest={item} />
                                    <ResultSubmitAnswer fib_wrAns={ansItem} /></>)
                                }
                              })
                            }
                          </Col>
                        </>
                      )
                    }

                    {/* Listening: Fill In The Blanks */}
                    {/* <Col md="12">
                      <h3>FIB-L</h3>
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <ResultFIBRW />
                      <ResultYourAnswer />
                    </Col> */}

                    {
                      l_fibQuest?.length > 0 &&
                      l_fibQuest?.map((item, index) =>

                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              l_fibAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {
                                  console.log('ansItem :>> ', ansItem);
                                  return <ResultFIBRW fib_wrQuest={item} ansItem={ansItem} />

                                }
                              })
                            }
                          </Col>
                        </>
                      )
                    }

                    {/* Highlight Correct Summary */}
                    {/* <Col md="12">
                      <h3>HCS</h3>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <QuestionMC />
                      <ResultChoice />
                      <ResultSampleAnswer />
                      <ResultYourAnswer />
                    </Col> */}

                    {
                      l_hcsQuest?.length > 0 &&
                      l_hcsQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />
                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              l_hcsAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {

                                  return (<>
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswerBooleanString fib_wrQuest={item} />
                                    <ResultSubmitAnswer fib_wrAns={ansItem} /></>)

                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                          </Col>
                        </>)
                    }

                    {/* Select Missing Word */}
                    {/* <Col md="12">
                      <h3>SMW</h3>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <QuestionMC />
                      <ResultChoice />
                      <ResultSampleAnswer />
                      <ResultYourAnswer />
                    </Col> */}

                    {
                      l_smwQuest?.length > 0 &&
                      l_smwQuest?.map((item, index) =>
                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />
                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              l_smwAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {

                                  return (<>
                                    <ResultChoice fib_wrQuest={item} />
                                    <ResultSampleAnswerBooleanString fib_wrQuest={item} />
                                    <ResultSubmitAnswer fib_wrAns={ansItem} /></>)

                                }
                              })
                            }
                            {/* <ResultYourAnswer /> */}
                          </Col>
                        </>)
                    }

                    {/* Highlight Incorrect Words */}
                    {/* <Col md="12">
                      <h3>HIW</h3>
                      <ResultHeading />
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <ResultSampleAnswer />
                      <ResultYourAnswer />
                    </Col> */}

                    {
                      hiwsQuest?.length > 0 &&
                      hiwsQuest?.map((item, index) =>

                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              hiwsAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item?._id) {
                                  console.log('ansItem :>> ', ansItem);
                                  return <ResultHIW fib_wrQuest={item} ansItem={ansItem} />

                                }
                              })
                            }
                          </Col>
                        </>
                      )
                    }

                    {/* Write From Dictation */}
                    {/* <Col md="12">
                      <h3>WFD</h3>
                      <div className="p-3">
                        <audio src="/images/audio.wav" controls ></audio>
                      </div>
                      <QuestionReadAloud />
                      <ResultYourAnswer />
                    </Col> */}

                    {
                      wfdsQuest?.length > 0 &&
                      wfdsQuest?.map((item, index) =>

                        <>
                          <Col md="12">
                            <ResultHeading tags={item?.tags} index={index + 1} questionId={item?.id} moduleName={item?.module_name} />

                            <QuestionReadAloud questionAudio={item?.question_content_audio} />
                            {
                              wfdsAns?.map((ansItem) => {
                                if (ansItem?.qst_id == item._id) {



                                  return (<>

                                    <ResultSampleAnswerNumber1 fib_wrQuest={item} />
                                    <ResultSubmitAnswerNumber fib_wrAns={ansItem} /></>)
                                }
                              })
                            }
                          </Col>
                        </>
                      )
                    }
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Block>
    </>
  );
}

export default AnswerTab;
