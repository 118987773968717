import React, { useContext, useEffect } from 'react';
import {
    Card,
    Row,
    Col,
    ProgressBar,
    Spinner,
    Table,
    Form,
    Button,
    Modal,
  } from "react-bootstrap";
  import NoUiSlider from "../../../../components/Form/NoUiSlider";
import ModalContext from '../../../../context/ModalContext';
function ListeningInstruction({instCount}) {
  let { popUpType, setPopUpType, studentContextData, setStudentContextData } =
  useContext(ModalContext);
useEffect(() => {

  if (instCount == 0) {
    setStudentContextData((prevState) => ({
      ...prevState,
      timeRamaining: {
        ...prevState.timeRamaining,
        status: true,
        total_time: "02:00",
      },
    }));
  }
 if(instCount>2){
  setStudentContextData((prevState) => ({
    ...prevState,
    timeRamaining: {
      ...prevState.timeRamaining,
      status: false,
      total_time: "00:00",
    },
  }));
 }
}, [instCount]);
console.log("studentContextData",studentContextData)
  return (
    <>
     {( instCount == 0 && (
            <Row className="my-3">
              <Col md="12" className="text-examheadtext">
                <h4 className="text-examheadtext">Part 3: Listening</h4>
                <ul className="mx-3" style={{ listStyleType: "disc" }}>
                  <li>You are about to begin Part 3 of the test</li>
                  <li>
                    Check your headset is on and when you are ready to begin,
                    press Next (N)
                  </li>
                </ul>
                <img
                  src="/images/listening-instructions.png"
                  className="timer-img"
                ></img>
              </Col>
            </Row>
          )) ||
            (instCount == 1 &&  (
              <Row className="my-3">
                <Col md="12" className="text-examheadtext">
                  <h4 className="text-examheadtext">Part 3: Listening</h4>
                  <ul className="mx-3" style={{ listStyleType: "disc" }}>
                    <li>
                      In this part you will be tested mainly on your listening
                      skils
                    </li>
                    <li>
                      Read the instructions to each question carefully and
                      answer as directed{" "}
                    </li>
                  </ul>
                  <Table
                    responsive
                    bordered
                    striped
                    hover
                    className="small my-3 w-50"
                  >
                    <thead className="bg-primary-soft">
                      <tr>
                        <th colSpan="3">Part 3: Listening</th>
                      </tr>
                      <tr>
                        <th className="text-examheadtext">Section</th>
                        <th className="text-examheadtext">Item type</th>
                        <th className="text-examheadtext">Time Allowed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-examheadtext">Section 1</td>
                        <td className="text-examheadtext">
                          Summarize spoken text
                        </td>
                        <td className="text-examheadtext">10 or 20 minutes</td>
                      </tr>
                      <tr>
                        <td rowSpan="7">Section 2</td>
                        <td className="text-examheadtext">
                          Multiple-choice, choose multiple answers
                        </td>
                        <td rowSpan="7" className="text-examheadtext">16 minutes</td>
                      </tr>
                      <tr>
                        <td className="text-examheadtext">
                          Fill in the blanks
                        </td>
                        
                      </tr>
                      <tr>
                        <td className="text-examheadtext">
                          Highlight correct summary
                        </td>
                        
                      </tr>
                      <tr>
                        <td className="text-examheadtext">
                          Multiple-choice, choose single answer
                        </td>
                        
                      </tr>
                      <tr>
                        <td className="text-examheadtext">
                          Select Missing word
                        </td>
                        
                      </tr>
                      <tr>
                        <td className="text-examheadtext">
                          Highlight incorrect words
                        </td>
                        
                      </tr>
                      <tr>
                        <td className="text-examheadtext">
                          Write from dictation
                        </td>
                        
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )) ||
            (instCount == 2 &&  (
              <Row className="my-3">
                <Col md="12" className="text-examheadtext">
                  <h4 className="text-examheadtext">Listening Instructions</h4>
                  <p>
                    This section contains audio or video clips which will paly
                    automatically. You will hear each clip only once.{" "}
                  </p>
                  <p>1. A countdown shows when the audio clip will start.</p>
                  <div className="d-flex justify-content-start">
                  <div className="audio-card my-3 bg-examrecord">
                  <p className="text-center mb-0">Recorded Answer</p>
                  <p className="d-flex flex-column">
                    <p className="mb-0">Current Status :</p>
                    <p className="mb-0">Beginning in 5 seconds.</p>
                  </p>
                  <ProgressBar className="progress-ui" variant="hisuccess" now={0} style={{ height: "18px" }} />
                </div>
              </div>
                  <p>
                    2. The bar shows the progress of the audio. You can adjust
                    the volume but you cannot or replay the clip.
                  </p>
                  <div className="d-flex justify-content-start gap-5">
                  <div className="audio-card my-3">
                  {/* status Beginning in 4s, Playing.., Completed  */}
                  <p>Status : Playing....</p>
                  <div className='audio-ui'>
                    <p className="w-100 text-secondary exam-vol">
                      <span className='w-25'>Volume</span>
                      <input
                        className="w-100"
                        type="range"
                        value="60"
                        disabled
                        // onChange={(e) => handleVolumeChange(e.target.value)}
                        max="100"
                        step="1"
                        id="volume-control"
                      />
                    </p>
                    <ProgressBar className='progress-ui' now={30} variant="hisuccess" style={{ height: "18px" }} />
                  </div>
                </div>
                <div className="audio-card my-3">
                  {/* status Beginning in 4s, Playing.., Completed  */}
                  <p>Status : Completed</p>
                  <div className='audio-ui'>
                    <p className="w-100 text-secondary exam-vol">
                      <span className='w-25'>Volume</span>
                      <input
                        className="w-100"
                        type="range"
                        value="60"
                        disabled
                        // onChange={(e) => handleVolumeChange(e.target.value)}
                        max="100"
                        step="1"
                        id="volume-control"
                      />
                    </p>
                    <ProgressBar className='progress-ui' now={100} variant="hisuccess" style={{ height: "18px" }} />
                  </div>
                </div>
              </div>
                </Col>
              </Row>
            ))}
    </>
  );
}

export default ListeningInstruction;
