import {  Card,
  Button,
  Modal,
  Form, } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import InstituteDataTable from "../admin/institutepages/InstituteDataTable";
import { ViewTestList } from "../admin/institutepages/InstituteData";
import { useEffect, useState } from "react";
import AdminApi from "../../api/admin";
import { LinkList } from "../../components";
import { Icon, Select } from "../../components";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
function ViewTest() {
  let api = new AdminApi();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const[deleted,setDeleted]=useState({
    status:false,
    id:null
  })
  useEffect(() => {
    getViewTest();
  }, []);

  useEffect(() => {
    setTotalRows(filteredData.length);
  }, [filteredData]);

  const getViewTest = async () => {
    await api
      .get_paper_admin()
      .then((res) => {
        if (res?.question.length > 0) {
          setData(res.question);
          setFilteredData(res.question)
          setTotalRows(res?.question?.length)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("data", data);
  const deleteTest = async (id) => {
    await api
      .deleteTest(id)
      .then((res) => {
        if (res.status) {
          if(res.data){
            setData(res.data)
            setFilteredData(res.data)
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [edit_data, setEditData] = useState({
    id: null,
    test_name: null,
  });
  const editTest = async (id) => {
    await api
    .editTest(edit_data)
    .then((res) => {
      if (res.status) {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Done",
            text: res?.message,
          });
          setEditData({...edit_data,id:null,test_name:null})
          if(res.data.length){
            setData(res.data)
            setFilteredData(res.data)
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
          setEditData({...edit_data,id:null,test_name:null})
        }
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
  };
  let navigate = useNavigate();

  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const ViewTestColumn = [
    // {
    //     name: <Form.Check className="form-check" id="check-all" />,
    //     cell: (row) => (
    //         <div className="text-start w-100">
    //             <Form.Check className="form-check" />
    //         </div>
    //     ),
    //     sortable: false,
    //     hide: "md",
    // },
    {
      name: "ID",
      grow: "1",
      selector: (row) => row.sno,
      cell: (row) => <span className="text-middark">{row.id}</span>,
      sortable: true,
      // hide: "md",
    },
    {
      name: "Test Name",
      grow: "2",
      selector: (row) => row.sno,
      cell: (row) => (
        <>
          {row._id == edit_data.id ? (
            <div className="form-control-wrap">
              <Form.Control
                className=""
                name="test_name"
                type="text"
                style={{height:"30px"}}
                id="name"
                onChange={(e) =>
                  setEditData({ ...edit_data, test_name: e.target.value })
                }
                value={edit_data?.test_name}
                // placeholder="Your Name"
              />
            </div>
          ) : (
            <span className="text-middark">{row?.test_name}</span>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "Pattern Name",
      grow: "2",
      selector: (row) => row.sno,
      cell: (row) => (
        <span className="text-middark">{row.pattern_id?.pattern}</span>
      ),
      sortable: true,
    },
    {
      name: "Speaking",
      grow: "1",
      selector: (row) => row.id,
      cell: (row) => (
        <span className="text-middark">
          {row.pattern_id?.speaking?.total_question}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Reading",
      grow: "1",
      selector: (row) => row.tag,
      cell: (row) => (
        <span className="text-middark">
          {row.pattern_id?.reading?.total_question}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Writing",
      grow: "1",
      selector: (row) => row.tag,
      cell: (row) => (
        <span className="text-middark">
          {row.pattern_id?.writing?.total_question}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Listening",
      grow: "1",
      selector: (row) => row.tag,
      cell: (row) => (
        <span className="text-middark">
          {row.pattern_id?.listening?.total_question}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Type",
      grow: "3",
      selector: (row) => row.type,
      cell: (row) => (
        <span className="text-middark">{row.type.toUpperCase()}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="text-end w-100">
          <span onClick={()=>navigate(`/exams_question/${row?._id}`)} className="edit-que">Edit Questions</span>
          <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
            
            {row._id == edit_data.id ? (
              <div className="d-flex">
                <div className="del-btn" onClick={()=>editTest()}><Icon name="save"></Icon></div>
               <div className="del-btn" onClick={()=>setEditData({...edit_data,id:null,test_name:null})} > <Icon name="cross"></Icon></div>
              </div>
            ) : (
              <>
               <div
                  className="del-btn"
                  onClick={() => {
                    setEditData({ ...edit_data, id: row._id,test_name:row?.test_name });
                  }}
                >
                  <Icon name="edit"></Icon>
                </div>
                <div className="del-btn" onClick={() => setDeleted({status:true,id:row._id})}>
                  <Icon name="trash"></Icon>
                </div>
              </>
            )}

            {/* </LinkListItem> */}
          </LinkList>
        </div>
      ),
      sortable: false,
    },
  ];
  console.log("data", data);

  


  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">View Test List</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>



     

      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
          
          <div className="data-table-action-wrap mb-2">
          <div className="data-table-select">
                <div className="form-control-wrap">
                  <Form.Select
                    style={{ width: "170px" }}
                    removeItemButton
                    onChange={(e) => {
                      setFilteredData(e.target.value == "" ? data : data.filter((item) => item?.pattern_id?.pattern_type == e.target.value))
                    }}
                    name="module_name"
                  >
                    <option value="">All</option>
                    <option value="isMock">Mock test</option>
                    <option value="isSection">Section Type</option>
                    <option value="isQuestion">Question Type</option>
                  </Form.Select>
                </div>
                <span className="text-secondary mx-1">Test Type</span>

                <select
                  className="form-select"
                  onChange={(e) => setShowItemPerPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
                <span className="text-secondary mx-1">Show</span>
              </div>
              </div>
              

<div className="data-table-wrapper">
<DataTable
                    columns={ViewTestColumn}
                  
                    data={filteredData}
                    noDataComponent={
                      <div className="p-2">There are no records found.</div>
                    }
                    sortIcon={<div className="data-table-sorter"></div>}
                    pagination
                    paginationTotalRows={totalRows}
                    onChangePage={setCurrentPage}

                    paginationComponent={({
                      rowsPerPage,
                      rowCount,
                      onChangePage,
                      onChangeRowsPerPage,
                      currentPage,
                    }) => (
                      <div className="data-table-bottom">
                        <DataTablePagination
                          showItemPerPage={showItemPerPage}
                          itemPerPage={rowsPerPage}
                          totalItems={rowCount}
                          paginate={onChangePage}
                          currentPage={currentPage}
                          onChangeRowsPerPage={onChangeRowsPerPage}
                          setShowItemPerPage={setShowItemPerPage}
                        />
                      </div>
                    )}
                  />
                  </div>
          </Card.Body>
        </Card>
      </Block>
      <Modal show={deleted?.status} onClick={()=>setDeleted({...deleted,status:false,id:null})} >
              <div className="modal-content">
                  <div className="modal-body p-5 text-center">
                      <h3>Are you sure want to delete ?</h3>
                      <ul className="d-flex gap g-3 justify-content-center pt-1">
                          <li><Button variant="success" onClick={()=>deleteTest(deleted?.id)} >Yes Delete it!</Button></li>
                          <li><Button variant="danger" className="btn-soft" onClick={()=>setDeleted({...deleted,status:false,id:null})} >Cancel</Button></li>
                      </ul>
                  </div>
                  <button type="button" className="btn-close position-absolute top-0 end-0 p-3"  aria-label="Close"></button>
              </div>
        </Modal>
    </Layout>
  );
}

export default ViewTest;
