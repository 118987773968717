
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Toast, ToastContainer } from "react-bootstrap";
import { toPng } from 'html-to-image';

import QRCodeStyling from "qr-code-styling";
import { MediaGroup, Media, MediaText, Image } from '../../components';
import Context from "../../context";









const QrCode = ({setShowQr,showQr,link,image}) => {

  let { domainData } = useContext(Context);
  domainData = JSON.parse(domainData);

  console.log('image323 :>> ', image);
  

  const qrCode = new QRCodeStyling({
    width: 500,
    height: 500,
    // image: domainData?.inst_profile?.business_logo,
    image: image,
  
      dotsOptions: {
        color: domainData?.inst_profile?.color_code,
        type: "rounded"
      },
  
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 20
    }
  });


  const [show, setShow] = useState(false);
  
  

  console.log(link,"linknn");

  


  const [url, setUrl] = useState(link);
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);


  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt
    });
  };


  const handleCloseQr = () => {
    if(setShowQr != undefined){

      setShowQr(false);
    }
    
  };

  const copyToClipboard = async () => {
    console.log('ref.current :>> ', ref.current);
    if (ref.current === null) {
      return;
    }

    try {
      const dataUrl = await toPng(ref.current);
      const response = await fetch(dataUrl);
      const blob = await response.blob();
    
    console.log(navigator.clipboard && navigator.clipboard.write,"item123");
    if (navigator.clipboard && navigator.clipboard.write) {  
      const item = new ClipboardItem({ 'image/png': blob });

      await navigator.clipboard.write([item]);

     setShow(true)
     
    } else {
      console.warn('Clipboard API not supported, cannot copy image.');
    }
  }
    
    catch (error) {
      console.error('Failed to copy QR code to clipboard:', error);
    }
  };


  return (
    <div>
    <Row style={{position:"relative", zIndex:"9999",}}>
      <Col xs={6}>
        {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
         
            <strong className="me-auto">QrCode Copied</strong>
         
          </Toast.Header>
        
        </Toast> */}


        <ToastContainer className="p-3" position="top-end" containerPosition="fixed">
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className="custom-toast">
                    <Toast.Header>
                      <MediaGroup>
                        <Media size="tiny">
                          <Image src="/images/favicon.png"/>
                        </Media>
                        <MediaText>
                          <strong>Hilingo</strong>
                        </MediaText>
                      </MediaGroup>
                      {/* <small>11 mins ago</small> */}
                    </Toast.Header>
                    <Toast.Body className="text-secondary" style={{height:"120px",backgroundColor:"#E8E7EC",boxShadow:" 0 4px 6px rgba(0, 0, 0, 0.1)",fontSize: "1.1em"}}>QRCode Copied!!</Toast.Body>
                </Toast>
                </ToastContainer>
      </Col>
      {/* <Col xs={6}>
        <Button onClick={() => setShow(true)}>Show Toast</Button>
      </Col> */}
    </Row>


<Modal
        size="sm"
        show={showQr }
        onHide={handleCloseQr}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry QRCode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
<div >
     
      <div ref={ref} />
    </div>

          </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center gap-3">
          {/* <Button variant="secondary" onClick={handleCloseQr}>
            Close
          </Button> */}
          <Button style={{backgroundColor:domainData?.inst_profile?.color_code}} variant="primary" onClick={copyToClipboard}>
          Copy
          </Button>
          <Button style={{backgroundColor:domainData?.inst_profile?.color_code}} variant="primary" onClick={onDownloadClick}>
          Download
          </Button>

        </Modal.Footer>
      </Modal>
      
    </div>
  )
}

export default QrCode


